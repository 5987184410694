import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isCanadianBrand } from 'utils/translation';
import { F, Button } from 'components/Ui';
import HtmlData from 'components/Ui/HtmlData/index';
import styles from 'components/ContentfulFields/Header/Navigation/WaterRegional/styles.scss';

const TEXT = 'Refer a Friend';

const NavButton = ({ isMobile }) => {
  if (window.isCostcoWater) return null;
  if (isCanadianBrand()) return null;

  return (
    <li>
      <HtmlData inline tracking={'Refer a friend'}>
        {isMobile ? (
          <Link
            className={styles.link}
            to="/refer"
            data-tracking="refer-nav-btn-mobile"
          >
            <F>{TEXT}</F>
          </Link>
        ) : (
          <Button
            component={Link}
            text={TEXT}
            to="/refer"
            className={styles.btn}
            data-tracking="refer-nav-btn"
          />
        )}
      </HtmlData>
    </li>
  );
};

NavButton.propTypes = {
  isMobile: PropTypes.bool,
};

export default NavButton;
