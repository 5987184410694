import { Map, fromJS } from 'immutable';
import { SHOW_DRAWER, HIDE_DRAWER } from 'containers/DrawerManager/constants';

const initialState = Map();

function drawerReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_DRAWER: {
      return state.merge(
        fromJS({
          [action.payload.pageTitle || 'root']: true,
        }),
      );
    }
    case HIDE_DRAWER: {
      return state.merge(
        fromJS({
          [action.payload.pageTitle || 'root']: false,
        }),
      );
    }
    default: {
      return state;
    }
  }
}

export default drawerReducer;
