import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/Ui';
import ProductsList from './Product';
import Description from './Description';
import styles from './styles.scss';

const CompareProducts = ({
  document: {
    fields: { content, products },
  },
}) => (
  <Container value="Compare Products" className={styles.container}>
    <Description content={content} />
    <ProductsList products={products} />
  </Container>
);

CompareProducts.propTypes = {
  document: PropTypes.object,
};

export default CompareProducts;
