import React, { forwardRef } from 'react';
import { WaterZipForm } from 'components/Forms';
import styles from './styles.scss';

const StartShopping = forwardRef((props, ref) => (
  <div ref={ref} className={styles.startShopping}>
    <WaterZipForm />
  </div>
));

export default StartShopping;
