module.exports = (theme) => ({
  root: {
    padding: '1px',
    fontSize: '0.9375rem',
    lineHeight: '1.1875rem',
    color: theme.colors.dimGrey,
    backgroundColor: theme.colors.white,
    '&$error $notchedOutline': {
      border: `1px solid ${theme.colors.lightRed} !important`,
    },
  },
  input: {
    padding: '10px 12px',
    backgroundColor: theme.colors.whiteGrey,
  },
  notchedOutline: {
    borderRadius: 5,
    border: `1px solid ${theme.colors.heavySome} !important`,
  },
  error: {},
  focused: {
    '& $notchedOutline': {
      border: `3px solid ${theme.colors.hoverBorder} !important`,
    },
  },
  selectFocused: {
    '& $notchedOutline': {
      border: `1px solid ${theme.colors.heavySome} !important`,
    },
  },
  multiline: {
    padding: 0,
  },
  inputError: {
    marginTop: 4,
    fontSize: '0.6875rem',
    color: `${theme.colors.mediumRed} !important`,
    '& span': {
      backgroundColor: 'transparent',
    },
  },
  adornedStart: {
    backgroundColor: 'transparent !important',
  },
  rootLabel: {
    paddingLeft: 0,
    whiteSpace: 'nowrap',
  },
  smallLabels: {
    display: 'inline',
    marginBottom: '10px',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: 900,
    lineHeight: '0.9rem',
  },
  defaultLabels: {
    color: theme.colors.black,
  },
  boldLabel: {
    fontWeight: 600,
  },
  rightComp: {
    position: 'absolute',
    top: -4,
    right: 0,
  },
});
