import { List } from 'immutable';
import qs from 'qs';
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { apiCall } from 'utils/swaggerClient';
import { dataLayerPush } from 'utils/tracking';
import {
  throwErrorTest,
  handleError,
  getErrorMessage,
} from 'utils/errorHandling';
import { getOrFetchServiceLocationId } from 'containers/PrimoAccount/saga';
import { getIsFavoritesRequest, getResults } from './selectors';
import actions, { types } from './actions';

function* fetchFavorites() {
  try {
    throwErrorTest('fetchFavorites');
    const serviceLocationId = yield call(getOrFetchServiceLocationId);

    const results = yield call(apiCall, {
      operationId: 'ServiceLocations.getFavoriteProducts',
      parameters: { serviceLocationId },
    });

    yield put(actions.receive(results));
  } catch (error) {
    yield handleError('fetchFavorites', types.FAIL, getErrorMessage(error));
  }
}

export function* getOrFetchFavorites() {
  const results = yield select(getResults);
  if (results) return results;

  yield put(actions.request());
  yield take(types.RECEIVE);
  return yield select(getResults);
}

export function* getRequestedFavorites() {
  const isFavoritesRequest = yield select(getIsFavoritesRequest);
  if (!isFavoritesRequest) return undefined;

  const favorites = yield call(getOrFetchFavorites);

  const itemNumbers = favorites
    .map((f) => f.get('itemNumber'))
    .filter((v) => !!v);

  // ultimately these item numbers are sent to the products api
  // the products api, given no item numbers, returns all products
  // to get zero products, we must request non-existent item number 0
  if (itemNumbers.size < 1) return List([0]);

  return itemNumbers;
}

function* showFavorites({ payload: history }) {
  const { serviceLocationId } = qs.parse(history.location.search);
  const search = qs.stringify({
    serviceLocationId,
    favorites: 1,
  });
  history.push({ search });
}

function* addFavorite({ payload: item, meta: dataLayer }) {
  try {
    throwErrorTest('addFavorite');
    const serviceLocationId = yield call(getOrFetchServiceLocationId);

    yield call(apiCall, {
      operationId: 'ServiceLocations.addToFavoriteProducts',
      parameters: { serviceLocationId },
      options: {
        requestBody: {
          products: [item],
        },
      },
    });

    yield put({
      type: types.TOGGLE_SUCCESS,
    });

    if (dataLayer) {
      dataLayerPush('SelfServe', dataLayer);
    }
  } catch (error) {
    yield handleError(
      'addFavorite',
      types.TOGGLE_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* removeFavorite({ payload: item }) {
  try {
    throwErrorTest('removeFavorite');
    const serviceLocationId = yield call(getOrFetchServiceLocationId);

    yield call(apiCall, {
      operationId: 'ServiceLocations.removeFromFavoriteProducts',
      parameters: { serviceLocationId },
      options: {
        requestBody: {
          products: [item],
        },
      },
    });

    yield put({
      type: types.TOGGLE_SUCCESS,
    });
  } catch (error) {
    yield handleError(
      'removeFavorite',
      types.TOGGLE_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* toggleFavorite({ payload, meta }) {
  throwErrorTest('toggleFavorite');

  const { item, isFavorite } = payload;

  const action = isFavorite ? actions.remove(item) : actions.add(item, meta);

  yield put(action);
}

const { REQUEST, SHOW, ADD, REMOVE, TOGGLE, TOGGLE_SUCCESS } = types;

export default function* favoritesSaga() {
  yield all([
    takeLeading(REQUEST, fetchFavorites),
    takeEvery(SHOW, showFavorites),
    takeEvery(ADD, addFavorite),
    takeEvery(REMOVE, removeFavorite),
    takeEvery(TOGGLE, toggleFavorite),
    takeLatest(TOGGLE_SUCCESS, fetchFavorites),
  ]);
}
