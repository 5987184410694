import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isSsr } from 'utils/ssrHelper';

const EXCEPT_ROUTE_LIST = ['/account/'];

const isInRouteList = (prevPath, curPath, list) =>
  !!list.find((item) => prevPath.includes(item) && curPath.includes(item));

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { pathname: curPath } = this.props.location;
    const { pathname: prevPath } = prevProps.location;
    if (
      curPath !== prevPath &&
      !isSsr() &&
      !isInRouteList(prevPath, curPath, EXCEPT_ROUTE_LIST)
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(ScrollToTop);
