const styles = (theme) => ({
  contentRoot: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0 0 0',
    },
  },
});

export default styles;
