import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Dialog } from 'components/Ui';
import { selectDrawers } from 'containers/DrawerManager/selectors';
import { showDrawer, hideDrawer } from 'containers/DrawerManager/actions';

const ReduxDrawer = ({
  WrappedComponent,
  drawerKey,
  drawerProps = {},
  props = {},
  type = 'drawer',
}) => {
  const dispatch = useDispatch();
  const drawers = useSelector(selectDrawers());
  const isOpen = useMemo(() => drawers[drawerKey], [drawers[drawerKey]]);
  const Component = useMemo(() => (type === 'drawer' ? Drawer : Dialog), [
    type,
  ]);
  const show = () => dispatch(showDrawer(drawerKey));
  const hide = () => dispatch(hideDrawer(drawerKey));

  let compProps = {};
  if (props.onClose) {
    compProps = {
      open: isOpen,
      show,
      onClose: props.onClose,
    };
  } else {
    compProps = {
      open: isOpen,
      show,
      [type === 'drawer' ? 'hide' : 'onClose']: hide,
    };
  }

  return (
    <Component {...compProps} {...drawerProps}>
      <WrappedComponent show={show} hide={hide} {...props} />
    </Component>
  );
};

export default ReduxDrawer;
