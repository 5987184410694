import React, { useMemo, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import findIndex from 'lodash/findIndex';
import { AppBar } from '@material-ui/core';
import { Button, Stepper, H1, Span } from 'components/Ui';
import { ArrowInRound } from 'images';
import { selectCheckoutLoading } from 'containers/CheckoutPage/selectors';
import { useIsMobile } from 'utils/hooks';
import { translateData, getToUrl } from 'utils/translation';

import {
  CONFIRMATION_PAGE,
  INFORMATION_PAGE,
  SETUP_PAGE,
  PAYMENT_PAGE,
  REVIEW_PAGE,
} from './constants';
import { steps } from './steps';

import styles from './styles.scss';

const Header = ({
  page,
  topOffset = 0,
  isForwardEnabled,
  forwardHandler,
  backHandler,
}) => {
  const [isCostco, setIsCostco] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (key) => dispatch(push(getToUrl(key, 'U41')));
  const isMobile = useIsMobile();

  const isProcessing = useSelector(selectCheckoutLoading());
  const stepsArray = Object.entries(steps).reduce((result, [key, item]) => {
    if (!item.hideSteps) {
      result.push({ key, title: translateData(item.buttonText) });
    }
    return result;
  }, []);

  const activeStep = useMemo(
    () => findIndex(stepsArray, (step) => step.key === page),
    [page],
  );
  const { hideSteps, headerText } = useMemo(() => steps[page], [page]);
  const showBackButton = useMemo(
    () => isMobile && [REVIEW_PAGE, SETUP_PAGE, PAYMENT_PAGE].includes(page),
    [isMobile, page],
  );
  const nonCostcoStyle = useMemo(
    () => (isMobile ? { bottom: 0, top: 'auto' } : { top: `${topOffset}px` }),
    [isMobile, topOffset],
  );
  const costcoStyle = useMemo(
    () => (isMobile ? { bottom: 0, top: 'auto' } : { top: `${topOffset}px` }),
    [isMobile, topOffset],
  );
  const style = isCostco ? costcoStyle : nonCostcoStyle;

  useEffect(() => {
    setIsCostco(window.isCostcoWater);
  }, []);

  return (
    <AppBar
      position={isMobile ? 'fixed' : 'sticky'}
      className={styles.header}
      style={style}
    >
      <div
        className={classnames(
          styles.container,
          page === CONFIRMATION_PAGE && styles.confirmationContainer,
        )}
      >
        {!hideSteps ? (
          <Fragment>
            <H1 className={styles.title}>Order checkout:</H1>
            <div className={styles.actionsContainer}>
              {isMobile && showBackButton && (
                <Button
                  className={classnames(
                    styles.backButton,
                    styles.button,
                    !showBackButton && styles.noBackButton,
                  )}
                  onClick={backHandler}
                  icon={<img src={ArrowInRound} alt="back" />}
                  disabled={isProcessing}
                />
              )}
              <div className={styles.stepperContainer}>
                <Stepper
                  steps={stepsArray}
                  activeStep={activeStep}
                  onClick={handleClick}
                />
              </div>
              {isMobile &&
                [
                  INFORMATION_PAGE,
                  SETUP_PAGE,
                  PAYMENT_PAGE,
                  REVIEW_PAGE,
                ].includes(page) && (
                  <Button
                    className={styles.button}
                    onClick={forwardHandler}
                    disabled={!isForwardEnabled}
                    icon={<img src={ArrowInRound} alt="forward" />}
                    loading={isProcessing}
                  />
                )}
            </div>
          </Fragment>
        ) : (
          <Span className={styles.successTitle}>{headerText}</Span>
        )}
      </div>
    </AppBar>
  );
};

Header.propTypes = {
  page: PropTypes.string.isRequired,
  topOffset: PropTypes.number,
  isForwardEnabled: PropTypes.bool,
  forwardHandler: PropTypes.func,
  backHandler: PropTypes.func,
};

export default Header;
