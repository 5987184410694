import React from 'react';
import { useDispatch } from 'react-redux';
import {
  updateDeliveryOrder as updateDeliveryOrderAction,
  updateRecurringOrder as updateRecurringOrderAction,
} from 'containers/PrimoAccount/actions';

import { addToDeliveryOrder as addToDeliveryOrderAction } from 'containers/PrimoProducts/actions';

export const withOrderUtils = (WrappedComponent) => ({ ...props }) => {
  const dispatch = useDispatch();

  const areSameItem = (item1, item2) =>
    `${item1.scr}|${item1.itemId}|${item1.scrOd}` ===
    `${item2.scr}|${item2.itemId}|${item2.scrOd}`;

  const addToDeliveryOrder = (deliveryOrder, product, quantity) => {
    dispatch(addToDeliveryOrderAction(deliveryOrder, product, quantity));
  };

  const updateDeliveryOrder = (deliveryOrder, changedDelivery) => {
    const deliveryOrderItems = deliveryOrder.items.map((defaultItem) => {
      const item = defaultItem;
      let { quantity } = defaultItem;
      let deliveryAction = 'NO_CHANGE';

      const deliveryItemFound = changedDelivery.find((deliveryItem) =>
        areSameItem(deliveryItem, defaultItem),
      );
      if (deliveryItemFound && deliveryItemFound.quantity > 0) {
        if (defaultItem.quantity !== deliveryItemFound.quantity) {
          quantity = deliveryItemFound.quantity;
          deliveryAction = 'CHANGED';
        }
      } else {
        deliveryAction = 'REMOVED';
      }
      return { ...item, quantity, deliveryAction };
    });

    dispatch(
      updateDeliveryOrderAction(
        deliveryOrderItems.map((item) => ({
          deliveryAction: item.deliveryAction,
          deliveryType: item.deliveryType,
          instanceId: item.instanceId,
          itemId: item.itemId,
          itemNumber: item.itemNumber,
          description: item.longDescription || item.description,
          quantity: item.quantity,
          itemType: item.itemType,
          lineNumber: item.lineNumber,
          orderNumber: item.orderNumber,
          orderReason: item.orderReason,
          orderStatus: item.orderStatus,
          orderType: item.orderType,
          priceChange: item.priceChange,
          removable: item.removable,
          src: item.src,
          srcId: item.srcId,
          serviceType: item.serviceType,
          price: item.price.toFixed(2),
          floorPrice: item.floorPrice.toFixed(2),
          lineTotal: item.lineTotal && item.lineTotal.toFixed(2),
          listPrice: item.listPrice && item.listPrice.toFixed(2),
        })),
        deliveryOrder.date,
      ),
    );
  };

  const updateRecurringOrder = (recurringOrder, showChangeSuccessfulModal) => {
    dispatch(
      updateRecurringOrderAction(recurringOrder, showChangeSuccessfulModal),
    );
  };

  const isInOrderDelivery = (itemNumber, shopDeliveryOrder) => {
    if (shopDeliveryOrder) {
      const itemsAdded = shopDeliveryOrder.itemsAdded || [];
      return (
        shopDeliveryOrder.items
          .concat(itemsAdded)
          .find((item) => item.itemNumber === itemNumber) !== undefined
      );
    }
    return false;
  };

  const others = {
    updateDeliveryOrder,
    addToDeliveryOrder,
    updateRecurringOrder,
    isInOrderDelivery,
  };

  return <WrappedComponent {...props} {...others} />;
};
