import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import qs from 'qs';
import injectSaga from 'utils/injectSaga';
import saga from './saga';
import { fetchClientLocation } from './actions';
import { LOCATION_KEY } from './constants';

const parseSearch = (search) => qs.parse(search, { ignoreQueryPrefix: true });

export class ClientLocationProvider extends PureComponent {
  componentDidMount() {
    const {
      location: { pathname, search },
    } = this.props;

    // If we are following a non servicable path, there's no need to fetch
    // the client location
    if (
      pathname === '/non-serviceable' &&
      qs.parse(search, { ignoreQueryPrefix: true }).enteredPostalCode
    ) {
      return;
    }
    const { postalCode: queryPostalCode } = parseSearch(search);
    this.props.fetchClientLocation({ zip: queryPostalCode });
  }

  render() {
    return null;
  }
}

ClientLocationProvider.propTypes = {
  fetchClientLocation: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
};

const mapDispatchToProps = { fetchClientLocation };

const withConnect = connect(null, mapDispatchToProps);

const withSaga = injectSaga({
  key: LOCATION_KEY,
  saga,
});

export default compose(
  withRouter,
  withConnect,
  withSaga,
)(ClientLocationProvider);
