import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import styles from './styles.scss';

const AddButton = ({
  children,
  onClick,
  className,
  dataTracking,
  ariaLabel,
}) => (
  <ButtonBase
    onClick={onClick}
    className={classnames(styles.addButton, className)}
    data-tracking={dataTracking}
    aria-label={ariaLabel}
  >
    <span className={styles.icon} />
    <label>{children}</label>
  </ButtonBase>
);

AddButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddButton.defaultProps = {
  className: null,
};

export default AddButton;
