import { isSsr } from 'utils/ssrHelper';

export const getServerData = (key) => {
  // Uncomment below if you want to test different brands without restarting server
  // if (key === 'LOCAL_DOMAIN') {
  //   const num = 4;
  //   const isAcquisition = true;
  //   const prefix = isAcquisition ? 'acquisition-' : '';
  //   switch (num) {
  //     case 1:
  //       return `${prefix}alhambrawater`;
  //     case 2:
  //       return `${prefix}canadiansprings`;
  //     case 3:
  //       return `${prefix}crystalrock`;
  //     case 4:
  //       return `${prefix}crystal-springs`;
  //     case 5:
  //       return `${prefix}deeprockwater`;
  //     case 6:
  //       return `${prefix}hinckleysprings`;
  //     case 7:
  //       return `${prefix}kentwoodsprings`;
  //     case 8:
  //       return `${prefix}labradorsource`;
  //     case 9:
  //       return `${prefix}mountolympuswater`;
  //     case 10:
  //       return `${prefix}sierrasprings`;
  //     case 11:
  //       return `${prefix}sparkletts`;
  //     case 12:
  //       return 'costcowater';
  //     default:
  //       return `${prefix}sparkletts`;
  //   }
  // }
  if (isSsr()) {
    return process.env[key];
  }

  return window.SERVER_DATA[key];
};
