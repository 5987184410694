import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AutoComplete, Button, Icon, H2, Div, Span } from 'components/Ui';
import { ProductListItem } from 'components/ContentfulFields/ProductCard';
import MessageDialog from 'components/Dialogs/MessageDialog';
import WhatAreMyOptionsDialog from 'components/Dialogs/WhatAreMyOptionsDialog/Loadable';
import {
  EASY_EDIT_PLAN,
  PREDICTIVE_ORDER,
  REPLENISH,
} from 'components/Dialogs/WhatAreMyOptionsDialog/constants';
import { accountIcons, InfoIcon } from 'images';
import { dataLayerPush } from 'utils/tracking';
import {
  selectServiceLocationIsFetchingOrder,
  selectCustomerSummary,
  selectSelectedServiceLocation,
} from '../selectors';
import roStyles from './recurring-order-styles.scss';
import {
  getProductsWithChangedField,
  getQuantityChangedProductsForDataLayer,
  getRemovedProductsForDataLayer,
} from './ExtraDeliveryForm';

const RecurringOrderForm = ({
  order,
  onClose,
  onSubmit,
  isFetching,
  customerSummary,
  history,
  serviceLocation,
}) => {
  const isResidential = customerSummary.custType === 'PERSON';
  const isCommercial = !isResidential;

  const [editedOrder, setEditedOrder] = useState(order);
  const [hasChanges, setHasChanges] = useState(false);

  const [options, changeOptions] = useState(false);
  const [
    showWhatAreMyOptionsDialog,
    changeShowWhatAreMyOptionsDialog,
  ] = useState(false);
  const [showSwitchToDialog, changeShowSwitchToDialog] = useState(false);

  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const cannotDeleteTitle = 'Oops!';
  const cannotDeleteMessage =
    'Looks like you’re trying to remove all the items from your recurring order.';

  const handleShowSwitchToDialog = (item, product) => {
    const isReturnable = product.relatedItemId > 0;
    changeOptions({
      isResidential,
      isReturnable,
      switchTo: item.switchToValue,
      value: item.value,
      product,
    });
    changeShowSwitchToDialog(product);
  };

  const handleHideSwitchToDialog = () => changeShowSwitchToDialog(false);

  const handleSaveSwitchTo = (switchTo) => {
    changeShowSwitchToDialog(false);
    options.product.switchTo = switchTo;
    handleDeliveryFrequencyChange(options.product, options.value);
  };

  const handleSwitchTo = (currentItem, item, product) => {
    if (currentItem.value !== item.value) {
      handleShowSwitchToDialog(item, product);
    }
  };

  const handleShowWhatAreMyOptionsDialog = (product) => {
    const isReturnable = product.relatedItemId > 0;
    changeOptions({ choice: true, isResidential, isReturnable });
    changeShowWhatAreMyOptionsDialog(true);
  };

  const handleHideWhatAreMyOptionsDialog = () =>
    changeShowWhatAreMyOptionsDialog(false);

  const onDelete = (deletedProduct) => {
    if (editedOrder.length === 1) {
      setOpenMessageDialog(true);
    } else {
      setHasChanges(true);
      setEditedOrder(
        editedOrder.filter(
          (product) => product.itemNumber !== deletedProduct.itemNumber,
        ),
      );
    }
  };

  const handleQuantityChange = (product, newCount) => {
    setHasChanges(true);
    const newOrder = editedOrder.map((orderedProduct) => {
      if (orderedProduct.itemNumber === product.itemNumber) {
        orderedProduct.originalQuantity = orderedProduct.originalQuantity
          ? orderedProduct.originalQuantity
          : orderedProduct.quantity;
        return { ...orderedProduct, quantity: newCount };
      }
      return orderedProduct;
    });
    setEditedOrder(newOrder);
  };

  const handleDeliveryFrequencyChange = (product, newDeliveryFrequency) => {
    setHasChanges(true);
    const newOrder = editedOrder.map((orderedProduct) => {
      if (orderedProduct.itemNumber === product.itemNumber) {
        const quantity = orderedProduct.originalQuantity
          ? orderedProduct.originalQuantity
          : orderedProduct.quantity;
        delete orderedProduct.originalQuantity;
        return {
          ...orderedProduct,
          quantity,
          deliveryFrequency: newDeliveryFrequency,
        };
      }
      return orderedProduct;
    });
    setEditedOrder(newOrder);
  };

  const handleBrowseProducts = () => {
    onClose();
    history.push(
      `/account/products/list?serviceLocationId=${serviceLocation.id}`,
    );
  };

  const formatLabel = (item) => (
    <Fragment>
      <div className={roStyles.selectLabelContainer}>
        <Div className={roStyles.label}>
          <span className={roStyles.informationImage}>
            <img src={accountIcons[item.replace(/\s+/g, '')]} alt="" />
          </span>
          {item}
        </Div>
      </div>
    </Fragment>
  );

  const renderBottom = (product) => {
    const isReturnable = product.relatedItemId > 0;
    const easyEditPlan = {
      label: formatLabel(EASY_EDIT_PLAN),
      value: 'Every Delivery',
      switchToValue: EASY_EDIT_PLAN,
    };
    const predictiveOrder = {
      label: formatLabel(PREDICTIVE_ORDER),
      value: 'Replenish',
      switchToValue: PREDICTIVE_ORDER,
    };
    const replenish = {
      label: formatLabel(REPLENISH),
      value: 'Replenish',
      switchToValue: REPLENISH,
    };
    const items = [
      easyEditPlan,
      isCommercial ? replenish : isReturnable ? replenish : predictiveOrder,
    ];

    const value =
      product.deliveryFrequency === 'Every Delivery' ? items[0] : items[1];

    return (
      <div className={roStyles.bottom}>
        <AutoComplete
          darkDropdown
          selectClassName={roStyles.options}
          options={items}
          value={value}
          onChange={(item) => handleSwitchTo(value, item, product)}
          name="switchTo"
          isClearable={false}
          isSearchable={false}
        />
        <Button
          className={roStyles.button}
          link
          text={
            <Div>
              <span className={roStyles.informationImage}>
                <img src={InfoIcon} alt="" />
              </span>
              What are my options
            </Div>
          }
          onClick={() => handleShowWhatAreMyOptionsDialog(product)}
        />
      </div>
    );
  };

  const handleSubmit = () => {
    onSubmit(editedOrder);

    const removedProducts = getRemovedProductsForDataLayer(order, editedOrder);

    const changedQuantityProducts = getQuantityChangedProductsForDataLayer(
      order,
      editedOrder,
    );

    const productsWithChangedOrderType = getProductsWithChangedField(
      'deliveryFrequency',
      order,
      editedOrder,
    );

    productsWithChangedOrderType.forEach((item) => {
      item.deliveryFrequency = item.switchTo
        ? item.switchTo
        : item.deliveryFrequency;
      delete item.switchTo;
    });

    if (productsWithChangedOrderType.length) {
      dataLayerPush(
        'SelfServe',
        {
          section: 'edit recurring order',
          event: 'type-change',
          products: productsWithChangedOrderType,
        },
        'dlS31',
      );
    }

    if (removedProducts.length) {
      dataLayerPush(
        'SelfServe',
        {
          section: 'edit recurring order',
          event: 'delete',
          products: removedProducts,
        },
        'dlS32',
      );
    }

    if (changedQuantityProducts.length) {
      dataLayerPush(
        'SelfServe',
        {
          section: 'edit recurring order',
          event: 'quantity-change',
          products: changedQuantityProducts,
        },
        'dlS33',
      );
    }
  };

  return (
    <div className={roStyles.formWrapper}>
      <div className={roStyles.editForm}>
        <div className={roStyles.formHeader}>
          <Icon icon="cog" />
          <H2>Your recurring order</H2>
          <Div className={roStyles.subtext}>
            You have two options for the items in your recurring service. Select
            the option that best fits your current needs.
          </Div>
        </div>

        <div className={roStyles.formBody}>
          {editedOrder ? (
            editedOrder.map((product) => (
              <ProductListItem
                onQuantityChange={
                  product.deliveryFrequency !== 'Replenish'
                    ? handleQuantityChange
                    : null
                }
                onDelete={product.removable ? onDelete : null}
                key={product.itemNumber}
                product={product}
                inDrawer
                bottom={renderBottom(product)}
              />
            ))
          ) : (
            <Div>Recurring order has nothing</Div>
          )}
        </div>
        <Div className={roStyles.note}>
          Note: Prices per item do not reflect bottle deposits and other
          surcharges.
        </Div>
        <div className={roStyles.formBottom}>
          <div className={roStyles.additionalActions}>
            <Span className={roStyles.text}>
              Add additional recurring products anytime!
            </Span>
            <Button
              className={roStyles.editButton}
              onClick={handleBrowseProducts}
              text="Browse Products"
              link
            />
          </div>
          <div className={roStyles.formActions}>
            <Button
              className={roStyles.cancelButton}
              text="Cancel"
              onClick={onClose}
            />
            <Button
              className={roStyles.saveButton}
              text="Save"
              onClick={handleSubmit}
              disabled={isFetching || !hasChanges || editedOrder.length === 0}
            />
          </div>
        </div>
      </div>
      <WhatAreMyOptionsDialog
        {...options}
        open={showSwitchToDialog}
        onSave={handleSaveSwitchTo}
        onClose={handleHideSwitchToDialog}
      />
      <WhatAreMyOptionsDialog
        {...options}
        open={showWhatAreMyOptionsDialog}
        onClose={handleHideWhatAreMyOptionsDialog}
      />
      {openMessageDialog && (
        <MessageDialog
          open={openMessageDialog}
          onClose={() => setOpenMessageDialog(false)}
          title={cannotDeleteTitle}
          message={cannotDeleteMessage}
        />
      )}
    </div>
  );
};

RecurringOrderForm.propTypes = {
  order: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  customerSummary: PropTypes.object,
  serviceLocation: PropTypes.object,
  history: PropTypes.object,
};

RecurringOrderForm.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  isFetching: selectServiceLocationIsFetchingOrder(),
  customerSummary: selectCustomerSummary(),
  serviceLocation: selectSelectedServiceLocation(),
});

export default withRouter(connect(mapStateToProps)(RecurringOrderForm));
