import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, ButtonBase } from '@material-ui/core';
import styles from './styles.scss';
import ViewIn3DImage from 'images/3DView.png';
import axios from 'axios';

const PRODUCT_IMG_WIDTH = 286;
const PRODUCT_THUMB_WIDTH = 77;

const ProductImageGallery = ({ images, options }) => {
  const [mainImage, handleChange] = useState(images[0]);
  const [liveModelOptions, setLiveModelOptions] = useState(null);

  if (!liveModelOptions && options?.liveModelId) {
    axios
      .get(`/products/live-models/${options.liveModelId}/`)
      .then(response => setLiveModelOptions(response.data));
  }

  return (
    <div className={styles.imageGallery}>

      {mainImage?.liveModelId && (
        <iframe src={liveModelOptions.liveModelUrl} width={PRODUCT_IMG_WIDTH} height={PRODUCT_IMG_WIDTH}></iframe>
      )}

      {!mainImage?.liveModelId && (
        <div className={classnames(styles.mainImage, styles.image)}>
          <img
            src={`${mainImage.fields.file.url}?w=${PRODUCT_IMG_WIDTH}&fm=webp`}
            alt={mainImage.fields.description}
          />
        </div>
      )}

      <Grid container spacing={3}>
        {images && images.map((image) => (
          <Grid key={image.sys.id} item xs={4} sm={4}>
            <ButtonBase
              className={styles.image}
              onClick={() => handleChange(image)}
            >
              <img
                src={`${image.fields.file.url}?w=${PRODUCT_THUMB_WIDTH}&fm=webp`}
                alt={image.fields.description}
              />
            </ButtonBase>
          </Grid>
        ))}

        {/* Show 3D View Button */}

        {options?.liveModelId &&
          <Grid item xs={4} sm={4}>
            <ButtonBase
              className={styles.image}
              onClick={() => handleChange(options)}
            >
              <img src={ViewIn3DImage} width={PRODUCT_THUMB_WIDTH} />
            </ButtonBase>
          </Grid>
        }
      </Grid>
    </div>
  );
};

ProductImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.object
};

export default ProductImageGallery;
