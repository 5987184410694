import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    height: '9px',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      height: '5px',
    },
  },
  progress: {
    backgroundImage: theme.gradients.primaryGradient,
  },
});

const Progress = ({ value = 0, classes }) => (
  <LinearProgress
    classes={{
      bar: classes.progress,
      root: classes.root,
    }}
    variant="determinate"
    value={value}
  />
);

Progress.propTypes = {
  value: PropTypes.number,
  classes: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Progress);
