import {
  takeLatest,
  put,
  call,
  all,
  select,
  take,
  takeLeading,
} from 'redux-saga/effects';

import get from 'lodash/get';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import ContentfulClient from 'utils/ContentfulClient';
import { apiCall } from 'utils/swaggerClient';
import { fetchProductCards } from 'utils/contentfulUtils';
import { isPurefloDomain } from 'utils/domainHelper';
import { adjustPostalCode } from 'utils/common';
import {
  throwErrorTest,
  handleError,
  getErrorMessage,
} from 'utils/errorHandling';
import { completeProductItemsSaga } from 'containers/PrimoProducts/saga';
import { showSnackbar } from 'components/Notifier/actions';
import {
  selectUserId,
  selectIsAuthenticated,
} from 'containers/Authentication/selectors';
import {
  selectCostcoAccount,
  selectPromotionLandingPageData,
  selectAcquisitionVariables,
  selectSection,
  selectHomeOffice,
} from 'containers/Landing/selectors';
import { selectProductsData } from 'containers/PrimoProducts/selectors';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import {
  UPDATE_USER_REGION,
  FETCH_USER_REGION,
} from 'containers/UserRegion/constants';

import { resetOrder } from 'containers/CheckoutPage/actions';
import { selectCheckoutCreditCard } from 'containers/CheckoutPage/selectors';
import {
  dataLayerPush,
  dataLayerPushSuccess,
  dataLayerPushFailure,
} from 'utils/tracking';
import { CLEAR_CART_FLAG } from './constants';
import {
  clearLocalStorageCartId,
  getLocalStorageCartId,
  updateLocalStorageCartId,
  getLocalStorageCartSecret,
  updateLocalStorageCartSecret,
  clearLocalStorageCartSecret,
} from './utils';
import {
  loadCartProducts,
  fetchAnonymousCartSuccess,
  createCartSuccess,
  addItemToAnonymousCartSuccess,
  addItemToUserCart as addItemToUserCartAction,
  addItemToAnonymousCart as addItemToAnonymousCartAction,
  getCartSummary as getCartSummaryAction,
  clearCart as clearCartAction,
  fetchAnonymousCartFailure,
} from './actions';
import {
  cartExists,
  selectCartId,
  selectCartIsLoaded,
  selectBranchId,
  selectPaymentAddress,
  selectPromotionId,
  selectCartSecret,
} from './selectors';
import * as types from './constants';
import * as productTypes from '../PrimoProducts/constants';

const contentfulClient = new ContentfulClient();

export function* getCartId() {
  const storeCartId = yield select(selectCartId());
  const storageCartId = getLocalStorageCartId();

  return storeCartId || storageCartId;
}

export function* getCartSecret() {
  const storeCartSecret = yield select(selectCartSecret());
  const storageCartSecret = getLocalStorageCartSecret();

  return storeCartSecret || storageCartSecret;
}

function* fetchAnonymousCart({ payload }) {
  const { cartId } = payload;
  try {
    throwErrorTest('fetchAnonymousCart');
    const cart = yield call(apiCall, {
      operationId: 'carts.getAnonymousCart',
      parameters: { cartId },
    });
    const secretId = yield getCartSecret();
    const cartWithSecret = set(cart, 'secretId', secretId);

    yield put(fetchAnonymousCartSuccess(cartWithSecret));
    if (!isEmpty(cart.items)) {
      yield put(loadCartProducts(cart.items));
    }
    yield put(getCartSummaryAction({ cartId }));
    yield put({ type: FETCH_USER_REGION, payload: cart.postalCode });
  } catch (error) {
    yield put(clearCartAction());
    yield put(fetchAnonymousCartFailure());
  }
}

function* fetchUserCart() {
  try {
    throwErrorTest('fetchUserCart');
    const userId = yield select(selectUserId());
    const userCart = yield call(apiCall, {
      operationId: 'users.getUserCart',
      parameters: { userId },
    });

    yield put({ type: types.FETCH_USER_CART_SUCCESS, payload: userCart });

    if (!isEmpty(userCart.items)) {
      yield put(loadCartProducts(userCart.items));
    }
    yield put(getCartSummaryAction({ cartId: userCart.id }));
    yield put({ type: UPDATE_USER_REGION, payload: userCart.postalCode });
  } catch (error) {
    yield handleError(
      'fetchUserCart',
      types.FETCH_USER_CART_FAILURE,
      getErrorMessage(error),
      {},
    );
  }
}

function* mergeAnonymousCart({ payload }) {
  const { cartId } = payload;
  try {
    throwErrorTest('mergeAnonymousCart');
    const userId = yield select(selectUserId());
    yield call(apiCall, 'carts.attachCartToUser', { cartId });
    const userCart = yield call(apiCall, {
      operationId: 'users.getUserCart',
      parameters: { userId },
    });

    yield put({ type: types.MERGE_ANON_CART_SUCCESS, payload: userCart });
    yield put({ type: UPDATE_USER_REGION, payload: userCart.postalCode });
  } catch (error) {
    yield handleError(
      'mergeAnonymousCart',
      types.MERGE_ANON_CART_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* changeItemQuantity({ payload }) {
  // mmm rrr changeItemQuantity
  const { cartId, item, quantity } = payload;
  try {
    throwErrorTest('changeItemQuantity');
    yield call(apiCall, {
      operationId: 'carts.updateItem',
      parameters: { cartId, itemId: item.id },
      options: { requestBody: { quantity, itemNumber: item.itemNumber } },
    });
    yield put({
      type: types.CHANGE_ITEM_QUANTITY_SUCCESS,
      payload: { quantity, itemId: item.id },
    });
    yield put(getCartSummaryAction({ cartId }));
    dataLayerPushSuccess('PUSH_ADD_TO_CART_DATA');
    dataLayerPushSuccess('PUSH_REMOVE_FROM_CART_DATA');
  } catch (error) {
    dataLayerPushFailure('PUSH_ADD_TO_CART_DATA');
    dataLayerPushFailure('PUSH_REMOVE_FROM_CART_DATA');
    yield handleError(
      'changeItemQuantity',
      types.CHANGE_ITEM_QUANTITY_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* removeItem({ payload }) {
  // mmm rrr removeItem
  const { cartId, itemId } = payload;
  try {
    throwErrorTest('removeItem');
    yield call(apiCall, {
      operationId: 'carts.deleteItemFromCart',
      parameters: { cartId, itemId },
    });
    yield put({ type: types.REMOVE_ITEM_SUCCESS, payload: itemId });
    yield put(getCartSummaryAction({ cartId }));
    dataLayerPushSuccess('PUSH_REMOVE_FROM_CART_DATA');
  } catch (error) {
    dataLayerPushFailure('PUSH_REMOVE_FROM_CART_DATA');
    yield handleError(
      'removeItem',
      types.REMOVE_ITEM_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* createCart({ payload }) {
  // mmm aaa createCart
  const { items = [], branchId, isShowSnackbar = true } = payload;

  try {
    throwErrorTest('createCart');
    const costcoAccount = yield select(selectCostcoAccount());
    const postalCode = yield select(selectPostalCode());
    const promotionLandingPageData = yield select(
      selectPromotionLandingPageData(),
    );
    const acquisitionVariables = yield select(selectAcquisitionVariables());
    let campaignId;
    let elementId;
    // Use campaignId and elementId in the promotionLandingPageData (if defined) as the default
    if (!isEmpty(promotionLandingPageData)) {
      campaignId = promotionLandingPageData.campaignId;
      elementId = promotionLandingPageData.elementId;
    } else {
      // Use campaignId and elementId (if defined) in the acquisitionVariables
      forEach(acquisitionVariables, (variable) => {
        if (variable.name === '{campaignId}') {
          campaignId = variable.value;
        } else if (variable.name === '{elementId}') {
          elementId = variable.value;
        }
      });
    }
    // if (isCanadianBrand()) {
    //   postalCode = postalCode.substring(0, 3);
    // }
    const nonCostcoAccount =
      campaignId && elementId
        ? {
            items,
            postalCode,
            branchId,
            campaignId,
            elementId,
          }
        : { items, postalCode, branchId };
    const requestBody = costcoAccount
      ? {
          items,
          postalCode,
          branchId,
          partnerName: 'Costco',
          partnerType: costcoAccount.memberType,
          partnerMemberId: costcoAccount.memberNum,
        }
      : nonCostcoAccount;

    yield put(resetOrder());

    const cart = yield call(apiCall, {
      operationId: 'carts.createAnonymousCart',
      parameters: {},
      options: { requestBody },
    });

    updateLocalStorageCartId(cart.id);
    updateLocalStorageCartSecret(cart.secretId);

    yield put(createCartSuccess(cart));

    // apply promotion if the capaign info is available
    if (campaignId && elementId) {
      yield put({ type: types.ADD_PROMOTION });
    }

    if (isShowSnackbar && get(cart, 'items.length', 0) > 0) {
      yield put(
        showSnackbar({
          message: 'Successfully added to cart',
          options: { variant: 'success' },
        }),
      );
    }

    const zipInfo = yield apiCall({
      operationId: 'brands.getAcqServiceabilityByZip',
      parameters: {
        postalCode: adjustPostalCode(postalCode),
      },
    });

    dataLayerPush('Acquisition', {
      event: 'cart create',
      division: zipInfo.divisionName,
      region: zipInfo.regionName,
      landingPageId: campaignId ? `${campaignId}_${elementId}` : '',
    });

    dataLayerPushSuccess('PUSH_ADD_TO_CART_DATA');
  } catch (error) {
    dataLayerPushFailure('PUSH_ADD_TO_CART_DATA');
    yield handleError(
      'createCart',
      types.CREATE_CART_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* addItemToAnonymousCart({ payload }) {
  // mmm aaa addItemToAnonymousCart
  const { cartId, secretId, items } = payload;
  try {
    throwErrorTest('addItemToAnonymousCart');
    const postalCode = yield select(selectPostalCode());
    const cart = yield call(apiCall, {
      operationId: 'carts.addItemToCart',
      parameters: { cartId },
      options: { requestBody: { ...items, postalCode, secretId } },
    });
    yield put(getCartSummaryAction({ cartId }));
    yield put(addItemToAnonymousCartSuccess(cart));
    yield put(
      showSnackbar({
        message: 'Successfully added to cart',
        options: { variant: 'success' },
      }),
    );
    dataLayerPushSuccess('PUSH_ADD_TO_CART_DATA');
  } catch (error) {
    dataLayerPushFailure('PUSH_ADD_TO_CART_DATA');
    // In case add to cart request fails with 400 error, start over with new cart
    if (error.status === 400) {
      yield call(createCart, {
        payload: {
          items: [items],
          branchId: items.branchId,
        },
      });
      const newCartID = yield getCartId();
      yield put(getCartSummaryAction({ cartId: newCartID }));
      return;
    }
    if (
      getErrorMessage(error).includes(
        'BranchId and postalCode in CartItem must match values in Cart',
      )
    ) {
      yield put({ type: CLEAR_CART_FLAG });
    } else {
      const sanitizedPayload = { ...payload };
      delete sanitizedPayload.secretId;
      yield handleError(
        'addItemToAnonymousCart',
        types.ADD_ITEM_TO_ANONYMOUS_CART_FAILURE,
        getErrorMessage(error),
        sanitizedPayload,
      );
    }
  }
}

function* addItemToUserCart({ payload }) {
  const { items } = payload;
  try {
    throwErrorTest('addItemToUserCart');
    const userId = yield select(selectUserId());
    const postalCode = yield select(selectPostalCode());
    const cart = yield call(apiCall, {
      operationId: 'carts.addItemToUsersCart',
      parameters: { userId },
      options: { requestBody: { ...items, postalCode } },
    });
    yield put({ type: types.ADD_ITEM_TO_USER_CART_SUCCESS, payload: cart });

    const isCartExists = yield select(cartExists());
    if (!isCartExists) {
      yield put({ type: types.FETCH_USER_CART });
    }
    yield put(getCartSummaryAction({ cartId: cart.id }));
  } catch (error) {
    yield handleError(
      'addItemToUserCart',
      types.ADD_ITEM_TO_USER_CART_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* loadCartProductsSaga({ payload }) {
  const { items } = payload;
  try {
    throwErrorTest('loadCartProductsSaga');
    const cartProductItems = yield call(completeProductItemsSaga, items);

    yield put({
      type: types.LOAD_CART_PRODUCTS_SUCCESS,
      payload: { items: cartProductItems },
    });
  } catch (error) {
    yield handleError(
      'loadCartProductsSaga',
      types.LOAD_CART_PRODUCTS_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* getOrderProductsByItemNumber({ payload }) {
  const { itemNumbers } = payload;
  try {
    throwErrorTest('getOrderProductsByItemNumber');
    const products = yield call(
      fetchProductCards,
      itemNumbers,
      contentfulClient,
    );
    yield put({ type: types.FETCH_ORDER_PRODUCTS_SUCCESS, payload: products });
  } catch (error) {
    yield handleError(
      'getOrderProductsByItemNumber',
      types.FETCH_ORDER_PRODUCTS_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* getPaymentSurcharge(cartId, amount) {
  if (window.isCostcoWater) return null;

  const checkoutCard = yield select(selectCheckoutCreditCard());
  const encryptedCard = checkoutCard?.encryptedCreditCardNumber;

  if (!encryptedCard) return null;

  const { countryCode, stateOrProvinceCode } = yield select(
    selectPaymentAddress(),
  );

  return yield call(apiCall, {
    operationId: 'orders.retrieveAcqPaymentSurcharge',
    parameters: { cartId },
    options: {
      requestBody: {
        encryptedCard,
        stateOrProvince: stateOrProvinceCode,
        optOutFlag: 'N',
        amount,
        countryCode,
      },
    },
  });
}

function* getCartSummary({ payload }) {
  try {
    throwErrorTest('getCartSummary');
    const { cartId } = payload;

    if (!cartId) {
      // the throw below interferes with createCart errors, so return for now
      // throw new Error('getCartSummary cartId is not valid');
      return;
    }

    const section = yield select(selectSection());
    const homeOffice = yield select(selectHomeOffice());
    const customerType = window.isCostcoWater
      ? productTypes.productsTypes[homeOffice]
      : productTypes.productsTypes[section];
    const summaryResponse = yield call(apiCall, {
      operationId: 'carts.getCartSummary',
      parameters: { cartId, customerType },
    });

    let summary = { ...summaryResponse };
    const paymentSurcharges = {};

    paymentSurcharges.initial = yield call(
      getPaymentSurcharge,
      cartId,
      summaryResponse.total,
    );

    paymentSurcharges.recurring = yield call(
      getPaymentSurcharge,
      cartId,
      summaryResponse.recurringCharge,
    );

    if (paymentSurcharges.initial && paymentSurcharges.recurring) {
      summary = {
        ...summary,
        paymentSurcharges,
        ogTotal: summary.total,
        ogRecurringCharge: summary.recurringCharge,
        total: summary.total + paymentSurcharges.initial.surchargeAmount,
        recurringCharge:
          summary.recurringCharge + paymentSurcharges.recurring.surchargeAmount,
      };
    }

    yield put({
      type: types.GET_CART_SUMMARY_SUCCESS,
      payload: summary,
    });

    const { items, id, promotionId } = summaryResponse;
    if (id && isEmpty(items) && !promotionId) {
      yield put(clearCartAction());
    }
  } catch (error) {
    yield put(clearCartAction());
    yield handleError(
      'getCartSummary',
      types.GET_CART_SUMMARY_FAILURE,
      getErrorMessage(error),
      payload,
    );
  }
}

function* addToCart({ payload }) {
  const { itemNumber, quantity, branchId = '' } = payload;
  const isPureflo = isPurefloDomain();
  const isAuthenticated = yield select(selectIsAuthenticated());
  const cartId = yield getCartId();
  const secretId = yield getCartSecret();

  const availableProducts = yield select(selectProductsData());
  const branch =
    branchId ||
    get(
      availableProducts &&
        availableProducts.find((product) => product.itemNumber === itemNumber),
      'branchId',
    );

  if (isAuthenticated && isPureflo) {
    yield put(
      addItemToUserCartAction({
        items: {
          itemNumber,
          quantity,
          branchId: branch,
        },
      }),
    );

    return;
  }

  if (cartId) {
    yield put(
      addItemToAnonymousCartAction({
        cartId,
        items: {
          itemNumber,
          quantity,
          branchId: branch,
        },
        secretId,
      }),
    );

    return;
  }

  yield call(createCart, {
    payload: {
      items: [
        {
          itemNumber,
          quantity,
        },
      ],
      branchId: branch,
    },
  });

  const newCartID = yield getCartId();
  yield put(getCartSummaryAction({ cartId: newCartID }));
}

function* clearCart() {
  clearLocalStorageCartId();
  clearLocalStorageCartSecret();
}

function* locationChangeWatcher({ payload }) {
  const isAuthenticated = yield select(selectIsAuthenticated());
  const cartId = getLocalStorageCartId();
  const isCartLoaded = yield select(selectCartIsLoaded());
  const isPureflo = isPurefloDomain();

  if (payload.action === 'REPLACE') {
    if (isAuthenticated && isPureflo) {
      yield put({ type: types.FETCH_USER_CART });
      return;
    }

    if (cartId && !isCartLoaded && !isAuthenticated) {
      yield put({ type: types.FETCH_ANONYMOUS_CART, payload: { cartId } });
    }
  }
}

function* addPromotionWatcher() {
  // mmm ppp addPromotionWatcher
  try {
    throwErrorTest('addPromotionWatcher');
    let cartId = yield getCartId();
    let branchId = yield select(selectBranchId());
    const promotionLandingPageData = yield select(
      selectPromotionLandingPageData(),
    );

    const promotionId = get(
      promotionLandingPageData,
      'promotionId',
      types.PROMOTION_ID,
    );

    if (!branchId) {
      yield put({ type: types.LOAD_PROMOTIONS });
      const promotionsResponse = yield take(types.LOAD_PROMOTIONS_SUCCESS);
      branchId = get(promotionsResponse, 'payload.branchId');
    }

    if (!cartId) {
      yield call(createCart, {
        payload: {
          branchId,
          isShowSnackbar: true,
        },
      });
      cartId = yield getCartId();
    }

    yield call(apiCall, {
      operationId: 'carts.attachPromotionToCart',
      parameters: { cartId },
      options: { requestBody: { promotionId } },
    });
    yield put(getCartSummaryAction({ cartId }));
    yield put({
      type: types.ADD_PROMOTION_SUCCESS,
      payload: promotionId,
    });
    dataLayerPushSuccess('PUSH_ADD_PROMOTION_DATA');
  } catch (error) {
    dataLayerPushFailure('PUSH_ADD_PROMOTION_DATA');
    yield handleError(
      'addPromotionWatcher',
      types.ADD_PROMOTION_FAILURE,
      getErrorMessage(error),
      {},
    );
  }
}

function* removePromotionWatcher() {
  try {
    throwErrorTest('removePromotionWatcher');
    const cartId = yield getCartId();
    const promotionId = yield select(selectPromotionId());
    if (!cartId || !promotionId) {
      yield put({ type: types.REMOVE_PROMOTION_SUCCESS });
      return;
    }
    yield call(apiCall, {
      operationId: 'carts.removeCartPromotion',
      parameters: { cartId },
    });
    yield put(getCartSummaryAction({ cartId }));
    yield put({ type: types.REMOVE_PROMOTION_SUCCESS });
  } catch (error) {
    yield handleError(
      'removePromotionWatcher',
      types.REMOVE_PROMOTION_FAILURE,
      getErrorMessage(error),
      {},
    );
  }
}

function* loadPromotionsList() {
  try {
    throwErrorTest('loadPromotionsList');
    let postalCode = yield select(selectPostalCode());
    postalCode = adjustPostalCode(postalCode);
    const promotionsResponse = yield call(apiCall, {
      operationId: 'Promotions.getPromotions',
      parameters: { postalCode },
      fullResponse: true,
    });

    yield put({
      type: types.LOAD_PROMOTIONS_SUCCESS,
      payload: {
        promotions: promotionsResponse.body,
        branchId: promotionsResponse.headers['x-branch-id'],
      },
    });
  } catch (error) {
    yield handleError(
      'loadPromotionsList',
      types.LOAD_PROMOTIONS_FAILURE,
      getErrorMessage(error),
      {},
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest([types.ADD_ITEM_TO_CART], addToCart),
    takeLatest(types.FETCH_ANONYMOUS_CART, fetchAnonymousCart),
    takeLatest(types.FETCH_USER_CART, fetchUserCart),
    takeLatest(types.CHANGE_ITEM_QUANTITY, changeItemQuantity),
    takeLeading(types.REMOVE_ITEM, removeItem),
    takeLatest(types.CREATE_CART, createCart),
    takeLatest(types.LOAD_CART_PRODUCTS, loadCartProductsSaga),
    takeLatest(types.FETCH_ORDER_PRODUCTS, getOrderProductsByItemNumber),
    takeLatest(types.ADD_ITEM_TO_ANONYMOUS_CART, addItemToAnonymousCart),
    takeLatest(types.MERGE_ANON_CART, mergeAnonymousCart),
    takeLatest(types.ADD_ITEM_TO_USER_CART, addItemToUserCart),
    takeLatest(types.GET_CART_SUMMARY, getCartSummary),
    takeLatest(types.CLEAR_CART, clearCart),
    takeLatest(types.ADD_PROMOTION, addPromotionWatcher),
    takeLatest(types.REMOVE_PROMOTION, removePromotionWatcher),
    takeLatest(types.LOAD_PROMOTIONS, loadPromotionsList),
    takeLatest('@@router/LOCATION_CHANGE', locationChangeWatcher),
  ]);
}
