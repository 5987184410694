import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { createStructuredSelector } from 'reselect';
import { pages } from 'containers/Landing/constants';
import withContentful from 'containers/Landing/withContenfull';
import primoAccountReducer from 'containers/PrimoAccount/reducer';
import { PRIMO_ACCCOUNT_PAGE_KEY } from 'containers/PrimoAccount/constants';
import primoAccountSaga from 'containers/PrimoAccount/saga';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import {
  loadMerchandizedProducts,
  addToOrder,
} from 'containers/PrimoProducts/actions';
import { selectProductsAreFetching } from 'containers/PrimoProducts/selectors';
import { PRODUCTS_KEY } from 'containers/PrimoProducts/constants';
import primoProductsSaga from 'containers/PrimoProducts/saga';
import { PROFILE_PAGE_KEY } from 'containers/PrimoProfile/constants';
import primoProfileReducer from 'containers/PrimoProfile/reducer';
import primoProfileSaga from 'containers/PrimoProfile/saga';
import { selectSsrReqRoute } from 'containers/Landing/selectors';
import PrimoProductPage from './ProductPage';

const mapStateToProps = () =>
  createStructuredSelector({
    productIsFetching: selectProductsAreFetching(),
    isAuthenticated: selectIsAuthenticated(),
    ssrReqRoute: selectSsrReqRoute(),
  });

const mapDispatchToProps = {
  loadMerchandizedProducts,
  addToOrder,
};

const withReducer = injectReducer({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  reducer: primoAccountReducer,
});
const withSaga = injectSaga({
  key: PRODUCTS_KEY,
  saga: primoProductsSaga,
});
const withAccountSaga = injectSaga({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  saga: primoAccountSaga,
});
const withProfileReducer = injectReducer({
  key: PROFILE_PAGE_KEY,
  reducer: primoProfileReducer,
});
const withProfileSaga = injectSaga({
  key: PROFILE_PAGE_KEY,
  saga: primoProfileSaga,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ProductPageBase = compose(
  withReducer,
  withSaga,
  withAccountSaga,
  withProfileReducer,
  withProfileSaga,
  withConnect,
  withRouter,
)(PrimoProductPage);

export const ProductPageLoggedIn = withContentful(pages.productPage)(
  ProductPageBase,
);

export const ProductPageLoggedOut = withContentful(pages.productPageLoggedOut)(
  ProductPageBase,
);
