import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import { selectLandingData } from 'containers/Landing/selectors';
import { selectIsFetching } from 'containers/Authentication/selectors';
import { fetchPageRequest } from 'containers/Landing/actions';
import { LANDING_PAGE_KEY } from 'containers/Landing/constants';
import landingSaga from 'containers/Landing/saga';
import saga from './saga';
import { fetchSiteRequest } from './actions';
import { LAYOUT_PAGE_KEY } from './constants';
import BaseLayout from './BaseLayout';

const mapDispatchToProps = {
  fetchSite: fetchSiteRequest,
  fetchPage: fetchPageRequest,
};

const mapStateToProps = createStructuredSelector({
  site: selectLandingData(),
  isAuthFetching: selectIsFetching(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: LAYOUT_PAGE_KEY, saga });
const withLandingSaga = injectSaga({
  key: LANDING_PAGE_KEY,
  saga: landingSaga,
});

export default compose(withConnect, withLandingSaga, withSaga)(BaseLayout);
