import * as types from './constants';

export const addToOrder = (item) => ({
  type: types.ADD_TO_ORDER,
  payload: item,
});

export const addToDelivery = (item) => ({
  type: types.ADD_TO_DELIVERY,
  payload: item,
});

export const addToDeliveryOrder = (deliveryOrder, product, quantity) => ({
  type: types.ADD_TO_DELIVERY_ORDER,
  payload: { deliveryOrder, product, quantity },
});

export const loadProductCategories = () => ({
  type: types.LOAD_PRODUCT_CATEGORIES,
});

export const loadFamilyProductBundles = ({ familyBundles }) => ({
  type: types.LOAD_FAMILY_PRODUCT_BUNDLES,
  payload: { familyBundles },
});

export const loadProductBundles = ({ categoryId }) => ({
  type: types.LOAD_PRODUCT_BUNDLES,
  payload: { categoryId },
});

export const loadMerchandizedProducts = ({
  itemNumber,
  categoryId,
  brands,
  waterTypes,
  page,
  isCostcoWater,
  size,
} = {}) => ({
  type: types.LOAD_MERCHANDIZED_PRODUCTS,
  payload: {
    itemNumber,
    categoryId,
    brands,
    waterTypes,
    page,
    isCostcoWater,
    size,
  },
});

export const loadAvailableProducts = () => ({
  type: types.LOAD_AVAILABLE_PRODUCTS,
});

export const loadFeaturedProducts = () => ({
  type: types.LOAD_FEATURED_PRODUCTS,
});

export const hideOrderChangeMessage = () => ({
  type: types.HIDE_ORDER_CHANGE_MESSAGE,
});

export const resetProductsError = () => ({
  type: types.RESET_PRODUCTS_ERROR,
});
