import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button } from 'components/Ui';
import { arrowBackward, arrowForward } from 'images';
import { useMediaQuery } from '@material-ui/core';
import styles from './styles.scss';

const Navbar = ({
  handleForward,
  handleBackward,
  isCanBack,
  isCanForward,
  previousMonth,
  nextMonth,
  localeUtils = {},
  className,
  month,
  useMobileNavbar,
  narrowView,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const months = localeUtils.getMonths();
  const useMobileNav = useMobileNavbar && isMobile;
  const prevLabel = useMobileNav
    ? months[previousMonth.getMonth()]
    : 'Backward';
  const nextLabel = useMobileNav ? months[nextMonth.getMonth()] : 'Forward';

  return (
    <div
      className={classnames(className, {
        [styles.useMobileNavbar]: useMobileNavbar,
        [styles.narrowView]: narrowView,
      })}
    >
      {!useMobileNav || isCanBack ? (
        <Button
          className={classnames({ [styles.disabled]: !isCanBack })}
          text={narrowView ? '' : prevLabel}
          aria-label="Previous month"
          icon={
            (!useMobileNav || narrowView) && <img src={arrowBackward} alt="" />
          }
          onClick={handleBackward}
          iconPosition="left"
          disabled={!isCanBack}
          link
        />
      ) : (
        <div className={styles.emptyBlock} />
      )}
      <div className={styles.navCaption}>
        {moment(month).format('MMMM YYYY')}
      </div>
      {!useMobileNav || isCanForward ? (
        <Button
          className={classnames({ [styles.disabled]: !isCanForward })}
          aria-label="Next month"
          text={narrowView ? '' : nextLabel}
          icon={
            (!useMobileNav || narrowView) && <img src={arrowForward} alt="" />
          }
          onClick={handleForward}
          iconPosition="right"
          disabled={!isCanForward}
          link
        />
      ) : (
        <div className={styles.emptyBlock} />
      )}
    </div>
  );
};

Navbar.propTypes = {
  handleForward: PropTypes.func.isRequired,
  handleBackward: PropTypes.func.isRequired,
  isCanBack: PropTypes.bool.isRequired,
  isCanForward: PropTypes.bool.isRequired,
  useMobileNavbar: PropTypes.bool,
  narrowView: PropTypes.bool,
  previousMonth: PropTypes.object,
  nextMonth: PropTypes.object,
  localeUtils: PropTypes.object,
  className: PropTypes.string,
  month: PropTypes.instanceOf(Date),
};

Navbar.defaultProps = {
  previousMonth: new Date(),
  nextMonth: new Date(),
  useMobileNavbar: true,
  narrowView: true,
};

export default Navbar;
