import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import styles from './styles.scss';

const ProductsList = ({
  document: {
    fields: { background, title, products },
  },
}) => (
  <div
    className={styles.productsContainer}
    style={{
      backgroundImage: `url(${
        background && background.fields.file.url
      }?fm=webp)`,
      backgroundColor: '#fff',
    }}
  >
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.products}>
      {products &&
        products.map((product) => (
          <Item key={product.sys.id} document={product} />
        ))}
    </div>
  </div>
);

ProductsList.propTypes = {
  document: PropTypes.object,
};

export default ProductsList;
