import React from 'react';
import PropTypes from 'prop-types';
import { translateElementChildren } from 'utils/translation';

const Div = React.forwardRef((props, ref) => {
  const { children, ariaHidden, defaultText, translationKey, ...rest } = props;
  return (
    <div ref={ref} aria-hidden={ariaHidden} {...rest}>
      {translateElementChildren(children, translationKey, defaultText)}
    </div>
  );
});

Div.propTypes = {
  children: PropTypes.node.isRequired,
  ariaHidden: PropTypes.string,
  defaultText: PropTypes.string,
  translationKey: PropTypes.string,
};

export default Div;
