import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';

import { Container, Wrapper, Form, Input, Button } from 'components/Ui';
import Loader from 'components/Loader';
import { selectIsLoading } from 'containers/Landing/selectors';
import styles from 'containers/Authentication/styles.scss';

class ForgotEmail extends Component {
  state = {
    accountNumber: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {
      props: { loading },
      state: { accountNumber },
    } = this;

    return (
      <Loader loading={loading}>
        <Container value="Forgot Email" className={styles.container}>
          <Wrapper color="grey">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Form
                  className={styles.verifyForm}
                  title="Verify Account"
                  onError={this.showError}
                  onSubmit={this.handleSubmit}
                >
                  <p className={styles.info}>
                    Your customer account number is in the upper left-hand
                    corner of your invoice. If you can’t find your customer
                    account number, please call us at 1-800-pureflo.
                  </p>
                  <Input
                    label="Customer Account Number"
                    validators={['required']}
                    errorMessages={['']}
                    value={accountNumber}
                    onChange={this.handleChange}
                    name="accountNumber"
                  />
                  <Grid container alignItems="flex-end" direction="column">
                    <Button
                      className={styles.button}
                      text="Save Changes"
                      loading={false}
                      capitalize
                      style={{ minWidth: 176 }}
                    />
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </Wrapper>
        </Container>
      </Loader>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading(),
});

ForgotEmail.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ForgotEmail);
