import { takeEvery, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import { isSsr } from 'utils/ssrHelper';
import { fetchUserRegion } from 'containers/UserRegion/actions';
import { isCanadianBrand } from 'utils/translation';
import * as types from './constants';
import {
  fetchClientLocationSuccess,
  fetchClientLocationFailure,
} from './actions';
import { DEFAULT_USER_REGION } from '../UserRegion/constants';

export function* fetchClientLocation({ payload: { zip } }) {
  if (!isSsr()) {
    try {
      const { IPSTACK_HOST, IPSTACK_API_KEY } = window.SERVER_DATA;

      const response = yield call(
        axios.get,
        `${window.location.protocol}//${IPSTACK_HOST}`,
        { params: { access_key: IPSTACK_API_KEY } },
      );

      response.data = typeof response.data === 'string' ? {} : response.data;

      window.ipSniffFailed = !response.data.zip && !zip;
      response.data.zip = zip || response.data.zip;

      if (!response.data.zip) {
        response.data = {
          zip: DEFAULT_USER_REGION.postalCode,
          brand: DEFAULT_USER_REGION.brand,
          branch: DEFAULT_USER_REGION.branch,
          country_code: 'US',
        };
      }

      // TODO: If we can determine the brand soon enough then we can use it below
      // Even though the yield api call below does this, the UI does not work since rendering
      // occurs without the window.brandByZip being set soon enough
      const href = window.location.href.toLowerCase();
      const isCostcoWaterLinkPage =
        href.includes('costcowater-privacy') ||
        href.includes('costcowater-cookies') ||
        href.includes('costcowater-terms') ||
        href.includes('costcowater-casupplychain') ||
        href.includes('costcowater-ccpa');

      if (isCostcoWaterLinkPage) {
        window.brandByZip = 'primo';
      }

      // TODO: This is comment out for now and left here in case we find that this is needed
      // if (response.data.zip) {
      //   const windowIsDefined = !isSsr();
      //   const href = windowIsDefined ? window.location.href.toLowerCase() : '';
      //   const isDevelopment = href.includes('localhost');
      //   const isCostcoWater = href.includes('costcowater');
      //   if (windowIsDefined && (isDevelopment || isCostcoWater)) {
      //   const brand = yield call(apiCall, {
      //     operationId: 'brands.getBrandByZip',
      //     parameters: { postalCode: response.data.zip },
      //   });
      //   window.brandByZip = brand;
      //   }
      // }
      if (isCanadianBrand()) {
        response.data.country_code = 'CA';
      }

      yield put(fetchClientLocationSuccess(response));
      yield put(fetchUserRegion(response.data.zip));
    } catch (error) {
      yield put(fetchClientLocationFailure(error));
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(types.GET_CLIENT_LOCATION_REQUEST, fetchClientLocation),
  ]);
}
