import { createSelector } from 'reselect';
import {
  selectLocationBrandName,
  selectLocationBranchCode,
} from 'containers/PrimoAccount/selectors';
import {
  selectBrand as selectUserRegionBrand,
  selectBranch as selectUserRegionBranch,
} from 'containers/UserRegion/selectors';
import { getBrandIdByLegacyId } from './index';

/**
 * Select the acq brand or fallback to the self-service brand.
 * UserRegion state is only populated in acq, thus the fallback
 * to the location state, which is populated in self-service.
 */
export const selectBrand = () =>
  createSelector(
    selectUserRegionBrand(),
    selectLocationBrandName(),
    (acqRes, ssBrand) => {
      const acqBrand = acqRes && getBrandIdByLegacyId(acqRes);
      return acqBrand || ssBrand;
    },
  );

/**
 * Select the acq branch or fallback to the self-service branch.
 * UserRegion state is only populated in acq, thus the fallback
 * to the location state, which is populated in self-service.
 *
 * FIXME this probably should be moved to a folder more aligned
 * with location or branches specifically.
 */
export const selectBranch = () =>
  createSelector(
    selectUserRegionBranch(),
    selectLocationBranchCode(),
    (acqBranch, ssBranch) => acqBranch || ssBranch,
  );
