import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectRestorePasswordFetching } from 'containers/Authentication/selectors';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { Input, Button, Form, Divider } from 'components/Ui';
import { KeyGreen } from 'images';
import styles from './styles.scss';
import MUIStyles from './styles';

class ForgotPasswordForm extends PureComponent {
  state = {
    email: '',
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    this.props.onSubmit({ email: this.state.email });
  };

  render() {
    const {
      state: { email },
      props: { classes, onReset, isRestorePasswordFetching },
    } = this;

    return (
      <Form className={styles.container} onSubmit={this.handleSubmit}>
        <Grid container spacing={4} className={styles.form}>
          <Grid item xs={12} md={12}>
            <img className={styles.keyImage} src={KeyGreen} alt="key" />
            <h1 className={styles.title}>Forgot your password?</h1>
            <p className={styles.description}>
              It happens to the best of us. Please ensure the email address you
              entered is the one associated with your account.
            </p>
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              label="Email Address:"
              validators={['required', 'isEmail']}
              errorMessages={['', 'Email is not valid']}
              value={email}
              classes={classes}
              onChange={this.handleChange}
              name="email"
            />
          </Grid>
          <Divider className={styles.divider} />
          <Grid className={styles.buttons} item xs={12} md={12}>
            <Button
              text="Cancel"
              className={styles.submit}
              color="gray"
              disabled={isRestorePasswordFetching}
              type="button"
              onClick={onReset}
            />
            <Button
              text="Continue"
              className={styles.submit}
              loading={isRestorePasswordFetching}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  classes: PropTypes.arrayOf(PropTypes.string),
  isRestorePasswordFetching: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isRestorePasswordFetching: selectRestorePasswordFetching(),
});

export default compose(
  withStyles(MUIStyles),
  connect(mapStateToProps),
)(ForgotPasswordForm);
