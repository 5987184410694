import { ValidatorForm } from 'react-material-ui-form-validator';
import { defaultTo, isEmpty } from 'lodash';
import creditCardType from 'credit-card-type';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const SUPPORTED_CARD_TYPES = [
  'visa',
  'mastercard',
  'american-express',
  'discover',
];

/* eslint-disable */
const luhn10 = (a, b, c, d, e) => {
  for (d = +a[(b = a.length - 1)], e = 0; b--; )
    (c = +a[b]), (d += ++e % 2 ? ((2 * c) % 10) + (c > 4) : c);
  return !(d % 10);
};
/* eslint-enable */

const getCCNumber = (value) => defaultTo(value, '').replace(/[^0-9]/g, '');

export const isValidPassword = (value) => validatePassword(value).validPassword;

export const isValidCardNumber = (value) => {
  const ccNumber = getCCNumber(value);
  const validLength = ccNumber.startsWith('3') ? 15 : 16;
  const valid =
    ccNumber.length > 0
      ? ccNumber.length === validLength && luhn10(ccNumber)
      : true;
  const result = valid
    ? ccNumber.length === 1 || !isEmpty(creditCardType(value))
    : false;
  return result;
};

const isValidRoutingNumber = (value) => {
  const routingNumber = defaultTo(value, '').replace(/[^0-9]/g, '');
  if (routingNumber.length !== 9) {
    return false;
  }
  const checksumTotal =
    7 *
      (parseInt(routingNumber.charAt(0), 10) +
        parseInt(routingNumber.charAt(3), 10) +
        parseInt(routingNumber.charAt(6), 10)) +
    3 *
      (parseInt(routingNumber.charAt(1), 10) +
        parseInt(routingNumber.charAt(4), 10) +
        parseInt(routingNumber.charAt(7), 10)) +
    9 *
      (parseInt(routingNumber.charAt(2), 10) +
        parseInt(routingNumber.charAt(5), 10) +
        parseInt(routingNumber.charAt(8), 10));
  const checksumMod = checksumTotal % 10;
  return checksumMod === 0;
};

const isValidBankAccountNumber = (value) => {
  const bankAccountNumber = defaultTo(value, '').replace(/[^0-9]/g, '');
  return bankAccountNumber.length >= 4;
};

ValidatorForm.addValidationRule('phone', (value) => {
  const parsed = parsePhoneNumberFromString(value, 'US');
  return parsed && parsed.isValid();
});

ValidatorForm.addValidationRule('zip', (value) => {
  const zipValue =
    /^[0-9]{5}(?:-[0-9]{4})?$/.test(value) ||
    /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(value);
  return zipValue;
});

ValidatorForm.addValidationRule('routingNumber', (value) =>
  isValidRoutingNumber(value),
);

ValidatorForm.addValidationRule('bankAccountNumber', (value) =>
  isValidBankAccountNumber(value),
);

ValidatorForm.addValidationRule('creditCard', (value) =>
  isValidCardNumber(value),
);

ValidatorForm.addValidationRule('password', (value) => isValidPassword(value));

ValidatorForm.addValidationRule('creditCardType', (value) => {
  const ccNumber = getCCNumber(value);
  const cardTypes = creditCardType(ccNumber);
  return (
    ccNumber.length === 1 ||
    cardTypes.some((card) => SUPPORTED_CARD_TYPES.includes(card.type))
  );
});

ValidatorForm.addValidationRule('number', (value) => /^\d+$/.test(value));

ValidatorForm.addValidationRule('floatNumber', (value) =>
  /^-?\d*(\.\d+)?$/.test(value),
);

ValidatorForm.addValidationRule('state', (value) => {
  const states = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];
  return states.includes(value.toUpperCase());
});

export const validatePassword = (p) => {
  const result = {
    hasAtLeast8Chars: true,
    // hasAtMost32Chars: true,
    hasAtLeastOneLowerCase: true,
    hasAtLeastOneUpperCase: true,
    hasAtLeastOneDigit: true,
    hasAtLeastOneSpecialChar: true,
    hasMixofLettersNumbers: true,
  };
  const errors = [];
  let lettersNumberCount = 0;
  if (p.length < 8) {
    result.hasAtLeast8Chars = false;
    errors.push('Your password must be at least 8 characters');
  }
  // if (p.length > 32) {
  //   result.hasAtMost32Chars = false;
  //   errors.push('Your password must be at max 32 characters');
  // }
  if (p.search(/[a-z]/) < 0) {
    result.hasAtLeastOneLowerCase = false;
    errors.push('Your password must contain at least one lower case letter.');
  } else {
    lettersNumberCount += 1;
  }
  if (p.search(/[A-Z]/) < 0) {
    result.hasAtLeastOneUpperCase = false;
    errors.push('Your password must contain at least one upper case letter.');
  } else {
    lettersNumberCount += 1;
  }

  if (p.search(/[0-9]/) < 0) {
    result.hasAtLeastOneDigit = false;
    errors.push('Your password must contain at least one digit.');
  } else {
    lettersNumberCount += 1;
  }
  // eslint-disable-next-line no-useless-escape
  if (p.search(/[!@#\$%\^&\*]/) < 0) {
    errors.push(
      'Your password must contain at least special char from -[ ! @ # $ % ^ & * ]',
    );
    result.hasAtLeastOneSpecialChar = false;
  } else {
    lettersNumberCount += 1;
  }
  result.hasMixofLettersNumbers = lettersNumberCount > 2;
  result.validPassword =
    result.hasAtLeast8Chars && result.hasMixofLettersNumbers;
  return result;
};
