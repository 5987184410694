import { get, entries, forEach, isEmpty, isString, isArray } from 'lodash';
import {
  isAcquisitionDomain,
  isSelfServeDomain,
  getRegionalBrandName,
  getDomainByHost,
  getBrandName,
} from 'utils/domainHelper';
import { isBrowser } from 'utils/ssrHelper';

import { startsWithIgnoreCase } from 'utils/common';

export const EN_US = 'en-US';
export const EN_CA = 'en-CA';
export const FR_CA = 'fr-CA';

export const CA_EN_URL = 'ca-en';
export const CA_FR_URL = 'ca-fr';
export const CA_EN_URL_PREFIX = `/${CA_EN_URL}`;
export const CA_FR_URL_PREFIX = `/${CA_FR_URL}`;

export const SUPPORTED_LANGUAGES = [EN_US, EN_CA, FR_CA];

export const localeToURL = { EN_CA: CA_EN_URL, FR_CA: CA_FR_URL };

export const getUrlFromLocale = (locale, defaultUrl) => {
  const url = localeToURL[locale];
  return url || defaultUrl;
};

const dontTranslate = (data) => {
  if (data === '{deliveryFeeInfo}') {
    return false;
  }
  return !(!!data || isAcquisitionDomain() || isSelfServeDomain());
};

const translateOrDefault = (resouceBundle, text, defaultText) => {
  const found = resouceBundle[text.trim()];
  if (found) {
    return replaceAllVariables(found);
  }
  const showWrapText = isBrowser() ? window.PrimoShowWrapText : false;
  if (showWrapText) {
    // eslint-disable-next-line no-console
    console.log('~ translate NotFound >>>', `>${text}<`);
  }
  if (defaultText.startsWith('DT:')) {
    return replaceAllVariables(defaultText.slice(3));
  }
  // eslint-disable-next-line no-console
  // console.log('~ translate NotFound >>>', `|${text}|`);
  return text;
};

const globallyReplaceSpecific = (text) =>
  text.replace(
    /(an )?equipments?|coolers?|équipements?|refroidisseurs?/gi,
    (match) => {
      switch (match) {
        case 'equipment':
        case 'cooler':
          return 'dispenser';
        case 'an equipment':
          return 'a dispenser';
        case 'Equipment':
        case 'Cooler':
          return 'Dispenser';
        case 'equipments':
        case 'coolers':
          return 'dispensers';
        case 'Equipments':
        case 'Coolers':
          return 'Dispensers';
        case 'équipement':
        case 'refroidisseur':
          return 'distributeur';
        case 'Équipement':
        case 'Refroidisseur':
          return 'Distributeur';
        case 'équipements':
        case 'refroidisseurs':
          return 'distributeurs';
        case 'Équipements':
        case 'Refroidisseurs':
          return 'Distributeurs';
        default:
          return 'dispenser';
      }
    },
  );

const translate = (text, defaultText) => {
  if (!isString(text)) {
    return text;
  }

  if (isBrowser()) {
    if (!window.PrimoVariables) {
      return text;
    }
    if (!window.PrimoTextToTranslate) {
      window.PrimoTextToTranslate = [];
    }
    if (
      text === '' ||
      text === '.' ||
      text === '1.' ||
      text === '2.' ||
      text === '3.' ||
      text === '4.' ||
      text === '5.' ||
      text === ' |' ||
      text === ' | ' ||
      text === 'EN' ||
      text === 'FR' ||
      text.startsWith('http')
    ) {
      return text;
    }
    const inPrimoTextToTranslate = window.PrimoTextToTranslate.find(
      (item) => item === text,
    );
    if (!inPrimoTextToTranslate) {
      // Push the text onto the array so that we can gather all the strings that need to be translated
      window.PrimoTextToTranslate.push(text);
    }
    const locale = getLocale();
    window.PrimoLocale = locale;
    const resouceBundle = locale.startsWith(FR_CA)
      ? get(window, 'canadaFrenchResourceBundle', [])
      : locale.startsWith(EN_CA)
      ? get(window, 'canadaEnglishResourceBundle', [])
      : get(window, 'usEnglishResourceBundle', []);
    window.PrimoResouceBundle = resouceBundle;
    const found = isString(text)
      ? translateOrDefault(resouceBundle, text.trim(), defaultText)
      : text;
    return globallyReplaceSpecific(found) || text;
  }
  return text;
};

const translateArray = (data, defaultText) => {
  if (dontTranslate(data)) {
    return data;
  }
  const translatedData = [];
  if (isArray(data)) {
    forEach(data, (item) => {
      const translated = translate(item, defaultText);
      const replaced = isString(translated)
        ? replaceAllVariables(translated)
        : translated;
      translatedData.push(wrapText(replaced, defaultText));
    });
  } else {
    const translated = translate(data, defaultText);
    const replaced = isString(translated)
      ? replaceAllVariables(translated)
      : translated;
    translatedData.push(wrapText(replaced, defaultText));
  }
  return translatedData;
};

const wrapText = (text, defaultText) => {
  const showWrapText = isBrowser() ? window.PrimoShowWrapText : false;
  if (!showWrapText) {
    return text;
  }
  if (text === '' || text === ' ') {
    return text;
  }
  const wrap = text && text.length > 0 && text !== ' | ';
  if (wrap) {
    if (defaultText === 'T') {
      // return `~${defaultText} ${text}~`;
      return `~${text}~`;
    }
    // return `|${defaultText.slice(1)} ${text}|`;
    return `|${text}|`;
  }
  return text;
};

const translateText = (data, variables, theBrandName, defaultText) => {
  if (dontTranslate(data)) {
    return data;
  }
  if (isString(data) && data.trim().length > 0) {
    const translated = data.startsWith('|') || data.startsWith('~');
    return translated
      ? data
      : wrapText(
          replaceAllVariables(
            translate(data, defaultText),
            variables,
            theBrandName,
          ),
          defaultText,
        );
  }
  return data;
};

export const replaceAllVariables = (data, variables, brandName) => {
  // Can be used by Costco, Acquisition, and Self Serve
  if (data) {
    let theBrandName;
    let theVariables;
    if (isBrowser()) {
      if (window.PrimoVariables) {
        theBrandName = window.PrimoBrandName;
        theVariables = window.PrimoVariables;
      } else {
        return data;
      }
    } else {
      theBrandName = brandName;
      theVariables = variables;
    }
    // Need to look at this for costco
    if (!theBrandName) {
      return data;
    }
    let replacedData = data.replace(new RegExp('{brand}', 'g'), theBrandName);
    if (!theVariables) {
      return replacedData;
    }
    forEach(theVariables, (item) => {
      replacedData = replacedData.replace(
        new RegExp(item.name, 'g'),
        item.value,
      );
    });
    if (replacedData.startsWith('ErrorCode')) {
      // The 'ErrorCode:some_error_code' was not found, use the defaultErrorMessage
      const translatedText = translate(
        'defaultErrorMessage',
        'DT:Sorry, we seem to be having some trouble. Please call us at {customerServiceNumber} so we can assist you. Thank you!',
        true,
      );
      return translatedText;
    }
    return replacedData;
  }
  return null;
};

export const translateData = (data, defaultText = 'T') => {
  // Note: remember to prefix defaultText with 'DT:' to to return the text after 'DT:' if data not found
  if (dontTranslate(data)) {
    return data;
  }
  if (isArray(data)) {
    const text = get(data, '[0]props.children');
    if (isString(text) && !isEmpty(text)) {
      return [
        {
          ...data[0],
          props: { children: translateText(text, null, null, defaultText) },
        },
      ];
    }
    return translateArray(data, defaultText);
  }
  if (isString(data)) {
    return translateText(data, null, null, defaultText);
  }
  return data;
};

export const translateChildren = (children, defaultText) => {
  if (dontTranslate(children)) {
    return children;
  }
  let translationNeeded = false;
  if (isArray(children)) {
    // translationNeeded = children.length === 1 && children[0].length > 0;
    translationNeeded = true;
  }
  if (isString(children)) {
    translationNeeded = children.trim().length > 0;
  }
  // translationNeeded = isString(children)
  //   ? children.trim().length > 0
  //   : translationNeeded;
  return translationNeeded ? translateData(children, defaultText) : children;
};

export const translateElementChildren = (
  children,
  translationKey,
  defaultText,
) => {
  const translatedText = translateChildren(
    translationKey || children,
    defaultText,
  );
  return translatedText === translationKey ? children : translatedText;
};

export const translateLink = (data, defaultText) => {
  if (dontTranslate(data)) {
    return data;
  }
  let translatedData;
  if (isArray(data)) {
    translatedData = wrapText(translate(data[0], defaultText), defaultText);
  } else if (isString(data)) {
    translatedData = wrapText(translate(data, defaultText), defaultText);
  } else {
    translatedData = data;
  }
  return translatedData;
};

const translateMap = ([k, v]) => translateData(k, `DT:${translateData(v)}`);

/**
 * Translates a map of strings where the object literal represents the translation key and
 * the default text if no maching translations found.
 *
 * @example
 * ```jsonc
 * // Translation JSON Data.
 * {
 *   "Confirmation_Message": "Terms and conditions may apply. Please confirm to proceed.",
 * }
 * ```
 *
 * // Call to translation.
 * const toTranslate = {
 *   Confirmation_Title: 'Confirm', // No maching translation.
 *   Confirmation_Message: 'Terms and conditions message goes here.',
 * };
 * console.log(translateValues(toTranslate));
 *
 * // Output:
 * {
 *   Confirmation_Title: 'Confirm',
 *   Confirmation_Message: 'Terms and conditions may apply. Please confirm to proceed.',
 * }
 *
 * @param {Object} mapOfStrings A map of strings representing the keys and the default values.
 * @returns {Object} Translated map of strings.
 */
export const translateValues = (mapOfStrings) =>
  entries(mapOfStrings).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: translateMap([k, v]),
    }),
    {},
  );

export const injectVariables = (data, brandName, variables, defaultText) => {
  if (defaultText === 'dontTranslate' || dontTranslate(data)) {
    return data;
  }
  if (isBrowser()) {
    if (brandName) {
      window.PrimoBrandName = brandName;
      window.PrimoVariables =
        variables && variables.length > 0
          ? variables
          : window.PrimoVariables || [];
    }
  }
  let dataInjected = data;
  const theBrandName = brandName || getRegionalBrandName(null, 11);

  if (isArray(dataInjected)) {
    const dataItems = [];
    forEach(dataInjected, (item) => {
      if (isString(item)) {
        dataItems.push(
          translateText(item, variables, theBrandName, defaultText),
        );
      } else if (isArray(get(item, 'props.children'))) {
        item.props.children[0] = translateText(
          item.props.children[0],
          variables,
          theBrandName,
          defaultText,
        );
        dataItems.push(item);
      } else {
        dataItems.push(item);
      }
    });
    dataInjected = dataItems;
  } else if (isString(dataInjected)) {
    dataInjected = translateText(
      dataInjected,
      variables,
      theBrandName,
      defaultText,
    );
  } else {
    dataInjected = translateText(dataInjected, null, null, defaultText);
  }
  return dataInjected;
};

export const getPrimoBrand = (def = '') => {
  let result = def;
  if (isBrowser()) {
    result = window.PrimoBrand;
  } else if (typeof global === 'object') {
    result = global.PrimoBrand;
  }
  return result;
};

const getPrimoPathname = (def = '/') => {
  let result = def;
  if (isBrowser()) {
    result = get(window, 'location.pathname', '');
  } else if (typeof global === 'object') {
    result = global.PrimoPathname;
  }
  return result;
};

const getPrimoLanguage = (def = EN_US) => {
  let result = def;
  if (isBrowser()) {
    result = window.PrimoLanguage;
  } else if (typeof global === 'object') {
    result = global.PrimoLanguage;
  }
  return result;
};

export const getPrimoBrandName = () => {
  let primoBrand = getPrimoBrand();
  if (primoBrand === 'localhost') {
    primoBrand = getDomainByHost(38).brand;
  }
  return getBrandName(primoBrand);
};

export const isCanadianBrand = () => {
  // lll Language isCanadianBrand
  if (isAcquisitionDomain() || isSelfServeDomain()) {
    let primoBrand = getPrimoBrand();
    if (primoBrand === 'localhost') {
      const domain = getDomainByHost(39);
      primoBrand = domain.brand;
    }
    primoBrand = primoBrand || '';
    const result =
      primoBrand.includes('labradorsource') ||
      primoBrand.includes('aquaterracorp') ||
      primoBrand.includes('canadiansprings');
    return result;
  }
  return false;
};

export const getLocale = () => {
  // lll Language getLocale
  const isCanadian = isCanadianBrand();
  const isAcquisition = isAcquisitionDomain();
  const isSelfServe = isSelfServeDomain();
  const theDefaultEnglish = isCanadian ? EN_CA : EN_US;

  if (!isCanadian || !(isAcquisition || isSelfServe)) {
    // If not Canada, then US Brand
    return EN_US;
  }

  // Acquisition Canadian brand
  // 1. Use language in URL
  // 3. Use browser default language
  // 4. Use en-CA

  if (isAcquisition) {
    const PrimoPathname = getPrimoPathname();

    // 1. Use language in URL
    if (startsWithIgnoreCase(CA_EN_URL_PREFIX, PrimoPathname)) {
      return theDefaultEnglish;
    }
    if (startsWithIgnoreCase(CA_FR_URL_PREFIX, PrimoPathname)) {
      return FR_CA;
    }
  }

  // Self Serve Canadian brand
  // 2. Use language in Local Storage
  // 3. Use browser default language
  // 4. Use en-CA

  if (isSelfServe && isBrowser()) {
    // 2. Use language in Local Storage
    const language = localStorage.getItem('language');
    const result =
      language === CA_FR_URL ? FR_CA : language === CA_EN_URL ? EN_CA : null;
    if (result) {
      return result;
    }
  }

  const PrimoLanguage = getPrimoLanguage(theDefaultEnglish);

  // 3. Use browser default language
  // If browser locale is set to an English locale, return theDefaultEnglish
  if (PrimoLanguage === EN_CA || PrimoLanguage === EN_US) {
    return theDefaultEnglish;
  }

  return PrimoLanguage;
};

// eslint-disable-next-line no-unused-vars
export const getToUrl = (to, test) => {
  // 1. If not Canada brand, dont change url
  if (isSelfServeDomain() || !isCanadianBrand() || !to) {
    return to;
  }

  // 2. If URL already begins with lang, dont change URL
  if (
    startsWithIgnoreCase(CA_EN_URL_PREFIX, to) ||
    startsWithIgnoreCase(CA_FR_URL_PREFIX, to)
  ) {
    return to;
  }

  // 3. If pathname already begins with lang, change URL
  const PrimoPathname = getPrimoPathname();
  if (startsWithIgnoreCase(CA_EN_URL_PREFIX, PrimoPathname)) {
    return `${CA_EN_URL_PREFIX}${to}`;
  }
  if (startsWithIgnoreCase(CA_FR_URL_PREFIX, PrimoPathname)) {
    return `${CA_FR_URL_PREFIX}${to}`;
  }

  // 4. If browser lang is set to en-CA or fr-CA, change URL
  const locale = getLocale();
  let prefix = '';
  if (locale === FR_CA) {
    prefix = CA_FR_URL_PREFIX;
  } else if (locale === EN_CA) {
    prefix = CA_EN_URL_PREFIX;
  }
  return `${prefix}${to}`;
};

export const getRedirectPathname = (pathname) => {
  const hasLangPrefix =
    pathname.startsWith(CA_EN_URL_PREFIX) ||
    pathname.startsWith(CA_FR_URL_PREFIX);

  if (isSelfServeDomain()) return null;

  if (isCanadianBrand()) {
    if (hasLangPrefix) return null;
    const prefix = getLocale() === FR_CA ? CA_FR_URL_PREFIX : CA_EN_URL_PREFIX;
    return pathname === '/' ? `${prefix}/home` : `${prefix}${pathname}`;
  }

  if (hasLangPrefix) return pathname.slice(6);

  return null;
};

export const isCanadianFrench = () => {
  if (isCanadianBrand()) {
    return getLocale() === FR_CA;
  }
  return false;
};

export const getCurrencyCode = () => (isCanadianBrand() ? 'CAD' : 'USD');
