import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import DeliveryInformation from 'containers/PrimoCheckoutPage/DeliveryInfomation/index';
import { useSelector } from 'react-redux';
import { selectCheckoutErrors } from 'containers/CheckoutPage/selectors';
import { H2 } from 'components/Ui';
import styles from './styles.scss';

const DeliveryInformationDrawer = ({
  open,
  toggle,
  values,
  isDrawer = false,
}) => {
  const form = useRef();
  const errors = useSelector(selectCheckoutErrors()) || {};

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggle}
      PaperProps={{ className: styles.drawer }}
      classes={{ root: styles.drawer }}
      disableEnforceFocus
    >
      <H2>Edit delivery information</H2>
      <DeliveryInformation
        title="Edit payment method"
        forwardedRef={form}
        values={values}
        changeSubmitAction={() => {}}
        errors={errors}
        drawerToggle={toggle}
        isDrawer={isDrawer}
      />
    </Drawer>
  );
};

DeliveryInformationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  values: PropTypes.shape({
    id: PropTypes.string,
    address1: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    stateOrProvinceCode: PropTypes.string,
  }),
  isDrawer: PropTypes.bool,
};

export default DeliveryInformationDrawer;
