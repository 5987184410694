import findIndex from 'lodash/findIndex';
import { getBrandTagsDEPREC } from 'components/Brands';

/**
 * TODO delete this file
 * utils might as well be called junk or catch-all
 * move tag func to ./byTag.js
 * move brand func to ./byBrand.js
 */

/**
 * @param {Object} c - a content object
 * @param {string|string[]} tags - a tag id or array of tag ids
 * @return {boolean} true if the given content object has any of the given tags
 */
export const hasTag = (c, tags) => {
  tags = Array.isArray(tags) ? tags : [tags];
  return findIndex(c?.metadata?.tags, (t) => tags.includes(t.sys.id)) > -1;
};

/** ** ** **
 * START DEPRECATED
 ** ** ** */

export const hasBrandTag = (c, brand) => hasTag(c, getBrandTagsDEPREC(brand));

/** ** ** **
 * END DEPRECATED
 * The code above is deprecated and should no longer be used.
 * Its dependencies rely on the hostname to determine the brand.
 ** ** ** */
