import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeConfig } from 'theme/themeConfig';

const Message = styled.div`
  color: ${(props) =>
    props.success ? themeConfig.colors.primaryGreen : themeConfig.colors.red};
  font-size: 0.75rem;
  line-height: 1.4;
  min-height: 16px;
  padding-top: 2px;
`;

export const SuccessMessage = ({ children, style }) => (
  <Message success style={style}>
    {children}
  </Message>
);

export const ErrorMessage = ({ children, style }) => (
  <Message error style={style}>
    {children}
  </Message>
);

ErrorMessage.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

SuccessMessage.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
