import React from 'react';
import { SvgIcon } from '@material-ui/core';

const SearchIcon = (props) => (
  <SvgIcon viewBox="0 0 12 12" width="12px" height="12px" {...props}>
    <g
      id="FAQs_HO"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="2_3.5.3_SPK_FAQs_HO-DT_1440-"
        transform="translate(-441.000000, -309.000000)"
        fill="#009CDE"
      >
        <path
          d="M446.202909,310.501411 C448.269207,310.501411 449.919272,312.151476 449.919272,314.217774 C449.919272,315.198894 449.562501,316.105687 448.938152,316.774632 C448.923287,316.789498 448.908421,316.804363 448.893556,316.819229 C448.823688,316.877204 448.759767,316.942612 448.700305,317.01248 C448.046225,317.607098 447.154298,317.949003 446.188043,317.949003 C444.121745,317.949003 442.47168,316.298938 442.47168,314.23264 C442.47168,312.166342 444.121745,310.516276 446.188043,310.516276 L446.202909,310.501411 Z M446.202909,309.014865 C443.333876,309.014865 441,311.348742 441,314.217774 C441,317.086807 443.333876,319.420683 446.202909,319.420683 C447.079971,319.420683 447.942167,319.212567 448.670574,318.8112 C448.728549,318.882554 448.793957,318.946475 448.863825,319.00445 L450.35037,320.490996 C450.906338,321.116831 451.86516,321.17332 452.490996,320.617352 C453.116831,320.061384 453.17332,319.102562 452.615866,318.476727 C452.577215,318.43213 452.535592,318.390507 452.490996,318.35037 L451.00445,316.863825 C450.933096,316.790984 450.852823,316.725576 450.766603,316.670574 C451.16797,315.942167 451.420683,315.094836 451.420683,314.202909 C451.420683,311.333876 449.086807,309 446.217774,309 L446.202909,309.014865 Z"
          id="icon_search_sm"
        ></path>
      </g>
    </g>
  </SvgIcon>
);

export default SearchIcon;
