import React from 'react';
import PropTypes from 'prop-types';
import { ProcessingGreen } from 'images';
import styles from './styles.scss';

const ProcessingBlock = ({ text = 'Processing...' }) => (
  <div className={styles.container}>
    <img src={ProcessingGreen} alt="processing" className={styles.spin} />
    <div>{text}</div>
  </div>
);

ProcessingBlock.propTypes = {
  text: PropTypes.string,
};

export default ProcessingBlock;
