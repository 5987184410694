import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Mobile, Default } from 'components/Media';
import Button from 'components/ContentfulFields/Button';
import { Container } from 'components/Ui';
import Logo from 'components/ContentfulFields/Logo';
import Burger from '../../Burger';
import styles from './styles.scss';

class Navigation extends React.PureComponent {
  render() {
    const { fields, logoFields, match, location } = this.props;

    const options = {
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node, children) => (
          <Button className={styles.btn} document={node.data.target}>
            {children}
          </Button>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => children,
        [INLINES.HYPERLINK]: (node, children) => {
          const {
            data: { uri: url },
          } = node;
          const isActive = url === location.pathname;

          return (
            <Link
              className={classnames(styles.link, isActive && styles.active)}
              to={url}
            >
              {children}
            </Link>
          );
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          const { fields: nodeFields } = node.data.target;
          const url = `/${nodeFields.slug}`;
          const isActive = url === location.pathname;

          return (
            <Link
              className={classnames(styles.link, isActive && styles.active)}
              to={url}
            >
              {children}
            </Link>
          );
        },
      },
    };

    const navigation = documentToReactComponents(fields, options);

    return (
      <Container value="Navigation">
        <div className={styles.navigation}>
          <Logo fields={logoFields} className={styles.logo} />
          <Default>
            <nav className={styles.navbar}>{navigation}</nav>
          </Default>
          <Mobile>
            <Burger fields={fields} match={match} />
          </Mobile>
        </div>
      </Container>
    );
  }
}

Navigation.propTypes = {
  fields: PropTypes.object,
  logoFields: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

Navigation.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default withRouter(Navigation);
