import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import {
  CheckedIcon,
  UncheckedIcon,
  HugeCheckbox,
} from 'components/Ui/CustomIcons/Checkbox';
import { isWaterRegionalTheme } from 'utils/common';
import { translateData } from 'utils/translation';

const styles = (theme) => ({
  labelRoot: {
    margin: 0,
    fontFamily: theme.fonts.benton,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: isWaterRegionalTheme(theme.themeName) ? '1rem' : '0.875rem',
  },
  root: {
    padding: '0 5px 0 0',
    '&$checked': {
      color: theme.colors.primaryBlue,
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  checked: {},
});

const FormCheckbox = (
  { checked, label, onChange, classes, className, size, name },
  { stylesTheme },
) => {
  const getIcon = () => {
    if (size === 'huge') {
      return <HugeCheckbox checked={checked} />;
    }

    if (isWaterRegionalTheme(stylesTheme)) {
      return <UncheckedIcon height="15px" width="15px" />;
    }

    return <CheckBoxOutlineBlank />;
  };

  const getCheckedIcon = () => {
    if (size === 'huge') {
      return <HugeCheckbox checked={checked} />;
    }

    if (isWaterRegionalTheme(stylesTheme)) {
      return <CheckedIcon height="15px" width="15px" />;
    }

    return <CheckBox />;
  };

  return (
    <FormControlLabel
      className={className}
      classes={{ root: classes.labelRoot, label: classes.label }}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          disableRipple
          icon={getIcon()}
          checkedIcon={getCheckedIcon()}
          color="primary"
          classes={{
            root: classes.root,
            checked: classes.checked,
          }}
          name={name}
        />
      }
      label={translateData(label)}
    />
  );
};

FormCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['huge', 'default']),
};

FormCheckbox.defaultProps = {
  className: null,
  size: 'default',
};

FormCheckbox.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default withStyles(styles)(FormCheckbox);
