import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get, isEmpty } from 'lodash';
import uuid from 'uuid/v4';
import classnames from 'classnames';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { getRegionalBrandName } from 'utils/domainHelper';
import { injectVariables } from 'utils/translation';
import {
  selectAcquisitionVariables,
  selectSsrReqRoute,
} from 'containers/Landing/selectors';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import Link from 'components/ContentfulFields/Link';
import FormsMapper from 'components/ContentfulFields/FormsMapper';
import { showCostcoMembershipDialog } from 'containers/Landing/actions';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import { useMediaQuery } from '@material-ui/core';
import { isSsr } from 'utils/ssrHelper';
import { Section } from 'components/Ui';
import Button from '../Button';
import UlItem from '../UlItem';
import Banner from '../Banner';
import Counter from '../Counter';

const NonServicablePartnerBanner = ({
  document,
  stylesTheme,
  entryTitle,
  showCostcoMembership,
  acquisitionVariables,
  ssrReqRoute,
}) => {
  const styles = require(`./${stylesTheme}.scss`);

  const regionalBrandName = getRegionalBrandName(ssrReqRoute, 1);

  const isTabletBelow = useMediaQuery('(max-width:1024px)');

  const onClickStartOrder = () => showCostcoMembership();
  const isServersideRunning = isSsr();
  const options = {
    renderMark: {
      [MARKS.CODE]: (node) => <sub>{node}</sub>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'button':
            if (
              !isServersideRunning &&
              window.isCostcoWater &&
              node.data.target.fields.text === 'Start Order'
            ) {
              delete node.data.target.fields.link;
              return (
                <Button
                  className={styles.btn}
                  document={node.data.target}
                  onClick={onClickStartOrder}
                >
                  {children}
                </Button>
              );
            }
            return (
              <Button
                className={classnames(styles.btn)}
                document={node.data.target}
              >
                {children}
              </Button>
            );
          case 'banner':
            return (
              <Banner
                stylesTheme={stylesTheme}
                document={node.data.target}
                entryTitle={entryTitle}
                key={node.data.target.sys.id}
                showCostcoMembership={showCostcoMembership}
                acquisitionVariables={acquisitionVariables}
              />
            );
          default:
            return null;
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        if (embedded) {
          return (
            <div>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C30',
              )}
            </div>
          );
        }

        const childrenWithContent = children.filter(
          (childContent) => childContent !== '',
        );

        if (isEmpty(childrenWithContent)) return null;

        return (
          <div className={setTheme(styles.subtitle, document, styles)}>
            {childrenWithContent.map(
              (child) =>
                child &&
                child
                  .split('\n')
                  .filter((textRow) => textRow.length > 0)
                  .map((textRow) => (
                    <div key={uuid()}>
                      {injectVariables(
                        textRow,
                        regionalBrandName,
                        acquisitionVariables,
                        'C31',
                      )}
                    </div>
                  )),
            )}
          </div>
        );
      },
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className={styles.quote}>{children}</blockquote>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={setTheme(styles.title, document, styles)}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className={setTheme(styles.title, document, styles)}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={setTheme(styles.title, document, styles)}>{children}</h3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol type="a" className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ol>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'ulItem':
            return <UlItem {...node.data.target} />;
          case 'button':
            if (
              !isServersideRunning &&
              window.isCostcoWater &&
              node.data.target.fields.text === 'Start Order'
            ) {
              delete node.data.target.fields.link;
              return (
                <Button
                  className={styles.btn}
                  document={node.data.target}
                  onClick={onClickStartOrder}
                >
                  {children}
                </Button>
              );
            }
            return (
              <Button className={styles.btn} document={node.data.target}>
                {children}
              </Button>
            );
          case 'form':
            return <FormsMapper type={node.data.target.fields.type} />;
          case 'counter':
            return (
              <Counter className={styles.counter} document={node.data.target} />
            );
          case 'sprawlingImage':
            return <Fragment />;
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: linkParser,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        return (
          <Link className={styles.link} to={`/${nodeFields.slug}`}>
            {children}
          </Link>
        );
      },
    },
  };

  const { content } = document.fields.content;
  const header = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: [content[0]],
    },
    options,
  );
  const subText1 = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: [content[1]],
    },
    options,
  );
  const subText2 = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: [content[2]],
    },
    options,
  );
  const subText3 =
    content.length > 3
      ? documentToReactComponents(
          document && {
            data: {},
            nodeType: 'document',
            content: [content[3]],
          },
          options,
        )
      : null;
  const partnerImage = null;

  const bottomContent = document.fields.bottomContent.content;
  const phone = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: [bottomContent[0]],
    },
    options,
  );
  const email = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: [bottomContent[1]],
    },
    options,
  );

  const emailElement = get(email, '[0].props.children[0]');
  const hasEmail = Array.isArray(emailElement) && emailElement.length > 0;

  return (
    document && (
      <Section
        aria-label="bottom banner"
        className={styles.nonServicablePartnerBanner}
      >
        {isTabletBelow && <div className={styles.right}> {partnerImage}</div>}
        <Section className={styles.left}>
          {header}
          <div className={styles.subText}>{subText1}</div>
          <div className={styles.subText}>{subText2}</div>
          <div className={styles.subText}>{subText3}</div>
          <Section
            className={classnames(styles.contactContainer, {
              [styles.contactContainerNoEmail]: !hasEmail,
            })}
            aria-label="contact information"
          >
            <Section
              className={styles.contactPhone}
              aria-label="contact by phone"
            >
              <div className={styles.icon}>
                <SmartphoneIcon />
              </div>
              <div className={styles.text}>
                <a href={`tel:+${phone}`}>{phone}</a>
              </div>
            </Section>
            {hasEmail && (
              <Section
                className={styles.contactEmail}
                aria-label="contact by email"
              >
                <div className={styles.icon}>
                  <EmailIcon />
                </div>
                <div className={styles.text}>{email}</div>
              </Section>
            )}
          </Section>
        </Section>
        {!isTabletBelow && (
          <Section className={styles.right} aria-label="partner image">
            {' '}
            {partnerImage}
          </Section>
        )}
      </Section>
    )
  );
};

NonServicablePartnerBanner.propTypes = {
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
  entryTitle: PropTypes.string,
  showCostcoMembership: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  acquisitionVariables: selectAcquisitionVariables(),
  ssrReqRoute: selectSsrReqRoute(),
});

const mapDispatchToProps = {
  showCostcoMembership: showCostcoMembershipDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NonServicablePartnerBanner);
