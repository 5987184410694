import { Map, List } from 'immutable';
import { createSelector } from 'reselect';

import { CART_PAGE_KEY } from 'containers/Cart/constants';
import {
  PRIMARY_CONTACT_FORM,
  DELIVERY_AND_INSTALLATION_LOCATION_FORM,
  DELIVERY_SETUP_FORM,
  CREDIT_CARD_FORM,
  BILLING_ADDRESS_FORM,
} from 'containers/CheckoutPage/constants';
import { isRequiredFieldsFilled } from 'containers/CheckoutPage/utils';
import { isPurefloDomain } from 'utils/domainHelper';

const selectCart = () => (state) => state.get(CART_PAGE_KEY, Map());

export const selectCartData = () =>
  createSelector(selectCart(), (cart) => cart.get('data').toJS());

export const selectItemsCount = () =>
  createSelector(selectCart(), (cart) => {
    const cartData = cart.get('data').toJS();

    return cartData.items.length > 0
      ? cartData.items
          .map((item) => item.quantity)
          .reduce((count, current) => count + current)
      : 0;
  });

export const selectCartPostalCode = () =>
  createSelector(selectCart(), (cart) => cart.getIn(['data', 'postalCode']));

export const selectCartId = () =>
  createSelector(selectCart(), (cart) => cart.getIn(['data', 'id']));

export const selectMemberId = () =>
  createSelector(selectCart(), (cart) =>
    cart.getIn(['data', 'partnerMemberId']),
  );

export const selectCartSecret = () =>
  createSelector(selectCart(), (cart) => cart.getIn(['data', 'secretId']));

export const selectCartLoading = () =>
  createSelector(selectCart(), (cart) => cart.get('loading'));

export const selectPromotionLoading = () =>
  createSelector(selectCart(), (cart) => cart.get('isPromotionLoading'));

export const selectCartProductsLoading = () =>
  createSelector(selectCart(), (cart) => cart.get('isCartProductsLoading'));

export const cartExists = () =>
  createSelector(selectCart(), (cart) => !!cart.getIn(['data', 'id']));

export const selectCartIsLoaded = () =>
  createSelector(selectCart(), (cart) => cart.get('isCartLoaded'));

export const selectIsContactsFilled = () =>
  createSelector(selectCartData(), (cartData) => {
    const isPureflo = isPurefloDomain();
    const contactRequiredFields = isPureflo
      ? ['firstName', 'lastName', 'phoneNumber']
      : ['firstName', 'lastName', 'email', 'phoneNumber'];
    const locationRequiredFields = isPureflo
      ? [
          'address1',
          'city',
          'stateOrProvinceCode',
          'postalCode',
          'customerType',
        ]
      : ['address1', 'city', 'stateOrProvinceCode', 'postalCode'];
    const isContactFilled = isRequiredFieldsFilled(
      cartData.contact,
      contactRequiredFields,
    );
    const isServiceAddressFilled = isRequiredFieldsFilled(
      cartData.servicesAddresses[0],
      locationRequiredFields,
    );

    return isContactFilled && isServiceAddressFilled;
  });

export const selectIsBillingFilled = () =>
  createSelector(selectCartData(), (cartData) => {
    const isPureflo = isPurefloDomain();
    const locationRequiredFields = isPureflo
      ? ['address1', 'city', 'stateOrProvinceCode', 'postalCode', 'phoneNumber']
      : ['address1', 'city', 'stateOrProvinceCode', 'postalCode'];
    return isRequiredFieldsFilled(
      cartData.billingAddress,
      locationRequiredFields,
    );
  });

export const selectIsDeliverySetupFilled = () =>
  createSelector(selectCartData(), (cartData) => {
    const deliverySetupRequiredFields = ['deliveryDate', 'deliveryTime'];
    return isRequiredFieldsFilled(
      cartData.deliverySetup,
      deliverySetupRequiredFields,
    );
  });

export const selectContactData = () =>
  createSelector(selectCart(), (cart) =>
    cart.getIn(['data', PRIMARY_CONTACT_FORM], Map()).toJS(),
  );

export const selectDeliveryData = () =>
  createSelector(selectCart(), (cart) => {
    const isPureflo = isPurefloDomain();
    const cartData = cart
      .getIn(['data', DELIVERY_AND_INSTALLATION_LOCATION_FORM], List())
      .toJS();

    if (isPureflo) {
      return cartData;
    }

    return cartData[0];
  });

export const selectDeliverySetupData = () =>
  createSelector(selectCart(), (cart) =>
    cart.getIn(['data', DELIVERY_SETUP_FORM], Map()).toJS(),
  );

export const selectPaymentData = () =>
  createSelector(selectCart(), (cart) =>
    cart.getIn(['data', CREDIT_CARD_FORM], Map()).toJS(),
  );

export const selectBillingData = () =>
  createSelector(selectCart(), (cart) =>
    cart.getIn(['data', BILLING_ADDRESS_FORM], Map()).toJS(),
  );

const selectBillingAddress = selectBillingData;

const selectServiceAddress = () =>
  createSelector(selectCart(), (cart) =>
    cart.getIn(['data', 'servicesAddresses', 0], Map()).toJS(),
  );

export const selectPaymentAddress = () =>
  createSelector(
    selectBillingAddress(),
    selectServiceAddress(),
    (bAddr, sAddr) => (bAddr.city && bAddr) || sAddr,
  );

export const selectCartSummary = () =>
  createSelector(selectCart(), (cart) => cart.get('summary').toJS());

export const selectPaymentSurcharges = () =>
  createSelector(selectCartSummary(), (cart) => cart.paymentSurcharges);

export const selectCartItems = () =>
  createSelector(selectCart(), (cart) => cart.getIn(['data', 'items']).toJS());

export const selectOrderSubtotal = () =>
  createSelector(selectCart(), (cart) => cart.getIn(['summary', 'subtotal']));

export const selectSummaryLoading = () =>
  createSelector(selectCart(), (cart) => cart.get('isSummaryLoading'));

export const selectPromotionId = () =>
  createSelector(selectCart(), (cart) => cart.getIn(['data', 'promotionId']));

export const selectBranchId = () =>
  createSelector(selectCart(), (cart) => cart.getIn(['data', 'branchId']));

export const selectPromotions = () =>
  createSelector(selectCart(), (cart) => cart.get('promotions').toJS());

export const selectClearCartFlag = () =>
  createSelector(selectCart(), (cart) => cart.get('isCartClear'));

export const selectCartError = () =>
  createSelector(selectCart(), (data) => (data ? data.get('error') : null));
