import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {
  Dialog as MUIDialog,
  DialogTitle as MUIDialogTitle,
  DialogContent as MUIDialogContent,
  withStyles,
} from '@material-ui/core';
import { CloseIconSvg } from 'components/Ui/CustomIcons';
import IconButton from 'components/Ui/Button/IconButton';

const styles = (theme) => ({
  backdropRoot: {
    backgroundColor: theme.colors.drawerBackgroundBlue,
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '80%',
    },
  },
  small: {
    maxWidth: '620px',
  },
  medium: {
    maxWidth: '800px',
  },
  large: {
    maxWidth: '1100px',
  },
  promo: {
    borderRadius: 10,
  },
  fullscreen: {
    padding: '0',
  },
  titleRoot: {
    padding: '30px 15px',
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '1.25rem',
    fontWeight: '900',
  },
  button: {
    border: '3px solid transparent',
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: 'auto',
    height: 'auto',
    boxShadow: 'none',
  },
  icon: {
    width: '2em',
    height: '2em',
  },
  contentRoot: {
    padding: '35px 30px',
  },
  overflowContentRoot: {
    padding: '45px 0',
    overflowX: 'hidden',
  },
  noPaddingContentRoot: {
    padding: 0,
  },
  v2: {
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none',
  },
  v2Button: {
    top: '0.7rem',
    right: '0.9rem',
  },
});

const CloseButton = styled(IconButton)`
  z-index: 10;
`;

const Dialog = ({
  open: propsOpen,
  onClose,
  classes,
  children,
  size,
  isCloseIcon,
  title,
  fullScreen,
  customStyle,
  isAutoClose,
  autoCloseTimeout,
  overflowContent,
  noPaddingContent,
  ...restProps
}) => {
  const [open, changeOpen] = useState(propsOpen);

  useEffect(() => {
    if (propsOpen !== open) {
      changeOpen(propsOpen);
    }
  }, [propsOpen]);

  useEffect(() => {
    if (isAutoClose && open) {
      setTimeout(() => onClose(), autoCloseTimeout);
    }
  }, [open]);

  const handleClose = () => {
    changeOpen(false);
    onClose(false);
  };

  const contentRoot = overflowContent
    ? classes.overflowContentRoot
    : noPaddingContent
    ? classes.noPaddingContentRoot
    : classes.contentRoot;
  return (
    <MUIDialog
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        },
      }}
      classes={{
        paper: classnames(
          classes.paper,
          classes[fullScreen ? 'fullscreen' : size],
          classes[customStyle],
        ),
      }}
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      data-tracking={title}
      {...restProps}
      disableEnforceFocus
    >
      {title && (
        <MUIDialogTitle classes={{ root: classes.titleRoot }}>
          {title && <div className={classes.title}>{title}</div>}
        </MUIDialogTitle>
      )}
      {isCloseIcon && (
        <CloseButton
          onClick={handleClose}
          className={classnames(
            classes.button,
            classes[`${customStyle}Button`],
          )}
          aria-label="Close Dialog"
        >
          <CloseIconSvg className={classes.icon} />
        </CloseButton>
      )}
      <MUIDialogContent
        dividers={!!title}
        classes={{
          root: contentRoot,
        }}
      >
        {children}
      </MUIDialogContent>
    </MUIDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  isCloseIcon: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullScreen: PropTypes.bool,
  title: PropTypes.string,
  isAutoClose: PropTypes.bool,
  autoCloseTimeout: PropTypes.number,
  overflowContent: PropTypes.bool,
  noPaddingContent: PropTypes.bool,
  customStyle: PropTypes.string,
};

Dialog.defaultProps = {
  open: false,
  isCloseIcon: true,
  onClose: () => {},
  size: 'large',
  fullScreen: false,
  customStyle: '',
  isAutoClose: false,
  autoCloseTimeout: 2000,
  overflowContent: false,
  noPaddingContent: false,
};

export default withStyles(styles)(Dialog);
