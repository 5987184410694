/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { translateElementChildren } from 'utils/translation';

const H2 = ({ children, translationKey, defaultText, ...rest }) => {
  return (
    <h2 {...rest}>
      {translateElementChildren(children, translationKey, defaultText)}
    </h2>
  );
};

H2.propTypes = {
  children: PropTypes.node.isRequired,
  translationKey: PropTypes.string,
  defaultText: PropTypes.string,
};

export default H2;
