import React from 'react';
import PropTypes from 'prop-types';
import { get, forEach } from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import { injectVariables, isCanadianBrand } from 'utils/translation';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from 'components/ContentfulFields/Button';
import Animated from 'components/ContentfulFields/Animated';
import { H3 } from 'components/Ui';
import Slider from './Slider';

const Item = ({
  document: {
    fields: {
      title,
      type,
      description,
      images,
      brandedImages,
      disableLazyLoad = false,
    },
    sys: { id }, // feature sysID
  },
  titled,
  stylesTheme,
  className,
  animation,
  regionalBrandName,
  variables,
}) => {
  const styles = stylesTheme ? require(`../../styles/${stylesTheme}.scss`) : {};

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div>
          {injectVariables(children, regionalBrandName, variables, 'C9')}
        </div>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <H3 id={`cat_description_${id}`}>{children}</H3>
      ),
      [INLINES.HYPERLINK]: linkParser,
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const nodeSysId = get(node, 'data.target.sys.contentType.sys.id');
        switch (nodeSysId) {
          case 'brandedContent': {
            const entryTitle = get(node, 'data.target.fields.entryTitle', '');
            if (
              entryTitle.includes(isCanadianBrand() ? 'CA Brands' : 'US Brands')
            ) {
              return documentToReactComponents(
                get(node, 'data.target.fields.content'),
                options,
              );
            }
            return null;
          }
          default:
            return null;
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const embedId = get(node, 'data.target.sys.contentType.sys.id');
        switch (embedId) {
          case 'button':
            return (
              <Button
                id={`btn_shop_${id}`}
                className={styles.btn}
                document={node.data.target}
              >
                {children}
              </Button>
            );
          default:
            return null;
        }
      },
    },
  };

  const featureDescription = documentToReactComponents(description, options);
  let theImages = images;
  if (brandedImages) {
    const theBrandedImages = [];
    forEach(brandedImages, (image) => {
      const fieldsTitle = get(image, 'fields.title');
      if (fieldsTitle.includes(regionalBrandName)) {
        theBrandedImages.push(image);
      }
    });
    theImages = theBrandedImages;
  }

  return (
    <Animated
      classNames={classNames(styles.feature, className, styles[type])}
      animation={animation}
    >
      {theImages && theImages.length === 1 && (
        <LazyLoadImage
          visibleByDefault={disableLazyLoad}
          src={`${get(theImages[0], 'fields.file.url', null)}?fm=webp`}
          alt={get(theImages[0], 'fields.description', null)}
          aria-hidden="true"
        />
      )}
      {theImages && theImages.length > 1 && <Slider images={theImages} />}
      {titled && (
        <span>
          {injectVariables(title, regionalBrandName, variables, 'C10')}
        </span>
      )}
      <div className={styles.description}>
        {injectVariables(
          featureDescription,
          regionalBrandName,
          variables,
          'C11',
        )}
      </div>
    </Animated>
  );
};

Item.propTypes = {
  titled: PropTypes.bool,
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
  className: PropTypes.string,
  animation: PropTypes.string,
  regionalBrandName: PropTypes.string,
  variables: PropTypes.array,
};

Item.defaultProps = {
  document: {
    fields: {
      type: 'small-round',
      image: null,
      description: null,
    },
  },
  titled: true,
};

export default Item;
