import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingIndicator from 'components/LoadingIndicator';

import { handleAuthentication } from 'containers/Authentication/actions';

class AuthenticationCallback extends React.PureComponent {
  static propTypes = {
    handleAuthentication: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.handleAuthentication();
  }

  render() {
    return <LoadingIndicator />;
  }
}

const mapDispatchToProps = {
  handleAuthentication,
};

export default connect(null, mapDispatchToProps)(AuthenticationCallback);
