/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { translateElementChildren } from 'utils/translation';

const H5 = ({ children, translationKey, defaultText, ...rest }) => {
  return (
    <h5 {...rest}>
      {translateElementChildren(children, translationKey, defaultText)}
    </h5>
  );
};

H5.propTypes = {
  children: PropTypes.node.isRequired,
  translationKey: PropTypes.string,
  defaultText: PropTypes.string,
};

export default H5;
