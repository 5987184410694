import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { getToUrl } from 'utils/translation';
import styles from './styles.scss';

const LearnMore = ({ fields, isMobile }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className={styles.content}>{children}</div>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.title}>{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={styles.title}>{children}</h3>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const { data } = node;

        return (
          <Link className={styles.link} to={getToUrl(data.uri, 'U3')}>
            {children}
          </Link>
        );
      },
    },
  };

  const content = documentToReactComponents(fields, options);
  const phoneNumber = documentToReactComponents(
    {
      data: {},
      nodeType: 'document',
      content: fields.content[0].content.slice(1),
    },
    options,
  );

  return (
    <div className={styles.learnMoreContainer}>
      {!isMobile && (
        <div className={styles.content}>
          <b>{content[0].props.children[0].props.children}</b>
          <a href={`tel:${phoneNumber[1].props.children}`}>{phoneNumber}</a>
        </div>
      )}
      {isMobile && <PhoneIphoneIcon />}
      {isMobile && (
        <a href={`tel:${phoneNumber[1].props.children}`}>{phoneNumber}</a>
      )}
    </div>
  );
};

LearnMore.propTypes = {
  fields: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default LearnMore;
