import React from 'react';
import PropTypes from 'prop-types';

const Weekday = ({ weekday, className, localeUtils, locale }) => {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={className} title={weekdayName}>
      {weekdayName.slice(0, 3)}
    </div>
  );
};

Weekday.propTypes = {
  localeUtils: PropTypes.object.isRequired,
  weekday: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Weekday;
