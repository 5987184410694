import React from 'react';
import PropTypes from 'prop-types';
import { isSsr } from 'utils/ssrHelper';
import { translateData } from 'utils/translation';

const PageScroll = ({ text, scrollOptions, className }) => {
  const handleScroll = () => {
    if (!isSsr()) {
      window.scrollTo(scrollOptions);
    }
  };

  return (
    <button
      onClick={handleScroll}
      type="button"
      className={className}
      style={{
        outline: 'none',
        cursor: 'pointer',
        borderStyle: 'none',
        backgroundColor: 'transparent',
      }}
    >
      {translateData(text)}
    </button>
  );
};

PageScroll.propTypes = {
  text: PropTypes.string,
  scrollOptions: PropTypes.object,
  className: PropTypes.string,
};

PageScroll.defaultProps = {
  text: 'Back to top',
  scrollOptions: { top: 0, behavior: 'smooth' },
};

export default PageScroll;
