import styled from 'styled-components';

const ScreenReader = styled.p`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const HiddenContent = styled.div`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const HiddenContentSpan = styled.span`
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const SkipToMainContent = styled.p`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  a {
    color: #036dc2;
  }
  &:focus-within {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: visible;
    z-index: 1500;
    border-radius: 50px;
    background-color: #fff;
    border: 5px #036dc2 solid;
    padding: 10px 20px;
    margin: 5px 0 0 0;
    text-decoration: underline;
    font-weight: 600;
  }
`;

export default ScreenReader;
