import React from 'react';
import PropTypes from 'prop-types';
import withPromo from '../withPromo';

/**
 * @param {object} props - props
 * @param {string} [props.promo] - promo key
 * @param {ReactChildren} [props.children]
 * @return {ReactElement}
 */
const PromoPart = (props) => {
  const WithPromo = withPromo(props.promo)(({ children }) => children);
  return <WithPromo {...props}>{props.children}</WithPromo>;
};

PromoPart.propTypes = {
  promo: PropTypes.string,
  children: PropTypes.node,
};

export default PromoPart;
