export default (theme) => ({
  root: {
    background: theme.colors.whiteSmoke,
    padding: '25px',
    borderRadius: '50px',
    width: '90%',
    margin: '15px 0 0 0',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.up('sm')]: {
      width: '33%',
      marginRight: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
  },
  img: {
    width: '60px',
    height: '170px',
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  dot: {
    backgroundImage: theme.gradients.lightGreyGradient,
    border: theme.borders.greyBorder(false),
  },
  dotActive: {
    backgroundImage: theme.gradients.darkGreyGradient,
  },
  mobileStepper: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.colors.whiteSmoke,
    margin: '10px 0',
  },
});
