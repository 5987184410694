import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import styles from './styles.scss';

const Container = ({ children, className, value, ...props }) => (
  <div
    {...props}
    data-tracking={GTM_TRACKING_TAGS[value] ? GTM_TRACKING_TAGS[value] : value}
    className={classnames(styles.container, className)}
  >
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Container.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default Container;
