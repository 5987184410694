import { fromJS } from 'immutable';
import reduceReducers from 'reduce-reducers';
import { makeFetchReducer, LOADING, ERROR } from 'utils/store/fetch';
import { namespaceReducer } from 'utils/store/reducer';
import { types, NAMESPACE } from './actions';

const initialState = fromJS({
  isToggling: false,
  error: null,
});

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOGGLE:
      return state.merge({
        isToggling: true,
        status: LOADING,
        error: null,
      });
    case types.TOGGLE_SUCCESS:
      return state.merge({
        isToggling: false,
      });
    case types.TOGGLE_FAILURE:
      return state.merge({
        isToggling: false,
        status: ERROR,
        error: action.payload,
      });
    default:
      return state;
  }
};

const fetchReducer = makeFetchReducer(NAMESPACE);
const mergedInitialState = fetchReducer().merge(reducer());

const favoritesReducer = reduceReducers(
  mergedInitialState,
  fetchReducer,
  reducer,
);

export default namespaceReducer(NAMESPACE, favoritesReducer);
