export const AUTHENTICATION_PAGE_KEY = 'authentication';

export const HANDLE_AUTHENTICATION = `${AUTHENTICATION_PAGE_KEY}/HANDLE_AUTHENTICATION`;
export const HANDLE_MOBILE_AUTHENTICATION = `${AUTHENTICATION_PAGE_KEY}/HANDLE_MOBILE_AUTHENTICATION`;

export const LOAD_AUTHENTICATION = `${AUTHENTICATION_PAGE_KEY}/LOAD_AUTHENTICATION`;
export const LOAD_AUTHENTICATION_SUCCESS = `${AUTHENTICATION_PAGE_KEY}/LOAD_AUTHENTICATION_SUCCESS`;
export const LOAD_AUTHENTICATION_FAILURE = `${AUTHENTICATION_PAGE_KEY}/LOAD_AUTHENTICATION_FAILURE`;
export const LOAD_MOBILE_AUTHENTICATION_SUCCESS = `${AUTHENTICATION_PAGE_KEY}/LOAD_MOBILE_AUTHENTICATION_SUCCESS`;
export const HANDLE_AUTHENTICATION_FAILURE = `${AUTHENTICATION_PAGE_KEY}/HANDLE_AUTHENTICATION_FAILURE`;

export const LOGIN = `${AUTHENTICATION_PAGE_KEY}/LOGIN`;
export const LOGIN_SUCCESS = `${AUTHENTICATION_PAGE_KEY}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${AUTHENTICATION_PAGE_KEY}/LOGIN_FAILURE`;

export const LOGOUT = `${AUTHENTICATION_PAGE_KEY}/LOGOUT`;
export const LOGOUT_INITIATED = `${AUTHENTICATION_PAGE_KEY}/LOGOUT_INITIATED`;

export const SIGNUP = `${AUTHENTICATION_PAGE_KEY}/SIGNUP`;
export const SIGNUP_SUCCESS = `${AUTHENTICATION_PAGE_KEY}/SIGNUP_SUCCESS`;
export const SIGNUP_FAILURE = `${AUTHENTICATION_PAGE_KEY}/SIGNUP_FAILURE`;

export const RESTORE_PASSWORD = `${AUTHENTICATION_PAGE_KEY}/RESTORE_PASSWORD`;
export const RESTORE_PASSWORD_SUCCESS = `${AUTHENTICATION_PAGE_KEY}/RESTORE_PASSWORD_SUCCESS`;
export const RESTORE_PASSWORD_FAILURE = `${AUTHENTICATION_PAGE_KEY}/RESTORE_PASSWORD_FAILURE`;

export const CHANGE_PASSWORD = `${AUTHENTICATION_PAGE_KEY}/CHANGE_PASSWORD_REQUEST`;
export const CHANGE_PASSWORD_SUCCESS = `${AUTHENTICATION_PAGE_KEY}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAILURE = `${AUTHENTICATION_PAGE_KEY}/CHANGE_PASSWORD_FAILURE`;

export const RESTORE_PASSWORD_MODAL_SUCCESS_HIDE = `${AUTHENTICATION_PAGE_KEY}/RESTORE_PASSWORD_MODAL_SUCCESS_HIDE`;

export const BASIC_REALM_NAME = 'Username-Password-Authentication';
export const ORACLE_REALM_NAME = 'Oracle';
export const AUTH_DEFAULT_EXPIRATION_TIMEOUT = 36000;
