import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const CloseExpandIcon = ({
  id = 'CloseExpandIcon',
  hoverColor,
  color: mainColor,
  className,
  ariaLabel,
  ...restProps
}) => {
  const theme = useTheme();
  const [isHovered, changeIsHovered] = useState(false);

  const onMouseEnter = () => {
    changeIsHovered(true);
  };

  const onMouseLeave = () => {
    changeIsHovered(false);
  };

  const color = isHovered ? hoverColor : mainColor;

  return (
    <SvgIcon
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      viewBox="0 0 40 40"
      width="40px"
      height="40px"
      fontSize="small"
      cursor="pointer"
      {...restProps}
    >
      <g
        id={`${id}-Navigation-States`}
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id={`${id}-Navigation_DT_1024---CartLoggedIn`}
          transform="translate(-926.000000, -349.000000)"
        >
          <g id={`${id}-Cart-Hover`}>
            <g
              transform="translate(411.000000, 57.000000)"
              id={`${id}-btn/X/blue-Copy-2`}
            >
              <g transform="translate(515.000000, 292.000000)">
                <g id={`${id}-ArrowButton`}>
                  <circle
                    id={`${id}-Oval`}
                    fill={color || theme.colors.heavySome}
                    cx="20"
                    cy="20"
                    r="20"
                  ></circle>
                  <g
                    id={`${id}-Group-2`}
                    transform="translate(13.000000, 13.000000)"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <path
                      d="M14.9521484,0 L0,14.9521484"
                      id={`${id}-Path-4-1`}
                    ></path>
                    <path
                      d="M14.9521484,0 L0,14.9521484"
                      id={`${id}-Path-4-2`}
                      transform="translate(7.476074, 7.476074) rotate(-270.000000) translate(-7.476074, -7.476074) "
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

CloseExpandIcon.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  className: PropTypes.string,
};

export default CloseExpandIcon;
