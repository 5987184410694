import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Redirect } from 'react-router-dom';
import {
  selectIsFetching,
  selectIsAuthenticated,
  selectIsInitiated,
} from 'containers/Authentication/selectors';

import Loading from 'components/LoadingIndicator';

class AnonymousRoute extends PureComponent {
  render() {
    const {
      render: Component,
      isFetching,
      isAuthenticated,
      isInitiated,
      ...rest
    } = this.props;

    if (isFetching || !isInitiated) {
      return <Loading />;
    }

    if (!isAuthenticated) {
      return <Route {...rest} render={Component} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/home',
          state: { prevPath: this.props.location.pathname },
        }}
      />
    );
  }
}

AnonymousRoute.propTypes = {
  component: PropTypes.func,
  isFetching: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  isAuthenticated: selectIsAuthenticated(),
  isInitiated: selectIsInitiated(),
});

export default connect(mapStateToProps)(AnonymousRoute);
