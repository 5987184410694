import React from 'react';
import { getDomainByHost } from 'utils/domainHelper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import Loader from 'components/Loader';
import { createStructuredSelector } from 'reselect';
import { getPageSlugKey } from 'utils/contentfulUtils';
import { selectSection } from 'containers/Landing/selectors';
import saga from './saga';
import { selectLandingData, selectForceFetch } from './selectors';
import { fetchPageRequest } from './actions';
import { LANDING_PAGE_KEY, pages } from './constants';

const withContentful = (key) => (WrappedComponent) => {
  class WithContentfulHOC extends React.Component {
    componentDidMount() {
      const {
        fetchPage,
        site: {
          components: { page },
        },
        section,
      } = this.props;

      const slug = getDomainByHost(11).page[pages[key]];

      if (
        !slug ||
        page === null ||
        page.slug !== getPageSlugKey(slug, section)
      ) {
        fetchPage({ key: slug ? pages[key] : key });
      }
    }

    componentDidUpdate(prevProps) {
      const slug = getDomainByHost(12).page[pages[key]];
      if (prevProps.forceFetch !== this.props.forceFetch) {
        this.props.fetchPage({ key: slug ? pages[key] : key });
      }
    }

    render() {
      const {
        site: { loading },
      } = this.props;

      return (
        <Loader loading={loading}>
          <WrappedComponent {...this.props} />
        </Loader>
      );
    }
  }

  const mapDispatchToProps = {
    fetchPage: fetchPageRequest,
  };

  const mapStateToProps = createStructuredSelector({
    site: selectLandingData(),
    section: selectSection(),
    forceFetch: selectForceFetch(),
  });

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const withSaga = injectSaga({
    key: LANDING_PAGE_KEY,
    saga,
  });

  return compose(withConnect, withSaga)(WithContentfulHOC);
};

export default withContentful;
