import React, { useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Mobile, Default } from 'components/Media';
import Button from 'components/ContentfulFields/Button';
import Collapse from 'components/ContentfulFields/Collapse';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import SignInDrawer from 'containers/WaterAccount/SignIn/Drawer';
import RegionalDrawer from 'containers/WaterAccount/BrandChoose/Drawer';
import { selectItemsCount } from 'containers/Cart/selectors';
import { isWaterTheme } from 'utils/common';
import MobileMenu from 'components/ContentfulFields/Header/HeaderCollapses/MobileMenu';
import Logo from 'components/ContentfulFields/Logo';
import StartShopping from 'components/ContentfulFields/Header/Navigation/Water/StartShopping';
import { useAuth0 } from 'auth0Provider';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { getToUrl } from 'utils/translation';
import { isSsr } from 'utils/ssrHelper';
import styles from './styles.scss';

const Navigation = (props, context) => {
  const navigationRef = useRef(null);
  const startShoppingRef = useRef(null);
  let shrinkOn = 450;
  const isServersideRendering = isSsr();

  const getWindowHeight = () => {
    const distanceY =
      (!isServersideRendering && window.pageYOffset) ||
      document.documentElement.scrollTop;
    const { classList, clientHeight } = navigationRef.current;
    const { classList: startShoppingClassList } = startShoppingRef.current;

    if (distanceY > shrinkOn) {
      classList.add(styles.shrink);
      startShoppingClassList.add(styles.visible);
      shrinkOn = 350;
    } else {
      classList.remove(styles.shrink);
      startShoppingClassList.remove(styles.visible);
      shrinkOn = 450;
    }

    if (props.changeHeight) {
      props.changeHeight(clientHeight);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (
      !isServersideRendering &&
      isWaterTheme(context.stylesTheme) &&
      !isMobile
    ) {
      window.addEventListener('scroll', getWindowHeight);
    }
  }, []);

  useEffect(
    () => () => {
      if (
        !isServersideRendering &&
        isWaterTheme(context.stylesTheme) &&
        !isMobile
      ) {
        window.removeEventListener('scroll', getWindowHeight);
      }
    },
    [],
  );

  const {
    fields,
    logoFields,
    match,
    location,
    activeDrawer,
    isAuthenticated,
    history,
    ...otherProps
  } = props;

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        if (get(node, 'data.target.sys.contentType.sys.id') === 'collapse') {
          return (
            <Collapse
              className={styles.collapse}
              document={node.data.target}
              toggle={otherProps.openCollapse}
              active={activeDrawer}
              isAuthenticated={isAuthenticated}
            />
          );
        }

        return (
          <Button className={styles.btn} document={node.data.target}>
            {children}
          </Button>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri: url },
        } = node;
        const isActive = url === location.pathname;

        return (
          <Link
            className={classnames(styles.link, { [styles.active]: isActive })}
            to={getToUrl(url, 'U8')}
          >
            {children}
          </Link>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        const url = `/${nodeFields.slug}`;
        const isActive = url === location.pathname;

        return (
          <Link
            className={classnames(styles.link, { [styles.active]: isActive })}
            to={getToUrl(url, 'U9')}
          >
            {children}
          </Link>
        );
      },
    },
  };

  const navigation = documentToReactComponents(fields, options);
  const { loginWithRedirect, logout } = useAuth0();

  return (
    <Fragment>
      <div className={styles.container}>
        <div
          className={styles.navigation}
          ref={navigationRef}
          data-tracking={GTM_TRACKING_TAGS.Navigation}
        >
          <Logo fields={logoFields} className={styles.logo} />
          <nav className={styles.navbar}>
            <div className={styles.tooltips}>
              <Default>{navigation}</Default>
              <Mobile>
                <MobileMenu
                  fields={fields}
                  openCollapse={otherProps.openCollapse}
                  closeCollapse={otherProps.closeCollapse}
                  loginWithRedirect={loginWithRedirect}
                  logout={logout}
                />
              </Mobile>
            </div>
          </nav>
        </div>
      </div>
      <StartShopping ref={startShoppingRef} />
      <SignInDrawer />
      <RegionalDrawer />
    </Fragment>
  );
};

Navigation.propTypes = {
  fields: PropTypes.object,
  logoFields: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  openCollapse: PropTypes.func.isRequired,
  activeDrawer: PropTypes.string,
  closeCollapse: PropTypes.func.isRequired,
  changeHeight: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  itemsCount: PropTypes.number,
  history: PropTypes.object,
};

Navigation.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  itemsCount: selectItemsCount(),
});

export default withRouter(connect(mapStateToProps)(Navigation));
