import React from 'react';
import { SvgIcon } from '@material-ui/core';

const SelectArrowIcon = (props) => (
  <SvgIcon {...props}>
    <defs>
      <path id="i293a" d="M322 1603h-8l4-7zm-8 2h8l-4 7z" />
    </defs>
    <g>
      <g transform="translate(-314 -1596)">
        <use fill="#494949" xlinkHref="#i293a" />
      </g>
    </g>
  </SvgIcon>
);

export default SelectArrowIcon;
