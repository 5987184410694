import * as types from './constants';

export const fetchAnonymousCart = (cartId) => ({
  type: types.FETCH_ANONYMOUS_CART,
  payload: { cartId },
});

export const fetchAnonymousCartSuccess = (payload) => ({
  type: types.FETCH_ANONYMOUS_CART_SUCCESS,
  payload,
});

export const fetchAnonymousCartFailure = () => ({
  type: types.FETCH_ANONYMOUS_CART_FAILURE,
});

export const fetchCart = (id) => ({
  type: types.FETCH_USER_CART,
  payload: { id },
});

export const fetchCartSuccess = (id) => ({
  type: types.FETCH_USER_CART_SUCCESS,
  payload: { id },
});

export const fetchCartFailure = (id) => ({
  type: types.FETCH_USER_CART_FAILURE,
  payload: { id },
});

export const changeItemQuantity = ({ cartId, item, quantity }) => ({
  type: types.CHANGE_ITEM_QUANTITY,
  payload: { cartId, item, quantity },
});

export const removeItem = ({ cartId, itemId }) => ({
  type: types.REMOVE_ITEM,
  payload: { cartId, itemId },
});

export const createCart = (payload) => ({
  type: types.CREATE_CART,
  payload,
});

export const createCartSuccess = (payload) => ({
  type: types.CREATE_CART_SUCCESS,
  payload,
});

export const createCartFailure = (payload) => ({
  type: types.CREATE_CART_FAILURE,
  payload,
});

export const loadCartProducts = (items) => ({
  type: types.LOAD_CART_PRODUCTS,
  payload: { items },
});

export const addItemToAnonymousCart = ({ cartId, items, secretId }) => ({
  type: types.ADD_ITEM_TO_ANONYMOUS_CART,
  payload: { cartId, items, secretId },
});

export const addItemToAnonymousCartSuccess = (payload) => ({
  type: types.ADD_ITEM_TO_ANONYMOUS_CART_SUCCESS,
  payload,
});

export const addItemToAnonymousCartFailure = (payload) => ({
  type: types.ADD_ITEM_TO_ANONYMOUS_CART_FAILURE,
  payload,
});

export const addItemToUserCart = ({ userId, items }) => ({
  type: types.ADD_ITEM_TO_USER_CART,
  payload: { userId, items },
});

export const mergeAnonymousCart = (payload) => ({
  type: types.MERGE_ANON_CART,
  payload,
});

export const clearCart = () => ({
  type: types.CLEAR_CART,
});

export const clearCartFlag = () => ({
  type: types.CLEAR_CART_FLAG,
});

export const updateCart = (payload) => ({
  type: types.UPDATE_CART,
  payload,
});

export const addToCart = (payload) => ({
  type: types.ADD_ITEM_TO_CART,
  payload,
});

export const getCartSummary = ({ cartId }) => ({
  type: types.GET_CART_SUMMARY,
  payload: { cartId },
});

export const addPromotion = () => ({
  type: types.ADD_PROMOTION,
});

export const removePromotion = () => ({
  type: types.REMOVE_PROMOTION,
});

export const loadPromotions = () => ({
  type: types.LOAD_PROMOTIONS,
});

export const resetCartError = () => ({
  type: types.RESET_CART_ERROR,
});
