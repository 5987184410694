export const PINS = [
  {
    left: '10%',
    top: '0',
  },
  {
    left: '5%',
    top: '10%',
  },
  {
    left: '14%',
    top: '10%',
  },
  {
    left: '4%',
    top: '23%',
  },
  {
    left: '13%',
    top: '27%',
  },
  {
    left: '7%',
    top: '33%',
  },
  {
    left: '10%',
    top: '14%',
  },
  {
    left: '3%',
    top: '42%',
  },
  {
    left: '9%',
    top: '52%',
  },
  {
    left: '16%',
    top: '60%',
  },
  {
    left: '20%',
    top: '34%',
  },
  {
    left: '16%',
    top: '39%',
  },
  {
    left: '18%',
    top: '17%',
  },
  {
    left: '20%',
    top: '50%',
  },
  {
    left: '23%',
    top: '19%',
  },
  {
    left: '29%',
    top: '25%',
  },
  {
    left: '31%',
    top: '37%',
  },
  {
    left: '25%',
    top: '42%',
  },
  {
    left: '29%',
    top: '68%',
  },
  {
    left: '36%',
    top: '69%',
  },
  {
    left: '45%',
    top: '69%',
  },
  {
    left: '40%',
    top: '82%',
  },
  {
    left: '44%',
    top: '57%',
  },
  {
    left: '40%',
    top: '53%',
  },
  {
    left: '39%',
    top: '38%',
  },
  {
    left: '44%',
    top: '40%',
  },
  {
    left: '46%',
    top: '30%',
  },
  {
    left: '50%',
    top: '26%',
  },
  {
    left: '50%',
    top: '43%',
  },
  {
    left: '56%',
    top: '30%',
  },
  {
    left: '55%',
    top: '38%',
  },
  {
    left: '53%',
    top: '72%',
  },
  {
    left: '56%',
    top: '62%',
  },
  {
    left: '60%',
    top: '52%',
  },
  {
    left: '63%',
    top: '49%',
  },
  {
    left: '60%',
    top: '31%',
  },
  {
    left: '60%',
    top: '41%',
  },
  {
    left: '66%',
    top: '31%',
  },
  {
    left: '74%',
    top: '25%',
  },
  {
    left: '61%',
    top: '65%',
  },
  {
    left: '66%',
    top: '60%',
  },
  {
    left: '70%',
    top: '72%',
  },
  {
    left: '73%',
    top: '80%',
  },
  {
    left: '71%',
    top: '55%',
  },
  {
    left: '74%',
    top: '48%',
  },
  {
    left: '69%',
    top: '37%',
  },
  {
    left: '74%',
    top: '37%',
  },
  {
    left: '79%',
    top: '35%',
  },
  {
    left: '76%',
    top: '33%',
  },
  {
    left: '79%',
    top: '27%',
  },
  {
    left: '77%',
    top: '17%',
  },
  {
    left: '81%',
    top: '20%',
  },
  {
    left: '84%',
    top: '16%',
  },
  {
    left: '81%',
    top: '11%',
  },
  {
    left: '84%',
    top: '9%',
  },
];
