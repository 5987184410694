import React from 'react';
import styles from './styles.scss';

const EmptyCart = () => (
  <div className={styles.emptyCart}>
    <div>Oh no!</div>
    <div className={styles.info}>
      Your cart is empty…
      <br />
      Go to the Our Products page to begin shopping.
    </div>
  </div>
);

export default EmptyCart;
