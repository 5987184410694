import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import axios from 'axios';
import { Input, Select, Button, PhoneInput, Span } from 'components/Ui';
import { useIsMobile } from 'utils/hooks';
import { find } from 'lodash';

import commonStyles from 'containers/PrimoCheckoutPage/styles.scss';
import { LANDING_PAGE_KEY } from 'containers/Landing/constants';
import { isSsr } from 'utils/ssrHelper';
import { connect, useDispatch, useSelector } from 'react-redux';
import { navigateZipRedirect } from 'containers/Landing/actions';
import {
  fetchCityCounty,
  saveCountyOption,
} from 'containers/CheckoutPage/actions';
import { dataLayerPush } from 'utils/tracking';
import { selectCartItems } from 'containers/Cart/selectors';
import { selectCityCountyListOption } from 'containers/CheckoutPage/selectors';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { isCanadianBrand, translateData } from 'utils/translation';
import { provinceValues } from 'utils/constants';
import { getRegionalBrandName } from 'utils/domainHelper';
import ReCaptcha from 'components/ReCaptcha';

import {
  requiredDeliveryInformationFieldsHome,
  requiredDeliveryInformationFieldsOffice,
} from '../utils';
import styles from './styles.scss';

const DeliveryInformationForm = ({
  defaultValues,
  onSubmit,
  isProcessing,
  errors,
  forwardedRef,
  changeCanGoForward,
  section,
  homeOffice,
  drawerToggle,
  isDrawer,
  cityCountyOptions,
  navigateZipRedirectAction,
}) => {
  const reCaptchaRef = useRef();
  const dispatch = useDispatch();
  const [values, changeValues] = useState(defaultValues);
  const [postalCode, changePostalCode] = useState(defaultValues.postalCode);
  const [captchaSiteKey, setCaptchaSiteKey] = useState();

  const isHomeSection =
    !isSsr() && window.isCostcoWater
      ? homeOffice === 'home'
      : section === 'home';

  const isCostcoWater = !isSsr() && window.isCostcoWater;
  const cartItems = useSelector(selectCartItems());
  const selectedPostalCode = useSelector(selectPostalCode());
  const selectedCityCountyListOption = useSelector(
    selectCityCountyListOption(),
  );
  const isFilled = isHomeSection
    ? requiredDeliveryInformationFieldsHome.every((field) => values[field])
    : requiredDeliveryInformationFieldsOffice.every((field) => values[field]);
  const isMobile = useIsMobile();
  const gridSpacing = useMemo(() => (isMobile ? 1 : 4), [isMobile]);
  const mdSzFl = (sz) => (isDrawer ? 6 : !sz ? 6 : sz);
  const mdSzHf = (sz) => (isDrawer ? 3 : !sz ? 6 : sz);
  const disableCityCountyState = cityCountyOptions.length === 1;
  const handlePromotionPreference = ({ target: { checked } }) => {
    setPromotionSubscription(checked);
  };
  const brandName = getRegionalBrandName();
  const [promotionSubscription, setPromotionSubscription] = useState(true);


  useEffect(() => {
    setCaptchaSiteKey(window.SERVER_DATA.RECAPTCHA_SITE_KEY);
    dataLayerPush(
      'Acquisition',
      {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 1 },
            products: cartItems.map((item) => ({
              name: item.name,
              id: item.itemNumber,
              price: `${item.price}`,
              brand: item.brand || '',
              category: item.category,
              variant: item.size,
              quantity: item.quantity,
            })),
          },
        },
      },
      'dlA16',
    );
  }, []);

  useEffect(() => {
    if (!isDrawer) {
      changeCanGoForward(isFilled);
    }
    handleCurrentSection();
  }, [isFilled]);

  useEffect(() => {
    if (
      defaultValues.cityCountyState &&
      selectedCityCountyListOption === null &&
      !isCanadianBrand()
    ) {
      changeCityCountyState(defaultValues.cityCountyState);
    }
  }, [defaultValues.cityCountyState]);

  useEffect(() => {
    if (window.redirectPostalCode !== selectedPostalCode) {
      changePostalCode(selectedPostalCode);
      if (!isCanadianBrand()) {
        dispatch(fetchCityCounty(selectedPostalCode));
      }
      window.redirectPostalCode = selectedPostalCode;
    }
  }, [window.redirectPostalCode]);

  useEffect(() => {
    if (selectedCityCountyListOption !== null && !isCanadianBrand()) {
      let cityCounty =
        selectedCityCountyListOption &&
        selectedCityCountyListOption.cityCountyOption;
      cityCounty = cityCounty && cityCounty.split(',');
      cityCountyOptions = [
        {
          label: selectedCityCountyListOption.cityCountyOption,
          value: `${cityCounty[0].trim()}${cityCounty[1].trim()}`,
          data: {
            city: cityCounty[0].trim(),
            county: cityCounty[1].trim(),
            state: cityCounty[2].trim(),
          },
        },
      ];
      changeCityCountyState(cityCountyOptions[0].value);
      values.cityCountyState = cityCountyOptions[0].value;
      values.cityCountyStateDisplay = cityCountyOptions[0].value;
    }
  }, []);
  const handleChange = ({ target: { value, name } }) => {
    if (name === 'postalCode') {
      value = value.toUpperCase();
      changePostalCode(value);
      dispatch(saveCountyOption(null));
    }
    changeValues({ ...values, [name]: value });
  };

  const handleZipCodeFocus = () => {
    if (
      /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(postalCode) ||
      /^[0-9]{0,5}(?:-[0-9]{0,4})?$/.test(postalCode)
    ) {
      if (!isCanadianBrand()) {
        dispatch(fetchCityCounty(postalCode));
      }
      dispatch(saveCountyOption(null));
    }
  };

  const handleExtChange = ({ target: { value, name } }) => {
    const ext = value.replace(/[^0-9]/g, '');
    if (ext.length <= 6) {
      changeValues({ ...values, [name]: ext });
    }
  };

  const stayCallback = () => {
    onSubmit({ ...values, postalCode: selectedPostalCode });
  };

  const handleReCaptchaVerify = (token) => {
    // After getting the token, proceed with form submission
    submitDeliveryInformation(token);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    reCaptchaRef.current.executeReCaptcha();
  };
  const submitDeliveryInformation = (token) => {
    if (promotionSubscription) {
      axios
        .post(
          '/leads',
          {
            emailAddress: values.email,
            source: `Regional-${brandName.split(' ').join('-')}-checkout`,
          },
          {
            headers: {
              'api-token': token,
              'pmo-event-name': 'leads-subscribed',
            },
          },
        )
        .then(() => {
          // localStorage.setItem('LeadsSubscriptionStatus', 'subscribed');
        })
        .catch((err) => {
          console.error(err); // eslint-disable-line
        });
    }

    navigateZipRedirectAction({
      zip: postalCode,
      stay: true,
      stayCallback,
    });
    const cityCountyOption =
      values.cityCountyStateDisplay || values.cityCountyState;
    dispatch(saveCountyOption({ cityCountyOption }));
  };

  const handleChangePhone = (value, name) => {
    changeValues({ ...values, [name]: value });
  };

  const handleCityCountyState = ({ target: { value } }) => {
    changeCityCountyState(value);
  };

  const changeCityCountyState = (value) => {
    if (isCanadianBrand()) {
      const selected = find(provinceValues, (option) => option.value === value);
      changeValues({
        ...values,
        provinceValues: value,
        city: values.city,
        county: selected.data.county,
        stateOrProvinceCode: selected.data.state,
        cityCountyStateDisplay: selected.label,
      });
    } else {
      const selected = find(
        cityCountyOptions,
        (option) => option.value === value,
      );
      changeValues({
        ...values,
        cityCountyState: value,
        city: selected.data.city,
        county: selected.data.county,
        stateOrProvinceCode: selected.data.state,
        cityCountyStateDisplay: selected.label,
      });
    }
  };

  const handleProvinceState = ({ target: { value } }) => {
    changeCityCountyState(value);
  };
  const handleCurrentSection = () => {
    section = section === 'home' || !section ? 'residential' : section;

    if (section !== defaultValues.customerType) {
      changeValues({ ...values, customerType: 'commercial' });
    }
  };

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      className={!isDrawer ? styles.form : styles.drawerForm}
      ref={forwardedRef}
      role="form"
    >
      {!isDrawer ? (
        <Fragment>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={12} md={12}>
              <div className={styles.requiredText}>
                <Span>Fields marked with an asterisk (*) are required.</Span>
              </div>
            </Grid>
            <Grid item sm={12} md={mdSzHf(3)}>
              <Input
                autoFocus
                label="First Name:"
                ariaLabel="First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['']}
                error={errors.firstName}
                autocomplete="shipping given-name"
                id="first-name"
              />
            </Grid>
            <Grid item sm={12} md={mdSzHf(3)}>
              <Input
                label="Last Name:"
                ariaLabel="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['']}
                error={errors.lastName}
                autocomplete="shipping family-name"
                id="last-name"
              />
            </Grid>
            {!isHomeSection && (
              <Grid item sm={12} md={mdSzFl()}>
                <Input
                  label="Company:"
                  ariaLabel="Company Name"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  validators={isHomeSection ? [] : ['required']}
                  errorMessages={isHomeSection ? [] : ['']}
                  error={errors.company}
                  autocomplete="shipping organization"
                  id="company-name"
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={12} md={mdSzHf()}>
              <Input
                label="Street Address:"
                ariaLabel="Street address"
                name="address1"
                value={values.address1}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['']}
                error={errors['[0].address1']}
                autocomplete="shipping address-line1"
                id="address-street-one"
              />
            </Grid>
            <Grid item sm={12} md={mdSzHf()}>
              <Input
                label="Apt/Ste/Other:"
                ariaLabel="Apartment Number, Suite Number, or Other"
                name="address2"
                value={values.address2}
                onChange={handleChange}
                error={errors['[0].address2']}
                autocomplete="shipping address-line2"
                id="address-street-two"
              />
            </Grid>
          </Grid>
          <Grid container spacing={gridSpacing}>
            {isCanadianBrand() ? (
              <>
                <Grid item sm={8} md={mdSzHf(4)}>
                  <Input
                    label="City:"
                    ariaLabel="City"
                    name="city"
                    value={values.city}
                    validators={['required']}
                    onChange={handleChange}
                    error={errors['[0].city']}
                    autocomplete="shipping city"
                    id="address-city"
                  />
                </Grid>
                <Grid item sm={4} md={mdSzHf(2)}>
                  <Select
                    label="Province:"
                    ariaLabel="Province"
                    boldLabel
                    value={values.cityCountyStateDisplay}
                    items={provinceValues}
                    onChange={handleProvinceState}
                    name="cityCountyState"
                    icon="primaryArrow"
                    validators={['required']}
                    errorMessages={['']}
                    error={errors['[0].cityCountyState']}
                    id="address-city-county-state"
                  />
                  <input
                    type="hidden"
                    name="cityCountyStateDisplay"
                    value={values.cityCountyStateDisplay}
                  />
                </Grid>
              </>
            ) : (
              <Grid item sm={12} md={mdSzHf()}>
                {disableCityCountyState ? (
                  <Fragment>
                    <Input
                      label="City, County, State:"
                      ariaLabel="City, County, State"
                      name="cityCountyStateDisplay"
                      disabled
                      value={values.cityCountyStateDisplay}
                      validators={['required']}
                      id="address-city-county-state"
                    />
                    <input
                      type="hidden"
                      name="cityCountyState"
                      value={values.cityCountyState}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Select
                      label="City, County, State:"
                      ariaLabel="City, County, State"
                      boldLabel
                      value={values.cityCountyState}
                      items={cityCountyOptions}
                      onChange={handleCityCountyState}
                      name="cityCountyState"
                      icon="primaryArrow"
                      validators={['required']}
                      errorMessages={['Please select city/state from dropdown']}
                      error={errors['[0].cityCountyState']}
                      id="address-city-county-state"
                    />
                    <input
                      type="hidden"
                      name="cityCountyStateDisplay"
                      value={values.cityCountyStateDisplay}
                    />
                  </Fragment>
                )}
              </Grid>
            )}
            <Grid item sm={8} md={mdSzHf(3)}>
              <Input
                label="ZIP code:"
                ariaLabel="ZIP or Postal Code"
                name="postalCode"
                value={postalCode}
                onChange={handleChange}
                onBlur={handleZipCodeFocus}
                validators={['required', 'zip']}
                errorMessages={['', translateData('Zip code is not valid')]}
                error={errors['[0].postalCode']}
                autocomplete="shipping postal-code"
                id="address-zip"
                disabled={isCostcoWater}
              />
            </Grid>
          </Grid>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={8} md={mdSzHf(4)}>
              <PhoneInput
                label="Phone Number:"
                ariaLabel="Phone Number"
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={handleChangePhone}
                error={errors.phoneNumber}
                autocomplete="shipping tel"
                id="phone"
              />
            </Grid>
            <Grid item sm={4} md={mdSzHf(2)}>
              <Input
                label="Ext:"
                ariaLabel="Phone Extension"
                name="phoneExtension"
                value={values.phoneExtension}
                onChange={handleExtChange}
                error={errors.phoneExtension}
                autocomplete="shipping tel-extension"
                id="phone-ext"
              />
            </Grid>
            <Grid item sm={12} md={mdSzFl()}>
              <Input
                label="Email Address:"
                ariaLabel="Email address"
                name="email"
                value={values.email}
                onChange={handleChange}
                validators={['required', 'isEmail']}
                errorMessages={[
                  '',
                  'Email is not valid. Please use the format test@example.com',
                ]}
                error={errors.email}
                autocomplete="shipping email"
                id="email"
              />
              <label className={styles.promotionSubscription}>
                <input
                  type="checkbox"
                  checked={promotionSubscription}
                  name="promotionSubscription"
                  onChange={handlePromotionPreference}
                  size="default"
                />
                {translateData('Signup for News and Promotions')}
              </label>
              <ReCaptcha
                siteKey={captchaSiteKey}
                onVerify={handleReCaptchaVerify}
                ref={reCaptchaRef}
              />
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={12} md={12}>
              <div className={styles.requiredText}>
                <Span>Fields marked with an asterisk (*) are required.</Span>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <Input
                label="First Name:"
                ariaLabel="First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['']}
                error={errors.firstName}
                autocomplete="shipping given-name"
                id="first-name"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Input
                label="Last Name:"
                ariaLabel="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['']}
                error={errors.lastName}
                autocomplete="shipping family-name"
                id="last-name"
              />
            </Grid>
            {!isHomeSection && (
              <Grid item sm={12} md={12}>
                <Input
                  label="Company:"
                  ariaLabel="Company Name"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  validators={isHomeSection ? [] : ['required']}
                  errorMessages={isHomeSection ? [] : ['']}
                  error={errors.company}
                  autocomplete="shipping organization"
                  id="company-name"
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={12} md={12}>
              <Input
                label="Street Address:"
                ariaLabel="Street address"
                name="address1"
                value={values.address1}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['']}
                error={errors['[0].address1']}
                autocomplete="shipping address-line1"
                id="address-street-one"
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <Input
                label="Apt/Ste/Other:"
                ariaLabel="Apartment Number, Suite Number, or Other"
                name="address2"
                value={values.address2}
                onChange={handleChange}
                error={errors['[0].address2']}
                autocomplete="shipping address-line2"
                id="address-street-two"
              />
            </Grid>
          </Grid>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={12} md={8}>
              {disableCityCountyState ? (
                <Fragment>
                  <Input
                    label="City, County, State:"
                    name="cityCountyStateDisplay"
                    disabled
                    value={values.cityCountyStateDisplay}
                    validators={['required']}
                  />
                  <input
                    type="hidden"
                    name="cityCountyState"
                    value={values.cityCountyState}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Select
                    label="City, County, State:"
                    ariaLabel="City, County, State"
                    boldLabel
                    value={values.cityCountyState}
                    items={cityCountyOptions}
                    onChange={handleCityCountyState}
                    name="cityCountyState"
                    icon="primaryArrow"
                    validators={['required']}
                    errorMessages={['']}
                    error={errors['[0].cityCountyState']}
                    id="address-city-county-state"
                  />
                  <input
                    type="hidden"
                    name="cityCountyStateDisplay"
                    value={values.cityCountyStateDisplay}
                  />
                </Fragment>
              )}
            </Grid>
            <Grid item sm={8} md={4}>
              <Input
                label="ZIP code:"
                ariaLabel="ZIP or Postal Code"
                name="postalCode"
                value={postalCode}
                onChange={handleChange}
                validators={['required', 'zip']}
                errorMessages={['', translateData('Zip code is not valid')]}
                error={errors['[0].postalCode']}
                autocomplete="shipping postal-code"
                id="address-zip"
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={8} md={8}>
              <PhoneInput
                label="Phone Number:"
                ariaLabel="Phone Number"
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={handleChangePhone}
                error={errors.phoneNumber}
                autocomplete="shipping tel"
                id="phone"
              />
            </Grid>
            <Grid item sm={4} md={4}>
              <Input
                label="Ext:"
                ariaLabel="Phone Extension"
                name="phoneExtension"
                value={values.phoneExtension}
                onChange={handleExtChange}
                error={errors.phoneExtension}
                autocomplete="shipping tel-extension"
                id="phone-ext"
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <Input
                label="Email Address:"
                ariaLabel="Email address"
                name="email"
                value={values.email}
                onChange={handleChange}
                validators={['required', 'isEmail']}
                errorMessages={['', 'Email is not valid']}
                error={errors.email}
                autocomplete="shipping email"
                id="email"
              />
            </Grid>
          </Grid>
        </Fragment>
      )}

      <hr />
      <div
        className={classnames(commonStyles.actions, styles.formBottom, {
          [styles.isDrawer]: isDrawer,
        })}
      >
        {isDrawer && (
          <Button
            type="button"
            text="Cancel"
            color="gray"
            loading={isProcessing}
            onClick={drawerToggle}
          />
        )}
        <Button
          text={isDrawer ? 'Save' : 'Continue'}
          disabled={!isFilled}
          loading={isProcessing}
          aria-disabled="true"
        />
      </div>
      <input type="hidden" name="city" value={values.city} />
      <input type="hidden" name="county" value={values.county} />
      <input
        type="hidden"
        name="stateOrProvinceCode"
        value={values.stateOrProvinceCode}
      />
    </ValidatorForm>
  );
};
const formValuesType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneExtension: PropTypes.string,
  company: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  stateOrProvinceCode: PropTypes.string,
  countryCode: PropTypes.string,
  deliveryInstructions: PropTypes.string,
  customerType: PropTypes.string,
  county: PropTypes.string,
  cityCountyState: PropTypes.string,
  cityCountyStateDisplay: PropTypes.string,
});

DeliveryInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  errors: formValuesType,
  forwardedRef: PropTypes.object,
  changeCanGoForward: PropTypes.func,
  section: PropTypes.string,
  homeOffice: PropTypes.string,
  defaultValues: formValuesType,
  drawerToggle: PropTypes.func,
  isDrawer: PropTypes.bool,
  cityCountyOptions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  section: state.get(LANDING_PAGE_KEY).toJS().section,
  homeOffice: state.get(LANDING_PAGE_KEY).toJS().homeOffice,
});

const mapDispatchToProps = {
  navigateZipRedirectAction: navigateZipRedirect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryInformationForm);
