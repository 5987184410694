import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormContainer from '../FormContainer';

const Form = ({ onError, onSubmit, children, withoutWrapper, ...rest }) => {
  const formWithValidator = (
    <ValidatorForm onError={onError} onSubmit={onSubmit}>
      {children}
    </ValidatorForm>
  );

  return withoutWrapper ? (
    <div {...rest}>{formWithValidator}</div>
  ) : (
    <FormContainer {...rest}>{formWithValidator}</FormContainer>
  );
};

Form.propTypes = {
  children: PropTypes.any.isRequired,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.oneOf(['grey', 'white', 'transparent']),
  title: PropTypes.string,
  withoutWrapper: PropTypes.bool,
};

Form.defaultProps = {
  color: 'white',
  title: null,
  onSubmit: () => {},
  onError: () => {},
  withoutWrapper: false,
};

export default Form;
