import FullPressGuided from './FullPressGuided';

export const PROMO_HOCS = {
  FullPressGuided,
};

export const PROMO_TYPES = Object.keys(PROMO_HOCS).reduce(
  (acc, key) => ({ ...acc, [key]: key }),
  {},
);
