import React from 'react';
import { SnackbarProvider } from 'notistack';
import ClientLocationProvider from 'containers/ClientLocationProvider';
import App from 'containers/App';
import { isPurefloDomain, isSelfServeDomain } from 'utils/domainHelper';
import Notifier from './components/Notifier';
import { Auth0Provider } from './auth0Provider';

const WaterApp = () => {
  const ipDetect = !(isPurefloDomain() || isSelfServeDomain());
  return (
    <SnackbarProvider maxSnack={3}>
      {ipDetect && <ClientLocationProvider />}
      <Auth0Provider>
        <App />
      </Auth0Provider>
      <Notifier />
    </SnackbarProvider>
  );
};

export default WaterApp;
