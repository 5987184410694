import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import Link from 'components/ContentfulFields/Link';
import { setTheme } from 'utils/themeHelper';
import { injectVariables } from 'utils/translation';
import get from 'lodash/get';
import { H1, Div, B } from 'components/Ui';
import Button from '../Button';
import UlItem from '../UlItem';
import Feature from '../Banner/Features/Item';

export const getContentOptions = (styles, document, brandName, variables) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => <B defaultText={'C61'}>{text}</B>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const id = get(node, 'data.target.sys.contentType.sys.id');

      switch (id) {
        case 'button':
          return (
            <Button className={styles.btn} document={node.data.target}>
              {children}
            </Button>
          );
        default:
          return null;
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const embedded = children.find((child) => (child ? child.props : false));

      if (embedded) {
        return children;
      }

      if (brandName && variables) {
        return (
          <div className={setTheme(styles.subtitle, document, styles)}>
            {injectVariables(children, brandName, variables, 'C90')}
          </div>
        );
      }

      return (
        <Div
          className={setTheme(styles.subtitle, document, styles)}
          defaultText={'C62'}
        >
          {children}
        </Div>
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      if (brandName && variables) {
        return (
          <h1 className={setTheme(styles.title, document, styles)}>
            {injectVariables(children, brandName, variables, 'C91')}
          </h1>
        );
      }
      return (
        <H1
          className={setTheme(styles.title, document, styles)}
          defaultText={'C63'}
        >
          {children}
        </H1>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: () => null,
    [BLOCKS.UL_LIST]: (node, children) => (
      <div className={setTheme(styles.list, document, styles)}>
        {children.map((child) => (
          <Div
            key={child.key}
            className={setTheme(styles.listItem, document, styles)}
            defaultText={'C64'}
          >
            {child.props.children}
          </Div>
        ))}
      </div>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const id = get(node, 'data.target.sys.contentType.sys.id');

      switch (id) {
        case 'ulItem':
          return <UlItem {...node.data.target} />;
        case 'button':
          return (
            <Button className={styles.btn} document={node.data.target}>
              {children}
            </Button>
          );
        case 'feature':
          return (
            <Feature className={styles.feature} document={node.data.target}>
              {children}
            </Feature>
          );
        default:
          return null;
      }
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;
      return <Link to={uri}>{children}</Link>;
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const { fields: nodeFields } = node.data.target;
      return (
        <Link className={styles.link} to={`/${nodeFields.slug}`}>
          {children}
        </Link>
      );
    },
  },
});

export const getImagesOptions = (styles) => ({
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => (
      <Div defaultText={'C65'}>{children}</Div>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <Div defaultText={'C66'}>{children}</Div>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const {
        fields: { file, description },
      } = node.data.target;

      return (
        <div className={styles.embeddedAsset}>
          <img src={`${file.url}?fm=webp`} alt={description} />
        </div>
      );
    },
    [BLOCKS.HEADING_1]: () => null,
    [BLOCKS.PARAGRAPH]: () => null,
    [BLOCKS.EMBEDDED_ENTRY]: () => null,
    [BLOCKS.HEADING_2]: () => null,
  },
});
