import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Link from 'components/ContentfulFields/Link';
import styles from './styles.scss';

const Contacts = ({ fields }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className={styles.content}>{children}</div>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.title}>{children}</h1>
      ),
      [BLOCKS.HR]: () => <hr className={styles.divider} />,
      [BLOCKS.UL_LIST]: (node, children) => (
        <div className={styles.schedule}>{children}</div>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <div className={styles.listItem}>{children}</div>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const { data } = node;

        return (
          <Link className={styles.link} to={data.uri}>
            {children}
          </Link>
        );
      },
    },
  };

  const contacts = documentToReactComponents(fields, options);

  return <div className={styles.contactsContainer}>{contacts}</div>;
};

Contacts.propTypes = {
  fields: PropTypes.object,
};

export default Contacts;
