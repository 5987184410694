import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import { H2 } from 'components/Ui';
import Payment from './index';
import styles from './styles.scss';

const PaymentDrawer = ({ open, toggle, values, isValidPayment }) => {
  const form = useRef();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggle}
      PaperProps={{ className: styles.drawer, role: 'dialog' }}
      classes={{ root: styles.drawer }}
      disableEnforceFocus
    >
      <H2>Edit payment method</H2>
      <Payment
        forwardedRef={form}
        changeSubmitAction={() => {}}
        changeCanGoForward={() => {}}
        drawerToggle={toggle}
        values={values}
        isDrawer
        isValidPayment={isValidPayment}
      />
    </Drawer>
  );
};

PaymentDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  isValidPayment: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export default PaymentDrawer;
