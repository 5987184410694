import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { white } from 'theme/_variables.scss';

const loadingImg = require('./loading.svg');

const IndicatorWrapperContainer = ({ local, ...rest }) => <div {...rest} />;

const IndicatorWrapper = styled(IndicatorWrapperContainer)`
  display: flex;
  justify-content: center;
  height: ${(props) => (props.local ? 'auto' : '100vh')};
  width: ${(props) => (props.local ? 'auto' : '100vw')};
  background-color: ${(props) => (props.local ? 'none' : white)};
  padding: ${(props) => (props.local ? '10px 0' : 0)};
`;

export const LoadingIndicator = ({ local, className }) => (
  // IE11 width/height
  <IndicatorWrapper local={local} className={className}>
    <img src={loadingImg} alt="loading" width="100" height="100" />
  </IndicatorWrapper>
);

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  local: PropTypes.bool,
};

LoadingIndicator.displayName = 'LoadingIndicator';

export default LoadingIndicator;
