/* Literal hacks */

// HACK displays 3rd party (OneTrust) cookie prefs modal via onclick of css-selected links
export const makeOneTrustTogglesOn = (cssSelector) => {
  document.querySelectorAll(cssSelector).forEach((link) => {
    link.onclick = (e) => {
      e.preventDefault();
      if (!window.Optanon) {
        console.warn( // eslint-disable-line
          'Optanon not found. OneTrust likely not loaded.',
        );
        return;
      }
      window.Optanon.ToggleInfoDisplay();
    };
  });
};
