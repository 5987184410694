import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CheckIcon } from 'components/Ui/CustomIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50px',
    height: '50px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.river}`,
    borderRadius: '30px',
    fontSize: '0.875rem',
    fontWeight: 900,
    lineHeight: '0.75rem',
    textAlign: 'center',
    color: theme.colors.pond,
  },
  completed: {
    color: theme.colors.black,
    backgroundColor: theme.colors.ice,
    border: `1px solid ${theme.colors.river}`,
  },
  '@keyframes borderPulsate': {
    from: {
      transform: 'scale(1)',
      '-webkit-transform': 'scale(1)',
      opacity: 0.25,
    },
    '10%': {
      transform: 'scale(1.1)',
      '-webkit-transform': 'scale(1.1)',
      opacity: 0.4,
    },
    '20%': {
      transform: 'scale(1)',
      '-webkit-transform': 'scale(1)',
      opacity: 0.25,
    },
    to: {
      transform: 'scale(1)',
      '-webkit-transform': 'scale(1)',
      opacity: 0.25,
    },
  },
  active: {
    color: theme.colors.black,
    backgroundColor: theme.colors.ice,
    border: `1px solid ${theme.colors.river}`,
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      top: '-4px',
      left: '-4px',
      right: '-4px',
      bottom: '-4px',
      transform: 'scale(1)',
      opacity: 0.25,
      borderRadius: '22px',
      animationDuration: '3.5s',
      animationDelay: '3s',
      animationName: '$borderPulsate',
      animationIterationCount: 'infinite',
    },
  },
  screenreaderOnly: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
  black: {
    color: theme.colors.black,
    margin: 'auto',
  },
  white: {
    color: theme.colors.pond,
    margin: 'auto',
  },
  displayOnly: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: 'auto',
  },
}));

const StepButton = ({ icon, active, completed, step, isDisplayOnly }) => {
  const classes = useStyles();
  const theme = useTheme();

  const displayLabel = () => (
    <Fragment>
      <span className={classes.screenreaderOnly}>{`Step ${icon} ${
        active ? 'Currently Active' : ''
      }: ${step}`}</span>
      <span className={classes.screenreaderOnly}>
        {completed ? 'Completed' : ''}
      </span>
      <span
        className={active ? classes.black : classes.white}
        aria-hidden="true"
      >
        {completed ? <CheckIcon color={theme.colors.black} /> : icon}
      </span>
    </Fragment>
  );
  return isDisplayOnly ? (
    <Box
      classes={{
        root: classnames(
          classes.root,
          classes.displayOnly,
          { [classes.completed]: completed },
          { [classes.active]: active },
          { [classes.displayOnlyActive]: active },
        ),
      }}
    >
      {displayLabel()}
    </Box>
  ) : (
    <ButtonBase
      classes={{
        root: classnames(
          classes.root,
          { [classes.completed]: completed },
          { [classes.active]: active },
        ),
      }}
    >
      {displayLabel()}
    </ButtonBase>
  );
};

StepButton.propTypes = {
  step: PropTypes.string,
  icon: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  isDisplayOnly: PropTypes.bool,
};

export default StepButton;
