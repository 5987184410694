import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import { Dialog, Button, Form, Input } from 'components/Ui';
import styles from './styles.scss';

const LeadsDialog = ({
  open,
  onClose,
  onSubmit,
  content,
  validateEmail,
  brandName,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({ firstName: '', email: '' });
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(true);
  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(open);
  const [popupContent, setPopupContent] = useState();
  const [brandedLogo, setBrandedLogo] = useState();
  const contentKeys = {
    title: 'Title',
    successTitle: 'Success Title',
    successMessage: 'Success Message',
    popup: 'Popup',
    logo: 'Logo',
    excludePaths: 'Exclude Paths',
  };

  const emailInputRef = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const pc = Object.assign(
      {},
      ...content.popupContent.fields.content.map((c) => ({
        [`${(c.fields.entryTitle || c.fields.name).split(' > ').pop()}`]: c,
      })),
    );
    setPopupContent(pc);

    setBrandedLogo(
      pc[contentKeys.logo].fields.media.find((m) =>
        m.metadata.tags.some(
          (t) =>
            t.sys.id.toLowerCase() ===
            brandName.split(' ').join('').toLowerCase(),
        ),
      ),
    );
  }, [content]);

  useEffect(() => {
    const leadsDialogStatus = localStorage.getItem('LeadsDialogStatus');
    setIsDialogOpen(leadsDialogStatus !== 'hide');

    if (popupContent) {
      const excludedPahts =
        popupContent[contentKeys.excludePaths].fields.jsonData;

      const isDialogExcluded = excludedPahts.some(
        (ep) => ep.toLowerCase() === window.location.pathname.toLowerCase(),
      );

      if (isDialogExcluded) {
        setIsDialogOpen(false);
        onClose();
      }
    }
  }, [popupContent]);

  useEffect(() => {
    if (!isVerifiedEmail && emailInputRef) {
      emailInputRef.current.focus();
    }
  }, [isVerifiedEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidEmail = await validateEmail(formData.email, isEmailValidated);
    setIsEmailValidated(true);
    setIsVerifiedEmail(isValidEmail);

    if (isValidEmail) {
      onSubmit(formData);
      setIsSubmitted(true);
    }
  };

  const handleClose = () => {
    localStorage.setItem('LeadsDialogStatus', 'hide');
    setIsDialogOpen(false);
    onClose();
  };

  const renderContent = () => {
    if (isSubmitted) {
      return (
        <div>
          <Typography variant="h4" className={styles.header}>
            {popupContent[contentKeys.successTitle].fields.text}
          </Typography>
          <Typography className={styles.successMessage}>
            {popupContent[contentKeys.successMessage].fields.text}
          </Typography>
          <Button
            text="SHOP NOW"
            className={styles.btn}
            onClick={handleClose}
          />
        </div>
      );
    }

    return (
      <div>
        <Typography variant="h4" className={styles.header}>
          {popupContent[contentKeys.title].fields.text}
        </Typography>
        <Form
          withoutWrapper
          className={styles.formContainer}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Input
                label="First name"
                autocomplete="given-name"
                value={formData.firstName}
                onChange={handleChange}
                name="firstName"
                id="firstName"
              />
            </Grid>
            <Grid item>
              <Input
                label="Email"
                autocomplete="email"
                validators={['required', 'isEmail']}
                errorMessages={['Email is required', 'Email is not valid']}
                value={formData.email}
                onChange={handleChange}
                name="email"
                id="email"
                inputRef={emailInputRef}
              />
            </Grid>
            <Grid item>
              {!isVerifiedEmail && (
                <Typography variant="body2" style={{ marginBottom: '10px' }}>
                  {content.emailConfirmation}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Button
                    type="submit"
                    text="SIGN UP"
                    className={styles.btn}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    text="No thanks"
                    onClick={handleClose}
                    link
                    className={styles.linkButton}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  };

  return popupContent ? (
    <Dialog
      size="medium"
      open={isDialogOpen}
      isCloseIcon
      onClose={handleClose}
      noPaddingContent
      className={styles.leadsDialog}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {!isMobile && (
          <Grid item xs={12} md={4}>
            <img
              src={
                popupContent[contentKeys.popup].fields.media[0].fields.file.url
              }
              alt=""
              className={styles.dialogImage}
            />
          </Grid>
        )}
        <Grid item xs={12} md={8} className={styles.contentContainer}>
          {renderContent()}
        </Grid>
      </Grid>
    </Dialog>
  ) : (
    <></>
  );
};

export default LeadsDialog;
