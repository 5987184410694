import styled from 'styled-components';
import { themeConfig as theme } from 'theme/themeConfig';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.darkGrey};
`;

Divider.displayName = 'Divider';

export default Divider;
