import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Redirect } from 'react-router-dom';
import {
  selectIsFetching,
  selectIsAuthenticated,
  selectIsInitiated,
} from 'containers/Authentication/selectors';
import { isWaterRegionalTheme } from 'utils/common';

class PrivateRoute extends PureComponent {
  render() {
    const {
      render: Component,
      isFetching,
      isAuthenticated,
      isInitiated,
      ...rest
    } = this.props;
    const { stylesTheme } = this.context;

    if (isAuthenticated) {
      return <Route {...rest} render={Component} />;
    }

    return (
      <Redirect
        to={{
          pathname: isWaterRegionalTheme(stylesTheme) ? '/' : '/signin',
          state: { prevPath: this.props.location.pathname },
        }}
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  isFetching: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};

PrivateRoute.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  isAuthenticated: selectIsAuthenticated(),
  isInitiated: selectIsInitiated(),
});

export default connect(mapStateToProps)(PrivateRoute);
