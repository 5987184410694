import { isAcquisitionDomain, isSelfServeDomain } from 'utils/domainHelper';
import { isSsr, getWindowObj } from 'utils/ssrHelper';

export const dataLayerPush = (
  domains,
  data,
  test = '',
  success = '',
  successCallback = () => {},
  failureCallback = () => {},
) => {
  const windowObj = getWindowObj();
  // console.log('DDD ~ dataLayerPush', test, domains, data.event, data);
  if (isSsr()) {
    return;
  }
  if (success) {
    // So how this works is if the caller passes a success string like 'PUSH_???_DATA'
    // it will be added to a global object, for now, and skip the dataLayer push
    // Then if the saga is successful, it will call dataLayerPushSuccess('PUSH_???_DATA');
    // which will continue to process the dataLayer push and remove the success data from the global object
    // If the saga has an exception, it will call dataLayerPushFailure('PUSH_???_DATA');
    // which will clean up the global object by and removing the success data from the global object
    // So the caller of dataLayerPush should be re-arranged so that the dataLayerPush is called before
    // the call to the saga, and the saga needs call dataLayerPushSuccess upon success and
    // call dataLayerPushFailure upon failure.
    // If the caller also sends a successCallback function, it will be called upon success.
    // If the caller also sends a failureCallback function, it will be called upon failure.

    if (!windowObj.dataLayerPushSuccess) {
      windowObj.dataLayerPushSuccess = {};
    }
    windowObj.dataLayerPushSuccess[success] = {
      domains,
      data,
      test,
      successCallback,
      failureCallback,
    };
    return;
  }
  const pushCostcoWater =
    windowObj.isCostcoWater &&
    (domains.includes('CostcoWater') || domains.includes('Acquisition'));
  const pushAcquisition =
    isAcquisitionDomain() && domains.includes('Acquisition');
  const pushSelfServe = isSelfServeDomain() && domains.includes('SelfServe');
  if (pushAcquisition) {
    // console.log('DDD ~ Acquisition', test, data.event, data);
    dataLayer.push(data);
  } else if (pushSelfServe) {
    // console.log('DDD ~ SelfServe', test, data.event, data);
    dataLayer.push(data);
  } else if (pushCostcoWater) {
    // console.log('DDD ~ CostcoWater', test, data.event, data);
    dataLayer.push(data);
  }
};

export const dataLayerPushSuccess = (success) => {
  const windowObj = getWindowObj();
  const successData =
    windowObj.dataLayerPushSuccess && windowObj.dataLayerPushSuccess[success];
  if (successData) {
    dataLayerPush(
      successData.domains,
      { ...successData.data },
      successData.test,
    );
    successData.successCallback.call();
    delete windowObj.dataLayerPushSuccess[success];
  }
};

export const dataLayerPushFailure = (success) => {
  const windowObj = getWindowObj();
  const successData =
    windowObj.dataLayerPushSuccess && windowObj.dataLayerPushSuccess[success];
  if (successData) {
    successData.failureCallback.call();
    delete windowObj.dataLayerPushSuccess[success];
  }
};
