import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Default, Mobile } from 'components/Media';
import Button from '../Button';

import styles from './styles.scss';

const SelfServeBanner = ({ document }) => {
  const {
    fields: { entryTitle, bottomButton, desktopImage, mobileImage, linkUrl },
  } = document;

  return (
    <section className={styles.container} data-tracking={entryTitle}>
      <Link className={styles.imgWrapper} to={linkUrl}>
        <Default>
          <img
            src={`${desktopImage.fields.file.url}?fm=webp`}
            alt={desktopImage.fields.file.description}
          />
        </Default>
        <Mobile>
          <img
            src={`${mobileImage.fields.file.url}?fm=webp`}
            alt={mobileImage.fields.file.description}
          />
        </Mobile>
      </Link>
      <div className={styles.bottomButtonWrapper}>
        <Button document={bottomButton} to={bottomButton.fields.link} />
      </div>
    </section>
  );
};

SelfServeBanner.propTypes = {
  document: PropTypes.object.isRequired,
};

export default SelfServeBanner;
