import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { createStructuredSelector } from 'reselect';
import Loader from 'components/Loader';
import cookies from 'js-cookie';
import { SECTION_HOME, SECTION_OFFICE } from 'containers/Landing/constants';
import {
  selectLandingData,
  // selectSection,
  selectSiteSlug,
  selectAcquisitionVariables,
  selectCostcoVariables,
  selectUSEnglishResourceBundle,
  selectCanadaFrenchResourceBundle,
  selectCanadaEnglishResourceBundle,
} from 'containers/Landing/selectors';
import { resetShopDeliveryOrder } from 'containers/PrimoAccount/actions';
import {
  setSection,
  // getFeatureTour,
  fetchEntry,
  fetchVariables,
  fetchResourceBundles,
} from 'containers/Landing/actions';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { selectZipCode } from 'containers/ClientLocationProvider/selectors';
import { fetchUserRegion } from 'containers/UserRegion/actions';
import {
  selectIsInitiated,
  selectIsFetching,
  selectIsAuthenticated,
} from 'containers/Authentication/selectors';
import {
  isPurefloDomain,
  isAcquisitionDomain,
  isSelfServeDomain,
} from 'utils/domainHelper';
import { isSsr } from 'utils/ssrHelper';

const parseSearch = (search) => qs.parse(search, { ignoreQueryPrefix: true });

class RouteProvider extends Component {
  constructor(props) {
    super(props);

    const slug = props.slug || '';
    if (slug.startsWith('acquisition')) {
      let theSection;
      const search = parseSearch(this.props.location.search);
      const { customerType } = search;

      if (customerType) {
        if (customerType.toUpperCase() === 'RESIDENTIAL') {
          theSection = SECTION_HOME;
        } else {
          theSection = SECTION_OFFICE;
        }
        cookies.set('section', theSection);
      } else {
        theSection = cookies.get('section');
      }

      if (!theSection) {
        const path = props.location.pathname;
        theSection =
          path.includes('office') ||
          path.includes('BreakroomSupplies') ||
          path.includes('CoffeeTeaAndHotChocolate')
            ? SECTION_OFFICE
            : path.includes('/products/')
            ? SECTION_HOME
            : undefined;
      }

      if (theSection) {
        this.safeSetSection(theSection);
      }
    }
  }

  safeSetSection = (section) => {
    if (section !== this.props.section) {
      this.props.setSection(section);
    }
  };

  componentDidMount() {
    const search = parseSearch(this.props.location.search);
    const { postalCode: queryPostalCode } = search;
    const {
      isServersideRendering,
      acquisitionVariables,
      costcoVariables,
      usEnglishResourceBundle,
      canadaFrenchResourceBundle,
      canadaEnglishResourceBundle,
    } = this.props;

    if (queryPostalCode && !this.props.isAuthenticated) {
      this.props.fetchUserRegion(queryPostalCode);
    }
    if (!isServersideRendering) {
      if (
        usEnglishResourceBundle &&
        canadaFrenchResourceBundle &&
        canadaEnglishResourceBundle
      ) {
        window.canadaFrenchResourceBundle = canadaFrenchResourceBundle;
        window.canadaEnglishResourceBundle = canadaEnglishResourceBundle;
        window.usEnglishResourceBundle = usEnglishResourceBundle;
      } else {
        this.props.fetchResourceBundles();
      }

      if (window.isCostcoWater || isSelfServeDomain()) {
        this.props.fetchEntry({
          entryId: 'XznAGn2qMYukyOBFrDdVC',
          key: 'primoTransitionBanner',
        });
      }

      if (window.isCostcoWater) {
        if (isEmpty(costcoVariables)) {
          this.props.fetchVariables({
            title: '4tB2HFOLeEaQZQBRTJB4F2',
            key: 'costcoVariables',
          });
        }
      }

      if (isAcquisitionDomain() || isSelfServeDomain()) {
        if (isEmpty(acquisitionVariables)) {
          // For now Acquisition and Self Serve will use the same variables
          this.props.fetchVariables({
            title: '5Yb812r4dC1Oc19T3UXS8m', // Acquisition > Landing > Variables
            brandTitle: '5coHCEQ6FD1ECzrYSD44kE', // Acquisition > Branded > Variables
            key: 'acquisitionVariables',
          });
        }
      }

      if (isSelfServeDomain()) {
        /* Disabled as per CX-2113, could be enabled in future
        if (!localStorage.getItem('displayFeatureTour')) this.props.getFeatureTour();
        */
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      // section,
      location,
      history,
      postalCode,
      locationPostalCode,
      isAuthenticated,
    } = this.props;
    if (location.pathname === '/callback') return;
    if (
      !location.pathname.startsWith('/account/products') &&
      !location.pathname.startsWith('/account/future-orders') &&
      isAuthenticated
    ) {
      this.props.resetShopDeliveryOrder();
    }

    const search = parseSearch(location.search);
    const prevSearch = parseSearch(prevProps.location.search);
    const { postalCode: queryPostalCode } = search;

    if (
      location.pathname !== prevProps.location.pathname ||
      location.search !== prevProps.location.search
    ) {
      // TODO: Leaving this in for now until fully tested or if still needed
      // const condition = false; // isAcquisitionDomain() && slug === SPARKLETTS_SLUG
      // if (condition) {
      //   const isQueryOfficeSection =
      //     parseSearch(prevProps.location.search).section === SECTION_OFFICE; // TODO: this is not a fool proof method for detecting office
      //   const isPageShared = !isEmpty(
      //     sharedRoutes.filter((route) =>
      //       location.pathname.match(new RegExp(route)),
      //     ),
      //   );

      //   if (
      //     (section === SECTION_OFFICE || isQueryOfficeSection) &&
      //     isPageShared
      //   ) {
      //     history.push({
      //       search: qs.stringify({ ...search, section: SECTION_OFFICE }),
      //     });
      //   }
      // }
      if (prevSearch.serviceLocationId && !search.serviceLocationId) {
        history.replace({
          search: qs.stringify({
            ...search,
            serviceLocationId: prevSearch.serviceLocationId,
          }),
        });
      }
    }

    if (!isAuthenticated && !isAcquisitionDomain()) {
      if (postalCode && queryPostalCode !== postalCode) {
        history.replace({ search: qs.stringify({ ...search, postalCode }) });
      }
      if (locationPostalCode && !postalCode && !queryPostalCode) {
        this.props.fetchUserRegion(locationPostalCode);
        history.replace({
          search: qs.stringify({ ...search, postalCode: locationPostalCode }),
        });
      }
    }
  }

  render() {
    const {
      authIsInitiated,
      authIsFetching,
      isServersideRendering,
    } = this.props;
    const isPureflo = isPurefloDomain();
    return (
      <Loader
        loading={
          !isServersideRendering &&
          !isPureflo &&
          !window.isCostcoWater &&
          !isAcquisitionDomain() &&
          (!authIsInitiated || authIsFetching)
        }
      >
        {this.props.children}
      </Loader>
    );
  }
}

RouteProvider.propTypes = {
  slug: PropTypes.string,
  // section: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  postalCode: PropTypes.string,
  locationPostalCode: PropTypes.string,
  children: PropTypes.node,
  fetchUserRegion: PropTypes.func,
  setSection: PropTypes.func,
  authIsInitiated: PropTypes.bool.isRequired,
  authIsFetching: PropTypes.bool.isRequired,
  isServersideRendering: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  // getFeatureTour: PropTypes.func,
  resetShopDeliveryOrder: PropTypes.func,
  fetchEntry: PropTypes.func,
  fetchVariables: PropTypes.func,
  acquisitionVariables: PropTypes.array,
  costcoVariables: PropTypes.array,
};

const selectIsSsr = () => () => isSsr();

const mapStateToProps = createStructuredSelector({
  site: selectLandingData(),
  postalCode: selectPostalCode(),
  locationPostalCode: selectZipCode(),
  // section: selectSection(),
  slug: selectSiteSlug(),
  authIsInitiated: selectIsInitiated(),
  authIsFetching: selectIsFetching(),
  isAuthenticated: selectIsAuthenticated(),
  isServersideRendering: selectIsSsr(),
  acquisitionVariables: selectAcquisitionVariables(),
  costcoVariables: selectCostcoVariables(),
  usEnglishResourceBundle: selectUSEnglishResourceBundle(),
  canadaFrenchResourceBundle: selectCanadaFrenchResourceBundle(),
  canadaEnglishResourceBundle: selectCanadaEnglishResourceBundle(),
});

const mapDispatchToProps = {
  fetchUserRegion,
  setSection,
  // getFeatureTour,
  resetShopDeliveryOrder,
  fetchEntry,
  fetchVariables,
  fetchResourceBundles,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RouteProvider);
