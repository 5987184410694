import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { flatten, uniqBy, get, filter, compact } from 'lodash';

import {
  LANDING_PAGE_KEY,
  SECTION_HOME,
  SECTION_OFFICE,
} from 'containers/Landing/constants';
import { hasTag } from 'components/Content/utils';

const selectLanding = () => (state) => state.get(LANDING_PAGE_KEY, Map());

export const selectLandingData = () =>
  createSelector(selectLanding(), (landing) => landing.toJS());

export const selectIsLoading = () =>
  createSelector(selectLanding(), (landing) => landing.get('loading'));

export const selectSiteName = () =>
  createSelector(selectLanding(), (landing) => landing.get('name'));

export const selectSiteSlug = () =>
  createSelector(selectLanding(), (landing) => landing.get('slug'));

export const selectPageSlug = () =>
  createSelector(selectLanding(), (landing) =>
    get(landing.getIn(['components', 'page']), 'slug'),
  );

export const selectIsProductPage = () =>
  createSelector(
    selectPageSlug(),
    (pageSlug) =>
      pageSlug === 'bottled-water-and-coffee' ||
      pageSlug === 'office-bottled-water-and-coffee' ||
      pageSlug === 'home-bottled-water-and-coffee' ||
      pageSlug === 'home-bottled-water-and-coffee-2' ||
      pageSlug === 'office-bottled-water-and-coffee-2',
  );

export const selectProductListItems = () =>
  createSelector(
    selectLanding(),
    selectIsProductPage(),
    (landing, isProductPage) => {
      const page = landing.getIn(['components', 'page']);

      if (isProductPage) {
        const productListModule = page.contentModules.find(
          (modules) => modules.sys.contentType.sys.id === 'productsList',
        );
        const productListItems = get(productListModule, 'fields.products');
        const uniqProducts = uniqBy(
          filter(compact(productListItems), (product) =>
            get(product, 'fields.itemNumber'),
          ),
          'fields.itemNumber',
        );

        return uniqProducts;
      }

      return [];
    },
  );

export const selectProductCategoriesItems = () =>
  createSelector(
    selectLanding(),
    selectIsProductPage(),
    (landing, isProductPage) => {
      const page = landing.getIn(['components', 'page']);

      if (isProductPage) {
        const productsCategoriesItems = flatten(
          page.contentModules
            .filter(
              (modules) =>
                get(modules, 'sys.contentType.sys.id') === 'productCategory',
            )
            .map((category) =>
              hasTag(category, 'productBundle')
                ? flatten(
                    category.fields.products.map((bundle) =>
                      bundle.fields.items.map(
                        (bundleItem) => bundleItem.fields.product,
                      ),
                    ),
                  )
                : get(category, 'fields.products'),
            ),
        );

        const uniqProducts = uniqBy(
          productsCategoriesItems,
          'fields.itemNumber',
        );

        return uniqProducts;
      }

      return [];
    },
  );

export const selectStylesTheme = () =>
  createSelector(selectLanding(), (landing) => landing.get('theme'));

export const selectForceFetch = () =>
  createSelector(selectLanding(), (landing) => landing.get('forceFetch'));

export const selectSection = () =>
  createSelector(selectLanding(), (landing) => landing.get('section'));

export const selectHomeOffice = () =>
  createSelector(selectLanding(), (landing) => landing.get('homeOffice'));

export const selectIsBrandFetching = () =>
  createSelector(selectLanding(), (landing) => landing.get('fetchingBrand'));

export const selectShowBrandSwitchDialog = () =>
  createSelector(selectLanding(), (data) => data.get('showBrandSwitchDialog'));

export const selectShowInitialZipDialog = () =>
  createSelector(selectLanding(), (landing) =>
    landing.get('showInitialZipDialog'),
  );

export const selectShowCostcoMembershipDialog = () =>
  createSelector(selectLanding(), (data) =>
    data.get('showCostcoMembershipDialog'),
  );

export const selectCostcoAccount = () =>
  createSelector(selectLanding(), (landing) => landing.get('costcoAccount'));

export const selectLayouts = () =>
  createSelector(selectLanding(), (landing) => landing.get('layouts'));

export const selectHeaderFooter = () =>
  createSelector(selectLayouts(), selectSection(), (layouts, section) => {
    if (!layouts) {
      return null;
    }

    if (!section) {
      return layouts[0].fields;
    }

    if (section === SECTION_HOME) {
      const currentLayout = layouts.find(
        (sectionLayout) => sectionLayout.fields.slug === 'residential',
      );

      if (currentLayout) {
        return currentLayout.fields;
      }
    } else if (section === SECTION_OFFICE) {
      const currentLayout = layouts.find(
        (sectionLayout) => sectionLayout.fields.slug === 'commercial',
      );

      if (currentLayout) {
        return currentLayout.fields;
      }
    }

    return layouts[0].fields;
  });

export const selectFeatureTour = () =>
  createSelector(selectLanding(), (landing) =>
    landing.getIn(['featureTour', 'video']),
  );

export const selectDisplayFeatureTour = () =>
  createSelector(selectLanding(), (landing) =>
    landing.getIn(['featureTour', 'displayFeatureTour']),
  );

export const selectLogo = () =>
  createSelector(selectLanding(), (landing) => landing.getIn(['logo', 'logo']));

export const selectPrimoTransitionBanner = () =>
  createSelector(selectLanding(), (landing) =>
    landing.getIn(['entries', 'primoTransitionBanner']),
  );

export const selectAcquisitionVariables = () =>
  createSelector(selectLandingData(), (landing) =>
    get(landing, 'entries.acquisitionVariables'),
  );

export const selectCostcoVariables = () =>
  createSelector(selectLandingData(), (landing) =>
    get(landing, 'entries.costcoVariables'),
  );

export const selectUSEnglishResourceBundle = () =>
  createSelector(selectLandingData(), (landing) =>
    get(landing, 'entries.usEnglishResourceBundle'),
  );

export const selectCanadaFrenchResourceBundle = () =>
  createSelector(selectLandingData(), (landing) =>
    get(landing, 'entries.canadaFrenchResourceBundle'),
  );

export const selectCanadaEnglishResourceBundle = () =>
  createSelector(selectLandingData(), (landing) =>
    get(landing, 'entries.canadaEnglishResourceBundle'),
  );

export const selectPromotionLandingPageData = () =>
  createSelector(selectLandingData(), (landing) =>
    get(landing, 'promotionLandingPageData.data'),
  );

export const selectSsrReqRoute = () =>
  createSelector(selectLanding(), (landing) => landing.get('ssrReqRoute'));

export const selectIsPageNotFound = () =>
  createSelector(selectLanding(), (landing) => landing.get('pageNotFound'));

export const selectZipCodeConfirmed = () =>
  createSelector(selectLanding(), (data) => data.get('showZipCodeEditPopper'));

export const selectGclId = () =>
  createSelector(selectLanding(), (data) => data.get('gclId'));

export const selectLanguage = () =>
  createSelector(selectLanding(), (data) => data.get('language'));

export const selectSteps = () =>
  createSelector(selectLanding(), (data) => data.get('steps').toJS());
