import sassVariables from 'theme/_variables.scss';

export const themeOverrides = {
  overrides: {
    MuiAppBar: {
      colorPrimary: sassVariables.white,
    },
    MuiGrid: {
      item: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      },
      container: {
        marginTop: '0px !important',
        marginBottom: '0px !important',
      },
    },
  },
};
