const styles = {
  rootLabel: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: '900',
    marginBottom: '10px',
  },
};

export default styles;
