import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, MobileStepper } from '@material-ui/core';
import get from 'lodash/get';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import jsStyles from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Slider extends PureComponent {
  state = {
    activeStep: 0,
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  onSliderClick = (itemIndex) => {
    const { images } = this.props;

    this.setState({
      activeStep: (itemIndex + 1) % images.length,
    });
  };

  render() {
    const { classes, theme, images } = this.props;

    const { activeStep } = this.state;
    const maxSteps = images.length;

    return (
      <div className={classes.root}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
          interval={10000}
        >
          {images.map((image, index) => (
            <div
              className={classes.item}
              key={image.sys.id}
              onClick={this.onSliderClick.bind(this, index)}
              onKeyPress={this.onSliderClick.bind(this, index)}
              role="presentation"
            >
              <img
                className={classes.img}
                src={`${get(image, 'fields.file.url', null)}?fm=webp`}
                alt={get(image, 'fields.description', null)}
              />
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          variant="dots"
          classes={{
            root: classes.mobileStepper,
            dot: classes.dot,
            dotActive: classes.dotActive,
          }}
        />
      </div>
    );
  }
}

Slider.propTypes = {
  images: PropTypes.array,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

Slider.defaultProps = {
  images: [],
};

export default withStyles(jsStyles, { withTheme: true })(Slider);
