import { fromJS } from 'immutable';
import * as types from './constants';

export const initialState = fromJS({
  loading: true,
  data: {},
});

function clientLocationReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CLIENT_LOCATION_REQUEST:
      return state.set('loading', true);
    case types.GET_CLIENT_LOCATION_SUCCESS:
      return state
        .set('data', fromJS(action.payload.data))
        .set('loading', false);
    case types.GET_CLIENT_LOCATION_FAILURE:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default clientLocationReducer;
