import React from 'react';
import { get, isArray } from 'lodash';
import { Link } from 'react-router-dom';
import { translateData, getToUrl } from 'utils/translation';

export function linkParser(node, children, linkClass = '') {
  if (isArray(children) && children.length === 1 && children[0] === '') {
    return null;
  }
  const uri = get(node, 'data.uri', '/');
  const external =
    uri.includes('http') || uri.startsWith('tel:') || uri.startsWith('mailto:');

  if (external) {
    const linkTitle = uri.startsWith('tel:')
      ? 'Call us'
      : uri.startsWith('mailto:')
      ? 'Mail us'
      : null;

    return linkClass ? (
      <span className={linkClass}>
        <a href={uri} target="_blank" title={linkTitle}>
          {translateData(children, 'C49')}
        </a>
      </span>
    ) : (
      <a href={uri} target="_blank" title={linkTitle}>
        {translateData(children, 'C50')}
      </a>
    );
  }
  const linkTarget =
    uri === '/costcowater-terms' ||
    uri === '/costcowater-privacy' ||
    uri === '/costcowater-service' ||
    uri === '/costcowater-ccpa'
      ? '_blank'
      : '_self';

  return (
    <Link className={linkClass} to={getToUrl(uri, 'U21')} target={linkTarget}>
      {translateData(children, 'C51')}
    </Link>
  );
}
