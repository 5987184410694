import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ContentfulFields from 'components/ContentfulFields';

const Content = ({ data, ...rest }) => {
  if (!data) return null;

  return (
    <ContentfulFields
      type={get(data, 'sys.contentType.sys.id')}
      document={data}
      key={data.sys.id}
      {...rest}
    />
  );
};

Content.propTypes = {
  data: PropTypes.object,
};

export default Content;
