import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import classnames from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { selectSteps } from 'containers/Landing/selectors';
import { setSteps as setStepsAction } from 'containers/Landing/actions';
import { IconButton } from 'components/Ui';
import Button from 'components/ContentfulFields/Button';
import { useIsMobile } from 'utils/hooks';
import styles from './styles.scss';

const CoolerCleaningServiceStep2 = ({ document, steps, setSteps }) => {
  const { currentStep, step1, step2 } = steps;
  const isMobile = useIsMobile();

  const days = 'Monday Tuesday Wednesday Thursday Friday';

  const canContinue = () =>
    step2.Monday ||
    step2.Tuesday ||
    step2.Wednesday ||
    step2.Thursday ||
    step2.Friday;

  const handleSelectToggle = (day) => {
    step2[day] = !step2[day];
    setSteps({ step1, step2, currentStep: 2 });
  };

  const handleButtonClick = () => {
    if (canContinue()) {
      setSteps({ step1, step2, currentStep: 3 });

      window.scrollTo({ top: 0 });
    }
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.title}>{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={styles.tbd1}>{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const nodeDocument = get(node, 'content[1].data.target');
        const id = get(nodeDocument, 'sys.contentType.sys.id');

        switch (id) {
          case 'button':
            return (
              <Button
                document={nodeDocument}
                className={classnames({
                  [styles.btn]: true,
                  [styles.disabled]: !canContinue(),
                })}
                onClick={handleButtonClick}
              >
                {children}
              </Button>
            );
          default: {
            if (children && children.length && days.includes(children[0])) {
              const selected = step2[children[0]];
              const day = children[0];
              return (
                <div className={styles.day}>
                  <IconButton
                    className={classnames({
                      [styles.selected]: selected,
                    })}
                    onClick={() => handleSelectToggle(day)}
                    onKeyPress={() => handleSelectToggle(day)}
                    tabIndex="0"
                  >
                    {day}
                  </IconButton>
                </div>
              );
            }
            return <div className={styles.paragraph}>{children}</div>;
          }
        }
      },
    },
  };

  if (isMobile && currentStep !== 2) {
    return null;
  }

  let content1 = null;
  let content2 = null;
  let content3 = null;
  if (document) {
    const content = get(
      document,
      'fields.content.content[0].content[1].data.target.fields.content.content',
      '[]',
    );
    content1 = documentToReactComponents(
      {
        data: {},
        nodeType: 'document',
        content: content.slice(0, 3),
      },
      options,
    );
    content2 = documentToReactComponents(
      {
        data: {},
        nodeType: 'document',
        content: content.slice(3, 8),
      },
      options,
    );

    content3 = documentToReactComponents(
      {
        data: {},
        nodeType: 'document',
        content: content.slice(8, 10),
      },
      options,
    );
  }

  return (
    <div className={styles.ccsBanner}>
      {isMobile && <div className={styles.step}>Step 2</div>}
      <div className={styles.content1}>{content1}</div>
      <div className={styles.content2}>{content2}</div>
      <div className={styles.content3}>{content3}</div>
    </div>
  );
};

CoolerCleaningServiceStep2.propTypes = {
  document: PropTypes.object.isRequired,
};

CoolerCleaningServiceStep2.defaultProps = {
  setSteps: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  steps: selectSteps(),
});

const mapDispatchToProps = {
  setSteps: setStepsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoolerCleaningServiceStep2);
