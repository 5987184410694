import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';
import { Map, List } from 'immutable';
import { createSelector } from 'reselect';

import { PROFILE_PAGE_KEY } from './constants';

export const selectBillingInformation = () => (state) =>
  state.get(PROFILE_PAGE_KEY, Map());

export const selectIsBillingInformationFetching = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isFetching', false),
  );

export const selectCreditCards = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('creditCards').toJS(),
  );

export const selectAutopayCreditCards = () =>
  createSelector(selectBillingInformation(), (data) =>
    data
      .get('creditCards')
      .filter((card) => !!card.get('autopay'))
      .toJS(),
  );

export const selectOneTimeCreditCard = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('oneTimeCreditCard'),
  );

export const selectBankAccounts = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('bankAccounts').toJS(),
  );

export const selectPaymentMethods = () =>
  createSelector(
    selectBankAccounts(),
    selectCreditCards(),
    selectOneTimeCreditCard(),
    (banks, cards, tmpcc) =>
      orderBy(compact([...cards, tmpcc, ...banks]), 'autopay', 'desc'),
  );

export const selectCurrentPaymentMethod = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('currentPaymentMethod'),
  );

export const selectCurrentPaymentMethodId = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.getIn(['currentPaymentMethod', 'id']),
  );

export const selectPaymentMethodsLoaded = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('paymentMethodsLoaded'),
  );

export const selectPaymentMethodsIsFetching = () =>
  createSelector(
    selectBillingInformation(),
    (data) =>
      data.get('creditCardsIsFetching') || data.get('bankAccountsIsFetching'),
  );

export const selectIsCardsFetching = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('creditCardsIsFetching'),
  );

export const selectIsBankAccountFetching = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('bankAccountsIsFetching'),
  );

export const selectBillingSummary = () =>
  createSelector(selectBillingInformation(), (data) => {
    const billingSummary = data.get('billingSummary');
    return (billingSummary || Map()).toJS();
  });

export const selectIsEquipmentPickupLockout = () =>
  createSelector(selectBillingInformation(), (data) => {
    const status = data.getIn(['billingSummary', 'status']);
    return status === 'Inactive' || status === 'Pending Termination';
  });

export const selectIsHardLockout = () =>
  createSelector(selectBillingInformation(), (data) => {
    const lockoutType = data.getIn(['billingSummary', 'lockoutType']);
    return lockoutType === 'HL';
  });

export const selectPaperlassEnabled = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.getIn(['billingSummary', 'paperlessEnabled']),
  );

export const selectSurchargeOptOut = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.getIn(['billingSummary', 'surchargeOptOut'], false),
  );

export const selectIsProcessing = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isProcessing'),
  );

export const selectIsCardSucessful = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isCardSucessful'),
  );

export const selectIsInvoiceSuccessful = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isInvoiceSuccessful'),
  );

export const selectIsEditEmailSuccessful = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isEditEmailSuccessful'),
  );

export const selectAddAuthorizedUserSuccessful = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isAddAuthorizedUserSuccessful'),
  );
export const selectIsEditNameSuccessful = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isEditNameSuccessful'),
  );
export const selectIsEditBillingSuccessful = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isEditBillingSuccessful'),
  );
export const selectIsPaymentRemovalSuccessful = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isPaymentRemovalSuccessful'),
  );

export const selectIsShowStatement = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.getIn(['statementSettings', 'show'], false),
  );

export const selectIsShowStatementSuccess = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.getIn(['statementSettings', 'showSuccess'], false),
  );

export const selectStatementSettings = () =>
  createSelector(selectBillingInformation(), (data) => {
    const statementSettings = data.get('statementSettings');
    return (statementSettings || Map()).toJS();
  });

export const selectIsStatementProcessing = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.getIn(['statementSettings', 'isProcessing'], false),
  );

export const selectStatementData = () =>
  createSelector(selectBillingInformation(), (data) =>
    (data.getIn(['statementSettings', 'data']) || Map()).toJS(),
  );

export const selectIsFetchingBillingAccount = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.getIn(['billingAccount', 'isFetching'], false),
  );

export const selectBillingAccount = () =>
  createSelector(selectBillingInformation(), (data) =>
    (data.getIn(['billingAccount', 'data']) || Map()).toJS(),
  );

export const selectBillingAccounts = () =>
  createSelector(selectBillingInformation(), (data) =>
    (data.getIn(['billingAccounts', 'data']) || List()).toJS(),
  );

export const selectUserInfo = () =>
  createSelector(selectBillingInformation(), (data) =>
    (data.get('userInfo') || Map()).toJS(),
  );

export const selectPayMyBillError = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('payMyBillError'),
  );

export const selectCreditCardsFetchError = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('creditCardsFetchError'),
  );

export const selectBankAccountsFetchError = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('bankAccountsFetchError'),
  );

export const selectBillingSummaryFetchError = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('billingSummaryFetchError'),
  );

export const selectCustomerNotes = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('customerNotes'),
  );

export const selectDeliveryNotes = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('deliveryNotes'),
  );

export const selectCustomerNotesIsUpdating = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isCustomerNotesUpdating'),
  );

export const selectCustomerNotesUpdatingSuccess = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isCustomerNotesUpdatingSuccess'),
  );

export const selectAutopayUpdatingSuccess = () =>
  createSelector(selectBillingInformation(), (data) =>
    data.get('isAutoPaySuccessful'),
  );

export const selectBillingInformationError = () =>
  createSelector(selectBillingInformation(), (data) =>
    data ? data.get('error') : null,
  );

export const selectIsCCHold = () =>
  createSelector(selectBillingInformation(), (data) => {
    const billToProfileStatus = data.getIn([
      'billingSummary',
      'billToProfileStatus',
    ]);
    return billToProfileStatus === 'CC HOLD';
  });
