import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import classnames from 'classnames';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { selectSteps } from 'containers/Landing/selectors';
import { setSteps as setStepsAction } from 'containers/Landing/actions';
import { IconButton } from 'components/Ui';
import Button from 'components/ContentfulFields/Button';
import { useIsMobile } from 'utils/hooks';
import styles from './styles.scss';

const CoolerCleaningServiceStep1 = ({ document, steps, setSteps }) => {
  const { currentStep, step1, step2 } = steps;
  const isMobile = useIsMobile();

  const canContinue = () =>
    step1['100 Series'] +
      step1['200 Series'] +
      step1['200k Series'] +
      step1['300 Series'] +
      step1['500 Series'] >
    0;

  const handleSelectChange = (e) => {
    step1[e.target.name] = e.target.value;
    setSteps({ step1, step2, currentStep: 1 });
  };

  const handleSelectToggle = (name) => {
    if (!step1[name]) {
      step1[name] = 1;
      setSteps({ step1, step2, currentStep: 1 });
    }
  };

  const handleButtonClick = () => {
    if (canContinue()) {
      setSteps({ step1, step2, currentStep: 2 });
      window.scrollTo({ top: 0 });
    }
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.title}>{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={styles.tbd1}>{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const nodeDocument = get(node, 'content[1].data.target');
        const id = get(nodeDocument, 'sys.contentType.sys.id');

        switch (id) {
          case 'button':
            return (
              <Button
                document={nodeDocument}
                className={classnames({
                  [styles.btn]: true,
                  [styles.disabled]: !canContinue(),
                })}
                onClick={handleButtonClick}
              >
                {children}
              </Button>
            );
          default:
            return <div className={styles.paragraph}>{children}</div>;
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const title = get(node, 'data.target.fields.title');
        const file = get(node, 'data.target.fields.image.fields.file.url');
        const image = (
          <img className={styles.image} src={`${file}?fm=webp`} alt={''} />
        );
        const count = step1[title];
        const selected = count > 0;

        return (
          <div className={styles.equipment}>
            <IconButton
              className={classnames({
                [styles.selected]: selected,
              })}
              onClick={() => handleSelectToggle(title)}
              onKeyPress={() => handleSelectToggle(title)}
              tabIndex="0"
            >
              {image}
            </IconButton>
            <div className={styles.title}>{title}</div>
            {count > 0 && (
              <FormControl className={styles.select} variant="outlined">
                <Select
                  name={title}
                  value={count}
                  displayEmpty
                  onChange={handleSelectChange}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5+</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'button':
            return (
              <Button className={styles.btn} document={node.data.target}>
                {children}
              </Button>
            );
          default:
            return null;
        }
      },
    },
  };

  if (isMobile && currentStep !== 1) {
    return null;
  }

  let content0 = null;
  let content1 = null;
  let content2 = null;
  let content3 = null;
  if (document) {
    const content = get(
      document,
      'fields.content.content[0].content[1].data.target.fields.content.content',
      '[]',
    );
    content0 = documentToReactComponents(
      {
        data: {},
        nodeType: 'document',
        content: content.slice(0, 2),
      },
      options,
    );
    content1 = documentToReactComponents(
      {
        data: {},
        nodeType: 'document',
        content: content.slice(2, 3 + 2),
      },
      options,
    );
    content2 = documentToReactComponents(
      {
        data: {},
        nodeType: 'document',
        content: content.slice(3 + 2, 8 + 2),
      },
      options,
    );

    content3 = documentToReactComponents(
      {
        data: {},
        nodeType: 'document',
        content: content.slice(8 + 2, 10 + 2),
      },
      options,
    );
  }

  return (
    <div className={styles.ccsBanner}>
      {isMobile && <div className={styles.step}>Step 1</div>}
      {!isMobile && <div className={styles.content0}>{content0}</div>}
      <div className={styles.content1}>{content1}</div>
      <div className={styles.content2}>{content2}</div>
      <div className={styles.content3}>{content3}</div>
    </div>
  );
};

CoolerCleaningServiceStep1.propTypes = {
  document: PropTypes.object.isRequired,
};

CoolerCleaningServiceStep1.defaultProps = {
  setSteps: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  steps: selectSteps(),
});

const mapDispatchToProps = {
  setSteps: setStepsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoolerCleaningServiceStep1);
