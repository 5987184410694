import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import { Grid, RadioGroup } from '@material-ui/core';
import { Input, Radio, Button, Form, FormLabel, Section } from 'components/Ui';
import { warningRed, pinIconBlack } from 'images';
import { apiCall } from 'utils/swaggerClient';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import {
  fetchHome as fetchHomeAction,
  navigateCostcoRoute,
  setHomeOffice,
} from 'containers/Landing/actions';
import { updateUserRegion } from 'containers/UserRegion/actions';
import { clearCart } from 'containers/Cart/actions';
import { resetOrder } from 'containers/CheckoutPage/actions';
import withWidth from '@material-ui/core/withWidth';
import { selectMemberId } from 'containers/Cart/selectors';
import { get } from 'lodash';

import styles from './styles.scss';
import costcoStyles from './costcoOverRide.scss';

class CostcoMembershipBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeOffice: 'home',
      memberCardNumber: get(props, 'memberId', ''),
      zipCode: window.ipSniffFailed ? '' : get(props, 'postalCode', ''),
      showMemberNumberError: false,
      showZipCodeError: false,
    };
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ showMemberNumberError: false, showZipCodeError: false });
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async () => {
    this.setState({ showMemberNumberError: false, showZipCodeError: false });

    try {
      if (
        get(this.props, 'memberId', '') !== this.state.memberCardNumber ||
        get(this.props, 'postalCode', '') !== this.state.zipCode
      ) {
        this.props.doClearCart();
      }
      this.props.doResetOrder();
      const {
        headers: { 'x-branch-code': branch },
        obj: brand,
      } = await apiCall({
        operationId: 'brands.getBrandByZip',
        parameters: { postalCode: this.state.zipCode, source: 'costco' },
        fullResponse: true,
      });
      window.brandByZip = brand;

      this.props.updateUserRegion({
        postalCode: this.state.zipCode,
        branch,
        brand,
      });

      this.routeToCostcoView();
    } catch (error) {
      this.routeToCostcoView();
    }
  };

  showError = (comps) => {
    this.setState({ showMemberNumberError: false, showZipCodeError: false });
    comps.forEach((comp) => {
      if (comp.props.name === 'memberCardNumber') {
        this.setState({ showMemberNumberError: true });
      } else if (comp.props.name === 'zipCode') {
        this.setState({ showZipCodeError: true });
      }
    });
  };

  routeToCostcoView = async () => {
    try {
      const result = await apiCall({
        operationId: 'CostcoMemberCheck.getCostcoMemberInfo',
        parameters: {
          memberCardNumber: this.state.memberCardNumber,
          postalCode: this.state.zipCode,
        },
        fullResponse: true,
      });
      if (result) {
        if (!result.obj.validExecutive && !result.obj.validNonExecutive) {
          this.setState({ showMemberNumberError: true });
          return 'error';
        }
        this.props.doSetHomeOffice(this.state.homeOffice);

        this.props.fetchHome();
        const costcoAccount = {
          ...result.obj,
          memberNum: this.state.memberCardNumber,
        };
        this.props.routeToCostcoView(costcoAccount);
        return 'success';
      }
      // TODO: Is below needed? I recall there might be a case where
      // getCostcoMemberInfo does not return a result but I cannot remember
      // Leaving this is for now as a catch for above just is case
      this.props.history.push('/costcowater-nonserv-restrictions');
      return 'exception';
    } catch (error) {
      this.props.history.push('/costcowater-nonserv-restrictions');
      return 'exception';
    }
  };

  render() {
    const {
      showMemberNumberError,
      showZipCodeError,
      memberCardNumber,
      zipCode,
    } = this.state;

    const isMobile = this.props.width === 'sm';
    const spacing = isMobile ? 0 : 4;

    return (
      <Section
        aria-label="Costco membership banner"
        className={classNames(styles.banner, styles.container)}
      >
        <div className={styles.content}>
          <div className={styles.title}>
            <img
              src={pinIconBlack}
              alt="location pin"
              className={styles.pinIconWhite}
              aria-hidden="true"
            />
            <h2>See products and services in your area</h2>
          </div>
          <Form
            className={styles.formContainer}
            onSubmit={this.handleSubmit}
            onError={this.showError}
          >
            <Grid container spacing={spacing} className={styles.form}>
              <Grid className={styles.memberNumberItem} item sm={12} md={4}>
                <Input
                  label={
                    <span id="memberNumber" className={styles.label}>
                      Costco Member Number:
                    </span>
                  }
                  validators={['required']}
                  errorMessages={['']}
                  value={memberCardNumber}
                  onChange={this.handleChange}
                  name="memberCardNumber"
                  placeholder="Enter your member number"
                  disableAsterisk
                  ariaLabel="costco member number"
                  autocomplete="costco number"
                />
              </Grid>
              {isMobile && showMemberNumberError && (
                <Grid className={styles.memberNumberErrorItem} item sm={12}>
                  <span className={styles.arrow} />
                  <div className={styles.memberNumberError}>
                    <img
                      src={warningRed}
                      alt="warning red enter valid member number"
                      className={styles.warningRed}
                    />
                    Please enter a valid member number
                  </div>
                </Grid>
              )}
              <Grid className={styles.zipCodeItem} item sm={4} md={2}>
                <Input
                  label={
                    <span id="zipCode" className={styles.label}>
                      ZIP Сode:
                    </span>
                  }
                  validators={['required', 'zip']}
                  errorMessages={['', '']}
                  value={zipCode}
                  onChange={this.handleChange}
                  name="zipCode"
                  disableAsterisk
                  ariaLabel="zip code"
                  autocomplete="postal-code"
                />
              </Grid>
              {isMobile && showZipCodeError && (
                <Grid className={styles.zipCodeErrorItem} item sm={12}>
                  <span className={styles.arrow} />
                  <div className={styles.zipCodeError}>
                    <img
                      src={warningRed}
                      alt="warning red enter valid zip code"
                      className={styles.warningRed}
                    />
                    Please enter a valid ZIP code
                  </div>
                </Grid>
              )}
              <Grid className={styles.radioItem} sm={12} item md={4}>
                <FormLabel label="This is for:" />
                <RadioGroup
                  name="homeOffice"
                  className={styles.radioGroup}
                  value={this.state.homeOffice}
                  onChange={this.handleChange}
                  row
                  aria-label="this is for"
                >
                  <Radio className={styles.radio} value="home" label="Home" />
                  <Radio
                    className={styles.radio}
                    value="office"
                    label="Office"
                  />
                </RadioGroup>
              </Grid>
              <Grid className={styles.submitItem} sm={12} item md={2}>
                <Button
                  text="Start Order"
                  className={classNames(styles.submit, costcoStyles.costcoBtn)}
                  ariaLabel="with member number and zip code"
                  color="dark"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={styles.form}>
              {!isMobile && showMemberNumberError && (
                <Grid className={styles.memberNumberErrorItem} item md={4}>
                  <span className={styles.arrow} />
                  <div className={styles.memberNumberError}>
                    <img
                      src={warningRed}
                      alt="warningRed"
                      className={styles.warningRed}
                    />
                    Please enter a valid member number
                  </div>
                </Grid>
              )}
              {!isMobile && showZipCodeError && (
                <Grid
                  className={classNames(styles.zipCodeErrorItem, {
                    [styles.zipCodeErrorItem2]: !showMemberNumberError,
                  })}
                  item
                  md={8}
                >
                  <span className={styles.arrow} />
                  <div className={styles.zipCodeError}>
                    <img
                      src={warningRed}
                      alt="warningRed"
                      className={styles.warningRed}
                    />
                    Please enter a valid ZIP code
                  </div>
                </Grid>
              )}
            </Grid>
          </Form>
        </div>
      </Section>
    );
  }
}

CostcoMembershipBanner.propTypes = {
  fetchHome: PropTypes.func.isRequired,
  updateUserRegion: PropTypes.func.isRequired,
  routeToCostcoView: PropTypes.func,
  history: PropTypes.object,
  doSetHomeOffice: PropTypes.func.isRequired,
  doClearCart: PropTypes.func.isRequired,
  doResetOrder: PropTypes.func.isRequired,
  width: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  postalCode: selectPostalCode(),
  memberId: selectMemberId(),
});

const mapDispatchToProps = {
  fetchHome: fetchHomeAction,
  updateUserRegion,
  routeToCostcoView: navigateCostcoRoute,
  doSetHomeOffice: setHomeOffice,
  doClearCart: clearCart,
  doResetOrder: resetOrder,
};

export default compose(
  withRouter,
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
)(CostcoMembershipBanner);
