import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isDevelopment } from 'utils/common';
import { apiCall } from 'utils/swaggerClient';
import { isSsr } from 'utils/ssrHelper';
import { getRegionalBrandName } from 'utils/domainHelper';
import ReCaptcha from 'components/ReCaptcha';
import LeadsDialog from 'components/Dialogs/LeadsDialog';

import styles from './styles.scss';

const Leads = ({ content: { fields: content } }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showDialog, setShowDialog] = useState(true);
  const brandName = getRegionalBrandName();
  const emailInputRef = useRef();
  const [captchaSiteKey, setCaptchaSiteKey] = useState();
  const reCaptchaRef = useRef();
  const reCaptchaRef1 = useRef();

  useEffect(() => {
    if (!isSsr()) {
      const subscriptionStatus = localStorage.getItem(
        'LeadsSubscriptionStatus',
      );

      if (subscriptionStatus === 'subscribed') {
        setIsSubscribed(true);
      } else {
        setCaptchaSiteKey(window?.SERVER_DATA?.RECAPTCHA_SITE_KEY);
      }
    }
  }, []);

  useEffect(() => {
    if (!isSsr()) {
      localStorage.setItem(
        'LeadsSubscriptionStatus',
        isSubscribed ? 'subscribed' : 'not-subscribed',
      );
    }
  }, [isSubscribed]);

  useEffect(() => {
    if (!isVerifiedEmail && emailInputRef) {
      emailInputRef.current.focus();
    }
  }, [isVerifiedEmail]);

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const submitLeads = (e) => {
    e.preventDefault();
    reCaptchaRef.current.executeReCaptcha();
  };

  const forceSubmitLeads = (e) => {
    e.preventDefault();
    reCaptchaRef1.current.executeReCaptcha();
  };

  const submitLeadsDialog = (leadsData) => {
    setFirstName(leadsData.firstName);
    setEmailAddress(leadsData.email);
    reCaptchaRef.current.executeReCaptcha();
  };

  const submitLeadsData = async (token, overrideEmailValidation) => {
    const isEmailValidated = await validateEmail(
      emailAddress,
      overrideEmailValidation,
    );

    if (isEmailValidated) {
      axios
        .post(
          '/leads',
          {
            firstName,
            emailAddress,
            source: `Regional-${brandName.split(' ').join('-')}${
              showDialog ? '-dialog' : ''
            }`,
          },
          {
            headers: { 'api-token': token },
          },
        )
        .then(() => {
          setIsSubscribed(true);
        })
        .catch((err) => {
          setIsValidEmail(false);
          console.error(err); // eslint-disable-line
        });
    }
  };

  const validateEmail = async (email, overrideEmailValidation) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = re.test(String(email).toLowerCase());
    setIsValidEmail(isValid);

    if (!isValid) {
      return false;
    }

    if (overrideEmailValidation) {
      return true;
    }

    try {
      const { body } = await apiCall({
        operationId: 'users.verifyEmailAddress',
        parameters: { emailAddress: email },
        fullResponse: true,
      });

      const isEmailVerified = body.isValid;

      if (!showDialog) {
        setIsVerifiedEmail(isEmailVerified);
      }

      return isEmailVerified;
    } catch (err) {
      console.error(err); // eslint-disable-line

      if (!showDialog) {
        setIsVerifiedEmail(false);
      }

      return false;
    }
  };

  return (
    <div className={styles.wrapper}>
      <LeadsDialog
        open={!isSubscribed && showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
        content={content}
        onSubmit={submitLeadsDialog}
        validateEmail={validateEmail}
        brandName={brandName}
      />
      {!isSubscribed && isVerifiedEmail && (
        <form className={styles.banner} onSubmit={submitLeads}>
          <span htmlFor="leads-signup-email-textbox">
            {content.initialPrompt}
          </span>
          <div>
            <input
              id="leads-signup-email-textbox"
              type="text"
              onInput={handleEmailChange}
              aria-describedby="leads-signup-email-error"
            />
            {!isValidEmail && (
              <small
                className={styles.emailError}
                id="leads-signup-email-error"
                role="alert"
              >
                {content.invalidEmail}
              </small>
            )}
          </div>
          {captchaSiteKey && (
            <ReCaptcha
              siteKey={captchaSiteKey}
              onVerify={(token) => submitLeadsData(token, showDialog)}
              ref={reCaptchaRef}
            />
          )}
          <input
            className={styles.subscribe}
            type="submit"
            value={content.signUpButton}
          />
        </form>
      )}

      {!isSubscribed && !isVerifiedEmail && (
        <form
          className={`${styles.banner} ${styles.emailValidation}`}
          onSubmit={forceSubmitLeads}
        >
          <div className={styles.emailConfirmation}>
            {content.emailConfirmation}
          </div>
          <div>
            <input
              type="text"
              defaultValue={emailAddress}
              onInput={handleEmailChange}
              ref={emailInputRef}
            />
            {!isValidEmail && (
              <small className={styles.emailError}>
                {content.invalidEmail}
              </small>
            )}
          </div>
          {captchaSiteKey && (
            <ReCaptcha
              siteKey={captchaSiteKey}
              onVerify={(token) => submitLeadsData(token, true)}
              ref={reCaptchaRef1}
            />
          )}
          <input type="submit" value={content.emailConfirmationButton} />
        </form>
      )}
      {!isSubscribed && (
        <div className={styles.recaptchaText}>
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          &nbsp;apply.
        </div>
      )}
      {isSubscribed && (
        <div className={styles.banner}>
          <h3>{content.confirmationTitle}</h3>
          <div className={styles.vl}></div>
          <div className={styles.confirmationMessage}>
            {content.confirmationMessage}
          </div>
        </div>
      )}
    </div>
  );
};

Leads.propTypes = {
  content: PropTypes.object,
};

export default Leads;
