import React from 'react';
import { useDispatch } from 'react-redux';
import ReduxDrawer from 'containers/DrawerManager/ReduxDrawer';
import { hideDrawer } from 'containers/DrawerManager/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import { PinIcon } from 'components/Ui/CustomIcons';
import { WaterZipForm } from 'components/Forms';
import { REGIONAL_CTA_DRAWER } from '../constants';
import styles from './styles.scss';

const MUIStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      maxWidth: 'unset',
      height: '100vh',
      maxHeight: 'unset',
    },
  },
  classes: {
    color: 'red',
  },
  contentRoot: {
    padding: '0 60px 30px 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 25px',
    },
  },
}));

const Wrapper = () => (
  <div className={styles.drawerWrapper}>
    <div className={styles.title}>
      <PinIcon color="#a4d65e" />
      <h1>Your beverage service starts here</h1>
      <p>Update your location to see what&aposs available in your area.</p>
    </div>
    <WaterZipForm view="drawer" />
  </div>
);

const BrandChoose = () => {
  const dispatch = useDispatch();
  const handleHideForm = () => dispatch(hideDrawer(REGIONAL_CTA_DRAWER));
  const classes = MUIStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ReduxDrawer
      type="dialog"
      WrappedComponent={Wrapper}
      drawerKey={REGIONAL_CTA_DRAWER}
      drawerProps={{
        noBackdrop: true,
        title: isMobile ? 'Info' : null,
        showBackButton: true,
        classes,
      }}
      props={{
        onClose: handleHideForm,
      }}
    />
  );
};

export default BrandChoose;
