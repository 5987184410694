export const badgeTypes = {
  ONE_TIME: 'oneTime',
  RESCHEDULED: 'rescheduled',
  RECURRING: 'recurring',
  IGNORE: 'ignore',
  HOLD: 'hold',
};

// more priority - less index
export const typesPriority = [
  badgeTypes.HOLD,
  badgeTypes.ONE_TIME,
  badgeTypes.RESCHEDULED,
  badgeTypes.RECURRING,
  badgeTypes.IGNORE,
];

export const calendarDateToType = {
  'scheduled-service': badgeTypes.RECURRING,
  delivered: badgeTypes.RECURRING,
  'past-order': badgeTypes.RECURRING,
  'service-on-request': badgeTypes.ONE_TIME,
  service: badgeTypes.ONE_TIME,
  'holiday-reschedule': badgeTypes.RESCHEDULED,
  holiday: badgeTypes.IGNORE,
  missed: badgeTypes.IGNORE,
  'service-realignment': badgeTypes.IGNORE,
  'costco-anniversary': badgeTypes.IGNORE,
  hold: badgeTypes.HOLD,
};

export const serviceTypeToBadge = {
  water: 'w',
  filtration: 'f',
  coffee: 'c',
};

export const defaultClassNames = {
  container: 'DayPicker',
  wrapper: 'DayPicker-wrapper',
  interactionDisabled: 'DayPicker--interactionDisabled',
  months: 'DayPicker-Months',
  month: 'DayPicker-Month',

  navBar: 'DayPicker-NavBar',
  navButtonPrev: 'DayPicker-NavButton DayPicker-NavButton--prev',
  navButtonNext: 'DayPicker-NavButton DayPicker-NavButton--next',
  navButtonInteractionDisabled: 'DayPicker-NavButton--interactionDisabled',

  caption: 'DayPicker-Caption',
  weekdays: 'DayPicker-Weekdays',
  weekdaysRow: 'DayPicker-WeekdaysRow',
  weekday: 'DayPicker-Weekday',
  body: 'DayPicker-Body',
  week: 'DayPicker-Week',
  weekNumber: 'DayPicker-WeekNumber',
  day: 'DayPicker-Day',
  footer: 'DayPicker-Footer',
  todayButton: 'DayPicker-TodayButton',

  // default modifiers
  today: 'today',
  selected: 'selected',
  disabled: 'disabled',
  outside: 'outsideRange',
};
