import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { AutoComplete, Span } from 'components/Ui';
import CaretDown from 'components/Ui/CustomIcons/CaretDown';
import { createStructuredSelector } from 'reselect';
import {
  selectServiceLocations,
  selectServiceLocationIsFetching,
  selectSelectedServiceLocation,
} from 'containers/PrimoAccount/selectors';
import { loadServiceLocation } from 'containers/PrimoAccount/actions';
import { showDrawer as showDrawerAction } from 'containers/DrawerManager/actions';
import { isSsr } from 'utils/ssrHelper';
import ConfirmationModal from './ConfirmationModal';
import styles from './styles.scss';

const ServiceLocations = ({
  id,
  serviceLocations,
  isFetching,
  darkDropdown,
  showDrawer,
  isBilling,
  reloadUrl,
  noHover,
  whiteLabel,
  ...props
}) => {
  const drawerId = `selectLocationsConfirmation${`-${id}` || ''}`;
  useEffect(() => {
    if (!serviceLocations.length && !isFetching) {
      props.loadServiceLocation();
    }
  }, []);
  const [value, changeValue] = useState({});

  const formatLabel = (item, asOption = false) => {
    if (!isEmpty(item) && props && props.selectedServiceLocation) {
      const {
        id: serviceLocationId,
        address1,
        address2,
        city,
        postalCode,
        stateOrProvinceCode,
        brandName,
        branchCode,
      } = item;

      const selected = serviceLocationId === props.selectedServiceLocation.id;
      const devText =
        !isSsr() && window.PrimoShowBrandAndBranch
          ? ` ${brandName} ${branchCode}`
          : '';

      return (
        <Fragment>
          <div className={styles.labelContainer}>
            <div className={styles.label}>
              <div>
                {<Span className={styles.location}>Location #</Span>}{' '}
                {`${serviceLocationId}${devText}`}
              </div>
              <div
                className={styles.address}
              >{`${address1.toLowerCase()}, ${city.toLowerCase()}, ${stateOrProvinceCode} ${postalCode}`}</div>
              {asOption && address2 && <span>{`${address2}`}</span>}
            </div>
            {asOption && selected && <div className={styles.selected} />}
          </div>
        </Fragment>
      );
    }
    return '';
  };

  const defaultValue = useMemo(() => {
    if (props.selectedServiceLocation) {
      const returnedValue = {
        value: props.selectedServiceLocation.id,
        label: props.selectedServiceLocation,
      };

      changeValue(returnedValue);
      return returnedValue;
    }

    return { value: '' };
  }, [props.selectedServiceLocation && props.selectedServiceLocation.id]);

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== defaultValue.value) {
      changeValue(selectedOption);
      showDrawer(drawerId);
    }
  };

  return (
    <Fragment>
      <AutoComplete
        darkDropdown={darkDropdown}
        noHover
        icon={<CaretDown />}
        name="deliveryAddress"
        label={isBilling ? 'Billing address:' : 'Delivery address:'}
        formatLabel={formatLabel}
        value={defaultValue}
        options={serviceLocations.map((item) => ({
          value: item.id,
          label: formatLabel(item, true),
        }))}
        onChange={handleChange}
        isFetching={isFetching}
        isClearable={false}
        selectClassName={props.selectClassName}
        isSearchable={false}
        whiteLabel={whiteLabel}
      />
      {value.value && defaultValue.value && (
        <ConfirmationModal
          newLocationId={value.value}
          oldLocationId={defaultValue.value}
          drawerId={drawerId}
          reloadUrl={reloadUrl}
        />
      )}
    </Fragment>
  );
};

ServiceLocations.propTypes = {
  id: PropTypes.string,
  reloadUrl: PropTypes.string,
  serviceLocations: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  darkDropdown: PropTypes.bool,
  selectedServiceLocation: PropTypes.object,
  loadServiceLocation: PropTypes.func.isRequired,
  showDrawer: PropTypes.func.isRequired,
  selectClassName: PropTypes.string,
  isBilling: PropTypes.bool,
};

ServiceLocations.defaultProps = {
  darkDropdown: false,
  id: '',
  reloadUrl: '/account/next-delivery',
  isBilling: false,
  whiteLabel: false,
};

const mapStateToProps = createStructuredSelector({
  serviceLocations: selectServiceLocations(),
  isFetching: selectServiceLocationIsFetching(),
  selectedServiceLocation: selectSelectedServiceLocation(),
});

const mapDispatchToProps = {
  loadServiceLocation,
  showDrawer: showDrawerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLocations);
