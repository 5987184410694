import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { Drawer, ProcessingBlock, SuccessBlock } from 'components/Ui';
import {
  createExtraDelivery,
  cancelExtraDelivery,
} from 'containers/PrimoAccount/actions';
import ExtraDeliveryForm from './ExtraDeliveryForm';
import {
  selectIsCreatingExtraDelivery,
  selectServiceLocationIsFetchingOrder,
  selectServiceLocationIsSuccessUpdatedOrder,
  selectServiceLocationIsAddDeliveryProcessing,
  selectRecurringOrder,
  selectNextDelivery,
} from '../selectors';
import styles from './extra-delivery-styles.scss';

const AddExtraDeliveryDrawer = () => {
  const dispatch = useDispatch();
  const isCreatingExtraDelivery = useSelector(selectIsCreatingExtraDelivery());
  const isLoading = useSelector(selectServiceLocationIsFetchingOrder());
  const isSuccessUpdated = useSelector(
    selectServiceLocationIsSuccessUpdatedOrder(),
  );
  const isAddDeliveryProcessing = useSelector(
    selectServiceLocationIsAddDeliveryProcessing(),
  );
  const order = useSelector(selectRecurringOrder());
  const delivery = useSelector(selectNextDelivery());

  const onCreateExtraDelivery = (extraDelivery, deliveryDate) => {
    dispatch(
      createExtraDelivery(
        extraDelivery.map((product) => ({
          itemId: product.itemId,
          itemNumber: product.itemNumber,
          quantity: product.quantity,
        })),
        deliveryDate,
      ),
    );
  };

  const handleClosingExtraDelivery = () => dispatch(cancelExtraDelivery());

  const renderContent = () => {
    if (isAddDeliveryProcessing) {
      return (
        <div className={styles.successMessage}>
          <ProcessingBlock />
        </div>
      );
    }

    if (isSuccessUpdated) {
      return (
        <div className={styles.successMessage}>
          <SuccessBlock text="Your additional delivery request has been sent." />
        </div>
      );
    }

    return (
      <ExtraDeliveryForm
        order={order}
        delivery={delivery}
        editable={delivery.editable}
        onSubmit={onCreateExtraDelivery}
        onClose={handleClosingExtraDelivery}
      />
    );
  };

  return (
    <Drawer
      headerTitle="Add extra delivery"
      onClose={handleClosingExtraDelivery}
      open={isCreatingExtraDelivery}
      noMargins
    >
      <Loader loading={isLoading} local>
        {renderContent()}
      </Loader>
    </Drawer>
  );
};

export default AddExtraDeliveryDrawer;
