import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeConfig } from 'theme/themeConfig';

const Text = styled.div`
  font-family: ${themeConfig.fonts.bentonRegular};
  font-family: ${(props) =>
    props.medium
      ? themeConfig.fonts.bentonMedium
      : themeConfig.fonts.bentonRegular};
  color: ${(props) => props.color || themeConfig.colors.lightGrey};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 1.08;
`;

Text.displayName = 'Text';

Text.propTypes = {
  color: PropTypes.string,
  display: PropTypes.bool,
  medium: PropTypes.bool,
};

Text.defaultProps = {
  medium: false,
};

export default Text;
