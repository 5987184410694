import filter from 'lodash/filter';
import { getBrandTags } from 'components/Brands';
import { hasTag } from './utils';

/**
 * @param {Object} c - a content object
 * @param {string} brand - a brand name
 * @return {?(Object|Object[])}
 * typically a single content or media object found, within the given `c` object, as tagged with
 * the current brand, or null if no content/media is found. If multiple tagged of either content or
 * media are found, an array is returned. If both content and media are found, an object with
 * `content` and `media` props is returned, containing their respective object or array.
 */
export const getContentTaggedAnyOfBrandsTags = (c, brand) => {
  const content = c?.fields.content;
  const media = c?.fields.media;
  const result = {};

  const brandTags = getBrandTags(brand);

  if (content) {
    const all = filter(content, (ci) => hasTag(ci, brandTags));
    result.content = all.length > 1 ? all : all[0];
  }

  if (media) {
    const all = filter(media, (m) => hasTag(m, brandTags));
    result.media = all.length > 1 ? all : all[0];
  }

  if (content && media) return result;
  if (content) return result.content;
  return result.media;
};
