import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/LoadingIndicator';

class Loader extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    local: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    local: false,
    className: null,
  };

  render() {
    const { loading, children = null, local, className } = this.props;

    return loading ? (
      <LoadingIndicator local={local} className={className} />
    ) : (
      children
    );
  }
}

Loader.displayName = 'Loader';

export default Loader;
