import { Map } from 'immutable';
import { INITIAL, LOADING } from 'utils/store/fetch';
import { NAMESPACE, PARENT_NAMESPACE } from './index';

const STATE_KEY = [PARENT_NAMESPACE, NAMESPACE];

export const getState = (state) => state.getIn(STATE_KEY, Map());

export const getStatus = (state) => getState(state).get('status');

export const getHasLoaded = (state) =>
  getStatus(state) !== INITIAL && getStatus(state) !== LOADING;

export const getHasFetched = (state) => getStatus(state) !== INITIAL;

export const getError = (state) => getState(state).get('error');

export const getResults = (state) =>
  getState(state).getIn(['results', 'products']);

export const getIsFavoritesRequest = (state) =>
  /favorites/i.test(state.getIn(['router', 'location', 'search']));
