/* eslint-disable react/no-unused-state, max-classes-per-file */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withStyles, Modal } from '@material-ui/core';
import { compact, isEqual, sortBy } from 'lodash';
import filterImg from 'images/Filter.svg';
import { Default, Mobile } from 'components/Media';
import IconButton from 'components/Ui/Button/IconButton';
import {
  parseSearchFilter,
  isWaterKind,
  getCategoryId,
} from 'containers/PrimoProducts/helpers';
import { FavoritesFilter } from 'containers/PrimoProducts/Favorites';
import { isSsr } from 'utils/ssrHelper';
import { Div } from 'components/Ui';
import FilterCollapse from './FilterCollapse';
import styles from './styles.scss';

const MUIstyles = (theme) => ({
  button: {
    position: 'absolute',
    top: 180,
    left: 10,
    zIndex: 1,
    width: 40,
    height: 40,
    backgroundColor: theme.colors.primaryBlue,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    borderRadius: '50%',
  },
  root: {
    backgroundColor: 'red',
  },
});

const CloseIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 19px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 9px;
    height: 23px;
    width: 2px;
    background-color: white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const BackdropComponent = styled.div`
  z-index: -1;
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  background-color: white;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
`;

class FiltersHeader extends React.Component {
  ref = React.createRef();

  componentDidMount() {
    this.ref.current.focus();
  }

  render() {
    return (
      <Div className={styles.header} tabIndex={-1} ref={this.ref}>
        {this.props.children}
      </Div>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
class Filter extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.location.search !== state.search ||
      props.location.pathname !== state.pathname
    ) {
      const showWaterTypeFilter = Filter.isWaterFilterShows(props);
      return {
        search: props.location.search,
        pathname: props.location.pathname,
        showWaterTypeFilter,
      };
    }

    if (!isEqual(props.productCategories, state.productCategories)) {
      return { productCategories: props.productCategories };
    }
    return null;
  }

  static isWaterFilterShows = ({
    location,
    productCategories,
    isAuthenticated,
  }) => {
    const { categoryId } = parseSearchFilter(location, isAuthenticated);

    return getCategoryId(categoryId, productCategories)
      ? isWaterKind(categoryId, productCategories)
      : true;
  };

  sortProductCategories = (categories) => sortBy(categories, ['sequence']);

  modifyProductCategories = (categories) => {
    let categoriesList = categories.map((item) => ({
      value: item.fields.urlName,
      label: item.fields.name,
      sequence: item.fields.sequence,
    }));

    categoriesList.push({
      value: null,
      label: 'All products',
    });

    categoriesList = this.sortProductCategories(categoriesList);

    return categoriesList;
  };

  state = {
    isOpen: false,
    productCategories: this.props.productCategories,
    showWaterTypeFilter: false,
    search: this.props.location.search,
    pathname: this.props.location.pathname,
  };

  filtersModal = React.createRef();

  async componentDidMount() {
    const showWaterTypeFilter = Filter.isWaterFilterShows(this.props);

    this.setState({
      showWaterTypeFilter,
    });
  }

  handleToggleFilter = (event) => {
    if (event && (event.key === ' ' || event.key === 'Enter')) {
      this.setState({ isOpen: false });
      return;
    }

    this.setState((prevProps) => ({ isOpen: !prevProps.isOpen }));
  };

  render() {
    const { isOpen, productCategories, showWaterTypeFilter } = this.state;
    const {
      classes,
      brands,
      waterTypes,
      shopDeliveryOrder,
      isAuthenticated,
    } = this.props;

    const categoryItems = this.modifyProductCategories(productCategories);
    const brandItems = compact(brands).map((brand) => ({
      value: brand,
      label: brand,
    }));

    const waterTypeItems = compact(waterTypes).map((type) => ({
      value: type,
      label: type,
    }));

    const filterComponent = (
      <Fragment>
        <FiltersHeader className="filters-header">
          <img src={filterImg} alt="" />
          Filters
          <Mobile>
            <CloseIcon
              onClick={this.handleToggleFilter}
              onKeyPress={this.handleToggleFilter}
              tabIndex="0"
              role="button"
              aria-label="close filter dialog"
            />
          </Mobile>
        </FiltersHeader>

        {isAuthenticated && <FavoritesFilter />}

        <FilterCollapse
          name="Category"
          filterKey="categoryId"
          productCategories={productCategories}
          shopDeliveryOrder={shopDeliveryOrder}
          items={categoryItems}
          isAuthenticated={isAuthenticated}
        />
        <FilterCollapse
          name="Brand"
          filterKey="brands"
          productCategories={productCategories}
          shopDeliveryOrder={shopDeliveryOrder}
          multi
          items={brandItems}
          isAuthenticated={isAuthenticated}
        />
        {showWaterTypeFilter && (
          <FilterCollapse
            name="Water Type"
            filterKey="waterTypes"
            productCategories={productCategories}
            shopDeliveryOrder={shopDeliveryOrder}
            multi
            items={waterTypeItems}
            isAuthenticated={isAuthenticated}
          />
        )}
      </Fragment>
    );

    return (
      <Fragment>
        {isSsr() ? (
          <div className={styles.filtersWrapper}>{filterComponent}</div>
        ) : (
          <Default>
            <div className={styles.filtersWrapper}>{filterComponent}</div>
          </Default>
        )}
        <Mobile>
          <IconButton
            className={classes.button}
            onClick={this.handleToggleFilter}
            onKeyPress={this.handleToggleFilter}
            tabIndex="0"
          >
            <img src={filterImg} alt="" />
          </IconButton>
          <Modal
            BackdropComponent={BackdropComponent}
            className={styles.modal}
            open={isOpen}
            onClose={this.handleToggleFilter}
            ref={this.filtersModal}
            aria-labelledby="filters-header"
          >
            {filterComponent}
          </Modal>
        </Mobile>
      </Fragment>
    );
  }
}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
  waterTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  location: PropTypes.object.isRequired,
  productCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(MUIstyles)(Filter));
