import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import { Input, Button, Form } from 'components/Ui';

import styles from './styles.scss';

const SearchBar = ({ onSearch, ...props }) => {
  const [keyword, setKeyword] = useState();

  useEffect(() => {
    setKeyword(props.keyword);
  }, [props.keyword]);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedKeyword = keyword.trim();
    onSearch(trimmedKeyword);
  };

  return (
    <Form className={styles.searchBar} onSubmit={handleSubmit} withoutWrapper>
      <Input
        containerClassName={styles.inputContainer}
        label="Search"
        value={keyword}
        onChange={handleChange}
        rightComp={
          <Button
            className={styles.searchButton}
            type="submit"
            icon={<SearchIcon />}
          />
        }
      />
    </Form>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
