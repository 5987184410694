import React, { Component } from 'react';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { translateData } from 'utils/translation';
import { selectCartData } from 'containers/Cart/selectors';
import Button from '../Button';
import Icon from '../Icon/Loadable';
import styles from './styles.scss';

class Counter extends Component {
  constructor(props) {
    super();

    this.state = {
      value: props.value || props.minValue,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.usePropsValue && props.value !== state.value) {
      return {
        value: props.value,
      };
    }

    return null;
  }

  increment = (e) => {
    if (this.props.onIncrement) {
      this.props.onIncrement();
    }

    e.stopPropagation();
    if (this.props.usePropsValue) {
      this.props.onChange(this.state.value + 1);
    } else {
      this.setState(
        (prevState) => ({ value: prevState.value + 1 }),
        () => this.props.onChange(this.state.value),
      );
    }
  };

  decrement = (e) => {
    if (this.props.onDecrement) {
      this.props.onDecrement();
    }

    e.stopPropagation();

    if (this.state.value === this.props.minValue) return;

    if (this.props.usePropsValue) {
      const value = this.state.value - 1;
      this.props.onChange(value);
    } else {
      this.setState(
        (prevState) => ({ value: prevState.value - 1 }),
        () => this.props.onChange(this.state.value),
      );
    }
  };

  render() {
    const {
      state: { value },
      props: { minValue, productTitle },
    } = this;

    const isDisabledDecrement = value === minValue;

    let theProductTitle = ' ';
    if (productTitle) {
      theProductTitle = ` ${productTitle} `;
    }

    const decreaseTranslated = translateData('Decrease');
    const increaseTranslated = translateData('Increase');
    const quantityTranslated = translateData('Quantity');

    return (
      <div className={classnames(styles.counter, this.props.className)}>
        <Button
          data-tracking={GTM_TRACKING_TAGS['Decrement Product Quantity']}
          icon={<Icon icon="minus" aria-hidden="true" />}
          onClick={this.decrement}
          disabled={this.props.disabled || isDisabledDecrement}
          ariaLabel={`${decreaseTranslated}${theProductTitle}${quantityTranslated}`}
          color="dark"
        />
        <span
          className={styles.value}
          aria-live="assertive"
          aria-relevant="text"
        >
          {value}
        </span>
        <Button
          data-tracking={GTM_TRACKING_TAGS['Increment Product Quantity']}
          icon={<Icon icon="plus" aria-hidden="true" />}
          onClick={this.increment}
          disabled={this.props.disabled}
          ariaLabel={`${increaseTranslated}${theProductTitle}${quantityTranslated}`}
          color="dark"
        />
      </div>
    );
  }
}

Counter.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  usePropsValue: PropTypes.bool,
  disabled: PropTypes.bool,
  productTitle: PropTypes.string,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  cartData: selectCartData(),
});

Counter.defaultProps = {
  value: 0,
  minValue: 0,
  onChange: () => {},
  className: null,
  usePropsValue: false,
  disabled: false,
  productTitle: null,
};

export default connect(mapStateToProps)(Counter);
