import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { translateData } from 'utils/translation';
import TabItem from './Item';

const styles = (theme) => ({
  tabContentContainer: {},
  noSearchResultsFound: {
    fontFamily: theme.fonts.sansPro,
    textAlign: 'center',
    lineHeight: '56px',
    fontSize: 48,
    marginTop: 40,
    paddingTop: 20,
  },
  tabWrapper: {
    backgroundColor: theme.colors.white,
    paddingTop: '15px',
  },
  root: {
    boxShadow: '0 6px 4px -4px rgba(0, 0, 0, 0.15)',
  },
  tabRoot: {
    color: theme.colors.black,
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    fontWeight: 900,
    lineHeight: '1.2rem',
    padding: '29px 17px',
    opacity: 1,
    '&$tabSelected': {
      color: theme.colors.primaryBlue,
    },
    border: '3px solid transparent',
    '&:focus, &:hover': {
      border: `3px solid ${theme.colors.hoverBorder}`,
    },
  },
  tabIndicator: {
    backgroundColor: theme.colors.primaryBlue,
    height: '8px',
  },
  tabSelected: {},
  flexContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
});

const ContentfullTabs = (props) => {
  const {
    document: {
      fields: { tabItems },
    },
  } = props;
  const { classes, theme, history, ...rest } = props;
  const { pathname } = history.location;
  const urlPathTail = `/${(pathname || '').split('/').pop()}`;

  const { content, labels } = tabItems.reduce(
    (result, { fields: { key, label, content: fieldContent } }) => {
      result.content[key] = fieldContent;
      result.labels[key] = translateData(label, 'C47');
      return result;
    },
    { content: {}, labels: {} },
  );

  const getTabKeyByUrlPath = (path) => {
    const labelKeys = Object.keys(labels);
    const doesUrlPathMatchATabKey = labelKeys.includes(path);
    return doesUrlPathMatchATabKey ? path : labelKeys[0];
  };

  const [current, setCurrent] = useState(getTabKeyByUrlPath(urlPathTail));

  useEffect(() => {
    setCurrent(getTabKeyByUrlPath(urlPathTail));
  }, [urlPathTail]);

  function handleChange(e, value) {
    if (value === current) return;
    if (value.startsWith('/')) history.push(value);
    setCurrent(value);
  }

  let hasResults = true;
  const type = get(content[current][0], 'sys.contentType.sys.id');
  if (
    type === 'faqCategory' &&
    rest &&
    rest.searchResult &&
    rest.searchResult.length !== 0
  ) {
    hasResults = !!content[current].find((item) =>
      item.fields.faqItems.find((faqItem) =>
        rest.searchResult.includes(faqItem.sys.id),
      ),
    );
  }

  return (
    <>
      <div className={classes.tabWrapper}>
        <Tabs
          value={current}
          onChange={handleChange}
          TabIndicatorProps={{
            classes: {
              root: classes.tabIndicator,
            },
          }}
          classes={{
            root: classes.root,
            flexContainer: classes.flexContainer,
          }}
          variant="scrollable"
          scrollButtons="off"
        >
          {Object.entries(labels).map(([key, label]) => (
            <Tab
              label={label}
              key={key}
              value={key}
              disableRipple
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          ))}
        </Tabs>
      </div>
      <div className={classes.tabContentContainer}>
        <TabItem items={content[current]} {...rest} />
      </div>
      {!hasResults && (
        <div className={classes.noSearchResultsFound}>
          {translateData('No search results found')}
        </div>
      )}
    </>
  );
};

ContentfullTabs.propTypes = {
  document: PropTypes.object,
  classes: PropTypes.object,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ContentfullTabs),
);
