import { all, takeLatest, take, call, put, select } from 'redux-saga/effects';
import {
  isEmpty,
  capitalize,
  compact,
  map,
  get,
  find,
  sortBy,
  isObject,
  forEach,
  flatten,
  flattenDepth,
} from 'lodash';
import ContentfulClient from 'utils/ContentfulClient';
import { apiCall } from 'utils/swaggerClient';
import { isPurefloDomain } from 'utils/domainHelper';
import { roundPricesToTwoDecimalPlaces, adjustPostalCode } from 'utils/common';
import {
  fetchProductCards,
  fetchProducts,
  fetchProductTypes,
} from 'utils/contentfulUtils';
import {
  throwErrorTest,
  handleError,
  getErrorMessage,
} from 'utils/errorHandling';
import {
  selectSelectedServiceLocation,
  selectSelectedServiceLocationId,
  selectSelectedServiceLocationBranch,
} from 'containers/PrimoAccount/selectors';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import {
  selectProductListItems,
  selectProductCategoriesItems,
  selectSection,
  selectHomeOffice,
} from 'containers/Landing/selectors';
import { UPDATE_USER_REGION } from 'containers/UserRegion/constants';
import { getOrFetchServiceLocation } from 'containers/PrimoAccount/saga';
import { addToCart } from 'containers/Cart/actions';
import { SECTION_OFFICE } from 'containers/Landing/constants';
import { isSsr } from 'utils/ssrHelper';
import { dataLayerPushSuccess, dataLayerPushFailure } from 'utils/tracking';
import { hasBrandTag } from 'components/Content/utils';
import { getRequestedFavorites } from './Favorites/state/saga';
import { addToDelivery as addToDeliveryAction } from './actions';
import * as types from './constants';
import { processCategories, processProductTypes } from './helpers';

const contentfulClient = new ContentfulClient();

const filterDeliveryItems = (delivery) =>
  get(delivery, 'items').filter((item) => get(item, 'itemType', '') !== 'PR');

function* loadProducts(itemNumbers) {
  if (isEmpty(itemNumbers)) {
    throw Error('No product numbers found');
  }
  let postalCode = yield select(selectPostalCode());
  postalCode = adjustPostalCode(postalCode);

  if (!postalCode) {
    yield take(UPDATE_USER_REGION);
    postalCode = yield select(selectPostalCode());
    postalCode = adjustPostalCode(postalCode);
  }

  if (postalCode) {
    const products = yield call(apiCall, {
      operationId: 'Products.listProducts',
      parameters: { postalCode, itemNumbers },
    });
    return products;
  }

  throw Error('No postal code found');
}

export function* loadFamilyProductBundles({ payload: item }) {
  try {
    const itemNumbers = flattenDepth(
      item.familyBundles.map((fb) =>
        fb.fields.items.map(
          (bundleItem) => bundleItem.fields.product.fields.itemNumber,
        ),
      ),
      2,
    );

    const products = yield call(loadProducts, itemNumbers);

    const mergedBundles = item.familyBundles
      .map((fb) => ({
        ...fb,
        ...fb.fields,
        items: fb.fields.items.map((bundleItem) => {
          const { product } = bundleItem.fields;
          const apiItem = products.find(
            (el) => el.itemNumber === product.fields.itemNumber,
          );
          const categories = map(
            get(product, 'fields.category', []),
            (category) => get(category, 'fields.name'),
          ).join(',');

          return {
            ...bundleItem,
            fields: {
              ...bundleItem.fields,
              isValidBundle: !!apiItem,
              product: {
                ...product,
                fields: {
                  ...product.fields,
                  price: get(apiItem, 'price'),
                  branchId: get(apiItem, 'branchId'),
                  category: categories,
                },
              },
            },
          };
        }),
      }))
      .filter((fb) => fb?.items?.every((i) => i.fields.isValidBundle));
    yield put({
      type: types.LOAD_FAMILY_PRODUCT_BUNDLES_SUCCESS,
      payload: {
        familyProductBundles: mergedBundles,
      },
    });
  } catch (error) {
    yield handleError(
      'loadFamilyProductBundles',
      types.LOAD_FAMILY_PRODUCT_BUNDLES_FAILURE,
      getErrorMessage(error),
    );
  }
}

export function* loadProductBundles({ payload: item }) {
  try {
    const categoriesResponse = yield call(contentfulClient.getEntries, {
      contentType: 'productCategory',
      additionalQueryParams: {
        'sys.id': item.categoryId,
      },
    });

    const productBundles = categoriesResponse.items[0].fields?.products;
    const bundleItems = flatten(
      productBundles.map((bundle) =>
        bundle.fields.items.map((bundleItem) => bundleItem.fields.product),
      ),
    );

    const itemNumbers = map(bundleItems, 'fields.itemNumber');
    const products = yield call(loadProducts, itemNumbers);

    const mergedBundles = productBundles
      .filter((b) => hasBrandTag(b))
      .map((bundle, index) => ({
        ...bundle,
        id: index,
        fields: {
          ...bundle.fields,
          items: bundle.fields.items.map((bundleItem) => {
            const { product } = bundleItem.fields;
            const apiItem = products.find(
              (el) => el.itemNumber === product.fields.itemNumber,
            );

            const categories = map(
              get(product, 'fields.category', []),
              (category) => get(category, 'fields.name'),
            ).join(',');

            return {
              ...bundleItem,
              fields: {
                ...bundleItem.fields,
                isValidBundle: !!apiItem,
                product: {
                  ...product,
                  fields: {
                    ...product.fields,
                    price: get(apiItem, 'price'),
                    branchId: get(apiItem, 'branchId'),
                    category: categories,
                  },
                },
              },
            };
          }),
        },
      }))
      .filter((fb) => fb?.fields.items?.every((i) => i.fields.isValidBundle));
    yield put({
      type: types.LOAD_PRODUCT_BUNDLES_SUCCESS,
      payload: {
        products: mergedBundles,
        totalCount: mergedBundles.length,
        brands: [],
        waterTypes: [],
        page: 1,
      },
    });
  } catch (error) {
    yield handleError(
      'loadProductBundles',
      types.LOAD_PRODUCT_BUNDLES_FAILURE,
      getErrorMessage(error),
    );
  }
}
export function* loadProductCategories() {
  let section = yield select(selectSection());
  if (!section) {
    section = localStorage.getItem('section');
  }
  const queryObj =
    section === SECTION_OFFICE ? { office: true } : { home: true };

  try {
    throwErrorTest('loadProductCategories');
    const categoriesResponse = yield call(contentfulClient.getEntries, {
      contentType: 'productCategory',
      query: queryObj,
      additionalQueryParams: {
        select: 'fields.name,fields.urlName,fields.sequence,metadata.tags',
      },
    });

    const items = processCategories(categoriesResponse.items);

    yield put({
      type: types.LOAD_PRODUCT_CATEGORIES_SUCCESS,
      payload: { categories: items },
    });
  } catch (error) {
    yield handleError(
      'loadProductCategories',
      types.LOAD_PRODUCT_CATEGORIES_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* addToOrder({ payload: item }) {
  const isAuthenticated = yield select(selectIsAuthenticated());

  if (isAuthenticated) {
    yield put(addToDeliveryAction(item));
    return;
  }

  yield put(addToCart(item));
}

function* addToDelivery({ payload: newItem }) {
  try {
    throwErrorTest('addToDelivery');
    const selectedServiceLocation = yield select(
      selectSelectedServiceLocation(),
    );
    const delivery = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationDelivery',
      parameters: {
        serviceLocationId: selectedServiceLocation.id,
        deliveryDate: selectedServiceLocation.nextDeliveryDate,
      },
    });

    const deliveryItems = filterDeliveryItems(delivery);

    const isProductInDelivery = !isEmpty(
      deliveryItems.find(
        (deliveryItem) => deliveryItem.itemNumber === newItem.itemNumber,
      ),
    );

    const getUpdatedDeliveryItems = () => {
      const deliveryItemsWithPricesRounded = deliveryItems.map(
        (deliveryItem) => {
          deliveryItem.deliveryAction = 'NO_CHANGE';
          roundPricesToTwoDecimalPlaces(deliveryItem);
          return deliveryItem;
        },
      );
      if (isProductInDelivery) {
        return deliveryItemsWithPricesRounded.map((deliveryItem) => {
          if (deliveryItem.itemNumber === newItem.itemNumber) {
            return {
              ...deliveryItem,
              quantity: deliveryItem.quantity + newItem.quantity,
              deliveryAction: 'CHANGED',
            };
          }
          return deliveryItem;
        });
      }

      const newItemWithPricesRounded = roundPricesToTwoDecimalPlaces(newItem);
      newItemWithPricesRounded.deliveryAction = 'NEW';
      return [...deliveryItemsWithPricesRounded, newItemWithPricesRounded];
    };

    const updatedDelivery = { ...delivery, items: getUpdatedDeliveryItems() };

    yield call(apiCall, {
      operationId: 'ServiceLocations.editDelivery',
      parameters: {
        serviceLocationId: selectedServiceLocation.id,
        deliveryDate: delivery.date,
      },
      options: { requestBody: updatedDelivery },
    });

    const href = !isSsr() ? window.location.href.toLowerCase() : '';
    if (href.includes('account/products')) {
      yield put({ type: types.SHOW_ORDER_CHANGE_MESSAGE });
    }

    yield put({ type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST });

    yield put({ type: types.ADD_TO_DELIVERY_SUCCESS });

    dataLayerPushSuccess('PUSH_ADD_TO_ONE_TIME_ORDER');
  } catch (error) {
    yield handleError(
      'addToDelivery',
      types.ADD_TO_DELIVERY_FAILURE,
      getErrorMessage(error),
    );
    dataLayerPushFailure('PUSH_ADD_TO_ONE_TIME_ORDER');
  }
}

function* addToDeliveryOrder({
  payload: { deliveryOrder, product, quantity },
}) {
  try {
    throwErrorTest('addToDeliveryOrder');
    const selectedServiceLocation = yield select(
      selectSelectedServiceLocation(),
    );
    const delivery = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationDelivery',
      parameters: {
        serviceLocationId: selectedServiceLocation.id,
        deliveryDate: deliveryOrder.date,
      },
    });

    const deliveryItems = filterDeliveryItems(delivery);

    const isProductInDelivery = !isEmpty(
      deliveryItems.find(
        (deliveryItem) => deliveryItem.itemNumber === product.itemNumber,
      ),
    );

    const getUpdatedDeliveryItems = () => {
      const deliveryItemsWithPricesRounded = deliveryItems.map(
        (deliveryItem) => {
          deliveryItem.deliveryAction = 'NO_CHANGE';
          roundPricesToTwoDecimalPlaces(deliveryItem);
          return deliveryItem;
        },
      );
      if (isProductInDelivery) {
        return deliveryItemsWithPricesRounded.map((deliveryItem) => {
          if (deliveryItem.itemNumber === product.itemNumber) {
            return {
              ...deliveryItem,
              quantity: deliveryItem.quantity + quantity,
              deliveryAction: 'CHANGED',
            };
          }
          return deliveryItem;
        });
      }

      const newItemWithPricesRounded = {
        itemNumber: product.itemNumber,
        deliveryAction: 'NEW',
        isRecurring: false,
        quantity,
      };
      return [...deliveryItemsWithPricesRounded, newItemWithPricesRounded];
    };

    const updatedDelivery = { ...delivery, items: getUpdatedDeliveryItems() };

    yield call(apiCall, {
      operationId: 'ServiceLocations.editDelivery',
      parameters: {
        serviceLocationId: selectedServiceLocation.id,
        deliveryDate: deliveryOrder.date,
      },
      options: { requestBody: updatedDelivery },
    });

    if (!deliveryOrder.itemsAdded) {
      deliveryOrder.itemsAdded = [];
    }
    deliveryOrder.itemsAdded.push(product);

    yield put({ type: types.ADD_TO_DELIVERY_ORDER_SUCCESS });
  } catch (error) {
    yield handleError(
      'addToDeliveryOrder',
      types.ADD_TO_DELIVERY_ORDER_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* loadServiceLocationProductsRequest({
  itemNumbers,
  categoryId,
  brands,
  waterTypes,
  page,
  isCostcoWater,
  size,
}) {
  const selectedServiceLocation = yield call(getOrFetchServiceLocation);
  const branchCode = yield select(selectSelectedServiceLocationBranch());

  const requestedFavorites = yield call(getRequestedFavorites);

  itemNumbers =
    (requestedFavorites && requestedFavorites.toArray()) || itemNumbers;

  const productParameters = {
    serviceLocationId: selectedServiceLocation.id,
    orderDate: selectedServiceLocation.nextDeliveryDate,
    itemNumbers,
    merchandized: true,
    selfServe: true,
    categoryId,
    brands,
    waterTypes,
    page,
    branchCode,
    size: size === 'all' ? undefined : types.PRODUCTS_PER_PAGE,
    partnerName: isCostcoWater ? 'Costco' : null,
  };

  return yield call(apiCall, {
    operationId: 'ServiceLocations.getServiceLocationProducts',
    parameters: productParameters,
    fullResponse: true,
  });
}

function* loadProductsRequest({
  itemNumber,
  categoryId,
  brands,
  waterTypes,
  page,
  isCostcoWater,
  size,
}) {
  const section = yield select(selectSection());
  const homeOffice = yield select(selectHomeOffice());
  let postalCode = yield select(selectPostalCode());
  postalCode = adjustPostalCode(postalCode);
  if (!postalCode) {
    yield take(UPDATE_USER_REGION);
    postalCode = yield select(selectPostalCode());
    postalCode = adjustPostalCode(postalCode);
  }

  const productParameters = isCostcoWater
    ? {
        postalCode,
        itemNumber,
        customerType: types.productsTypes[homeOffice],
        segments: [capitalize(homeOffice)],
        merchandized: true,
        categoryId,
        brands,
        waterTypes,
        page,
        size: size === 'all' ? undefined : types.PRODUCTS_PER_PAGE,
        partnerName: 'Costco',
      }
    : {
        postalCode,
        itemNumber,
        customerType: types.productsTypes[section],
        segments: [capitalize(section)],
        merchandized: true,
        categoryId,
        brands,
        waterTypes,
        page,
        size: size === 'all' ? undefined : types.PRODUCTS_PER_PAGE,
      };
  return yield call(apiCall, {
    operationId: 'Products.listProducts',
    parameters: productParameters,
    fullResponse: true,
  });
}

export function* loadMerchandizedProducts({
  payload: {
    itemNumber,
    categoryId,
    brands,
    waterTypes,
    page = 1,
    isCostcoWater,
    size,
  },
}) {
  try {
    throwErrorTest('loadMerchandizedProducts');
    const isAuthenticated = yield select(selectIsAuthenticated());
    const apiProductsResponse = yield isAuthenticated
      ? loadServiceLocationProductsRequest({
          itemNumbers: [itemNumber],
          categoryId,
          brands,
          waterTypes,
          page,
          size,
        })
      : loadProductsRequest({
          itemNumber,
          categoryId,
          brands,
          waterTypes,
          page,
          isCostcoWater,
          size,
        });

    const onlyImages = true;
    const products = yield call(
      completeProductItemsSaga,
      apiProductsResponse.body,
      onlyImages,
      categoryId,
    );

    yield put({
      type: types.LOAD_MERCHANDIZED_PRODUCTS_SUCCESS,
      payload: {
        products,
        totalCount: apiProductsResponse.headers['x-total-count'],
        brands: apiProductsResponse.headers['x-brands'],
        waterTypes: apiProductsResponse.headers['x-water-types'],
        page,
      },
    });
  } catch (error) {
    yield handleError(
      'loadMerchandizedProducts',
      types.LOAD_MERCHANDIZED_PRODUCTS_FAILURE,
      getErrorMessage(error),
    );
  }
}

export function* loadAvailableProducts() {
  try {
    throwErrorTest('loadAvailableProducts');
    const isPureflo = isPurefloDomain();
    const contentfulProducts = yield select(
      isPureflo ? selectProductListItems() : selectProductCategoriesItems(),
    );
    let postalCode = yield select(selectPostalCode());
    postalCode = adjustPostalCode(postalCode);
    if (!postalCode) {
      yield take(UPDATE_USER_REGION);
      postalCode = yield select(selectPostalCode());
      postalCode = adjustPostalCode(postalCode);
    }
    if (postalCode && !isEmpty(contentfulProducts)) {
      const itemNumbers = map(contentfulProducts, 'fields.itemNumber');
      const products = yield call(apiCall, {
        operationId: 'Products.listProducts',
        parameters: { postalCode, itemNumbers },
      });
      yield put({
        type: types.LOAD_AVAILABLE_PRODUCTS_SUCCESS,
        payload: products,
      });
    } else {
      throw Error('No postal code or product categories are empty');
    }
  } catch (error) {
    yield handleError(
      'loadAvailableProducts',
      types.LOAD_AVAILABLE_PRODUCTS_FAILURE,
      getErrorMessage(error),
    );
  }
}

// Method used across application (cart, checkout, product pages) where need to combine results from API and Contentful
export function* completeProductItemsSaga(
  items = [],
  onlyImages = false,
  categoryId,
) {
  if (isEmpty(items)) return [];

  const isPureflo = isPurefloDomain();
  const itemNumbers = map(items, 'itemNumber');
  const contentfulProductItems = yield call(
    isPureflo ? fetchProductCards : fetchProducts,
    itemNumbers,
    contentfulClient,
    onlyImages,
  );
  const contentfulItems = contentfulProductItems.items;
  const sortedItems = [];
  forEach(itemNumbers, (itemNumber) => {
    const found = contentfulItems.find(
      (item) => itemNumber === item.fields.itemNumber,
    );
    if (found) {
      sortedItems.push(found);
    }
  });
  contentfulProductItems.items = sortedItems;

  const productTypesEntries = yield call(fetchProductTypes, contentfulClient);

  const productTypes = processProductTypes(productTypesEntries);

  return map(get(contentfulProductItems, 'items', []), (contentfulProduct) => {
    const categories = compact(
      map(get(contentfulProduct, 'fields.category', []), (category) =>
        get(category, 'fields.name'),
      ),
    )
      .sort((a, b) => (a > b ? 1 : -1))
      .join(' | ');
    const itemFields =
      items.find(
        (item) =>
          item.itemNumber === get(contentfulProduct, 'fields.itemNumber'),
      ) || {};
    const categoryFilter = find(
      itemFields.category,
      (category) => category.id === categoryId,
    );
    const contentfulProductFields = get(contentfulProduct, 'fields');
    let { longDescription } = itemFields;
    if (
      isObject(contentfulProductFields.description) &&
      contentfulProductFields.description.nodeType === 'document'
    ) {
      longDescription = contentfulProductFields.description.content.reduce(
        (acc, content) => {
          const newLine = acc ? '\n' : '';
          acc = `${acc}${newLine}${content.content[0].value}`;
          return acc;
        },
        '',
      );
    }
    const completeProduct = {
      ...itemFields,
      ...contentfulProductFields,
      longDescription,
      image: get(contentfulProduct, 'fields.images[0].fields.file'),
      category: categories,
      categoryFilter: categoryFilter || { id: null, name: 'All products' },
      productType:
        productTypes[get(contentfulProduct, 'fields.productType.sys.id')],
    };
    return completeProduct;
  });
}

function* loadFeaturedProducts() {
  try {
    throwErrorTest('loadFeaturedProducts');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());

    const response = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationRecommendedProducts',
      parameters: {
        serviceLocationId,
      },
    });

    const completeProducts = yield call(
      completeProductItemsSaga,
      response,
      true,
    );
    const products = sortBy(completeProducts, ['sequence'], ['asc']).slice(
      0,
      9,
    );

    yield put({
      type: types.LOAD_FEATURED_PRODUCTS_SUCCESS,
      payload: {
        products,
      },
    });
  } catch (error) {
    yield handleError(
      'loadFeaturedProducts',
      types.LOAD_FEATURED_PRODUCTS_FAILURE,
      getErrorMessage(error),
    );
  }
}

export function* completeProductItemsWithNonContentfulSaga(
  items = [],
  onlyImages = false,
  categoryId,
) {
  // Combine items from API and Contentful, then if the item is not in Contentful and has a quantity > 0, include it
  const itemsContentful = yield call(
    completeProductItemsSaga,
    items,
    onlyImages,
    categoryId,
  );

  const itemsWithNonContentful = [];
  items.forEach((item) => {
    const contentfulItem = itemsContentful.find(
      (itemContentful) => itemContentful.itemNumber === item.itemNumber,
    );
    if (contentfulItem) {
      itemsWithNonContentful.push(contentfulItem);
    } else if (item.quantity > 0) {
      itemsWithNonContentful.push(item);
    }
  });

  return itemsWithNonContentful;
}

export default function* primoProductsSaga() {
  yield all([
    takeLatest(types.LOAD_PRODUCT_CATEGORIES, loadProductCategories),
    takeLatest(types.ADD_TO_ORDER, addToOrder),
    takeLatest(types.ADD_TO_DELIVERY, addToDelivery),
    takeLatest(types.ADD_TO_DELIVERY_ORDER, addToDeliveryOrder),
    takeLatest(types.LOAD_MERCHANDIZED_PRODUCTS, loadMerchandizedProducts),
    takeLatest(types.LOAD_AVAILABLE_PRODUCTS, loadAvailableProducts),
    takeLatest(types.LOAD_FEATURED_PRODUCTS, loadFeaturedProducts),
    takeLatest(types.LOAD_PRODUCT_BUNDLES, loadProductBundles),
    takeLatest(types.LOAD_FAMILY_PRODUCT_BUNDLES, loadFamilyProductBundles),
  ]);
}
