import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Popper from 'components/Ui/Popper';
import { selectItemsCount } from 'containers/Cart/selectors';
import CartItemsCounter from './CartItemsCounter';
import EmptyCart from './EmptyCart';
import CartList from './CartList';
import styles from './styles.scss';

const CartPopper = ({ itemsCount }) => {
  const ref = React.createRef();

  const tooltipButton = (
    <div ref={ref}>
      <CartItemsCounter count={itemsCount} />
    </div>
  );

  return (
    <div>
      <Popper
        role="dialog"
        title="List of items in your cart"
        className={styles.cartPopper}
        arrowClassName={styles.popperArrow}
        button={tooltipButton}
        disablePortal
        placement="bottom-end"
      >
        {itemsCount ? <CartList /> : <EmptyCart />}
      </Popper>
    </div>
  );
};

CartPopper.propTypes = {
  itemsCount: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  itemsCount: selectItemsCount(),
});

export default connect(mapStateToProps)(CartPopper);
