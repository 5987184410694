import React, { Fragment } from 'react';

import EditRecurringOrderDrawer from './EditRecurringOrderDrawer';
import AddExtraDeliveryDrawer from './AddExtraDeliveryDrawer';

const OrderEditor = () => (
  <Fragment>
    <EditRecurringOrderDrawer />
    <AddExtraDeliveryDrawer />
  </Fragment>
);

OrderEditor.propTypes = {};

export default OrderEditor;
