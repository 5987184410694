import Immutable, { fromJS } from 'immutable';
import { get, map, unionBy, forEach, uniqBy, flatten } from 'lodash';
import { LOAD_AVAILABLE_PRODUCTS_SUCCESS } from 'containers/PrimoProducts/constants';
import {
  getDomainByHost,
  getRegionalBrandName,
  isAcquisitionDomain,
} from 'utils/domainHelper';
import { injectVariables, CA_EN_URL } from 'utils/translation';
import { isBrowser } from 'utils/ssrHelper';
import cookies from 'js-cookie';
import { hasTag } from 'components/Content/utils';
import * as types from './constants';

const initialState = Immutable.fromJS({
  name: '',
  slug: getDomainByHost(8).slug,
  loading: false,
  fetchingBrand: false,
  components: {
    footer: null,
    header: null,
    page: null,
  },
  layouts: null,
  theme: 'water-regional',
  section: isAcquisitionDomain() ? 'home' : null,
  homeOffice: 'home',
  promotionLandingPageData: {
    data: {},
    error: false,
  },
  forceFetch: false,
  showBrandSwitchDialog: false,
  showZipCodeEditPopper: true,
  showInitialZipDialog: true,
  showCostcoMembershipDialog: false,
  costcoAccount: null,
  featureTour: {
    videoLink: false,
    loading: false,
    error: null,
    displayFeatureTour: false,
  },
  logo: {
    logo: false,
    loading: false,
    error: null,
  },
  entries: {
    primoTransitionBanner: {},
    acquisitionVariables: [],
    loading: false,
    error: null,
  },
  ssrReqRoute: {},
  pageNotFound: false,
  statusCode: 200,
  gclId: null,
  language: CA_EN_URL,
  steps: {
    currentStep: 1,
    step1: {
      '100 Series': 0,
      '200 Series': 0,
      '200k Series': 0,
      '300 Series': 0,
      '500 Series': 0,
    },
    step2: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
    },
  },
});

const mergeProductData = (contentfulProducts, apiProducts) => {
  const mergedProducts = contentfulProducts.map((product) => {
    const apiItem = apiProducts.find(
      (el) => el.itemNumber === product.fields.itemNumber,
    );

    const categories = map(get(product, 'fields.category', []), (category) =>
      get(category, 'fields.name'),
    ).join(',');

    return {
      ...product,
      isValidProduct: !!apiItem,
      fields: {
        ...product.fields,
        price: get(apiItem, 'price'),
        branchId: get(apiItem, 'branchId'),
        category: categories,
      },
    };
  });

  return mergedProducts;
};

function getLayoutBySection(layouts, section) {
  if (!section) {
    return layouts[0].fields;
  }

  if (section === types.SECTION_HOME) {
    const currentLayout = layouts.find(
      (sectionLayout) => sectionLayout.fields.slug === 'residential',
    );

    if (currentLayout) {
      return currentLayout.fields;
    }
  } else if (section === types.SECTION_OFFICE) {
    const currentLayout = layouts.find(
      (sectionLayout) => sectionLayout.fields.slug === 'commercial',
    );

    if (currentLayout) {
      return currentLayout.fields;
    }
  }
  return layouts[0].fields;
}

const processFeatureTour = (rawFeatureTourContent) =>
  get(rawFeatureTourContent, 'items[0].fields');

const processLogo = (rawContent) => get(rawContent, 'items[0].fields');

function landingReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SITE_REQUEST: {
      return state.set('loading', true);
    }
    case types.FETCH_SITE_SUCCESS: {
      const {
        layout: {
          fields: { footer, header, leads },
        },
        theme,
        name,
        slug,
      } = action.payload.items[0].fields;

      return state
        .set('loading', false)
        .set('name', name)
        .set('slug', slug)
        .set('theme', theme)
        .setIn(['components', 'footer'], footer)
        .setIn(['components', 'header'], header)
        .setIn(['components', 'leads'], leads);
    }
    case types.FETCH_SITE_FAILURE: {
      return state.set('loading', false).set('forceFetch', false);
    }
    case types.SET_LOADER_FALSE: {
      return state.set('loading', false);
    }
    case types.FETCH_PAGE_REQUEST: {
      return state.set('loading', true);
    }
    case types.FETCH_PAGE_SUCCESS: {
      const {
        noIndex,
        contentModules,
        entryTitle,
        slug,
        title,
        description,
        brandedDescription,
        layouts,
        metaData,
        site: {
          fields: { theme },
        },
      } = action.payload.page.items[0].fields;
      const { key } = action.payload;

      const { header, footer, leads } = getLayoutBySection(
        layouts,
        state.get('section'),
      );

      const regionalBrandName = getRegionalBrandName(
        action.payload.ssrReqRoute,
        7,
      );

      let brandedDescriptionValue;
      const brandedDescriptionContent = get(brandedDescription, 'content');
      if (brandedDescriptionContent) {
        forEach(brandedDescriptionContent, (item) => {
          const brandedDescriptionEntryTitle = get(
            item,
            'data.target.fields.entryTitle',
          );
          if (
            brandedDescriptionEntryTitle &&
            brandedDescriptionEntryTitle.includes(regionalBrandName)
          ) {
            brandedDescriptionValue = get(
              item,
              'data.target.fields.content.content[0].content[0].value',
            );
          }
        });
      }

      return state
        .set('loading', false)
        .set('setPageNotFound', false)
        .set('forceFetch', false)
        .set('theme', theme)
        .setIn(['components', 'page'], {
          noIndex,
          contentModules,
          entryTitle,
          slug,
          title: key
            ? injectVariables(
                title,
                regionalBrandName,
                action.payload.acquisitionVariables,
                'C27',
              )
            : 'Primo Water North America',
          description: injectVariables(
            brandedDescriptionValue || description,
            regionalBrandName,
            action.payload.acquisitionVariables,
            'C28',
          ),
          metaData,
        })
        .setIn(['components', 'footer'], footer)
        .setIn(['components', 'header'], header)
        .setIn(['components', 'leads'], leads);
    }
    case types.FETCH_PAGE_FAILURE: {
      return state.set('loading', false).setIn(['components', 'page'], null);
    }
    case types.SET_LAYOUTS:
      return state.set('layouts', action.payload);
    case types.SET_HEADER_FOOTER: {
      const { header, footer, leads } = action.payload;
      return state
        .setIn(['components', 'footer'], footer)
        .setIn(['components', 'header'], header)
        .setIn(['components', 'leads'], leads);
    }
    case LOAD_AVAILABLE_PRODUCTS_SUCCESS: {
      const components = state.get('components').toJS();
      components.page.availableProductsSuccess = true;
      const newModules = components.page.contentModules.map((mod) => {
        if (get(mod, 'sys.contentType.sys.id') === 'productCategory') {
          if (hasTag(mod, 'productBundle')) {
            const productInBundle = uniqBy(
              flatten(
                mod.fields.products.map((bundle) =>
                  bundle.fields.items.map(
                    (bundleItem) => bundleItem.fields.product,
                  ),
                ),
              ),
              'fields.itemNumber',
            );

            return {
              ...mod,
              fields: {
                ...mod.fields,
                productBundles: mod.fields.products
                  .map((bundle) => ({
                    ...bundle,
                    fields: {
                      ...bundle.fields,
                      items: bundle.fields.items.map((bundleItem) => ({
                        ...bundleItem,
                        fields: {
                          ...bundleItem.fields,
                          product: mergeProductData(
                            [bundleItem.fields.product],
                            action.payload,
                          )[0],
                        },
                      })),
                    },
                  }))
                  .filter((fb) =>
                    fb?.fields?.items?.every(
                      (i) => i.fields.product.isValidProduct,
                    ),
                  ),
                products: mergeProductData(productInBundle, action.payload),
              },
            };
          }

          return {
            ...mod,
            fields: {
              ...mod.fields,
              productBundles: [],
              products: mergeProductData(mod.fields.products, action.payload),
            },
          };
        }
        return mod;
      });

      return state.mergeDeep(
        fromJS({
          components: Object.assign(components.page, {
            contentModules: newModules,
          }),
        }),
      );
    }
    case types.SET_SECTION:
      if (isBrowser()) {
        localStorage.setItem('section', action.payload);
      }
      cookies.set('section', action.payload);
      return state.set('section', action.payload);
    case types.SET_HOME_OFFICE:
      return state.set('homeOffice', action.payload);
    case types.NAVIGATE_ZIP_REDIRECT:
      return state.set('fetchingBrand', true);
    case types.SET_FORCE_FETCH:
      return state.set('forceFetch', action.payload);
    case types.NAVIGATE_ZIP_REDIRECT_SUCCESS:
      return state.set('fetchingBrand', false);
    case types.NAVIGATE_ZIP_REDIRECT_FAILURE:
      return state.set('fetchingBrand', false);
    case types.SHOW_BRAND_SWITCH_DIALOG:
      return state.set('showBrandSwitchDialog', true);
    case types.SHOW_ZIPCODE_POPPER:
      return state.set('showZipCodeEditPopper', action.payload);
    case types.HIDE_BRAND_SWITCH_DIALOG:
      return state.set('showBrandSwitchDialog', false);
    case types.HIDE_INITIAL_ZIP_DIALOG:
      return state.set('showInitialZipDialog', false);
    case types.NAVIGATE_COSTCO_ROUTE:
      return state.set('costcoAccount', action.payload);
    case types.SHOW_COSTCO_MEMBERSHIP_DIALOG:
      return state.set('showCostcoMembershipDialog', true);
    case types.HIDE_COSTCO_MEMBERSHIP_DIALOG:
      return state.set('showCostcoMembershipDialog', false);
    case types.FETCH_FEATURE_TOUR: {
      return state
        .setIn(['featureTour', 'loading'], true)
        .setIn(['featureTour', 'promotion'], {})
        .setIn(['featureTour', 'error'], null)
        .setIn(['featureTour', 'displayFeatureTour'], false);
    }
    case types.FETCH_FEATURE_TOUR_SUCCESS: {
      return state
        .setIn(['featureTour', 'loading'], false)
        .setIn(['featureTour', 'displayFeatureTour'], true)
        .setIn(['featureTour', 'video'], processFeatureTour(action.payload));
    }
    case types.FETCH_FEATURE_TOUR_FAILURE:
      return state
        .setIn(['featureTour', 'loading'], false)
        .setIn(['featureTour', 'error'], action.payload)
        .setIn(['featureTour', 'displayFeatureTour'], false);
    case types.FETCH_LOGO: {
      return state
        .setIn(['logo', 'loading'], true)
        .setIn(['logo', 'error'], null);
    }
    case types.FETCH_LOGO_SUCCESS: {
      return state
        .setIn(['logo', 'loading'], false)
        .setIn(['logo', 'logo'], processLogo(action.payload));
    }
    case types.FETCH_LOGO_FAILURE:
      return state
        .setIn(['logo', 'loading'], false)
        .setIn(['logo', 'error'], action.payload);
    case types.FETCH_ENTRY_SUCCESS: {
      return state
        .setIn(['entries', 'loading'], false)
        .setIn(['entries', action.payload.key], action.payload.entry);
    }
    case types.FETCH_ENTRY_FAILURE:
      return state
        .setIn(['entries', 'loading'], false)
        .setIn(['entries', 'error'], action.payload);
    case types.UPDATE_VARIABLES: {
      const acquisitionVariables = state.getIn([
        'entries',
        'acquisitionVariables',
      ]);
      return state.setIn(
        ['entries', 'acquisitionVariables'],
        unionBy(action.payload, acquisitionVariables, 'name'),
      );
    }
    case types.FETCH_PROMOTION_LANDING_DATA:
      return state
        .setIn(['promotionLandingPageData', 'data'], {})
        .setIn(['promotionLandingPageData', 'error'], false);
    case types.FETCH_PROMOTION_LANDING_DATA_SUCCESS:
      return state
        .setIn(['promotionLandingPageData', 'data'], action.payload)
        .setIn(['promotionLandingPageData', 'error'], false);
    case types.FETCH_PROMOTION_LANDING_DATA_FAILURE:
      return state.setIn(['promotionLandingPageData', 'error'], true);
    case types.SSR_REQ_ROUTE: {
      return state.set('ssrReqRoute', action.payload);
    }
    case types.SET_PAGE_NOT_FOUND:
      return state.set('pageNotFound', true);
    case types.SET_SSR_REQ_STATUS_CODE:
      return state.set('statusCode', action.payload);
    case types.SET_GCL_ID:
      return state.set('gclId', action.payload);
    case types.SET_LANGUAGE:
      return state.set('language', action.payload);
    case types.SET_STEPS:
      return state.set('steps', fromJS(action.payload));
    default: {
      return state;
    }
  }
}

export default landingReducer;
