module.exports = (theme) => ({
  select: {
    boxShadow: theme.boxShadows.darkShadow,
    borderRadius: 5,
    '&:focus': {
      backgroundColor: theme.colors.whiteGrey,
      borderRadius: 5,
    },
  },
  menuPaper: {
    boxShadow: theme.boxShadows.darkShadow,
    borderRadius: 5,
  },
  menuList: {
    padding: '0px !important',
  },
  menuItem: {
    color: theme.colors.middleGrey,
    fontSize: '0.75rem',
    fontWeight: 600,
    height: '0.9375rem',
    padding: '12px 9px',
    transition: theme.smoothTransition,
    '&:nth-child(2n)': {
      backgroundColor: theme.colors.whiteGrey,
    },
    '&:hover': {
      backgroundColor: `${theme.colors.primaryBlue} !important`,
      color: theme.colors.white,
    },
  },
  menuItemSelected: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      right: 17,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 11,
      width: 11,
      boxSizing: 'border-box',
      border: '1px solid rgba(0,0,0,0.25)',
      borderRadius: '50%',
      backgroundColor: theme.colors.lime,
    },
    '&:nth-child(2n)': {
      backgroundColor: theme.colors.whiteGrey,
    },
    '&:nth-child(2n + 1)': {
      backgroundColor: theme.colors.white,
    },
  },
  icon: {
    backgroundColor: theme.colors.primaryDarkBlue,
    height: 'calc(100% + 2px)',
    top: '-1px !important',
    right: '-1px',
  },
  selectMenu: {
    backgroundColor: theme.colors.white,
  },
});
