import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CloseExpandIcon = (props) => (
  <SvgIcon
    viewBox="0 0 40 40"
    width="40px"
    height="40px"
    fontSize="small"
    {...props}
  >
    <g
      id="FAQs_HO"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="2_3.5.3_SPK_FAQs_HO-DT_1440-"
        transform="translate(-1125.000000, -949.000000)"
      >
        <g id="Accordians-" transform="translate(250.000000, 559.000000)">
          <g id="Account-Info">
            <g id="Open-Accordian-" transform="translate(0.000000, 373.000000)">
              <g
                id="btn/arrow/blue-copy"
                transform="translate(875.000000, 17.000000)"
              >
                <g id="ArrowButton">
                  <circle
                    id="Oval"
                    fill="#C7C8CA"
                    cx="20"
                    cy="20"
                    r="20"
                  ></circle>
                  <g
                    id="Group-2"
                    transform="translate(20.500000, 20.500000) rotate(-315.000000) translate(-20.500000, -20.500000) translate(13.000000, 13.000000)"
                    stroke="#FFFFFF"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <path d="M14.9521484,0 L0,14.9521484" id="Path-4"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default CloseExpandIcon;
