import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectSsrReqRoute } from 'containers/Landing/selectors';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { get } from 'lodash';
import { makeOneTrustTogglesOn } from 'utils/hacks';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import {
  getRegionalBrandName,
  ALL_BRANDS,
  US_BRANDS,
  CA_BRANDS,
} from 'utils/domainHelper';
import { Span } from 'components/Ui';

const Copyright = ({ fields, isCostcoWater, ssrReqRoute }, { stylesTheme }) => {
  useEffect(() => {
    makeOneTrustTogglesOn('[href="/#cookie-prefs"]');
  }, [fields]);

  let index = 0;
  const styles = require(`./styles/${stylesTheme}.scss`);
  const regionalBrandName = getRegionalBrandName(ssrReqRoute, 14);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Span
          // eslint-disable-next-line no-plusplus
          key={`Copyright${index++}`}
          className={styles.paragraph}
          defaultText={'C0'}
        >
          {children}
        </Span>
      ),
      [INLINES.HYPERLINK]: (node, children) =>
        linkParser(node, children, styles.link),
      [INLINES.ENTRY_HYPERLINK]: (node, children) =>
        linkParser(node, children, styles.link),
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'brandedContent': {
            const entryTitle = get(node, 'data.target.fields.entryTitle', '');
            const isUSBrand = entryTitle.includes('US Brands');
            const isCABrand = entryTitle.includes('CA Brands');
            const supportedBrands = isUSBrand
              ? US_BRANDS
              : isCABrand
              ? CA_BRANDS
              : ALL_BRANDS;

            if (supportedBrands.includes(regionalBrandName)) {
              const comps = (
                <>
                  {get(node, 'data.target.fields.content.content').map((item) =>
                    documentToReactComponents(item, options),
                  )}
                </>
              );
              return comps;
            }
            return null;
          }
          default:
            return null;
        }
      },
    },
  };

  if (fields.content.length >= 2) {
    // ToDo: Would be better done in Contentful
    const content1 = fields.content[1].content;
    const content2 = content1[0];
    const content3 = [
      { data: {}, marks: [], value: '', nodeType: 'text' },
      ...content1.slice(1),
    ];
    fields.content[1].content = [content2];
    fields.content.push({ data: {}, content: content3, nodeType: 'paragraph' });
  }
  const content = documentToReactComponents(fields, options);

  return (
    <div
      className={classnames(styles.copyright, {
        [styles.costcoWater]: isCostcoWater,
      })}
    >
      {content}
    </div>
  );
};

Copyright.propTypes = {
  fields: PropTypes.object,
  isCostcoWater: PropTypes.bool,
};

Copyright.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  ssrReqRoute: selectSsrReqRoute(),
});

export default connect(mapStateToProps, null)(Copyright);
