module.exports = (theme) => ({
  select: {
    borderRadius: 4,
    '&:focus': {
      backgroundColor: theme.colors.white,
      borderRadius: 4,
    },
  },
  icon: {
    top: 'calc(50% - 8px)',
  },
});
