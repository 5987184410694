import React, { useEffect, useState } from 'react';
import { useBrand, useBranch } from 'components/Brands/hooks';
import { getOrFetchPromos } from './fetch';
import { PROMO_HOCS } from './promos';

const DEFAULT_TYPE = 'Default';

const withPromo = (type = DEFAULT_TYPE) => {
  const promoHOC = PROMO_HOCS[type];

  return (Component) => {
    const Promo = promoHOC ? promoHOC(Component) : Component;

    const WithPromo = (props) => {
      const [promos, setPromos] = useState();
      const [hasLoaded, setHasLoaded] = useState(false);
      const brand = useBrand();
      const branch = useBranch();

      useEffect(() => {
        (async () => {
          if (!brand) return;
          const result = await getOrFetchPromos(type, brand, branch);
          setPromos(result);
          setHasLoaded(true);
        })();
      }, [brand, branch]);

      if (!hasLoaded || !promos || promos.total < 1) return null;

      return <Promo {...props} brand={brand} branch={branch} promos={promos} />;
    };

    return WithPromo;
  };
};

export default withPromo;
