import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { selectServiceLocationId } from 'containers/PrimoAccount/selectors';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from 'components/ContentfulFields/Button';
import CategoryFullWidthBanner from 'components/ContentfulFields/Collapse/CategoryFullWidthBanner';
import { ClickAwayListener } from '@material-ui/core';
import { get, forEach } from 'lodash';
import CollapseButton from './CollapseButton';
import styles from './styles.scss';

const Collapse = ({
  document,
  toggle,
  active,
  className,
  isAuthenticated,
  serviceLocationId,
  section,
  ...props
}) => {
  let content;
  const { id } = document.sys;
  const { entryTitle } = document.fields;
  const isProductCategory =
    entryTitle.indexOf('Products Category Collapse') >= 0;
  const isAcquisition = isAcquisitionDomain();

  const handleClickAway = () => {
    toggle(id);
  };

  const onToggle = () => {
    if (active === id) {
      handleClickAway();
    }
    const wrappedContent = (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={classNames(styles.collapseContentWrapper, {
            [styles.productCat]: isProductCategory,
          })}
          data-tracking={props['data-tracking']}
        >
          <Fragment>
            {content}
            {isAcquisition && isProductCategory && <CategoryFullWidthBanner />}
          </Fragment>
        </div>
      </ClickAwayListener>
    );

    toggle(id, wrappedContent);
  };

  const isServicesCollapse =
    document.fields.entryTitle.indexOf('Services Collapse') >= 0;
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [INLINES.HYPERLINK]: (node, children) =>
        isAcquisition || serviceLocationId ? (
          <CollapseButton
            isActive={active === id}
            onToggle={onToggle}
            className={className}
            ariaHaspopup
            ariaExpanded={active === id}
          >
            {children}
          </CollapseButton>
        ) : null,
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const classname = classNames(className, styles.button, {
          [styles.active]: active === id,
        });

        return (
          <Button
            className={classname}
            onClick={onToggle}
            document={node.data.target}
          >
            {children}
          </Button>
        );
      },
    },
  };

  const contentOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        get(children, '[1].props.document.fields.text') ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            data-tracking={children[1].props.document.fields.text}
            onClick={props.closeCollapse}
            role="button"
            tabIndex="0"
          >
            {children}
          </div>
        ) : (
          children
        ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <Button
          className={styles.collapseContent}
          document={node.data.target}
          useImageSize={isServicesCollapse}
        >
          {children}
        </Button>
      ),
    },
  };

  if (document && isAuthenticated) {
    forEach(document.fields.content.content, (item) => {
      if (item.content.length === 3) {
        const { fields } = item.content[1].data.target;
        // TODO: Hack until we get translation working in SS
        if (fields.text === '{on_the_go} Case Packs') {
          fields.text = 'On the Go Case Packs';
        }
        const [na, products, categoryId] = fields.link.split('/');
        if (
          !fields.link.includes('categoryId') &&
          na === '' &&
          products === 'products'
        ) {
          fields.link = `/products/?categoryId=${categoryId}`;
        }
      }
    });
  }

  content = isAuthenticated
    ? documentToReactComponents(
        document && {
          data: {},
          nodeType: 'document',
          content: document.fields.content.content.slice(0, 5),
        },
        contentOptions,
      )
    : documentToReactComponents(
        document && document.fields.content,
        contentOptions,
      );
  const collapse = documentToReactComponents(
    document && document.fields.label,
    options,
  );

  return document && collapse;
};

export default connect(
  createStructuredSelector({
    serviceLocationId: selectServiceLocationId(),
  }),
  null,
)(Collapse);
