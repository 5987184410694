import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    borderCollapse: 'inherit',
    borderSpacing: 'inherit',
    captionSide: 'inherit',
    color: 'inherit',
    cursor: 'inherit',
    direction: 'inherit',
    emptyCells: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontVariant: 'inherit',
    fontWeight: 'inherit',
    fontSizeAdjust: 'inherit',
    fontStretch: 'inherit',
    font: 'inherit',
    letterSpacing: 'inherit',
    lineHeight: 'inherit',
    listStyleImage: 'inherit',
    listStylePosition: 'inherit',
    listStyleType: 'inherit',
    listStyle: 'inherit',
    orphans: 'inherit',
    quotes: 'inherit',
    tabSize: 'inherit',
    textAlign: 'inherit',
    textAlignLast: 'inherit',
    textDecorationColor: 'inherit',
    textIndent: 'inherit',
    textJustify: 'inherit',
    textShadow: 'inherit',
    textTransform: 'inherit',
    visibility: 'inherit',
    whiteSpace: 'inherit',
    widows: 'inherit',
    wordBreak: 'inherit',
    wordSpacing: 'inherit',
    wordWrap: 'inherit',
  },
}));
export default ({ children, inline, block, inlineBlock, flex, ...props }) => {
  const classes = useStyles();

  const dataProps = Object.entries(props)
    .map(([key, value]) => [`data-${key}`, value])
    .reduce((sum, [key, value]) => {
      sum[key] = value;
      return sum;
    }, {});

  const Wrapper = (wrapperProps) =>
    inline ? (
      <span {...wrapperProps}>{children}</span>
    ) : inlineBlock ? (
      <span
        {...{
          ...wrapperProps,
          style: {
            ...wrapperProps.style,
            display: 'inline-block',
          },
        }}
      >
        {children}
      </span>
    ) : flex ? (
      <div
        {...{
          ...wrapperProps,
          style: {
            ...wrapperProps.style,
            flex: 1,
            display: 'flex',
          },
        }}
      >
        {children}
      </div>
    ) : (
      <div {...wrapperProps}>{children}</div>
    );

  return (
    <Wrapper {...dataProps} className={classes.wrapper}>
      {children}
    </Wrapper>
  );
};
