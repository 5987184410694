import React, { useMemo } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import {
  selectOrderData,
  selectDeliveryData,
  selectBillingData,
} from 'containers/CheckoutPage/selectors';
import { H3, Span, Section } from 'components/Ui';
import { translateData } from 'utils/translation';
import { BILLING_TYPE_ANOTHER } from '../constants';
import styles from './styles.scss';

const Confirmation = () => {
  const order = useSelector(selectOrderData());
  const delivery = useSelector(selectDeliveryData());
  const billingData = useSelector(selectBillingData());
  const billing =
    billingData.billingType === BILLING_TYPE_ANOTHER ? billingData : delivery;
  const equipments = useMemo(
    () =>
      get(order, 'items', []).filter((item) => item.category === 'Equipment'),
    [get(order, 'items', []).length],
  );
  const showRecurringOrder =
    delivery && delivery.validationMessage === 'SUCCESS';

  return (
    order && (
      <Section
        className={styles.container}
        aria-label={translateData('Confirmation order summary')}
      >
        <Section
          className={styles.summary}
          aria-label={translateData('Confirmation order date and time')}
        >
          <div className={styles.block}>
            <H3>First Delivery Date:</H3>
            <span>{moment(order.deliveryDate).format('MMMM Do')}</span>
          </div>
          {showRecurringOrder && (
            <div className={styles.block}>
              <H3>Recurring Order:</H3>
              <Span>Every 2 weeks</Span>
            </div>
          )}
        </Section>
        <Section
          aria-label={translateData('Confirmation order details')}
          className={styles.info}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className={styles.detailBlock}>
                <H3>Equipment Rental:</H3>
                {equipments.map((equipment) => (
                  <div
                    key={equipment.itemNumber}
                    className={styles.equipmentBlock}
                  >
                    <span>{equipment.name}</span>
                    <span>{equipment.count}</span>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={styles.section}>
                <div className={styles.detailBlock}>
                  <H3>Bill to:</H3>
                  <span>
                    {billing.address1}, {billing.city},{' '}
                    {billing.stateOrProvinceCode} {billing.postalCode}
                  </span>
                </div>
                <div className={styles.detailBlock}>
                  <H3>Deliver to:</H3>
                  <span>
                    {delivery.address1}, {delivery.city},{' '}
                    {delivery.stateOrProvinceCode} {delivery.postalCode}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Section>
      </Section>
    )
  );
};

export default Confirmation;
