import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
} from '@material-ui/core';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import { ExpandIcon, CloseExpandIcon } from 'components/Ui/CustomIcons';
import { P } from 'components/Ui';
import { translateData, isCanadianBrand } from 'utils/translation';
import styles from './styles.scss';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b key={`${text}-key`}>{text}</b>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <P className={styles.subtitle}>{children}</P>
    ),
    [INLINES.HYPERLINK]: linkParser,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const id = get(node, 'data.target.sys.contentType.sys.id');

      switch (id) {
        case 'brandedContent': {
          const entryTitle = get(node, 'data.target.fields.entryTitle', '');
          if (
            entryTitle.includes(isCanadianBrand() ? 'CA Brands' : 'US Brands')
          ) {
            return (
              <>
                {get(node, 'data.target.fields.content.content').map((item) =>
                  documentToReactComponents(item, options),
                )}
              </>
            );
          }
          return null;
        }
        default:
          return null;
      }
    },
  },
};

const muiStyles = (theme) => ({
  panelSummary: {
    minHeight: '74px',
  },
  summaryContent: {
    fontSize: '1.125rem',
    fontWeight: 900,
    lineHeight: '1.125rem',
    paddingRight: '22px',
  },
  panelDetail: {
    fontSize: '0.9375rem',
    lineHeight: '1.1875rem',
    flexDirection: 'column',

    '& a': {
      border: '3px solid transparent',
      color: `${theme.colors.primaryDarkBlue}`,

      '&:focus, &:hover': {
        border: `3px solid ${theme.colors.hoverBorder}`,
        color: `${theme.colors.hoverColor}`,
      },
    },
  },
});

class FaqItem extends PureComponent {
  state = {
    expanded: false,
  };

  handleChange = (event, expanded) => this.setState({ expanded });

  render() {
    const { item, classes } = this.props;
    const { expanded } = this.state;
    const content = documentToReactComponents(item.answer, options);
    const num = Math.floor(Math.random() * Math.floor(100000000));

    return (
      <div className={styles.itemContainer}>
        <ExpansionPanel onChange={this.handleChange}>
          <ExpansionPanelSummary
            expandIcon={
              expanded ? (
                <CloseExpandIcon fontSize="large" />
              ) : (
                <ExpandIcon id={num} fontSize="large" />
              )
            }
            aria-controls={`panel1a-content-${num}`}
            id={`panel1a-header-${num}`}
            classes={{
              root: classes.panelSummary,
              content: classes.summaryContent,
            }}
          >
            {translateData(item.question, 'C48')}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.panelDetail }}>
            {content}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FaqItem.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(muiStyles, { withTheme: true })(FaqItem);
