// domainHelper
import {
  domainMap,
  PUREFLO_SLUG,
  WATER_SLUG,
  AUTH_CLIENT_IDS,
  COSTCO_WATER_SLUG,
  ACQUISITION_SPARKLETTS_SLUG,
} from 'containers/Landing/constants';
import { get, forEach, isEmpty, isString, find } from 'lodash';

import { isSsr, getWindowObj } from 'utils/ssrHelper';
import { getServerData } from 'utils/getServerData';

const env = getServerData('ENVIRONMENT');
const isDevelopment = process.env.NODE_ENV === 'development';
const devLocalDomain = getServerData('LOCAL_DOMAIN');
const costcoWaterCheck = (hostname) =>
  hostname && hostname.includes('costcowater');

export const HOSTNAMES_US = [
  'sparkletts',
  'crystal-springs',
  'hinckleysprings',
  'kentwoodsprings',
  'alhambrawater',
  'deeprockwater',
  'mountolympuswater',
  'sierrasprings',
  'crystalrock',
];

export const HOSTNAMES_US_PLUS = [...HOSTNAMES_US, 'water'];

export const HOSTNAMES_CA = ['labradorsource', 'canadiansprings'];

export const HOSTNAMES_CA_PLUS = [...HOSTNAMES_CA, 'aquaterracorp'];

export const HOSTNAMES = [...HOSTNAMES_US, ...HOSTNAMES_CA];

export const HOSTNAMES_PLUS = [...HOSTNAMES_US_PLUS, HOSTNAMES_CA_PLUS];

export const US_BRANDS = [
  'Alhambra',
  'Crystal Rock',
  'Crystal Springs',
  'Deep Rock',
  'Hinckley Springs',
  'Kentwood Springs',
  'Mount Olympus',
  'Sierra Springs',
  'Sparkletts',
];

export const CA_BRANDS = ['Canadian Springs', 'Labrador Source'];

export const ALL_BRANDS = [...US_BRANDS, ...CA_BRANDS];

export const getBrandNameSlugFromHostname = (hostname) => {
  const theHostname = hostname.toLowerCase();
  return find(HOSTNAMES, (hn) => theHostname.includes(hn));
};

const getBrandNameSlug = (hostname) => {
  const theHostname = hostname.toLowerCase();
  if (theHostname.includes('drink')) {
    return null;
  }
  if (theHostname.includes('dss-aws')) {
    return null;
  }
  return getBrandNameSlugFromHostname(hostname);
};

export const purefloCheck = (hostname) =>
  hostname && hostname.includes('pureflo');

export const acquisitionCheck = (hostname) => {
  if (hostname) {
    if (hostname.includes('acq-')) {
      const acquistionRegexp = new RegExp(
        `^(?:www.)?([A-z-]+).com$|^([A-z-]+)-(?:acq-${env}).dss-aws.com$`,
        'gm',
      );
      const acquisitionResult = acquistionRegexp.exec(hostname);
      return acquisitionResult;
    }
    return getBrandNameSlug(hostname);
  }
  return null;
};

const selfServeCheck = (hostname) => {
  const selfServeRegexp = new RegExp(
    `^(?:drink.)?([A-z-]+).com$|^([A-z-]+)-(?:${env}).dss-aws.com$`,
    'gm',
  );
  const selfServeResult = selfServeRegexp.exec(hostname);
  return selfServeResult;
};

// TODO: This method is a temp solution for the FIXME in 'getDomainByHost' and
//       should be deprecated once fixed.
export const getDomainByHostname = (hostname) =>
  domainMap[getRegionalBrandSlug(hostname)];

export const getDomainByHost = () => {
  const windowIsDefined = !isSsr();
  const domainSlug = devLocalDomain || ACQUISITION_SPARKLETTS_SLUG;

  if (!windowIsDefined) {
    if (domainSlug === 'costcowater') {
      return domainMap[COSTCO_WATER_SLUG];
    }

    // FIXME: For SSR scenarios, resolve based on the HTTPRequest.Headers.Host value instead of
    //        defaulting to Sparkletts.
    return isDevelopment
      ? domainMap[domainSlug]
      : domainMap[ACQUISITION_SPARKLETTS_SLUG];
  }

  const windowObj = getWindowObj();
  const hostname = isDevelopment
    ? devLocalDomain
    : get(windowObj, 'location.hostname');

  const isPureflo = purefloCheck(hostname);
  if (isPureflo) {
    return domainMap[PUREFLO_SLUG];
  }

  const isCostcoWaterURL = costcoWaterCheck(hostname);

  if (isCostcoWaterURL) {
    windowObj.isCostcoWater = true;
    const waterBrand = `costcowater-${get(windowObj, 'brandByZip', '').replace(
      /-/g,
      '',
    )}`;
    const result = domainMap[waterBrand]
      ? domainMap[waterBrand]
      : domainMap[COSTCO_WATER_SLUG];
    return result;
  }
  windowObj.isCostcoWater = false;

  if (isDevelopment) {
    const result = domainMap[devLocalDomain]
      ? domainMap[devLocalDomain]
      : domainMap[domainSlug];
    return result;
  }

  const acquisitionResult = acquisitionCheck(hostname);

  if (acquisitionResult) {
    const slug = isString(acquisitionResult)
      ? acquisitionResult
      : acquisitionResult.slice(1).find((group) => group);
    const acquisitionSlug = `acquisition-${slug}`;
    const result = domainMap[acquisitionSlug]
      ? domainMap[acquisitionSlug]
      : domainMap[domainSlug];
    return result;
  }

  const selfServeResult = selfServeCheck(hostname);

  if (!selfServeResult) {
    return domainMap[domainSlug];
  }

  const slug = selfServeResult.slice(1).find((group) => group);
  const result = domainMap[slug] ? domainMap[slug] : domainMap[domainSlug];
  return result;
};

export const isSsrDomain = (hostname) => {
  if (isDevelopment) {
    const domainName =
      domainMap[devLocalDomain] && domainMap[devLocalDomain].slug;
    if (!domainName || !domainName.includes('acquisition')) {
      return false;
    }
    return true;
  }

  if (purefloCheck(hostname) || costcoWaterCheck(hostname)) {
    return false;
  }

  const acquisitionResult = acquisitionCheck(hostname);

  return !isEmpty(acquisitionResult);
};

export const getRegionalHost = ({ brand }) => {
  const windowObj = getWindowObj();
  const hostname = get(windowObj, 'location.hostname');
  if (!isSsr()) {
    if (RegExp(/^[A-z-]+.localhost$/).test(hostname)) {
      return `${brand}.localhost`;
    }

    if (hostname === 'localhost') {
      return 'localhost:3000';
    }

    if (env === 'production') {
      return brand === 'pureflo' ? 'www.pureflo.com' : `drink.${brand}.com`;
    }

    return `${brand}-${env}.dss-aws.com`;
  }

  return undefined;
};

export const getAcquisitionOrigin = (brand) => {
  if (!window) return '';

  const { hostname, protocol } = window.location;

  if (RegExp(/^[A-z-]+.localhost$/).test(hostname)) {
    return `${protocol}//${brand}.localhost`;
  }

  const host = isProduction()
    ? `www.${brand}.com`
    : `${brand}-acq-${env}.dss-aws.com`;

  return `https://${host}`;
};

export const isAcquisitionDomain = () => {
  const domain = getDomainByHost(28);
  return domain.slug && domain.slug.includes('acquisition');
};

// TODO: Add https support at SSR server layer
export const getProtocol = () =>
  getServerData('ENVIRONMENT') === 'dev' ? 'http' : 'https';

export const isPurefloDomain = () => {
  const { slug } = getDomainByHost(29);
  return slug === PUREFLO_SLUG;
};

export const isSelfServeDomain = () => {
  const { slug } = getDomainByHost(30);
  return (
    slug !== PUREFLO_SLUG &&
    !slug.startsWith('costcowater') &&
    !isAcquisitionDomain()
  );
};

export const isAcquisitionOrCostcoDomain = () => {
  const { slug } = getDomainByHost();
  return slug.includes('acquisition') || slug.startsWith('costcowater');
};

export const isWaterDomain = () => {
  const { slug } = getDomainByHost(31);

  return slug === WATER_SLUG;
};

export const getAuthClientId = () => {
  const domain = getDomainByHost(32);

  return AUTH_CLIENT_IDS[get(domain, 'slug', WATER_SLUG)];
};

export const getBrandName = (brandSlug) => {
  switch (brandSlug) {
    case 'crystal-springs':
      return 'Crystal Springs';
    case 'crystalrock':
      return 'Crystal Rock';
    case 'alhambrawater':
      return 'Alhambra';
    case 'labradorsource':
      return 'Labrador Source';
    case 'canadiansprings':
      return 'Canadian Springs';
    case 'deeprockwater':
      return 'Deep Rock';
    case 'hinckleysprings':
      return 'Hinckley Springs';
    case 'kentwoodsprings':
      return 'Kentwood Springs';
    case 'mountolympuswater':
      return 'Mount Olympus';
    case 'sierrasprings':
      return 'Sierra Springs';
    case 'sparkletts':
      return 'Sparkletts';
    default:
      return 'Sparkletts';
  }
};

const getRegionalBrandSlug = (hostname) => {
  if (isDevelopment) return getDomainByHost().brand;
  hostname = hostname || get(getWindowObj(), 'location.hostname', '');
  return getBrandNameSlugFromHostname(hostname);
};

export const getRegionalBrandName = (ssrReqRoute) => {
  const hostname = !isSsr() ? '' : get(ssrReqRoute, 'hostname', '');
  const brandSlug = getRegionalBrandSlug(hostname);

  const result = getBrandName(brandSlug);

  if (!isSsr()) {
    window.PrimoBrandName = window.isCostcoWater
      ? getBrandName(window.brandByZip)
      : result;
  }

  return result;
};

export const getBrandedLogo = (brandedLogos, defaultLogo, ssrReqRoute) => {
  let brandedLogo;
  if (brandedLogos) {
    const regionalBrandName = getRegionalBrandName(ssrReqRoute, 10);
    forEach(brandedLogos, (item) => {
      const fieldsTitle = get(item, 'fields.title');
      if (fieldsTitle.includes(regionalBrandName)) {
        brandedLogo = item;
      }
    });
  } else {
    brandedLogo = defaultLogo;
  }
  return brandedLogo;
};

export const isProduction = () => env === 'production';

// add url to remove top banner which has zip form
const HIDE_NAV_URL_LIST = ['/cart', '/checkout'];

export const shouldHideNav = (url) =>
  HIDE_NAV_URL_LIST.find((item) => url.includes(item));
