module.exports = (theme) => ({
  root: {
    '&$focused': {
      color: theme.colors.dimGrey,
    },
    '&$error': {
      color: theme.colors.red,
    },
    fontFamily: theme.fonts.benton,
    fontSize: '1rem',
    position: 'static',
    transform: 'none',
    lineHeight: '1.4375rem',
    color: theme.colors.dimGrey,
    marginBottom: 7,
    display: 'block',
  },
  focused: {},
  error: {},
});
