import React from 'react';
import PropTypes from 'prop-types';
import { ForgotPassword } from 'components/Forms';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, useMediaQuery } from '@material-ui/core';
import { Dialog } from 'components/Ui';

import MUIStyles from './styles';

const ForgotPasswordDialog = ({ onSubmit, onClose, open, classes }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={classes}
      size="small"
      title={isMobile ? 'Forgot password?' : ''}
      fullScreen={isMobile}
      isCloseIcon
    >
      <ForgotPassword onReset={onClose} onSubmit={onSubmit} />
    </Dialog>
  );
};

ForgotPasswordDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.string,
};

export default withStyles(MUIStyles, { withTheme: true })(ForgotPasswordDialog);
