import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import Popper from 'components/Ui/Popper';
import { selectUserInfo } from 'containers/PrimoProfile/selectors';
import { selectServiceLocationId } from 'containers/PrimoAccount/selectors';
import { Button, F, Img } from 'components/Ui';
import accountImg from 'images/Account.svg';
import arrowImg from 'images/arrow-button.svg';
import { useAuth0 } from 'auth0Provider';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import styles from './styles.scss';
const UserInfoPopper = (props) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const togglePopper = (value) => setIsPopperOpen(value);
  const ref = React.createRef();
  const { logout } = useAuth0();

  window.PrimoLogoutNow = logout;
  if (window.location.href.includes('?logoutnow')) {
    logout();
  }

  const tooltipButton = (
    <ButtonBase
      ref={ref}
      className={styles.tooltip}
      aria-haspopup="true"
      aria-expanded={isPopperOpen}
      aria-label="profile"
    >
      <Img src={accountImg} alt="account" aria-hidden="true" />
      <Img
        className={styles.arrow}
        src={arrowImg}
        alt="menu"
        aria-hidden="true"
      />
    </ButtonBase>
  );

  const {
    userInfo: { firstName, nickname },
  } = props;

  const name = firstName ? `, ${firstName}` : nickname ? `, ${nickname}` : '';

  return (
    <Popper
      arrowClassName={styles.popperArrow}
      button={tooltipButton}
      disablePortal
      className={styles.popper}
      open={isPopperOpen}
      handleChange={togglePopper}
    >
      <div
        className={styles.userInfo}
        data-tracking={GTM_TRACKING_TAGS['User Info Popper']}
      >
        <div className={styles.userName}>
          <F>Hi</F>
          {name}
        </div>
        {props.serviceLocationId && (
          <Link
            to="/account/profile"
            data-tracking={GTM_TRACKING_TAGS['My Profile']}
          >
            <F>My Profile</F>
          </Link>
        )}
        <hr />
        <Button text="Logout" link onClick={logout} />
      </div>
    </Popper>
  );
};

UserInfoPopper.propTypes = {
  userInfo: PropTypes.object.isRequired,
  serviceLocationId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  userInfo: selectUserInfo(),
  serviceLocationId: selectServiceLocationId(),
});

export default connect(mapStateToProps, null)(UserInfoPopper);
