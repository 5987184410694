import { createMemoryHistory, createBrowserHistory } from 'history';
import { isSsr } from 'utils/ssrHelper';

const getHistory = () => {
  if (isSsr()) {
    return createMemoryHistory();
  }

  return createBrowserHistory();
};

export default getHistory();
