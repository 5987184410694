import { get, mapValues, isEmpty } from 'lodash';
import SwaggerClient from 'swagger-client';
import { getAccessToken, removeAccessToken } from 'utils/accessTokenManager';
import { checkAuthSession } from 'utils/authClient';
import moment from 'moment';
import { getServerData } from 'utils/getServerData';
import { isSsr, getWindowObj } from 'utils/ssrHelper';
import { dataLayerPush } from 'utils/tracking';
import { isAcquisitionDomain, isProduction } from 'utils/domainHelper';

export class SwaggerError extends Error {}

// eslint-disable-next-line no-underscore-dangle
let _clientPromise = null;

export const getClient = async ({ token = null } = {}) => {
  const jsonFormatHeaders = ['x-brands', 'x-water-types'];
  const numberHeaders = ['x-total-count', 'x-branch-id'];
  let authorizations = null;
  if (_clientPromise === null || token) {
    if (!isSsr()) {
      const storedAccessToken = getAccessToken();
      const accessToken = token || storedAccessToken;
      authorizations = accessToken
        ? { oAuth: { token: { access_token: accessToken } } }
        : null;
    }
    _clientPromise = new SwaggerClient({
      url: getServerData('API_URL'),
      authorizations,
      responseInterceptor: (response) => {
        response.headers = mapValues(
          response.headers,
          (headerValue, headerKey) => {
            if (jsonFormatHeaders.includes(headerKey)) {
              return JSON.parse(headerValue);
            }

            if (numberHeaders.includes(headerKey)) {
              return Number(headerValue);
            }

            return headerValue;
          },
        );
      },
    });

    await _clientPromise;
  }

  return _clientPromise;
};

export const getApiModels = async () => {
  const client = await getClient();
  return client.spec.definitions;
};

export const authorizeApiClient = async (accessToken) => {
  const client = await getClient();

  client.authorizations = accessToken
    ? { oAuth: { token: { access_token: accessToken } } }
    : null;
};

export const deauthorizeApiClient = async () => {
  const client = await getClient();

  client.authorizations = null;
};

export const apiCall = async ({
  operationId,
  parameters,
  options = {},
  fullResponse = false,
}) => {
  const windowObj = getWindowObj();
  try {
    if (windowObj.enableApiCallDebug) {
      if (windowObj.showApiCall) {
        // eslint-disable-next-line no-console
        console.log('~ apiCall', operationId, parameters, options);
      }
      if (
        windowObj.throwErrorApiCall &&
        windowObj.throwErrorApiCall.includes(operationId)
      ) {
        // If throwErrorApiCall and throwErrorCode are arrays then the matching index
        // from throwErrorApiCall would be used to access the error code in throwErrorCode
        let throwErrorCode;
        if (
          Array.isArray(windowObj.throwErrorApiCall) &&
          Array.isArray(windowObj.throwErrorCode) &&
          windowObj.throwErrorApiCall.length === windowObj.throwErrorCode.length
        ) {
          const i = windowObj.throwErrorApiCall.findIndex(
            (a) => a === operationId,
          );
          throwErrorCode = windowObj.throwErrorCode[i];
        } else {
          throwErrorCode = Array.isArray(windowObj.throwErrorCode)
            ? windowObj.throwErrorCode[0]
            : windowObj.throwErrorCode;
        }
        throw new Error(throwErrorCode);
      }

      if (windowObj.skipApiCall) {
        if (
          windowObj.skipApiCall === true ||
          windowObj.skipApiCall.includes(operationId)
        ) {
          // eslint-disable-next-line no-console
          console.log('~ skipping', operationId, parameters, options);
          return '';
        }
      }

      if (
        !isProduction() &&
        windowObj.sendApiCall &&
        windowObj.sendApiCall.includes(operationId) &&
        (windowObj.sendApiParameters || windowObj.sendApiOptions)
      ) {
        // The below is ONLY to be allowed in development and staging
        if (windowObj.sendApiParameters) {
          parameters = windowObj.sendApiParameters;
        }
        if (windowObj.sendApiOptions) {
          options = windowObj.sendApiOptions;
        }
        // eslint-disable-next-line no-console
        console.log('~ sending', operationId, parameters, options);
      }
    }
    const client = await getClient();
    const response = await get(client, `apis.${operationId}`).call(
      client,
      parameters,
      { attachContentTypeForEmptyPayload: true, ...options },
    );

    return fullResponse ? response : response.body;
  } catch (error) {
    let errorCode;
    let message;

    // eslint-disable-next-line no-console
    console.log(error);
    if (!isEmpty(error.message)) {
      errorCode = error.message;
    } else {
      errorCode = get(error, 'response.body.errorCode');
      message = get(error, 'response.body.message');
    }

    if (!message) {
      // Was 'Something went wrong!';
      message = 'defaultErrorMessage';
    }

    if (operationId === 'orders.createOrder') {
      const { cardholderName, encryptedCreditCardNumber } = options.requestBody;
      options.requestBody = {
        ...options.requestBody,
        cardholderName: cardholderName?.length > 0 ? '###' : '',
        encryptedCreditCardNumber:
          encryptedCreditCardNumber?.length > 0 ? '###' : '',
      };
    }

    // eslint-disable-next-line no-console
    console.log(
      `~ [${moment().format()}] ERROR`,
      operationId,
      errorCode,
      message,
      JSON.stringify(parameters),
      JSON.stringify(options),
    );

    dataLayerPush(
      'Acquisition, SelfServe',
      {
        event: 'apiError',
        operationId,
        errorCode,
        message,
        session_id: window?.DD_RUM?.getInternalContext().session_id,
        parameters,
        options,
      },
      'dlE0',
    );

    // When token expires, try to refresh it and make same API call again
    if (
      error.status === 401 &&
      error.response.body &&
      error.response.body.errorCode === 'tokenExpired'
    ) {
      try {
        const accessToken = await checkAuthSession();
        authorizeApiClient(accessToken);

        return apiCall({ operationId, parameters, options, fullResponse });
      } catch (checkSessionError) {
        removeAccessToken();
        deauthorizeApiClient();
      }
    }

    message = isAcquisitionDomain() ? 'defaultErrorMessage' : message;
    if (windowObj.showApiCall) {
      // eslint-disable-next-line no-console
      console.log(
        '~ >>>>>> operationId/errorCode',
        operationId,
        errorCode,
        errorCode ? `ErrorCode:${errorCode}` : message,
      );
    }
    throw new Error(errorCode ? `ErrorCode:${errorCode}` : message);
  }
};
