import {
  SKIP_EQUIPMENT,
  PROMOTION_LEARN,
  PROMOTION_REDEEM,
  PROMOTION_APPLIED,
  QUICK_SHOP,
  REVIEW_ORDER,
  SELECT_EQUIPMENT,
  QUICK_SHOP_COSTCO,
  COSTCO_PROMOTION_LEARN,
} from './constants';

const quickShopcontent = {
  [SKIP_EQUIPMENT]: {
    title: 'Already have equipment?',
    note:
      'No problem—review your cart and skip to checkout. You can always add more products to your delivery later.',
    buttonLabel: 'Review Cart',
  },
  [PROMOTION_LEARN]: {
    title: 'Get $50 off your first invoice!',
    buttonLabel: 'LEARN MORE',
  },
  [PROMOTION_REDEEM]: {
    title: 'Get $50 off your first invoice!',
    buttonLabel: 'REDEEM',
  },
  [PROMOTION_APPLIED]: {
    title: 'Promotion has been applied!',
    buttonLabel: null,
  },
  [REVIEW_ORDER]: {
    questionLabel: null,
    title: 'Review your order',
    note: 'Make sure you have everything you need.',
    buttonLabel: 'REVIEW CART',
    costco: {
      title: 'Review your order',
      note: 'Make sure you have everything you need.',
      buttonLabel: 'REVIEW CART',
    },
  },
  [SELECT_EQUIPMENT]: {
    questionLabel: 'Have enough for 2 weeks?',
    title: 'Select your equipment',
    note: 'Check out our state-of-the-art coolers.',
    buttonLabel: 'SELECT EQUIPMENT',
    costco: {
      questionLabel: 'Have enough for 2 weeks?',
      title: 'Add your equipment',
      note: 'Learn more about our state-of-the-art cooler.',
      buttonLabel: 'ADD EQUIPMENT',
      terms: `Promotional pricing valid for current Costco members only. Entry into Service Agreement required to receive promotional pricing.
      Must add four 5-gallon bottles to order and a credit for the fourth bottle will be applied on your first invoice. 
      Promotional pricing valid through August 31, 2021. After the promotional end date, regular bottle prices will apply. 
      Credit approval may be required in select areas. Variable enery surcharge, delivery fees, bottle deposits, paper invoice fees and late fees may apply. 
      Customer subject to CA CASH REFUND or applicable state bottle deposits on all cases. Not available in all areas. 
      Dispenser is not included in bottle price and selection subject to availability. Water type is subject to availability. 
      Available for a limited only. Pricing may be subject to change. Service provided by third party vendor.`,
    },
  },
  [QUICK_SHOP]: {
    title: 'Quick Shop',
    note: 'A few quick steps to get you set up for your recurring delivery.',
    stepperNote: 'Order based on a 2-week delivery cycle*',
    callToOrder: 'teleMarketingText',
  },
  [QUICK_SHOP_COSTCO]: {
    title: 'Shop',
    note: 'A few quick steps to get you set up for your recurring delivery.',
    stepperNote: 'Order based on a 2-week delivery cycle',
  },
  [COSTCO_PROMOTION_LEARN]: {
    title: '$9.99 Introductory Price for Primo Water Plus Alkaline Water!',
    buttonLabel: 'LEARN MORE',
  },
};

export default quickShopcontent;
