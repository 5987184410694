import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid } from '@material-ui/core';

import { Form, Input, Button, ErrorMessage } from 'components/Ui';
import { signup } from 'containers/Authentication/actions';
import {
  selectSignUpFetching,
  selectSignUpError,
} from 'containers/Authentication/selectors';
import styles from 'containers/Authentication/styles.scss';

export class Signup extends React.PureComponent {
  state = {
    email: '',
    password: '',
    passwordConfirmation: '',
  };

  componentDidMount() {
    ValidatorForm.addValidationRule(
      'isPasswordMatch',
      (value) => value === this.state.password,
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    const {
      location: { state = {} },
      redirectUrl,
    } = this.props;
    const returnTo = redirectUrl || state.prevPath;

    this.props.signup({ email, password, returnTo });
  };

  render() {
    const {
      state: { email, password, passwordConfirmation },
      props: { isFetching, error, className },
    } = this;

    return (
      <Form
        title="Create an Account"
        className={className}
        onError={this.showError}
        onSubmit={this.handleSubmit}
      >
        {error && (
          <ErrorMessage style={{ marginBottom: '5px' }}>
            {List.isList(error)
              ? error.map((errorEntry) => (
                  <div key={errorEntry}>{errorEntry}</div>
                ))
              : error}
          </ErrorMessage>
        )}
        <Input
          label="Username or Email Address"
          validators={['required']}
          errorMessages={['']}
          value={email}
          onChange={this.handleChange}
          name="email"
          autoComplete="off"
        />
        <Input
          label="Create Password"
          validators={['required']}
          errorMessages={['']}
          value={password}
          type="password"
          onChange={this.handleChange}
          name="password"
          autoComplete="off"
        />
        <Input
          label="Re-enter Password"
          validators={['required', 'isPasswordMatch']}
          errorMessages={['', "Password doesn't match"]}
          value={passwordConfirmation}
          type="password"
          onChange={this.handleChange}
          name="passwordConfirmation"
          autoComplete="off"
        />
        <Grid container justify="flex-end">
          <Button
            className={styles.button}
            text="CREATE ACCOUNT"
            loading={isFetching}
            style={{ minWidth: 232 }}
          />
        </Grid>
      </Form>
    );
  }
}

Signup.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(List)]),
  signup: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  className: PropTypes.string,
  redirectUrl: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      prevPath: PropTypes.string,
    }),
  }),
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectSignUpFetching(),
  error: selectSignUpError(),
});

const mapDispatchToProps = {
  signup,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Signup);
