import React from 'react';
import pick from 'lodash/pick';
import { translateData } from 'utils/translation';

const withTranslation = (...propsToTranslate) => (Component) => {
  const WithTranslation = (props) => {
    const pickedProps = pick(props, propsToTranslate);
    const translatedProps = Object.entries(pickedProps).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: translateData(v),
      }),
      {},
    );

    const propsResult = {
      ...props,
      ...translatedProps,
    };

    return <Component {...propsResult} />;
  };

  // Wrap display name (helps debug)
  WithTranslation.displayName = `WithTranslation(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithTranslation;
};

export default withTranslation;
