import Loadable from 'react-loadable';
import LoadingIndicator from 'components/LoadingIndicator';

export default Loadable({
  loader: () => import('./index' /* webpackChunkName: "QuickShop" */),
  loading: LoadingIndicator,
});

export const PromotionValidation = Loadable({
  loader: () =>
    import(
      '../QuickShop/PromotionValidation.js' /* webpackChunkName: "QuickShop" */
    ),
  loading: LoadingIndicator,
});
