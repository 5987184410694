import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { isPastDeliveryCutOffDateTime } from 'utils/common';
import { Popper, Button, Div, Span, F, LanguageToggle } from 'components/Ui';
import { BelowDesktop, Desktop } from 'components/Media';
import Loader from 'components/Loader';
import primoAccountSaga from 'containers/PrimoAccount/saga';
import primoAccountReducer from 'containers/PrimoAccount/reducer';
import { PRIMO_ACCCOUNT_PAGE_KEY } from 'containers/PrimoAccount/constants';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import {
  getServiceLocationNextDelivery as getServiceLocationNextDeliveryAction,
  showSkipDialog as showSkipDialogRequest,
} from 'containers/PrimoAccount/actions';
import {
  selectSelectedNextDeliveryDate,
  selectNextDelivery,
  selectDeliveryIsFetching,
  selectIsFirstDelivery,
  selectIsSelectedServiceLocationExcluded,
  selectSelectedServiceLocation,
} from 'containers/PrimoAccount/selectors';
import {
  selectIsHardLockout,
  selectIsEquipmentPickupLockout,
} from 'containers/PrimoProfile/selectors';
import { TooltipIcon } from 'components/Ui/CustomIcons';
import OrderEditor from 'containers/PrimoAccount/OrderEditor';
import { getImage } from 'images';
import HtmlData from 'components/Ui/HtmlData/index';
import styles from './styles.scss';

const NextDeliveryPopper = (props) => {
  const {
    showSkipDialog,
    className,
    isFetching,
    nextDelivery,
    nextDeliveryDate,
    isHardLockout,
    isFirstDelivery,
    isEquipmentPickupLockout,
    isSelectedServiceLocationExcluded,
    getServiceLocationNextDelivery,
    showPopper,
    serviceLocation,
  } = props;

  const [isPopperOpen, setIsPopperOpen] = useState(showPopper);
  const ref = useRef();

  const handleShowSkip = () => showSkipDialog();

  const togglePopper = (value) => setIsPopperOpen(value);

  const showEditOrder = () => {
    setIsPopperOpen(false);
    getServiceLocationNextDelivery();
  };

  const showSkipOrder = () => {
    setIsPopperOpen(false);
    handleShowSkip();
  };

  useEffect(() => {
    if (nextDeliveryDate && isEmpty(nextDelivery)) {
      getServiceLocationNextDelivery();
    }
  }, [nextDeliveryDate]);

  const tooltipButton = (
    // TODO: Try to use React.forwardRef one more time
    <span
      id={showPopper ? '' : 'NextDeliveryPopperButton'}
      className={classnames({ [styles.hiddenTooltip]: showPopper })}
      ref={ref}
    >
      <HtmlData tracking={GTM_TRACKING_TAGS['Show Next Delivery Popper']}>
        <Button
          link
          aria-haspopup="true"
          aria-expanded={isPopperOpen}
          text={
            nextDeliveryDate ? moment(nextDeliveryDate).format('MMM DD') : ''
          }
          className={styles.tooltip}
          icon={<TooltipIcon className={styles.tooltipIcon} />}
          iconPosition="right"
        />
      </HtmlData>
    </span>
  );

  const nextDeliveryForm = () => {
    const itemsAmount =
      nextDelivery.items &&
      nextDelivery.items.reduce((acc, item) => acc + item.quantity, 0);

    const isPastCutOff = isPastDeliveryCutOffDateTime(serviceLocation);

    const enableEdit = !(
      isFirstDelivery ||
      isSelectedServiceLocationExcluded ||
      isEquipmentPickupLockout ||
      isHardLockout ||
      isPastCutOff
    );

    return (
      <Loader loading={isFetching} local>
        {nextDelivery.items && (
          <HtmlData tracking={GTM_TRACKING_TAGS['Next Delivery Dialog']}>
            <div className={styles.nextDeliveryPopper}>
              <Desktop>
                <Div className={styles.title}>Refreshment will arrive:</Div>
              </Desktop>
              <div className={styles.date}>
                {moment(nextDelivery.date).format('dddd MMMM DD')}
              </div>
              <div className={styles.subtitle}>
                <F>Items in your order</F>
                <BelowDesktop>
                  <span>: {itemsAmount}</span>
                </BelowDesktop>
              </div>
              <Grid className={styles.productList} container spacing={1}>
                {nextDelivery.items.map((item) => {
                  const gridSize =
                    nextDelivery.items.length < 3
                      ? 12 / nextDelivery.items.length
                      : 4;

                  return (
                    <Grid
                      key={item.itemNumber}
                      item
                      xs={gridSize}
                      className={styles.product}
                    >
                      <img
                        src={`${getImage(
                          item,
                          'images[0].fields.file.url',
                        )}?fm=webp`}
                        alt={
                          item.name || item.longDescription || item.description
                        }
                      />
                      <div>x{item.quantity}</div>
                    </Grid>
                  );
                })}
              </Grid>
              {enableEdit && (
                <div className={styles.actions}>
                  <Button
                    text="Edit next delivery"
                    onClick={showEditOrder}
                    href="/account/next-delivery"
                  />
                  <Button text="Skip next delivery" onClick={showSkipOrder} />
                </div>
              )}
            </div>
          </HtmlData>
        )}
      </Loader>
    );
  };

  return (
    <div className={classnames(styles.nextDelivery, className)}>
      {!showPopper && <Span style={{ marginRight: 5 }}>Next delivery:</Span>}
      {nextDelivery.date && (
        <Popper
          role="dialog"
          title="Summary of your next delivery"
          className={styles.popperModal}
          arrowClassName={styles.arrow}
          button={tooltipButton}
          anchorElButton={
            showPopper
              ? document.getElementById('NextDeliveryPopperButton')
              : null
          }
          open={isPopperOpen}
          disablePortal
          handleChange={togglePopper}
        >
          {nextDelivery && nextDeliveryForm()}
        </Popper>
      )}
      <LanguageToggle header />

      <OrderEditor />
    </div>
  );
};

NextDeliveryPopper.propTypes = {
  nextDeliveryDate: PropTypes.string,
  nextDelivery: PropTypes.object,
  showSkipDialog: PropTypes.func,
  className: PropTypes.string,
  getServiceLocationNextDelivery: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isHardLockout: PropTypes.bool.isRequired,
  isFirstDelivery: PropTypes.bool.isRequired,
  isEquipmentPickupLockout: PropTypes.bool.isRequired,
  isSelectedServiceLocationExcluded: PropTypes.bool.isRequired,
  showPopper: PropTypes.bool,
  serviceLocation: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  nextDeliveryDate: selectSelectedNextDeliveryDate(),
  nextDelivery: selectNextDelivery(),
  isFetching: selectDeliveryIsFetching(),
  isHardLockout: selectIsHardLockout(),
  isFirstDelivery: selectIsFirstDelivery(),
  isEquipmentPickupLockout: selectIsEquipmentPickupLockout(),
  isSelectedServiceLocationExcluded: selectIsSelectedServiceLocationExcluded(),
  serviceLocation: selectSelectedServiceLocation(),
});

const mapDispatchToProps = {
  getServiceLocationNextDelivery: getServiceLocationNextDeliveryAction,
  showSkipDialog: showSkipDialogRequest,
};

const withSaga = injectSaga({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  saga: primoAccountSaga,
});
const withReducer = injectReducer({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  reducer: primoAccountReducer,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withSaga,
  withReducer,
)(React.memo(NextDeliveryPopper));
