export const ACCOUNT_PAGE_KEY = 'account';

export const PRIMO_ACCCOUNT_PAGE_KEY = 'sparkletts-account';

export const LOAD_SERVICE_LOCATIONS = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_SERVICE_LOCATIONS`;
export const LOAD_SERVICE_LOCATIONS_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_SERVICE_LOCATIONS_SUCCESS`;
export const LOAD_SERVICE_LOCATIONS_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_SERVICE_LOCATIONS_FAILURE`;
export const LOAD_MOBILE_APP_BILL_PAY_DATA = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_MOBILE_APP_BILL_PAY_DATA`;
export const LOAD_MOBILE_APP_BILL_PAY_DATA_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_MOBILE_APP_BILL_PAY_DATA_SUCCESS`;
export const LOAD_MOBILE_APP_BILL_PAY_DATA_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_MOBILE_APP_BILL_PAY_DATA_FAILURE`;

export const SELECT_SERVICE_LOCATION = `${PRIMO_ACCCOUNT_PAGE_KEY}/SELECT_SERVICE_LOCATION`;
export const SELECT_SERVICE_LOCATION_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/SELECT_SERVICE_LOCATION_SUCCESS`;
export const SELECT_SERVICE_LOCATION_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/SELECT_SERVICE_LOCATION_FAILURE`;

export const LOAD_CALENDAR_DATES = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_CALENDAR_DATES`;
export const LOAD_CALENDAR_DATES_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_CALENDAR_DATES_SUCCESS`;
export const LOAD_CALENDAR_DATES_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_CALENDAR_DATES_FAILURE`;

export const LOAD_DELIVERY_AFTER_SKIP = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_DELIVERY_AFTER_SKIP`;
export const LOAD_DELIVERY_AFTER_SKIP_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_DELIVERY_AFTER_SKIP_SUCCESS`;
export const LOAD_DELIVERY_AFTER_SKIP_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_DELIVERY_AFTER_SKIP_FAILURE`;

export const LOAD_RECURRING_ORDER_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_RECURRING_ORDER_REQUEST`;
export const LOAD_RECURRING_ORDER_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_RECURRING_ORDER_SUCCESS`;
export const LOAD_RECURRING_ORDER_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_RECURRING_ORDER_FAILURE`;
export const LOAD_RECURRING_ORDER_CANCEL = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_RECURRING_ORDER_CANCEL`;

export const LOAD_DELIVERY_ORDER_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_DELIVERY_ORDER_REQUEST`;
export const LOAD_DELIVERY_ORDER_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_DELIVERY_ORDER_SUCCESS`;
export const LOAD_DELIVERY_ORDER_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_DELIVERY_ORDER_FAILURE`;
export const LOAD_DELIVERY_ORDER_CANCEL = `${PRIMO_ACCCOUNT_PAGE_KEY}/LOAD_DELIVERY_ORDER_CANCEL`;
export const UPDATE_DELIVERY_ORDER_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_DELIVERY_ORDER_REQUEST`;
export const UPDATE_DELIVERY_ORDER_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_DELIVERY_ORDER_SUCCESS`;
export const UPDATE_DELIVERY_ORDER_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_DELIVERY_ORDER_FAILURE`;

export const UPDATE_RECURRING_ORDER = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_RECURRING_ORDER`;
export const UPDATE_RECURRING_ORDER_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_RECURRING_ORDER_SUCCESS`;
export const UPDATE_RECURRING_ORDER_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_RECURRING_ORDER_FAILURE`;

export const UPDATE_ADD_TO_RECURRING_ORDER_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_ADD_TO_RECURRING_ORDER_SUCCESS`;

export const FETCH_BILLING_STATEMENTS_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_BILLING_STATEMENTS_REQUEST`;
export const FETCH_BILLING_STATEMENTS_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_BILLING_STATEMENTS_SUCCESS`;
export const FETCH_BILLING_STATEMENTS_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_BILLING_STATEMENTS_FAILURE`;

export const GET_ORDER_ITEMS_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_ORDER_ITEMS_REQUEST`;
export const GET_ORDER_ITEMS_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_ORDER_ITEMS_SUCCESS`;
export const GET_ORDER_ITEMS_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_ORDER_ITEMS_FAILURE`;

export const GET_SERVICE_LOCATION_RECURRING_DELIVERY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_RECURRING_DELIVERY_REQUEST`;
export const GET_SERVICE_LOCATION_RECURRING_DELIVERY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_RECURRING_DELIVERY_SUCCESS`;
export const GET_SERVICE_LOCATION_RECURRING_DELIVERY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_RECURRING_DELIVERY_FAILURE`;

export const GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_REQUEST`;
export const GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_SUCCESS`;
export const GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_FAILURE`;

export const GET_SERVICE_LOCATION_EQUIPMENT_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_EQUIPMENT`;
export const GET_SERVICE_LOCATION_EQUIPMENT_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_EQUIPMENT_SUCCESS`;
export const GET_SERVICE_LOCATION_EQUIPMENT_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_EQUIPMENT_FAILURE`;

export const GET_CUSTOMER_SUMMARY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_CUSTOMER_SUMMARY_REQUEST`;
export const GET_CUSTOMER_SUMMARY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_CUSTOMER_SUMMARY_SUCCESS`;
export const GET_CUSTOMER_SUMMARY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_CUSTOMER_SUMMARY_FAILURE`;

export const GET_SERVICE_SUMMARY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_SUMMARY_REQUEST`;
export const GET_SERVICE_SUMMARY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_SUMMARY_SUCCESS`;
export const GET_SERVICE_SUMMARY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_SUMMARY_FAILURE`;

export const MAKE_PAYMENT_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/MAKE_PAYMENT_REQUEST`;
export const MAKE_PAYMENT_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/MAKE_PAYMENT_SUCCESS`;
export const MAKE_PAYMENT_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/MAKE_PAYMENT_FAILURE`;

export const GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST`;
export const GET_SERVICE_LOCATION_NEXT_DELIVERY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_NEXT_DELIVERY_SUCCESS`;
export const GET_SERVICE_LOCATION_NEXT_DELIVERY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_NEXT_DELIVERY_FAILURE`;

export const SKIP_DELIVERY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/SKIP_DELIVERY_REQUEST`;
export const SKIP_DELIVERY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/SKIP_DELIVERY_SUCCESS`;
export const SKIP_DELIVERY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/SKIP_DELIVERY_FAILURE`;

export const SKIP_DELIVERY_ORDER_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/SKIP_DELIVERY_ORDER_REQUEST`;
export const SKIP_DELIVERY_ORDER_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/SKIP_DELIVERY_ORDER_SUCCESS`;
export const SKIP_DELIVERY_ORDER_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/SKIP_DELIVERY_ORDER_FAILURE`;

export const EXTRA_DELIVERY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/EXTRA_DELIVERY_REQUEST`;
export const EXTRA_DELIVERY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/EXTRA_DELIVERY_SUCCESS`;
export const EXTRA_DELIVERY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/EXTRA_DELIVERY_FAILURE`;
export const EXTRA_DELIVERY_CANCEL = `${PRIMO_ACCCOUNT_PAGE_KEY}/EXTRA_DELIVERY_CANCEL`;

export const CREATE_EXTRA_DELIVERY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/CREATE_EXTRA_DELIVERY_REQUEST`;
export const CREATE_EXTRA_DELIVERY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/CREATE_EXTRA_DELIVERY_SUCCESS`;
export const CREATE_EXTRA_DELIVERY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/CREATE_EXTRA_DELIVERY_FAILURE`;

export const GET_BEST_DELIVERY_DATES_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_BEST_DELIVERY_DATES_REQUEST`;
export const GET_BEST_DELIVERY_DATES_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_BEST_DELIVERY_DATES_SUCCESS`;
export const GET_BEST_DELIVERY_DATES_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_BEST_DELIVERY_DATES_FAILURE`;

export const UPDATE_NEXT_DELIVERY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_DELIVERY_REQUEST`;
export const UPDATE_NEXT_DELIVERY_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_NEXT_DELIVERY_SUCCESS`;
export const UPDATE_NEXT_DELIVERY_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_NEXT_DELIVERY_FAILURE`;

export const CREATE_NOTE_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/CREATE_NOTE_REQUEST`;
export const CREATE_NOTE_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/CREATE_NOTE_SUCCESS`;
export const CREATE_NOTE_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/CREATE_NOTE_FAILURE`;

export const SHOW_SKIP_DIALOG = `${PRIMO_ACCCOUNT_PAGE_KEY}/SHOW_SKIP_DIALOG`;
export const HIDE_SKIP_DIALOG = `${PRIMO_ACCCOUNT_PAGE_KEY}/HIDE_SKIP_DIALOG`;

export const UPDATE_DEFAULT_SERVICE_LOCATION = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_DEFAULT_SERVICE_LOCATION`;
export const UPDATE_DEFAULT_SERVICE_LOCATION_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_DEFAULT_SERVICE_LOCATION_SUCCESS`;
export const UPDATE_DEFAULT_SERVICE_LOCATION_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/UPDATE_DEFAULT_SERVICE_LOCATION_FAILURE`;

export const DELIVERY_UPDATED_SUCCESS_DRAWER = `${PRIMO_ACCCOUNT_PAGE_KEY}/DELIVERY_UPDATED_SUCCESS_DRAWER`;

export const RESET_MAKE_PAYMENT = `${PRIMO_ACCCOUNT_PAGE_KEY}/RESET_MAKE_PAYMENT`;
export const RESET_SERVICE_LOCATION_ERROR = `${PRIMO_ACCCOUNT_PAGE_KEY}/RESET_SERVICE_LOCATION_ERROR`;

export const SHOW_ORDER_CHANGE_MESSAGE = `${PRIMO_ACCCOUNT_PAGE_KEY}/SHOW_ORDER_CHANGE_MESSAGE`;
export const HIDE_ORDER_CHANGE_MESSAGE = `${PRIMO_ACCCOUNT_PAGE_KEY}/HIDE_ORDER_CHANGE_MESSAGE`;

export const PRODUCTS_KEY = `products`;

export const ADD_TO_ORDER = `${PRODUCTS_KEY}/ADD_TO_ORDER`;
export const ADD_TO_ORDER_SUCCESS = `${PRODUCTS_KEY}/ADD_TO_ORDER_SUCCESS`;
export const ADD_TO_ORDER_FAILURE = `${PRODUCTS_KEY}/ADD_TO_ORDER_FAILURE`;

export const ADD_TO_DELIVERY = `${PRODUCTS_KEY}/ADD_TO_DELIVERY`;
export const ADD_TO_DELIVERY_SUCCESS = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_SUCCESS`;
export const ADD_TO_DELIVERY_FAILURE = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_FAILURE`;

export const SHOP_DELIVERY_ORDER = `${PRODUCTS_KEY}/SHOP_DELIVERY_ORDER`;

export const ADD_TO_DELIVERY_ORDER = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_ORDER`;
export const ADD_TO_DELIVERY_ORDER_SUCCESS = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_ORDER_SUCCESS`;
export const ADD_TO_DELIVERY_ORDER_FAILURE = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_ORDER_FAILURE`;

export const REMOVE_HOLD_REQUEST = `${PRODUCTS_KEY}/REMOVE_HOLD_REQUEST`;
export const REMOVE_HOLD_SUCCESS = `${PRODUCTS_KEY}/REMOVE_HOLD_SUCCESS`;
export const REMOVE_HOLD_FAILURE = `${PRODUCTS_KEY}/REMOVE_HOLD_FAILURE`;

export const SEND_CONTACT_US_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/SEND_CONTACT_US_REQUEST`;
export const SEND_CONTACT_US_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/SEND_CONTACT_US_SUCCESS`;
export const SEND_CONTACT_US_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/SEND_CONTACT_US_FAILURE`;

export const FETCH_CONTACT_US_SUBJECTS = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_CONTACT_US_SUBJECTS`;
export const FETCH_CONTACT_US_SUBJECTS_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_CONTACT_US_SUBJECTS_SUCCESS`;
export const FETCH_CONTACT_US_SUBJECTS_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_CONTACT_US_SUBJECTS_FAILURE`;

export const FETCH_RECENT_ACTIVITIES = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_RECENT_ACTIVITIES`;
export const FETCH_RECENT_ACTIVITIES_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_RECENT_ACTIVITIES_FAILURE`;
export const FETCH_RECENT_ACTIVITIES_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/FETCH_RECENT_ACTIVITIES_SUCCESS`;

export const SCHEDULE_CALLBACK = `${PRIMO_ACCCOUNT_PAGE_KEY}/SCHEDULE_CALLBACK`;
export const SCHEDULE_CALLBACK_SUCCESS = `${PRIMO_ACCCOUNT_PAGE_KEY}/SCHEDULE_CALLBACK_SUCCESS`;
export const SCHEDULE_CALLBACK_FAILURE = `${PRIMO_ACCCOUNT_PAGE_KEY}/SCHEDULE_CALLBACK_FAILURE`;
