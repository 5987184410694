export const findPromoContentByType = (promos, type) => {
  promos = promos.items;

  // find all content of type (active for this user) in given promos
  const content = promos.reduce((acc, promo, idx) => {
    const found = promo.fields.content.filter(
      (entry) => entry.sys.contentType?.sys.id === type,
    );

    found.forEach((entry) => {
      acc.push({
        ...entry,
        promoHandle: promo.fields.handle,
        promoPriority: promo.fields.priority,
        promotionId: promo.fields.promotionId,
      });
    });

    // if final iteration, sort content by promoPriority (lower num is higher prio)
    if (idx === promos.length - 1) {
      acc.sort((a, b) => a.promoPriority - b.promoPriority);
    }

    return acc;
  }, []);

  return content;
};
