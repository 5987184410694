import React from 'react';
import { node, object } from 'prop-types';
import template from 'lodash/template';
import { getRegionalBrandName } from 'utils/domainHelper';

const Tmpl = ({ children, vars = {} }) => {
  const varsDefault = { brand: getRegionalBrandName() };
  vars = { ...varsDefault, ...vars };

  const compiled = template(children)(vars);

  return <>{compiled}</>;
};

Tmpl.propTypes = {
  children: node.isRequired,
  vars: object,
};

export default Tmpl;
