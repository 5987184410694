const jsStyles = (theme) => ({
  popper: {
    margin: '-1px -10px 0 0',
    pointerEvents: 'unset',
    left: '-35px !important',
  },
  tooltip: {
    margin: '0 0 0 0',
    maxWidth: 'none',
    boxShadow: '-1px 1px 6px 0 rgba(0, 0, 0, 0.68)',
    backgroundColor: theme.colors.white,
  },
  popperContent: {
    color: 'black',
  },
  arrow: {
    '&:after': {
      content: '""',
      zIndex: 0,
      position: 'absolute',
      width: 0,
      height: 0,
      top: '0',
      left: '60%',
      boxSizing: 'border-box',
      border: `5px solid ${theme.colors.black}`,
      borderColor: `transparent transparent ${theme.colors.white} ${theme.colors.white}`,
      transformOrigin: '0 0',
      transform: 'rotate(135deg)',
      boxShadow: '-1px 1px 1px 0 rgba(0, 0, 0, 0.4)',
    },
  },
});

export default jsStyles;
