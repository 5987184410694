import React from 'react';
import { object } from 'prop-types';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { isButton } from 'components/Content';
import Button from 'components/ContentfulFields/Button';
import Tmpl from 'components/Tmpl';

const RichText = ({ richText, styles }) => {
  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        if (!isButton(node.data.target)) return null;

        return (
          <Button className={styles.btn} document={node.data.target}>
            {children}
          </Button>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, description } = node.data.target.fields;

        return <img src={`${file.url}?fm=webp`} alt={description} />;
      },
    },
    renderText: (text) => <Tmpl>{text}</Tmpl>,
  };

  return documentToReactComponents(richText, options);
};

RichText.propTypes = {
  richText: object.isRequired,
  styles: object,
};

export default RichText;
