import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

const PinIcon = ({ color }, ...props) => (
  <SvgIcon {...props} viewBox="0 0 35 35">
    <g
      id="Water.com"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="1.0_Water.com_Home_DT_1440"
        transform="translate(-266.000000, -548.000000)"
        fill={color}
      >
        <g id="ZipBar" transform="translate(250.000000, 526.000000)">
          <path
            d="M28,25.75 C32.1625,25.75 35.5,29.125 35.5,33.25 C35.5,37.4125 32.1625,40.75 28,40.75 C23.875,40.75 20.5,37.4125 20.5,33.25 C20.5,29.125 23.875,25.75 28,25.75 M28,22 C21.775,22 16.75,27.025 16.75,33.25 C16.75,40.75 28,52 28,52 C28,52 39.25,40.75 39.25,33.25 C39.25,27.025 34.225,22 28,22"
            id="icon_pin"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

PinIcon.propTypes = {
  color: PropTypes.string,
};

PinIcon.defaultProps = {
  color: '#fff',
};

export default PinIcon;
