import React from 'react';
import { Button } from '@material-ui/core';
import { Span } from 'components/Ui';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '0.9375rem',
    padding: 0,

    backgroundColor: theme.colors.white,
    transition: 'none',
    [theme.breakpoints.down('md')]: {
      padding: '10px 40px 0 0',
    },

    '&:hover': {
      boxShadow: `inset 0 0 0 3px ${theme.colors.hoverBorder}`,
      backgroundColor: theme.colors.white,
    },

    '& img, & svg': {
      height: '30px',
      margin: 0,
      width: '90px',
      objectFit: 'contain',
      flexShrink: 0,
    },

    '& svg': {
      width: '90px !important',
    },

    '&$disabled': {
      opacity: 0.4,
    },
  },
  label: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '20px 25px 20px 4px',

    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'start',

    '& span:not(:last-child)': {
      marginBottom: '5px',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: '18px',
  },
  text: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '19px',
    color: theme.colors.middleGrey,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
      lineHeight: '0.9375rem',
    },
  },
  note: {
    marginTop: 18,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '15px',
    color: theme.colors.middleGrey,
  },
  disabled: {},
}));

const BlockButton = ({
  icon,
  title,
  text,
  note,
  className,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      className={className}
      classes={{
        root: classes.root,
        disabled: classes.disabled,
        label: classes.label,
      }}
      variant="contained"
      disabled={disabled}
      {...rest}
      disableRipple
    >
      {icon}
      <div className={classes.content}>
        <Span className={classes.title}>{title}</Span>
        <Span className={classes.text}>{text}</Span>
        {note && <Span className={classes.note}>{note}</Span>}
      </div>
    </Button>
  );
};

export default BlockButton;
