import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Container } from 'components/Ui';
import Loader from 'components/Loader';
import { selectIsLoading } from 'containers/Landing/selectors';
import styles from 'containers/Authentication/styles.scss';
import SignInForm from './SignInForm';

const SignIn = ({ loading }) => (
  <Loader loading={loading}>
    <Container value="Sign In" className={styles.container}>
      <SignInForm />
    </Container>
  </Loader>
);

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading(),
});

SignIn.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(SignIn);
