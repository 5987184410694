import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AOS from 'aos';
import MapAnimated from './MapAnimated';
import styles from './styles.scss';

class Animated extends Component {
  constructor(props) {
    super(props);

    this.animatedRef = createRef();
  }

  componentDidMount() {
    AOS.init({
      duration: 1500,
      once: true,
      initClassName: 'aos-init',
      animatedClassName: 'aos-animate',
    });
  }

  render() {
    const { children, animation, classNames } = this.props;

    const isMapAnimation = animation === 'map-animation';

    return animation ? (
      <div
        className={classnames(classNames, {
          [styles.animatedMap]: isMapAnimation,
        })}
        data-aos={animation}
        ref={this.animatedRef}
      >
        {isMapAnimation && <MapAnimated>{children}</MapAnimated>}
        {!isMapAnimation && children}
      </div>
    ) : (
      <div className={classNames}>{children}</div>
    );
  }
}

Animated.propTypes = {
  children: PropTypes.any.isRequired,
  animation: PropTypes.string,
  classNames: PropTypes.string,
};

export default Animated;
