import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { useSelector, connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { useDeepCompareEffect } from 'utils/hooks';
import { withOrderUtils } from 'utils/withOrderUtils';
import { getRegionalBrandName, isSelfServeDomain } from 'utils/domainHelper';
import ContentfulFields from 'components/ContentfulFields';
import Loader from 'components/Loader';
import { selectShopDeliveryOrder } from 'containers/PrimoAccount/selectors';
import { selectBrand } from 'containers/UserRegion/selectors';
import ServiceLocations from 'containers/PrimoAccount/ServiceLocations';
import {
  parseSearchFilter,
  getLoadProductsParams,
  isWaterBundlesKind,
  isEquipmentKind,
} from 'containers/PrimoProducts/helpers';
import { get, forEach, flatten, isEmpty } from 'lodash';
import OrderChangeDrawer from 'components/Dialogs/OrderChangeDrawer/Loadable';
import EditingDeliveryOrderDrawer from 'components/Dialogs/EditingDeliveryOrderDrawer/Loadable';
import { loadDeliveryOrder as loadDeliveryOrderRequest } from 'containers/PrimoAccount/actions';
import { isSsr } from 'utils/ssrHelper';
import { H1, H2, Text, Button } from 'components/Ui';
import { Mobile } from 'components/Media';
import { hasTag } from 'components/Content/utils';
import { dataLayerPush } from 'utils/tracking';
import ProductList from './ProductList';
import styles from './styles.scss';
import Filter from './Filter/index';
import SearchBar from './SearchBar';

export const ProductListContext = React.createContext({
  current: null,
});

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% + 10px)',
      margin: '-5px',
    },
  },
  item: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px !important',
    },
  },
}));

const PrimoProducts = ({
  products,
  totalCount,
  brands,
  waterTypes,
  productCategories,
  site: {
    components: { page },
    loading,
  },
  loadMerchandizedProducts,
  loadProductBundles,
  loadProductCategories,
  isAuthenticated,
  areProductsFetching,
  history,
  location,
  showOrderChangeMessage,
  hideOrderChangeMessage,
  loadDeliveryOrder,
  userRegionBrand,
  content,
}) => {
  const classes = useStyles();
  const shopDeliveryOrder = useSelector(selectShopDeliveryOrder());
  const selectedProductRef = useRef(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isBundle, setIsBundle] = useState(false);
  const [categoryHeaderContent, setCategoryHeaderContent] = useState();
  const dataLayerPushName = !isAuthenticated ? 'Acquisition' : 'SelfServe';
  const searchParams = parseSearchFilter(location, isAuthenticated);
  const [brandName] = useState(getRegionalBrandName());
  const filterBundles = (query) =>
    products.length > 0
      ? products.filter((p) => p.fields?.title?.toLowerCase().includes(query))
      : products;

  const filterProducts = (query) =>
    products.filter(
      (item) =>
        item.name?.toLowerCase().includes(query) ||
        item.size?.toLowerCase().includes(query),
    );

  const productsToRender = useMemo(() => {
    const lowerCasedKeyword = searchKeyword.toLowerCase();
    const result = isBundle
      ? filterBundles(lowerCasedKeyword)
      : filterProducts(lowerCasedKeyword);

    if (searchKeyword) {
      dataLayerPush(dataLayerPushName, {
        event: 'product-list-filter',
        category: searchParams.categoryId || 'All products',
        brand: searchParams.brands || 'All',
        search_term: searchKeyword,
        numSearchResults: result.length,
        'water-type': searchParams.waterTypes,
      });
      if (isBundle) {
        try {
          dataLayerPush(dataLayerPushName, {
            currencyCode: 'USD',
            event: 'view_item_list',
            ecommerce: {
              impressions: result.flatMap((item, index) =>
                flatten(
                  item.fields.items.map((bi) => ({
                    name: item.fields.title,
                    id: bi.fields.product.fields.itemNumber,
                    price: `${
                      bi.fields.product.fields.price * bi.fields.quantity
                    }`,
                    brand: bi.fields.product.fields.brand || '',
                    category: bi.fields.product.fields.category,
                    variant: bi.fields.product.fields.size,
                    list: 'Quick Shop Water - Bundles',
                    position: index + 1,
                    bundle_name: item.fields.entryTitle,
                    quantity: bi.fields.quantity,
                  })),
                ),
              ),
            },
          });
        } catch (e) {
          console.error(e); // eslint-disable-line
        }
      } else {
        dataLayerPush(dataLayerPushName, {
          currencyCode: 'USD',
          event: 'view_item_list',
          ecommerce: {
            impressions: result.map((item, idx) => ({
              name: item.name,
              id: item.id,
              price: item.price,
              brand: item.brand,
              category: item.category,
              variant: item.size,
              list: `Search Results: ${searchKeyword}`,
              position: idx + 1,
            })),
          },
        });
      }
    }

    return result;
  }, [searchKeyword, products]);

  const totalCountToShow = searchKeyword ? productsToRender.length : totalCount;

  useEffect(() => {
    hideOrderChangeMessage();
    loadProductCategories();
  }, []);

  useEffect(() => {
    const categoryContent = {
      title: 'Shop water and other beverages',
      description: '',
      metadescription: '',
    };
    if (searchParams?.categoryId) {
      if (content) {
        const cat = content[searchParams?.categoryId?.toLowerCase()];
        if (!isEmpty(cat)) {
          cat.forEach((element) => {
            categoryContent[
              element.fields.entryTitle.split(' > ').pop().toLowerCase()
            ] = element.fields.text;
          });
        }
      }
    }
    setCategoryHeaderContent(categoryContent);
  }, [searchParams.categoryId]);

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(`/account/products/list${location.search}`);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const loadProductsParams = getLoadProductsParams(
      1, // pageNum
      productCategories,
      searchParams,
    );

    if (isWaterBundlesKind(searchParams?.categoryId, productCategories)) {
      if (!searchKeyword) {
        return;
      }

      setIsBundle(true);
      loadProductBundles({ categoryId: loadProductsParams.categoryId });
    } else if (searchKeyword) {
      setIsBundle(false);
      dataLayerPush(dataLayerPushName, {
        event: 'search',
        search_term: searchKeyword,
      });
      loadMerchandizedProducts({ ...loadProductsParams, size: 'all' });
    } else {
      loadMerchandizedProducts({ ...loadProductsParams });
    }
  }, [searchKeyword]);

  const handleResetSearch = () => {
    setSearchKeyword('');
  };

  const handleOrderChangeConfirm = () => {
    hideOrderChangeMessage();
    history.push('/account/next-delivery');
  };

  const handleOrderChangeCancel = () => {
    hideOrderChangeMessage();
    if (selectedProductRef.current) {
      selectedProductRef.current.focus();
    }
  };

  const handleDeliveryOrderConfirm = () => {
    if (localStorage.getItem('profilePage') === 'true') {
      history.push('/account/profile');
      loadDeliveryOrder(shopDeliveryOrder);
    } else if (localStorage.getItem('nextDeliveryPage') === 'true') {
      history.push('/account/next-delivery');
      loadDeliveryOrder(shopDeliveryOrder);
    } else {
      history.push('/account/future-orders');
      shopDeliveryOrder.isEditing = true;
      loadDeliveryOrder(shopDeliveryOrder);
    }
  };

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword.trim());
  };

  const loadFilteredProducts = (pageNum = 1) => {
    const loadProductsParams = getLoadProductsParams(
      pageNum,
      productCategories,
      searchParams,
    );

    if (isWaterBundlesKind(searchParams?.categoryId, productCategories)) {
      setIsBundle(true);
      loadProductBundles({ categoryId: loadProductsParams.categoryId });
    } else {
      setIsBundle(false);
      loadMerchandizedProducts(loadProductsParams);
    }
  };

  useDeepCompareEffect(() => {
    loadFilteredProducts();
  }, [isAuthenticated, searchParams]);

  const reloadUrl = '/account/products/list?categoryId=3K46ptPRZQ31b3PSe4VX57'; // Large Bottle

  const isSelfServe = isSelfServeDomain();

  const productCategoriesFiltered = isAuthenticated
    ? productCategories.filter(
        (item) =>
          !isEquipmentKind(item.fields.name) && !hasTag(item, 'productBundle'),
      )
    : productCategories;

  const showServiceLocations = false; // TODO: Was isAuthenticated but changed to hide for now

  let showProducts = true;
  if (userRegionBrand) {
    const notSparkletts = userRegionBrand.toLowerCase() !== 'sparkletts';
    if (notSparkletts && products && products.length) {
      forEach(products, (product) => {
        if (product.brand === 'Sparkletts') {
          showProducts = false;
        }
      });
    }
  }

  return (
    <ProductListContext.Provider value={{ selectedProductRef }}>
      <Loader loading={loading}>
        <div className={styles.productListPage}>
          <div className={styles.productsContainer}>
            <div
              className={classnames(styles.header, {
                [styles.center]: !isAuthenticated,
              })}
            >
              <H1 className={styles.title}>
                {categoryHeaderContent && categoryHeaderContent.title}
              </H1>
              {showServiceLocations && (
                <ServiceLocations
                  selectClassName={styles.locationSelect}
                  reloadUrl={reloadUrl}
                />
              )}
              <div className={styles.description}>
                {categoryHeaderContent && categoryHeaderContent.description}
              </div>
            </div>
            <Grid
              container
              spacing={4}
              className={styles.listContainer}
              classes={{ container: classes.container }}
            >
              <Grid item sm={12} md={3} classes={{ item: classes.item }}>
                <SearchBar onSearch={handleSearch} keyword={searchKeyword} />
                <Filter
                  shopDeliveryOrder={shopDeliveryOrder}
                  brands={brands}
                  waterTypes={waterTypes}
                  productCategories={productCategoriesFiltered}
                  isAuthenticated={isAuthenticated}
                />
              </Grid>
              <Grid item sm={12} md={9} classes={{ item: classes.item }}>
                {showProducts && (
                  <>
                    <Mobile>
                      <H2 className={styles.resultsTitle}>Results</H2>
                    </Mobile>
                    <div className={styles.productsListInfo}>
                      {searchKeyword && (
                        <>
                          <Text className={styles.searchKeyword} color="#000">
                            {searchKeyword}
                          </Text>
                          <Divider orientation="vertical" flexItem />
                        </>
                      )}
                      <Text color="#000">
                        {totalCountToShow} products found
                      </Text>
                      <Divider orientation="vertical" flexItem />
                      <Button
                        link
                        type="button"
                        text="View all products"
                        onClick={handleResetSearch}
                      />
                    </div>
                    <ProductList
                      isAuthenticated={isAuthenticated}
                      products={productsToRender}
                      totalCount={totalCountToShow}
                      loadFilteredProducts={loadFilteredProducts}
                      isLoading={areProductsFetching}
                      shopDeliveryOrder={shopDeliveryOrder}
                      searchKeyword={searchKeyword}
                      isProductsInit
                    />
                  </>
                )}
                <Loader
                  local
                  className={styles.loader}
                  loading={areProductsFetching}
                />
              </Grid>
            </Grid>
          </div>
          {!isSelfServe &&
            content.banner.map((banner) => (
              <ContentfulFields
                type={get(banner, 'sys.contentType.sys.id', '')}
                document={banner}
                key={banner.sys.id}
              />
            ))}
          {!isSsr() && (
            <OrderChangeDrawer
              open={showOrderChangeMessage}
              onConfirm={handleOrderChangeConfirm}
              onCancel={handleOrderChangeCancel}
            />
          )}
          {shopDeliveryOrder && (
            <EditingDeliveryOrderDrawer
              section="future delivery product list"
              shopDeliveryOrder={shopDeliveryOrder}
              onConfirm={handleDeliveryOrderConfirm}
            />
          )}
          {/* <ProcessingModal /> */}
        </div>
        {categoryHeaderContent && (
          <Helmet>
            <title>{`${categoryHeaderContent.title} | ${brandName}`}</title>
            <meta
              name="description"
              content={categoryHeaderContent.metadescription}
            />
          </Helmet>
        )}
      </Loader>
    </ProductListContext.Provider>
  );
};

PrimoProducts.propTypes = {
  loadProductCategories: PropTypes.func.isRequired,
  loadMerchandizedProducts: PropTypes.func.isRequired,
  loadProductBundles: PropTypes.func.isRequired,
  site: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number,
  brands: PropTypes.arrayOf(PropTypes.string),
  productCategories: PropTypes.arrayOf(PropTypes.object),
  waterTypes: PropTypes.arrayOf(PropTypes.string),
  areProductsFetching: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showOrderChangeMessage: PropTypes.bool.isRequired,
  hideOrderChangeMessage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadDeliveryOrder: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userRegionBrand: selectBrand(),
});

const mapDispatchToProps = {
  loadDeliveryOrder: loadDeliveryOrderRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withOrderUtils,
)(PrimoProducts);
