export const UNSUPPORTED_BROWSER = 'UnsupportedBrowser';
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const contentfulModels = ['header', 'footer'];

export const provinceValues = [
  {
    id: 1,
    label: 'AB',
    data: { county: 'AB', state: 'CN' },
    value: 'AB',
  },
  {
    id: 2,
    label: 'BC',
    data: { county: 'BC', state: 'CN' },
    value: 'BC',
  },
  {
    id: 3,
    label: 'MB',
    data: { county: 'MB', state: 'CN' },
    value: 'MB',
  },
  {
    id: 4,
    label: 'NB',
    data: { county: 'NB', state: 'CN' },
    value: 'NB',
  },
  {
    id: 5,
    label: 'NL',
    data: { county: 'NL', state: 'CN' },
    value: 'NL',
  },
  {
    id: 6,
    label: 'NT',
    data: { county: 'NT', state: 'CN' },
    value: 'NT',
  },
  {
    id: 7,
    label: 'NS',
    data: { county: 'NS', state: 'CN' },
    value: 'NS',
  },
  {
    id: 8,
    label: 'NU',
    data: { county: 'NU', state: 'CN' },
    value: 'NU',
  },
  {
    id: 9,
    label: 'ON',
    data: { county: 'ON', state: 'CN' },
    value: 'ON',
  },
  {
    id: 10,
    label: 'PE',
    data: { county: 'PE', state: 'CN' },
    value: 'PE',
  },
  {
    id: 11,
    label: 'QC',
    data: { county: 'QC', state: 'CN' },
    value: 'QC',
  },
  {
    id: 12,
    label: 'SK',
    data: { county: 'SK', state: 'CN' },
    value: 'SK',
  },
  {
    id: 13,
    label: 'YT',
    data: { county: 'YT', state: 'CN' },
    value: 'YT',
  },
];

export const interestAreas = [
  'Personal Health',
  'Water Quality',
  'Environmental Impact',
  'Equipment Maintenance',
];

export const MONTHS_TITLES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const frequencyTitles = {
  '4-weeks': 'Every 4 weeks',
  '3-weeks': 'Every 3 weeks',
  '2-weeks': 'Every 2 weeks',
  weekly: 'Every week',
  'twice-week': 'Twice a week',
  '3-per-week': 'Three times a week',
  daily: 'Every day',
  'on-request': 'On Request',
};

export const GTM_TRACKING_TAGS = {
  'My Account': 'My Account',
  'Our Products': 'Our Products',
  Support: 'Support',
  'My Profile': 'My Profile',
  'Pay My Bill': 'Pay My Bill',
  'Next Delivery': 'Next Delivery',
  'Future Deliveries': 'Future Deliveries',
  'Next Delivery Dialog': 'Next Delivery Dialog',
  'Billing & Delivery History': 'Billing & Delivery History',
  'Delivery Calendar': 'Delivery Calendar',
  Shop: 'Shop',
  Logo: 'Logo',
  'Contact Us': 'Contact Us',
  'User Info Popper': 'User Info Popper',
  FAQs: 'FAQs',
  Manuals: 'Manuals',
  'Edit Order': 'Edit Order',
  'Manage recurring order': 'Manage recurring order',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  'Change Location': 'Change Location',
  'Change Location - Cancel': 'Change Location - Cancel',
  'Change Location - Confirm': 'Change Location - Confirm',
  'Change Location - Make Default': 'Change Location - Make Default',
  'Recent Transactions - View': 'Recent Transactions - View',
  'View Invoice': 'View Invoice',
  'View Delivery Tickets': 'View Delivery Tickets',
  'Print Schedule': 'Print Schedule',
  'Skip Delivery': 'Skip Delivery',
  'Billing Preferences': 'Billing Preferences',
  'Payment Methods': 'Payment Methods',
  'Add Card': 'Add Card',
  'Add Bank Account': 'Add Bank Account',
  Submit: 'Submit',
  'Sign up for AutoPay': 'Sign up for AutoPay',
  'Make AutoPay': 'Make AutoPay',
  'View All Water Coolers': 'View All Water Coolers',
  'View AquaCafe': 'View AquaCafe',
  Contact: 'Contact',
  'Show Next Delivery Popper': 'Show Next Delivery Popper',
  Backward: 'Backward',
  Forward: 'Forward',
  'Manage Order': 'Manage Order',
  'Sign up': 'Sign up',
  'what is this?': 'what is this?',
  Logout: 'Logout',
  Navigation: 'Navigation',
  Header: 'Header',
  Clear: 'Clear',
  'See more': 'See more',
  'Decrement Product Quantity': 'Decrement Product Quantity',
  'Increment Product Quantity': 'Increment Product Quantity',
  'One-Time Order': 'One-Time Order',
  'Recurring Order': 'Recurring Order',
  'Back to All products': 'Back to All products',
  'Back to Category': 'Back to Category',
  'Make a payment': 'Make a payment',
  'Recent Transactions': 'Recent Transactions',
  Invoices: 'Invoices',
  'Skip this delivery': 'Skip this delivery',
  'Add an extra delivery': 'Add an extra delivery',
  'Add to your order': 'Add to your order',
  'Autopay enabled': 'Autopay enabled',
  'Enable Autopay': 'Enable Autopay',
  'Confirm Changes Model': 'Confirm Changes Model',
  'My Profile Tooltip': 'My Profile Tooltip',
  'Debit/Credit Cards': 'Debit/Credit Cards',
  'Bank Accounts': 'Bank Accounts',
  'Account Suspended': 'Account Suspended',
  'No deliveries scheduled': 'No deliveries scheduled',
  'Make Default': 'Make Default',
  'Featured Products': 'Featured Products',
  'Support Collapse': 'Support Collapse',
  'My Account Collapse': 'My Account Collapse',
  'Feature Tour': 'Feature Tour',
  'Delivery information': 'Delivery information',
  'Order Profile': 'Order Profile',
  'Billing and payments': 'Billing and payments',
  'Account details': 'Account details',
  eInvoice: 'eInvoice',
};

export const Series100 = '100 Series';
export const Series200 = '200 Series';
export const Series200k = '200k Series';
export const Series300 = '300 Series';
export const Series500 = '500 Series';

export const coolerCleaningEquipment = {
  Series100,
  Series200,
  Series200k,
  Series300,
  Series500,
};
