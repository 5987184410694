import { useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  CREDIT_EXPIRATION_MONTHS_CURRENT,
  MONTHS_SINCE_CURRENT,
} from 'utils/selectOptions';

export default (values) => {
  const checkCurrentYearPicked = useCallback(
    () => values.expirationYear === String(moment().year()).substr(-2),
    [values.expirationYear],
  );
  useEffect(() => {
    ValidatorForm.addValidationRule('expMonth', (value) => {
      const isCurrentYearPicked = checkCurrentYearPicked();
      return isCurrentYearPicked ? MONTHS_SINCE_CURRENT.includes(value) : true;
    });

    return () => ValidatorForm.removeValidationRule('expMonth');
  }, [values.expirationYear]);

  const monthExpired = CREDIT_EXPIRATION_MONTHS_CURRENT.filter(
    (item) => item.value === values.expirationMonth,
  );
  const isCurrentYearPicked = checkCurrentYearPicked();
  return [
    isCurrentYearPicked && isEmpty(monthExpired) ? '' : values.expirationMonth,
    isCurrentYearPicked,
  ];
};
