import React, { useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { ButtonBase } from '@material-ui/core';
import injectSaga from 'utils/injectSaga';
import { NAMESPACE, actions, saga } from '../state';
import { getHasFetched, getIsFavoritesRequest } from '../state/selectors';
import styles from './styles.scss';

const FavoritesFilter = ({
  history,
  hasFetched,
  isFavoritesRequest,
  request,
  show,
}) => {
  useEffect(() => {
    if (isFavoritesRequest && !hasFetched) request();
  }, [hasFetched, isFavoritesRequest]);

  const handleClick = () => show(history);

  return (
    <div className={styles.filter}>
      <ButtonBase className={styles.actionBlock} onClick={handleClick}>
        <span className={styles.name}>Favorites</span>
        <div
          className={classNames(styles.circle, {
            [styles.checked]: isFavoritesRequest,
          })}
        />
      </ButtonBase>
    </div>
  );
};

FavoritesFilter.propTypes = {
  history: object,
  hasFetched: bool,
  isFavoritesRequest: bool,
  request: func,
  show: func,
};

const mapStateToProps = (state) => ({
  hasFetched: getHasFetched(state),
  isFavoritesRequest: getIsFavoritesRequest(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSaga({ key: NAMESPACE, saga }),
  withRouter,
  withImmutablePropsToJS,
)(FavoritesFilter);
