import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Ui';

import styles from './styles.scss';

const Category = ({ images, content, productsListUrl, children }) => (
  <section className={styles.container}>
    <header>
      {images}
      <div>{content}</div>
    </header>
    {children}
  </section>
);
Category.propTypes = {
  content: PropTypes.array,
};
export default Category;
