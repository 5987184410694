import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  selectProductsData,
  selectProductsAreFetching,
  selectProductCategories,
} from 'containers/PrimoProducts/selectors';
import { selectCartItems } from 'containers/Cart/selectors';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import {
  selectSsrReqRoute,
  selectAcquisitionVariables,
} from 'containers/Landing/selectors';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import qs from 'qs';
import {
  get,
  map,
  filter,
  includes,
  slice,
  find,
  forEach,
  flatten,
} from 'lodash';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { dataLayerPush } from 'utils/tracking';
import { getRegionalBrandName } from 'utils/domainHelper';
import { getToUrl, translateData, getCurrencyCode } from 'utils/translation';
import { Button, Icon } from 'components/Ui';
import Loader from 'components/Loader';
import ProductsList from 'components/ContentfulFields/ProductsList';
import {
  getContentOptions,
  getImagesOptions,
} from 'components/ContentfulFields/CollapseBanner/parserOptions';
import Category from 'containers/Showcase/Categories/Category';
import { hasBrandTag, hasTag } from 'components/Content/utils';

const COUNT_OF_VISIBLE_PRODUCTS = 3;

const setCartQuantity = (products, cartItems) =>
  forEach(products, (product) => {
    const match = find(
      cartItems,
      (item) => product.fields.itemNumber === item.itemNumber,
    );
    if (match) {
      product.fields.itemId = match.id;
      product.fields.quantity = match.quantity;
    }
  });

const ProductCategory = ({
  document,
  isProductsLoading,
  availableProducts,
  stylesTheme,
  cartItems,
  productCategories,
  isAuthenticated,
  ssrReqRoute,
  acquisitionVariables,
  isShowcase = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = require(`./${stylesTheme}.scss`);

  const regionalBrandName = getRegionalBrandName(ssrReqRoute, 13);

  const content = documentToReactComponents(
    document && document.fields.content,
    getContentOptions(
      styles,
      document,
      regionalBrandName,
      acquisitionVariables,
    ),
  );

  const isProductBundle = hasTag(document, 'productBundle');
  let brandedImages;
  let foundBrandedImage = false;
  const fieldsContent = get(document, 'fields.content.content');
  forEach(fieldsContent, (item) => {
    const contentType = get(item, 'data.target.sys.contentType.sys.id');
    if (!foundBrandedImage && contentType === 'brandedImages') {
      const fieldsEntryTitle = get(item, 'data.target.fields.entryTitle');
      if (
        fieldsEntryTitle.includes('AllBrands') ||
        fieldsEntryTitle.includes(regionalBrandName)
      ) {
        foundBrandedImage = true;
        brandedImages = documentToReactComponents(
          get(item, 'data.target.fields.content'),
          getImagesOptions(styles),
        );
      }
    }
  });

  const contentImages =
    brandedImages ||
    documentToReactComponents(
      document && document.fields.content,
      getImagesOptions(styles),
    );

  const contentfulProducts = get(document, 'fields.products', []);
  const contentfulProductBundless = get(document, 'fields.productBundles', []);
  const productBundles = filter(
    contentfulProductBundless,
    (contentfulProduct) => hasBrandTag(contentfulProduct),
  );
  const availableItemNumbers = map(availableProducts, 'itemNumber');
  const products = isProductBundle
    ? contentfulProducts
    : slice(
        filter(contentfulProducts, (contentfulProduct) =>
          includes(availableItemNumbers, contentfulProduct.fields.itemNumber),
        ),
        0,
        COUNT_OF_VISIBLE_PRODUCTS,
      ); // Display only first 3 products which are available

  if (isProductBundle && productBundles && productBundles.length > 0) {
    try {
      dataLayerPush(
        'Acquisition',
        {
          currencyCode: getCurrencyCode(),
          event: 'view_item_list',
          ecommerce: {
            impressions: productBundles.flatMap((item, index) =>
              flatten(
                item.fields.items.map((bi) => ({
                  name: item.fields.title,
                  id: bi.fields.product.fields.itemNumber,
                  price: `${
                    bi.fields.product.fields.price * bi.fields.quantity
                  }`,
                  brand: bi.fields.product.fields.brand || '',
                  category: bi.fields.product.fields.category,
                  variant: bi.fields.product.fields.size,
                  list: 'All product - Bundles',
                  position: index + 1,
                  bundle_name: item.fields.entryTitle,
                  quantity: bi.fields.quantity,
                })),
              ),
            ),
          },
        },
        'dlA33',
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  if (!isProductBundle && products && products.length > 0) {
    dataLayerPush(
      'Acquisition',
      {
        currencyCode: getCurrencyCode(),
        event: 'view_item_list',
        ecommerce: {
          impressions: products.map((item, index) => ({
            name: get(item, 'fields.name', ''),
            id: get(item, 'fields.itemNumber', ''),
            price: String(get(item, 'fields.price', '')),
            brand: get(item, 'fields.brand', ''),
            category: get(document, 'fields.name', ''),
            variant: get(item, 'fields.size', ''),
            position: index + 1,
          })),
        },
      },
      'dlA33',
    );
  }

  setCartQuantity(products, cartItems);

  const sysId = get(document, 'sys.id');
  const found = productCategories.find((cat) => sysId === cat.sys.id);
  const categoryId = found ? found.fields.urlName : sysId;

  let productsListUrl;
  if (isAuthenticated) {
    productsListUrl = `/products?${qs.stringify({ categoryId })}`;
  } else {
    productsListUrl = getToUrl(`/products/${categoryId}`, 'U66');
  }
  if (isShowcase) {
    return (
      document &&
      (isProductBundle ? productBundles.length > 0 : products.length > 0) && (
        <Category
          images={contentImages}
          content={content}
          productsListUrl={productsListUrl}
        >
          <Loader loading={isProductsLoading} local>
            <ProductsList
              products={isProductBundle ? productBundles : products}
            />
          </Loader>
        </Category>
      )
    );
  }
  return (
    document && (
      <div className={styles.productsContainer}>
        <div
          className={setTheme(styles.productCategory, document, styles)}
          style={{
            backgroundImage: `url(${get(
              document,
              'fields.backgroundImage.fields.file.url',
            )}?fm=webp)`,
          }}
        >
          <div className={styles.content}>
            <div className={styles.images}>{contentImages}</div>
            <div className={styles.descriptionWrapper}>{content}</div>
          </div>
          <div className={styles.shopButton}>
            {!isMobile ? (
              <Button text="Shop" href={productsListUrl} />
            ) : (
              <Link to={getToUrl(productsListUrl, 'U17')}>
                {translateData('Shop All')}
                <Icon icon="chevron-right" />
                <Icon icon="chevron-right" />
              </Link>
            )}
          </div>
          <Loader loading={isProductsLoading} local>
            <ProductsList products={products} />
          </Loader>
        </div>
      </div>
    )
  );
};

ProductCategory.propTypes = {
  document: PropTypes.shape({
    fields: PropTypes.shape({
      content: PropTypes.object,
    }),
    sys: PropTypes.shape({ id: PropTypes.string }),
  }),
  availableProducts: PropTypes.arrayOf(
    PropTypes.shape({
      branchId: PropTypes.number,
      customerType: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string,
      id: PropTypes.number,
      itemNumber: PropTypes.string,
      itemType: PropTypes.string,
      mail: PropTypes.bool,
      price: PropTypes.number,
      selfServe: PropTypes.bool,
      webDisplayAvailability: PropTypes.bool,
    }),
  ),
  stylesTheme: PropTypes.string,
  productCategories: PropTypes.arrayOf(PropTypes.object),
  isAuthenticated: PropTypes.bool.isRequired,
  acquisitionVariables: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  availableProducts: selectProductsData(),
  isProductsLoading: selectProductsAreFetching(),
  cartItems: selectCartItems(),
  productCategories: selectProductCategories(),
  isAuthenticated: selectIsAuthenticated(),
  ssrReqRoute: selectSsrReqRoute(),
  acquisitionVariables: selectAcquisitionVariables(),
});

export default withRouter(connect(mapStateToProps)(ProductCategory));
