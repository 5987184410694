import React from 'react';
import { bool, string } from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const HeartIcon = ({ filled = false, fill, stroke, ...restProps }) => {
  const { colors } = useTheme();
  const isFilled = filled;

  fill = (fill && colors[fill]) || colors.primaryBlue;
  stroke = (stroke && colors[stroke]) || colors.primaryBlue;

  return (
    <SvgIcon
      viewBox="0 0 17.427 14.257"
      width="17.427"
      height="14.257"
      fontSize="small"
      cursor="pointer"
      {...restProps}
    >
      <g transform="translate(-14.607 -2.1)">
        <g>
          <path
            d="M1,5.017A4,4,0,0,1,4.216,1.079a3.882,3.882,0,0,1,3.7,1.2c.37.387.713.8,1.064,1.2.171.2.3.2.472,0,.36-.413.711-.834,1.089-1.23A3.876,3.876,0,0,1,13.716,1.01a3.972,3.972,0,0,1,3.679,3.5,5.127,5.127,0,0,1-.477,2.749,9.68,9.68,0,0,1-1.7,2.508,19.765,19.765,0,0,1-3.948,3.28c-.638.413-1.3.785-1.953,1.178a.167.167,0,0,1-.188,0,24.712,24.712,0,0,1-5-3.545A11.7,11.7,0,0,1,1.762,7.745a6.014,6.014,0,0,1-.7-2.031C1.027,5.484,1.02,5.249,1,5.017Z"
            transform="translate(14.107 1.603)"
            fill={isFilled ? fill : 'none'}
            stroke={isFilled ? fill : stroke}
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

HeartIcon.propTypes = {
  filled: bool,
  fill: string,
  stroke: string,
};

export default HeartIcon;
