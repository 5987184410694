import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { Button, Div } from 'components/Ui';
import { formatCurrency } from 'utils/common';
import { InfoIcon } from 'images';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const BillingPaymentItem = ({
  showActivityDialog,
  showBalanceDialog,
  showBillDrawer,
  openManagePaymentDrawer,
  billingSummary,
}) => (
  <div className={styles.order}>
    <div className={styles.orderSubContainer}>
      <div className={styles.amountContainer}>
        <Div className={styles.paymentAreaLabel}>AMOUNT DUE</Div>
        <div className={styles.recentActivityAmount}>
          {billingSummary.amountDue
            ? formatCurrency(billingSummary.amountDue)
            : formatCurrency(0)}
        </div>
      </div>
      <div className={styles.operatorContainer}>
        <span>+</span>
      </div>
      <div className={styles.amountContainer}>
        <Div className={styles.paymentAreaLabel}>RECENT ACTIVITY</Div>
        <div className={styles.recentActivityContainer}>
          <div className={styles.recentActivityAmount}>
            {billingSummary.recentActivity
              ? formatCurrency(billingSummary.recentActivity)
              : formatCurrency(0)}
          </div>
          <div className={styles.recentActivity}>
            <span className={styles.informationImage}>
              <img src={InfoIcon} alt="" />
            </span>
            <Button
              tabIndex="0"
              className={styles.paymentOverlay}
              onClick={showActivityDialog}
              text="what is this?"
              link
            />
          </div>
        </div>
      </div>
      <div className={classnames(styles.operatorContainer)}>
        <span>=</span>
      </div>
      <div className={styles.amountContainer}>
        <Div className={styles.paymentAreaLabel}>CURRENT BALANCE</Div>
        <div className={styles.recentActivityContainer}>
          <div className={styles.paymentAmountDue}>
            {billingSummary.totalCurrentBalance
              ? formatCurrency(billingSummary.totalCurrentBalance)
              : formatCurrency(0)}
          </div>
          <div className={styles.recentActivity}>
            <span className={styles.informationImage}>
              <img src={InfoIcon} alt="" />
            </span>
            <Button
              tabIndex="0"
              className={styles.paymentOverlay}
              onClick={showBalanceDialog}
              text="what is this?"
              link
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <hr />
        <div className={styles.buttonContainer}>
          <Button
            size="large"
            className={styles.payBill}
            text="Pay My Bill"
            onClick={showBillDrawer}
            disabled={isEmpty(billingSummary)}
          />
        </div>
        <div className={styles.managePaymentMethodContainer}>
            <Button
              onClick={openManagePaymentDrawer}
              text="Manage Payment Methods"
              link
              bold
            />
          </div>
      </div>
    </div>
  </div>
);

BillingPaymentItem.propTypes = {
  showActivityDialog: PropTypes.func.isRequired,
  showBalanceDialog: PropTypes.func.isRequired,
  showBillDrawer: PropTypes.func.isRequired,
  billingSummary: PropTypes.object,
  openManagePaymentDrawer: PropTypes.func.isRequired,
};

export default BillingPaymentItem;
