import { createClient } from 'contentful';
import { COSTCO_WATER_SLUG } from 'containers/Landing/constants';
import { isSsr } from 'utils/ssrHelper';
import { getLocale } from 'utils/translation';
export default class ContentfulClient {
  constructor(SERVER_DATA) {
    if (!SERVER_DATA && !isSsr()) {
      this.SERVER_DATA = window.SERVER_DATA;
    } else {
      const {
        CONTENTFUL_API_SPACE,
        CONTENTFUL_API_ENVIRONMENT,
        CONTENTFUL_API_TOKEN,
      } = process.env;
      this.SERVER_DATA = {
        CONTENTFUL_API_SPACE,
        CONTENTFUL_API_ENVIRONMENT,
        CONTENTFUL_API_TOKEN,
      };
    }
  }

  getClient = async () => {
    const client = await createClient({
      space: this.SERVER_DATA.CONTENTFUL_API_SPACE,
      environment: this.SERVER_DATA.CONTENTFUL_API_ENVIRONMENT,
      accessToken: this.SERVER_DATA.CONTENTFUL_API_TOKEN,
      resolveLinks: true,
    });
    return client;
  };

  getSpace = async () => {
    const client = await this.getClient();
    const space = await client.getSpace();
    return space;
  };

  getEntries = async ({ contentType, query = {}, additionalQueryParams }) => {
    const queryParams = Object.entries(query).reduce(
      (resultParams, [queryKey, queryValue]) => ({
        ...resultParams,
        [`fields.${queryKey}`]: queryValue,
      }),
      {},
    );
    const client = await this.getClient();
    if (
      queryParams['fields.slug'] === 'costcowater-nonserv-absopure' ||
      queryParams['fields.slug'] === 'costcowater-nonserv-premiumwater'
    ) {
      queryParams['fields.site.fields.slug'] = COSTCO_WATER_SLUG;
    } else if (
      queryParams['fields.slug'] === COSTCO_WATER_SLUG ||
      (queryParams['fields.slug'] === 'home' &&
        queryParams['fields.site.fields.slug'] === COSTCO_WATER_SLUG)
    ) {
      // Below prevents the ZIP causing the 'flashing' issue
      if (window.dontSkipGettingEntries) {
        window.dontSkipGettingEntries = false;
      } else {
        return {};
      }
    }
    const entries = await client.getEntries({
      content_type: contentType,
      ...queryParams,
      ...additionalQueryParams,
      include: 10,
      locale: getLocale(),
    });
    return entries;
  };

  getEntry = async (id) => {
    const client = await this.getClient();
    const entry = await client.getEntry(id, { locale: getLocale() });
    return entry;
  };

  loadContentTypes = async () => {
    const contentTypes = await this.client.getContentTypes();
    return contentTypes;
  };

  getAssets = async (query) => {
    const client = await this.getClient();
    const assets = await client.getAssets(query);
    return assets;
  };
}
