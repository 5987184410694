import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, MobileStepper } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import customStyles from './styles.scss';
import jsStyles from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Product extends PureComponent {
  state = {
    activeStep: 0,
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  onSliderClick = (itemIndex) => {
    const {
      document: {
        fields: { images },
      },
    } = this.props;

    this.setState({
      activeStep: (itemIndex + 1) % images.length,
    });
  };

  render() {
    const {
      classes,
      theme,
      document: {
        fields: { description, images, title, launchDate },
      },
    } = this.props;

    const { activeStep } = this.state;
    const maxSteps = images.length;

    return (
      <div className={classes.root}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
          interval={10000}
        >
          {images.map((image, index) => (
            <div
              className={classes.item}
              key={image.sys.id}
              onClick={this.onSliderClick.bind(this, index)}
              onKeyPress={this.onSliderClick.bind(this, index)}
              role="presentation"
            >
              <img
                className={classes.img}
                src={`${image.fields.file.url}?fm=webp`}
                alt={image.fields.description}
              />
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          variant="dots"
          classes={{
            root: classes.mobileStepper,
            dot: classes.dot,
            dotActive: classes.dotActive,
          }}
        />
        <div className={customStyles.title}>{title}</div>
        <div className={customStyles.launchDate}>{launchDate}</div>
        <div className={customStyles.description}>{description}</div>
      </div>
    );
  }
}

Product.propTypes = {
  document: PropTypes.object,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(jsStyles, { withTheme: true })(Product);
