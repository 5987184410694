import * as types from './constants';

export const fetchSiteRequest = (payload) => ({
  type: types.FETCH_SITE_REQUEST,
  payload,
});

export const fetchSiteSuccess = (payload) => ({
  type: types.FETCH_SITE_SUCCESS,
  payload,
});

export const fetchSiteFailure = (payload) => ({
  type: types.FETCH_SITE_FAILURE,
  payload,
});
