import history from 'utils/history';
import * as types from './constants';

export const showSnackbar = (notification) => ({
  type: types.SHOW_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const showErrorSnackbar = (message) =>
  showSnackbar({
    message:
      message || 'Something went wrong, please try again or contact support',
    options: { variant: 'error' },
  });

export const showSomethingWentWrong = (message, code, showError) => {
  window.showSomethingWentWrongError = { message, code, showError };
  history.push(`/something-went-wrong`);
};

export const removeSnackbar = (key) => ({
  type: types.REMOVE_SNACKBAR,
  key,
});
