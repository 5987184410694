import React, { useEffect } from 'react';

const HomeRedirect = () => {
  useEffect(() => {
    try {
      const isRedirect = new URLSearchParams(window.location.search).has('rdr');

      if (isRedirect) throw new Error('Redirect param found.');

      window.location.replace('/?rdr');
    } catch (err) {
      console.error(err, 'Already tried redirecting to /. Stopping.'); // eslint-disable-line
    }
  }, []);

  return <div>Redirecting...</div>;
};

export default HomeRedirect;
