import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tab, Tabs as MUITabs, withStyles } from '@material-ui/core';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { translateData } from 'utils/translation';
import TabSubLabel from './TabSubLabel';

const styles = (theme) => ({
  tabsRoot: {
    minHeight: 60,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 0 0 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 100px 0 100px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 350px 0 350px',
    },
  },
  tabsRootModern: {
    background: theme.colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px 0 0 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '76px 100px 0 100px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '76px 350px 0 350px',
    },
  },
  tabRoot: {
    minWidth: 140,
    paddingBottom: 20,
    opacity: 1,
    textTransform: 'none',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    border: `3px solid transparent`,
    '&:focus, &:hover': {
      borderColor: theme.colors.primaryBlue,
      borderWidth: '3px 3px 3px 3px',
    },
  },
  tabRootModern: {
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
  },
  tabsIndicator: {
    backgroundColor: theme.colors.primaryBlue,
    height: '8px',
  },
  tabSelected: {
    color: theme.colors.primaryBlue,
  },
});

class Tabs extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return { value: props.value };
    }
    return null;
  }

  state = {
    value: this.props.value,
  };

  handleChange = (event, value) => {
    this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    const {
      classes,
      tabLabels,
      tabSubLabels,
      content,
      className,
      variant,
      modernTabStyling,
    } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <MUITabs
          value={value}
          onChange={this.handleChange}
          classes={{
            root: classNames(classes.tabsRoot, {
              [classes.tabsRootModern]: modernTabStyling,
            }),
            selected: classes.tabsSelected,
            indicator: classes.tabsIndicator,
            flexContainer: classes.flexContainer,
          }}
          variant={variant}
        >
          {tabLabels.map((label, i) => {
            const translatedLabel = translateData(label);
            const resultLabel = tabSubLabels[i] ? (
              <TabSubLabel label={translatedLabel} subLabel={tabSubLabels[i]} />
            ) : (
              translatedLabel
            );
            return (
              <Tab
                data-tracking={GTM_TRACKING_TAGS[label]}
                key={`${label}-${tabSubLabels[i] || ''}`}
                disableRipple
                classes={{
                  root: classNames(classes.tabRoot, {
                    [classes.tabRootModern]: modernTabStyling,
                  }),
                  selected: classes.tabSelected,
                  wrapper: classes.labelContainer,
                }}
                label={resultLabel}
              />
            );
          })}
        </MUITabs>
        <div className={className}>{content && content[value]}</div>
      </div>
    );
  }
}

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  tabLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabSubLabels: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.string,
  modernTabStyling: PropTypes.bool,
};

Tabs.defaultProps = {
  value: 0,
  onChange: () => {},
  tabSubLabels: [],
  modernTabStyling: false,
};

export default withStyles(styles)(Tabs);
