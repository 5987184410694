import { takeLatest, all, put, select, call } from 'redux-saga/effects';
import { selectIsProductPage } from 'containers/Landing/selectors';
import { loadAvailableProducts } from 'containers/PrimoProducts/actions';
import { apiCall } from 'utils/swaggerClient';
import { fetchPageRequest } from 'containers/Landing/actions';

import { getDomainByHost } from 'utils/domainHelper';

import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { isSsr } from 'utils/ssrHelper';
import { getServerData } from 'utils/getServerData';
import { isCanadianBrand } from 'utils/translation';
import { fixBrand } from 'utils/common';
import {
  UPDATE_USER_REGION,
  FETCH_USER_REGION,
  DEFAULT_USER_REGION,
} from './constants';
import { setUserRegionToBrandDefault } from './actions';
import { selectPostalCode } from './selectors';

function* updateUserRegion({ payload: postalCode }) {
  const windowIsDefined = !isSsr();
  const href = windowIsDefined ? window.location.href.toLowerCase() : '';
  const isDevelopment = href.includes('localhost');
  const fetchPage = !(
    href.includes('privacy-policy') ||
    href.includes('cookie-policy') ||
    href.includes('terms-of-use') ||
    href.includes('california-notice-at-collection') ||
    href.includes('CASupplyChain') ||
    href.includes('texas-notice-at-collection') ||
    href.includes('costcowater-ccpa') ||
    href.includes('costcowater-terms') ||
    href.includes('costcowater-privacy') ||
    href.includes('costcowater-service') ||
    href.includes('costcowater-nonserv-absopure') ||
    href.includes('costcowater-nonserv-premiumwater') ||
    href.includes('costcowater-nonserv-restrictions') ||
    href.includes('costcowater-casupplychain') ||
    href.includes('costcowater-cookies')
  );
  postalCode = isCanadianBrand() ? 'H1J' : postalCode;
  const devLocalDomain = getServerData('LOCAL_DOMAIN');
  const isCostcoWater =
    href.includes('costcowater') ||
    (isDevelopment && devLocalDomain === 'costcowater');

  try {
    /* eslint-disable prefer-const */
    const {
      headers: { 'x-branch-code': branch },
      obj: brand,
    } = yield call(apiCall, {
      operationId: 'brands.getBrandByZip',
      parameters: { postalCode, source: isCostcoWater ? 'costco' : 'TDB 4' },
      fullResponse: true,
    });

    if (windowIsDefined && isCostcoWater) {
      window.brandByZip = fixBrand(brand);
    }

    const isAuthenticated = yield select(selectIsAuthenticated());

    if (isCostcoWater && fetchPage && !isAuthenticated) {
      yield put(fetchPageRequest({ key: 'home' }));
    }

    if (windowIsDefined && !window.isCostcoWater) {
      const domain = getDomainByHost(25);
      if (!isAuthenticated && (!brand || domain.brand !== brand)) {
        throw new Error();
      }
    }

    yield put({
      type: UPDATE_USER_REGION,
      payload: { postalCode, brand, branch },
    });

    const isProductPage = yield select(selectIsProductPage());
    if (isProductPage) {
      yield put(loadAvailableProducts());
    }
  } catch (e) {
    if (windowIsDefined && window.isCostcoWater) {
      // Below prevents the ZIP causing the 'flashing' issue
      window.dontSkipGettingEntries = true;
      if (fetchPage) {
        yield put(fetchPageRequest({ key: 'home' }));
      }
    } else {
      const currentPostalCode = yield select(selectPostalCode());
      if (DEFAULT_USER_REGION.postalCode !== currentPostalCode) {
        yield put(setUserRegionToBrandDefault());
      }
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest([FETCH_USER_REGION], updateUserRegion)]);
}
