import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import injectSaga from 'utils/injectSaga';
import { selectCartLoading } from 'containers/Cart/selectors';
import {
  selectIsAuthenticated,
  selectIsFetching,
} from 'containers/Authentication/selectors';
import {
  selectProductsData,
  selectProductsAreFetching,
} from 'containers/PrimoProducts/selectors';
import { CART_PAGE_KEY } from 'containers/Cart/constants';
import {
  fetchAnonymousCart,
  fetchCart,
  addToCart,
} from 'containers/Cart/actions';
import saga from 'containers/Cart/saga';
import Item from './Item';

const mapDispatchToProps = {
  fetchAnonymousCart,
  fetchCart,
  addToCart,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  authenticationLoading: selectIsFetching(),
  cartLoading: selectCartLoading(),
  products: selectProductsData(),
  isProductsLoading: selectProductsAreFetching(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withCartSaga = injectSaga({ key: CART_PAGE_KEY, saga });

export default compose(withConnect, withCartSaga)(Item);
