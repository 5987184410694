import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from 'components/ContentfulFields/Button';
import Tmpl from 'components/Tmpl';
import scss from './styles.scss';

const Item = (props) => {
  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={styles.list}>{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className={styles.listItem}>{children}</li>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <Button className={styles.btnBottom} document={node.data.target}>
          {children}
        </Button>
      ),
    },
    renderText: (text) => <Tmpl>{text}</Tmpl>,
  };

  const {
    document: {
      fields: { description, title, image, imageLarge, className },
    },
    document,
    altStyles,
  } = props;

  // override default styles with altStyles if given
  const styles = altStyles || scss;

  const convertedDescription = documentToReactComponents(description, options);
  const isTitleInsideInfo = !includes(className, 'clean-water');

  const isImage = !!image?.fields?.file?.url;
  const isResponsive = isImage && !!imageLarge?.fields?.file?.url;
  const isVideo =
    isImage && get(image, 'fields.file.contentType', '').includes('video');

  return (
    document && (
      <div className={classnames(styles.item, styles[className])}>
        {!isTitleInsideInfo && <div className={styles.title}>{title}</div>}

        <div className={styles.info}>
          {isTitleInsideInfo && <div className={styles.title}>{title}</div>}
          <div className={styles.description}>{convertedDescription}</div>
        </div>

        {isVideo && (
          <div className={styles.video}>
            <video autoPlay muted loop>
              <source
                src={image.fields.file.url}
                type={image.fields.file.contentType}
              />
            </video>
          </div>
        )}

        {isResponsive && (
          <div className={styles.image}>
            <picture>
              <source
                srcSet={`${imageLarge.fields.file.url}?fm=webp`}
                media="(min-width: 768px)"
              />
              <img
                src={`${image.fields.file.url}?fm=webp`}
                alt={image.fields.description}
              />
            </picture>
          </div>
        )}

        {isImage && !isResponsive && !isVideo && (
          <div className={styles.image} data-image-container>
            <img
              src={`${image.fields.file.url}?fm=webp`}
              alt={image.fields.description}
            />
          </div>
        )}
      </div>
    )
  );
};

Item.propTypes = {
  document: PropTypes.object,
};

export default Item;
