import React from 'react';
import { useBrand } from 'components/Brands/hooks';
import PropTypes from 'prop-types';
import { filter, find, get, isString } from 'lodash';
import { getContentType } from 'components/Content';
import { getContentTaggedAnyOfBrandsTags } from 'components/Content/byBrand';

/**
 * @param {Object|string} titleKeys - single entry title or object w/many entry title values
 * @param {Object} typeKeys - object of Contentful content type values
 * @return {function} a react component that renders the given component w/a new 'content' prop
 * @example
 * const Example = ({ content }) => (<div>{content.text}</div>);
 * export default withContent({ text: 'Example Text' })(Example);
 */
const withContent = (titleKeys = {}, typeKeys = {}) => (Component) => {
  const isSingleKey = isString(titleKeys);
  if (isSingleKey) titleKeys = { _solo_: titleKeys };

  const contentKeys = { ...titleKeys, ...typeKeys };

  /**
   * WithContent - given either a Contentful `site` object or a sub-site `content` object,
   *   this component will pass a structured object of found content, keyed to the given
   *   `titleKeys` hash of content entry titles, and/or `typeKeys` hash of content types,
   *   as prop `content` to the wrapped component.
   * @param {Object} props - props
   * @param {Object} [props.site] - a Contentful `site` object (usually obtained via `withContentful`)
   * @param {Object} [props.content] - a content object usually found nested in a `site` object
   * @return {ReactElement} a react element
   */
  const WithContent = (props) => {
    const brand = useBrand();

    if (!brand) return null;

    const { site, content: cfContent } = props;
    let content;

    try {
      let modules;

      if (site) {
        modules = get(site, 'components.page.contentModules', []);
      } else {
        const c = get(cfContent, 'fields.content', []);
        const media = get(cfContent, 'fields.media', []);
        modules = [...c, ...media];
      }

      if (modules.length < 1) {
        throw `No content found for ${Component.name}!`; // eslint-disable-line
      }

      content = Object.entries(contentKeys).reduce((acc, [key, val]) => {
        const isTypeKey = !!typeKeys[key];
        const filterOrFind = isTypeKey ? filter : find;

        const keyContent = filterOrFind(modules, (module) => {
          if (isTypeKey) {
            return getContentType(module) === val;
          }

          const title = module.fields.entryTitle || module.fields.title || '';
          const titleHead = title.split('> ').pop();
          return titleHead === val;
        });

        // if (!keyContent) {
        //   console.debug(`No content found via contentKey: '${val}'!`); // eslint-disable-line
        // }

        const value = ((c) => {
          const type = getContentType(c);
          if (type === 'branded') {
            return getContentTaggedAnyOfBrandsTags(c, brand);
          }
          if (type === 'textPlain') return c.fields.text; // special case returns just text
          return c;
        })(keyContent);

        return { ...acc, [key]: value };
      }, {});
    } catch (e) {
      // FIXME first re-render (eg. press browser back button) almost always throws no content
      // so disabling these chatty logs for now
      // console.info(e); // eslint-disable-line
      return null;
    }

    const endContent = isSingleKey ? content._solo_ : content; // eslint-disable-line

    return <Component {...props} content={endContent} />;
  };

  WithContent.contentKeys = contentKeys;
  WithContent.typeKeys = typeKeys;

  // Wrap display name (helps debug)
  WithContent.displayName = `WithContent(${
    Component.displayName || Component.name || 'Component'
  })`;

  WithContent.propTypes = {
    site: PropTypes.object,
  };

  return WithContent;
};

export default withContent;
