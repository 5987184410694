import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Div } from 'components/Ui';
import styles from './styles.scss';

const NotificationBanner = ({ image, title, text, ...props }) => (
  <div className={classnames(styles.banner, props.className)}>
    <div className={styles.notificationInfo}>
      <div className={styles.image}>{image}</div>
      <div className={styles.content}>
        <Div className={styles.title}>{title}</Div>
        <Div className={styles.text}>{text}</Div>
      </div>
    </div>
  </div>
);

NotificationBanner.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NotificationBanner;
