import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Download = (props) => (
  <SvgIcon {...props} viewBox="0 0 425 500" fontSize="small">
    <g>
      <g id="file-download">
        <path d="M395.25,153h-102V0h-153v153h-102l178.5,178.5L395.25,153z M38.25,382.5v51h357v-51H38.25z" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </SvgIcon>
);

export default Download;
