import {
  LOGIN,
  LOGOUT,
  LOAD_AUTHENTICATION,
  HANDLE_AUTHENTICATION,
  HANDLE_MOBILE_AUTHENTICATION,
  SIGNUP,
  RESTORE_PASSWORD,
  CHANGE_PASSWORD,
  RESTORE_PASSWORD_MODAL_SUCCESS_HIDE,
  HANDLE_AUTHENTICATION_FAILURE,
  LOGOUT_INITIATED,
} from 'containers/Authentication/constants';

export function login() {
  return {
    type: LOGIN,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function logoutInitiated() {
  return {
    type: LOGOUT_INITIATED,
  };
}

export function loadAuthentication() {
  return {
    type: LOAD_AUTHENTICATION,
  };
}

export function handleAuthentication(authData) {
  return {
    type: HANDLE_AUTHENTICATION,
    payload: authData,
  };
}

export function handleMobileAppViewAuthentication() {
  return {
    type: HANDLE_MOBILE_AUTHENTICATION,
  };
}

export function handleAuthenticationFailure(error) {
  return {
    type: HANDLE_AUTHENTICATION_FAILURE,
    payload: error,
  };
}

export function signup({ email, password, returnTo }) {
  return {
    type: SIGNUP,
    payload: {
      email,
      password,
      returnTo,
    },
  };
}

export function restorePassword({ email }) {
  return {
    type: RESTORE_PASSWORD,
    payload: {
      email,
    },
  };
}

export function changePassword({ currentPassword, newPassword }) {
  return {
    type: CHANGE_PASSWORD,
    payload: {
      currentPassword,
      newPassword,
    },
  };
}

export function hideSuccessModal() {
  return {
    type: RESTORE_PASSWORD_MODAL_SUCCESS_HIDE,
  };
}
