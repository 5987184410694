import moment from 'moment';
import canadaRegions from 'utils/regionsData/canadaRegions.json';
import usRegions from 'utils/regionsData/usRegions.json';

const formatRegions = (regionList) =>
  regionList.map((region) => ({
    ...region,
    label: region.name,
    value: region.abbreviation,
  }));

export const CANADA_REGIONS = formatRegions(canadaRegions);
export const US_REGIONS = formatRegions(usRegions);

export const HELP_OPTIONS = [
  { value: 'SERVICE', label: 'Existing Service' },
  { value: 'NEW_WATER', label: 'New Water Service' },
  { value: 'NEW_FILTRATION', label: 'New Filtration Service' },
  { value: 'NEW_COFFEE', label: 'New Coffee Service' },
  { value: 'WEB_SITE', label: 'Website' },
  { value: 'MEDIA_INQUIRY', label: 'Media Inquiry' },
  { value: 'DONATIONS', label: 'Donation/Sponsorship' },
  {
    value: 'RD_INQUIRY',
    label: 'Retail/Distribution Inquiry',
  },
  { value: 'OTHER', label: 'Other' },
];

export const PEOPLE_NUMBER = [
  { value: '1-9', label: '1-9' },
  { value: '10-19', label: '10-19' },
  { value: '20-34', label: '20-34' },
  { value: '35+', label: '35+' },
];

export const CONTACT_METHODS = [
  { label: 'Phone', value: 'Phone' },
  { label: 'Email', value: 'Email' },
];

export const CONTACT_REGARDING = [
  { value: 'Water Filtration Workplace', label: 'Workplace' },
  { value: 'Water Filtration Home', label: 'Home' },
];

export const LOCATIONS_NUMBER = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '5+', label: '5+' },
];

export const LOCATION_TYPE = [
  { value: 'commercial', label: 'Commercial' },
  { value: 'residential', label: 'Residential' },
];

const getNextYears = (length = 10) => {
  const currentYear = Number(moment().format('YYYYY'));
  return Array.from({ length }, (_, i) => currentYear + i).map((year) => ({
    label: String(year),
    value: String(year).substr(2, 2),
  }));
};

export const MONTHS_LIST = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
export const CREDIT_EXPIRATION_MONTHS = MONTHS_LIST.map((month) => ({
  label: String(month),
  value: String(month),
}));
export const CREDIT_EXPIRATION_YEARS = getNextYears();

export const getMonthsSinceCurrent = () => {
  const currentMonthIndex = moment().format('M') - 1;
  return MONTHS_LIST.slice(currentMonthIndex).map((month) => ({
    label: String(month),
    value: String(month),
  }));
};

export const CREDIT_EXPIRATION_MONTHS_CURRENT = getMonthsSinceCurrent();
export const MONTHS_SINCE_CURRENT = MONTHS_LIST.slice(moment().format('M') - 1);

const getQuantityList = (length = 2) =>
  Array.from({ length }, (_, i) => i + 1).map((quantity) => ({
    label: String(quantity),
    value: String(quantity),
  }));

const getTermList = (length = 10) =>
  Array.from({ length }, (_, i) => i + 1).map((term) => ({
    label: `${term} yr`,
    value: String(term),
  }));

export const QUANTITY_LIST = getQuantityList();
export const TERM_LIST = getTermList();
