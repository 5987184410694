import { getQueryParam } from 'utils/common';

const CART_ID_KEY = 'cartId';
const CART_SECRET_KEY = 'cartSecret';
const CART_ID_QUERY = 'cartid';

export const updateLocalStorageCartId = (id) =>
  localStorage.setItem(CART_ID_KEY, id);

const getCartIdFromQuery = () => {
  const param = getQueryParam(CART_ID_QUERY);
  if (param) {
    updateLocalStorageCartId(param);
  }
  return param;
};

export const getLocalStorageCartId = () =>
  getCartIdFromQuery() || localStorage.getItem(CART_ID_KEY);

export const clearLocalStorageCartId = () =>
  localStorage.removeItem(CART_ID_KEY);

export const updateLocalStorageCartSecret = (id) =>
  localStorage.setItem(CART_SECRET_KEY, id);

export const getLocalStorageCartSecret = () =>
  localStorage.getItem(CART_SECRET_KEY);

export const clearLocalStorageCartSecret = () =>
  localStorage.removeItem(CART_SECRET_KEY);
