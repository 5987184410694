import { makeFetchActions } from 'utils/store/fetch';

export const NAMESPACE = 'favorites';

const [fetchActions, fetchTypes] = makeFetchActions(NAMESPACE);

export const types = {
  ...fetchTypes, // REQUEST, RECEIVE, FAIL, RESET

  SHOW: `${NAMESPACE}/SHOW`,
  ADD: `${NAMESPACE}/ADD`,
  REMOVE: `${NAMESPACE}/REMOVE`,
  TOGGLE: `${NAMESPACE}/TOGGLE`,
  TOGGLE_SUCCESS: `${NAMESPACE}/TOGGLE_SUCCESS`,
  TOGGLE_FAILURE: `${NAMESPACE}/TOGGLE_FAILURE`,
};

const show = (history) => ({
  type: types.SHOW,
  payload: history,
});

const add = (item, meta) => ({
  type: types.ADD,
  payload: item,
  meta,
});

const remove = (item) => ({
  type: types.REMOVE,
  payload: item,
});

const toggle = (item, isFavorite, meta) => ({
  type: types.TOGGLE,
  payload: {
    item,
    isFavorite,
  },
  meta,
});

const actions = {
  ...fetchActions, // request, receive, fail, reset
  show,
  add,
  remove,
  toggle,
};

export default actions;
