import { fromJS } from 'immutable';
import { SELECT_SERVICE_LOCATION_SUCCESS } from 'containers/PrimoAccount/constants';
import * as types from './constants';

const initialState = fromJS({
  isFetching: false,
  isInitiated: false,
  error: null,
  payMyBillError: null,
  creditCards: [],
  creditCardsIsFetching: false,
  creditCardsFetchError: '',
  bankAccounts: [],
  bankAccountsIsFetching: false,
  bankAccountsFetchError: '',
  paymentMethodsLoaded: false,
  billingAccount: {
    isFetching: false,
    data: null,
  },
  billingAccounts: {
    isFetching: false,
    data: null,
  },
  userInfo: { firstName: '', lastName: '' },
  billingSummary: null,
  billingSummaryFetchError: '',
  currentPaymentMethod: null,
  isProcessing: false,
  isPaymentRemovalSuccessful: false,
  isCardSucessful: false,
  isInvoiceSuccessful: false,
  isEditEmailSuccessful: false,
  isAddAuthorizedUserSuccessful: false,
  isEditNameSuccessful: false,
  isEditBillingSuccessful: false,
  oneTimeCreditCard: null,
  isAutoPaySuccessful: false,
  statementSettings: {
    isFetching: false,
    data: null,
    isProcessing: false,
    show: false,
    showSuccess: false,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CREDIT_CARDS:
      return state
        .set('creditCardsIsFetching', true)
        .set('creditCardsFetchError', '')
        .set('error', null);
    case types.LOAD_CREDIT_CARDS_SUCCESS:
      return state
        .set('creditCardsIsFetching', false)
        .set('creditCardsFetchError', '')
        .set('creditCards', fromJS(action.payload))
        .set('error', null);
    case types.LOAD_CREDIT_CARDS_FAILURE:
      return state
        .set('creditCardsIsFetching', false)
        .set('creditCardsFetchError', action.payload)
        .set('error', action.payload);
    case types.LOAD_BANK_ACCOUNTS:
      return state
        .set('bankAccountsIsFetching', true)
        .set('bankAccountsFetchError', '')
        .set('error', null);
    case types.LOAD_BANK_ACCOUNTS_SUCCESS:
      return state
        .set('bankAccountsIsFetching', false)
        .set('bankAccountsFetchError', '')
        .set('bankAccounts', fromJS(action.payload))
        .set('error', null);
    case types.LOAD_BANK_ACCOUNTS_FAILURE:
      return state
        .set('bankAccountsIsFetching', false)
        .set('bankAccountsFetchError', action.payload)
        .set('error', action.payload);
    case SELECT_SERVICE_LOCATION_SUCCESS:
      return state.set('paymentMethodsLoaded', false);
    case types.FETCH_PAYMENT_METHODS_SUCCESS:
      return state.set('paymentMethodsLoaded', true);
    case types.FETCH_PAYMENT_METHODS_RESET:
      return state.set('paymentMethodsLoaded', false);
    case types.SET_CURRENT_PAYMENT_METHOD_SUCCESS:
      return state.set('currentPaymentMethod', action.payload);
    case types.SET_CURRENT_PAYMENT_METHOD_FAILURE:
      return state.set('currentPaymentMethod', action.payload);
    case types.GET_BILLING_SUMMARY:
      return state
        .set('isFetching', true)
        .set('billingSummaryFetchError', '')
        .set('error', null);
    case types.GET_BILLING_SUMMARY_SUCCESS:
      return state
        .set('isFetching', false)
        .set('billingSummaryFetchError', '')
        .set('billingSummary', fromJS(action.payload))
        .set('error', null);
    case types.GET_BILLING_SUMMARY_FAILURE:
      return state
        .set('isFetching', false)
        .set('billingSummaryFetchError', action.payload)
        .set('error', action.payload);
    case types.UPDATE_CREDIT_CARD_SUCCESS: {
      const creditCards = state.get('creditCards').toJS();
      const updatedCard = {
        ...creditCards.find((card) => card.id === action.payload),
        autopay: true,
      };
      return state
        .set('isProcessing', false)
        .set('currentPaymentMethod', updatedCard)
        .set('isAutoPaySuccessful', true)
        .updateIn(['creditCards'], (cards) =>
          fromJS(
            cards.toJS().map((card) => {
              if (card.id === action.payload) {
                return updatedCard;
              }
              return card;
            }),
          ),
        );
    }
    case types.UPDATE_AUTO_PAY_SUCCESS:
      return state.set('isProcessing', false);
    case types.ADD_BANK_ACCOUNT:
    case types.UPDATE_BANK_ACCOUNT:
    case types.DELETE_BANK_ACCOUNT:
    case types.ADD_CREDIT_CARD:
    case types.UPDATE_CREDIT_CARD:
    case types.DELETE_CREDIT_CARD:
      return state.set('isProcessing', true).set('payMyBillError', null);
    case types.ADD_CREDIT_CARD_SUCCESS:
      return state
        .set('isCardSucessful', true)
        .set('oneTimeCreditCard', action.payload)
        .set('isProcessing', false);
    case types.RESET_ADD_CARD:
      return state.set('isCardSucessful', false).set('isProcessing', false);
    case types.RESET_PAYMENT_REMOVAL:
      return state.set('isPaymentRemovalSuccessful', false);
    case types.ADD_BANK_ACCOUNT_FAILURE:
    case types.UPDATE_BANK_ACCOUNT_FAILURE:
    case types.DELETE_BANK_ACCOUNT_FAILURE:
    case types.ADD_CREDIT_CARD_FAILURE:
    case types.UPDATE_CREDIT_CARD_FAILURE:
    case types.DELETE_CREDIT_CARD_FAILURE:
    case types.UPDATE_AUTO_PAY_FAILURE:
      return state
        .set('isProcessing', false)
        .set('payMyBillError', action.payload);
    case types.ADD_BANK_ACCOUNT_SUCCESS:
      return state.set('isProcessing', false);
    case types.UPDATE_BANK_ACCOUNT_SUCCESS:
      return state
        .set('isProcessing', false)
        .update('bankAccounts', (bankAccounts) =>
          fromJS(
            bankAccounts.toJS().map((bankAccount) => {
              if (bankAccount.id === action.payload.id) {
                return fromJS(action.payload);
              }
              return bankAccount;
            }),
          ),
        );
    case types.DELETE_BANK_ACCOUNT_SUCCESS:
      return state
        .set('isProcessing', false)
        .set(
          'bankAccounts',
          state
            .get('bankAccounts')
            .filter((bankAccount) => bankAccount.get('id') !== action.payload),
        )
        .set('isPaymentRemovalSuccessful', true);
    case types.DELETE_CREDIT_CARD_SUCCESS:
      return state
        .set('isProcessing', false)
        .set(
          'creditCards',
          state
            .get('creditCards')
            .filter((creditCard) => creditCard.get('id') !== action.payload),
        )
        .set('isPaymentRemovalSuccessful', true);
    case types.SHOW_STATEMENT_SETTINGS_SUCCESS:
      return state.setIn(['statementSettings', 'showSuccess'], true);
    case types.HIDE_STATEMENT_SETTINGS_SUCCESS:
      return state.setIn(['statementSettings', 'showSuccess'], false);
    case types.SHOW_STATEMENT_SETTINGS:
      return state.setIn(['statementSettings', 'show'], true);
    case types.HIDE_STATEMENT_SETTINGS:
      return state.setIn(['statementSettings', 'show'], false);
    case types.GET_STATEMENT_SETTINGS:
      return state.setIn(['statementSettings', 'isFetching'], true);
    case types.GET_STATEMENT_SETTINGS_SUCCESS:
      return state
        .setIn(['statementSettings', 'isFetching'], false)
        .setIn(['statementSettings', 'data'], fromJS(action.payload));
    case types.GET_STATEMENT_SETTINGS_FAILURE:
      return state.setIn(['statementSettings', 'isFetching'], false);
    case types.UPDATE_STATEMENT_SETTINGS:
      return state.setIn(['statementSettings', 'isProcessing'], true);
    case types.UPDATE_STATEMENT_SETTINGS_SUCCESS:
      return state
        .set('isInvoiceSuccessful', true)
        .setIn(['statementSettings', 'show'], false)
        .setIn(['statementSettings', 'isProcessing'], false)
        .updateIn(['statementSettings', 'data'], (data) =>
          fromJS({ ...data.toJS(), ...action.payload }),
        );
    case types.UPDATE_STATEMENT_SETTINGS_FAILURE:
      return state
        .setIn(['statementSettings', 'isProcessing'], false)
        .set('payMyBillError', action.payload);
    case types.GET_BILLING_ACCOUNT:
      return state.setIn(['billingAccount', 'isFetching'], true);
    case types.GET_BILLING_ACCOUNT_SUCCESS:
      return state
        .setIn(['billingAccount', 'isFetching'], false)
        .setIn(['billingAccount', 'data'], fromJS(action.payload));
    case types.GET_BILLING_ACCOUNT_FAILURE:
      return state.setIn(['billingAccount', 'isFetching'], false);
    case types.LOAD_BILLING_ACCOUNTS:
      return state.setIn(['billingAccounts', 'isFetching'], true);
    case types.LOAD_BILLING_ACCOUNTS_SUCCESS:
      return state
        .set('userInfo', fromJS(action.payload.userInfo))
        .setIn(['billingAccounts', 'isFetching'], false)
        .setIn(
          ['billingAccounts', 'data'],
          fromJS(action.payload.billingAccounts),
        );
    case types.LOAD_BILLING_ACCOUNTS_FAILURE:
      return state.setIn(['billingAccounts', 'isFetching'], false);
    case types.DELETE_BILLING_ACCOUNT:
      return state.set('isProcessing', true);
    case types.DELETE_BILLING_ACCOUNT_SUCCESS:
      return state
        .set('isProcessing', false)
        .set('isEditEmailSuccessful', true);
    case types.DELETE_BILLING_ACCOUNT_FAILURE:
      return state.set('isProcessing', false);
    case types.ADD_AUTHORIZED_USER:
      return state.set('isProcessing', true);
    case types.ADD_AUTHORIZED_USER_SUCCESS:
      return state
        .set('isProcessing', false)
        .set('isAddAuthorizedUserSuccessful', true);
    case types.ADD_AUTHORIZED_USER_FAILURE:
      return state.set('isProcessing', false).set('error', action.payload);
    case types.UPDATE_USER_DETAILS:
      return state.set('isProcessing', true);
    case types.UPDATE_USER_DETAILS_SUCCESS:
      return state.set('isProcessing', false).set('isEditNameSuccessful', true);
    case types.UPDATE_USER_DETAILS_FAILURE:
      return state.set('isProcessing', false);
    case types.UPDATE_BILLING_ADDRESS:
      return state.set('isProcessing', true);
    case types.UPDATE_BILLING_ADDRESS_SUCCESS:
      return state
        .set('isProcessing', false)
        .set('isEditBillingSuccessful', true);
    case types.UPDATE_BILLING_ADDRESS_FAILURE:
      return state.set('isProcessing', false);
    case types.CLOSE_INVOICE_DRAWER_SUCCESS:
      return state.set('isInvoiceSuccessful', false);
    case types.CLOSE_USER_DETAILS_DRAWER_SUCCESS:
      return state.set('isEditNameSuccessful', false);
    case types.CLOSE_REMOVE_EMAIL_DRAWER_SUCCESS:
      return state.set('isEditEmailSuccessful', false);
    case types.CLOSE_ADD_AUTHORIZED_USER_DRAWER_SUCCESS:
      return state.set('isAddAuthorizedUserSuccessful', false);
    case types.REMOVE_ONE_TIME_CREDIT_CARD:
      return state.set('oneTimeCreditCard', null);
    case types.CLOSE_EDIT_BILLING_DRAWER_SUCCESS:
      return state.set('isEditBillingSuccessful', false);
    case types.RESET_PAY_MY_BILL_ERROR:
      return state.set('payMyBillError', null);
    case types.RESET_BILLING_INFORMATION_ERROR:
      return state.set('error', null);
    case types.UPDATE_CUSTOMER_NOTES:
      return state.set('isCustomerNotesUpdating', true);
    case types.CLOSE_CUSTOMER_NOTES_DRAWER:
      return state
        .set('isCustomerNotesUpdating', false)
        .set('isCustomerNotesUpdatingSuccess', false);
    case types.UPDATE_CUSTOMER_NOTES_SUCCESS:
      return state
        .set('isCustomerNotesUpdating', false)
        .set('isCustomerNotesUpdatingSuccess', true);
    case types.UPDATE_CUSTOMER_NOTES_FAILURE:
      return state
        .set('isCustomerNotesUpdating', false)
        .set('isCustomerNotesFetchingSuccess', false)
        .set('error', action.payload);
    case types.FETCH_CUSTOMER_NOTES:
      return state
        .set('isCustomerNotesFetching', true)
        .set('isCustomerNotesFetchingSuccess', false);
    case types.FETCH_CUSTOMER_NOTES_SUCCESS:
      return state
        .set('customerNotes', action.payload)
        .set('isCustomerNotesFetching', false)
        .set('isCustomerNotesFetchingSuccess', true);
    case types.FETCH_DELIVERY_NOTES_SUCCESS:
      return state
        .set('deliveryNotes', action.payload)
        .set('isDeliveryNotesFetching', false)
        .set('isDeliveryNotesFetchingSuccess', true);
    case types.CLOSE_CUSTOMER_NOTES_DRAWER_SUCCESS:
      return state
        .set('isCustomerNotesFetchSuccessful', false)
        .set('isCustomerNotesUpdating', false);
    default:
      return state;
  }
};
