import React from 'react';
import classnames from 'classnames';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import { selectPromotionId } from 'containers/Cart/selectors';
import { withPromo, track } from 'components/Promos';
import { findPromoContentByType } from 'components/Promos/content';
import { withContent } from 'components/Content';
import Button from 'components/ContentfulFields/Button';
import RichText from 'components/RichText';
import styles from './styles.scss';

export const PLACEMENTS = {
  aboveLogo: 'Above logo in header',
  belowHeader: 'Below header',
};

const PLACEMENT_PATHS_EXCLUDE = {
  [PLACEMENTS.aboveLogo]: ['/cart', '/checkout'],
};

const CONTENT_KEYS = {
  blurb: 'Blurb',
  cta: 'Promo CTA',
  icon: 'Promo Icon',
};

const PlainBlurb = ({ blurb, icon }) => (
  <div className={styles.copy}>
    {icon?.fields?.file && (
      <img
        className={styles.icon}
        src={`${icon.fields.file.url}?fm=webp`}
        alt=""
      />
    )}
    <h1 className={styles.blurb}>{blurb}</h1>
  </div>
);

const RichBlurb = ({ blurb }) => (
  <div className={styles.richBlurb}>
    <RichText richText={blurb.fields.richText} />
  </div>
);

const BannerContent = withContent(CONTENT_KEYS)(function PromoBanner({
  content,
  placement,
  promoHandle,
}) {
  const { blurb, cta, icon } = content;

  if (!blurb && !cta && !icon) return null;

  const Blurb = blurb?.fields?.richText ? RichBlurb : PlainBlurb;

  return (
    <div
      data-promo-handle={promoHandle}
      className={classnames(styles.promoBanner, {
        [styles.aboveLogo]: placement === PLACEMENTS.aboveLogo,
      })}
    >
      <Blurb blurb={blurb} icon={icon} />
      <Button
        className={styles.cta}
        document={cta}
        onClick={track.promoClick}
      />
    </div>
  );
});

const PromoBanner = (props) => {
  const { promos, placement = PLACEMENTS.belowHeader } = props;
  const appliedPromotionId = useSelector(selectPromotionId());

  // Do NOT render if given placement excludes the current route
  if (PLACEMENT_PATHS_EXCLUDE[placement]) {
    const isExcluded = PLACEMENT_PATHS_EXCLUDE[placement].some((route) =>
      matchPath(props.location.pathname, route),
    );

    if (isExcluded) return null;
  }

  const byAppliedPromo = (entry) =>
    !entry.fields.isAppliedPromoRequired ||
    entry.promotionId === appliedPromotionId;

  const byPlacement = (entry) => entry.fields.placement.includes(placement);

  const ofType = findPromoContentByType(promos, 'promoBanner');
  const found = ofType.filter(byAppliedPromo).filter(byPlacement);

  if (!found.length) return null;

  return (
    <>
      {found.map((entry) => (
        <BannerContent
          key={entry.sys.id}
          content={entry}
          promoHandle={entry.promoHandle}
          {...props}
        />
      ))}
    </>
  );
};

export default compose(withRouter, withPromo())(PromoBanner);
