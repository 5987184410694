export const requiredDeliveryInformationFieldsHome = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'address1',
  'city',
  'stateOrProvinceCode',
  'postalCode',
];
export const requiredDeliveryInformationFieldsOffice = [
  ...requiredDeliveryInformationFieldsHome,
  'company',
];
export const requiredCardFields = [
  'cardholderName',
  'cardNumber',
  'expirationMonth',
  'expirationYear',
];
export const requiredBillingFields = [
  'address1',
  'city',
  'stateOrProvinceCode',
  'postalCode',
];
export const requiredDeliverySetup = ['deliveryDate', 'deliveryTime'];
