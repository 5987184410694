import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { CHECKOUT_PAGE_KEY } from './constants';
import { isRequiredFieldsFilled } from './utils';

const selectCheckout = () => (state) => state.get(CHECKOUT_PAGE_KEY, Map());

export const selectCheckoutCreditCard = () =>
  createSelector(selectCheckout(), (card) => card.get('creditCardData').toJS());

export const selectCheckoutLoading = () =>
  createSelector(selectCheckout(), (card) => card.get('loading'));

export const selectCheckoutErrors = () =>
  createSelector(selectCheckout(), (card) =>
    card.get('errors') ? card.get('errors') : undefined,
  );
export const selectCheckoutError = () =>
  createSelector(selectCheckout(), (data) => (data ? data.get('error') : null));

export const selectCheckoutPaymentErrors = () =>
  createSelector(selectCheckout(), (card) =>
    card.get('paymentErrors') ? card.get('paymentErrors').toJS() : undefined,
  );

export const selectCreatePasswordError = () =>
  createSelector(selectCheckout(), (card) =>
    card.get('createPasswordError')
      ? card.get('createPasswordError')
      : undefined,
  );

export const selectCreatePasswordSuccess = () =>
  createSelector(selectCheckout(), (card) =>
    card.get('createPasswordSuccess')
      ? card.get('createPasswordSuccess')
      : undefined,
  );

export const selectIsCreditCardFilled = () =>
  createSelector(selectCheckoutCreditCard(), (cardData) => {
    const creditCardRequiredFields = [
      'cardholderName',
      'encryptedCreditCardNumber',
      'expirationMonth',
      'expirationYear',
      'cvv',
    ];
    return isRequiredFieldsFilled(cardData, creditCardRequiredFields);
  });

export const selectOrderData = () =>
  createSelector(selectCheckout(), (checkout) =>
    checkout.get('order') ? checkout.get('order').toJS() : null,
  );

export const selectDeliveryData = () =>
  createSelector(selectCheckout(), (checkout) =>
    checkout.getIn(['order', 'servicesAddresses', 0], Map()).toJS(),
  );

export const selectBillingData = () =>
  createSelector(selectCheckout(), (checkout) =>
    checkout.getIn(['order', 'billing'], Map()).toJS(),
  );

export const selectCityCountyList = () =>
  createSelector(selectCheckout(), (checkout) =>
    checkout.get('cityCountyList').toJS(),
  );

export const selectCityCountyListOption = () =>
  createSelector(selectCheckout(), (checkout) =>
    checkout.get('cityCountyOption')
      ? checkout.get('cityCountyOption').toJS()
      : null,
  );
