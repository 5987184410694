import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { slide as Menu } from 'react-burger-menu';
import { createStructuredSelector } from 'reselect';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import classnames from 'classnames';
import { getToUrl } from 'utils/translation';
import { isPurefloDomain } from 'utils/domainHelper';
import { selectItemsCount } from 'containers/Cart/selectors';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { Button } from 'components/Ui';
import { useAuth0 } from 'auth0Provider';

import styles from './style.scss';
const Burger = ({ fields, isAuthenticated, match, itemsCount }) => {
  const isPureflo = isPurefloDomain();
  const { logout } = useAuth0();

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: () => null,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        const url = `/${nodeFields.slug}`;
        const isActive = url === match.path;

        return (
          <Link
            className={classnames(
              'menu-item',
              styles.link,
              isActive && 'active',
            )}
            to={getToUrl(url, 'U4')}
          >
            {children}
          </Link>
        );
      },
    },
  };

  const navigation = documentToReactComponents(fields, options);

  return (
    <div className={styles.burger}>
      <Menu right>
        {navigation}
        {!isPureflo && (
          <Link to="/cart" className={styles.cart}>
            Cart({itemsCount})
          </Link>
        )}
        {!isPureflo && isAuthenticated && (
          <Button
            link
            className={styles.logout}
            onClick={logout}
            text="Log Out"
          />
        )}
      </Menu>
    </div>
  );
};

Burger.propTypes = {
  fields: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  match: PropTypes.object,
  itemsCount: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  itemsCount: selectItemsCount(),
});

export default connect(mapStateToProps, null)(Burger);
