import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';
import {
  Mobile,
  Default,
  AboveSmallPhone,
  AboveMobile,
} from 'components/Media';
import Button from 'components/ContentfulFields/Button';
import { Icon, Container, LanguageToggle } from 'components/Ui';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { selectZipCode } from 'containers/ClientLocationProvider/selectors';
import {
  PurefloTheme,
  WaterRegionalTheme,
  WaterTheme,
} from 'components/ThemeProvider';
import ZipCodeTooltip from 'components/ContentfulFields/Header/HeaderPoppers/ZipTooltip';
import NextDeliveryPopper from 'components/ContentfulFields/Header/HeaderPoppers/NextDeliveryPopper';
import { selectSection, selectLanguage } from 'containers/Landing/selectors';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { isSsr } from 'utils/ssrHelper';
import { translateData, getToUrl } from 'utils/translation';
import {
  setSection as setSectionAction,
  setForceFetch as setForceFetchAction,
} from 'containers/Landing/actions';
import { shouldHideNav } from 'utils/domainHelper';

const Contacts = (
  {
    fields,
    section,
    setSection,
    isAuthenticated,
    postalCode,
    location,
    setForceFetch,
    locationPostalCode,
    match,
  },
  { stylesTheme },
) => {
  const styles = require(`./styles/${stylesTheme}.scss`);
  const isServersideRendering = isSsr();

  if (shouldHideNav(match.path)) return null;

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const {
          fields: nodeFields,
          sys: {
            contentType: {
              sys: { id },
            },
          },
        } = node.data.target;
        switch (id) {
          case 'button': {
            let isActive = false;

            if (section && section === nodeFields.section) {
              isActive = true;
            }

            const handleClick = nodeFields.section
              ? () => {
                  setSection(nodeFields.section);
                  // Note: Before, the setForceFetch was only called on certain pages but this needs to be
                  // for all pages because of the need to have the correct product category menu
                  setForceFetch(true);
                }
              : () => {};

            let fieldLink = null;
            const { pathname } = location;
            if (pathname === '/' && nodeFields.section === 'office') {
              fieldLink = '/office';
            } else if (
              pathname === '/office/who-we-serve' &&
              nodeFields.section === 'home'
            ) {
              fieldLink = '/';
            } else if (
              pathname.includes('home') &&
              nodeFields.section === 'office'
            ) {
              fieldLink = pathname.replace('home', 'office');
            } else if (
              pathname.includes('office') &&
              nodeFields.section === 'home'
            ) {
              fieldLink = pathname.replace('office', 'home');
            } else if (
              pathname.includes('BreakroomSupplies') ||
              pathname.includes('TeaAndHotChocolate') ||
              pathname.includes('Coffee')
            ) {
              fieldLink = '/products';
            }
            return (
              <Button
                link={!isActive}
                document={{
                  ...node.data.target,
                  fields: { ...node.data.target.fields, link: fieldLink },
                }}
                onClick={handleClick}
                section={nodeFields.section}
              />
            );
          }
          default:
            return (
              <Icon className={styles.phoneIcon} icon={nodeFields.iconName} />
            );
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <span>{children}</span>,
    },
  };

  const contacts = documentToReactComponents(fields, options);
  const isPostalCodeDetermined = Boolean(postalCode || locationPostalCode);

  const topHeaderInfo = <div className={styles.number}>{contacts}</div>;

  const href = !isServersideRendering ? window.location.href.toLowerCase() : '';
  if (
    !isServersideRendering &&
    (href.includes('costcowater') || window.isCostcoWater)
  ) {
    return null;
  }

  return (
    <Grid
      direction="row"
      className={styles.contacts}
      container
      justify="space-between"
      alignItems="center"
      data-tracking="header nav"
    >
      <PurefloTheme>
        <Container value="Header" className={styles.container}>
          {topHeaderInfo}
          <Mobile>
            <Link
              to={getToUrl('/get-started', 'U5')}
              className={styles.getStarted}
            >
              Get started
            </Link>
          </Mobile>
        </Container>
      </PurefloTheme>

      <WaterRegionalTheme>
        <div className={styles.container}>
          {!isAuthenticated && topHeaderInfo}
          {!isAuthenticated ? (
            <div className={styles.zipInfo}>
              {isPostalCodeDetermined && (
                <span
                  className={styles.deliverTo}
                  id={`dt${postalCode || locationPostalCode}`}
                >
                  {translateData('Deliver to')}{' '}
                  <span className={styles.postalCode}>
                    {postalCode || locationPostalCode}{' '}
                  </span>
                </span>
              )}
              <AboveSmallPhone>
                <ZipCodeTooltip />
              </AboveSmallPhone>
              <AboveMobile>
                <LanguageToggle header />
              </AboveMobile>
            </div>
          ) : (
            <NextDeliveryPopper className={styles.nextDelivery} />
          )}
        </div>
      </WaterRegionalTheme>

      <WaterTheme>
        <div className={styles.container}>
          {!isAuthenticated && topHeaderInfo}
          {!isAuthenticated ? (
            <div className={styles.zipInfo}>
              {isPostalCodeDetermined && (
                <span>
                  {translateData('Deliver to')}{' '}
                  {postalCode || locationPostalCode}{' '}
                </span>
              )}
              <Default>
                <ZipCodeTooltip />
              </Default>
            </div>
          ) : (
            <NextDeliveryPopper className={styles.nextDelivery} />
          )}
        </div>
      </WaterTheme>
    </Grid>
  );
};

Contacts.propTypes = {
  fields: PropTypes.object,
  section: PropTypes.string,
  setSection: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  postalCode: PropTypes.string,
  locationPostalCode: PropTypes.string,
};

Contacts.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  section: selectSection(),
  language: selectLanguage(),
  isAuthenticated: selectIsAuthenticated(),
  postalCode: selectPostalCode(),
  locationPostalCode: selectZipCode(),
});

const mapDispatchToProps = {
  setSection: setSectionAction,
  setForceFetch: setForceFetchAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contacts),
);
