import {
  AuthenticationCallbackPage,
  SignInPage,
  RetrievePasswordPage,
  ForgotEmailPage,
  ResetPasswordPage,
} from 'containers/Authentication';
import { REGIONAL_DOMAIN } from 'containers/Landing/constants';
import BaseLayout from 'containers/Layout/BaseLayout';

const AUTH_ROUTES = [
  {
    path: '/signin',
    exact: true,
    component: SignInPage,
    key: 'signin-route',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
  },
  {
    path: '/callback',
    exact: true,
    component: AuthenticationCallbackPage,
    key: 'auth-callback-route',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
  },
  {
    path: '/retrieve-password',
    exact: true,
    component: RetrievePasswordPage,
    key: 'retrieve-password-route',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPasswordPage,
    key: 'reset-password-route',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
  },
  {
    path: '/forgot-email',
    exact: true,
    component: ForgotEmailPage,
    key: 'forgot-email-route',
    layout: BaseLayout,
    domain: REGIONAL_DOMAIN,
  },
];

export default AUTH_ROUTES;
