import sassVariables from 'theme/_variables.scss';

export const themeConfig = {
  breakpoints: {
    values: {
      sm: 320,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
    keys: ['sm', 'md', 'lg', 'xl'],
  },
  gradients: {
    darkBlueGradient: sassVariables.darkBlueGradient,
    primaryGradient: sassVariables.primaryGradient,
    lightGreyGradient: sassVariables.lightGreyGradient,
    greyGradient: sassVariables.greyGradient,
    darkGreyGradient: sassVariables.darkGreyGradient,
  },
  borders: {
    greyBorder: (isWide = true) =>
      isWide ? sassVariables.greyBorder : sassVariables.thinGreyBorder,
    blueBorder: sassVariables.blueBorder,
    transparentBorder: sassVariables.transparentBorder,
  },
  boxShadows: {
    darkShadow: sassVariables.darkShadow,
  },
  colors: {
    hoverColor: sassVariables.hoverColor,
    hoverBorder: sassVariables.hoverBorder,
    primaryBlue: sassVariables.primaryBlue,
    primaryIce: sassVariables.primaryIce,
    primaryDarkBlue: sassVariables.primaryDarkBlue,
    middleBlue: sassVariables.middleBlue,
    primaryGreen: sassVariables.primaryGreen,
    black: sassVariables.black,
    blue: sassVariables.blue,
    inactiveBlue: sassVariables.inactiveBlue,
    white: sassVariables.white,
    whiteSmoke: sassVariables.whiteSmoke,
    whiteGrey: sassVariables.whiteGrey,
    darkBlue: sassVariables.darkBlue,
    grey: sassVariables.grey,
    lightGrey: sassVariables.lightGrey,
    extraLightGrey: sassVariables.extraLightGrey,
    darkGrey: sassVariables.darkGrey,
    deepBlue: sassVariables.deepBlue,
    skyBlue: sassVariables.skyBlue,
    red: sassVariables.red,
    lightRed: sassVariables.lightRed,
    lightGreen: sassVariables.lightGreen,
    dimGrey: sassVariables.dimGrey,
    skyGrey: sassVariables.skyGrey,
    heavySome: sassVariables.heavySome,
    middleGrey: sassVariables.middleGrey,
    lime: sassVariables.lime,
    limeDark: sassVariables.limeDark,
    lightViolet: sassVariables.lightViolet,
    drawerBackgroundBlue: sassVariables.drawerBackgroundBlue,
    lightButtonGrey: sassVariables.lightButtonGrey,
    mediumRed: sassVariables.mediumRed,
    stream: sassVariables.stream,
    pond: sassVariables.pond,
    lake: sassVariables.lake,
    river: sassVariables.river,
    ice: sassVariables.ice,
  },
  fonts: {
    sansPro: sassVariables.fontSansPro,
    benton: sassVariables.fontBenton,
  },
  transition: sassVariables.transition,
  smoothTransition: sassVariables.smoothTransition,
  typography: {
    fontFamily: '"benton-sans", sans-serif',
    fontSize: '1rem',
  },
};
