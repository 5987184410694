import DeliveryInfomation from './DeliveryInfomation';
import DeliverySetup from './DeliverySetup';
import Payment from './Payment';
import Review from './Review';
import Confirmation from './Confirmation';
import {
  CONFIRMATION_PAGE,
  INFORMATION_PAGE,
  PAYMENT_PAGE,
  REVIEW_PAGE,
  SETUP_PAGE,
} from './constants';

export const steps = {
  [INFORMATION_PAGE]: {
    buttonText: 'Delivery Information',
    subtitle: 'Step 1',
    title: 'My delivery info',
    notes: ['Please provide your preferred delivery information below.'],
    content: DeliveryInfomation,
  },
  [SETUP_PAGE]: {
    buttonText: 'Delivery Setup',
    subtitle: 'Step 2',
    title: 'My first delivery setup',
    notes: [
      "To get started, please select a preferred date when someone will be home. We'll come set up any equipment you've ordered.",
    ],
    content: DeliverySetup,
  },
  [PAYMENT_PAGE]: {
    buttonText: 'Payment',
    subtitle: 'Step 3',
    title: 'My payment',
    notes: ['Please enter your credit or debit card information.'],
    content: Payment,
    additionalNote:
      'Note: Bank account payments can take up to one week to process and clear. Credit/debit card payments can take up to two days to process.',
  },
  [REVIEW_PAGE]: {
    buttonText: 'Review',
    subtitle: 'Step 4',
    title: 'Review my order',
    notes: [
      'Please make sure you have all the products you would like delivered for a 2-week delivery cycle. If you need to make changes, please select edit below.',
    ],
    content: Review,
  },
  [CONFIRMATION_PAGE]: {
    title: 'Thanks for ordering from {site-name}',
    notes: [],
    xnotes: [
      'Nice work! The hard part is done, now you can sit back and enjoy your service.',
      'For your convenience, a confirmation will be sent to your email address.',
    ],
    notesOther: [],
    xnotesOther: [
      'You may get a call prior to your first delivery if a Route Sales Representative needs to confirm your address.',
    ],
    content: Confirmation,
    hideSteps: true,
    headerText: 'You did it! How refreshing does that feel?',
  },
};
