import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio as MUIRadio,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { translateData } from 'utils/translation';
import {
  CheckedRadioIcon,
  UncheckedRadioIcon,
} from 'components/Ui/CustomIcons/RadioIcons';

const styles = () => ({
  label: {
    fontSize: '0.9375rem',
  },
});

const Radio = ({
  value,
  label,
  classes,
  checked,
  size = 'small',
  ...props
}) => (
  <FormControlLabel
    value={value}
    classes={{
      label: classes.label,
      root: classes.root,
    }}
    control={
      <MUIRadio
        color="primary"
        icon={<UncheckedRadioIcon fontSize={size} id={`${value}-unchecked`} />}
        checkedIcon={
          <CheckedRadioIcon fontSize={size} id={`${value}-checked`} />
        }
        checked={checked}
        value={value}
        {...props}
      />
    }
    label={translateData(label)}
    {...props}
  />
);

export default withStyles(styles)(Radio);

Radio.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool,
};
