import reduceReducers from 'reduce-reducers';
import { fromJS } from 'immutable';
import { sortBy } from 'lodash';
import { reducer as favoritesReducer } from './Favorites/state';
import * as types from './constants';

const initialState = fromJS({
  isFetching: false,
  data: [],
  totalCount: 0,
  brands: [],
  waterTypes: [],
  categories: types.PRODUCT_CATEGORIES,
  isCategoriesFetching: false,
  featured: [],
  error: null,
});

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_AVAILABLE_PRODUCTS:
    case types.LOAD_MERCHANDIZED_PRODUCTS:
      return state.set('isFetching', true).set('error', null);
    case types.LOAD_PRODUCT_BUNDLES:
      return state.set('isBundlesFetching', true).set('error', null);
    case types.LOAD_FAMILY_PRODUCT_BUNDLES:
      return state.set('isBundlesFetching', true).set('error', null);
    case types.LOAD_AVAILABLE_PRODUCTS_SUCCESS:
      return state
        .set('isFetching', false)
        .set('data', fromJS(action.payload || []));
    case types.LOAD_MERCHANDIZED_PRODUCTS_SUCCESS: {
      // In case applied new set of filters replace list of products, in case change page append to existing
      const productsData = fromJS(action.payload);
      const products = productsData.get('products');
      const productsList =
        action.payload.page > 1 ? state.get('data').concat(products) : products;

      return state
        .set('isFetching', false)
        .set('data', productsList)
        .set('totalCount', productsData.get('totalCount'))
        .set('brands', productsData.get('brands'))
        .set('waterTypes', productsData.get('waterTypes'));
    }
    case types.LOAD_PRODUCT_BUNDLES_SUCCESS: {
      // In case applied new set of filters replace list of products, in case change page append to existing
      const productsData = fromJS(action.payload);
      const products = productsData.get('products');
      const productsList =
        action.payload.page > 1 ? state.get('data').concat(products) : products;

      return state
        .set('isBundlesFetching', false)
        .set('data', productsList)
        .set('totalCount', productsData.get('totalCount'))
        .set('brands', productsData.get('brands'))
        .set('waterTypes', productsData.get('waterTypes'));
    }
    case types.LOAD_FAMILY_PRODUCT_BUNDLES_SUCCESS: {
      const productsData = fromJS(action.payload);
      const familyProductBundles = productsData.get('familyProductBundles');
      return state
        .set('isBundlesFetching', false)
        .set('familyProductBundles', familyProductBundles);
    }
    case types.LOAD_FEATURED_PRODUCTS_SUCCESS:
      return state.set(
        'featured',
        fromJS(sortBy(action.payload.products || [], ['sequence'])),
      );

    case types.LOAD_PRODUCT_BUNDLES_FAILURE:
    case types.LOAD_FAMILY_PRODUCT_BUNDLES_FAILURE:
      return state.set('isBundlesFetching', false).set('error', action.payload);
    case types.LOAD_AVAILABLE_PRODUCTS_FAILURE:
    case types.LOAD_MERCHANDIZED_PRODUCTS_FAILURE:
    case types.LOAD_FEATURED_PRODUCTS_FAILURE:
      return state.set('isFetching', false).set('error', action.payload);
    case types.LOAD_PRODUCT_CATEGORIES:
      return state.set('isCategoriesFetching', true);
    case types.LOAD_PRODUCT_CATEGORIES_SUCCESS:
      return state
        .set('isCategoriesFetching', false)
        .set('categories', fromJS(action.payload.categories));
    case types.LOAD_PRODUCT_CATEGORIES_FAILURE:
      return state.set('isCategoriesFetching', false);
    case types.RESET_PRODUCTS_ERROR:
      return state.set('error', null);
    default:
      return state;
  }
};

export default reduceReducers(initialState, productsReducer, favoritesReducer);
