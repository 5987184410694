import moment from 'moment';

export const getNextPossibleDeliveryDay = () => {
  const d = moment().add(2, 'days');
  const dayOfWeek = d.isoWeekday();
  const isWeekend = dayOfWeek === 6 || dayOfWeek === 7;

  if (isWeekend) {
    return d.add(1, 'week').isoWeekday(1);
  }

  return d;
};

export const getCommonDisabledDeliveryDays = () => {
  const commonDisabledDates = [
    {
      before: moment().add(2, 'days').startOf('day').toDate(),
      after: moment().add(2, 'months').endOf('month').toDate(),
    },
    {
      daysOfWeek: [0, 6],
    },
  ];

  return commonDisabledDates;
};
