import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import {
  Drawer as MUIDrawer,
  withStyles,
  AppBar,
  ButtonBase,
} from '@material-ui/core';
import { CloseIconSvg } from 'components/Ui/CustomIcons';
import { arrowBackward } from 'images';
import { Button, H2 } from 'components/Ui';

const styles = (theme) => ({
  backdropRoot: {
    backgroundColor: theme.colors.drawerBackgroundBlue,
  },
  noBackdrop: {
    backgroundColor: 'inherit',
  },
  paper: {
    minWidth: '470px',
    width: '32rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      width: '100%',
    },
  },
  header: {
    width: 'auto',
    minHeight: '85px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px 0 29px',
    borderBottom: theme.borders.greyBorder(false),
    backgroundColor: theme.colors.white,
    boxShadow: 'none',
    "&:not([data-scroll='0'])": {
      boxShadow: 'inherit',
    },
  },
  button: {
    border: '3px solid transparent',
  },
  content: (props) => ({
    margin: props.noMargins ? '' : '28px 40px 30px 29px',
  }),
  bottom: (props) => ({
    margin: props.noMargins ? '' : '0 29px',
    borderTop: theme.borders.greyBorder(false),
  }),
  headerTitle: {
    color: theme.colors.black,
  },
  backButton: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    marginBottom: '20px',

    '& img': {
      width: '17px',
      marginRight: '10px',
    },
  },
});

const Drawer = ({
  headerTitle,
  open: propsOpen,
  onClose,
  bottom,
  noMargins,
  classes,
  children,
  noBackdrop,
  showBackButton,
  hideCloseButton,
  show,
  hide,
  ...rest
}) => {
  const headerRef = useRef();

  const headerCallbackRef = useCallback((node) => {
    if (node !== null) {
      headerRef.current = node;
      headerRef.current.dataset.scroll = node.scrollTop;
    }
  }, []);

  const storeScroll = (event) => {
    if (event) {
      event.target.dataset.scroll = event.target.scrollTop;
    }

    if (headerRef && headerRef.current) {
      headerRef.current.dataset.scroll = event.target.scrollTop;
    }
  };

  const debouncedStoreScroll = (event) => {
    event.persist();
    debounce(storeScroll)(event);
  };

  const [open, changeOpen] = useState(propsOpen);

  useEffect(() => {
    if (propsOpen !== open) {
      changeOpen(propsOpen);
    }
  }, [propsOpen]);

  const handleClose = () => {
    if (hide) {
      hide();
    } else {
      changeOpen(false);
    }
    onClose();
  };

  const handleBack = () => {
    if (hide) {
      hide();
    } else {
      changeOpen(false);
    }
  };

  return (
    <MUIDrawer
      anchor="right"
      PaperProps={{
        'data-tracking': rest['data-tracking'] || `Modal - ${headerTitle}`,
      }}
      ModalProps={{
        BackdropProps: {
          'data-tracking':
            `${rest['data-tracking']} Backdrop` ||
            `Modal Backdrop - ${headerTitle}`,
          classes: {
            root: classnames(
              classes.backdropRoot,
              noBackdrop && classes.noBackdrop,
            ),
            paper: classes.paper,
          },
        },
      }}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={handleClose}
      onScroll={debouncedStoreScroll}
      {...rest}
      disableEnforceFocus
    >
      <AppBar
        className={classes.header}
        position="sticky"
        ref={headerCallbackRef}
      >
        <H2 className={classes.headerTitle}>{headerTitle}</H2>
        {!hideCloseButton && (
          <ButtonBase
            className={classes.button}
            onClick={handleClose}
            aria-label="Close drawer"
          >
            <CloseIconSvg fontSize="large" />
          </ButtonBase>
        )}
      </AppBar>
      <div className={classes.content}>
        {showBackButton && (
          <Button
            className={classes.backButton}
            text="Back"
            icon={<img src={arrowBackward} alt="" />}
            onClick={handleBack}
            iconPosition="left"
            link
          />
        )}
        {children}
      </div>
      {bottom && <div className={classes.bottom}>{bottom}</div>}
    </MUIDrawer>
  );
};

Drawer.defaultProps = {
  headerTitle: '',
  open: false,
  noBackdrop: false,
  onClose: () => {},
  hideCloseButton: false,
};

Drawer.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default withStyles(styles)(Drawer);
