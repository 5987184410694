import * as types from './constants';

export const fetchClientLocation = (payload) => ({
  type: types.GET_CLIENT_LOCATION_REQUEST,
  payload,
});

export const fetchClientLocationSuccess = (payload) => ({
  type: types.GET_CLIENT_LOCATION_SUCCESS,
  payload,
});

export const fetchClientLocationFailure = (payload) => ({
  type: types.GET_CLIENT_LOCATION_FAILURE,
  payload,
});
