import { push } from 'connected-react-router';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  setAccessToken,
  removeAccessToken,
  getParsedAccessToken,
  getAccessToken,
} from 'utils/accessTokenManager';
import { authorizeApiClient, deauthorizeApiClient } from 'utils/swaggerClient';
import { isPurefloDomain } from 'utils/domainHelper';
import * as constants from 'containers/Authentication/constants';
import {
  MERGE_ANON_CART,
  FETCH_ANONYMOUS_CART,
  FETCH_USER_CART,
} from 'containers/Cart/constants';
import { clearStorageCreditCard } from 'containers/CheckoutPage/utils';
import {
  clearLocalStorageCartId,
  clearLocalStorageCartSecret,
} from 'containers/Cart/utils';
import { getCartId } from 'containers/Cart/saga';
import { loadServiceLocation } from 'containers/PrimoAccount/actions';
import { showSnackbar } from 'components/Notifier/actions';
import cookies from 'js-cookie';
import { isSsr } from 'utils/ssrHelper';
import { getServerData } from 'utils/getServerData';

function* handleAuthError({ error, triggerLogout = true }) {
  const errorMessage = error || 'Error at time of authentication';
  yield put({
    type: constants.LOAD_AUTHENTICATION_FAILURE,
    payload: {
      errorMessage,
    },
  });
  yield put(
    showSnackbar({
      message: errorMessage,
      options: { variant: 'error' },
    }),
  );
  if (triggerLogout) {
    yield call(logoutSaga);
  }
}

function* loadUserInfo(userInfo) {
  const parsedToken = getParsedAccessToken();
  const apiNamespace = !isSsr() ? getServerData('API_HOST') : '';

  const additionalUserInfo = Object.entries(parsedToken).reduce(
    (result, [key, value]) => {
      if (key.includes(apiNamespace)) {
        result[key.replace(apiNamespace, '')] = value;
      }

      return result;
    },
    {},
  );

  const parsedUserInfo = Object.entries(userInfo).reduce(
    (result, [key, value]) => {
      if (key.includes(apiNamespace)) {
        result[key.replace(apiNamespace, '')] = value;
      } else {
        result[key] = value;
      }

      return result;
    },
    {},
  );

  return {
    ...additionalUserInfo,
    ...parsedUserInfo,
  };
}

export function* handleAuth({
  payload: { accessToken, signInUser, appState },
}) {
  try {
    if (!accessToken) {
      yield handleAuthError();
    }
    setAccessToken(accessToken);
    yield call(authorizeApiClient, accessToken);
    const userInfo = yield call(loadUserInfo, signInUser);
    yield put({
      type: constants.LOAD_AUTHENTICATION_SUCCESS,
      payload: userInfo,
    });
    yield put(loadServiceLocation());
    if (appState) {
      yield put(push(appState));
    }
  } catch (error) {
    yield handleAuthError({ error });
  }
}

export function* handleMobileViewAuth() {
  const accessToken = cookies.get('accessToken');
  if (!accessToken) {
    yield handleAuthError();
  }
  setAccessToken(accessToken);
  yield put({ type: constants.LOAD_MOBILE_AUTHENTICATION_SUCCESS });
}

export function* checkAuth() {
  const cartId = yield getCartId();

  try {
    const accessToken = getAccessToken();
    if (!accessToken) {
      yield handleAuthError();
    }

    if (isPurefloDomain()) {
      if (cartId) {
        yield put({ type: MERGE_ANON_CART, payload: { cartId } });
        clearLocalStorageCartId();
        clearLocalStorageCartSecret();
      } else {
        yield put({ type: FETCH_USER_CART });
      }
    }
  } catch (error) {
    yield put({
      type: constants.LOAD_AUTHENTICATION_FAILURE,
      payload: { errorMessage: null },
    });
    if (cartId) {
      yield put({ type: FETCH_ANONYMOUS_CART, payload: { cartId } });
    }
  }
}

export function* logoutSaga() {
  yield removeAccessToken();
  yield deauthorizeApiClient();
  yield clearStorageCreditCard();
}

export default function* authSaga() {
  yield all([
    takeLatest(constants.LOGOUT, logoutSaga),
    takeLatest(constants.HANDLE_AUTHENTICATION, handleAuth),
    takeLatest(constants.LOAD_AUTHENTICATION, checkAuth),
    takeLatest(constants.HANDLE_AUTHENTICATION_FAILURE, handleAuthError),
    takeLatest(constants.HANDLE_MOBILE_AUTHENTICATION, handleMobileViewAuth),
  ]);
}
