import React from 'react';
import classnames from 'classnames';
import { isNil, isEmpty } from 'lodash';
import {
  withStyles,
  OutlinedInput,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import FormLabel from 'components/Ui/FormLabel';
import { translateData } from 'utils/translation';
import { warningRedSmall } from 'images';
import styled from 'styled-components';
import TextMask from './TextMask';

const styles = (theme) => {
  const getStyle = require(`./styles/${theme.themeName}.js`);
  return getStyle(theme);
};

const DEFAULT_HEIGHT = 88;
const UNLABELED_HEIGHT = 65;
const NO_ERROR_HEIGHT = 70;
const NAKED_HEIGHT = 41;

class Input extends ValidatorComponent {
  getDefaultHeight = () => {
    if (this.props.withoutError && !this.props.label) {
      return NAKED_HEIGHT;
    }

    if (this.props.withoutError) {
      return NO_ERROR_HEIGHT;
    }

    if (!this.props.label) {
      return UNLABELED_HEIGHT;
    }

    return DEFAULT_HEIGHT;
  };

  render() {
    const {
      id,
      autocomplete,
      errorMessages,
      error,
      classes,
      label,
      validators,
      validatorListener,
      minHeight,
      required,
      withoutError,
      select,
      startAdornment,
      smallLabel,
      boldLabel,
      mask,
      placeholderChar,
      placeholder,
      showMask,
      fullWidth,
      inline,
      showError,
      hasExternalError,
      containerClassName,
      labelClassName,
      disableAsterisk,
      ariaLabel,
      regularLabel,
      rightComp,
      onChange,
      maxLength,
      helperText,
      ...rest
    } = this.props;

    const translatedAriaLabel = translateData(
      ariaLabel || this.props['aria-label'],
      '',
    );
    const translatedLabel = translateData(label);

    const { isValid } = this.state;

    const isRequired = validators.includes('required') || required;

    const errorText =
      showError !== false &&
      (hasExternalError ||
        (!isValid && this.getErrorMessage()) ||
        (!isEmpty(error) && error));
    const isInvalidElement = error || !isValid;

    const defaultHeight = this.getDefaultHeight();
    let ariaInfo = {
      'aria-label': `${isRequired ? '*' : ''}${
        translatedAriaLabel || translatedLabel
      }`,
      'aria-required': `${isRequired}`,
    };
    ariaInfo = errorText
      ? { ...ariaInfo, 'aria-describedby': `${id}-error` }
      : ariaInfo;
    const inputProps = mask
      ? {
          mask,
          placeholderChar,
          showMask,
          ...ariaInfo,
          autoComplete: autocomplete,
        }
      : {
          ...ariaInfo,
          autoComplete: autocomplete,
        };

    const WarningIcon = styled.img`
      margin-bottom: 2px;
    `;

    const ErrorText = styled.span`
      background-color: #f4f5fa;
    `;

    const showErrorText = errorText && !hasExternalError;

    return (
      <FormControl
        fullWidth={fullWidth !== false}
        error={!!isInvalidElement}
        style={{ minHeight: !isNil(minHeight) ? minHeight : defaultHeight }}
        classes={{
          root: classnames(classes.formControl, inline && classes.inline),
        }}
        className={containerClassName && containerClassName}
      >
        {select ? (
          <label
            className={classnames(
              classes.rootLabel,
              smallLabel && classes.smallLabels,
              inline && classes.inlineLabel,
              boldLabel && classes.boldLabel,
            )}
            id={`${id}-label`}
            htmlFor={id}
          >
            {isRequired ? '*' : ''}
            {translatedLabel}
          </label>
        ) : (
          <>
            <FormLabel
              label={translatedLabel}
              id={`${id}-label`}
              required={isRequired}
              classes={{
                root: classnames(
                  classes.rootLabel,
                  smallLabel && classes.smallLabels,
                  inline && classes.inlineLabel,
                ),
              }}
              disableAsterisk={disableAsterisk}
              htmlFor={id}
            />
            {rightComp && (
              <span className={classes.rightComp}>{rightComp}</span>
            )}
          </>
        )}
        <OutlinedInput
          id={id}
          className={this.props.className}
          classes={{
            root: classes.root,
            error: classes.error,
            focused: select ? classes.selectFocused : classes.focused,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
            multiline: classes.multiline,
            inputAdornedStart: classes.adornedStart,
          }}
          inputComponent={mask ? TextMask : 'input'}
          inputProps={inputProps}
          startAdornment={startAdornment}
          labelWidth={0}
          onChange={(ev) => {
            if (
              !maxLength ||
              !this.props.value ||
              maxLength >= ev.target.value.length
            ) {
              onChange(ev);
            }
          }}
          placeholder={translateData(placeholder)}
          {...rest}
        />
        {showErrorText && (
          <FormHelperText
            classes={{
              root: classnames(classes.inputError, labelClassName),
            }}
          >
            <ErrorText aria-live="polite" id={`${id}-error`}>
              <WarningIcon src={warningRedSmall} alt="Error" />
              {` ${translateData(errorText)}`}
            </ErrorText>
          </FormHelperText>
        )}
        {!showErrorText && helperText && (
          <FormHelperText
            classes={{
              root: classnames(labelClassName),
            }}
          >
            <div>{helperText}</div>
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(styles)(Input);
