/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectSsrReqRoute } from 'containers/Landing/selectors';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import {
  isPurefloDomain,
  isSelfServeDomain,
  isAcquisitionDomain,
  getBrandedLogo,
} from 'utils/domainHelper';
import { Container } from 'components/Ui';
import { PurefloTheme } from 'components/ThemeProvider';
import Logo from 'components/ContentfulFields/Logo';
import Content from './Content';
import LearnMore from './LearnMore';
import PurefloLearnMore from './PurefloLearnMore';
import Contacts from './Contacts';
import Copyright from './Copyright';

const Footer = (
  {
    isCostcoWater,
    document: {
      fields: {
        logo,
        brandedLogos,
        learnMore,
        contact,
        content,
        background,
        copyright,
      },
    },
    ssrReqRoute,
  },
  { stylesTheme },
) => {
  const styles = require(`./styles/${stylesTheme}.scss`);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isPureflo = isPurefloDomain();
  const isMobileOrTablet = isMobile || isTablet;

  const isSelfServe = isSelfServeDomain();
  const isAcquisition = isAcquisitionDomain();

  const theLogo = getBrandedLogo(brandedLogos, logo, ssrReqRoute);

  return (
    <footer
      className={classnames(styles.footer, {
        [styles.selfServe]: isSelfServe,
      })}
      style={{
        backgroundImage:
          background && (!(isMobile || isTablet) || !isPureflo)
            ? `url(${background.fields.file.url}?fm=webp)`
            : null,
      }}
    >
      {(isMobile || isTablet) && isPureflo && (
        <div
          className={styles.waveBorder}
          style={{
            backgroundImage: background
              ? `url(${background.fields.file.url}?fm=webp)`
              : null,
          }}
        />
      )}
      <Container value="Footer" className={styles.container}>
        <div
          className={classnames(styles.logo, {
            [styles.costcoLogo]: isCostcoWater,
          })}
        >
          <Logo anchor fields={theLogo} />
        </div>
        <div
          className={classnames(
            { [styles.footerBlocks]: !isSelfServe },
            { [styles.costcoWater]: isCostcoWater },
          )}
        >
          {isAcquisition && <Content fields={content} />}
          <PurefloTheme>
            <PurefloLearnMore fields={learnMore} />
            <Contacts fields={contact} />
          </PurefloTheme>
        </div>
        {!isMobileOrTablet && (
          <Copyright isCostcoWater={isCostcoWater} fields={copyright} />
        )}
        {isCostcoWater && <LearnMore isMobile={isMobile} fields={learnMore} />}
        {isMobileOrTablet && (
          <Copyright isCostcoWater={isCostcoWater} fields={copyright} />
        )}
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  document: PropTypes.object,
  isCostcoWater: PropTypes.bool,
};

Footer.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  ssrReqRoute: selectSsrReqRoute(),
});

export default connect(mapStateToProps, null)(Footer);
