import React from 'react';
import { SvgIcon } from '@material-ui/core';

const SingleDownArrow = (props) => (
  <SvgIcon width="13" height="10.5" viewBox="0 0 10 7" {...props}>
    <g>
      <g>
        <path fill="#6c6c6c" d="M0 1.5L1.5 0 5 3.5 8.5 0 10 1.5l-5 5z" />
      </g>
    </g>
  </SvgIcon>
);

export default SingleDownArrow;
