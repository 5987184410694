import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { USER_REGION_KEY } from './constants';

export const selectUserRegion = () => (state) =>
  state.get(USER_REGION_KEY, Map());

export const selectPostalCode = () =>
  createSelector(selectUserRegion(), (data) => data.get('postalCode'));

export const selectBrand = () =>
  createSelector(selectUserRegion(), (data) => data.get('brand'));
export const selectBranch = () =>
  createSelector(selectUserRegion(), (data) => data.get('branch'));
