import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { IconButton, H3, Div, Img } from 'components/Ui';
import { CloseIconSvg } from 'components/Ui/CustomIcons';
import { useTheme } from '@material-ui/core/styles';
import { cartIcons, getImage } from 'images';
import { formatCurrency, getProductName } from 'utils/common';
import { isEquipment } from 'utils/categories';

import styles from './item.scss';

const ListItem = ({ product, onDelete }) => {
  const theme = useTheme();

  const handleRemove = () => {
    onDelete(product.id);
  };

  const image = getImage(product, 'images[0].fields.file.url', 'image.url');
  const qty = get(product, 'quantity', 1);
  // todo : why are we using qty here, and product.count elsewhere? MM

  return (
    <div className={styles.order}>
      <div className={styles.orderType}>
        {isEquipment(product.category) ? (
          <Img src={cartIcons.OneTimeIcon} alt="" />
        ) : (
          <Img src={cartIcons.Recurring} alt="" />
        )}
      </div>
      <div className={styles.image}>
        {image && <img src={`${image}?fm=webp`} alt="" />}
      </div>
      <div className={styles.description}>
        <H3 defaultText={'C75'}>{getProductName(product)}</H3>
        <div className={styles.info}>
          {product.size && <Div defaultText={'C76'}>{product.size}</Div>}
          {product.count && <Div defaultText={'C77'}>{product.count}</Div>}
          {product.price && (
            <div className={styles.price}>
              {formatCurrency(product.price * qty)}
            </div>
          )}
        </div>
      </div>
      <div className={styles.quantity}>
        <span>x{qty}</span>
      </div>
      <IconButton
        aria-label="Remove Item"
        className={styles.deleteIcon}
        onClick={handleRemove}
      >
        <CloseIconSvg
          color={theme.colors.heavySome}
          hoverColor={theme.colors.primaryBlue}
        />
      </IconButton>
    </div>
  );
};

ListItem.propTypes = {
  product: PropTypes.object,
  onDelete: PropTypes.func,
};

export default ListItem;
