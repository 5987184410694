import Content from './Content';
import withContent from './withContent';

export const getContentType = (content) => content?.sys?.contentType?.sys?.id;
export const isContentType = (c, type) => getContentType(c) === type;
export const isButton = (c) => isContentType(c, 'button');

export { withContent };

export default Content;
