import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel as MUIFormLabel, withStyles } from '@material-ui/core';
import { translateData } from 'utils/translation';
import { isWaterRegionalTheme } from 'utils/common';

const styles = (theme) => {
  const getStyle = require(`./styles/${theme.themeName}`);
  return getStyle(theme);
};

const FormLabel = (
  { classes, label, required, disableAsterisk, htmlFor, regularLabel = true },
  { stylesTheme: theme },
) => {
  const translatedLabel = translateData(label);
  const getRequiredLabel = () => (
    <>
      <span aria-hidden="true">*</span>
      {translatedLabel}
    </>
  );
  return (
    <MUIFormLabel
      label={translatedLabel}
      required={isWaterRegionalTheme(theme) ? false : required}
      classes={{
        root: classes.root,
        focused: classes.focused,
        error: classes.error,
        asterisk: disableAsterisk && classes.disabledAsterisk,
      }}
      htmlFor={htmlFor}
      error={!regularLabel}
    >
      {isWaterRegionalTheme(theme) && required && !disableAsterisk
        ? getRequiredLabel()
        : translatedLabel}
    </MUIFormLabel>
  );
};

FormLabel.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  disableAsterisk: PropTypes.bool,
  htmlFor: PropTypes.string,
  regularLabel: PropTypes.bool,
};

FormLabel.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default withStyles(styles)(FormLabel);
