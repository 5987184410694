/* eslint-disable camelcase */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import axios from 'axios';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import {
  Input,
  Select,
  Button,
  FormCheckbox,
  Form,
  PhoneInput,
} from 'components/Ui';
import FormError from 'components/Forms/FormError';
import * as options from 'utils/selectOptions';
import { isSsr } from 'utils/ssrHelper';
import { getDomainByHost } from 'utils/domainHelper';
import styles from './styles.scss';
class GetStartedForm extends PureComponent {
  state = {
    firstname: '',
    lastname: '',
    phone: '',
    phone_ext: '',
    emailAddress: '',
    companyname: '',
    address: '',
    city: '',
    province: '',
    state: '',
    zipcode: '',
    postalcode: '',
    preferred_contact_method: '',
    contact_regarding: '',
    Num_of_Locations: '',
    Num_of_People: '',
    comments: '',
    emailMe: false,
    lead_source: !isSsr() ? window.location.hostname : '',
    country: this.props.country || 'US',
    formConditions: {
      showErrorMessage: false,
      isLoading: false,
    },
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handlePhoneChange = (phone) => {
    this.setState({ phone });
  };

  handleSubmit = () => {
    const { country } = this.props;

    this.toggleLoading();

    const USFields = ['zipcode', 'state'].concat('formConditions');
    const CaFields = ['postalcode', 'province'].concat('formConditions');
    const data = omit(this.state, country === 'US' ? CaFields : USFields);

    axios
      .post(`/pardot/get_started?brand=${getDomainByHost(3).brand}`, data)
      .then(() => {
        this.toggleLoading();
        this.props.handleSubmit();
      })
      .catch(() => {
        this.setState({ formConditions: { isLoading: false } });
      });
  };

  showError = () => {
    if (!this.state.showErrorMessage) {
      this.setState((prevState) => ({
        formConditions: { ...prevState.formConditions, showErrorMessage: true },
      }));
    }
  };

  toggleLoading = () => {
    this.setState((prevState) => ({
      formConditions: { isLoading: !prevState.isLoading },
    }));
  };

  handleEmailNewsChange = () => {
    this.setState((prevState) => ({ emailMe: !prevState.emailMe }));
  };

  render() {
    const {
      firstname,
      lastname,
      phone,
      phone_ext,
      emailAddress,
      companyname,
      address,
      city,
      province,
      state,
      zipcode,
      postalcode,
      preferred_contact_method,
      contact_regarding,
      Num_of_Locations,
      Num_of_People,
      comments,
      formConditions: { showErrorMessage, isLoading },
      emailMe,
    } = this.state;

    const isUS = this.state.country === 'US';

    return (
      <Form
        className={styles.container}
        onError={this.showError}
        onSubmit={this.handleSubmit}
        color="grey"
      >
        <div className={styles.title}>
          Please complete this form so that we may best assist you:
        </div>
        <Grid container spacing={4} className={styles.form}>
          <Grid item xs={12} md={6}>
            <Input
              label="First Name"
              validators={['required']}
              errorMessages={['']}
              value={firstname}
              onChange={this.handleChange}
              name="firstname"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Last Name"
              validators={['required']}
              errorMessages={['']}
              value={lastname}
              onChange={this.handleChange}
              name="lastname"
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <PhoneInput
              label="Phone"
              name="phone"
              value={phone}
              onChange={this.handlePhoneChange}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <Input
              label="Ext"
              value={phone_ext}
              onChange={this.handleChange}
              name="phone_ext"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Email"
              validators={['required', 'isEmail']}
              errorMessages={['', 'Email is not valid']}
              value={emailAddress}
              onChange={this.handleChange}
              name="emailAddress"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Company Name"
              value={companyname}
              onChange={this.handleChange}
              name="companyname"
            />
          </Grid>
          <Grid item md={8} />
          <Grid item xs={12} md={4}>
            <Input
              label="Address"
              validators={['required']}
              errorMessages={['']}
              value={address}
              onChange={this.handleChange}
              name="address"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              label="City"
              validators={['required']}
              errorMessages={['']}
              value={city}
              onChange={this.handleChange}
              name="city"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            {isUS ? (
              <Select
                label="State"
                validators={['required']}
                errorMessages={['']}
                value={state}
                items={options.US_REGIONS}
                onChange={this.handleChange}
                name="state"
              />
            ) : (
              <Select
                label="Province"
                validators={['required']}
                errorMessages={['']}
                value={province}
                items={options.CANADA_REGIONS}
                onChange={this.handleChange}
                name="province"
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {isUS ? (
              <Input
                label="ZIP"
                validators={['required', 'zip']}
                errorMessages={['', 'ZIP is not valid']}
                value={zipcode}
                onChange={this.handleChange}
                name="zipcode"
              />
            ) : (
              <Input
                label="Postal Code"
                validators={['required']}
                errorMessages={['']}
                value={postalcode}
                onChange={this.handleChange}
                name="postalcode"
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Preferred contact method"
              validators={['required']}
              errorMessages={['']}
              value={preferred_contact_method}
              items={options.CONTACT_METHODS}
              onChange={this.handleChange}
              name="preferred_contact_method"
            />
          </Grid>
          <Grid item md={8} />
          <Grid item xs={12} md={3}>
            <Select
              label="Are you a"
              value={contact_regarding}
              items={options.CONTACT_REGARDING}
              onChange={this.handleChange}
              name="contact_regarding"
            />
          </Grid>
          <Grid item xs={6} md={3} style={{ maxWidth: 151 }}>
            <Select
              label="No. of Locations"
              value={Num_of_Locations}
              items={options.LOCATIONS_NUMBER}
              onChange={this.handleChange}
              name="Num_of_Locations"
            />
          </Grid>
          <Grid item xs={6} md={3} style={{ maxWidth: 151 }}>
            <Select
              label="No. of People"
              value={Num_of_People}
              items={options.PEOPLE_NUMBER}
              onChange={this.handleChange}
              name="Num_of_People"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              label="Question/Comment"
              multiline
              rows={8}
              value={comments}
              onChange={this.handleChange}
              name="comments"
              minHeight={165}
            />
          </Grid>
          <Grid item className={styles.formFooter}>
            <FormCheckbox
              className={styles.checkbox}
              checked={emailMe}
              onChange={this.handleEmailNewsChange}
              label="Please email news and offers"
            />
            <div className={styles.callInfo}>
              WANT TO CALL NOW?&nbsp;
              <a href="tel:18002016218">1-800-201-6218</a>
            </div>
            <div className={styles.submit}>
              <Button text="SUBMIT" loading={isLoading} disabled={isLoading} />
            </div>
            <div className={styles.requiredText}>* All fields required</div>
          </Grid>
        </Grid>
        {showErrorMessage && (
          <Fragment>
            <FormError className={classNames(styles.error, styles.errorTop)} />
            <FormError
              className={classNames(styles.error, styles.errorBottom)}
            />
          </Fragment>
        )}
      </Form>
    );
  }
}

GetStartedForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
};

export default GetStartedForm;
