import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Button } from 'components/Ui';
import RichText from 'components/RichText';
import Dialog from 'components/Ui/Dialog';
import { dataLayerPush } from 'utils/tracking';
import { getCurrencyCode } from 'utils/translation';
import { formatCurrency } from 'utils/common';

import styles from './styles.scss';

const ProductBundle = ({
  document,
  onClick,
  isAuthenticated,
  isFamilyBundle = false,
  isAllProductsPage = false,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const renderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          href={node.data.uri}
          onClick={(e) => {
            e.preventDefault();
            if (node.data.uri === '#bundleDetails') {
              setShowDetails(true);
            }
          }}
        >
          {children}
        </a>
      ),
    },
  };
  const detailsDescriptionRenderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [BLOCKS.HEADING_3]: () => null,
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          href="#s"
          onClick={(e) => {
            e.preventDefault();
            if (node.data.uri === '#bundleDetails') {
              setShowDetails(true);
            }
          }}
        >
          {children}
        </a>
      ),
    },
  };
  const onCloseDetails = () => {
    setShowDetails(false);
  };
  useEffect(() => {
    if (showDetails) {
      try {
        dataLayerPush(
          isAuthenticated ? 'SelfServe' : 'Acquisition',
          {
            event: 'view_item',
            ecommerce: {
              currency: getCurrencyCode(),
              items: document.items.map((p) => ({
                name: document.title,
                id: p.fields.product.fields.itemNumber,
                price: p.fields.quantity * p.fields.product.fields.price,
                brand: p.fields.product.fields.brand,
                bundle_name: document.entryTitle,
                category: `Water Bundles | ${p.fields.product.fields.category}`,
                variant: p.fields.product.fields.size,
                list: isFamilyBundle
                  ? 'Quick Shop Water Bundles'
                  : isAllProductsPage
                  ? 'All Products Water Bundles'
                  : 'Water Bundles',
                quantity: p.fields.quantity,
                position: 1,
              })),
            },
          },
          'dlA22',
        );
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    }
  }, [showDetails]);

  const calculateBundlePrice = (items) =>
    formatCurrency(
      items
        .map((p) => p.fields.quantity * p.fields.product.fields.price)
        .reduce((a, b) => a + b, 0),
    );

  return (
    <>
      {document && (
        <div
          className={`${styles.productBundleWrapper} ${
            isFamilyBundle ? styles.familyBundle : ''
          }`}
          onClick={() => setShowDetails(true)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setShowDetails(true);
            }
          }}
          role="button"
          tabIndex="0"
        >
          {document.header && (
            <header>
              <RichText richText={document.header} styles={styles} />
            </header>
          )}
          <div className={styles.productBundle}>
            <div>
              <img
                className={styles.image}
                src={`${document.image.fields.file.url}?fm=webp`}
                alt={document.image.fields.description}
              />
              <div>
                <h2 className={styles.productTitle}>{document.title}</h2>
                {isFamilyBundle && (
                  <div className={styles.price}>
                    {calculateBundlePrice(document.items)}
                  </div>
                )}
              </div>
            </div>
            {!isFamilyBundle &&
              document.items.map((p, index) => (
                <div key={index} className={styles.info}>
                  {p.fields.quantity} x {p.fields.product.fields.size}
                </div>
              ))}
            {!isFamilyBundle && (
              <div className={styles.price}>
                {calculateBundlePrice(document.items)}
              </div>
            )}
            <Button
              className={styles.btn}
              text="Add to Cart"
              onClick={onClick}
            />
          </div>
          {document.footer && (
            <footer>
              {documentToReactComponents(document.footer, renderOptions)}
            </footer>
          )}
        </div>
      )}
      <Dialog
        size="small"
        isAutoClose={false}
        isCloseIcon
        open={showDetails}
        onClose={onCloseDetails}
      >
        <div
          data-tracking="product-bundle-modal"
          className={styles.bundleDetails}
        >
          <div className={styles.bundleContent}>
            <div>
              <header>
                <h2>{document.title}</h2>
              </header>
              <div className={styles.bundleItems}>
                {document.items.map((p, index) => (
                  <div key={index}>
                    <div className={styles.info}>
                      {p.fields.quantity} x {p.fields.product.fields.size}
                    </div>
                    <RichText
                      richText={p.fields.product.fields.description}
                    ></RichText>
                  </div>
                ))}
              </div>
              <div>
                {documentToReactComponents(
                  document.header,
                  detailsDescriptionRenderOptions,
                )}
              </div>
              <div className={styles.price}>
                {calculateBundlePrice(document.items)}
              </div>
              <Button
                className={styles.btn}
                text="Add to Cart"
                onClick={onClick}
              />
            </div>
            <div>
              <img
                className={styles.image}
                src={`${document.image.fields.file.url}?fm=webp`}
                alt={document.image.fields.description}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

ProductBundle.propTypes = {
  document: PropTypes.object,
};

export default ProductBundle;
