import * as types from './constants';

export function loadCreditCards() {
  return {
    type: types.LOAD_CREDIT_CARDS,
  };
}

export function loadBankAccounts() {
  return {
    type: types.LOAD_BANK_ACCOUNTS,
  };
}

export const updateCustomerNotes = (payload) => ({
  type: types.UPDATE_CUSTOMER_NOTES,
  payload,
});

export const fetchDeliveryNotes = () => ({
  type: types.FETCH_DELIVERY_NOTES,
});

export const fetchCustomerNotes = () => ({
  type: types.FETCH_CUSTOMER_NOTES,
});

export function addCreditCard(payload) {
  return {
    type: types.ADD_CREDIT_CARD,
    payload,
  };
}

export function updateCreditCard(payload) {
  return {
    type: types.UPDATE_CREDIT_CARD,
    payload,
  };
}

export function updateAutoPay(payload) {
  return {
    type: types.UPDATE_AUTO_PAY,
    payload,
  };
}

export function deleteCreditCard(id) {
  return {
    type: types.DELETE_CREDIT_CARD,
    payload: id,
  };
}

export function addBankAccount(payload) {
  return {
    type: types.ADD_BANK_ACCOUNT,
    payload,
  };
}

export function updateBankAccount(payload) {
  return {
    type: types.UPDATE_BANK_ACCOUNT,
    payload,
  };
}

export function deleteBankAccount(id) {
  return {
    type: types.DELETE_BANK_ACCOUNT,
    payload: id,
  };
}

export function getBillingSummary() {
  return {
    type: types.GET_BILLING_SUMMARY,
  };
}

export function setCurrentPaymentMethod(id) {
  return {
    type: types.SET_CURRENT_PAYMENT_METHOD,
    payload: id,
  };
}

export function fetchPaymentMethods() {
  return {
    type: types.FETCH_PAYMENT_METHODS_REQUEST,
  };
}

export function loadStatementSettings() {
  return {
    type: types.GET_STATEMENT_SETTINGS,
  };
}

export function updateStatementSettings(payload) {
  return {
    type: types.UPDATE_STATEMENT_SETTINGS,
    payload,
  };
}

export function showStatementSettings() {
  return {
    type: types.SHOW_STATEMENT_SETTINGS,
  };
}

export function hideStatementSettings() {
  return {
    type: types.HIDE_STATEMENT_SETTINGS,
  };
}

export function showStatementSuccess() {
  return {
    type: types.SHOW_STATEMENT_SETTINGS_SUCCESS,
  };
}

export function hideStatementSuccess() {
  return {
    type: types.HIDE_STATEMENT_SETTINGS_SUCCESS,
  };
}

export function resetAddCardSucessful() {
  return {
    type: types.RESET_ADD_CARD,
  };
}

export function resetPaymentRemovalSucessful() {
  return {
    type: types.RESET_PAYMENT_REMOVAL,
  };
}

export function getBillingAccount() {
  return {
    type: types.GET_BILLING_ACCOUNT,
  };
}

export function loadBillingAccounts() {
  return {
    type: types.LOAD_BILLING_ACCOUNTS,
  };
}

export function closeInvoiceDrawer() {
  return {
    type: types.CLOSE_INVOICE_DRAWER,
  };
}

export function closeUserDetailsDrawer() {
  return {
    type: types.CLOSE_USER_DETAILS_DRAWER,
  };
}

export function closeRemoveEmailsDrawer() {
  return {
    type: types.CLOSE_REMOVE_EMAIL_DRAWER,
  };
}

export function removeOneTimeCreditCard() {
  return {
    type: types.REMOVE_ONE_TIME_CREDIT_CARD,
  };
}

export function closeEditBillingDrawer() {
  return {
    type: types.CLOSE_EDIT_BILLING_DRAWER,
  };
}

export const closeAddAuthorizedUserDrawer = () => ({
  type: types.CLOSE_ADD_AUTHORIZED_USER_DRAWER,
});

export const resetPayMyBillError = () => ({
  type: types.RESET_PAY_MY_BILL_ERROR,
});

export const resetBillingInformationError = () => ({
  type: types.RESET_BILLING_INFORMATION_ERROR,
});

export const closeCustomerNotesDrawer = () => ({
  type: types.CLOSE_CUSTOMER_NOTES_DRAWER,
});

export const updateBillingAddress = (payload) => ({
  type: types.UPDATE_BILLING_ADDRESS,
  payload,
});

export const updatePassword = (payload) => ({
  type: types.UPDATE_PASSWORD,
  payload,
});

export const deleteAssociatedBillingAccount = (payload) => ({
  type: types.DELETE_BILLING_ACCOUNT,
  payload,
});

export const updateLoggedInUserDetails = (payload) => ({
  type: types.UPDATE_USER_DETAILS,
  payload,
});

export const addAuthorizedUser = (payload) => ({
  type: types.ADD_AUTHORIZED_USER,
  payload,
});
