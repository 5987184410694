import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { AUTHENTICATION_PAGE_KEY } from 'containers/Authentication/constants';

const selectAuthenticationDomain = () => (state) =>
  state.get(AUTHENTICATION_PAGE_KEY, Map());

// LOG IN

const selectLogin = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('logIn'),
  );

export const selectLoginFetching = () =>
  createSelector(selectLogin(), (substate) => substate.get('isFetching'));

export const selectLoginError = () =>
  createSelector(selectLogin(), (substate) => substate.get('error'));

// SIGN UP

const selectSignUp = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('signUp'),
  );

export const selectSignUpFetching = () =>
  createSelector(selectSignUp(), (substate) => substate.get('isFetching'));

export const selectSignUpError = () =>
  createSelector(selectSignUp(), (substate) => substate.get('error'));

// RESTORE PASSWORD

const selectRestorePassword = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('restorePassword'),
  );

export const selectRestorePasswordFetching = () =>
  createSelector(selectRestorePassword(), (substate) =>
    substate.get('isFetching'),
  );

export const selectRestorePasswordError = () =>
  createSelector(selectRestorePassword(), (substate) => substate.get('error'));

export const selectRestorePasswordMessage = () =>
  createSelector(selectRestorePassword(), (substate) =>
    substate.get('message'),
  );

export const selectShowSuccess = () =>
  createSelector(selectRestorePassword(), (substate) =>
    substate.get('showSuccess'),
  );

export const selectRestoreEmail = () =>
  createSelector(selectRestorePassword(), (substate) => substate.get('email'));

// CHANGE PASSWORD

const selectChangePassword = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('changePassword'),
  );

export const selectChangePasswordFetching = () =>
  createSelector(selectChangePassword(), (substate) =>
    substate.get('isFetching'),
  );

export const selectChangePasswordError = () =>
  createSelector(selectChangePassword(), (substate) => substate.get('error'));

export const selectChangePasswordMessage = () =>
  createSelector(selectChangePassword(), (substate) => substate.get('message'));

//

const selectIsFetching = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('isFetching'),
  );

const selectIsAuthenticated = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('isAuthenticated'),
  );

const selectIsAuthenticating = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('isAuthenticating'),
  );

const selectIsInitiated = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('isInitiated'),
  );

const selectAuthError = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('error'),
  );

const selectIsMobileAppView = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('isMobileAppView'),
  );

export {
  selectIsFetching,
  selectIsAuthenticated,
  selectIsInitiated,
  selectAuthError,
  selectIsMobileAppView,
  selectIsAuthenticating,
};

export const selectUserInfo = () =>
  createSelector(selectAuthenticationDomain(), (substate) => {
    const userInfo = substate.get('user');
    return userInfo ? userInfo.toJS() : {};
  });

export const selectUserId = () =>
  createSelector(selectAuthenticationDomain(), (authInfo) =>
    authInfo.getIn(['user', 'sub']),
  );

export const selectDefaultServiceLocationId = () =>
  createSelector(selectAuthenticationDomain(), (authInfo) =>
    authInfo.getIn(['user', 'defaultServiceLocationId']),
  );

export const selectUserEmail = () =>
  createSelector(selectAuthenticationDomain(), (authInfo) =>
    authInfo.getIn(['user', 'name']),
  );

export const selectUserNickname = () =>
  createSelector(selectAuthenticationDomain(), (authInfo) =>
    authInfo.getIn(['user', 'nickname']),
  );

export const selectIsLogoutInitiated = () =>
  createSelector(selectAuthenticationDomain(), (substate) =>
    substate.get('isLogoutInitiated'),
  );

export const selectIsExcluded = () =>
  createSelector(selectAuthenticationDomain(), (authInfo) => {
    const excludeFlag = authInfo.getIn(['user', 'excludeFlag']);
    return excludeFlag === 'Y';
  });
