import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIsMobile } from 'utils/hooks';
import { Dialog, P, Div } from 'components/Ui';
import { dataLayerPush } from 'utils/tracking';
import { translateChildren } from 'utils/translation';
import { InfoIcon, warningRed } from 'images';
import styles from './styles.scss';

const MessageDialog = ({
  title,
  message,
  open,
  isCloseIcon,
  onClose,
  isErrorMessage,
}) => {
  const isMobile = useIsMobile();

  const WarningIcon = styled.img`
    margin-bottom: 2px;
  `;

  const theMessage = isErrorMessage ? (
    <Div className={styles.errorMessage}>
      <WarningIcon src={warningRed} alt="Error" /> {message}
    </Div>
  ) : (
    message
  );

  if (open) {
    dataLayerPush(
      'Acquisition, SelfServe',
      {
        event: 'showMessage',
        title: translateChildren(title),
        message: translateChildren(message),
        isErrorMessage,
        session_id: window?.DD_RUM?.getInternalContext().session_id,
      },
      'dlM',
    );
  }

  return open ? (
    <Dialog
      fullScreen={isMobile}
      isAutoClose={false}
      isCloseIcon={isCloseIcon}
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
    >
      <div className={styles.container}>
        <div
          className={!isMobile ? styles.subContainer : styles.mobileContainer}
        >
          <div className={styles.imageContainer}>
            <img src={InfoIcon} alt=""></img>
          </div>
          {title && (
            <Div className={styles.headerContainer} id="dialog-title">
              {title}
            </Div>
          )}
          {message && (
            <div className={styles.textContainer}>
              <P>{theMessage}</P>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  ) : null;
};

MessageDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isErrorMessage: PropTypes.bool,
  open: PropTypes.bool,
  isCloseIcon: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

MessageDialog.defaultProps = {
  title: 'Sorry!',
  isErrorMessage: false,
  isCloseIcon: true,
};

export default MessageDialog;
