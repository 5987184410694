import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  subLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    paddingLeft: '6px',
    [theme.breakpoints.down('sm')]: {
      color: 'inherit',
      paddingLeft: 0,
      fontSize: '0.625rem',
      lineHeight: 1,
    },
  },
}));

const TabSubLabel = ({ label, subLabel }) => {
  const classes = useStyles();

  return (
    <Fragment>
      {label}: <span className={classes.subLabel}>{subLabel}</span>
    </Fragment>
  );
};

TabSubLabel.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
};

export default TabSubLabel;
