import React, { useState, useMemo, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, RadioGroup } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import {
  Input,
  Radio,
  Select,
  Button,
  Checkbox,
  Div,
  Span,
} from 'components/Ui';
import { US_REGIONS } from 'utils/selectOptions';
import { useIsTabletBelow } from 'utils/hooks';
import styled from 'styled-components';

import {
  BILLING_TYPE_ANOTHER,
  BILLING_TYPE_SHIPPING,
} from 'containers/PrimoCheckoutPage/constants';
import commonStyles from 'containers/PrimoCheckoutPage/styles.scss';
import { selectCartData } from 'containers/Cart/selectors';
import { isValidCardNumber } from 'utils/formValidators';
import { dataLayerPush } from 'utils/tracking';
import { isCanadianBrand, translateData } from 'utils/translation';
import { provinceValues } from 'utils/constants';
import { requiredCardFields, requiredBillingFields } from '../utils';
import CreditCardForm from './CreditCardForm';

import styles from './styles.scss';

const ScreenReader = styled.p`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const PaymentForm = ({
  onSubmit,
  onBack,
  storedValues,
  errors,
  isProcessing,
  changeCanGoForward,
  forwardedRef,
  isDrawer = false,
}) => {
  const defaultValues = useMemo(
    () => ({
      cardholderName: '',
      cardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      address1: '',
      address2: '',
      city: '',
      stateOrProvinceCode: '',
      postalCode: '',
      countryCode: isCanadianBrand() ? 'CA' : 'US',
      billingType: BILLING_TYPE_SHIPPING,
      autoPay: false,
      displayAutopayMessage: false,
      eInvoice: true,
      statementDeliveryMethod: 'EPDF',
    }),
    [],
  );

  const [isCostco, setIsCostco] = useState(false);
  const [values, changeValues] = useState({
    ...defaultValues,
    ...storedValues,
  });
  const [editPayment, changeEditPayment] = useState(false);
  const showPaymentView = useMemo(
    () => Boolean(storedValues.last4 && !editPayment),
    [editPayment, storedValues.last4],
  );
  const isFilledCard =
    showPaymentView ||
    (!showPaymentView && requiredCardFields.every((field) => values[field]));
  const isFilledBilling =
    values.billingType === BILLING_TYPE_SHIPPING ||
    (values.billingType === BILLING_TYPE_ANOTHER &&
      requiredBillingFields.every((field) => values[field]));
  const isFilled =
    isFilledBilling && isFilledCard && isValidCardNumber(values.cardNumber);
  const isTabletBelow = useIsTabletBelow();
  const gridSpacing = useMemo(() => (isTabletBelow ? 1 : 4), [isTabletBelow]);

  const cartData = useSelector(selectCartData());

  useEffect(() => {
    const deliveryTime =
      cartData &&
      cartData.deliverySetup &&
      cartData.deliverySetup.deliveryTime === 'morning'
        ? 'Morning'
        : 'Afternoon';

    dataLayerPush(
      'Acquisition',
      {
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: { step: '2', option: deliveryTime },
          },
        },
      },
      'dlA18',
    );

    dataLayerPush(
      'Acquisition',
      {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 3 },
          },
        },
      },
      'dlA19',
    );
  }, []);

  useEffect(() => {
    if (!isDrawer) {
      changeCanGoForward(isFilled);
    }
  }, [isFilled]);

  useEffect(() => {
    setIsCostco(window.isCostcoWater);
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'postalCode') {
      value = value.toUpperCase();
    }
    changeValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    if (!values.autoPay) {
      changeValues({ ...values, displayAutopayMessage: true });
      return;
    }
    onSubmit({ values, isSaveCard: !showPaymentView });
  };

  const handleAutoPay = (checked) => {
    changeValues({
      ...values,
      autoPay: checked,
      displayAutopayMessage: !checked,
    });
  };

  const handleEInvoice = (checked) => {
    changeValues({
      ...values,
      eInvoice: checked,
      statementDeliveryMethod: checked ? 'EPDF' : 'MAIL',
    });
  };

  const handleShowEdit = () => {
    if (!isDrawer) {
      changeValues({
        ...values,
        cardholderName: defaultValues.cardholderName,
        cardNumber: defaultValues.cardNumber,
        expirationMonth: defaultValues.expirationMonth,
        expirationYear: defaultValues.expirationYear,
      });
    } else {
      changeValues({
        ...values,
        cardNumber: defaultValues.cardNumber,
      });
    }

    changeEditPayment(true);
  };

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      ref={forwardedRef}
      className={!isDrawer ? styles.genericForm : styles.drawerForm}
      role="form"
    >
      {!isDrawer ? (
        <Fragment>
          <div className={styles.block}>
            <div className={commonStyles.title}>
              {showPaymentView ? (
                <div>
                  <Span>Your Payment Method</Span>:{' '}
                  <Button
                    onClick={handleShowEdit}
                    link
                    type="button"
                    text="Edit"
                    className={commonStyles.editLink}
                  />
                </div>
              ) : (
                <Div>{!isCostco ? 'Add a Credit/Debit Card:' : ''}</Div>
              )}
            </div>
            {showPaymentView ? (
              <div className={styles.paymentView}>
                <div>
                  <Span>Credit Card ending in</Span> {storedValues.last4}
                </div>
              </div>
            ) : (
              <Fragment>
                <Grid item sm={12} md={12}>
                  <div className={styles.requiredText}>
                    <Span>
                      Fields marked with an asterisk (*) are required.
                    </Span>
                  </div>
                </Grid>
                <CreditCardForm
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  isDrawer={isDrawer}
                />
              </Fragment>
            )}
          </div>
          <div className={styles.block}>
            <Div className={commonStyles.title}>Billing Address:</Div>
            <RadioGroup
              name="billingType"
              value={values.billingType}
              onChange={handleChange}
              row
              className={styles.radioGroup}
            >
              <Radio
                value={BILLING_TYPE_SHIPPING}
                label="Use my delivery address"
              />
              <Radio value={BILLING_TYPE_ANOTHER} label="Use another address" />
            </RadioGroup>
            {values.billingType === BILLING_TYPE_ANOTHER && (
              <Fragment>
                <Grid
                  {...(!isDrawer ? { container: true } : {})}
                  spacing={gridSpacing}
                >
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Street Address:"
                      ariaLabel="Street address"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.address1}
                      autocomplete="billing address-line1"
                      id="address-street-one"
                    />
                  </Grid>
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Apt/Ste/Other:"
                      ariaLabel="Apartment Number, Suite Number, or Other"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      error={errors.address2}
                      autocomplete="billing address-line2"
                      id="address-street-two"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="City:"
                      ariaLabel="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.city}
                      autocomplete="billing address-level2"
                      id="address-city"
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    {
                      <Select
                        placeholder={'Select State'}
                        ariaLabel={'Select State'}
                        label={'State:'}
                        boldLabel
                        value={values.stateOrProvinceCode}
                        items={isCanadianBrand() ? provinceValues : US_REGIONS}
                        onChange={handleChange}
                        name="stateOrProvinceCode"
                        icon="primaryArrow"
                        validators={['required']}
                        errorMessages={['']}
                        error={errors.stateOrProvinceCode}
                        autocomplete="billing address-level1"
                        id="address-state"
                      />
                    }
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <Input
                      label="ZIP code:"
                      ariaLabel="ZIP or Postal Code"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      validators={['required', 'zip']}
                      errorMessages={[
                        '',
                        translateData('Zip code is not valid'),
                      ]}
                      error={errors.postalCode}
                      autocomplete="billing postal-code"
                      id="address-zip"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}></Grid>
              </Fragment>
            )}
          </div>
          <div className={styles.block}>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                className={styles.checkbox}
                label="Auto Pay"
                ariaLabel="Enroll in Auto Pay"
                ariaDescribedby="autopay-message autopay-description"
                checked={values.autoPay}
                onChange={handleAutoPay}
                validators={['autoPay']}
                id="autopay"
              />
              {values.displayAutopayMessage && (
                <Div
                  className={styles.autoPayErrorMessage}
                  id="autopay-message"
                >
                  AutoPay is required to complete all web orders. Feel free to
                  contact our Customer Service Department at 1-800-201-6218 to
                  complete any non-AutoPay orders.
                </Div>
              )}
            </div>
            <Div className={styles.checkboxText} id="autopay-description">
              By checking here I am authorizing you to automatically charge the
              credit or debit card I have provided above each billing period
              (which is generally every 28 days) in the amounts (which amounts
              may change each billing period for various reasons, including
              changes to the products and services I order) and by the due dates
              reflected on the invoices you will provide me (AutoPay). I
              understand that I can cancel AutoPay at any time by calling you at
              1-800-492-8377.
            </Div>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                defaultChecked
                className={styles.checkbox}
                label="eInvoice"
                checked={values.eInvoice}
                onChange={handleEInvoice}
                id="einvoice"
                ariaLabel="Enroll in Electronic Invoicing"
                ariaDescribedby="einvoice-description"
                required
              />
            </div>
            <Div className={styles.checkboxText} id="einvoice-description">
              By checking here I am authorizing you to send all of my invoices
              to me electronically in lieu of in paper form. I also acknowledge
              that I will avoid a $4.00 fee which applies to each paper invoice
              mailed to customers.
            </Div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={styles.block}>
            <div className={commonStyles.title}>
              {showPaymentView ? (
                <div>
                  <Span>Your Payment Method</Span>:{' '}
                  <Button
                    onClick={handleShowEdit}
                    link
                    type="button"
                    text="Edit"
                    className={commonStyles.editLink}
                  />
                </div>
              ) : (
                <Div>{!isCostco ? 'Add a Credit/Debit Card:' : ''}</Div>
              )}
            </div>
            {showPaymentView ? (
              <div className={styles.paymentView}>
                <div>
                  <Span>Credit Card ending in</Span> {storedValues.last4}
                </div>
              </div>
            ) : (
              <Fragment>
                <Grid item sm={12} md={12}>
                  <div className={styles.requiredText}>
                    <Span>
                      Fields marked with an asterisk (*) are required.
                    </Span>
                  </div>
                </Grid>
                <CreditCardForm
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  isDrawer={isDrawer}
                />
              </Fragment>
            )}
          </div>
          <div className={styles.block}>
            <Div className={commonStyles.title}>Billing Address:</Div>
            <RadioGroup
              name="billingType"
              value={values.billingType}
              onChange={handleChange}
              row
              className={styles.radioGroup}
            >
              <Radio
                value={BILLING_TYPE_SHIPPING}
                label="Use my delivery address"
              />
              <Radio value={BILLING_TYPE_ANOTHER} label="Use another address" />
            </RadioGroup>
            {values.billingType === BILLING_TYPE_ANOTHER && (
              <Fragment>
                <Grid
                  {...(!isDrawer ? { container: true } : {})}
                  spacing={gridSpacing}
                >
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Street Address:"
                      ariaLabel="Street address"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.address1}
                      autocomplete="billing address-line1"
                      id="address-street-one"
                    />
                  </Grid>
                  <Grid item sm={12} md={isDrawer ? 12 : 6}>
                    <Input
                      label="Apt/Ste/Other:"
                      ariaLabel="Apartment Number, Suite Number, or Other"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      error={errors.address2}
                      autocomplete="billing address-line2"
                      id="address-street-two"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12} md={12}>
                    <Input
                      label="City:"
                      ariaLabel="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.city}
                      autocomplete="billing address-level2"
                      id="address-city"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm={12} md={6}>
                    <Select
                      placeholder={'Select State'}
                      ariaLabel={'Select State'}
                      label={'State:'}
                      boldLabel
                      value={values.stateOrProvinceCode}
                      items={isCanadianBrand() ? provinceValues : US_REGIONS}
                      onChange={handleChange}
                      name="stateOrProvinceCode"
                      icon="primaryArrow"
                      validators={['required']}
                      errorMessages={['']}
                      error={errors.stateOrProvinceCode}
                      autocomplete="billing address-level1"
                      id="address-state"
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Input
                      label="ZIP code:"
                      ariaLabel="ZIP or Postal Code"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      validators={['required', 'zip']}
                      errorMessages={[
                        '',
                        translateData('Zip code is not valid'),
                      ]}
                      error={errors.postalCode}
                      autocomplete="billing postal-code"
                      id="address-zip"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}></Grid>
              </Fragment>
            )}
          </div>
          <div className={styles.block}>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                className={styles.checkbox}
                label="Auto Pay"
                ariaLabel="Enroll in Auto Pay"
                ariaDescribedby="autopay-message autopay-description"
                checked={values.autoPay}
                onChange={handleAutoPay}
                validators={['autoPay']}
              />
              {values.displayAutopayMessage && (
                <Div
                  className={styles.autoPayErrorMessage}
                  id="autopay-message"
                >
                  AutoPay is required to complete all web orders. Feel free to
                  contact our Customer Service Department at 1-800-201-6218 to
                  complete any non-AutoPay orders.
                </Div>
              )}
            </div>
            <Div className={styles.checkboxText} id="autopay-description">
              By checking here I am authorizing you to automatically charge the
              credit or debit card I have provided above each billing period
              (which is generally every 28 days) in the amounts (which amounts
              may change each billing period for various reasons, including
              changes to the products and services I order) and by the due dates
              reflected on the invoices you will provide me (AutoPay). I
              understand that I can cancel AutoPay at any time by calling you at
              1-800-492-8377.
            </Div>
            <div className={styles.autoPay}>
              <Checkbox
                isRectangle
                defaultChecked
                className={styles.checkbox}
                label="eInvoice"
                checked={values.eInvoice}
                onChange={handleEInvoice}
                id="einvoice"
                ariaLabel="Enroll in Electronic Invoicing"
                ariaDescribedby="einvoice-description"
                required
              />
            </div>
            <Div className={styles.checkboxText} id="einvoice-description">
              By checking here I am authorizing you to send all of my invoices
              to me electronically in lieu of in paper form. I also acknowledge
              that I will avoid a $4.00 fee which applies to each paper invoice
              mailed to customers.
            </Div>
          </div>
        </Fragment>
      )}

      <div
        className={classnames(
          commonStyles.actions,
          styles.block,
          styles.actions,
        )}
      >
        <ScreenReader>
          Form Navigation, Select the Back button to return to the My First
          Delivery Setup form. Select continue to the Review My Order form.
        </ScreenReader>
        <Button
          text="Back"
          color="gray"
          type="button"
          onClick={onBack}
          disabled={isProcessing}
        />
        <Button text="Continue" disabled={!isFilled} loading={isProcessing} />
      </div>
    </ValidatorForm>
  );
};

PaymentForm.propTypes = {
  storedValues: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  changeCanGoForward: PropTypes.func,
  isProcessing: PropTypes.bool,
  forwardedRef: PropTypes.object,
  isDrawer: PropTypes.bool,
};

export default PaymentForm;
