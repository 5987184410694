import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { accountIcons } from 'images';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Input } from 'components/Ui';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { submitZip } from 'containers/WaterStartOrder/utils';

import styles from './styles.scss';

const SignInForm = () => {
  const [zipCode, changeZipCode] = useState(
    useSelector(selectPostalCode()) || '',
  );
  const [loading, changeLoading] = useState(false);
  const [showError, changeShowError] = useState(false);

  const handleChange = ({ target: { value } }) => {
    changeZipCode(value);
  };

  const handleSubmit = async () => {
    changeLoading(true);
    changeShowError(false);

    try {
      const { isNonServiceable } = await submitZip({
        zip: zipCode,
        target: 'signIn',
      });

      if (isNonServiceable) {
        throw new Error();
      }
    } catch (e) {
      changeShowError(true);
      changeLoading(false);
    }
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.header}>
        <img src={accountIcons.MessageGreen} alt="message icon" />
        <h1>Welcome back!</h1>
        <span>
          To sign in, please confirm your ZIP Code so we can give you a
          customized experience for the area you live, including the products
          available to you. Once your ZIP Code is confirmed, you will be able to
          log in as usual. Please bookmark that page for easy access to your
          sign in anytime.
        </span>
      </div>
      <div className={styles.inputContainer}>
        <Input
          value={zipCode}
          onChange={handleChange}
          label="ZIP Code:"
          validators={['required', 'zip']}
          errorMessages={['', 'Please enter a valid ZIP Code.']}
          error={
            showError &&
            'The ZIP Code entered does not match a serviceable area, please check that you’ve entered the correct ZIP Code for your delivery address.'
          }
          errorPlacement="top"
          labelClassName={styles.inputLabel}
          fullWidth={false}
          disableAsterisk
          className={styles.input}
        />
      </div>
      <div className={styles.button}>
        <Button text="Confirm" loading={loading} />
      </div>
    </ValidatorForm>
  );
};

export default SignInForm;
