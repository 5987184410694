import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from 'components/Ui/Button';
import contactUsIcon from 'images/ContactUs.svg';
import faqsIcon from 'images/FAQs.svg';
import manualsIcon from 'images/Manuals.png';
import CollapseButton from 'components/ContentfulFields/Collapse/CollapseButton';
import { ClickAwayListener } from '@material-ui/core';
import styles from 'components/ContentfulFields/Collapse/styles.scss';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import { getToUrl } from 'utils/translation';
import cx from 'classnames';

const SUPPORT_COLLAPSE_KEY = 'support';

const SUPPORT_PAGE_URLS = {
  faq: getToUrl('/faqs', 'U57'),
  contactUs: getToUrl('/support/contact-us', 'U58'),
  manuals: getToUrl('/support/equipment-manuals', 'U59'),
};

const SupportCollapse = ({
  toggleCollapse,
  activeDrawer,
  closeCollapse,
  ...props
}) => {
  const handleClickAway = () => {
    toggleCollapse(SUPPORT_COLLAPSE_KEY);
  };

  const openCollapse = () => {
    const collapseContent = (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={cx(
            styles.collapseContentWrapper,
            styles.supportCollapseContentWrapper,
          )}
          data-tracking={GTM_TRACKING_TAGS['Support Collapse']}
        >
          <Button
            className={styles.collapseContent}
            onClick={closeCollapse}
            iconButton
            icon={contactUsIcon}
            href={SUPPORT_PAGE_URLS.contactUs}
            text="Contact Us"
          />
          <Button
            className={styles.collapseContent}
            onClick={closeCollapse}
            iconButton
            icon={faqsIcon}
            href={SUPPORT_PAGE_URLS.faq}
            text="FAQs"
          />
          <Button
            className={styles.collapseContent}
            onClick={closeCollapse}
            iconButton
            icon={manualsIcon}
            href={SUPPORT_PAGE_URLS.manuals}
            text="Manuals"
          />
        </div>
      </ClickAwayListener>
    );

    toggleCollapse(SUPPORT_COLLAPSE_KEY, collapseContent);
  };

  const isActive =
    Object.values(SUPPORT_PAGE_URLS).includes(props.location.pathname) ||
    activeDrawer === SUPPORT_COLLAPSE_KEY;

  const isExpanded = activeDrawer === SUPPORT_COLLAPSE_KEY;

  return (
    <CollapseButton
      isActive={isActive}
      onToggle={openCollapse}
      ariaHaspopup
      ariaExpanded={isExpanded}
    >
      Support
    </CollapseButton>
  );
};

SupportCollapse.propTypes = {
  toggleCollapse: PropTypes.func.isRequired,
  activeDrawer: PropTypes.string,
  closeCollapse: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(SupportCollapse);
