import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  translateLink,
  getToUrl,
  replaceAllVariables,
} from 'utils/translation';

const Link = ({ to, className, children, ...props }) => {
  const re = /(\/[a-z-]+)+(\?|&)*(.)*$/;
  if (to.search(re) === 0) {
    return (
      <RouterLink className={className} to={getToUrl(to, 'U14')} {...props}>
        {translateLink(children, 'C0')}
      </RouterLink>
    );
  }

  const toTranslated = replaceAllVariables(to);
  return (
    <a className={className} href={toTranslated} target="_blank" {...props}>
      {translateLink(children, 'C0')}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
};

export default Link;
