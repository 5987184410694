import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BLOCKS } from '@contentful/rich-text-types';
import moment from 'moment';
import { get, kebabCase, isEmpty } from 'lodash';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { selectSelectedServiceLocation } from 'containers/PrimoAccount/selectors';
import { selectBranch } from 'containers/UserRegion/selectors';
import PrimoLearnMoreDialog from 'components/Dialogs/PrimoLearnMoreDialog/Loadable';
import { Div } from 'components/Ui';
import { getContentOptions } from '../CollapseBanner/parserOptions';
import Button from '../Button';
import styles from './water-regional.scss';

const PromoBanner = ({
  document,
  isAuthenticated,
  userRegionBranch,
  serviceLocation,
}) => {
  const [openPrimoLearnMoreDialog, setOpenPrimoLearnMoreDialog] = useState(
    false,
  );

  if (isAuthenticated && !serviceLocation) {
    return null;
  }

  const options = {
    renderNode: {
      ...Object.assign(getContentOptions(styles, document).renderNode, {
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
          const title = document?.fields?.entryTitle;
          const id = get(node, 'data.target.sys.contentType.sys.id');

          switch (id) {
            case 'button':
              return (
                <Button
                  id={`btn-${kebabCase(title)}`}
                  className={styles.btn}
                  document={node.data.target}
                  onClick={() => {
                    setOpenPrimoLearnMoreDialog(true);
                  }}
                >
                  {children}
                </Button>
              );
            default:
              return null;
          }
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          if (!get(node, 'data.target.fields.title', false)) {
            return null;
          }

          const {
            fields: { file, description },
          } = node.data.target;

          return (
            <div className={styles.embeddedAsset}>
              <img src={`${file.url}?fm=webp`} alt={description} />
            </div>
          );
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
          const contentfulText = Array.isArray(children)
            ? children[0]
            : children;
          const dates = branchDates[theBranch] || 'Coming Soon';
          const bannerText = contentfulText.replace(
            '$Date',
            dates.transitionDate,
          );
          if (isEmpty(bannerText)) {
            return null;
          }
          return <Div className={styles.text}>{bannerText}</Div>;
        },
      }),
    },
  };

  const theBranch = isAuthenticated
    ? serviceLocation.branchCode
    : userRegionBranch;

  const branchDates = get(
    document,
    'fields.features.content[0].content[0].value',
    '',
  )
    .split('\n')
    .map((item) => item.trim())
    .filter((item) => item.length > 0)
    .reduce((acc, item) => {
      const branchDate = item.replace(/\s\s+/g, ' ').split(' ');
      const [branch, showDate, transitionDate] = branchDate;
      acc[branch] = { showDate, transitionDate };
      return acc;
    }, {});

  const learnMore = get(
    document,
    'fields.bottomContent.content[0].data.target.fields',
    '',
  );

  const content = documentToReactComponents(
    document && document.fields && document.fields.content,
    options,
  );

  const dates = branchDates[theBranch] || branchDates.ALL;
  if (!dates) {
    return null;
  }
  const { transitionDate, showDate } = dates;
  const isSameOrAfterShowDate = moment().isSameOrAfter(
    moment(showDate, 'MM/DD/YYYY'),
  );
  const isBeforeTransitionDate = moment().isBefore(
    moment(transitionDate, 'MM/DD/YYYY'),
  );

  const showBanner = isSameOrAfterShowDate && isBeforeTransitionDate;

  return (
    showBanner && (
      <div className={setTheme([styles.promotionBanner], document, styles)}>
        <div className={styles.content}>
          <div className={styles.controlledContent}>
            <div className={styles.descriptionWrapper}>{content}</div>
          </div>
        </div>
        {openPrimoLearnMoreDialog && (
          <PrimoLearnMoreDialog
            open
            learnMore={learnMore}
            onClose={() => {
              setOpenPrimoLearnMoreDialog(false);
            }}
          />
        )}
      </div>
    )
  );
};

PromoBanner.propTypes = {
  document: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  userRegionBranch: PropTypes.string,
  serviceLocation: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  userRegionBranch: selectBranch(),
  serviceLocation: selectSelectedServiceLocation(),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PromoBanner);
