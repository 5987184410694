import React from 'react';
import PropTypes from 'prop-types';
import Product from './Product';

import styles from './styles.scss';

const ProductsList = ({ products }) =>
  products &&
  products.length > 0 && (
    <div className={styles.productsWrapper}>
      <div className={styles.productsList}>
        {products.map((product) => (
          <Product key={product.sys.id} document={product} isAllProductsPage />
        ))}
      </div>
    </div>
  );

ProductsList.propTypes = {
  products: PropTypes.array,
};

ProductsList.defaultProps = {
  products: [],
};

export default ProductsList;
