import styled from 'styled-components';
import { white } from 'theme/_variables.scss';

const CaretDown = styled.div`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${white};
`;

export default CaretDown;
