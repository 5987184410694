import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Div } from 'components/Ui';
import { Slide } from '@material-ui/core';
import ThumbUpGreen from 'images/ThumbUpGreen.svg';
import { useIsVertPhone } from 'utils/hooks';

import styles from './styles.scss';

const OrderChangeDrawer = ({ open, onConfirm, onCancel }) => {
  const isVertPhone = useIsVertPhone();
  const drawerRef = useRef();

  useEffect(() => {
    if (drawerRef.current && open) {
      drawerRef.current.focus();
    }
  }, [open, document.activeElement]);

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div className={styles.modal} ref={drawerRef} tabIndex="-1">
        <img src={ThumbUpGreen} alt="" />
        <Div
          className={classnames(styles.text, isVertPhone && styles.textPhone)}
        >
          Your order has been changed.
        </Div>
        <Button
          text="View Order"
          className={styles.confirmButton}
          onClick={onConfirm}
        />
        <Button
          text="Continue Shopping"
          className={styles.cancelButton}
          onClick={onCancel}
        />
      </div>
    </Slide>
  );
};

OrderChangeDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrderChangeDrawer;
