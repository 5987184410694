import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import { useIsMobile } from 'utils/hooks';
import { Dialog, Input, Radio, Button, Form, FormLabel } from 'components/Ui';
import { Grid, RadioGroup } from '@material-ui/core';
import { warningRed, PinGreen } from 'images';
import { apiCall } from 'utils/swaggerClient';
import {
  fetchHome as fetchHomeAction,
  navigateCostcoRoute,
  setHomeOffice,
} from 'containers/Landing/actions';
import { updateUserRegion } from 'containers/UserRegion/actions';
import { clearCart } from 'containers/Cart/actions';
import { resetOrder } from 'containers/CheckoutPage/actions';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { selectMemberId } from 'containers/Cart/selectors';

import styles from './styles.scss';

const CostcoMembershipDialog = ({
  open,
  onClose,
  doUpdateUserRegion,
  fetchHome,
  doRouteToCostcoView,
  history,
  doSetHomeOffice,
  doClearCart,
  doResetOrder,
  postalCode,
  memberId,
}) => {
  const isMobile = useIsMobile();
  const [homeOffice, changeHomeOffice] = useState('home');
  const [zipCode, changeZipCode] = useState(
    window.ipSniffFailed ? '' : postalCode,
  );
  const [memberCardNumber, changeMemberCardNumber] = useState(memberId);
  const [showMemberNumberError, changeShowMemberNumberError] = useState(false);
  const [showZipCodeError, changeShowZipCodeError] = useState(false);

  useEffect(() => {
    changeMemberCardNumber(memberId);
    changeZipCode(window.ipSniffFailed ? '' : postalCode);
  }, [open]);

  const handleHomeOfficeChange = ({ target: { value } }) => {
    changeHomeOffice(value);
  };

  const handleZipCodeChange = ({ target: { value } }) => {
    changeZipCode(value);
  };

  const handleMemberCardNumberChange = ({ target: { value } }) => {
    changeMemberCardNumber(value);
  };

  const handleSubmit = async () => {
    changeShowMemberNumberError(false);
    changeShowZipCodeError(false);

    try {
      if (memberId !== memberCardNumber || postalCode !== zipCode) {
        doClearCart();
      }
      doResetOrder();
      const {
        headers: { 'x-branch-code': branch },
        obj: brand,
      } = await apiCall({
        operationId: 'brands.getBrandByZip',
        parameters: { postalCode: zipCode, source: 'costco' },
        fullResponse: true,
      });
      window.brandByZip = brand;

      doUpdateUserRegion({
        postalCode: zipCode,
        branch,
        brand,
      });

      routeToCostcoView().then((result) => {
        if (result !== 'error') {
          onClose();
        }
      });
    } catch (error) {
      onClose();
      routeToCostcoView();
    }
  };

  const showError = (comps) => {
    changeShowMemberNumberError(false);
    changeShowZipCodeError(false);
    comps.forEach((comp) => {
      if (comp.props.name === 'memberCardNumber') {
        changeShowMemberNumberError(true);
      } else if (comp.props.name === 'zipCode') {
        changeShowZipCodeError(true);
      }
    });
  };

  const routeToCostcoView = async () => {
    try {
      const result = await apiCall({
        operationId: 'CostcoMemberCheck.getCostcoMemberInfo',
        parameters: {
          memberCardNumber,
          postalCode: zipCode,
        },
        fullResponse: true,
      });
      if (result) {
        if (!result.obj.validExecutive && !result.obj.validNonExecutive) {
          changeShowMemberNumberError(true);
          return 'error';
        }
        doSetHomeOffice(homeOffice);
        fetchHome();
        const costcoAccount = { ...result.obj, memberNum: memberCardNumber };
        doRouteToCostcoView(costcoAccount);
        return 'success';
      }
      // TODO: Is below needed? I recall there might be a case where
      // getCostcoMemberInfo does not return a result but I cannot remember
      // Leaving this is for now as a catch for above just is case
      history.push('/costcowater-nonserv-restrictions');
      return 'exception';
    } catch (error) {
      history.push('/costcowater-nonserv-restrictions');
      return 'exception';
    }
  };

  return (
    <Dialog
      isAutoClose={false}
      isCloseIcon
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      className={styles.dialog}
      aria-labelledby="membership-dialog-title"
      aria-describedby="membership-dialog-description"
    >
      <div
        className={styles.outerContainer}
        aria-label="Costco membership dialog"
      >
        {isMobile && (
          <Fragment>
            <div className={styles.header}>Confirm Location</div>
            <hr />
          </Fragment>
        )}
        <div className={styles.container}>
          <div className={styles.content}>
            <img src={PinGreen} alt="location pin" className={styles.pin} />
            <h1 className={styles.title} id="membership-dialog-title">
              Explore products and services in your area
            </h1>
            <div className={styles.subtitle} id="membership-dialog-description">
              Enter your ZIP code and Costco member number to see the full
              selection of products and services available in your area.
            </div>
            {!isMobile && <hr />}
            <Form
              className={styles.formContainer}
              onSubmit={handleSubmit}
              onError={showError}
            >
              <Grid
                container
                spacing={isMobile ? 0 : 2}
                className={styles.form}
              >
                <Grid className={styles.memberNumberItem} item sm={12} md={6}>
                  <Input
                    id="membership-dialog-number-input"
                    label="Costco Member Number:"
                    validators={['required']}
                    errorMessages={[
                      '',
                      'Please enter a valid membership number',
                    ]}
                    showError={showMemberNumberError}
                    hasExternalError
                    value={memberCardNumber}
                    onChange={handleMemberCardNumberChange}
                    name="memberCardNumber"
                    placeholder="Enter your member number"
                    disableAsterisk
                    ariaLabel="Costco Member Number"
                  />
                </Grid>
                {isMobile && showMemberNumberError && (
                  <Grid className={styles.memberNumberErrorItem} item sm={12}>
                    <span className={styles.arrow} />
                    <div className={styles.memberNumberError}>
                      <img
                        src={warningRed}
                        alt="warningRed"
                        className={styles.warningRed}
                      />
                      <span id="membership-dialog-number-input-error">
                        Please enter a valid member number
                      </span>
                    </div>
                  </Grid>
                )}
                <Grid className={styles.zipCodeItem} item sm={4} md={6}>
                  <Input
                    id="membership-dialog-zip-input"
                    label="ZIP Сode:"
                    validators={['required', 'zip']}
                    showError={showZipCodeError}
                    hasExternalError
                    errorMessages={['', '']}
                    value={zipCode}
                    onChange={handleZipCodeChange}
                    name="zipCode"
                    disableAsterisk
                    ariaLabel="zip code"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={isMobile ? 0 : 2}
                className={styles.form}
              >
                {!isMobile && showMemberNumberError && (
                  <Grid className={styles.memberNumberErrorItem} item md={6}>
                    <span className={styles.arrow} />
                    <div className={styles.memberNumberError}>
                      <img
                        src={warningRed}
                        alt="warningRed"
                        className={styles.warningRed}
                      />
                      <span id="membership-dialog-number-input-error">
                        Please enter a valid membership number
                      </span>
                    </div>
                  </Grid>
                )}
                {showZipCodeError && (
                  <Grid
                    className={classNames(styles.zipCodeErrorItem, {
                      [styles.zipCodeErrorItem2]: !showMemberNumberError,
                    })}
                    item
                    md={6}
                  >
                    <span className={styles.arrow} />
                    <div className={styles.zipCodeError}>
                      <img
                        src={warningRed}
                        alt="warningRed"
                        className={styles.warningRed}
                      />
                      <span id="membership-dialog-zip-input-error">
                        Please enter a valid ZIP code
                      </span>
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={isMobile ? 0 : 4}
                className={styles.form}
              >
                <div className={styles.radioGroupLabel}>
                  <FormLabel label="This is for:" />
                </div>
                <Grid className={styles.radioGroupItem} item sm={12} md={8}>
                  <RadioGroup
                    name="type"
                    className={styles.radioGroup}
                    value={homeOffice}
                    onChange={handleHomeOfficeChange}
                    row
                    aria-label="this is for"
                  >
                    <Radio className={styles.radio} value="home" label="Home" />
                    <Radio
                      className={styles.radio}
                      value="office"
                      label="Office"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              {!isMobile && <hr />}
              <div className={styles.buttonContainer}>
                <Button
                  text="Start Order"
                  className={styles.submit}
                  color="dark"
                  ariaLabel="with member number and zip code"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

CostcoMembershipDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  fetchHome: PropTypes.func.isRequired,
  doUpdateUserRegion: PropTypes.func.isRequired,
  doRouteToCostcoView: PropTypes.func.isRequired,
  history: PropTypes.object,
  doSetHomeOffice: PropTypes.func.isRequired,
  doClearCart: PropTypes.func.isRequired,
  doResetOrder: PropTypes.func.isRequired,
  postalCode: PropTypes.string,
  memberId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  postalCode: selectPostalCode(),
  memberId: selectMemberId(),
});

const mapDispatchToProps = {
  fetchHome: fetchHomeAction,
  doUpdateUserRegion: updateUserRegion,
  doRouteToCostcoView: navigateCostcoRoute,
  doSetHomeOffice: setHomeOffice,
  doClearCart: clearCart,
  doResetOrder: resetOrder,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CostcoMembershipDialog),
);
