import Loadable from 'react-loadable';
import LoadingIndicator from 'components/LoadingIndicator';

export default Loadable({
  loader: () =>
    import(
      '../CostcoWaterNonServPremiumwater' /* webpackChunkName: "Our Story" */
    ),
  loading: LoadingIndicator,
});
