import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Dialog, SuccessBlock, Button } from 'components/Ui';
import {
  selectRestorePasswordMessage,
  selectRestoreEmail,
  selectRestorePasswordFetching,
} from 'containers/Authentication/selectors';
import { restorePassword } from 'containers/Authentication/actions';

import MUIStyles from './styles';
import styles from './styles.scss';

const SuccessRestoreDialog = (props) => {
  const isSuccessRestoreDialogOpen = Boolean(props.restorePasswordMessage);

  const handleClick = () => {
    props.restorePassword({ email: props.email });
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isSuccessRestoreDialogOpen}
      classes={props.classes}
      size="small"
      fullScreen={isMobile}
      title={isMobile ? 'Forgot password?' : ''}
      isCloseIcon
    >
      <div className={styles.modalContainer}>
        <SuccessBlock
          imageAnimation="bounce"
          image="letter"
          text="Almost there...one more step"
        />
        <p className={styles.modalDescription}>
          A password reset link was emailed to you. Click the link in the email
          to create a new password. If you do not receive an email within 5
          minutes, please click the re-send email link below.
        </p>
        <Button
          onClick={handleClick}
          text="Resend"
          className={styles.resendBtn}
          loading={props.isRestorePasswordFetching}
        />
      </div>
    </Dialog>
  );
};

SuccessRestoreDialog.propTypes = {
  restorePasswordMessage: PropTypes.string,
  isRestorePasswordFetching: PropTypes.bool,
  classes: PropTypes.string,
  restorePassword: PropTypes.func,
  email: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  restorePasswordMessage: selectRestorePasswordMessage(),
  isRestorePasswordFetching: selectRestorePasswordFetching(),
  email: selectRestoreEmail(),
});

const mapDispatchToProps = {
  restorePassword,
};

export default compose(
  withStyles(MUIStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(SuccessRestoreDialog);
