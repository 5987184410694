import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Title from 'components/Ui/Title';

export const Wrapper = (
  { children, color, className, title, innerRef, id },
  { stylesTheme },
) => {
  const styles = require(`./styles/${stylesTheme}.scss`);
  const wrapperClassName = classNames(styles.wrapper, className, {
    [styles.grey]: color === 'grey',
    [styles.white]: color === 'white',
  });

  return (
    <Fragment>
      {title && <Title className={styles.title}>{title}</Title>}
      <div className={wrapperClassName} ref={innerRef} id={id}>
        {children}
      </div>
    </Fragment>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.oneOf(['grey', 'white']),
  className: PropTypes.string,
  title: PropTypes.string,
  innerRef: PropTypes.object,
  id: PropTypes.string,
};

Wrapper.defaultProps = {
  color: 'white',
};

Wrapper.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default React.forwardRef((props, ref) => (
  <Wrapper innerRef={ref} {...props} />
));
