import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './styles.scss';

const Upload = ({ text, onChange }) => {
  const [image, changeImage] = useState(null);

  const handleChange = (e) => {
    const reader = new FileReader();
    reader.onload = ({ target: { result } }) => {
      changeImage(result);
    };
    const file = e.target.files[0];
    reader.readAsDataURL(file);

    onChange(file);
  };

  return (
    <div className={styles.wrapper}>
      {image ? (
        <div className={styles.uploadedContainer}>
          <img src={image} alt="uploaded" />
          <div>
            <span>Your photo</span>
            <Button text="Upload a different photo" link />
            <input type="file" name="file" onChange={handleChange} />
          </div>
        </div>
      ) : (
        <Fragment>
          <Button text={text} type="button" className={styles.button} />
          <input type="file" name="file" onChange={handleChange} />
        </Fragment>
      )}
    </div>
  );
};

Upload.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
};

Upload.defaultProps = {
  text: 'Upload a file',
  onChange: () => {},
};

export default Upload;
