import ContentfulClient from 'utils/ContentfulClient';
import { filter, sortBy, startCase } from 'lodash';
import { getBrandTags } from 'components/Brands';

const contentfulClient = new ContentfulClient();

const getPromoEntries = (type, query) =>
  contentfulClient.getEntries({
    contentType: 'promo',
    order: 'fields.priority',
    additionalQueryParams: {
      'fields.type': startCase(type),
      ...query,
    },
  });

const getPromos = (type, tags) =>
  getPromoEntries(type, {
    'metadata.tags.sys.id[in]': tags,
  });

const getPromosViaBranch = async (type, tags, branch) => {
  // get all WHERE current branch IS:
  //   - raw (no modifier) OR + (included)
  //   - AND NOT - (excluded)
  const branchInclusive = await getPromoEntries(type, {
    'fields.branches[in]': `${branch},+${branch}`,
    'fields.branches[nin]': `-${branch}`,
  });

  // get all WHERE
  //  - branches field DOES NOT CONTAIN current branch
  //  - AND tags field CONTAINS ANY of current brand's tags
  const branded = await getPromoEntries(type, {
    'fields.branches[nin]': `${branch},+${branch},-${branch}`,
    'metadata.tags.sys.id[in]': tags,
  });

  // Filter out branch-ONLY promos,
  // WHERE branches field contains raw (no modifier) branches
  // This is purely defensive, as branch-ONLY promos need NO brand tags
  // AND should NOT be brand tagged BUT nothing prevents their being tagged
  const brandedItemsSansBranchOnly = filter(branded.items, (promo) => {
    const branches = promo.fields.branches || [];
    if (!branches.length) return true;

    const allBranchesHaveModifiers = branches.every((b) => /^[+-]/.test(b));

    // If all branches listed have modifiers, it IS NOT a branch-only promo
    return allBranchesHaveModifiers;
  });

  // Combine & sort by priority
  const promoItems = sortBy(
    [...branchInclusive.items, ...brandedItemsSansBranchOnly],
    'fields.priority',
  );

  // Return a Contentful response object as expected, albeit modified for our purposes
  const promos = {
    ...branded,
    items: promoItems,
    total: promoItems.length,
  };

  return promos;
};

const fetchPromos = async (type, brand, branch) => {
  const tags = getBrandTags(brand).join(',');

  if (!tags) {
    console.warn('withPromo: No tags found for brand', brand); // eslint-disable-line
    return null;
  }

  const promos = branch
    ? await getPromosViaBranch(type, tags, branch)
    : await getPromos(type, tags);

  return promos;
};

const promosCache = {};

/**
 * Return cache OR fetch all promos of given type based on given brand and branch
 * @param {string} type - promo type
 * @param {string} brand - brand name
 * @param {string} [branch] - branch code
 * @return {Object} a Contentful response object
 */
export const getOrFetchPromos = async (type, brand, branch) => {
  const key = !branch ? `${type}-${brand}` : `${type}-${brand}-${branch}`;

  promosCache[key] =
    promosCache[key] || (await fetchPromos(type, brand, branch));

  return promosCache[key];
};
