import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from 'components/Ui';
import styles from './styles.scss';

const GetStartedFormSuccess = ({ siteName }) => (
  <Wrapper color="grey" className={styles.wrapper}>
    <div className={styles.title}>THANKS!</div>
    <div className={styles.subTitle}>
      A {siteName} representative will reach out to you ASAP
    </div>
  </Wrapper>
);

GetStartedFormSuccess.propTypes = {
  siteName: PropTypes.string.isRequired,
};

export default GetStartedFormSuccess;
