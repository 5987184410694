import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from './actions';
import { NOTIFICATIONS_KEY } from './constants';
import Notifier from './Notifier';

const mapDispatchToProps = {
  removeSnackbar,
};

const mapStateToProps = (state) => ({
  notifications: state.get(NOTIFICATIONS_KEY).toJS(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withSnackbar, withConnect)(Notifier);
