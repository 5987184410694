/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { translateElementChildren } from 'utils/translation';

const B = ({ children, translationKey, defaultText, ...rest }) => {
  return (
    <b {...rest}>
      {translateElementChildren(children, translationKey, defaultText)}
    </b>
  );
};

B.propTypes = {
  children: PropTypes.node.isRequired,
  translationKey: PropTypes.string,
  defaultText: PropTypes.string,
};

export default B;
