export default (theme) => ({
  root: {
    padding: '0',
    background: 'transparent',
  },
  dots: {
    display: 'none',
    background: 'transparent',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  dot: {
    width: '12px',
    height: '12px',
    margin: '0 5px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  dotActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
});
