import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-component';
import get from 'lodash/get';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Container } from 'components/Ui';
import UlItem from '../UlItem';
import Button from '../Button';
import styles from './styles.scss';

const FAQ = ({ document }) => {
  const options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => (
        <div className={styles.questionBlock}>{children}</div>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <div className={styles.listItem}>{children}</div>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'ulItem':
            return <UlItem {...node.data.target} />;
          case 'button':
            return (
              <Button className={styles.btn} document={node.data.target}>
                {children}
              </Button>
            );
          default:
            return null;
        }
      },
    },
  };

  const content = documentToReactComponents(
    document && document.fields.content,
    options,
  );

  const masonryOptions = {
    gutter: 20,
    percentPosition: true,
    fitWidth: true,
    horizontalOrder: true,
    transitionDuration: 0,
  };

  return (
    <Container value="FAQ" className={styles.container}>
      <Masonry className={styles.masonry} options={masonryOptions}>
        {content}
      </Masonry>
    </Container>
  );
};

FAQ.propTypes = {
  document: PropTypes.object,
};

export default FAQ;
