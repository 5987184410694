import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty, some } from 'lodash';
import uuid from 'uuid/v4';
import classnames from 'classnames';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import Link from 'components/ContentfulFields/Link';
import FormsMapper from 'components/ContentfulFields/FormsMapper';
import { showCostcoMembershipDialog } from 'containers/Landing/actions';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Section } from 'components/Ui';
import { HiddenContentSpan } from 'components/Ui/ScreenReader';
import Button from '../Button';
import UlItem from '../UlItem';
import Counter from '../Counter';
import SprawlingImage from '../SprawlingImage';

import styles from './styles.scss';

const CostcoPricingBanner = ({
  document,
  stylesTheme,
  showCostcoMembership,
}) => {
  const onClickStartOrder = () => showCostcoMembership();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const options = {
    renderMark: {
      [MARKS.CODE]: (node) => <sub>{node}</sub>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'button':
            delete node.data.target.fields.link;
            return (
              <Button
                className={styles.btn}
                document={node.data.target}
                onClick={onClickStartOrder}
              >
                {children}
              </Button>
            );
          case 'banner':
            return (
              <CostcoPricingBanner
                stylesTheme={stylesTheme}
                document={node.data.target}
                key={node.data.target.sys.id}
              />
            );
          default:
            return null;
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        const embeddedType = get(embedded, 'type.name');

        if (embedded) {
          return (
            <div
              className={classnames({
                [styles.subtitle]: embeddedType === 'Link',
              })}
            >
              {children}
            </div>
          );
        }

        const childrenWithContent = children.filter(
          (childContent) => childContent !== '',
        );

        if (isEmpty(childrenWithContent)) return null;

        return (
          <div className={setTheme(styles.subtitle, document, styles)}>
            {childrenWithContent.map(
              (child) =>
                child &&
                child
                  .split('\n')
                  .filter((textRow) => textRow.length > 0)
                  .map((textRow) => <div key={uuid()}>{textRow}</div>),
            )}
          </div>
        );
      },
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className={styles.quote}>{children}</blockquote>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={setTheme(styles.title, document, styles)}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className={setTheme(styles.title, document, styles)}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={setTheme(styles.title, document, styles)}>{children}</h3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol type="a" className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ol>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');
        switch (id) {
          case 'ulItem':
            return <UlItem {...node.data.target} />;
          case 'button':
            delete node.data.target.fields.link;
            return (
              <Button
                className={styles.btn}
                document={node.data.target}
                onClick={onClickStartOrder}
              >
                {children}
              </Button>
            );
          case 'form':
            return <FormsMapper type={node.data.target.fields.type} />;
          case 'counter':
            return (
              <Counter className={styles.counter} document={node.data.target} />
            );
          case 'sprawlingImage':
            return <Fragment />;
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: linkParser,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        return (
          <Link className={styles.link} to={`/${nodeFields.slug}`}>
            {children}
          </Link>
        );
      },
    },
  };

  const imagesOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <div>{children}</div>,
      [BLOCKS.LIST_ITEM]: (node, children) => <div>{children}</div>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (!get(node, 'data.target.fields.title', false)) {
          return null;
        }

        const {
          fields: { file, description },
        } = node.data.target;

        return (
          <div className={styles.embeddedAsset}>
            <img src={`${file.url}?fm=webp`} alt={description} />
          </div>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'sprawlingImage':
            return (
              <div className={styles.embeddedAsset}>
                <SprawlingImage document={node.data.target} />
              </div>
            );
          default:
            return null;
        }
      },
      [BLOCKS.HEADING_1]: () => null,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        const hasLinks = some(node.content, ['nodeType', 'hyperlink']);

        if (embedded && !hasLinks) {
          return children;
        }

        return null;
      },
      [BLOCKS.HEADING_2]: () => null,
      [BLOCKS.HEADING_3]: () => null,
    },
  };

  const header = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(0, 2),
    },
    options,
  );
  const executiveMemberCard = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(2, 3),
    },
    imagesOptions,
  );
  const goldStarMemberCard = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(3, 4),
    },
    imagesOptions,
  );
  const waterImage = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(4, 5),
    },
    imagesOptions,
  );
  const waterLabel = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(5, 6),
    },
    options,
  );
  const waterExecutiveMemberPrice = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(6, 7),
    },
    options,
  );
  const waterGoldStarMemberPrice = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(7, 8),
    },
    options,
  );
  const equipmentImage = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(8, 9),
    },
    imagesOptions,
  );
  const equipmentLabel = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(9, 10),
    },
    options,
  );
  const equipmentExecutiveMemberPrice = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(10, 11),
    },
    options,
  );
  const equipmentGoldStarMemberPrice = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(11, 12),
    },
    options,
  );
  const bottomContent = documentToReactComponents(
    document && document.fields.bottomContent,
    options,
  );

  return (
    document && (
      <Section
        className={setTheme(styles.banner, document, styles)}
        style={{
          backgroundImage:
            document.fields.backgroundImage &&
            `url(${
              document.fields.backgroundImage &&
              document.fields.backgroundImage.fields.file.url
            }?fm=webp)`,
        }}
        aria-label="Costco pricing banner"
      >
        {get(
          document,
          'fields.backgroundImage.fields.file.contentType',
          '',
        ).includes('video') && (
          <div className={styles.backgroundVideo}>
            <video autoPlay muted loop>
              <source
                src={document.fields.backgroundImage.fields.file.url}
                type={document.fields.backgroundImage.fields.file.contentType}
              />
            </video>
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.header}>{header}</div>
            <div
              className={styles.memberCardsContaniner}
              aria-label="executive member card"
            >
              <div className={styles.memberCards}>
                <div className={styles.memberCard}>{executiveMemberCard}</div>
                <div
                  className={styles.memberCard}
                  aria-label="gold star member card"
                >
                  {goldStarMemberCard}
                </div>
              </div>
            </div>
            <table
              aria-label="pricing example container"
              className={styles.rowContainer}
            >
              <caption>
                <HiddenContentSpan>
                  Pricing Example table <br />
                  <span>
                    Column one contains an image of the product, column two
                    contains a description, column three contains pricing for a
                    Costco Executive Member, and column four contains pricing
                    for a Costco Gold Star Member
                  </span>
                </HiddenContentSpan>
              </caption>
              <tbody>
                <tr
                  aria-label="Bottled Water Pricing Row"
                  className={styles.rowLayout}
                >
                  {!isMobile && <td className={styles.image}>{waterImage}</td>}
                  <td className={styles.label}>
                    {isMobile && (
                      <div className={styles.image}>{waterImage}</div>
                    )}
                    {waterLabel}
                  </td>
                  <td className={styles.pricing}>
                    {waterExecutiveMemberPrice}
                  </td>
                  <td className={styles.pricing}>{waterGoldStarMemberPrice}</td>
                </tr>
                <tr
                  aria-label="equipment Pricing Row"
                  className={styles.rowLayout}
                >
                  {!isMobile && (
                    <td className={styles.image}>{equipmentImage}</td>
                  )}
                  <td className={styles.label}>
                    {isMobile && (
                      <div className={styles.image}>{equipmentImage}</div>
                    )}
                    {equipmentLabel}
                  </td>
                  <td className={styles.pricing}>
                    {equipmentExecutiveMemberPrice}
                  </td>
                  <td className={styles.pricing}>
                    {equipmentGoldStarMemberPrice}
                  </td>
                </tr>
              </tbody>
            </table>
            <Section className={styles.ctaContainer}>
              <div className={styles.leftSide}>{bottomContent[0]}</div>
              <div className={styles.rightSide}>{bottomContent[1]}</div>
            </Section>
            <Section
              className={styles.agreement}
              aria-label="pricing agreement"
            >
              {bottomContent[2]}
            </Section>
          </div>
        </div>
      </Section>
    )
  );
};

CostcoPricingBanner.propTypes = {
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
  showCostcoMembership: PropTypes.func,
};

const mapDispatchToProps = {
  showCostcoMembership: showCostcoMembershipDialog,
};

export default connect(null, mapDispatchToProps)(CostcoPricingBanner);
