import React, { PureComponent } from 'react';
import { bool, node, object } from 'prop-types';
import { autoPlay } from 'react-swipeable-views-utils';
import classNames from 'classnames';
import { MobileStepper, withStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { get, isEmpty } from 'lodash';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Button } from 'components/Ui';
import { hasBrandTag } from 'components/Content/utils';
import Item from './Item';
import jsStyles from './styles';
import styles from './styles.scss';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DOTS_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

class Slider extends PureComponent {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  getDotsClass = (isSingleStep, notBannerElement, dotsPosition, classes) => {
    const { dotsBottom, dotsTop, dotsHidden } = classes;
    if (isSingleStep || !notBannerElement) return dotsHidden;
    return dotsPosition === DOTS_POSITION.BOTTOM ? dotsBottom : dotsTop;
  };

  render() {
    const {
      altStyles,
      classes: themeStyles,
      altItemStyles,
      theme,
      backIcon = <ChevronLeft />,
      nextIcon = <ChevronRight />,
      document: {
        fields: {
          items,
          title,
          background,
          dotsPosition = DOTS_POSITION.BOTTOM,
          className,
          interval = 20,
        },
      },
    } = this.props;

    const itemsToShow = items.filter(
      (slide) => isEmpty(slide.metadata?.tags) || hasBrandTag(slide),
    );
    const intervalInMs = interval * 1000;

    let { hideDisabled = true } = this.props;

    const { activeStep } = this.state;
    const maxSteps = itemsToShow.length;
    const isSingleStep = maxSteps === 1;

    if (isSingleStep) hideDisabled = true;

    // override default styles with altStyles if given
    const classes = altStyles || themeStyles;
    const style = altStyles || styles;

    const hasBannerSlide = itemsToShow.some(
      (slide) => slide.fields.type === 'banner',
    );
    const notBannerElement =
      (hasBannerSlide && activeStep !== 0) || !hasBannerSlide;

    const hasNext = activeStep !== maxSteps - 1;
    const hasBack = activeStep !== 0;

    const dotsClass = this.getDotsClass(
      isSingleStep,
      notBannerElement,
      dotsPosition,
      classes,
    );
    const titleClass = classNames(style.title, {
      [style.titleSmall]: hasBannerSlide,
    });
    const nextButtonClass = classNames(classes.buttons, style.btn, {
      [style.btnCenter]: !notBannerElement,
    });
    const sliderClass = classNames(classes.root, style[className], {
      [style.noBannerSlide]: notBannerElement,
    });
    const sliderWrapperClass = classNames(style.sliderWrapper, {
      [style.hasBackground]: background,
      [style.customWrapper]: hasBannerSlide,
    });

    return (
      <div
        className={sliderWrapperClass}
        style={{
          backgroundImage: `url(${get(
            background,
            'background.fields.file.url',
          )}?fm=webp)`,
          backgroundColor: '#fff',
        }}
      >
        <div className={style.sliderContainer}>
          {notBannerElement && <h1 className={titleClass}>{title}</h1>}
          <div className={sliderClass}>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
              interval={intervalInMs}
            >
              {itemsToShow.map((slide) => (
                <Item
                  key={slide.sys.id}
                  document={slide}
                  altStyles={altItemStyles}
                />
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              classes={{
                root: classes.mobileStepper,
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: dotsClass,
              }}
              backButton={
                hasBack || !hideDisabled ? (
                  <Button
                    className={classNames(classes.buttons, style.btn)}
                    icon={backIcon}
                    onClick={this.handleBack}
                    disabled={!hasBack}
                  >
                    Back
                  </Button>
                ) : null
              }
              nextButton={
                hasNext || !hideDisabled ? (
                  <Button
                    className={nextButtonClass}
                    icon={nextIcon}
                    onClick={this.handleNext}
                    disabled={!hasNext}
                  >
                    Next
                  </Button>
                ) : null
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  document: object,
  classes: object,
  theme: object,
  altStyles: object,
  altItemStyles: object,
  backIcon: node,
  nextIcon: node,
  hideDisabled: bool,
};

export default withStyles(jsStyles, { withTheme: true })(Slider);
