import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { selectProductCategories } from 'containers/PrimoProducts/selectors';
import { Div, H4, LanguageToggle } from 'components/Ui';
import {
  selectAcquisitionVariables,
  selectSsrReqRoute,
} from 'containers/Landing/selectors';
import Link from 'components/ContentfulFields/Link';
import { getRegionalBrandName, CA_BRANDS } from 'utils/domainHelper';
import { injectVariables, getToUrl } from 'utils/translation';

const Content = (
  { fields, productCategories, acquisitionVariables, ssrReqRoute },
  { stylesTheme },
) => {
  const getCategoryId = (uuid) =>
    productCategories.find((cat) => uuid === cat.sys?.id)?.fields?.urlName ||
    uuid;

  const styles = require(`./styles/${stylesTheme}.scss`);

  const options = {
    renderMark: {
      [MARKS.CODE]: (text) => {
        if (text === '{LanguageToggle}') {
          return <LanguageToggle />;
        }
        return <code>{text}</code>;
      },
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (
          Array.isArray(children) &&
          children.length === 1 &&
          children[0].length > 0
        ) {
          return (
            <H4 className={styles.content} defaultText={'C0'}>
              {children}
            </H4>
          );
        }
        return children;
      },
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={styles.footerBlock}>{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className={styles.footerSection}>
          <Div>{children}</Div>
        </li>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data;
        const regionalBrandName = getRegionalBrandName(ssrReqRoute, 5);
        if (
          CA_BRANDS.includes(regionalBrandName) &&
          (uri === '/cooler-cleaning-service' ||
            uri === 'https://shop.water.com')
        ) {
          // Remove these links for CA brands
          return null;
        }
        return (
          <Link className={styles.link} to={uri}>
            {children}
          </Link>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link className={styles.link} to={node.data.uri}>
          {children}
        </Link>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const contentType = get(node, 'data.target.sys.contentType.sys.id');

        switch (contentType) {
          case 'productCategory': {
            const name = get(node, 'data.target.fields.name');
            const categoryName = name === 'Equipment' ? 'Rent Equipment' : name;
            const categoryId = getCategoryId(get(node, 'data.target.sys.id'));
            const productsListUrl = getToUrl(`/products/${categoryId}`, 'U63');
            return <Link to={productsListUrl}>{categoryName}</Link>;
          }
          case 'button': {
            const buttonData = get(node, 'data.target.fields');
            const regionalBrandName = getRegionalBrandName(ssrReqRoute, 5);
            const brandedLink = injectVariables(
              buttonData.link,
              regionalBrandName,
              acquisitionVariables,
              'C23',
            );
            return (
              <Link className={styles.link} to={brandedLink}>
                <img
                  src={`${buttonData.iconImage.fields.file.url}?fm=webp`}
                  alt={buttonData.iconImage.fields.file.fileName
                    .split('.')[0]
                    .toLowerCase()}
                  className={styles.socialIcon}
                />
              </Link>
            );
          }
          default:
            return null;
        }
      },
    },
  };

  const content = documentToReactComponents(fields, options);

  return <Div className={styles.contentContainer}>{content}</Div>;
};

Content.propTypes = {
  fields: PropTypes.object,
  productCategories: PropTypes.arrayOf(PropTypes.object),
};

Content.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  productCategories: selectProductCategories(),
  acquisitionVariables: selectAcquisitionVariables(),
  ssrReqRoute: selectSsrReqRoute(),
});

export default connect(mapStateToProps)(Content);
