import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { loginURL, redirectToExternalURL } from 'utils/common';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { translateData, getToUrl } from 'utils/translation';
import Collapse from 'components/ContentfulFields/Collapse';
import { LanguageToggle, Button, Icon, Span, F, Img } from 'components/Ui';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { selectUserInfo } from 'containers/PrimoProfile/selectors';
import {
  selectNextDelivery,
  selectServiceLocationId,
} from 'containers/PrimoAccount/selectors';
import { selectSection } from 'containers/Landing/selectors';
import accountImg from 'images/Account.svg';
import SignIn from 'containers/Authentication/Login/Water';
import SignUp from 'containers/Authentication/Signup/Water';
import AuthenticatedNavigation from 'components/ContentfulFields/Header/Navigation/WaterRegional/AuthenticatedNavigation';
import NextDeliveryPopper from 'components/ContentfulFields/Header/HeaderPoppers/NextDeliveryPopper';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import HtmlData from 'components/Ui/HtmlData/index';
import styles from './styles.scss';
import { VIEW_KEYS } from './constants';

const NEXT_DELIVERY_KEY = 'NEXT_DELIVERY_KEY';

class MenuContent extends PureComponent {
  getInitialState = () => ({
    showAuthenticationOptions: false,
    swipableIndex: 0,
  });

  state = this.getInitialState();

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState({ ...this.getInitialState() });
  };

  handleSwitchView = (value) => {
    this.setState({ swipableIndex: value });
  };

  handleCancel = () => {
    this.handleSwitchView(VIEW_KEYS.INITIAL);
  };

  showAuthHeader = () => {
    this.setState({
      showAuthenticationOptions: true,
    });
  };

  openNextDeliveryCollapse = () => {
    this.props.closeCollapse();
    this.props.openCollapse(
      NEXT_DELIVERY_KEY,
      <NextDeliveryPopper showPopper />,
    );
  };

  signInOnClick = () => {
    this.props.loginWithRedirect({
      showForm: 'signin',
    });
  };

  signUpOnClick = () => {
    this.props.loginWithRedirect({
      showForm: 'registration',
    });
  };

  render() {
    const {
      fields,
      location,
      isAuthenticated,
      userInfo: { firstName, nickname },
      section,
      openCollapse,
      closeCollapse,
      nextDelivery,
      logoutWithRedirect,
      serviceLocationId,
    } = this.props;

    const isAcquisition = isAcquisitionDomain();

    const { showAuthenticationOptions, swipableIndex } = this.state;

    const name = firstName ? `, ${firstName}` : nickname ? `, ${nickname}` : '';

    const options = {
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node) => {
          if (get(node, 'data.target.sys.contentType.sys.id') === 'collapse') {
            return (
              <li>
                <Collapse
                  className={styles.collapse}
                  document={node.data.target}
                  toggle={openCollapse}
                  section={section}
                  isAuthenticated={isAuthenticated}
                  closeCollapse={closeCollapse}
                />
                {isAcquisition && <Icon icon="chevron-right" />}
              </li>
            );
          }
          return null;
        },
        [BLOCKS.PARAGRAPH]: (node, children) => children,
        [INLINES.HYPERLINK]: (node, children) => {
          const isActive = node.data.uri === location.pathname;

          return (
            <li>
              <Link
                to={getToUrl(node.data.uri, 'U6')}
                className={classNames({ [styles.active]: isActive })}
              >
                {translateData(children, 'C44')}
              </Link>
            </li>
          );
        },
      },
    };

    const accountImage = (
      <Img src={accountImg} alt="account" aria-label="Account Login" />
    );

    const navigation = documentToReactComponents(fields, options);
    const authenticatedNavigation = (
      <AuthenticatedNavigation
        isMobile
        toggleCollapse={openCollapse}
        closeCollapse={closeCollapse}
        ourProducts={navigation && navigation.length > 0 && navigation[1]}
      />
    );

    const navigationComponent = (
      <HtmlData tracking={GTM_TRACKING_TAGS.Navigation}>
        <div className={styles.mobileNavigation}>
          <ul className={styles.mobileUlNavigation}>
            <li>
              {!showAuthenticationOptions && (
                <div className={styles.account}>
                  {isAuthenticated ? (
                    <Fragment>
                      {accountImage}
                      <span>
                        <F>Hi</F>
                        {name}
                      </span>
                    </Fragment>
                  ) : (
                    <button
                      type="button"
                      aria-controls="auth-buttons"
                      onClick={() => redirectToExternalURL(loginURL())}
                    >
                      {accountImage}
                      <Span>Log in</Span>
                    </button>
                  )}
                </div>
              )}
            </li>
            {isAuthenticated && (
              <li>
                <HtmlData tracking={GTM_TRACKING_TAGS['Next Delivery']} inline>
                  <Button
                    link
                    onClick={this.openNextDeliveryCollapse}
                    data-tracking={false}
                    className={styles.nextDelivery}
                    text={`${translateData('Next delivery:')} ${moment(
                      nextDelivery.date,
                    ).format('dddd MMMM DD')}`}
                  />
                </HtmlData>
              </li>
            )}
            {isAuthenticated ? authenticatedNavigation : navigation}
            {isAuthenticated && serviceLocationId && (
              <>
                <li>
                  <HtmlData tracking={GTM_TRACKING_TAGS['My Profile']}>
                    <Link to="/account/profile">
                      {translateData('My Profile')}
                    </Link>
                  </HtmlData>
                </li>
                <li>
                  <Button link text="Logout" onClick={logoutWithRedirect} />
                </li>
              </>
            )}
          </ul>
          <LanguageToggle mobileMenu />
        </div>
      </HtmlData>
    );

    const cancelButton = (
      <Button
        className={styles.cancel}
        link
        onClick={this.handleCancel}
        text="Cancel"
      />
    );
    return (
      <>
        {showAuthenticationOptions ? (
          <SwipeableViews animateHeight disabled index={swipableIndex}>
            <div>
              {showAuthenticationOptions && (
                <div className={styles.authHeader} id="auth-buttons">
                  <Button
                    text="Sign In"
                    className={styles.signinButton}
                    onClick={this.signInOnClick}
                  />
                  <Button
                    text="Sign Up"
                    className={styles.signupButton}
                    onClick={this.signUpOnClick}
                  />
                </div>
              )}
              {navigationComponent}
            </div>
            <div>
              <SignIn />
              {cancelButton}
            </div>
            <div>
              <SignUp />
              {cancelButton}
            </div>
          </SwipeableViews>
        ) : (
          navigationComponent
        )}
      </>
    );
  }
}

MenuContent.propTypes = {
  open: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  openCollapse: PropTypes.func.isRequired,
  closeCollapse: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  section: PropTypes.string,
  loginWithRedirect: PropTypes.func.isRequired,
  logoutWithRedirect: PropTypes.func.isRequired,
  serviceLocationId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  userInfo: selectUserInfo(),
  nextDelivery: selectNextDelivery(),
  section: selectSection(),
  serviceLocationId: selectServiceLocationId(),
});

export default withRouter(connect(mapStateToProps, null)(MenuContent));
