import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isEmpty, find } from 'lodash';
import isNumber from 'lodash/isNumber';
import { ButtonBase } from '@material-ui/core';
import { Counter, Button, H2, Div, Span } from 'components/Ui';
import { formatCurrency, isPastDeliveryCutOffDateTime } from 'utils/common';
import { withOrderUtils } from 'utils/withOrderUtils';
import { dataLayerPush } from 'utils/tracking';
import { getCurrencyCode } from 'utils/translation';
import { removeItem, changeItemQuantity } from 'containers/Cart/actions';
import { addToOrder } from 'containers/PrimoProducts/actions';
import {
  updateRecurringOrder,
  removeHold as removeHoldRequest,
} from 'containers/PrimoAccount/actions';
import { selectCostcoAccount } from 'containers/Landing/selectors';
import { selectCartId, selectCartItems } from 'containers/Cart/selectors';
import {
  selectNextDelivery,
  selectRecurringOrder,
  selectIsFirstDelivery,
  selectIsSelectedServiceLocationExcluded,
  selectSelectedServiceLocation,
  selectShopDeliveryOrder,
  selectIsSelectedServiceIndefiniteHold,
} from 'containers/PrimoAccount/selectors';
import {
  selectIsHardLockout,
  selectIsEquipmentPickupLockout,
} from 'containers/PrimoProfile/selectors';
import { createStructuredSelector } from 'reselect';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { ProductListContext } from 'containers/PrimoProducts/ProductListPage/ProductListPage';
import { FavoriteButton } from 'containers/PrimoProducts/Favorites';
import { getImage } from 'images';
import { isSsr } from 'utils/ssrHelper';
import CannotCancelDialog from 'components/Dialogs/CannotCancelDialog/Loadable';
import ProductBundle from './ProductBundle';
import FocusWithin from './FocusWithin';
import styles from './styles.scss';

const PRODUCT_IMG_WIDTH = 220;

const getProductInCart = (itemNumber, cartItems) =>
  find(cartItems, (item) => itemNumber === item.itemNumber);

const getQuantityInCart = (product, cartItems) => {
  let quantityInCart = 0;
  const match = getProductInCart(product.itemNumber, cartItems);
  if (match) {
    quantityInCart = match.quantity;
  }
  return quantityInCart;
};

class ProductGridItem extends React.Component {
  static contextType = ProductListContext;

  constructor(props) {
    super(props);

    this.state = {
      quantity: get(props, 'product.quantity', 1),
      // eslint-disable-next-line react/no-unused-state
      propQuantity: get(props, 'product.quantity'),
      cannotCancel: null,
    };

    this.handleUpdateItemQty = this.handleUpdateItemQty.bind(this);
    this.ref = React.createRef();
  }

  static getDerivedStateFromProps = (props, state) => {
    const productQty = get(props, 'product.quantity');

    if (productQty && productQty !== state.propQuantity) {
      return {
        quantity: productQty,
        propQuantity: productQty,
      };
    }

    return null;
  };

  isCannotCancelDialogOpen = () => !isEmpty(this.state.cannotCancel);

  handleUpdateCart = (e, handler) => {
    const { product } = this.props;
    const cannot = product?.isPPS || false;

    if (cannot) {
      this.setState({ cannotCancel: { handler } });
    } else {
      handler(e);
    }
  };

  clearCannotCancelState = () => this.setState({ cannotCancel: null });

  continueUpdateCart = (e) => {
    const {
      cannotCancel: { handler },
    } = this.state;

    this.clearCannotCancelState();
    handler(e);
  };

  closeCannotCancelDialog = (e) => {
    this.clearCannotCancelState();
  };

  getDataLayer = (event, section = this.props.section) => {
    const { quantity } = this.state;
    const { product, displayIndex } = this.props;
    const { itemNumber, name, brand, category, price, size } = product;
    return {
      event,
      section,
      id: itemNumber,
      name,
      brand: brand || '',
      category,
      variant: size,
      price,
      quantity,
      position: displayIndex + 1,
    };
  };

  handleUpdateOneTimeOrder = (event) => {
    const {
      itemNumber,
      name,
      brand,
      category,
      price,
      size,
    } = this.props.product;

    if (this.context.selectedProductRef && this.ref) {
      this.context.selectedProductRef.current = this.ref.current;
    }
    event.stopPropagation();
    const { handleAddItem, section } = this.props;
    const { quantity } = this.state;

    handleAddItem({
      deliveryAction: 'NEW',
      quantity: Math.max(quantity, 1),
      itemNumber,
      isRecurring: false,
    });

    dataLayerPush(
      'SelfServe',
      {
        event: 'add-to-one-time',
        section,
        id: itemNumber,
        name,
        brand: brand || '',
        category,
        variant: size,
        price,
        quantity,
        position: this.props.displayIndex + 1,
      },
      'dlS2',
      'PUSH_ADD_TO_ONE_TIME_ORDER',
    );
  };

  handleUpdateRecurringOrder = (event) => {
    event.stopPropagation();

    const {
      itemNumber,
      name,
      brand,
      category,
      price,
      size,
    } = this.props.product;

    if (this.context.selectedProductRef && this.ref) {
      this.context.selectedProductRef.current = this.ref.current;
    }
    const {
      handleUpdateRecurringOrder,
      product,
      recurringOrder = [],
      section,
      serviceLocation: { recurringDeliveryId },
    } = this.props;

    const { quantity } = this.state;
    const recurringItems = [
      ...recurringOrder,
      { ...product, quantity, itemId: product.id, recurringDeliveryId },
    ];
    const recurringOrderToUpdate = recurringItems.map((item) => ({
      itemId: item.itemId,
      itemNumber: item.itemNumber,
      quantity: item.quantity,
      recurringDeliveryId: item.recurringDeliveryId,
      deliveryFrequency: item.deliveryFrequency
        ? item.deliveryFrequency
        : 'Every Delivery',
    }));
    handleUpdateRecurringOrder(recurringOrderToUpdate, true);

    dataLayerPush(
      'SelfServe',
      {
        event: 'add-to-recurring-order',
        section,
        id: itemNumber,
        name,
        brand: brand || '',
        category,
        variant: size,
        price,
        quantity,
        position: this.props.displayIndex + 1,
      },
      'dlS3',
      'PUSH_ADD_TO_RECURRING_ORDER',
    );
  };

  pushDataLaterForAddToDeliveryOrder = (product, quantity) => {
    const { itemNumber, name, category, price, brand, size } = product;

    dataLayerPush(
      'SelfServe',
      {
        event: 'add-to-order',
        section: 'future delivery product list',
        id: itemNumber,
        name,
        brand: brand || '',
        category,
        variant: size,
        price,
        quantity,
        position: this.props.displayIndex + 1,
      },
      'dlS4',
    );
  };

  handleUpdateDeliveryOrder = (event) => {
    event.stopPropagation();
    if (this.context.selectedProductRef && this.ref) {
      this.context.selectedProductRef.current = this.ref.current;
    }
    const {
      addToDeliveryOrder,
      product,
      shopDeliveryOrder,
      isIndefiniteHold,
      removeHold,
    } = this.props;
    const { quantity } = this.state;

    if (!isIndefiniteHold && shopDeliveryOrder.holdId) {
      removeHold({
        deliveryOrder: shopDeliveryOrder,
        successCallback: () => {
          shopDeliveryOrder.skipDelivery = false;
          addToDeliveryOrder(shopDeliveryOrder, product, quantity);
          this.pushDataLaterForAddToDeliveryOrder(product, quantity);
        },
      });
    } else {
      addToDeliveryOrder(shopDeliveryOrder, product, quantity);
      this.pushDataLaterForAddToDeliveryOrder(product, quantity);
    }
  };

  pushAddToCart = (quantity) => {
    const {
      product: { itemNumber, name, brand, category, size, price, isBestSeller },
    } = this.props;

    // mmm aaa pushAddToCart
    dataLayerPush(
      'Acquisition',
      {
        event: 'addToCart',
        ecommerce: {
          currencyCode: getCurrencyCode(),
          add: {
            products: [
              {
                name,
                id: itemNumber,
                price,
                brand: brand || '',
                category: `${category}${isBestSeller ? ' | Best Seller' : ''}`,
                variant: size,
                quantity,
              },
            ],
          },
        },
      },
      'dlA1',
      'PUSH_ADD_TO_CART_DATA', // addItemToAnonymousCart OR createCart
    );
  };

  pushRemoveFromCart = (quantity) => {
    const {
      product: { itemNumber, name, brand, category, size, price },
    } = this.props;

    // mmm rrr pushRemoveFromCart
    dataLayerPush(
      'Acquisition',
      {
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: getCurrencyCode(),
          remove: {
            products: [
              {
                name,
                id: itemNumber,
                price,
                brand: brand || '',
                category,
                variant: size,
                quantity,
              },
            ],
          },
        },
      },
      'dlA3',
      'PUSH_REMOVE_FROM_CART_DATA',
    );
  };

  handleUpdateItemQty = (event) => {
    event.stopPropagation();
    if (this.context.selectedProductRef && this.ref) {
      this.context.selectedProductRef.current = this.ref.current;
    }
    const {
      handleRemoveItem,
      handleAddItem,
      handleChangeQty,
      cartId,
      product,
      product: { itemId, itemNumber, branchId },
      cartItems,
    } = this.props;
    const { quantity } = this.state;

    this.addToCart(
      product,
      cartItems,
      quantity,
      handleChangeQty,
      cartId,
      itemId,
      itemNumber,
      handleAddItem,
      branchId,
      handleRemoveItem,
    );
  };

  addBundleToCart = (event) => {
    event.stopPropagation();
    if (this.context.selectedProductRef && this.ref) {
      this.context.selectedProductRef.current = this.ref.current;
    }
    const {
      handleAddItem,
      handleChangeQty,
      cartId,
      product: bundle,
      cartItems,
      isQuickShopPage,
      isAllProductsPage,
    } = this.props;

    bundle.items.forEach((bundleItem, index) => {
      const productInCart = getProductInCart(
        bundleItem.fields.product.fields.itemNumber,
        cartItems,
      );
      const { product, quantity } = bundleItem.fields;
      const { itemNumber, branchId } = product.fields;

      const quantityInCart = getQuantityInCart(product.fields, cartItems);
      const hasItemInCart = quantityInCart > 0;

      if (hasItemInCart) {
        handleChangeQty({
          cartId,
          item: {
            id: productInCart?.id,
            itemNumber,
          },
          quantity: (productInCart?.quantity || 0) + quantity,
        });
      } else {
        handleAddItem({
          deliveryAction: 'NEW',
          quantity: (productInCart?.quantity || 0) + quantity,
          itemNumber,
          branchId,
        });
      }

      dataLayerPush(
        'Acquisition',
        {
          event: 'addToCart',
          ecommerce: {
            currencyCode: getCurrencyCode(),
            add: {
              products: [
                {
                  name: bundle.title,
                  id: itemNumber,
                  price: product.fields.price,
                  brand: product.fields.brand,
                  bundle_name: bundle.entryTitle,
                  category: `${product.fields.category} | Water Bundles`,
                  variant: product.fields.size,
                  list: isQuickShopPage
                    ? 'Quick Shop Water - Bundles'
                    : isAllProductsPage
                    ? 'All product - Bundles'
                    : 'Product List',
                  quantity,
                  position: index,
                },
              ],
            },
          },
        },
        'dlA1',
        'PUSH_ADD_TO_CART_DATA', // addItemToAnonymousCart OR createCart
      );
    });
  };

  setFocused = () => {
    this.props.changeFocusedProduct(this.props.product);
  };

  renderCostcoStartOrder = (
    cardClassName,
    imageSrc,
    imageAlt,
    isCostco,
    isEquipmentStep,
    quantityInCart,
    quantity,
    isNew,
  ) => {
    const { product, costcoAccount } = this.props;
    const productName = get(product, 'name');

    return (
      <FocusWithin>
        {({ focused, getRef }) => (
          <div
            ref={getRef}
            className={classnames(cardClassName, {
              [styles.productFocused]: focused,
            })}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
          >
            <div>
              <img
                className={styles.image}
                src={`${imageSrc}?fm=webp`}
                alt=""
                onFocus={this.setFocused}
              />
              <H2 className={styles.productTitle} defaultText={'C70'}>
                {productName}
              </H2>
              {!isEquipmentStep && (
                <Div className={styles.info} defaultText={'C71'}>
                  {product.size}
                </Div>
              )}
              {isNumber(product.price) && (
                <div className={styles.price}>
                  <Fragment>
                    {costcoAccount.validExecutive
                      ? formatCurrency(product.costcoExecutivePrice)
                      : formatCurrency(product.price)}
                  </Fragment>
                  {isEquipmentStep && (
                    <Span className={styles.textFee}>rental fee per bill</Span>
                  )}
                </div>
              )}
            </div>
            <div className={styles.quantityBlock}>
              <Div className={styles.quantity}>Quantity</Div>
              <Counter
                value={quantity}
                onChange={(e) => this.setState({ quantity: e })}
                minValue={quantityInCart === 0 ? 1 : 0}
                usePropsValue
                productTitle={productName}
              />
              <Button
                className={styles.btn}
                text={
                  quantityInCart === 0
                    ? 'Add to Cart'
                    : quantity > 0
                    ? 'Update Cart'
                    : 'Remove Item'
                }
                onClick={(e) =>
                  this.handleUpdateCart(e, this.handleUpdateItemQty)
                }
              />
            </div>
            {isNew && (
              <div className={styles.newBanner}>
                <Div className={styles.text}>New!</Div>
                <div className={styles.triangle} />
              </div>
            )}
            {quantityInCart > 0 && (
              <div className={styles.qtyBanner}>
                <div className={styles.text}>{quantityInCart}</div>
                <div className={styles.triangle} />
              </div>
            )}
          </div>
        )}
      </FocusWithin>
    );
  };

  addToCart(
    product,
    cartItems,
    quantity,
    handleChangeQty,
    cartId,
    itemId,
    itemNumber,
    handleAddItem,
    branchId,
    handleRemoveItem,
  ) {
    const quantityInCart = getQuantityInCart(product, cartItems);
    const hasItemInCart = quantityInCart > 0;
    const isAddingOrUpdating = quantity > 0;
    if (isAddingOrUpdating) {
      if (hasItemInCart) {
        if (quantity !== quantityInCart) {
          if (quantity > quantityInCart) {
            this.pushAddToCart(quantity - quantityInCart);
          } else {
            this.pushRemoveFromCart(quantityInCart - quantity);
          }
          handleChangeQty({
            cartId,
            item: { id: itemId, itemNumber },
            quantity,
          });
        }
      } else {
        this.pushAddToCart(quantity);
        handleAddItem({
          deliveryAction: 'NEW',
          quantity: Math.max(quantity, 1),
          itemNumber,
          branchId,
        });
      }
    } else {
      this.pushRemoveFromCart(quantityInCart);
      handleRemoveItem({ cartId, itemId });
    }

    this.setState({ quantity: quantity > 0 ? quantity : 1 });
  }

  render() {
    const {
      product,
      cartItems,
      className,
      onClick,
      isAuthenticated,
      isHardLockout,
      isFirstDelivery,
      isEquipmentPickupLockout,
      isSelectedServiceLocationExcluded,
      nextDelivery,
      recurringOrder,
      isCostco,
      page,
      costcoAccount,
      serviceLocation,
      shopDeliveryOrder,
      isInOrderDelivery,
      isQuickShopPage,
      isBundle,
      isFamilyBundle,
    } = this.props;
    const isOneTime = !!product.isOneTimePurchase;
    const disableEdit =
      isFirstDelivery ||
      isSelectedServiceLocationExcluded ||
      isEquipmentPickupLockout ||
      isHardLockout;
    const nextDeliveryItems = get(nextDelivery, 'items', []);
    const noNextDelivery = nextDeliveryItems.length === 0;
    const inNextDelivery =
      nextDeliveryItems.find(
        (item) => item.itemNumber === product.itemNumber,
      ) !== undefined;
    const inOrderDelivery = isInOrderDelivery(
      product.itemNumber,
      shopDeliveryOrder,
    );
    const recurringOrderItems = recurringOrder || [];
    const inRecurringOrder =
      recurringOrderItems.find(
        (item) => item.itemNumber === product.itemNumber,
      ) !== undefined;

    const { quantity } = this.state;
    const imageSrc = getImage(product, 'images[0].fields.file.url');
    const imageAlt = get(product, 'name');
    const isNew = get(product, 'isNew', false);
    const isEquipmentStep = page === 'select-equipment';
    const quantityInCart = getQuantityInCart(product, cartItems);

    const cardClassName = classnames(
      styles.productCard,
      { [styles.costcoProductCard]: isCostco },
      quantityInCart >= 1 ? styles.productCardActive : '',
      className,
    );

    const productTitle = get(product, 'name', '');
    const isPastCutOff = isPastDeliveryCutOffDateTime(serviceLocation);

    const counterDisabled = shopDeliveryOrder
      ? disableEdit || inOrderDelivery
      : disableEdit || (inNextDelivery && inRecurringOrder);

    const priceElement = isNumber(product.price) && (
      <div className={styles.price}>
        {isCostco && costcoAccount.validExecutive ? (
          <Fragment>{formatCurrency(product.costcoExecutivePrice)}</Fragment>
        ) : (
          <Fragment>{formatCurrency(product.price)}</Fragment>
        )}

        {isEquipmentStep && isCostco && (
          <Span className={styles.textFee}>rental fee per bill</Span>
        )}
      </div>
    );

    const productInfoElement = (
      <>
        <H2 className={styles.productTitle} defaultText={'C72'}>
          {productTitle}
        </H2>
        {!isEquipmentStep && product.size && (
          <Div className={styles.info} defaultText={'C73'}>
            {product.size}
          </Div>
        )}
        {isEquipmentStep && isCostco && <div></div>}
      </>
    );

    if (isBundle) {
      return (
        <ProductBundle
          document={product}
          onClick={this.addBundleToCart}
          isFamilyBundle={isFamilyBundle}
          isAllProductsPage={this.props.isAllProductsPage}
          isAuthenticated={isAuthenticated}
        />
      );
    }
    const onCardMouseEnter = () => {
      const {
        product: { itemNumber, name, brand, category, size, price },
      } = this.props;
      dataLayerPush(
        isAuthenticated ? 'SelfServe' : 'Acquisition',
        {
          event: 'view_item',
          ecommerce: {
            currencyCode: getCurrencyCode(),
            items: [
              {
                name,
                id: itemNumber,
                price,
                brand: brand || '',
                category,
                variant: size,
                quantity,
                list: 'Quick Shop Water',
              },
            ],
          },
        },
        'dlS2',
      );
    };
    return (
      (isSsr() || document) &&
      (isCostco ? (
        this.renderCostcoStartOrder(
          cardClassName,
          imageSrc,
          imageAlt,
          isCostco,
          isEquipmentStep,
          quantityInCart,
          quantity,
          isNew,
        )
      ) : (
        <ButtonBase
          component="div"
          className={cardClassName}
          onClick={onClick}
          ref={this.ref}
          data-tracking={`product-card ${product.name}`}
          onMouseEnter={onCardMouseEnter}
        >
          <CannotCancelDialog
            open={this.isCannotCancelDialogOpen()}
            item={product}
            onContinue={this.continueUpdateCart}
            onClose={this.closeCannotCancelDialog}
          />

          {isAuthenticated && (
            <FavoriteButton
              minimal
              item={{ itemId: product.id, itemNumber: product.itemNumber }}
              dataLayer={this.getDataLayer('add-item-to-favorites')}
            />
          )}
          {product.isBestSeller && (
            <span className={styles.bestSeller} data-tracking="best seller">
              BEST SELLER
            </span>
          )}
          <div
            className={classnames(styles.shortDescWrapper, {
              [styles.showShortDescWrapper]: !isQuickShopPage,
            })}
            data-tracking="product-short-description"
          >
            <img
              className={styles.image}
              src={`${imageSrc}?w=${PRODUCT_IMG_WIDTH}&fm=webp`}
              alt=""
            />
            {productInfoElement}
            {!isCostco && (
              <Div className={styles.info} defaultText={'C74'}>
                {product.count}
              </Div>
            )}
            {priceElement}
          </div>
          {isQuickShopPage && (
            <div
              data-tracking="product-long-description"
              className={styles.longDescWrapper}
            >
              {productInfoElement}
              <div className={styles.divider}></div>
              <div className={styles.longDesc}>{product.longDescription}</div>
              {priceElement}
            </div>
          )}
          <div className={styles.quantityBlock}>
            <Div className={styles.quantity}>Quantity</Div>
            {isAuthenticated && disableEdit ? (
              <div className={styles.quantityRO}>1</div>
            ) : (
              <Counter
                disabled={counterDisabled}
                value={quantity}
                onChange={(e) => this.setState({ quantity: e })}
                minValue={quantityInCart === 0 ? 1 : 0}
                usePropsValue
                productTitle={productTitle}
              />
            )}
            {!isAuthenticated && (
              <Button
                className={styles.btn}
                text={
                  quantityInCart === 0
                    ? 'Add to Cart'
                    : quantity > 0
                    ? 'Update Cart'
                    : 'Remove Item'
                }
                onClick={(e) =>
                  this.handleUpdateCart(e, this.handleUpdateItemQty)
                }
              />
            )}
            {isAuthenticated && (
              <div className={styles.ssButtonContainer}>
                <Div className={styles.subText}>Add this to</Div>
                {!shopDeliveryOrder && (
                  <Button
                    disabled={
                      disableEdit ||
                      isPastCutOff ||
                      inNextDelivery ||
                      noNextDelivery
                    }
                    className={classnames(styles.onetimeButton)}
                    text="One-Time Order"
                    onClick={(e) =>
                      this.handleUpdateCart(e, this.handleUpdateOneTimeOrder)
                    }
                  />
                )}
                {!shopDeliveryOrder && (
                  <Button
                    disabled={disableEdit || inRecurringOrder || isOneTime}
                    className={classnames(styles.recurringButton)}
                    text="Recurring Order"
                    onClick={(e) =>
                      this.handleUpdateCart(e, this.handleUpdateRecurringOrder)
                    }
                  />
                )}
                {shopDeliveryOrder && (
                  <Button
                    disabled={disableEdit || inOrderDelivery}
                    className={classnames(styles.thisOrderButton)}
                    text="This Order"
                    onClick={(e) =>
                      this.handleUpdateCart(e, this.handleUpdateDeliveryOrder)
                    }
                  />
                )}
              </div>
            )}
          </div>
          {isNew && (
            <div className={styles.newBanner}>
              <Div className={styles.text}>New!</Div>
              <div className={styles.triangle} />
            </div>
          )}
          {quantityInCart > 0 && (
            <div className={styles.qtyBanner}>
              <div className={styles.text}>{quantityInCart}</div>
              <div className={styles.triangle} />
            </div>
          )}
        </ButtonBase>
      ))
    );
  }
}

ProductGridItem.propTypes = {
  product: PropTypes.object.isRequired,
  className: PropTypes.string,
  cartId: PropTypes.string,
  onClick: PropTypes.func,
  handleAddItem: PropTypes.func,
  handleRemoveItem: PropTypes.func,
  handleChangeQty: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  handleUpdateRecurringOrder: PropTypes.func,
  isHardLockout: PropTypes.bool.isRequired,
  isFirstDelivery: PropTypes.bool.isRequired,
  isEquipmentPickupLockout: PropTypes.bool.isRequired,
  isSelectedServiceLocationExcluded: PropTypes.bool.isRequired,
  nextDelivery: PropTypes.object,
  isCostco: PropTypes.bool,
  page: PropTypes.string,
  recurringOrder: PropTypes.array,
  costcoAccount: PropTypes.object,
  changeFocusedProduct: PropTypes.func,
  serviceLocation: PropTypes.object,
  shopDeliveryOrder: PropTypes.object,
  displayIndex: PropTypes.number,
  isIndefiniteHold: PropTypes.bool,
};

ProductGridItem.defaultProps = {
  className: null,
  onClick: () => {},
  changeFocusedProduct: () => {},
};

const mapStateToProps = createStructuredSelector({
  cartId: selectCartId(),
  cartItems: selectCartItems(),
  isAuthenticated: selectIsAuthenticated(),
  recurringOrder: selectRecurringOrder(),
  isHardLockout: selectIsHardLockout(),
  isFirstDelivery: selectIsFirstDelivery(),
  isEquipmentPickupLockout: selectIsEquipmentPickupLockout(),
  isSelectedServiceLocationExcluded: selectIsSelectedServiceLocationExcluded(),
  nextDelivery: selectNextDelivery(),
  costcoAccount: selectCostcoAccount(),
  serviceLocation: selectSelectedServiceLocation(),
  shopDeliveryOrder: selectShopDeliveryOrder(),
  isIndefiniteHold: selectIsSelectedServiceIndefiniteHold(),
});

const mapDispatchToProps = {
  handleAddItem: addToOrder,
  handleRemoveItem: removeItem,
  handleChangeQty: changeItemQuantity,
  handleUpdateRecurringOrder: updateRecurringOrder,
  removeHold: removeHoldRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withOrderUtils,
)(ProductGridItem);
