import { dataLayerPush } from 'utils/tracking';

export const promoClick = (promoHandle) => () =>
  dataLayerPush('Acquisition', {
    event: 'promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            name: promoHandle,
            position: 'Special Offers Bar',
          },
        ],
      },
    },
  });

export default {
  promoClick,
};
