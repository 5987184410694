/*
 *
 * Account reducer
 *
 */

import { fromJS } from 'immutable';
import { omit } from 'lodash';
import {
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  LOAD_USER_ORDERS,
  LOAD_USER_ORDERS_SUCCESS,
  LOAD_USER_ORDERS_FAILURE,
} from 'containers/Account/constants';
import {
  FETCH_ORDER_PRODUCTS,
  FETCH_ORDER_PRODUCTS_SUCCESS,
  FETCH_ORDER_PRODUCTS_FAILURE,
} from 'containers/Cart/constants';

const initialState = fromJS({
  isFetching: false,
  userInfo: null,
  orders: [],
  error: null,
  selectedLocation: null,
});

const mergeProductData = (fields, items) =>
  fields.map((field) => {
    const item = items.find((elem) => elem.itemNumber === field.itemNumber);
    return { ...field, ...item };
  });

function accountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INFO:
      return state.merge(
        fromJS({
          isFetching: true,
          error: null,
        }),
      );
    case GET_USER_INFO_SUCCESS:
      return state.merge(
        fromJS({
          isFetching: false,
          userInfo: action.payload.userInfo,
          error: null,
        }),
      );
    case GET_USER_INFO_FAILURE:
      return state.merge(
        fromJS({
          isFetching: false,
          error: action.payload.errorMessage,
        }),
      );
    case LOAD_USER_ORDERS:
      return state.set('isFetching', true);
    case LOAD_USER_ORDERS_SUCCESS:
      return state.set('isFetching', false).set('orders', action.payload);
    case LOAD_USER_ORDERS_FAILURE:
      return state.set('isFetching', false).set('error', action.payload);
    case FETCH_ORDER_PRODUCTS:
      return state.set('isFetching', true);
    case FETCH_ORDER_PRODUCTS_SUCCESS: {
      const fields = action.payload.items.map((item) => ({
        ...omit(item.fields, 'images'),
        image: item.fields.images ? item.fields.images[0].fields.file : null,
      }));
      return state.set('isFetching', false).updateIn(['orders'], (orders) =>
        orders.map((order) =>
          Object.assign(order, {
            items: mergeProductData(fields, order.items),
          }),
        ),
      );
    }
    case FETCH_ORDER_PRODUCTS_FAILURE:
      return state.set('isFetching', false);
    default:
      return state;
  }
}

export default accountReducer;
