import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { letterCoffee, letterFilter, letterWater } from 'images/calendar';
import { badgeTypes } from './constants';
import styles from './styles.scss';
import wrapperStyles from './wrapperStyles.scss';

const getIcon = (badge) => {
  switch (badge) {
    case 'w':
      return letterWater;
    case 'f':
      return letterFilter;
    case 'c':
      return letterCoffee;
    default:
      return '';
  }
};

const getAltText = (badge, skipDeliveryHold) => {
  const deliveryType = skipDeliveryHold ? 'skipped' : 'scheduled';
  switch (badge) {
    case 'w':
      return `Water delivery ${deliveryType}`;
    case 'f':
      return `Filtration delivery ${deliveryType}`;
    case 'c':
      return `Coffee delivery ${deliveryType}`;
    default:
      return '';
  }
};

const DayWrapper = ({
  children,
  type,
  badges = [],
  calendarRender = false,
  skipDelivery,
  date,
}) => {
  let className;
  let showBadges = true;

  if (calendarRender && skipDelivery) {
    className = classnames(styles.holdDelivery, styles.holdDeliveryWithHover);
  } else {
    switch (type) {
      case badgeTypes.ONE_TIME:
        className = calendarRender
          ? styles.oneTimeDelivery
          : wrapperStyles.oneTimeDelivery;
        break;
      case badgeTypes.RESCHEDULED:
        className = calendarRender
          ? styles.rescheduledDelivery
          : wrapperStyles.rescheduledDelivery;
        break;
      case badgeTypes.RECURRING:
        className = calendarRender
          ? classnames(
              styles.recurringDelivery,
              styles.recurringDeliveryWithHover,
            )
          : wrapperStyles.recurringDelivery;
        break;
      case badgeTypes.HOLD:
        if (calendarRender) {
          showBadges = false;
        } else {
          className = wrapperStyles.holdDelivery;
        }
        break;
      default:
        showBadges = false;
        break;
    }
  }

  const tabbable = className && date;

  return (
    <div role="button" tabIndex={tabbable ? '0' : '-1'} className={className}>
      {children}
      {showBadges &&
        badges
          .slice(0, 3)
          .map((badge) => (
            <img
              key={badge}
              className={styles.badge}
              src={getIcon(badge)}
              alt={getAltText(badge, calendarRender && skipDelivery)}
            />
          ))}
    </div>
  );
};

DayWrapper.propTypes = {
  children: PropTypes.node,
  calendarRender: PropTypes.bool,
  type: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(PropTypes.string),
};

export default DayWrapper;
