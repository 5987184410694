import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get, isEmpty, some } from 'lodash';
import uuid from 'uuid/v4';
import classnames from 'classnames';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import Link from 'components/ContentfulFields/Link';
import FormsMapper from 'components/ContentfulFields/FormsMapper';
import DescriptionIcon from '@material-ui/icons/Description';
import { showCostcoMembershipDialog } from 'containers/Landing/actions';
import { selectAcquisitionVariables } from 'containers/Landing/selectors';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import { useMediaQuery } from '@material-ui/core';
import { Section } from 'components/Ui';
import { isSsr } from 'utils/ssrHelper';
import Button from '../Button';
import UlItem from '../UlItem';
import Banner from '../Banner';
import Animated from '../Animated';
import Counter from '../Counter';
import SprawlingImage from '../SprawlingImage';

const NonServicablePartnerBanner = ({
  document,
  stylesTheme,
  entryTitle,
  showCostcoMembership,
  costcoWaterEquipment,
  acquisitionVariables,
}) => {
  const styles = require(`./${stylesTheme}.scss`);
  const animation = get(document, 'fields.animation');

  const isTabletBelow = useMediaQuery('(max-width:1024px)');

  const onClickStartOrder = () => showCostcoMembership();
  const isServerRendering = isSsr();
  const options = {
    renderMark: {
      [MARKS.CODE]: (node) => <sub>{node}</sub>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'button':
            if (
              !isServerRendering &&
              window.isCostcoWater &&
              node.data.target.fields.text === 'Start Order'
            ) {
              delete node.data.target.fields.link;
              return (
                <Button
                  className={styles.btn}
                  document={node.data.target}
                  onClick={onClickStartOrder}
                >
                  {children}
                </Button>
              );
            }
            return (
              <Button
                className={classnames(styles.btn)}
                document={node.data.target}
              >
                {children}
              </Button>
            );
          case 'banner':
            return (
              <Banner
                stylesTheme={stylesTheme}
                document={node.data.target}
                entryTitle={entryTitle}
                key={node.data.target.sys.id}
                showCostcoMembership={showCostcoMembership}
                costcoWaterEquipment={costcoWaterEquipment}
                acquisitionVariables={acquisitionVariables}
              />
            );
          default:
            return null;
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        const embeddedType = get(embedded, 'type.name');

        if (embedded) {
          if (costcoWaterEquipment) {
            children[1].props.children[0] = (
              <span className={styles.link}>
                <DescriptionIcon /> {children[1].props.children[0]}
              </span>
            );
          }
          return (
            <div
              className={classnames({
                [styles.subtitle]: embeddedType === 'Link',
                [styles.pdfLink]: costcoWaterEquipment,
              })}
            >
              {children}
            </div>
          );
        }

        const childrenWithContent = children.filter(
          (childContent) => childContent !== '',
        );

        if (isEmpty(childrenWithContent)) return null;

        return (
          <div className={setTheme(styles.subtitle, document, styles)}>
            {childrenWithContent.map(
              (child) =>
                child &&
                child
                  .split('\n')
                  .filter((textRow) => textRow.length > 0)
                  .map((textRow) => <div key={uuid()}>{textRow}</div>),
            )}
          </div>
        );
      },
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className={styles.quote}>{children}</blockquote>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={setTheme(styles.title, document, styles)}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className={setTheme(styles.title, document, styles)}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={setTheme(styles.title, document, styles)}>{children}</h3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol type="a" className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ol>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'ulItem':
            return <UlItem {...node.data.target} />;
          case 'button':
            if (
              !isServerRendering &&
              window.isCostcoWater &&
              node.data.target.fields.text === 'Start Order'
            ) {
              delete node.data.target.fields.link;
              return (
                <Button
                  className={styles.btn}
                  document={node.data.target}
                  onClick={onClickStartOrder}
                >
                  {children}
                </Button>
              );
            }
            return (
              <Button className={styles.btn} document={node.data.target}>
                {children}
              </Button>
            );
          case 'form':
            return <FormsMapper type={node.data.target.fields.type} />;
          case 'counter':
            return (
              <Counter className={styles.counter} document={node.data.target} />
            );
          case 'sprawlingImage':
            return <Fragment />;
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: linkParser,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        return (
          <Link className={styles.link} to={`/${nodeFields.slug}`}>
            {children}
          </Link>
        );
      },
    },
  };

  const imagesOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <div>{children}</div>,
      [BLOCKS.LIST_ITEM]: (node, children) => <div>{children}</div>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (!get(node, 'data.target.fields.title', false)) {
          return null;
        }

        const {
          fields: { file, description },
        } = node.data.target;

        return (
          <div className={styles.embeddedAsset}>
            <Animated animation={animation}>
              <img src={`${file.url}?fm=webp`} alt={description} />
            </Animated>
          </div>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'sprawlingImage':
            return (
              <div className={styles.embeddedAsset}>
                <Animated animation={animation}>
                  <SprawlingImage document={node.data.target} />
                </Animated>
              </div>
            );
          default:
            return null;
        }
      },
      [BLOCKS.HEADING_1]: () => null,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        const hasLinks = some(node.content, ['nodeType', 'hyperlink']);

        if (embedded && !hasLinks) {
          return children;
        }

        return null;
      },
      [BLOCKS.HEADING_2]: () => null,
      [BLOCKS.HEADING_3]: () => null,
    },
  };

  const header = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(0, 1),
    },
    options,
  );
  const subText = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(1, 2),
    },
    options,
  );
  const phone = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(2, 3),
    },
    options,
  );
  const email = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(3, 4),
    },
    options,
  );
  const partnerImage = documentToReactComponents(
    document && {
      data: {},
      nodeType: 'document',
      content: document.fields.content.content.slice(4, 5),
    },
    imagesOptions,
  );

  const emailElement = get(email, '[0].props.children[0]');
  const hasEmail = Array.isArray(emailElement) && emailElement.length > 0;

  return (
    document && (
      <Section
        aria-label="bottom banner"
        className={styles.nonServicablePartnerBanner}
      >
        {isTabletBelow && <div className={styles.right}> {partnerImage}</div>}
        <Section className={styles.left}>
          {header}
          <div className={styles.subText}>{subText}</div>
          <Section
            className={classnames(styles.contactContainer, {
              [styles.contactContainerNoEmail]: !hasEmail,
            })}
            aria-label="contact information"
          >
            <Section
              className={styles.contactPhone}
              aria-label="contact by phone"
            >
              <div className={styles.icon}>
                <SmartphoneIcon />
              </div>
              <div className={styles.text}>
                <a href={`tel:+${phone}`}>{phone}</a>
              </div>
            </Section>
            {hasEmail && (
              <Section
                className={styles.contactEmail}
                aria-label="contact by email"
              >
                <div className={styles.icon}>
                  <EmailIcon />
                </div>
                <div className={styles.text}>{email}</div>
              </Section>
            )}
          </Section>
        </Section>
        {!isTabletBelow && (
          <Section className={styles.right} aria-label="image">
            {' '}
            {partnerImage}
          </Section>
        )}
      </Section>
    )
  );
};

NonServicablePartnerBanner.propTypes = {
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
  entryTitle: PropTypes.string,
  showCostcoMembership: PropTypes.func,
  costcoWaterEquipment: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  acquisitionVariables: selectAcquisitionVariables(),
});

const mapDispatchToProps = {
  showCostcoMembership: showCostcoMembershipDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NonServicablePartnerBanner);
