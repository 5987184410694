import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withTranslation from 'utils/withTranslation';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    height: 'auto',

    '&$disabled': {
      opacity: 0.4,
    },
  },
  disabled: {},
}));

const IconButton = ({ children, className, disabled, ...rest }) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={className}
      classes={{
        root: classes.root,
        disabled: classes.disabled,
      }}
      disabled={disabled}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};

export default withTranslation('aria-label')(IconButton);
