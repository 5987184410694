import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { LOCATION_KEY } from './constants';

const selectLocation = () => (state) => state.get(LOCATION_KEY, Map());

export const selectLocationData = () =>
  createSelector(selectLocation(), (location) => location.get('data'));

export const selectLocationCountryCode = () =>
  createSelector(selectLocationData(), (locationData) =>
    locationData.get('country_code'),
  );

export const selectZipCode = () =>
  createSelector(selectLocationData(), (locationData) =>
    locationData.get('zip'),
  );
