import React from 'react';
import { withStyles, ButtonBase } from '@material-ui/core';
import { loginURL, redirectToExternalURL } from 'utils/common';
import { isCanadianFrench, translateData } from 'utils/translation';
import accountNewImg from 'images/Account-New.svg';
import accountFrench from 'images/Account-French.png';

import styles from './styles.scss';

const MUIstyles = (theme) => ({
  flexContainer: {
    justifyContent: 'center',
  },
  tabsRoot: {
    borderRadius: '6px 6px 0 0',
    backgroundColor: theme.colors.primaryBlue,
  },
  tabsIndicator: {
    display: 'none',
  },
  tabRoot: {
    marginTop: 10,
    borderRadius: '8px 8px 0 0',
    minHeight: 38,
    color: theme.colors.white,
    opacity: 1,
    transition: 'all 0.2s ease',
  },
  tabSelected: {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
  },
  label: {
    fontSize: '1rem',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
});

const LoginButton = () => (
  <ButtonBase
    className={styles.loginBtn}
    onClick={() => redirectToExternalURL(loginURL())}
    id="menubutton"
  >
    <img
      src={isCanadianFrench() ? accountFrench : accountNewImg}
      alt={translateData('Account Options')}
      id="account_icon"
    />
  </ButtonBase>
);

export default withStyles(MUIstyles)(LoginButton);
