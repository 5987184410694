import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { translateElementChildren } from 'utils/translation';
import styles from './styles.scss';

const Note = ({ children, className, style, translationKey, defaultText }) => (
  <div className={classnames(styles.note, className)} style={style}>
    {translateElementChildren(children, translationKey, defaultText)}
  </div>
);

Note.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
  defaultText: PropTypes.string,
  translationKey: PropTypes.string,
};

export default Note;
