import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ children, to, ...props }) => {
  const re = /^(http|mailto|tel)/i;
  const isExternal = re.test(to);

  if (isExternal) {
    return (
      <a target="_blank" rel="noopener" {...props} href={to}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink {...props} to={to}>
      {children}
    </RouterLink>
  );
};

export default Link;
