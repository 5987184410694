import { translateData } from 'utils/translation';

// constants
export const CAT_EQUIPMENT = 'PV-Equipment';

const CATEGORIES = [CAT_EQUIPMENT];

// functions
const isCategoryMatch = (category) => (testString) =>
  new RegExp(translateData(category), 'i').test(testString);

export const isEquipment = isCategoryMatch(CAT_EQUIPMENT);

export default CATEGORIES;
