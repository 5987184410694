import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';

import { Input, Form, Button, ErrorMessage } from 'components/Ui';
import { login, logout } from 'containers/Authentication/actions';
import {
  selectLoginFetching,
  selectLoginError,
} from 'containers/Authentication/selectors';
import styles from 'containers/Authentication/styles.scss';

export class Login extends React.PureComponent {
  state = {
    username: '',
    password: '',
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    const { username, password } = this.state;
    const {
      location: { state = {} },
      redirectUrl,
    } = this.props;
    const returnTo = redirectUrl || state.prevPath;

    this.props.login({ username, password, returnTo });
  };

  render() {
    const {
      state: { username, password },
      props: { error, isFetching, className },
    } = this;

    return (
      <Form
        title="Sign In"
        className={className}
        onError={this.showError}
        onSubmit={this.handleSubmit}
      >
        {error && (
          <ErrorMessage style={{ marginBottom: '5px' }}>{error}</ErrorMessage>
        )}
        <Input
          label="Username or Email Address"
          validators={['required']}
          errorMessages={['']}
          value={username}
          onChange={this.handleChange}
          name="username"
        />
        <Input
          label="Password"
          validators={['required']}
          errorMessages={['']}
          value={password}
          type="password"
          onChange={this.handleChange}
          name="password"
        />
        <Grid container alignItems="flex-end" direction="column">
          <Link className={styles.link} to="/retrieve-password">
            Forgot your password?
          </Link>
          <Button
            className={styles.button}
            text="SIGN IN"
            loading={isFetching}
            style={{ minWidth: 144 }}
          />
        </Grid>
      </Form>
    );
  }
}

Login.propTypes = {
  isFetching: PropTypes.bool,
  error: PropTypes.string,
  login: PropTypes.func.isRequired,
  className: PropTypes.string,
  redirectUrl: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      prevPath: PropTypes.string,
    }),
  }),
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectLoginFetching(),
  error: selectLoginError(),
});

const mapDispatchToProps = { login, logout };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Login);
