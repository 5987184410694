import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { Button, Section } from 'components/Ui';
import { addPromotion } from 'containers/Cart/actions';
import {
  selectPromotionId,
  selectCartLoading,
} from 'containers/Cart/selectors';
import { selectPageSlug } from 'containers/Landing/selectors';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import uuid from 'uuid/v4';
import { isEmpty, some } from 'lodash';
import DescriptionIcon from '@material-ui/icons/Description';
import UlItem from 'components/ContentfulFields/UlItem';
import FormsMapper from 'components/ContentfulFields/FormsMapper';
import Counter from 'components/ContentfulFields/Counter';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import Link from 'components/ContentfulFields/Link';
import { getContentOptions } from '../CollapseBanner/parserOptions';

class NonServicableRestrictionsBanner extends Component {
  render() {
    const {
      document,
      stylesTheme,
      promotionId,
      isCartLoading,
      isAuthenticated,
      costcoWaterEquipment,
    } = this.props;

    const styles = require(`./${stylesTheme}.scss`);
    const applyPromotion = () => {
      this.props.addPromotion();
    };

    const options = {
      renderNode: {
        ...Object.assign(getContentOptions(styles, document).renderNode, {
          [INLINES.EMBEDDED_ENTRY]: (node) => {
            const id = get(node, 'data.target.sys.contentType.sys.id');

            switch (id) {
              case 'button':
                return (
                  <Button
                    className={classnames(styles.btn, {
                      [styles.hidden]: promotionId,
                    })}
                    document={node.data.target}
                    text={node.data.target.fields.text}
                    onClick={applyPromotion}
                    loading={isCartLoading}
                  />
                );
              default:
                return null;
            }
          },
        }),
        [BLOCKS.PARAGRAPH]: (node, children) => {
          const embedded = children.find((child) =>
            child ? child.props : false,
          );

          const embeddedType = get(embedded, 'type.name');

          if (embedded) {
            if (costcoWaterEquipment) {
              children[1].props.children[0] = (
                <span className={styles.link}>
                  <DescriptionIcon /> {children[1].props.children[0]}
                </span>
              );
            }
            return (
              <div
                className={classnames({
                  [styles.subtitle]: embeddedType === 'Link',
                  [styles.pdfLink]: costcoWaterEquipment,
                })}
              >
                {children}
              </div>
            );
          }

          const childrenWithContent = children.filter(
            (childContent) => childContent !== '',
          );

          if (isEmpty(childrenWithContent)) return null;

          return (
            <div className={setTheme(styles.subtitle, document, styles)}>
              {childrenWithContent.map(
                (child) =>
                  child &&
                  child
                    .split('\n')
                    .filter((textRow) => textRow.length > 0)
                    .map((textRow) => <div key={uuid()}>{textRow}</div>),
              )}
            </div>
          );
        },
        [BLOCKS.QUOTE]: (node, children) => (
          <blockquote className={styles.quote}>{children}</blockquote>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
          <h1 className={setTheme(styles.title, document, styles)}>
            {children}
          </h1>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <h2 className={setTheme(styles.title, document, styles)}>
            {children}
          </h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <h3 className={setTheme(styles.title, document, styles)}>
            {children}
          </h3>
        ),
        [BLOCKS.EMBEDDED_ASSET]: () => null, // JS: have to get image from here
        [BLOCKS.UL_LIST]: (node, children) => (
          <ul className={setTheme(styles.list, document, styles)}>
            {children.map((child) => (
              <li
                key={child.key}
                className={setTheme(styles.listItem, document, styles)}
              >
                {child.props.children}
              </li>
            ))}
          </ul>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
          <ol type="a" className={setTheme(styles.list, document, styles)}>
            {children.map((child) => (
              <li
                key={child.key}
                className={setTheme(styles.listItem, document, styles)}
              >
                {child.props.children}
              </li>
            ))}
          </ol>
        ),
        [INLINES.EMBEDDED_ENTRY]: (node, children) => {
          const id = get(node, 'data.target.sys.contentType.sys.id');

          switch (id) {
            case 'ulItem':
              return <UlItem {...node.data.target} />;
            case 'button':
              return (
                <Button className={styles.btn} document={node.data.target}>
                  {children}
                </Button>
              );
            case 'form':
              return <FormsMapper type={node.data.target.fields.type} />;
            case 'counter':
              return (
                <Counter
                  className={styles.counter}
                  document={node.data.target}
                />
              );
            case 'sprawlingImage':
              return <Fragment />;
            default:
              return null;
          }
        },
        [INLINES.HYPERLINK]: linkParser,
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          const { fields: nodeFields } = node.data.target;
          return (
            <Link className={styles.link} to={`/${nodeFields.slug}`}>
              {children}
            </Link>
          );
        },
      },
    };

    const imagesOptions = {
      renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <div>{children}</div>,
        [BLOCKS.LIST_ITEM]: (node, children) => <div>{children}</div>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          if (!get(node, 'data.target.fields.title', false)) {
            return null;
          }

          const {
            fields: { file, description },
          } = node.data.target;

          return (
            <div className={styles.embeddedAsset}>
              <img src={`${file.url}?fm=webp`} alt={description} />
            </div>
          );
        },
        [INLINES.EMBEDDED_ENTRY]: (node) => {
          const id = get(node, 'data.target.sys.contentType.sys.id');

          switch (id) {
            case 'sprawlingImage':
              return <div className={styles.embeddedAsset}></div>;
            default:
              return null;
          }
        },
        [BLOCKS.HEADING_1]: () => null,
        [BLOCKS.PARAGRAPH]: (node, children) => {
          const embedded = children.find((child) =>
            child ? child.props : false,
          );

          const hasLinks = some(node.content, ['nodeType', 'hyperlink']);

          if (embedded && !hasLinks) {
            return children;
          }

          return null;
        },
        [BLOCKS.HEADING_2]: () => null,
        [BLOCKS.HEADING_3]: () => null,
      },
    };

    const mainContent = documentToReactComponents(
      document && document.fields.content,
      options,
    );
    const imageContent = documentToReactComponents(
      document && document.fields.bottomContent,
      imagesOptions,
    );
    const images = documentToReactComponents(
      document && document.fields.content,
      imagesOptions,
    );

    return (
      !isAuthenticated &&
      document && (
        <main
          className={setTheme([styles.nonServicableBanner], document, styles)}
        >
          <Section
            className={styles.containerHeader}
            aria-label="We don't deliver to your area"
          >
            <div className={styles.images}>{images}</div>
            <p className={styles.textSection}> {mainContent}</p>
          </Section>
          <Section className={styles.containerSection}>{imageContent}</Section>
          <Section className={styles.bottomSection}>
            <a href="https://costcowaterdelivery.com/" target="_blank">
              Learn More
            </a>
          </Section>
        </main>
      )
    );
  }
}

NonServicableRestrictionsBanner.propTypes = {
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
  promotionId: PropTypes.number,
  addPromotion: PropTypes.func,
  isCartLoading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  promotionId: selectPromotionId(),
  isCartLoading: selectCartLoading(),
  pageSlug: selectPageSlug(),
  isAuthenticated: selectIsAuthenticated(),
});

const mapDispatchToProps = {
  addPromotion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NonServicableRestrictionsBanner);
