module.exports = (theme) => ({
  root: {
    '&$focused': {
      color: theme.colors.black,
    },
    '&$error': {
      color: theme.colors.lightRed,
    },
    fontFamily: theme.fonts.sansPro,
    fontSize: '0.9375rem',
    fontWeight: 600,
    position: 'static',
    transform: 'none',
    lineHeight: '1.1875rem',
    color: theme.colors.black,
    marginBottom: 3,
    display: 'block',
  },
  focused: {},
  error: {},
  disabledAsterisk: {
    display: 'none',
  },
});
