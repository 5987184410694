import uuid from 'uuid/v4';
import { isSsr } from 'utils/ssrHelper';

export const createDownloadLink = ({ data, fileName }) => {
  if (!isSsr()) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = fileName || `${uuid()}.pdf`;

    // Fix for Firefox
    link.dispatchEvent(
      new MouseEvent(`click`, {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
  }
};

export const openFile = ({ url, data }) => {
  if (!isSsr()) {
    const isChrome = !!window.chrome && !/Edge/.test(navigator.userAgent);
    const pdfBlob = new Blob([data], { type: 'application/pdf' });

    // IE Fix
    if (data && window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(pdfBlob);
      return;
    }

    const link = document.createElement('a');
    link.href = url || window.URL.createObjectURL(pdfBlob);
    link.target = isChrome ? '_blank' : '_self';

    // Firefox fix
    link.dispatchEvent(
      new MouseEvent(`click`, {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
  }
};
