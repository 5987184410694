import React from 'react';
import { SvgIcon } from '@material-ui/core';

const size = 20;

export const CheckedRadioIcon = (props) => (
  <SvgIcon
    {...props}
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
  >
    <g
      id={`${props.id}-icon`}
      stroke="none"
      strokeWidth=".66"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id={`${props.id}-error`}
        transform="translate(-354.000000, -276.000000)"
      >
        <g id={`${props.id}-copy`} transform="translate(312.000000, 37.000000)">
          <g
            id={`${props.id}-blue`}
            transform="translate(42.000000, 239.000000)"
          >
            <g
              id={`${props.id}-arrow`}
              fill="#FFFFFF"
              stroke="#000000"
              opacity="50%"
              strokeWidth=".88"
            >
              <circle
                id={`${props.id}-inner-oval`}
                cx={size / 2}
                cy={size / 2}
                r="9"
              ></circle>
            </g>
            <circle
              id={`${props.id}-outer-oval`}
              fill="#036DC2"
              cx={size / 2}
              cy={size / 2}
              r="5.25"
            ></circle>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const UncheckedRadioIcon = (props) => (
  <SvgIcon
    {...props}
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
  >
    <g
      id={`${props.id}-icon`}
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id={`${props.id}-error`}
        transform="translate(-477.000000, -276.000000)"
      >
        <g id={`${props.id}-copy`} transform="translate(312.000000, 37.000000)">
          <g
            id={`${props.id}-blue`}
            transform="translate(165.000000, 239.000000)"
            strokeWidth=".66"
          >
            <g
              id={`${props.id}-arrow`}
              fill="#FFFFFF"
              stroke="#000000"
              opacity="50%"
              strokeWidth=".8"
            >
              <circle
                id={`${props.id}-inner-oval`}
                cx={size / 2}
                cy={size / 2}
                r="9"
              ></circle>
            </g>
            <circle
              id={`${props.id}-outer-oval`}
              stroke="#999999"
              fill="#F4F5FA"
              cx={size / 2}
              cy={size / 2}
              r="5.25"
            ></circle>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
