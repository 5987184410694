import { select, put } from 'redux-saga/effects';
import { showSomethingWentWrong } from 'components/Notifier/actions';
import { selectShowSomethingWentWrongList } from 'containers/PrimoAccount/selectors';
import { isIE } from 'react-device-detect';
import { translateData } from 'utils/translation';
import { UNSUPPORTED_BROWSER } from 'utils/constants';
import { get, isEmpty } from 'lodash';
import { dataLayerPush } from 'utils/tracking';
import { getWindowObj } from 'utils/ssrHelper';

export const throwErrorTest = (message) => {
  let windowThrowErrorTestList;
  const windowObj = getWindowObj();
  if (windowObj.showSaga) {
    // eslint-disable-next-line no-console
    console.log('~ saga', message);
  }
  if (windowObj.throwErrorTestList) {
    windowThrowErrorTestList = windowObj.throwErrorTestList;
  }
  const throwErrorTestList = windowThrowErrorTestList || [''];
  if (
    throwErrorTestList &&
    (throwErrorTestList.includes(message) || throwErrorTestList.includes('all'))
  ) {
    // eslint-disable-next-line no-throw-literal
    throw { response: { body: { message } } };
  }
};

export function* handleError(funcName, type, message = 'Error', payload = {}) {
  const windowObj = getWindowObj();
  if (windowObj.showHandleError) {
    // eslint-disable-next-line no-console
    console.log('~ handleError', funcName, type, message, payload);
  }
  const browserNotSupported = isIE && funcName === 'fetchUserServiceLocations';

  if (browserNotSupported) {
    yield put({
      type,
      payload: UNSUPPORTED_BROWSER,
    });
  } else {
    const showSomethingWentWrongList = yield select(
      selectShowSomethingWentWrongList(),
    );
    const somethingWrong =
      !isEmpty(showSomethingWentWrongList) &&
      showSomethingWentWrongList.includes(funcName);

    if (somethingWrong) {
      yield put(showSomethingWentWrong(`${funcName}: ${message}`, type, true));
    } else {
      dataLayerPush(
        'Acquisition, SelfServe',
        {
          event: 'showError',
          type,
          message,
          session_id: window?.DD_RUM?.getInternalContext().session_id,
          payload,
        },
        'dlE1',
      );

      yield put({ type, payload: message });
    }
  }
}

export const getErrorMessage = (error) =>
  get(error, 'response.body.message') || get(error, 'message');
