import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';

import { Container, Wrapper, Form, Input, Button } from 'components/Ui';
import Loader from 'components/Loader';
import { selectIsLoading } from 'containers/Landing/selectors';
import styles from 'containers/Authentication/styles.scss';

class ResetPassword extends Component {
  state = {
    password: '',
    passwordConfirmation: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const {
      props: { loading },
      state: { password, passwordConfirmation },
    } = this;

    return (
      <Loader loading={loading}>
        <Container value="Reset Password" className={styles.container}>
          <Wrapper color="grey">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Form
                  className={styles.resetForm}
                  title="Create a New Password"
                  onError={this.showError}
                  onSubmit={this.handleSubmit}
                >
                  <p className={styles.info}>
                    We’ll ask for this password whenever you sign in.
                  </p>
                  <Input
                    label="New Password"
                    validators={['required']}
                    errorMessages={['']}
                    value={password}
                    type="password"
                    onChange={this.handleChange}
                    name="password"
                    autoComplete="off"
                  />
                  <Input
                    label="Re-enter Password"
                    validators={['required', 'isPasswordMatch']}
                    errorMessages={['', "Password doesn't match"]}
                    value={passwordConfirmation}
                    type="password"
                    onChange={this.handleChange}
                    name="passwordConfirmation"
                    autoComplete="off"
                  />
                  <Grid container alignItems="flex-end" direction="column">
                    <Link className={styles.link} to="/forgot-email">
                      Forgot your email address?
                    </Link>
                    <Button
                      className={styles.button}
                      text="Save Changes"
                      loading={false}
                      capitalize
                      style={{ minWidth: 176 }}
                    />
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </Wrapper>
        </Container>
      </Loader>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading(),
});

ResetPassword.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ResetPassword);
