import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';

import {
  Container,
  Wrapper,
  Form,
  Input,
  Button,
  ErrorMessage,
  SuccessMessage,
} from 'components/Ui';
import Loader from 'components/Loader';
import { selectIsLoading } from 'containers/Landing/selectors';
import {
  selectRestorePasswordFetching,
  selectRestorePasswordError,
  selectRestorePasswordMessage,
} from 'containers/Authentication/selectors';
import { restorePassword } from 'containers/Authentication/actions';
import styles from 'containers/Authentication/styles.scss';

class RetrievePassword extends Component {
  state = {
    email: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.props.restorePassword({ email: this.state.email });
  };

  render() {
    const {
      props: { loading, isFetching, error, message },
      state: { email },
    } = this;

    return (
      <Loader loading={loading}>
        <Container value="Retrieve Password" className={styles.container}>
          <Wrapper color="grey">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Form
                  className={styles.retrieveForm}
                  title="Password Assistance"
                  onError={this.showError}
                  onSubmit={this.handleSubmit}
                >
                  <p className={styles.info}>
                    Enter the email address you use to sign in to your account.
                    An email will be sent with a link to reset your password.
                  </p>
                  <Input
                    label="Email Address"
                    validators={['required', 'isEmail']}
                    errorMessages={['', 'Email is not valid']}
                    value={email}
                    onChange={this.handleChange}
                    name="email"
                  />
                  <Grid container alignItems="flex-end" direction="column">
                    <Link className={styles.link} to="/forgot-email">
                      Forgot your email address?
                    </Link>
                    <Button
                      className={styles.button}
                      text="CONTINUE"
                      loading={isFetching}
                      disabled={isFetching}
                      style={{ minWidth: 144 }}
                    />
                  </Grid>
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                  {message && <SuccessMessage>{message}</SuccessMessage>}
                </Form>
              </Grid>
            </Grid>
          </Wrapper>
        </Container>
      </Loader>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading(),
  isFetching: selectRestorePasswordFetching(),
  error: selectRestorePasswordError(),
  message: selectRestorePasswordMessage(),
});

const mapDispatchToProps = {
  restorePassword,
};

RetrievePassword.propTypes = {
  loading: PropTypes.bool.isRequired,
  restorePassword: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
  message: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RetrievePassword);
