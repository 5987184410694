import qs from 'qs';
import { isEmpty, isString, intersection, reduce } from 'lodash';
import { startsWithIgnoreCase } from 'utils/common';
import { CA_EN_URL_PREFIX, CA_FR_URL_PREFIX } from 'utils/translation';
import { PRODUCT_CATEGORIES, WATER_CATEGORIES } from './constants';

export const parseSearch = (location) =>
  qs.parse(location.search, { ignoreQueryPrefix: true });

export const hasIntersection = (arr1, arr2) =>
  !isEmpty(intersection(arr1, arr2));

export const parseSearchFilter = (location, isAuthenticated) => {
  const search = parseSearch(location);
  if (isAuthenticated) {
    return search;
  }
  if (
    startsWithIgnoreCase(CA_EN_URL_PREFIX, location.pathname) ||
    startsWithIgnoreCase(CA_FR_URL_PREFIX, location.pathname)
  ) {
    // eslint-disable-next-line no-unused-vars
    const [na, lang, products, categoryId] = location.pathname.split('/');
    if (na === '' && products === 'products') {
      search.categoryId = categoryId;
    }
    return search;
  }
  const [na, products, categoryId] = location.pathname.split('/');
  if (na === '' && products === 'products') {
    search.categoryId = categoryId;
  }
  return search;
};

export const getCategoryByName = (
  urlName,
  productCategories = PRODUCT_CATEGORIES,
  // eslint-disable-next-line consistent-return
) => {
  if (!isEmpty(productCategories) && !isEmpty(urlName)) {
    const key = urlName.toUpperCase();
    return productCategories.find(
      (cat) => cat.fields.urlName.toUpperCase() === key,
    );
  }
};

export const getCategoryId = (
  categoryId,
  productCategories = PRODUCT_CATEGORIES,
) => getCategoryByName(categoryId, productCategories)?.sys?.id || categoryId;

export const isEquipmentKind = (urlName, productCategories = []) =>
  isEmpty(productCategories)
    ? getCategoryByName(urlName)?.isEquipmentKind
    : getCategoryByName(urlName)?.isEquipmentKind &&
      getCategoryByName(urlName, productCategories);

export const isWaterBundlesKind = (urlName, productCategories) =>
  isEmpty(productCategories)
    ? getCategoryByName(urlName)?.isWaterBundlesKind
    : getCategoryByName(urlName)?.isWaterBundlesKind &&
      getCategoryByName(urlName, productCategories);

export const isWaterKind = (urlName, productCategories = []) =>
  isEmpty(productCategories)
    ? getCategoryByName(urlName, WATER_CATEGORIES)
    : getCategoryByName(urlName, WATER_CATEGORIES) &&
      getCategoryByName(urlName, productCategories);

export const getLoadProductsParams = (
  pageNum = 1,
  productCategories,
  searchParams,
) => ({
  categoryId: getCategoryId(searchParams?.categoryId || '', productCategories),
  brands: isString(searchParams.brands)
    ? [searchParams.brands]
    : searchParams.brands,
  waterTypes: isString(searchParams.waterTypes)
    ? [searchParams.waterTypes]
    : searchParams.waterTypes,
  page: pageNum,
});

export const processCategories = (categories) =>
  categories.map((item) => ({
    fields: {
      ...item.fields,
    },
    sys: item.sys,
    metadata: item.metadata,
  }));

export const processProductTypes = (productTypesEntries) =>
  reduce(
    productTypesEntries.items,
    (v, entry) => {
      v[entry.sys.id] = entry.fields;
      return v;
    },
    {},
  );
