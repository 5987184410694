import React from 'react';
import PropTypes from 'prop-types';
import { useDeepCompareEffect } from 'utils/hooks';
import { dataLayerPush } from 'utils/tracking';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { isEmpty, find } from 'lodash';
import { Button, Span } from 'components/Ui';
import { CircularProgress } from '@material-ui/core';
import {
  selectCartId,
  selectCartItems,
  selectOrderSubtotal,
  selectSummaryLoading,
} from 'containers/Cart/selectors';
import { removeItem, loadCartProducts } from 'containers/Cart/actions';
import { getToUrl } from 'utils/translation';
import { formatCurrency } from 'utils/common';
import ListItem from './ListItem';
import styles from './styles.scss';

const CartList = ({ cartId, items, subtotal, isSummaryLoading, ...props }) => {
  useDeepCompareEffect(() => {
    if (!isEmpty(items)) {
      props.loadCartProducts(items);
    }
  }, [items]);

  const handleRemove = (itemId) => {
    const found = find(items, (item) => itemId === item.id);
    const { itemNumber, name, brand, category, size, price, quantity } = found;
    // mmm rrr handleRemove
    dataLayerPush(
      'Acquisition',
      {
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name,
                id: itemNumber,
                price,
                brand: brand || '',
                category,
                variant: size,
                quantity,
              },
            ],
          },
        },
      },
      'dlA30',
      'PUSH_REMOVE_FROM_CART_DATA',
    );
    props.removeItem({ cartId, itemId });
  };

  return (
    <div className={styles.cart}>
      <div>
        {items &&
          items.map((item) => (
            <ListItem
              onDelete={handleRemove}
              key={item.itemNumber}
              product={item}
            />
          ))}
      </div>
      <div className={styles.billingInfo}>
        <div role="status" className={styles.subtotal}>
          <Span>Subtotal:</Span>{' '}
          <span>
            {isSummaryLoading || subtotal === undefined ? (
              <CircularProgress size={24} />
            ) : (
              formatCurrency(subtotal)
            )}
          </span>
        </div>
        <div className={styles.checkout}>
          <Link to={getToUrl('/cart', 'U7')}>
            <Span>View Cart</Span>
          </Link>
          <Button href="/checkout/delivery-information" text="Checkout" />
        </div>
      </div>
    </div>
  );
};

CartList.propTypes = {
  cartId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      itemNumber: PropTypes.string,
      deposit: PropTypes.number,
      price: PropTypes.number,
      quantity: PropTypes.number,
      relatedFee: PropTypes.number,
    }),
  ),
  subtotal: PropTypes.number,
  isSummaryLoading: PropTypes.bool,
  removeItem: PropTypes.func,
  loadCartProducts: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  cartId: selectCartId(),
  items: selectCartItems(),
  subtotal: selectOrderSubtotal(),
  isSummaryLoading: selectSummaryLoading(),
});
const mapDispatchToProps = {
  removeItem,
  loadCartProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartList);
