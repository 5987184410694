import { Map, fromJS } from 'immutable';
import { omit, get } from 'lodash';
import * as types from './constants';

const initialState = fromJS({
  loading: false,
  error: null,
  data: {
    items: [],
    billingAddress: {},
    contact: {},
    servicesAddresses: [],
    branchId: null,
    promotionId: null,
  },
  promotions: [],
  summary: {},
  isPromotionLoading: false,
  isCartLoaded: false,
  isSummaryLoading: false,
  isCartProductsLoading: false,
  isCartClear: false,
});

const mergeCartDataWithDefault = (data) => ({
  ...initialState.get('data', Map()).toJS(),
  ...data,
});

function cartReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USER_CART:
      return state.set('error', null).set('loading', true);
    case types.FETCH_USER_CART_SUCCESS:
      return state.merge(
        fromJS({
          loading: false,
          data: mergeCartDataWithDefault(action.payload),
          isCartLoaded: true,
        }),
      );
    case types.FETCH_ANONYMOUS_CART:
      return state.set('error', null).set('loading', true);
    case types.FETCH_USER_CART_FAILURE:
      return state
        .set('error', action.payload)
        .set('loading', false)
        .set('isCartLoaded', true);
    case types.CREATE_CART_SUCCESS:
    case types.FETCH_ANONYMOUS_CART_SUCCESS:
      return state.merge(
        fromJS({
          loading: false,
          data: mergeCartDataWithDefault(action.payload),
          isCartLoaded: true,
        }),
      );
    case types.CREATE_CART_FAILURE:
    case types.FETCH_ANONYMOUS_CART_FAILURE:
      return state.set('loading', false).set('isCartLoaded', true);

    case types.MERGE_ANON_CART:
      return state.set('error', null).set('loading', true);
    case types.MERGE_ANON_CART_SUCCESS:
      return state.merge(
        fromJS({
          loading: false,
          data: mergeCartDataWithDefault(action.payload),
          isCartLoaded: true,
        }),
      );
    case types.MERGE_ANON_CART_FAILURE:
      return state
        .set('error', action.payload)
        .set('loading', false)
        .set('isCartLoaded', false);
    case types.LOAD_CART_PRODUCTS:
      return state.set('error', null).set('isCartProductsLoading', true);
    case types.LOAD_CART_PRODUCTS_SUCCESS: {
      return state
        .set('isCartProductsLoading', false)
        .setIn(['data', 'items'], fromJS(action.payload.items));
    }
    case types.LOAD_CART_PRODUCTS_FAILURE:
      return state
        .set('error', action.payload)
        .set('isCartProductsLoading', false);
    case types.ADD_ITEM_TO_CART:
    case types.CREATE_CART:
    case types.ADD_ITEM_TO_ANONYMOUS_CART:
    case types.ADD_ITEM_TO_USER_CART:
      return state.set('error', null).set('loading', true);
    case types.ADD_ITEM_TO_ANONYMOUS_CART_SUCCESS:
    case types.ADD_ITEM_TO_USER_CART_SUCCESS: {
      const payloadItem = fromJS(action.payload);
      return state
        .set('loading', false)
        .updateIn(['data', 'items'], (items) => {
          const itemIndex = items.findIndex(
            (item) => item.get('id') === payloadItem.get('id'),
          );
          if (itemIndex === -1) {
            return items.push(payloadItem);
          }
          return items.setIn([itemIndex], payloadItem);
        });
    }
    case types.ADD_ITEM_TO_ANONYMOUS_CART_FAILURE:
    case types.ADD_ITEM_TO_USER_CART_FAILURE:
      return state.set('error', action.payload).set('loading', false);

    case types.REMOVE_ITEM:
      return state.set('error', null);
    case types.REMOVE_ITEM_SUCCESS:
      return state.updateIn(['data', 'items'], (items) =>
        items.filter((item) => item.get('id') !== action.payload),
      );
    case types.REMOVE_ITEM_FAILURE:
      return state.set('error', action.payload);
    case types.CHANGE_ITEM_QUANTITY:
      return state.set('error', null);
    case types.CHANGE_ITEM_QUANTITY_SUCCESS:
      return state.updateIn(['data', 'items'], (items) =>
        items.map((item) =>
          item.get('id') === action.payload.itemId
            ? item.set('quantity', action.payload.quantity)
            : item,
        ),
      );
    case types.CHANGE_ITEM_QUANTITY_FAILURE:
      return state.set('error', action.payload);
    case types.UPDATE_CART:
      return Object.entries(action.payload).reduce(
        (resultState, [formKey, values]) =>
          resultState.setIn(['data', formKey], fromJS(values)),
        state,
      );
    case types.GET_CART_SUMMARY:
      return (
        state
          // .set('error', null) // This interferes with createCart errors
          .set('loading', true)
          .set('isSummaryLoading', true)
      );
    case types.GET_CART_SUMMARY_SUCCESS: {
      const payloadItems = fromJS(action.payload.items);
      const mergedItems = state
        .getIn(['data', 'items'])
        .map((cartItem) =>
          cartItem.mergeDeep(
            payloadItems.find(
              (payloadItem) =>
                cartItem.get('itemNumber') === payloadItem.get('itemNumber'),
            ),
          ),
        );

      return state
        .set('loading', false)
        .set('isSummaryLoading', false)
        .set('summary', fromJS(omit(action.payload, 'items')))
        .setIn(['data', 'items'], mergedItems);
    }
    case types.GET_CART_SUMMARY_FAILURE:
      return state
        .set('error', action.payload)
        .set('loading', false)
        .set('isSummaryLoading', false);
    case types.CLEAR_CART:
      return state
        .set('data', initialState.get('data'))
        .set('isCartClear', false);
    case types.CLEAR_CART_FLAG:
      return state.set('isCartClear', true);
    case types.ADD_PROMOTION:
      return state
        .set('error', null)
        .set('loading', true)
        .set('isPromotionLoading', true);
    case types.ADD_PROMOTION_SUCCESS:
      return state
        .set('loading', false)
        .set('isPromotionLoading', false)
        .setIn(['data', 'promotionId'], action.payload);
    case types.ADD_PROMOTION_FAILURE:
      return state
        .set('error', action.payload)
        .set('isPromotionLoading', false)
        .set('loading', false);
    case types.REMOVE_PROMOTION:
      return state.set('loading', true);
    case types.REMOVE_PROMOTION_SUCCESS:
      return state.set('loading', false).setIn(['data', 'promotionId'], null);
    case types.REMOVE_PROMOTION_FAILURE:
      return state.set('error', action.payload).set('loading', false);
    case types.LOAD_PROMOTIONS:
      return state.set('error', null);
    case types.LOAD_PROMOTIONS_SUCCESS: {
      return state
        .setIn(['data', 'branchId'], get(action, 'payload.branchId'))
        .set('promotions', fromJS(get(action, 'payload.promotions')));
    }
    case types.LOAD_PROMOTIONS_FAILURE:
      return state.set('error', action.payload);
    case types.RESET_CART_ERROR:
      return state.set('error', null);
    default:
      return state;
  }
}

export default cartReducer;
