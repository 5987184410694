import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from 'components/Ui/Dialog';
import { ProcessingBlock } from 'components/Ui';
import { selectIsProcessing } from 'containers/PrimoProfile/selectors';

const ProcessingModal = () => {
  const isProcessing = useSelector(selectIsProcessing());

  return (
    <Dialog size="small" open={isProcessing} isCloseIcon={false}>
      <ProcessingBlock />
    </Dialog>
  );
};

export default ProcessingModal;
