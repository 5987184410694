import { takeLatest, call, put, all } from 'redux-saga/effects';
import ContentfulClient from 'utils/ContentfulClient';
import { getDomainByHost } from 'utils/domainHelper';
import { fetchSite as contentfulFetchSite } from 'utils/contentfulUtils';
import * as types from './constants';
import { fetchSiteSuccess, fetchSiteFailure } from './actions';

const contentfulClient = new ContentfulClient();

export function* fetchSite({ payload }) {
  try {
    const domain = getDomainByHost(21);

    const site = yield call(
      contentfulFetchSite,
      payload,
      domain,
      contentfulClient,
    );
    yield put(fetchSiteSuccess(site));
  } catch (error) {
    yield put(fetchSiteFailure(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(types.FETCH_SITE_REQUEST, fetchSite)]);
}
