/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import history from 'utils/history';
import clientLocationReducer from 'containers/ClientLocationProvider/reducer';
import authenticationReducer from 'containers/Authentication/reducer';
import landingReducer from 'containers/Landing/reducer';
import checkoutReducer from 'containers/CheckoutPage/reducer';
import cartReducer from 'containers/Cart/reducer';
import productsReducer from 'containers/PrimoProducts/reducer';
import drawerReducer from 'containers/DrawerManager/reducer';
import accountReducer from 'containers/Account/reducer';

import { LANDING_PAGE_KEY } from 'containers/Landing/constants';
import { AUTHENTICATION_PAGE_KEY } from 'containers/Authentication/constants';
import { CHECKOUT_PAGE_KEY } from 'containers/CheckoutPage/constants';
import { CART_PAGE_KEY } from 'containers/Cart/constants';
import { PRODUCTS_KEY } from 'containers/PrimoProducts/constants';
import { DRAWERS_STORE_KEY } from 'containers/DrawerManager/constants';
import { ACCOUNT_PAGE_KEY } from 'containers/Account/constants';
import { PROMOTION_DETAILS_KEY } from 'containers/PromotionDetails/constants';
import promotionsReducer from 'containers/PromotionDetails/reducer';
import { USER_REGION_KEY } from 'containers/UserRegion/constants';
import userRegionReducer from 'containers/UserRegion/reducer';
import { NOTIFICATIONS_KEY } from './components/Notifier/constants';
import notificationReducer from './components/Notifier/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const reducers = {
    clientLocation: clientLocationReducer,
    [AUTHENTICATION_PAGE_KEY]: authenticationReducer,
    [LANDING_PAGE_KEY]: landingReducer,
    [CART_PAGE_KEY]: cartReducer,
    [CHECKOUT_PAGE_KEY]: checkoutReducer,
    [NOTIFICATIONS_KEY]: notificationReducer,
    [PRODUCTS_KEY]: productsReducer,
    [DRAWERS_STORE_KEY]: drawerReducer,
    [ACCOUNT_PAGE_KEY]: accountReducer,
    [PROMOTION_DETAILS_KEY]: promotionsReducer,
    [USER_REGION_KEY]: userRegionReducer,
    ssr: (state) => state,
    router: connectRouter(history),
    ...injectedReducers,
  };

  return combineReducers(reducers);
}
