import { ACCESS_TOKEN_KEY } from 'global-constants';
import jwtDecode from 'jwt-decode';
import { generateNonce } from 'utils/nonce';

export function getParsedAccessToken() {
  const accessToken = getAccessToken();
  if (accessToken) {
    return jwtDecode(sessionStorage.getItem(ACCESS_TOKEN_KEY));
  }
  return {};
}

export function getAccessToken() {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY);
}

export function setAccessToken(token) {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function removeAccessToken() {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function storeLastLocation(returnTo = '/') {
  const nonce = generateNonce();
  sessionStorage.setItem(nonce, returnTo);

  return nonce;
}

export function getLastLocation(nonce) {
  const returnTo = sessionStorage.getItem(nonce) || '/';
  sessionStorage.removeItem(nonce);

  return returnTo;
}
