/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { omit, mapValues, get, isObject } from 'lodash';
import axios from 'axios';
import classNames from 'classnames';
import { Grid, FormGroup, FormControl } from '@material-ui/core';
import {
  Input,
  Select,
  Button,
  FormCheckbox,
  Form,
  PhoneInput,
  FormLabel,
} from 'components/Ui';
import FormError from 'components/Forms/FormError';
import * as options from 'utils/selectOptions';
import { isSsr } from 'utils/ssrHelper';
import { translateData } from 'utils/translation';
import { PARDOT_SLUGS } from 'containers/Landing/constants';
import { getDomainByHost } from 'utils/domainHelper';
import { dataLayerPush } from 'utils/tracking';

import styles from './styles.scss';

class ServiceForm extends PureComponent {
  state = {
    first_name: '',
    last_name: '',
    phone: '',
    phone_ext: '',
    email: '',
    company: '',
    address_one: '',
    city: '',
    state: '',
    zip: '',
    location: '',
    num_of_people: '',
    num_of_locations: '',
    coffee_service: false,
    tea_service: false,
    water_filtration: false,
    water_delivery: false,
    emailme: false,
    comments: '',
    lead_source: window.location.hostname,
    isErrorShown: false,
    isLoading: false,
  };

  handleChange = (valueObj, fieldName) => {
    const name = isObject(valueObj) ? get(valueObj, 'target.name') : fieldName;
    const value = isObject(valueObj) ? get(valueObj, 'target.value') : valueObj;

    this.setState({ [name]: value });
  };

  handleChangeCheckbox = ({ target: { checked, name } }) => {
    this.setState({ [name]: checked });
  };

  handleSubmit = () => {
    const {
      water_delivery,
      coffee_service,
      water_filtration,
      emailme,
      tea_service,
    } = this.state;

    let service = water_delivery ? 'Bottled Water Delivery' : '';
    service = coffee_service ? `${service}, Coffee` : service;
    service = water_filtration ? `${service}, Filtration` : service;
    service = emailme ? `${service}, News and Offers` : service;
    service = tea_service ? `${service}, Tea` : service;

    dataLayerPush(
      'Acquisition',
      {
        event: 'Service Form Submit',
        service,
      },
      'dlA31',
    );

    this.toggleLoading();

    const data = mapValues(
      omit(this.state, ['isErrorShown', 'isLoading']),
      (formValue) => formValue.value || formValue,
    );

    if (!isSsr()) {
      const domain = getDomainByHost(4);
      const pardotSlug = PARDOT_SLUGS[domain.slug];

      axios.post(`pardot/${this.props.url}/${pardotSlug}`, data).then(() => {
        this.toggleLoading();
        this.props.handleSubmit();
      });
    }
  };

  showError = () => {
    this.setState({ isErrorShown: true });
  };

  toggleLoading = () => {
    this.setState((prevState) => ({
      isLoading: !prevState.isLoading,
      isErrorShown: false,
    }));
  };

  render() {
    const {
      first_name,
      last_name,
      phone,
      phone_ext,
      email,
      company,
      address_one,
      city,
      state,
      zip,
      location,
      num_of_people,
      num_of_locations,
      coffee_service,
      tea_service,
      water_filtration,
      water_delivery,
      emailme,
      comments,
      isErrorShown,
      isLoading,
    } = this.state;

    return (
      <Form
        className={styles.container}
        onError={this.showError}
        onSubmit={this.handleSubmit}
        color="grey"
      >
        {isErrorShown && (
          <FormError className={classNames(styles.error, styles.errorTop)} />
        )}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Input
              label="*First Name:"
              validators={['required']}
              errorMessages={['']}
              value={first_name}
              onChange={this.handleChange}
              name="first_name"
              disableAsterisk
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="*Last Name:"
              validators={['required']}
              errorMessages={['']}
              value={last_name}
              onChange={this.handleChange}
              name="last_name"
              disableAsterisk
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <PhoneInput
              label="*Phone:"
              name="phone"
              value={phone}
              onChange={this.handleChange}
              disableAsterisk
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <Input
              label="Ext:"
              value={phone_ext}
              onChange={this.handleChange}
              name="phone_ext"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="*Email Address:"
              validators={['required', 'isEmail']}
              errorMessages={['', 'Email is not valid']}
              value={email}
              onChange={this.handleChange}
              name="email"
              disableAsterisk
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Company:"
              value={company}
              onChange={this.handleChange}
              name="company"
            />
          </Grid>
          <Grid item md={6} />
          <Grid item xs={12} md={6}>
            <Input
              label="*Street Address:"
              validators={['required']}
              errorMessages={['']}
              value={address_one}
              onChange={this.handleChange}
              name="address_one"
              disableAsterisk
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="*City:"
              validators={['required']}
              errorMessages={['']}
              value={city}
              onChange={this.handleChange}
              name="city"
              disableAsterisk
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label="*State:"
              validators={['required']}
              errorMessages={['']}
              value={state}
              items={options.US_REGIONS}
              onChange={this.handleChange}
              name="state"
              icon="primaryArrow"
              placeholder="Select State"
              disableAsterisk
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              label="*ZIP Code:"
              validators={['required', 'zip']}
              errorMessages={['', 'ZIP is not valid']}
              value={zip}
              onChange={this.handleChange}
              name="zip"
              disableAsterisk
            />
          </Grid>
          <hr />
          <Grid item xs={12} md={4}>
            <Select
              label="Location:"
              value={location}
              items={options.CONTACT_REGARDING}
              onChange={this.handleChange}
              name="location"
              icon="primaryArrow"
              placeholder="Select One"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Number of Locations:"
              value={num_of_locations}
              items={options.LOCATIONS_NUMBER}
              onChange={this.handleChange}
              name="num_of_locations"
              icon="primaryArrow"
              placeholder="Select One"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Number of People:"
              value={num_of_people}
              items={options.PEOPLE_NUMBER}
              onChange={this.handleChange}
              name="num_of_people"
              icon="primaryArrow"
              placeholder="Select One"
            />
          </Grid>
          <hr />
          <FormControl className={styles.checkboxContainer}>
            <FormLabel label="I'm interested in:" />
            <FormGroup
              row
              aria-label={translateData('services')}
              name="checkboxes"
            >
              <FormCheckbox
                checked={coffee_service}
                name="coffee_service"
                onChange={this.handleChangeCheckbox}
                label="Coffee Service"
                size="huge"
              />
              <FormCheckbox
                checked={tea_service}
                name="tea_service"
                onChange={this.handleChangeCheckbox}
                label="Tea"
                size="huge"
              />
              <FormCheckbox
                checked={water_filtration}
                name="water_filtration"
                onChange={this.handleChangeCheckbox}
                label="Filtration"
                size="huge"
              />
              <FormCheckbox
                checked={water_delivery}
                name="water_delivery"
                onChange={this.handleChangeCheckbox}
                label="Bottled Water Delivery"
                size="huge"
              />
              <FormCheckbox
                checked={emailme}
                name="emailme"
                onChange={this.handleChangeCheckbox}
                label="News and Offers"
                size="huge"
              />
            </FormGroup>
          </FormControl>
          <hr />
          <Grid item xs={12} md={12}>
            <Input
              label="Questions/Comments:"
              multiline
              rows={8}
              value={comments}
              onChange={this.handleChange}
              name="comments"
              minHeight={165}
            />
          </Grid>
          <Grid item className={styles.formFooter}>
            <Button
              text="SUBMIT"
              loading={isLoading}
              disabled={isLoading}
              className={styles.submit}
            />
          </Grid>
        </Grid>
        {isErrorShown && (
          <FormError className={classNames(styles.error, styles.errorBottom)} />
        )}
      </Form>
    );
  }
}

ServiceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default ServiceForm;
