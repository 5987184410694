import React from 'react';
import PropTypes from 'prop-types';
import {
  FormError,
  ForgotPassword,
  WaterZipForm,
  CoolerCleaningServiceStep1,
  CoolerCleaningServiceStep2,
  CoolerCleaningServiceStep3,
} from 'components/Forms';

const FormsMapper = ({ type, document }) => {
  switch (type) {
    case 'waterZip':
      return <WaterZipForm view="block" />;
    case 'formError':
      return <FormError />;
    case 'forgotPassword':
      return <ForgotPassword />;
    case 'coolerCleaningServiceStep1':
      return <CoolerCleaningServiceStep1 document={document} />;
    case 'coolerCleaningServiceStep2':
      return <CoolerCleaningServiceStep2 document={document} />;
    case 'coolerCleaningServiceStep3':
      return <CoolerCleaningServiceStep3 document={document} />;
    default:
      return null;
  }
};

FormsMapper.propTypes = {
  type: PropTypes.string.isRequired,
  document: PropTypes.object,
};

export default FormsMapper;
