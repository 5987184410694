import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Checkbox, Button } from 'components/Ui';
import { warningRedSmall } from 'images';
import {
  BILLING_TYPE_ANOTHER,
  BILLING_TYPE_SHIPPING,
} from 'containers/PrimoCheckoutPage/constants';
import commonStyles from 'containers/PrimoCheckoutPage/styles.scss';
import { isValidCardNumber } from 'utils/formValidators';
import styled from 'styled-components';
import { requiredCardFields, requiredBillingFields } from '../utils';
import CreditCardForm from './CreditCardForm';
import styles from './styles.scss';

const ValidatePaymentForm = ({
  onSubmit,
  onBack,
  storedValues,
  errors,
  isProcessing,
  changeCanGoForward,
  forwardedRef,
  isDrawer = false,
}) => {
  const defaultValues = useMemo(
    () => ({
      cardholderName: '',
      cardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      address1: '',
      address2: '',
      city: '',
      stateOrProvinceCode: '',
      postalCode: '',
      countryCode: 'US',
      billingType: BILLING_TYPE_ANOTHER,
    }),
    [],
  );

  const [values, changeValues] = useState({
    ...defaultValues,
    ...storedValues,
  });
  const showPaymentView = false;
  const isFilledCard =
    showPaymentView ||
    (!showPaymentView && requiredCardFields.every((field) => values[field]));
  const isFilledBilling =
    values.billingType === BILLING_TYPE_SHIPPING ||
    (values.billingType === BILLING_TYPE_ANOTHER &&
      requiredBillingFields.every((field) => values[field]));
  const isFilled =
    isFilledBilling && isFilledCard && isValidCardNumber(values.cardNumber);

  useEffect(() => {
    if (!isDrawer) {
      changeCanGoForward(isFilled);
    }
  }, [isFilled]);

  const handleChange = ({ target: { name, value } }) => {
    changeValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit({ values, isSaveCard: !showPaymentView });
  };
  const WarningIcon = styled.img`
    margin-bottom: 2px;
  `;
  return (
    <ValidatorForm onSubmit={handleSubmit} ref={forwardedRef}>
      <div className={styles.validateMessage} role="alert">
        <div>
          <WarningIcon src={warningRedSmall} alt="Error" />
        </div>
        <div className={styles.validateText}>
          This payment method could not be processed. Please re-enter the
          information or enter another card.
        </div>
      </div>
      <div className={styles.block}>
        <CreditCardForm
          values={values}
          errors={errors}
          handleChange={handleChange}
          isDrawer={isDrawer}
          showAsValidateError
        />
      </div>
      <div className={styles.block}>
        <Checkbox
          disabled
          isRectangle
          defaultChecked
          onChange={() => {}}
          label={
            <span className={styles.checkbox}>
              {values.billingType === BILLING_TYPE_SHIPPING
                ? 'Use my delivery address'
                : 'Use another address'}
            </span>
          }
        />
      </div>
      <div className={classnames(commonStyles.actions, styles.block)}>
        <Button
          text="Cancel"
          color="gray"
          type="button"
          onClick={onBack}
          disabled={isProcessing}
        />
        <Button text="Save" disabled={!isFilled} loading={isProcessing} />
      </div>
    </ValidatorForm>
  );
};

ValidatePaymentForm.propTypes = {
  storedValues: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  changeCanGoForward: PropTypes.func,
  isProcessing: PropTypes.bool,
  forwardedRef: PropTypes.object,
  isDrawer: PropTypes.bool,
};

export default ValidatePaymentForm;
