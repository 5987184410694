import React from 'react';
import PropTypes from 'prop-types';
import { Animated } from 'react-animated-css';
import { Div } from 'components/Ui';
import { ThumbUpGreen, LetterGreen } from 'images';
import styles from './styles.scss';

const images = {
  letter: LetterGreen,
  thumb: ThumbUpGreen,
};

const SuccessBlock = ({ text = 'Success', image, imageAnimation }) => {
  const lines = typeof text === 'string' ? [text] : text;

  return (
    <Animated isVisible animationIn="pulse" animationOut="pulse">
      <div className={styles.container}>
        <img
          className={styles[imageAnimation]}
          src={images[image]}
          alt="thumb up"
        />
        {lines.map((line) => (
          <Div>{line}</Div>
        ))}
      </div>
    </Animated>
  );
};

SuccessBlock.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  image: PropTypes.string,
  imageAnimation: PropTypes.string,
};

SuccessBlock.defaultProps = {
  image: 'thumb',
  imageAnimation: null,
};

export default SuccessBlock;
