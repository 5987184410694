import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import { translateData } from 'utils/translation';
import styles from './styles.scss';

const CollapseButton = ({
  onToggle,
  children,
  className,
  isActive,
  ariaHaspopup,
  ariaExpanded,
  disabled,
}) => {
  const classname = classNames(className, styles.label, {
    [styles.active]: isActive,
  });

  return (
    <ButtonBase
      disabled={disabled}
      className={classname}
      onClick={onToggle}
      aria-haspopup={ariaHaspopup}
      aria-expanded={ariaExpanded}
      disableRipple
    >
      {translateData(children, 'C43')}
    </ButtonBase>
  );
};

CollapseButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  ariaHaspopup: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CollapseButton;
