import { put } from 'redux-saga/effects';
import { showDrawer, hideDrawer } from 'containers/DrawerManager/actions';

export function showDrawerSaga(drawerName) {
  return function* innerShowDrawer() {
    yield put(showDrawer(drawerName));
  };
}

export function hideDrawerSaga(drawerName) {
  return function* innerHideDrawer() {
    yield put(hideDrawer(drawerName));
  };
}
