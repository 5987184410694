import React from 'react';
import PropTypes from 'prop-types';
import { Divider, withStyles } from '@material-ui/core';
import { get, filter } from 'lodash';
import { H2 } from 'components/Ui';
import { ALL_BRANDS, US_BRANDS, CA_BRANDS } from 'utils/domainHelper';
import { getPrimoBrandName } from 'utils/translation';
import FAQItem from './FAQItem';
import styles from './styles.scss';

const muiStyles = (theme) => ({
  divider: {
    margin: '0 0 65px',
  },
  faqCategoryContainer: {
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    '&&:first-child': {
      '&& hr': {
        display: 'none',
      },
    },
  },
});

const isSupportedFAQ = (item) => {
  let supportedBrands = get(item, 'fields.supportedBrands', 'All Brands');
  supportedBrands =
    supportedBrands === 'US Brands'
      ? US_BRANDS
      : supportedBrands === 'CA Brands'
      ? CA_BRANDS
      : ALL_BRANDS;
  const brandName = getPrimoBrandName();
  return supportedBrands.includes(brandName);
};

//
const FaqCategory = ({ faqItems, title, classes }) => (
  <div className={classes.faqCategoryContainer}>
    <Divider classes={{ root: classes.divider }} variant="middle" />
    <div className={styles.faqCategory}>
      <H2 className={styles.categoryTitle}>{title}</H2>
      {filter(faqItems, isSupportedFAQ).map(({ fields, sys }) => (
        <FAQItem item={fields} key={sys.id} />
      ))}
    </div>
  </div>
);

FaqCategory.propTypes = {
  faqItems: PropTypes.array,
  title: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(muiStyles)(FaqCategory);
