import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Step,
  StepLabel,
  Stepper as MUIStepper,
  useMediaQuery,
} from '@material-ui/core';

import { translateData } from 'utils/translation';
import StepConnector from './Connector';
import StepIcon from './StepIcon';

const StepLabelContainer = styled(StepLabel)``;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'inherit',
    marginLeft: '13%',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
  },
  completed: {},
  instructions: {},
  label: {
    '&$alternativeLabel': {
      marginTop: '5px',
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '0.9375rem',
      textAlign: 'center',
      color: theme.colors.river,
    },
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: 0,
      right: 0,
    },
  },
  alternativeLabel: {},
  horizontal: {
    padding: 0,
  },
  labelText: {
    color: theme.colors.river,
  },
}));

const Stepper = ({ steps, activeStep, onClick, isCostco, isDisplayOnly }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MUIStepper
      alternativeLabel
      activeStep={activeStep}
      className={classes.root}
      connector={<StepConnector />}
    >
      {steps.map(({ key, title, costcoTitle }) => {
        const isActive = key === steps[activeStep].key;
        const handleClickStep = () => onClick(key);

        const mobileTitle = title;

        const mobileCostcoTitle = costcoTitle;

        return (
          <Step
            key={key}
            classes={{
              horizontal: classes.horizontal,
            }}
            isdisplayonly={(!!isDisplayOnly).toString()}
          >
            <StepLabelContainer
              classes={{
                label: classes.label,
                alternativeLabel: classes.alternativeLabel,
              }}
              onClick={handleClickStep}
              StepIconComponent={StepIcon}
              StepIconProps={{ active: isActive, step: title, isDisplayOnly }}
            >
              {!isCostco ? (
                <span className={classes.labelText}>
                  {translateData(isMobile ? mobileTitle : title)}
                </span>
              ) : (
                <span className={classes.labelText}>
                  {isMobile ? mobileCostcoTitle : costcoTitle}
                </span>
              )}
            </StepLabelContainer>
          </Step>
        );
      })}
    </MUIStepper>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  activeStep: PropTypes.number,
  onClick: PropTypes.func,
  isCostco: PropTypes.bool,
  isDisplayOnly: PropTypes.bool,
};

export default Stepper;
