export const PRODUCTS_PER_PAGE = 20;
export const PROMOTION_LEARN = 'learn-promotion';
export const PROMOTION_REDEEM = 'redeem-promotion';
export const PROMOTION_APPLIED = 'promotion-applied';
export const QUICK_SHOP = 'quick-shop';
export const QUICK_SHOP_COSTCO = 'quick-shop-costco';
export const QUICK_SHOP_KEY = 'quickshop';
export const REVIEW_ORDER = 'review-cart';
export const SELECT_EQUIPMENT = 'select-equipment';
export const SELECT_EQUIPMENT_COSTCO = 'select-equipment-costco';
export const SELECT_WATER = 'select-water';
export const SKIP_EQUIPMENT = 'skip-equipment';
export const VIEW_CART = 'cart';
export const COSTCO_PROMOTION_LEARN = 'costco-learn-promotion';
export const PROMO_NAME = '$_50 OFF FIRST INVOICE';
export const pages = [SELECT_EQUIPMENT, SELECT_WATER];
