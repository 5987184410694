import React from 'react';
import { oneOfType, array, node, string } from 'prop-types';
import withRestrictToBrands from './withRestrictToBrands';

/**
 * @param {object} props - props
 * @param {string[]|string} [props.brands] - single brand or array of brands
 * @param {ReactChildren} [props.children]
 * @return {ReactElement}
 */
const RestrictToBrands = (props) => {
  const WithRestrictToBrands = withRestrictToBrands(props.brands)(({ children }) => children);
  return <WithRestrictToBrands {...props}>{props.children}</WithRestrictToBrands>;
};

RestrictToBrands.propTypes = {
  brands: oneOfType([array, string]),
  children: node,
};

export { withRestrictToBrands };

export default RestrictToBrands;
