import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Input, Button, Span } from 'components/Ui';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { createStructuredSelector } from 'reselect';
import { RadioGroup, withStyles } from '@material-ui/core';
import { dataLayerPush } from 'utils/tracking';
import {
  setSection,
  navigateZipRedirect,
  showZipCodeEditPopper,
} from 'containers/Landing/actions';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { isAcquisitionDomain } from 'utils/domainHelper';
import {
  selectIsBrandFetching,
  selectSection,
} from 'containers/Landing/selectors';
import Radio from 'components/Ui/Radio';
import FormLabel from 'components/Ui/FormLabel';
import { PinRiverIcon } from 'images';
import { PROMO_NAME } from 'containers/QuickShop/constants';

import styles from './styles.scss';

const MUIStyles = (theme) => ({
  inputError: {
    position: 'absolute',
    top: '-20px',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: `${theme.colors.lightRed} !important`,
    display: 'none',
  },
  label: {
    color: `${theme.colors.primaryBlue}`,
    '& > span': {
      display: 'none',
    },
  },
  rootLabel: {
    margin: '0 3px 0 0',
    textAlign: 'left',
    marginBottom: '5px',
    color: `${theme.colors.primaryBlue} !important`,
    '& > span': {
      display: 'none',
    },
  },
  rootLabelDrawer: {
    color: `${theme.colors.black} !important`,
  },
  formControl: {
    width: '130px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '20px',
  },
  formControlDrawer: {
    minHeight: 'unset !important',
    alignItems: 'flex-start',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  blockFormControl: {
    flexDirection: 'column',
    minHeight: 'unset !important',
    width: '90px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      margin: '0',
    },
  },
});

class WaterZip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      zip: null,
      changed: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.postalCode !== state.zip) {
      return {
        zip: state.changed ? state.zip : props.postalCode,
      };
    }

    return null;
  }

  handleZipChange = ({ target: { value } }) => {
    if (/^[0-9]{0,5}(?:-[0-9]{0,4})?$/.test(value)) {
      this.setState({ zip: value, changed: true });
    } else {
      value = value.toUpperCase();
      this.setState({ zip: value, changed: true });
    }
  };

  handleTypeChange = ({ target: { value } }) => {
    this.props.setSection(value);
  };

  handleSubmit = () => {
    const { zip } = this.state;
    const {
      navigateZipRedirect: navigateZipRedirectAction,
      showZipCodeEditPopper: showZipCodeEditPopperAction,
    } = this.props;
    // mmm ppp promotionClick - Done dlA5 - $50 Off Promotion Home > Zip Form > Shop
    dataLayerPush(
      'Acquisition',
      {
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [
              {
                name: PROMO_NAME,
                position: 'Zip Bar',
              },
            ],
          },
        },
      },
      'dlA5',
      'PUSH_ADD_PROMOTION_DATA',
    );
    showZipCodeEditPopperAction(false);
    localStorage.setItem('showPopper', false);
    if (isAcquisitionDomain()) {
      localStorage.setItem('confirmedPostalCode', zip);
    }
    navigateZipRedirectAction({ zip, stay: false });
  };

  render() {
    const { zip } = this.state;
    const { section, classes, view, isBrandFetching } = this.props;

    const formControlTypes = {
      drawer: classes.formControlDrawer,
      block: classes.blockFormControl,
      inline: classes.formControl,
    };

    return (
      <ValidatorForm
        onError={() => {}}
        onSubmit={this.handleSubmit}
        className={classnames(styles.waterZipForm, {
          [styles.block]: view === 'block',
          [styles.drawer]: view === 'drawer',
        })}
      >
        {view !== 'drawer' && (
          <div className={styles.subtitle}>
            <img src={PinRiverIcon} className={styles.pin} alt="Pin" />
            <Span>Enter your ZIP Code to see products in your area</Span>
          </div>
        )}
        <div className={styles.form} data-tracking="Zip Bar">
          <div className={styles.controls}>
            <FormLabel classes={{ root: classes.label }} label="This is for:" />
            <RadioGroup
              name="section"
              className={styles.radioGroup}
              value={section}
              onChange={this.handleTypeChange}
              row
            >
              <Radio value="home" label="Home" />
              <Radio value="office" label="Office" />
            </RadioGroup>
          </div>
          <div className={styles.bottomWrapper}>
            <Input
              label="ZIP Code:"
              validators={['required', 'zip']}
              errorMessages={[
                'Zip code is required',
                'Please enter a valid ZIP code',
              ]}
              className={styles.zipInput}
              classes={{
                ...classes,
                formControl: view
                  ? formControlTypes[view]
                  : formControlTypes.default,
                rootLabel:
                  view === 'drawer'
                    ? classes.rootLabelDrawer
                    : classes.rootLabel,
              }}
              value={zip}
              name="zip"
              onChange={this.handleZipChange}
            />
            <Button
              text="Shop"
              className={styles.submit}
              loading={isBrandFetching}
              color={view === 'block' || view === 'drawer' ? 'primary' : 'dark'}
            />
          </div>
        </div>
      </ValidatorForm>
    );
  }
}

WaterZip.propTypes = {
  postalCode: PropTypes.string,
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
  view: PropTypes.string,
  navigateZipRedirect: PropTypes.func.isRequired,
  showZipCodeEditPopper: PropTypes.func.isRequired,
  isBrandFetching: PropTypes.bool.isRequired,
  section: PropTypes.string,
};

WaterZip.defaultProps = {
  postalCode: null,
  view: 'inline',
};

const mapStateToProps = createStructuredSelector({
  postalCode: selectPostalCode(),
  isBrandFetching: selectIsBrandFetching(),
  section: selectSection(),
});

const mapDispatchToProps = {
  setSection,
  navigateZipRedirect,
  showZipCodeEditPopper,
};

export default compose(
  withStyles(MUIStyles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(WaterZip);
