import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from 'components/ContentfulFields/Button';
import styles from './styles.scss';

const Description = ({ content }) => {
  const textOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <Button className={styles.btn} document={node.data.target}>
          {children}
        </Button>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) => child.props);

        if (embedded) {
          return children;
        }

        return <div className={styles.subtitle}>{children}</div>;
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.title}>{children}</h1>
      ),
    },
  };

  const buttonsOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <Button className={styles.mobileBtn} document={node.data.target}>
          {children}
        </Button>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) => child.props);

        if (embedded) {
          return children;
        }

        return null;
      },
      [BLOCKS.HEADING_1]: () => null,
    },
  };

  const description = documentToReactComponents(content, textOptions);

  const button = documentToReactComponents(content, buttonsOptions);

  return (
    <div className={styles.description}>
      {description}
      {button}
    </div>
  );
};

Description.propTypes = {
  content: PropTypes.object,
};

export default Description;
