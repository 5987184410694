import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import injectSaga from 'utils/injectSaga';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { CART_PAGE_KEY } from 'containers/Cart/constants';
import { createCart, clearCart } from 'containers/Cart/actions';
import saga from 'containers/Cart/saga';
import { updateUserRegion } from 'containers/UserRegion/actions';
import ProductsList from './ProductsList';

const mapDispatchToProps = {
  createCart,
  updateUserRegion,
  clearCart,
};

const mapStateToProps = createStructuredSelector({
  postalCode: selectPostalCode(),
  isAuthenticated: selectIsAuthenticated(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withCartSaga = injectSaga({ key: CART_PAGE_KEY, saga });

export default compose(withConnect, withCartSaga)(ProductsList);
