import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import { Default, Mobile } from 'components/Media';
import Button from 'components/ContentfulFields/Button';

import styles from './styles.scss';

const SelfServePromo = ({ document }) => {
  const {
    fields: { desktopImage, mobileImage, title, description, ctaLink },
  } = document;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'button':
            return (
              <Button
                className={styles.btn}
                document={node.data.target}
                to={node.data.target.fields.link}
              >
                {children}
              </Button>
            );
          default:
            return null;
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={styles.description}>{children}</p>
      ),
    },
  };

  return (
    <section className={styles.promo}>
      <Link to={ctaLink}>
        <Default>
          <img
            src={`${desktopImage.fields.file.url}?fm=webp`}
            alt=""
            aria-hidden="true"
          />
        </Default>
        <Mobile>
          <img src={`${mobileImage.fields.file.url}?fm=webp`} />
        </Mobile>
      </Link>
      <div className={styles.descriptionWrapper}>
        <h2 className={styles.subtitle}>{title}</h2>
        {documentToReactComponents(description, options)}
      </div>
    </section>
  );
};

SelfServePromo.propTypes = {
  document: PropTypes.object.isRequired,
};

export default SelfServePromo;
