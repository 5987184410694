import * as types from './constants';

export function loadServiceLocation() {
  return {
    type: types.LOAD_SERVICE_LOCATIONS,
  };
}

export function selectServiceLocation({
  serviceLocationId,
  useAsDefault,
  drawerId,
}) {
  return {
    type: types.SELECT_SERVICE_LOCATION,
    payload: {
      serviceLocationId,
      useAsDefault,
      drawerId,
    },
  };
}

export function loadCalendarDates({ serviceLocationId, toDate, fromDate }) {
  return {
    type: types.LOAD_CALENDAR_DATES,
    payload: {
      serviceLocationId,
      toDate,
      fromDate,
    },
  };
}

export function getDeliveryAfterSkip() {
  return {
    type: types.LOAD_DELIVERY_AFTER_SKIP,
  };
}

export const getServiceLocationRecentTransactions = (serviceLocationId) => ({
  type: types.GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_REQUEST,
  payload: serviceLocationId,
});

export const fetchBillingStatements = (billingAccountId) => ({
  type: types.FETCH_BILLING_STATEMENTS_REQUEST,
  payload: billingAccountId,
});

export const getServiceLocationRecurringDeliveries = () => ({
  type: types.GET_SERVICE_LOCATION_RECURRING_DELIVERY_REQUEST,
});

export const getServiceLocationEquipment = () => ({
  type: types.GET_SERVICE_LOCATION_EQUIPMENT_REQUEST,
});

export const getCustomerSummary = (payload) => ({
  type: types.GET_CUSTOMER_SUMMARY_REQUEST,
  payload,
});

export const getServiceSummary = (payload) => ({
  type: types.GET_SERVICE_SUMMARY_REQUEST,
  payload,
});

export function loadRecurringOrder() {
  return {
    type: types.LOAD_RECURRING_ORDER_REQUEST,
  };
}

export function loadDeliveryOrder(payload) {
  return {
    type: types.LOAD_DELIVERY_ORDER_REQUEST,
    payload,
  };
}

export function updateRecurringOrder(delivery, showChangeSuccessfulModal) {
  return {
    type: types.UPDATE_RECURRING_ORDER,
    payload: {
      delivery,
      showChangeSuccessfulModal,
    },
  };
}

export function updateDeliveryOrder(delivery, date) {
  return {
    type: types.UPDATE_DELIVERY_ORDER_REQUEST,
    payload: {
      delivery,
      date,
    },
  };
}

export function cancelRecurringOrderEditing() {
  return {
    type: types.LOAD_RECURRING_ORDER_CANCEL,
  };
}

export function cancelDeliveryOrderEditing() {
  return {
    type: types.LOAD_DELIVERY_ORDER_CANCEL,
  };
}

export const getServiceLocationNextDelivery = () => ({
  type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST,
});

export const skipDelivery = (delivery) => ({
  type: types.SKIP_DELIVERY_REQUEST,
  payload: delivery,
});

export const skipDeliveryOrder = (date) => ({
  type: types.SKIP_DELIVERY_ORDER_REQUEST,
  payload: date,
});

export const removeHold = (payload) => ({
  type: types.REMOVE_HOLD_REQUEST,
  payload,
});

export const shopDeliveryOrder = (deliveryOrder) => ({
  type: types.SHOP_DELIVERY_ORDER,
  payload: deliveryOrder,
});

export const resetShopDeliveryOrder = () => ({
  type: types.SHOP_DELIVERY_ORDER,
  payload: null,
});

export const addExtraDelivery = () => ({
  type: types.EXTRA_DELIVERY_REQUEST,
});

export const createExtraDelivery = (delivery, date) => ({
  type: types.CREATE_EXTRA_DELIVERY_REQUEST,
  payload: {
    delivery,
    date,
  },
});

export const cancelExtraDelivery = () => ({
  type: types.EXTRA_DELIVERY_CANCEL,
});

export const getBestDeliveryDates = ({
  serviceLocationId,
  fromDate,
  toDate,
  serviceType,
}) => ({
  type: types.GET_BEST_DELIVERY_DATES_REQUEST,
  payload: {
    serviceLocationId,
    fromDate,
    toDate,
    serviceType,
  },
});

export const updateNextDelivery = (delivery, date, successCallback) => ({
  type: types.UPDATE_NEXT_DELIVERY_REQUEST,
  payload: {
    delivery,
    date,
    successCallback,
  },
});

export function makePayment(amount, paymentId, paymentMethodType) {
  return {
    type: types.MAKE_PAYMENT_REQUEST,
    payload: {
      amount,
      paymentId,
      paymentMethodType,
    },
  };
}

export const createNote = (payload) => ({
  type: types.CREATE_NOTE_REQUEST,
  payload,
});

export function resetMakePayment() {
  return {
    type: types.RESET_MAKE_PAYMENT,
  };
}

export const showSkipDialog = (payload) => ({
  type: types.SHOW_SKIP_DIALOG,
  payload,
});

export const hideSkipDialog = (payload) => ({
  type: types.HIDE_SKIP_DIALOG,
  payload,
});

export function getMobileAppBillPayData() {
  return {
    type: types.LOAD_MOBILE_APP_BILL_PAY_DATA,
  };
}

export function resetServiceLocationError() {
  return {
    type: types.RESET_SERVICE_LOCATION_ERROR,
  };
}

export const sendContactUs = (payload) => ({
  type: types.SEND_CONTACT_US_REQUEST,
  payload,
});

export const scheduleCallback = (payload) => ({
  type: types.SCHEDULE_CALLBACK,
  payload,
});

export const fetchContactUsSubjects = (payload) => ({
  type: types.FETCH_CONTACT_US_SUBJECTS,
  payload,
});

export const fetchRecentActivities = () => ({
  type: types.FETCH_RECENT_ACTIVITIES,
});
