import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: 300,
  },
  slider: {
    padding: '22px 0px',
  },
  trackBefore: {
    backgroundColor: theme.colors.primaryBlue,
  },
  track: {
    backgroundColor: theme.colors.primaryGreen,
  },
});

const RangeSlider = ({ classes, min, max, step, onChange, value }) => (
  <div className={classes.root}>
    <Slider
      classes={{
        container: classes.slider,
        trackBefore: classes.trackBefore,
        thumb: classes.track,
      }}
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
    />
  </div>
);

RangeSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(RangeSlider);
