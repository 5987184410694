import { fromJS } from 'immutable';
import {
  FETCH_PROMOTION_ASSETS,
  FETCH_PROMOTION_ASSETS_SUCCESS,
  FETCH_PROMOTION_ASSETS_FAILURE,
  SET_PROMOTION_DETAILS,
} from './constants';

const initialState = fromJS({
  fetchingPromotionAssets: false,
  promotionAssets: {},
});

function promotionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROMOTION_ASSETS:
      return state.set('fetchingPromotionAsets', true);
    case FETCH_PROMOTION_ASSETS_SUCCESS: {
      const promoAssets = action.payload.items.reduce(
        (
          result,
          {
            fields: {
              title,
              file: { url },
            },
          },
        ) => {
          result[title] = url;
          return result;
        },
        {},
      );

      return state
        .set('promotionAssets', promoAssets)
        .set('fetchingPromotionAsets', false);
    }
    case FETCH_PROMOTION_ASSETS_FAILURE: {
      return state.set('fetchingPromotionAsets', false);
    }
    case SET_PROMOTION_DETAILS: {
      return state.set('promotionalDetails', action.payload);
    }
    default: {
      return state;
    }
  }
}

export default promotionsReducer;
