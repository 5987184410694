import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

const ExpandIcon = (props) => (
  <SvgIcon
    viewBox="0 0 40 40"
    width="40px"
    height="40px"
    fontSize="small"
    {...props}
  >
    <g
      id={`${props.id}-Homepage`}
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id={`${props.id}-2.0_SPK_Homepage_H-DT_1440`}
        transform="translate(-1151.000000, -1069.000000)"
      >
        <g
          id={`${props.id}-SpecialOffersBar/DT`}
          transform="translate(0.000000, 1014.000000)"
        >
          <g
            id={`${props.id}-Group`}
            transform="translate(250.000000, 0.000000)"
          >
            <g
              id={`${props.id}-ExpandControl`}
              transform="translate(762.000000, 55.000000)"
            >
              <g
                id={`${props.id}-ArrowButton`}
                transform="translate(159.000000, 20.000000) rotate(-270.000000) translate(-159.000000, -20.000000) translate(139.000000, 0.000000)"
              >
                <g>
                  <circle
                    id={`${props.id}-Oval`}
                    fill="#009CDE"
                    cx="20"
                    cy="20"
                    r="20"
                  ></circle>
                  <polyline
                    id={`${props.id}-Path-2`}
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="18 14 24.3623047 20.3623047 18.3623047 26.3623047"
                  ></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ExpandIcon.propTypes = {
  id: PropTypes.string,
};

export default ExpandIcon;
