import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { compact, find, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import { formatCurrency } from 'utils/common';
import { withOrderUtils } from 'utils/withOrderUtils';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { dataLayerPush } from 'utils/tracking';
import { FavoriteButton } from 'containers/PrimoProducts/Favorites';
import { selectCartItems, selectCartId } from 'containers/Cart/selectors';
import { selectShopDeliveryOrder } from 'containers/PrimoAccount/selectors';
import { Default } from 'components/Media';
import CannotCancelDialog from 'components/Dialogs/CannotCancelDialog/Loadable';
import { Divider, Button, Counter, H3, Div, Span } from 'components/Ui';
import { getCurrencyCode } from 'utils/translation';
import classnames from 'classnames';

import styles from './styles.scss';

const ProductInfo = ({
  product,
  addToOrder,
  removeItem,
  changeItemQuantity,
  updateRecurringOrder,
  isAuthenticated,
  isReadonly,
  isPastCutOff,
  recurringOrder = [],
  noNextDelivery,
  inNextDelivery,
  inRecurringOrder,
  isInOrderDelivery,
  serviceRecurringDeliveryId,
  onUpdateDeliveryOrder,
}) => {
  const cartItems = useSelector(selectCartItems());
  const cartId = useSelector(selectCartId());
  const shopDeliveryOrder = useSelector(selectShopDeliveryOrder());

  useEffect(() => {
    const { itemNumber, name, brand, category, size, price } = product;
    dataLayerPush(
      'Acquisition',
      {
        ecommerce: {
          detail: {
            actionField: { list: category },
            products: [
              {
                name,
                id: itemNumber,
                price,
                brand: brand || '',
                category,
                variant: size,
              },
            ],
          },
        },
      },
      'dlA29',
    );
  }, []);

  const getProductInCart = () => {
    const found = find(
      cartItems,
      (item) => product.itemNumber === item.itemNumber,
    );
    return found || { quantity: 0 };
  };
  const productInCart = getProductInCart();
  const quantityInCart = productInCart.quantity;
  const isAcquisition = isAcquisitionDomain();
  const canBeRemoved = isAcquisition && quantityInCart > 0;

  const [quantity, setQuantity] = useState(
    isAcquisition && quantityInCart > 0 ? quantityInCart : 1,
  );
  const [cannotCancel, setCannotCancel] = useState(null);

  const isCannotCancelDialogOpen = () => !isEmpty(cannotCancel);

  const handleUpdateCart = (e, handler) => {
    const cannot = product?.isPPS || false;

    if (cannot) {
      setCannotCancel({ handler });
    } else {
      handler(e);
    }
  };

  const clearCannotCancelState = () => setCannotCancel(null);

  const continueUpdateCart = (e) => {
    const { handler } = cannotCancel;
    clearCannotCancelState();
    handler(e);
  };

  const closeCannotCancelDialog = (e) => {
    clearCannotCancelState();
  };

  const inOrderDelivery = isInOrderDelivery(
    product.itemNumber,
    shopDeliveryOrder,
  );

  const getDataLayer = (event, section = 'product detail') => {
    const { itemNumber, name, category, price, brand, size } = product;

    return {
      event,
      section,
      id: itemNumber,
      name,
      brand: brand || '',
      category,
      price,
      variant: size,
      quantity,
      position: 1,
    };
  };

  const pushAddToCart = (pushAddToCartQuantity) => {
    const { itemNumber, name, brand, category, size, price } = product;
    // mmm aaa pushAddToCart
    dataLayerPush(
      'Acquisition',
      {
        event: 'addToCart',
        ecommerce: {
          currencyCode: getCurrencyCode(),
          add: {
            products: [
              {
                name,
                id: itemNumber,
                price,
                brand: brand || '',
                category,
                variant: size,
                quantity: pushAddToCartQuantity,
              },
            ],
          },
        },
      },
      'dlA35',
      'PUSH_ADD_TO_CART_DATA',
    );
  };

  const pushRemoveFromCart = (sendRemoveFromCartQuantity) => {
    const { itemNumber, name, brand, category, size, price } = product;
    // mmm rrr pushRemoveFromCart
    dataLayerPush(
      'Acquisition',
      {
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: getCurrencyCode(),
          remove: {
            products: [
              {
                name,
                id: itemNumber,
                price,
                brand: brand || '',
                category,
                variant: size,
                quantity: sendRemoveFromCartQuantity,
              },
            ],
          },
        },
      },
      'dlA36',
      'PUSH_REMOVE_FROM_CART_DATA',
    );
  };

  const handleUpdateItemQty = (event) => {
    event.stopPropagation();
    const { itemNumber } = product;
    const hasItemInCart = quantityInCart > 0;
    const isAddingOrUpdating = quantity > 0;

    if (isAddingOrUpdating) {
      if (hasItemInCart) {
        if (quantity !== quantityInCart) {
          if (quantity > quantityInCart) {
            pushAddToCart(quantity - quantityInCart);
          } else {
            pushRemoveFromCart(quantityInCart - quantity);
          }
          changeItemQuantity({
            cartId,
            item: { id: productInCart.id, itemNumber },
            quantity,
          });
        }
      } else {
        pushAddToCart(quantity);
        addToOrder({ quantity, itemNumber });
      }
    } else {
      pushRemoveFromCart(quantityInCart);
      removeItem({ cartId, itemId: productInCart.id });
      setQuantity(1);
    }
  };

  const handleUpdateOneTimeOrder = (event) => {
    const { itemNumber, name, category, price, brand, size } = product;

    event.stopPropagation();
    addToOrder({
      deliveryAction: 'NEW',
      quantity: Math.max(quantity, 1),
      itemNumber: product.itemNumber,
      isRecurring: false,
    });

    dataLayerPush(
      'SelfServe',
      {
        event: 'add-to-one-time',
        section: 'product detail',
        id: itemNumber,
        name,
        brand: brand || '',
        category,
        price,
        variant: size,
        position: 1,
        quantity,
      },
      'dlS36',
      'PUSH_ADD_TO_ONE_TIME_ORDER',
    );
  };

  const handleUpdateRecurringOrder = (event) => {
    event.stopPropagation();
    const recurringItems = [
      ...recurringOrder,
      {
        ...product,
        quantity,
        itemId: product.id,
        recurringDeliveryId: serviceRecurringDeliveryId,
      },
    ];
    const recurringOrderToUpdate = recurringItems.map((item) => ({
      itemId: item.itemId,
      itemNumber: item.itemNumber,
      quantity: item.quantity,
      recurringDeliveryId: item.recurringDeliveryId,
      deliveryFrequency: item.deliveryFrequency
        ? item.deliveryFrequency
        : 'Every Delivery',
    }));
    updateRecurringOrder(recurringOrderToUpdate, true);
    const { itemNumber, name, category, price, brand, size } = product;

    dataLayerPush(
      'SelfServe',
      {
        event: 'add-to-recurring-order',
        section: 'product detail',
        id: itemNumber,
        name,
        brand: brand || '',
        category,
        price,
        variant: size,
        position: 1,
        quantity,
      },
      'dlS37',
      'PUSH_ADD_TO_RECURRING_ORDER',
    );
  };

  const handleUpdateDeliveryOrder = (event) => {
    event.stopPropagation();
    onUpdateDeliveryOrder(quantity);
  };

  const text = isAcquisition
    ? quantityInCart === 0
      ? 'Add to Cart'
      : quantity > 0
      ? 'Update Cart'
      : 'Remove Item'
    : 'Add to order';

  const renderAddItemButton = () => (
    <Button
      className={styles.button}
      text={text}
      onClick={(e) => handleUpdateCart(e, handleUpdateItemQty)}
    />
  );

  const renderOneTimeAndRecurringButtons = () => {
    const isOneTime = !!product.isOneTimePurchase;

    return (
      <div className={styles.ssButtonContainer}>
        <Div className={styles.subText}>Add this to</Div>
        {!shopDeliveryOrder && (
          <>
            <Button
              disabled={
                isReadonly || isPastCutOff || inNextDelivery || noNextDelivery
              }
              className={classnames(styles.onetimeButton)}
              text="One-Time Order"
              onClick={(e) => handleUpdateCart(e, handleUpdateOneTimeOrder)}
            />
            <Button
              disabled={isReadonly || inRecurringOrder || isOneTime}
              className={classnames(styles.recurringButton)}
              text="Recurring Order"
              onClick={(e) => handleUpdateCart(e, handleUpdateRecurringOrder)}
            />
          </>
        )}
        {shopDeliveryOrder && (
          <Button
            disabled={isReadonly || inOrderDelivery}
            className={classnames(styles.thisOrderButton)}
            text="This Order"
            onClick={(e) => handleUpdateCart(e, handleUpdateDeliveryOrder)}
          />
        )}
      </div>
    );
  };

  const description = product.longDescription
    ? product.longDescription.split('\n')
    : [product.description];

  const counterDisabled = shopDeliveryOrder
    ? isReadonly || inOrderDelivery
    : isReadonly || (inNextDelivery && inRecurringOrder);

  return (
    <Fragment>
      <Default>
        {isAuthenticated && (
          <FavoriteButton
            className={styles.favBtn}
            item={{ itemId: product.id, itemNumber: product.itemNumber }}
            dataLayer={getDataLayer('add-item-to-favorites')}
          />
        )}
        <H3 className={styles.title} defaultText={'C0'}>
          {product.name}
        </H3>
      </Default>
      {(product.size || product.count) && (
        <Div className={styles.sizeCount} defaultText={'C0'}>
          {compact([product.size, product.count]).join(', ')}
        </Div>
      )}
      {description.map((line, index) => (
        <Div
          key={`Line${index}`}
          className={styles.description}
          defaultText={'C0'}
        >
          {line}
        </Div>
      ))}
      {product.price && (
        <div className={styles.price}>
          {formatCurrency(product.price)} <Span>each</Span>
        </div>
      )}
      {product && product.isPPS && (
        <CannotCancelDialog
          open={isCannotCancelDialogOpen()}
          item={product}
          onContinue={continueUpdateCart}
          onClose={closeCannotCancelDialog}
        />
      )}
      <Grid className={styles.priceContainer} container spacing={2}>
        <Grid item>
          <Div className={styles.label}>Quantity</Div>
          {isReadonly ? (
            <div className={styles.total}>1</div>
          ) : (
            <Counter
              disabled={counterDisabled}
              className={styles.counter}
              value={quantity}
              usePropsValue
              onChange={setQuantity}
              minValue={canBeRemoved ? 0 : 1}
            />
          )}
        </Grid>
        <Grid item className={styles.totalPrice}>
          <Div className={styles.label}>Total</Div>
          <div className={styles.total}>
            {formatCurrency(quantity * product.price)}
          </div>
        </Grid>
      </Grid>
      <Divider className={styles.divider} />
      {!isAuthenticated && renderAddItemButton()}
      {isAuthenticated && renderOneTimeAndRecurringButtons()}
      <Helmet title={`${product.name} | ${product.size}`}>
        <meta name="description" content={`${description}`} />
      </Helmet>
    </Fragment>
  );
};

ProductInfo.propTypes = {
  product: PropTypes.object.isRequired,
  recurringOrder: PropTypes.array,
  addToOrder: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  changeItemQuantity: PropTypes.func.isRequired,
  updateRecurringOrder: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  isPastCutOff: PropTypes.bool.isRequired,
  noNextDelivery: PropTypes.bool.isRequired,
  inNextDelivery: PropTypes.bool.isRequired,
  inRecurringOrder: PropTypes.bool.isRequired,
  serviceRecurringDeliveryId: PropTypes.number,
  onUpdateDeliveryOrder: PropTypes.func,
};

export default withOrderUtils(ProductInfo);
