import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';
import { themeConfig } from 'theme/themeConfig';

export const CheckedIcon = (props) => (
  <SvgIcon
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="check-circle"
    className="svg-inline--fa fa-check-circle fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{ fontSize: 15 }}
    {...props}
  >
    <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
  </SvgIcon>
);

export const UncheckedIcon = (props) => (
  <SvgIcon
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="circle"
    className="svg-inline--fa fa-circle fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{ fontSize: 15 }}
    {...props}
  >
    <path
      fill="currentColor"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"
    />
  </SvgIcon>
);

const CheckboxWrapper = styled.div`
  height: 40px;
  width: 40px;
  border: 2px solid #b1b3b3;
  border-radius: 5px;
`;

const CheckMark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 27px;
  width: 16px;
  border-width: 0 7px 7px 0;
  border-style: solid;
  border-color: ${themeConfig.colors.primaryBlue};
`;

export const HugeCheckbox = ({ checked }) => (
  <CheckboxWrapper>{checked && <CheckMark />}</CheckboxWrapper>
);

HugeCheckbox.propTypes = {
  checked: PropTypes.bool,
};

HugeCheckbox.defaultProps = {
  checked: 'false',
};
