import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import styles from './styles.scss';

const CloseIcon = ({ onClick, className }) => (
  <ButtonBase
    aria-label="Close"
    onClick={onClick}
    classes={{ root: classnames(styles.closeIcon, className) }}
  />
);

CloseIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CloseIcon;
