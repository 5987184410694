import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectServiceLocationId } from 'containers/PrimoAccount/selectors';
import Button from 'components/Ui/Button';
import dashboardIcon from 'images/Dashboard.svg';
import pastOrdersIcon from 'images/PastOrders.svg';
import deliverySchedule from 'images/DeliverySchedule.svg';
import CollapseButton from 'components/ContentfulFields/Collapse/CollapseButton';
import { ClickAwayListener } from '@material-ui/core';
import styles from 'components/ContentfulFields/Collapse/styles.scss';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import cx from 'classnames';

const MY_ACCOUNT_COLLAPSE_KEY = 'myAccount';

const MY_ACCOUNT_PAGE_URLS = {
  dashboard: '/account/next-delivery',
  pastOrders: '/account/past-orders',
  deliverySchedule: '/account/future-orders',
};

const MyAccountCollapse = ({
  toggleCollapse,
  activeDrawer,
  closeCollapse,
  serviceLocationId,
  ...props
}) => {
  const handleClickAway = () => {
    toggleCollapse(MY_ACCOUNT_COLLAPSE_KEY);
  };

  const openCollapse = () => {
    const collapseContent = (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={cx(styles.collapseContentWrapper, styles.myAccount)}
          data-tracking={GTM_TRACKING_TAGS['My Account Collapse']}
        >
          <Button
            className={styles.collapseContent}
            onClick={closeCollapse}
            iconButton
            icon={dashboardIcon}
            href={MY_ACCOUNT_PAGE_URLS.dashboard}
            text="Next Delivery"
          />
          <Button
            disabled={!serviceLocationId}
            className={styles.collapseContent}
            onClick={closeCollapse}
            iconButton
            icon={pastOrdersIcon}
            href={MY_ACCOUNT_PAGE_URLS.pastOrders}
            text="Billing & Delivery History"
          />
          <Button
            disabled={!serviceLocationId}
            className={styles.collapseContent}
            onClick={closeCollapse}
            iconButton
            icon={deliverySchedule}
            href={MY_ACCOUNT_PAGE_URLS.deliverySchedule}
            text="Future Deliveries"
          />
        </div>
      </ClickAwayListener>
    );

    toggleCollapse(MY_ACCOUNT_COLLAPSE_KEY, collapseContent);
  };

  const isActive =
    Object.values(MY_ACCOUNT_PAGE_URLS).includes(props.location.pathname) ||
    activeDrawer === MY_ACCOUNT_COLLAPSE_KEY;

  const isExpanded = activeDrawer === MY_ACCOUNT_COLLAPSE_KEY;

  return (
    <CollapseButton
      isActive={isActive}
      onToggle={openCollapse}
      ariaHaspopup
      ariaExpanded={isExpanded}
    >
      My Account
    </CollapseButton>
  );
};

MyAccountCollapse.propTypes = {
  toggleCollapse: PropTypes.func.isRequired,
  activeDrawer: PropTypes.string,
  closeCollapse: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  serviceLocationId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  serviceLocationId: selectServiceLocationId(),
});

export default withRouter(connect(mapStateToProps, null)(MyAccountCollapse));
