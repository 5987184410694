import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { get } from 'lodash';
import { ALL_BRANDS, US_BRANDS, CA_BRANDS } from 'utils/domainHelper';
import { getPrimoBrandName } from 'utils/translation';
import Item from './Item';

const Features = ({
  document,
  stylesTheme,
  animation,
  regionalBrandName,
  variables,
}) => {
  const featuresOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        let supportedBrands = get(
          node,
          'data.target.fields.supportedBrands',
          ALL_BRANDS,
        );
        supportedBrands =
          supportedBrands === 'US Brands'
            ? US_BRANDS
            : supportedBrands === 'CA Brands'
            ? CA_BRANDS
            : ALL_BRANDS;
        const brandName = getPrimoBrandName();
        if (supportedBrands.includes(brandName)) {
          return (
            <Item
              document={node.data.target}
              stylesTheme={stylesTheme}
              animation={animation}
              regionalBrandName={regionalBrandName}
              variables={variables}
            />
          );
        }
        return null;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => children,
    },
  };

  const features = documentToReactComponents(document, featuresOptions);

  const styles = require(`../styles/${stylesTheme}.scss`);
  return features && <div className={styles.featuresList}>{features}</div>;
};

Features.propTypes = {
  regionalBrandName: PropTypes.string,
  variables: PropTypes.array,
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
};

export default Features;
