import React, { useEffect, useState } from 'react';
import { oneOfType, array, string } from 'prop-types';
import { getDomainByHost } from 'utils/domainHelper';

/**
 * @param {string[]|string} [brands] - single brand or array of brands
 * @return {function} a wrapping react component
 */
const withRestrictToBrands = (brands) => (Component) => {
  /**
   * @param {object} props - props
   * @param {string[]|string} [props.restrictToBrands] - single brand or array of brands
   * @return {ReactElement|null}
   */
  const WithRestrictToBrands = (props) => {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
      brands = props.restrictToBrands || brands;
      brands = !brands || brands.length ? brands : [brands];

      if (!brands) {
        throw new Error('restrictToBrands: `brands` is not defined.');
      }

      const { brand } = getDomainByHost();

      if (brands.includes(brand)) setShouldRender(true);
    }, [props.restrictToBrands]);

    return !shouldRender ? null : <Component {...props} />;
  };

  WithRestrictToBrands.propTypes = {
    brands: oneOfType([array, string]),
  };

  // Wrap display name (helps debug)
  WithRestrictToBrands.displayName = `WithRestrictToBrands(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithRestrictToBrands;
};

export default withRestrictToBrands;
