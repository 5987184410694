import React, { Component } from 'react';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import { themeConfig } from 'theme/themeConfig';
import { translateData } from 'utils/translation';
import styles from './water-regional.scss';

const defaultOptions = [];

class SingleSelect extends Component {
  state = {
    isClearable: this.props.isClearable,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: !!this.props.isSearchable,
    isSelectOpen: false,
  };

  jsStyles = {
    container: () => ({
      height: '40px',
      position: 'relative',
      boxShadow: themeConfig.boxShadows.darkShadow,
      border: themeConfig.borders.greyBorder(false),
      borderRadius: '5px',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      color: themeConfig.colors.middleGrey,
      fontWeight: '600',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      backgroundColor: state.selectProps.darkDropdown
        ? themeConfig.colors.primaryDarkBlue
        : themeConfig.colors.primaryBlue,
      border: 'none',
      width: '40px',
      height: 'calc(100% + 2px)',
      position: 'relative',
      right: '-1px',
      display: !state.selectProps.menuIsOpen ? 'flex' : 'none',
      color: themeConfig.colors.white,
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      '&:hover': {
        color: '#e6e8ed',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    control: (provided, state) => {
      const focusVisible =
        this.containerRef.current &&
        this.containerRef.current.querySelectorAll('.focus-visible').length > 0;
      const { menuIsOpen } = state;
      return {
        ...provided,
        cursor: 'pointer',
        border: 'none',
        height: '100%',
        boxShadow: menuIsOpen
          ? `0 0 0 3px ${themeConfig.colors.primaryBlue}`
          : 'none',
        borderRadius: menuIsOpen ? '4px 4px 0 0' : '4px',
        outline: focusVisible ? '2px dashed black' : 'none',
      };
    },
    option: () => ({
      padding: '13px 10px',
      color: themeConfig.colors.middleGrey,
      backgroundColor: themeConfig.colors.white,
      '&:first-child': {
        borderRadius: '5px 5px 0 0',
      },
      '&:last-child': {
        borderRadius: '0 0 5px 5px',
      },
      '&:nth-child(2n)': {
        backgroundColor: themeConfig.colors.whiteGrey,
      },
      '&:hover': this.props.noHover
        ? {}
        : {
            backgroundColor: themeConfig.colors.primaryBlue,
            color: themeConfig.colors.white,
            cursor: 'pointer',
          },
    }),
    menuList: () => ({
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      top: '100%',
      borderRadius: 5,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeConfig.colors.dimGrey,
      fontFamily: themeConfig.fonts.sansPro,
      fontSize: '0.9375rem',
      lineHeight: '1.25rem',
      fontWeight: '400',
      textTransform: 'lowercase',
    }),
  };

  containerRef = React.createRef();

  toggleClearable = () =>
    this.setState((state) => ({ isClearable: !state.isClearable }));

  toggleDisabled = () =>
    this.setState((state) => ({ isDisabled: !state.isDisabled }));

  toggleLoading = () =>
    this.setState((state) => ({ isLoading: !state.isLoading }));

  toggleRtl = () => this.setState((state) => ({ isRtl: !state.isRtl }));

  toggleSearchable = () =>
    this.setState((state) => ({ isSearchable: !state.isSearchable }));

  // TODO: Fix React-Select so we dont have to use setTimeout
  onMenuOpen = () => setTimeout(() => this.setState({ isSelectOpen: true }), 0);

  onMenuClose = () =>
    setTimeout(() => this.setState({ isSelectOpen: false }), 0);

  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
      isSelectOpen,
    } = this.state;

    const {
      className,
      icon,
      name,
      label,
      options,
      onChange,
      isFetching,
      darkDropdown,
      value,
      selectClassName,
      formatLabel,
      placeholder,
      whiteLabel,
    } = this.props;

    const translatedLabel = translateData(label);

    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        {icon}
      </components.DropdownIndicator>
    );

    const SingleValue = ({ children }) => (
      <div
        id={`select-value-${name}`}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={isSelectOpen}
        aria-labelledby={`select-${name} select-value-${name}`}
      >
        {formatLabel ? formatLabel(children) : children}
      </div>
    );

    const Option = ({ ...props }) => (
      <div role="option" aria-selected={props.isSelected}>
        <components.Option {...props} />
      </div>
    );

    return (
      <div className={className} ref={this.containerRef} data-tracking={label}>
        <label
          className={classNames(styles.label, {
            [styles.white]: whiteLabel,
          })}
          htmlFor={name}
          id={`select-${name}`}
        >
          {translatedLabel}
        </label>
        <div
          role="button"
          aria-haspopup="listbox"
          aria-expanded={isSelectOpen}
          aria-labelledby={`select-${name} select-value-${name}`}
        >
          <Select
            className={classNames(styles.autocomplete, selectClassName)}
            styles={this.jsStyles}
            classNamePrefix="select"
            defaultValue={options[0]}
            value={value}
            isDisabled={isDisabled}
            isLoading={isFetching || isLoading}
            isMenuOpen
            isClearable={isClearable}
            isRtl={isRtl}
            components={{
              DropdownIndicator,
              SingleValue,
              Option,
            }}
            isSearchable={isSearchable}
            name={name}
            placeholder={placeholder}
            formatOptionLabel={(option, { context }) =>
              option.short && context === 'value' ? option.short : option.label
            }
            options={options}
            label={label && translatedLabel}
            onChange={onChange}
            darkDropdown={darkDropdown}
            onMenuOpen={this.onMenuOpen}
            onMenuClose={this.onMenuClose}
          />
        </div>
      </div>
    );
  }
}

SingleSelect.defaultProps = {
  options: defaultOptions,
  isClearable: true,
  noHover: false,
};

export default SingleSelect;
