export const INFORMATION_PAGE = 'delivery-information';
export const SETUP_PAGE = 'delivery-setup';
export const PAYMENT_PAGE = 'payment';
export const REVIEW_PAGE = 'review';
export const CONFIRMATION_PAGE = 'confirmation';
export const HOMEPAGE_PAGE = 'home';
export const HOMEPAGE_OFFICE_PAGE = 'office';

export const pages = [
  INFORMATION_PAGE,
  SETUP_PAGE,
  PAYMENT_PAGE,
  REVIEW_PAGE,
  CONFIRMATION_PAGE,
];
export const isPageWithHeader = (page) =>
  [INFORMATION_PAGE, SETUP_PAGE, PAYMENT_PAGE, REVIEW_PAGE].includes(page);

export const BILLING_TYPE_ANOTHER = 'another';
export const BILLING_TYPE_SHIPPING = 'shipping';
