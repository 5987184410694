import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { isAcquisitionDomain } from 'utils/domainHelper';
import PromotionBanner from 'containers/QuickShop/PromotionBanner/Loadable';
import quickShopContent from 'containers/QuickShop/quickShopContent';
import {
  selectPromotionId,
  selectPromotionLoading,
} from 'containers/Cart/selectors';
import PromotionAppliedDialog from 'components/Dialogs/PromotionAppliedDialog/Loadable';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { loadProductCategories as loadProductCategoriesRequest } from 'containers/PrimoProducts/actions';
import withContentful from './withContenfull';

/**
 * @param {string} key - a Contentful page key
 * @return {function} a wrapping React component
 */
const withLanding = (pageKey) => (Component) => {
  const WithLanding = ({
    site,
    promotionId,
    isPromotionLoading,
    loadProductCategories,
  }) => {
    const [
      openRedeemedPromotionDialog,
      setRedeemedPromotionDialogStatus,
    ] = useState(false);

    useEffect(() => {
      loadProductCategories();
    }, []);

    const {
      components: { page },
    } = site;

    const slug = get(page, 'slug', '');
    const isHomepage = ['acquisition-home-2', 'acquisition-office'].includes(slug);
    const promotionApplied = !!promotionId;

    const showRedeemDialog = () => {
      setRedeemedPromotionDialogStatus(true);
    };

    return (
      <>
        {isHomepage && isAcquisitionDomain() && (
          <PromotionBanner
            isCartLoading={false}
            promotionApplied={promotionApplied}
            showRedeemDialog={showRedeemDialog}
            content={quickShopContent}
          />
        )}

        <Component site={site} />

        <PromotionAppliedDialog
          open={openRedeemedPromotionDialog}
          setStatus={setRedeemedPromotionDialogStatus}
          promotionLoading={isPromotionLoading}
        />
      </>
    );
  };

  // Wrap display name (helps debug)
  WithLanding.displayName = `WithLanding(${
    Component.displayName || Component.name || 'Component'
  })`;

  WithLanding.propTypes = {
    site: PropTypes.object,
    isPromotionLoading: PropTypes.bool,
    loadProductCategories: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = {
    loadProductCategories: loadProductCategoriesRequest,
  };

  const mapStateToProps = createStructuredSelector({
    promotionId: selectPromotionId(),
    isPromotionLoading: selectPromotionLoading(),
    isAuthenticated: selectIsAuthenticated(),
  });

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(
    withContentful(pageKey),
    withConnect,
  )(WithLanding);
};

export default withLanding;
