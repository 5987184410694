import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const TextMask = (props) => {
  const { inputRef, mask, showMask, placeholderChar, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={showMask}
    />
  );
};

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  ).isRequired,
  showMask: PropTypes.bool,
  placeholderChar: PropTypes.string,
};

TextMask.defaultProps = {
  showMask: false,
  placeholderChar: '\u2000',
};

export default TextMask;
