import pick from 'lodash/pick';
import { all, call, put, takeLatest, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import creditCardType from 'credit-card-type';
import cookies from 'js-cookie';
import { equalsIgnoreCase, getRandomString } from 'utils/common';
import { showDrawerSaga, hideDrawerSaga } from 'containers/DrawerManager/sagas';
import auth0 from 'auth0-js';

import {
  selectBillingLocations,
  selectBillingAccountId,
  selectSelectedServiceLocation,
  selectSelectedServiceLocationId,
} from 'containers/PrimoAccount/selectors';
import { UPDATE_USER_DETAILS } from 'containers/PrimoProfile/constants';
import {
  ACCOUNT_DETAILS_TITLE,
  ACCOUNT_DETAILS_SUCCESS_TITLE,
  EDIT_EMAIL_ADDRESS,
  ADD_NEW_ACCOUNT_LOGIN,
} from 'containers/PrimoProfile/AccountDetails/constants';
import { SELECT_SERVICE_LOCATION_SUCCESS } from 'containers/PrimoAccount/constants';
import {
  CHANGE_PASSWORD_SUCCESS,
  ORACLE_REALM_NAME,
} from 'containers/Authentication/constants';
import {
  EDIT_BANK_ACCOUNT,
  EDIT_BILLING_INFORMATION,
  EDIT_CREDIT_CARD,
  EDIT_CUSTOMER_NOTES,
  EDIT_INVOICE_INFORMATION,
} from 'containers/PrimoProfile/BillingInformation/constants';
import { AUTOPAY_SIGN_UP } from 'containers/PrimoAccount/NextDelivery/MakePayment/constants';
import { hideDrawer } from 'containers/DrawerManager/actions';
import { apiCall } from 'utils/swaggerClient';
import {
  throwErrorTest,
  handleError,
  getErrorMessage,
} from 'utils/errorHandling';
import { getAuthClientId } from 'utils/domainHelper';
import {
  selectUserEmail,
  selectUserId,
  selectUserInfo,
} from 'containers/Authentication/selectors';
import { isSsr } from 'utils/ssrHelper';
import { getServerData } from 'utils/getServerData';
import * as actions from './actions';
import {
  selectStatementData,
  selectPaymentMethods,
  selectPaymentMethodsLoaded,
  selectCurrentPaymentMethod,
  selectAutopayCreditCards,
  selectBillingAccount,
} from './selectors';

import * as types from './constants';

function* fetchCreditCards() {
  try {
    throwErrorTest('fetchCreditCards');
    const { billingAccountId } = yield call(waitServiceLocation);

    const paymentMethods = yield call(apiCall, {
      operationId: 'BillingAccounts.listBillingAccountPaymentMethods',
      parameters: { billingAccountId },
    });

    yield put({
      type: types.LOAD_CREDIT_CARDS_SUCCESS,
      payload: paymentMethods,
    });
  } catch (error) {
    yield handleError(
      'fetchCreditCards',
      types.LOAD_CREDIT_CARDS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* closeCustomerNotesDrawer() {
  yield put(hideDrawer(EDIT_CUSTOMER_NOTES));
  yield put({ type: types.CLOSE_CUSTOMER_NOTES_DRAWER_SUCCESS });
}

function* fetchBankAccounts() {
  try {
    throwErrorTest('fetchBankAccounts');
    const { billingAccountId } = yield call(waitServiceLocation);

    const bankAccounts = yield call(apiCall, {
      operationId: 'BillingAccounts.listBillingAccountBankAccounts',
      parameters: { billingAccountId },
    });

    yield put({
      type: types.LOAD_BANK_ACCOUNTS_SUCCESS,
      payload: bankAccounts,
    });
  } catch (error) {
    yield handleError(
      'fetchBankAccounts',
      types.LOAD_BANK_ACCOUNTS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* fetchPaymentMethods({ payload }) {
  try {
    throwErrorTest('fetchPaymentMethods');
    let isPaymentMethodsLoaded = false;

    if (payload?.force) {
      yield put({ type: types.FETCH_PAYMENT_METHODS_RESET });
    } else {
      isPaymentMethodsLoaded = yield select(selectPaymentMethodsLoaded());
    }

    if (!isPaymentMethodsLoaded) {
      yield put({ type: types.LOAD_BANK_ACCOUNTS });
      yield put({ type: types.LOAD_CREDIT_CARDS });

      yield all([
        take(types.LOAD_BANK_ACCOUNTS_SUCCESS),
        take(types.LOAD_CREDIT_CARDS_SUCCESS),
      ]);

      yield put({ type: types.SET_CURRENT_PAYMENT_METHOD });
    }

    yield put({ type: types.FETCH_PAYMENT_METHODS_SUCCESS });
  } catch (error) {
    yield handleError(
      'fetchPaymentMethods',
      types.FETCH_PAYMENT_METHODS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* setCurrentPaymentMethod({ payload: id }) {
  try {
    throwErrorTest('setCurrentPaymentMethod');

    const payMethods = yield select(selectPaymentMethods());

    if (payMethods.length < 1) throw new Error('No payment methods found!');

    let currentPaymentMethod;

    if (id) {
      currentPaymentMethod = payMethods.find((mthd) => mthd.id === id);
    }

    if (!currentPaymentMethod) {
      const autopayMethod = payMethods.find((mthd) => mthd.autopay);
      currentPaymentMethod = autopayMethod || payMethods[0];
    }

    yield put({
      type: types.SET_CURRENT_PAYMENT_METHOD_SUCCESS,
      payload: currentPaymentMethod,
    });
  } catch (error) {
    yield handleError(
      'setCurrentPaymentMethod',
      types.SET_CURRENT_PAYMENT_METHOD_FAILURE,
      getErrorMessage(error),
    );
  }
}

export function* getOrFetchCurrentPaymentMethod() {
  const results = yield select(selectCurrentPaymentMethod());
  if (results) return results;

  yield put(actions.fetchPaymentMethods());
  yield take(types.SET_CURRENT_PAYMENT_METHOD_SUCCESS);
  return yield select(selectCurrentPaymentMethod());
}

function* getBillingSummary() {
  try {
    throwErrorTest('getBillingSummary');
    const billingLocations = yield select(selectBillingLocations());
    const selectedBillingAccountId = yield select(selectBillingAccountId());
    const billingAccountId = selectedBillingAccountId || billingLocations[0].id;

    const billingSummary = yield call(apiCall, {
      operationId: 'BillingAccounts.getBillingSummary',
      parameters: { billingAccountId },
    });

    yield put({
      type: types.GET_BILLING_SUMMARY_SUCCESS,
      payload: billingSummary,
    });

    yield loadBillingAccounts();
  } catch (error) {
    yield handleError(
      'getBillingSummary',
      types.GET_BILLING_SUMMARY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* addCreditCard({ payload }) {
  try {
    throwErrorTest('addCreditCard');
    const billingAccountId = yield select(selectBillingAccountId());
    const formattedCardNumber = payload.cardNumber.replace(/[^0-9]/g, '');
    const txKey = !isSsr() ? getServerData('TOKENEX_API_KEY') : '';
    const encryptedCreditCardNumber = TxEncrypt(txKey, formattedCardNumber);
    const cardType = creditCardType(payload.cardNumber);
    const mobile = cookies.get('isMobileView') === 'Y';

    const requestBody = {
      encryptedCreditCardNumber,
      cardholderName: payload.cardholderName.trim(),
      expirationMonth: payload.expirationMonth,
      expirationYear: payload.expirationYear,
      cardType: cardType[0].type,
      address1: payload.address1,
      postalCode: payload.postalCode,
      last4: payload.cardNumber.slice(-4),
      autopay: payload.autopay,
      saveForFuture: payload.saveForFuture,
      userName: mobile ? 'MOBILE APP USER' : undefined,
    };

    const apiResponse = yield call(apiCall, {
      operationId: 'BillingAccounts.createBillingAccountPaymentMethod',
      parameters: { billingAccountId },
      options: { requestBody },
    });

    if (!apiResponse?.id) throw new Error('Invalid api response');

    const id = `${apiResponse.id}`;

    if (payload.saveForFuture === true) {
      yield put({ type: types.ADD_CREDIT_CARD_SUCCESS });
    } else {
      yield put({
        type: types.ADD_CREDIT_CARD_SUCCESS,
        payload: {
          id,
          ...pick(requestBody, [
            'cardholderName',
            'cardType',
            'expirationYear',
            'expirationMonth',
            'postalCode',
            'address1',
            'last4',
          ]),
          autopay: false,
        },
      });
    }

    if (payload.autopay && payload.saveForFuture) {
      yield put(actions.updateAutoPay({ id, ...payload }));
    } else {
      yield put(actions.loadCreditCards());
      yield take(types.LOAD_CREDIT_CARDS_SUCCESS);
      yield put(actions.setCurrentPaymentMethod(id));
    }
  } catch (error) {
    yield handleError(
      'addCreditCard',
      types.ADD_CREDIT_CARD_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* updateCreditCard({ payload: { isUpdateSettings = false, ...data } }) {
  try {
    throwErrorTest('updateCreditCard');
    const billingAccountId = yield select(selectBillingAccountId());
    const paymentMethodId = data.id;
    const mobile = cookies.get('isMobileView') === 'Y';

    const requestBody = {
      ...pick(data, [
        'cardholderName',
        'expirationMonth',
        'expirationYear',
        'cardType',
        'address1',
        'postalCode',
        'autopay',
        'last4',
      ]),
      userName: mobile ? 'MOBILE APP USER' : undefined,
    };

    yield call(apiCall, {
      operationId: 'BillingAccounts.updateBillingAccountPaymentMethod',
      parameters: {
        billingAccountId,
        paymentMethodId,
      },
      options: { requestBody },
    });

    yield put({
      type: types.UPDATE_CREDIT_CARD_SUCCESS,
      payload: paymentMethodId,
    });

    if (isUpdateSettings) {
      yield put({
        type: types.UPDATE_STATEMENT_SETTINGS,
        payload: { paperlessEnabled: data.paperlessEnabled },
      });
    }

    yield put(hideDrawer(`${EDIT_CREDIT_CARD}-${data.id}`));
  } catch (error) {
    yield handleError(
      'updateCreditCard',
      types.UPDATE_CREDIT_CARD_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* deleteCreditCard({ payload: id }) {
  try {
    throwErrorTest('deleteCreditCard');
    const billingAccountId = yield select(selectBillingAccountId());
    const paymentMethodId = id;

    yield call(apiCall, {
      operationId: 'BillingAccounts.deleteBillingAccountPaymentMethod',
      parameters: { billingAccountId, paymentMethodId },
    });

    yield put({ type: types.DELETE_CREDIT_CARD_SUCCESS, payload: id });
  } catch (error) {
    yield handleError(
      'deleteCreditCard',
      types.DELETE_CREDIT_CARD_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* addBankAccount({ payload }) {
  try {
    throwErrorTest('addBankAccount');
    const billingAccountId = yield select(selectBillingAccountId());
    const mobile = cookies.get('isMobileView') === 'Y';

    const requestBody = {
      accountNumber: payload.accountNumber,
      routingNumber: payload.routingNumber,
      accountType: payload.accountType,
      nameOnAccount: payload.nameOnAccount,
      address1: payload.address1,
      city: payload.city,
      stateOrProvinceCode: payload.stateOrProvinceCode,
      postalCode: payload.postalCode,
      email: payload.email,
      autopay: payload.autopay,
      saveForFuture: true,
      userName: mobile ? 'MOBILE APP USER' : undefined,
    };

    yield call(apiCall, {
      operationId: 'BillingAccounts.createBillingAccountBankAccount',
      fullResponse: true,
      parameters: {
        billingAccountId,
      },
      options: { requestBody },
    });

    // FIXME id is not returned in response, but in header
    // location: /v1/billing-accounts/20735348/payment-methods/1385279
    //
    // unfortunately it seems the swagger spec needs to be changed or
    // this field cannot be retrieved from the response.
    // https://github.com/go-swagger/go-swagger/issues/1918

    // console.log(apiResponse);
    // if (!apiResponse?.id) throw new Error('Invalid api response');
    // const id = `${apiResponse.id}`;

    yield put({ type: types.ADD_BANK_ACCOUNT_SUCCESS });

    if (payload.autopay) {
      // autopay selection might effect all pay methods, so refetch
      yield put({
        type: types.FETCH_PAYMENT_METHODS_REQUEST,
        payload: { force: true },
      });
    } else {
      yield put(actions.loadBankAccounts());
      yield take(types.LOAD_BANK_ACCOUNTS_SUCCESS);
      // yield put(actions.setCurrentPaymentMethod(id));
    }
    yield put(hideDrawer(EDIT_BANK_ACCOUNT));
  } catch (error) {
    yield handleError(
      'addBankAccount',
      types.ADD_BANK_ACCOUNT_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* updateBankAccount({
  payload: { isUpdateSettings = false, ...data },
}) {
  try {
    throwErrorTest('updateBankAccount');
    const billingAccountId = yield select(selectBillingAccountId());
    const bankAccountId = data.id;
    const mobile = cookies.get('isMobileView') === 'Y';

    const requestBody = {
      ...pick(data, [
        'accountNumber',
        'routingNumber',
        'accountType',
        'nameOnAccount',
        'address1',
        'city',
        'stateOrProvinceCode',
        'postalCode',
        'email',
        'autopay',
      ]),
      userName: mobile ? 'MOBILE APP USER' : undefined,
    };

    const bankAccount = yield call(apiCall, {
      operationId: 'BillingAccounts.updateBillingAccountBankAccount',
      parameters: {
        billingAccountId,
        bankAccountId,
      },
      options: { requestBody },
    });

    yield put({
      type: types.UPDATE_BANK_ACCOUNT_SUCCESS,
      payload: bankAccount,
    });

    if (isUpdateSettings) {
      yield put({
        type: types.UPDATE_STATEMENT_SETTINGS,
        payload: { paperlessEnabled: data.paperlessEnabled },
      });
    }

    yield put(hideDrawer(`${EDIT_BANK_ACCOUNT}-${bankAccountId}`));
  } catch (error) {
    yield handleError(
      'updateBankAccount',
      types.UPDATE_BANK_ACCOUNT_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* deleteBankAccount({ payload: id }) {
  try {
    throwErrorTest('deleteBankAccount');
    const billingAccountId = yield select(selectBillingAccountId());

    yield call(apiCall, {
      operationId: 'BillingAccounts.deleteBillingAccountBankAccount',
      parameters: { billingAccountId, bankAccountId: id },
    });

    yield put({ type: types.DELETE_BANK_ACCOUNT_SUCCESS, payload: id });
  } catch (error) {
    yield handleError(
      'deleteBankAccount',
      types.DELETE_BANK_ACCOUNT_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* setAutopayOnMethod(method, bool) {
  const isBank = !!method.bankName;
  const isCard = !!method.cardType;

  if (!isBank && !isCard) {
    throw new Error('Invalid payment method given.');
  }

  if (isBank) {
    const { id, nameOnAccount } = method;

    yield put({
      type: types.UPDATE_BANK_ACCOUNT,
      payload: {
        id,
        nameOnAccount,
        autopay: bool,
      },
    });

    yield all([take(types.UPDATE_BANK_ACCOUNT_SUCCESS)]);
  }

  if (isCard) {
    yield put({
      type: types.UPDATE_CREDIT_CARD,
      payload: {
        ...pick(method, [
          'id',
          'cardholderName',
          'cardType',
          'expirationMonth',
          'expirationYear',
          'last4',
        ]),
        autopay: bool,
      },
    });

    yield all([take(types.UPDATE_CREDIT_CARD_SUCCESS)]);
  }
}

function* setChosenAutopayMethod({ payload: chosen }) {
  try {
    throwErrorTest('setChosenAutopayMethod');
    if (!chosen.id) {
      throw new Error('Given payment method must include id.');
    }

    const isAlreadyAutopay = chosen.autopay === true;

    if (isAlreadyAutopay) {
      yield put({ type: types.UPDATE_AUTO_PAY_SUCCESS });
      return;
    }

    // Here's an interesting quirk of the underlying apis:
    // if updating a bank acct to autopay: true,
    //   all other methods (cc & bank) will be set autopay: false
    // BUT if updating a cc to autopay: true,
    //   all bank accts will be set autopay: false
    //   BUT other cc's will not, possibly allowing 2+ autopay cc's
    // THUS if a cc is chosen as autopay method,
    //   we must manually unset autopay on any current autopay cc

    const isCard = !!chosen.cardType;
    const autopayCards = yield select(selectAutopayCreditCards());
    const hasAutopayCards = autopayCards.length > 0;

    if (isCard && hasAutopayCards) {
      const jobs = autopayCards.map((card) => call(setAutopayOnMethod, card, false)); // eslint-disable-line
      yield all(jobs);
    }

    if (!chosen.autopay) {
      yield call(setAutopayOnMethod, chosen, true);
    }

    yield put({
      type: types.FETCH_PAYMENT_METHODS_REQUEST,
      payload: { force: true },
    });

    yield all([take(types.FETCH_PAYMENT_METHODS_SUCCESS)]);

    yield put({ type: types.UPDATE_AUTO_PAY_SUCCESS });
    yield put(hideDrawer(AUTOPAY_SIGN_UP));
  } catch (error) {
    yield handleError(
      'setChosenAutopayMethod',
      types.UPDATE_AUTO_PAY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* loadStatementSettings() {
  try {
    throwErrorTest('loadStatementSettings');
    const { id: serviceLocationId } = yield call(waitServiceLocation);

    const response = yield call(apiCall, {
      operationId: 'ServiceLocations.getStatementSettings',
      parameters: { serviceLocationId },
    });

    yield put({
      type: types.GET_STATEMENT_SETTINGS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield handleError(
      'loadStatementSettings',
      types.GET_STATEMENT_SETTINGS_FAILURE,
      getErrorMessage(error),
    );
  }
}

export function* waitServiceLocation() {
  const serviceLocation = yield select(selectSelectedServiceLocation());
  if (serviceLocation) {
    return serviceLocation;
  }

  yield take(SELECT_SERVICE_LOCATION_SUCCESS);
  return yield select(selectSelectedServiceLocation());
}

function* updateStatementSettings({ payload }) {
  try {
    throwErrorTest('updateStatementSettings');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    const currentStatementSettings = yield select(selectStatementData());
    const userEmail = yield select(selectUserEmail());

    const getEmail = () => {
      if (payload.email) {
        return payload.email;
      }

      if (currentStatementSettings.email) {
        return currentStatementSettings.email;
      }

      return userEmail;
    };

    const requestBody = {
      paperlessEnabled: payload.paperlessEnabled,
      email: getEmail(),
    };

    const response = yield call(apiCall, {
      operationId: 'ServiceLocations.updateStatementSettings',
      parameters: { serviceLocationId },
      options: { requestBody },
    });

    yield put({
      type: types.UPDATE_STATEMENT_SETTINGS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield handleError(
      'updateStatementSettings',
      types.UPDATE_STATEMENT_SETTINGS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getBillingAccount() {
  try {
    throwErrorTest('getBillingAccount');
    const billingAccountId = yield select(selectBillingAccountId());
    const response = yield call(apiCall, {
      operationId: 'BillingAccounts.getBillingAccount',
      parameters: { billingAccountId },
    });

    yield put({ type: types.GET_BILLING_ACCOUNT_SUCCESS, payload: response });
  } catch (error) {
    yield handleError(
      'getBillingAccount',
      types.GET_BILLING_ACCOUNT_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* loadBillingAccounts(data) {
  try {
    throwErrorTest('loadBillingAccounts');
    const userInfo = yield select(selectUserInfo());
    let billingAccounts;
    if (data) {
      billingAccounts = data.SelfServeUsers;
    } else {
      const billingAccountId = yield select(selectBillingAccountId());
      const response = yield call(apiCall, {
        operationId: 'Users.getSelfServeUsers',
        parameters: { billingAccountId },
      });
      billingAccounts = response.SelfServeUsers;
    }
    const otherUsernames = billingAccounts
      ? billingAccounts.filter(
          (account) =>
            account.userName.toUpperCase() !== userInfo.name.toUpperCase(),
        )
      : [];
    const loggedInBillingAccountUser = billingAccounts
      ? billingAccounts.find(
          (account) =>
            account.userName.toUpperCase() === userInfo.name.toUpperCase(),
        )
      : userInfo;

    const loggedInUser = { ...userInfo, ...loggedInBillingAccountUser };

    yield put({
      type: types.LOAD_BILLING_ACCOUNTS_SUCCESS,
      payload: {
        billingAccounts: otherUsernames,
        userInfo: loggedInUser,
      },
    });
  } catch (error) {
    yield handleError(
      'loadBillingAccounts',
      types.LOAD_BILLING_ACCOUNTS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* updatePassword() {
  // TODO: Finish this with { payload }
}

function* updateLoggedInUserDetails({ payload }) {
  try {
    throwErrorTest('updateLoggedInUserDetails');
    const userId = yield select(selectUserId());
    const billingAccountId = yield select(selectBillingAccountId());
    const response = yield call(apiCall, {
      operationId: 'Users.updateLoggedInUserDetails',
      parameters: { userId, billingAccountId },
      options: { requestBody: payload },
    });

    yield loadBillingAccounts(response);
    yield put({ type: types.UPDATE_USER_DETAILS_SUCCESS, payload: response });
  } catch (error) {
    yield handleError(
      'updateLoggedInUserDetails',
      types.UPDATE_USER_DETAILS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* deleteAssociatedBillingAccount({ payload }) {
  try {
    throwErrorTest('deleteAssociatedBillingAccount');
    const userId = yield select(selectUserId());
    const billingAccountId = yield select(selectBillingAccountId());
    const response = yield call(apiCall, {
      operationId: 'Users.deleteAssociatedBillingAccount',
      parameters: { userId, billingAccountId },
      options: {
        requestBody: {
          userName: payload.userName,
        },
      },
    });
    yield loadBillingAccounts(response);

    yield put({
      type: types.DELETE_BILLING_ACCOUNT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield handleError(
      'deleteAssociatedBillingAccount',
      types.DELETE_BILLING_ACCOUNT_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* addAuthorizedUser({ payload }) {
  try {
    throwErrorTest('addAuthorizedUser');
    const billingAccount = yield select(selectBillingAccount());
    const webAuth = new auth0.WebAuth({
      domain: getServerData('AUTH_DOMAIN'),
      clientID: getAuthClientId(),
    });
    const password = getRandomString(20);
    const signUpChannel = eventChannel((emitter) => {
      webAuth.signup(
        {
          connection: ORACLE_REALM_NAME,
          email: payload.emailAddress,
          password,
          userMetadata: {
            accountNumber: `${billingAccount.accountNumber}${billingAccount.id}`,
            firstName: payload.firstName,
            lastName: payload.lastName,
          },
        },
        (er) => {
          if (er) {
            // eslint-disable-next-line no-console
            console.error(er);
            emitter({ data: er });
          } else {
            emitter({ data: { email: payload.emailAddress } });
          }
        },
      );

      return () => {
        // Perform any cleanup you need here
      };
    });

    while (true) {
      const { data } = yield take(signUpChannel);
      if (!data) {
        break;
      }
      if (data.statusCode === 400) {
        throw new Error(data.description);
      }

      yield loadBillingAccounts();

      webAuth.changePassword(
        {
          connection: ORACLE_REALM_NAME,
          email: data.email,
        },
        () => {
          // handle callback. nothing expected as of now.
        },
      );

      yield put({
        type: types.ADD_AUTHORIZED_USER_SUCCESS,
        payload: {},
      });
    }
  } catch (error) {
    yield handleError(
      'addAuthorizedUser',
      types.ADD_AUTHORIZED_USER_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* updateBillingAddress({ payload }) {
  try {
    throwErrorTest('updateBillingAddress');
    const billingAccountId = yield select(selectBillingAccountId());
    const requestBody = {
      address1: payload.address1,
      address2: payload.address2,
      city: payload.city,
      stateOrProvinceCode: payload.stateOrProvinceCode,
      postalCode: payload.postalCode,
      country: payload.countryCode,
    };
    yield call(apiCall, {
      operationId: 'BillingAccounts.updateBillingAddress',
      parameters: { billingAccountId },
      options: { requestBody },
    });
    yield getBillingAccount();
    yield put({ type: types.UPDATE_BILLING_ADDRESS_SUCCESS });
  } catch (error) {
    yield handleError(
      'updateBillingAddress',
      types.UPDATE_BILLING_ADDRESS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* closeInvoiceDrawer() {
  yield put(hideDrawer(EDIT_INVOICE_INFORMATION));
  yield put({ type: types.CLOSE_INVOICE_DRAWER_SUCCESS });
}

function* closeUserDetailsDrawer() {
  yield put(hideDrawer(UPDATE_USER_DETAILS));
  yield put({ type: types.CLOSE_USER_DETAILS_DRAWER_SUCCESS });
}

function* closeRemoveEmailsDrawer() {
  yield put(hideDrawer(EDIT_EMAIL_ADDRESS));
  yield put({ type: types.CLOSE_REMOVE_EMAIL_DRAWER_SUCCESS });
}

function* closeAddAuthorizedUserDrawer() {
  yield put(hideDrawer(ADD_NEW_ACCOUNT_LOGIN));
  yield put({ type: types.CLOSE_ADD_AUTHORIZED_USER_DRAWER_SUCCESS });
}

function* closeEditBillingDrawer() {
  yield put(hideDrawer(EDIT_BILLING_INFORMATION));
  yield put({ type: types.CLOSE_EDIT_BILLING_DRAWER_SUCCESS });
}

function* fetchDeliveryNotes() {
  try {
    throwErrorTest('fetchDeliveryNotes');
    const response = yield call(apiCall, {
      operationId: 'ServiceLocations.getDeliveryNotes',
    });
    const { deliveryNotes } = response;
    const houseIndex = deliveryNotes.findIndex((item) =>
      equalsIgnoreCase(item.noteType, 'HOUSE'),
    );
    const apartmentIndex = deliveryNotes.findIndex((item) =>
      equalsIgnoreCase(item.noteType, 'APARTMENT'),
    );
    const businessIndex = deliveryNotes.findIndex((item) =>
      equalsIgnoreCase(item.noteType, 'BUSINESS'),
    );

    yield put({
      type: types.FETCH_DELIVERY_NOTES_SUCCESS,
      payload: [
        deliveryNotes[houseIndex],
        deliveryNotes[apartmentIndex],
        deliveryNotes[businessIndex],
      ],
    });
  } catch (error) {
    yield handleError(
      'fetchCustomerNotes',
      types.FETCH_DELIVERY_NOTES_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* fetchCustomerNotes() {
  try {
    throwErrorTest('fetchCustomerNotes');
    const userId = yield select(selectUserId());
    const customerNotes = yield call(apiCall, {
      operationId: 'Users.getUserServiceAndBillingLocations',
      parameters: { userId },
    });
    yield put({
      type: types.FETCH_CUSTOMER_NOTES_SUCCESS,
      payload: customerNotes,
    });
  } catch (error) {
    yield handleError(
      'fetchCustomerNotes',
      types.FETCH_CUSTOMER_NOTES_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* updateCustomerNotes({ payload }) {
  try {
    throwErrorTest('updateCustomerNotes');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    yield call(apiCall, {
      operationId: 'ServiceLocations.createCustomerDeliveryNotes',
      parameters: {
        serviceLocationId,
      },
      options: {
        requestBody: {
          ...payload,
        },
      },
    });
    yield put({
      type: types.UPDATE_CUSTOMER_NOTES_SUCCESS,
    });
  } catch (error) {
    yield handleError(
      'updateCustomerNotes',
      types.UPDATE_CUSTOMER_NOTES_FAILURE,
      getErrorMessage(error),
    );
  }
}

export default function* primoProfileSaga() {
  yield all([
    takeLatest(CHANGE_PASSWORD_SUCCESS, hideDrawerSaga(ACCOUNT_DETAILS_TITLE)),
    takeLatest(
      CHANGE_PASSWORD_SUCCESS,
      showDrawerSaga(ACCOUNT_DETAILS_SUCCESS_TITLE),
    ),
    takeLatest(types.LOAD_CREDIT_CARDS, fetchCreditCards),
    takeLatest(types.LOAD_BANK_ACCOUNTS, fetchBankAccounts),
    takeLatest(types.SET_CURRENT_PAYMENT_METHOD, setCurrentPaymentMethod),
    takeLatest(types.FETCH_PAYMENT_METHODS_REQUEST, fetchPaymentMethods),
    takeLatest(types.GET_BILLING_SUMMARY, getBillingSummary),
    takeLatest(types.ADD_CREDIT_CARD, addCreditCard),
    takeLatest(types.UPDATE_CREDIT_CARD, updateCreditCard),
    takeLatest(types.UPDATE_AUTO_PAY, setChosenAutopayMethod),
    takeLatest(types.DELETE_CREDIT_CARD, deleteCreditCard),
    takeLatest(types.ADD_BANK_ACCOUNT, addBankAccount),
    takeLatest(types.UPDATE_BANK_ACCOUNT, updateBankAccount),
    takeLatest(types.DELETE_BANK_ACCOUNT, deleteBankAccount),
    takeLatest(types.GET_STATEMENT_SETTINGS, loadStatementSettings),
    takeLatest(types.UPDATE_STATEMENT_SETTINGS, updateStatementSettings),
    takeLatest(types.GET_BILLING_ACCOUNT, getBillingAccount),
    takeLatest(types.CLOSE_INVOICE_DRAWER, closeInvoiceDrawer),
    takeLatest(types.CLOSE_EDIT_BILLING_DRAWER, closeEditBillingDrawer),
    takeLatest(types.CLOSE_CUSTOMER_NOTES_DRAWER, closeCustomerNotesDrawer),
    takeLatest(types.FETCH_DELIVERY_NOTES, fetchDeliveryNotes),
    takeLatest(types.FETCH_CUSTOMER_NOTES, fetchCustomerNotes),
    takeLatest(types.UPDATE_CUSTOMER_NOTES, updateCustomerNotes),
    takeLatest(types.UPDATE_CUSTOMER_NOTES_SUCCESS, fetchCustomerNotes),
    takeLatest(types.UPDATE_BILLING_ADDRESS, updateBillingAddress),
    takeLatest(types.UPDATE_PASSWORD, updatePassword),
    takeLatest(types.UPDATE_USER_DETAILS, updateLoggedInUserDetails),
    takeLatest(types.DELETE_BILLING_ACCOUNT, deleteAssociatedBillingAccount),
    takeLatest(types.ADD_AUTHORIZED_USER, addAuthorizedUser),
    takeLatest(types.LOAD_BILLING_ACCOUNTS, loadBillingAccounts),
    takeLatest(types.CLOSE_USER_DETAILS_DRAWER, closeUserDetailsDrawer),
    takeLatest(types.CLOSE_REMOVE_EMAIL_DRAWER, closeRemoveEmailsDrawer),
    takeLatest(
      types.CLOSE_ADD_AUTHORIZED_USER_DRAWER,
      closeAddAuthorizedUserDrawer,
    ),
  ]);
}
