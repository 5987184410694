import React from 'react';
import { ButtonGroup as MUIButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  grouped: {
    [theme.breakpoints.down('md')]: {
      '&:not(:last-child)': {
        borderRight: 'none',
        borderBottom: theme.borders.greyBorder(false),
      },
    },
  },
}));

const ButtonGroup = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <MUIButtonGroup
      className={className}
      classes={{
        root: classes.root,
        grouped: classes.grouped,
      }}
      variant="contained"
      {...rest}
    >
      {React.Children.toArray(children)}
    </MUIButtonGroup>
  );
};

export default ButtonGroup;
