import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const useIsVertTablet = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isTabletBelow = useMediaQuery('(max-width: 1024px)');
  return isPortrait && isTabletBelow && !isMobile;
};

export default useIsVertTablet;
