import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import { List } from 'immutable';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { Input, Button, ErrorMessage } from 'components/Ui';
import { signup } from 'containers/Authentication/actions';
import {
  selectSignUpFetching,
  selectSignUpError,
} from 'containers/Authentication/selectors';
import styles from './styles.scss';

export class Signup extends React.PureComponent {
  state = {
    email: '',
    password: '',
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    const {
      location: { state = {} },
      redirectUrl,
    } = this.props;
    const returnTo = redirectUrl || state.prevPath;

    this.props.signup({ email, password, returnTo });
  };

  render() {
    const {
      state: { email, password },
      props: { isFetching, error, className },
    } = this;

    return (
      <ValidatorForm onSubmit={this.handleSubmit} className={className}>
        <div className={styles.title}>
          Let’s get you set up with an account.
          <h1>Sign Up</h1>
        </div>
        {error && (
          <ErrorMessage style={{ marginBottom: '5px' }}>
            {List.isList(error)
              ? error.map((errorEntry) => (
                  <div key={errorEntry}>{errorEntry}</div>
                ))
              : error}
          </ErrorMessage>
        )}
        <div className={styles.form}>
          <Input
            label="Username (Email)"
            validators={['required']}
            errorMessages={['']}
            value={email}
            onChange={this.handleChange}
            name="email"
            autoComplete="off"
            withoutError
          />
          <Input
            label="Password"
            validators={['required']}
            errorMessages={['']}
            value={password}
            type="password"
            onChange={this.handleChange}
            name="password"
            autoComplete="off"
            withoutError
          />
          <Button
            text="CONTINUE"
            className={styles.submit}
            loading={isFetching}
          />
          <div className={styles.info}>
            Already have service?&nbsp;
            <Link to="/home">Create an online account</Link>
          </div>
        </div>
      </ValidatorForm>
    );
  }
}

Signup.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(List)]),
  signup: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  className: PropTypes.string,
  redirectUrl: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      prevPath: PropTypes.string,
    }),
  }),
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectSignUpFetching(),
  error: selectSignUpError(),
});

const mapDispatchToProps = {
  signup,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Signup);
