export const CART_PAGE_KEY = 'cart';

export const RESET_CART_ERROR = 'cart/RESET_CART_ERROR';

export const FETCH_USER_CART = 'cart/FETCH_USER_CART';
export const FETCH_USER_CART_SUCCESS = 'cart/FETCH_USER_CART_SUCCESS';
export const FETCH_USER_CART_FAILURE = 'cart/FETCH_USER_CART_FAILURE';

export const FETCH_ANONYMOUS_CART = 'cart/FETCH_ANONYMOUS_CART';
export const FETCH_ANONYMOUS_CART_SUCCESS = 'cart/FETCH_ANONYMOUS_CART_SUCCESS';
export const FETCH_ANONYMOUS_CART_FAILURE = 'cart/FETCH_ANONYMOUS_CART_FAILURE';

export const LOAD_CART_PRODUCTS = 'cart/LOAD_CART_PRODUCTS';
export const LOAD_CART_PRODUCTS_SUCCESS = 'cart/LOAD_CART_PRODUCTS_SUCCESS';
export const LOAD_CART_PRODUCTS_FAILURE = 'cart/LOAD_CART_PRODUCTS_FAILURE';

export const FETCH_ORDER_PRODUCTS = 'cart/FETCH_ORDER_PRODUCTS';
export const FETCH_ORDER_PRODUCTS_SUCCESS = 'cart/FETCH_ORDER_PRODUCTS_SUCCESS';
export const FETCH_ORDER_PRODUCTS_FAILURE = 'cart/FETCH_ORDER_PRODUCTS_FAILURE';

export const CHANGE_ITEM_QUANTITY = 'cart/CHANGE_ITEM_QUANTITY';
export const CHANGE_ITEM_QUANTITY_SUCCESS = 'cart/CHANGE_ITEM_QUANTITY_SUCCESS';
export const CHANGE_ITEM_QUANTITY_FAILURE = 'cart/CHANGE_ITEM_QUANTITY_FAILURE';

export const REMOVE_ITEM = 'cart/REMOVE_ITEM';
export const REMOVE_ITEM_SUCCESS = 'cart/REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_FAILURE = 'cart/REMOVE_ITEM_FAILURE';

export const CREATE_CART = 'cart/CREATE_CART';
export const CREATE_CART_SUCCESS = 'cart/CREATE_CART_SUCCESS';
export const CREATE_CART_FAILURE = 'cart/CREATE_CART_FAILURE';

export const ADD_ITEM_TO_ANONYMOUS_CART = 'cart/ADD_ITEM_TO_ANONYMOUS_CART';
export const ADD_ITEM_TO_ANONYMOUS_CART_SUCCESS =
  'cart/ADD_ITEM_TO_ANONYMOUS_CART_SUCCESS';
export const ADD_ITEM_TO_ANONYMOUS_CART_FAILURE =
  'cart/ADD_ITEM_TO_ANONYMOUS_CART_FAILURE';

export const ADD_ITEM_TO_USER_CART = 'cart/ADD_ITEM_TO_USER_CART';
export const ADD_ITEM_TO_USER_CART_SUCCESS =
  'cart/ADD_ITEM_TO_USER_CART_SUCCESS';
export const ADD_ITEM_TO_USER_CART_FAILURE =
  'cart/ADD_ITEM_TO_USER_CART_FAILURE';

export const UPDATE_CART = 'cart/UPDATE_CART';

export const MERGE_ANON_CART = 'cart/MERGE_ANON_CART';
export const MERGE_ANON_CART_SUCCESS = 'cart/MERGE_ANON_CART_SUCCESS';
export const MERGE_ANON_CART_FAILURE = 'cart/MERGE_ANON_CART_FAILURE';

export const CLEAR_CART = 'cart/CLEAR_CART';

export const CLEAR_CART_FLAG = 'cart/CLEAR_CART_FLAG';

export const GET_CART_SUMMARY = 'cart/GET_CART_SUMMARY';
export const GET_CART_SUMMARY_SUCCESS = 'cart/GET_CART_SUMMARY_SUCCESS';
export const GET_CART_SUMMARY_FAILURE = 'cart/GET_CART_SUMMARY_FAILURE';

export const ADD_ITEM_TO_CART = 'cart/ADD_ITEM_TO_CART';

export const ADD_PROMOTION = 'cart/ADD_PROMOTION';
export const ADD_PROMOTION_SUCCESS = 'cart/ADD_PROMOTION_SUCCESS';
export const ADD_PROMOTION_FAILURE = 'cart/ADD_PROMOTION_FAILURE';

export const REMOVE_PROMOTION = 'cart/REMOVE_PROMOTION';
export const REMOVE_PROMOTION_SUCCESS = 'cart/REMOVE_PROMOTION_SUCCESS';
export const REMOVE_PROMOTION_FAILURE = 'cart/REMOVE_PROMOTION_FAILURE';

export const LOAD_PROMOTIONS = 'cart/LOAD_PROMOTIONS';
export const LOAD_PROMOTIONS_SUCCESS = 'cart/LOAD_PROMOTIONS_SUCCESS';
export const LOAD_PROMOTIONS_FAILURE = 'cart/LOAD_PROMOTIONS_FAILURE';

export const PROMOTION_ID = 17601;
