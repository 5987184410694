import React from 'react';
import PropTypes from 'prop-types';
import { ProductGridItem } from 'components/ContentfulFields/ProductCard';
import { isContentType } from 'components/Content';

import styles from './styles.scss';

const Product = ({ document, section, index, isAllProductsPage }) =>
  document && (
    <div className={styles.productWrapper}>
      <ProductGridItem
        section={section}
        displayIndex={index}
        className={styles.product}
        product={document.fields}
        isBundle={isContentType(document, 'productBundle')}
        isAllProductsPage={isAllProductsPage}
      />
    </div>
  );

Product.propTypes = {
  document: PropTypes.object,
};

export default Product;
