import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RestrictToBrands from 'components/RestrictToBrands';
import { selectPromotionLandingPageData } from 'containers/Landing/selectors';
import { BRANDS } from 'containers/Landing/constants';

const Promo50Restricted = (props) => {
  const { landing } = props;

  const brands = [BRANDS.CRYSTAL];

  // here via a promo landing? no brand restriction
  if (landing?.campaignId) return <>{props.children}</>;

  return (
    <RestrictToBrands {...props} brands={brands}>
      {props.children}
    </RestrictToBrands>
  );
};

const withFullPressGuided = (Component) => {
  let FullPressGuided = (props) => {
    const { landing, promos } = props;

    const promo = promos.items[0];
    const promoHandle = promo.fields.handle;

    const { campaignId, elementId } = landing || {};
    const landingPageId = campaignId && `${campaignId}_${elementId}`;

    const dataProps = landingPageId
      ? { 'data-tracking': promoHandle, 'data-landing-id': landingPageId }
      : { 'data-tracking': promoHandle };

    const Promo = (
      <span {...dataProps}>
        <Component content={promo} {...props} />
      </span>
    );

    if (promoHandle === 'promo50Restricted') {
      return (
        <Promo50Restricted {...props}>
          <Promo />
        </Promo50Restricted>
      );
    }

    return Promo;
  };

  const mapStateToProps = createStructuredSelector({
    landing: selectPromotionLandingPageData(),
  });

  FullPressGuided = connect(mapStateToProps)(FullPressGuided);

  return FullPressGuided;
};

export default withFullPressGuided;
