import get from 'lodash/get';

import CheckboxOn from './checkbox/CheckboxOn.png';
import CheckboxOff from './checkbox/CheckboxOff.png';
import CheckboxLocked from './checkbox/CheckboxLocked.png';

import Camera from './account/Camera.png';
import Message from './account/Message.png';
import MessageGreen from './account/MessageGreen.png';
import Question from './account/Question.png';
import AddItem from './account/addItem.png';
import SkipIcon from './account/skipIcon.png';
import ManageIcon from './account/manageIcon.png';
import Calendar from './account/calendar.png';
import Bank from './account/Bank.png';
import Card from './account/Card.png';
import CreditCard from './account/CreditCard.png';
import Person from './account/Person.png';
import Invoice from './account/Invoice.png';
import Autopay from './account/Autopay.png';
import Droplet from './account/droplet.png';
import Cog from './account/cog.png';
import Not from './account/not.png';
import Plus from './account/Plus.png';
import BottlePickup from './account/BottlePickup.png';
import EquipmentService from './account/EquipmentService.png';
import GreenCamera from './account/greenCamera.png';
import InvoiceGreen from './account/invoiceGreen.png';
import AutopayGreen from './account/autopayGreen.png';
import InProgress from './account/inProgress.png';
import InQueue from './account/inQueue.png';
import Deliver from './account/deliver.png';
import Prepared from './account/prepared.png';
import Finger from './account/finger.svg';
import WrenchIcon from './account/wrench.png';
import LocationIcon from './account/locationIcon.png';
import ArrowBlack from './account/arrowBlack.png';
import EasyEditPlan from './account/EasyEditPlan.png';
import PredictiveOrder from './account/PredictiveOrder.png';
import Replenish from './account/Replenish.png';
import LargeBottleCircle from './account/LargeBottleCircle.png';
import LargeBottleCircleNot from './account/LargeBottleCircleNot.png';

import OneTimeIcon from './cart/oneTime.png';
import Recurring from './cart/recurring.png';

import ComingSoon from './ComingSoon.png';
export { default as comingSoon } from './ComingSoon.png';

export { default as warningRed } from './WarningRed.png';
export { default as warningRedSmall } from './WarningRedSmall.png';
export { default as pinIconWhite } from './PinIconWhite.png';
export { default as pinIconBlack } from './PinIconBlack.png';
export { default as carbon } from './Carbon.png';
export { default as cartridge } from './Cartridge.png';
export { default as chlorine } from './Chlorine.png';
export { default as accountNew } from './Account-New.svg';
export { default as accountFrench } from './Account-French.png';
export { default as detoxification } from './Detoxification.png';
export { default as emerging } from './Emerging.png';
export { default as finishQuiz } from './finishQuiz.png';
export { default as healthFirst } from './healthFirst.png';
export { default as healthySkin } from './HealthySkin.png';
export { default as ioT } from './IoT.png';
export { default as lead } from './Lead.png';
export { default as leaf } from './Leaf.png';
export { default as leakDetection } from './LeakDetection.png';
export { default as logo } from './logo.svg';
export { default as map } from './Map.png';
export { default as minerals } from './Minerals.png';
export { default as nitrates } from './Nitrates.png';
export { default as overallHealth } from './OverallHealth.png';
// export { default as rectangle } from './rectangle.png';
export { default as reverseOsmosis } from './ReverseOsmosis.png';
// export { default as scale } from './Scale.png';
export { default as shaker } from './Shaker.png';
export { default as thermometerCold } from './ThermometerCold.png';
export { default as thermometerHot } from './ThermometerHot.png';
export { default as towerTransmit } from './TowerTransmit.png';
export { default as itemStatusComplete } from './itemStatusComplete.png';
export { default as itemStatusActive } from './ItemStatusActive.png';
export { default as itemStatusInactive } from './ItemStatusInactive.png';
export { default as contactPrefGroup } from './ContactPrefGroup.png';
export { default as emailIcon } from './emailIcon.png';
export { default as textIcon } from './textIcon.png';
export { default as callIcon } from './callIcon.png';
export { default as autopayIcon } from './autopayIcon.png';
export { default as monitorIcon } from './monitorIcon.png';
export { default as chatIcon } from './chatIcon.svg';
export { default as lockIcon } from './lockIcon.png';
export { default as xBtn } from './xBtn.png';
export { default as BlackBtn } from './BlackBtn.png';
export { default as arrowBackward } from './arrowBackward.png';
export { default as arrowForward } from './arrowForward.png';
export { default as ProcessingGreen } from './ProcessingGreen.png';
export { default as ThumbUpGreen } from './ThumbUpGreen.svg';
export { default as ThumbUpWhite } from './ThumbUpWhite.png';
export { default as KeyGreen } from './key.png';
export { default as LetterGreen } from './Letter.png';
export { default as InfoIcon } from './InfoIcon.svg';
export { default as ArrowInRound } from './arrowInRound.png';
export { default as PromotionIcon } from './promotionIcon.png';
export { default as PromotionWhiteIcon } from './PromotionWhite.png';
export { default as PromotionBlackIcon } from './PromotionBlackIcon.svg';
export { default as PinIcon } from './Pin.svg';
export { default as QuickShopImage } from './QuickShopImage.png';
export { default as ShopImage } from './ShopImage.png';
export { default as QuickShopImageGeneric } from './QuickShopImageGeneric.png';
export { default as QuickShopImageNew } from './QuickShopImageNew.png';
export { default as ShopImageGeneric } from './ShopImageGeneric.png';
export { default as SmileDownIcon } from './SmileDownIcon.png';
export { default as PinMapIcon } from './Pin.png';
export { default as PinZipIcon } from './PinZip.png';
export { default as PinGreen } from './PinGreen.png';
export { default as InfinityGreen } from './InfinityGreen.svg';
export { default as Bottle } from './Bottle.jpg';
export { default as Cooler } from './Cooler.jpg';
export { default as TimerGreen } from './TimerGreen.png';
export { default as ArrowLeftWhite } from './ArrowLeftWhite.png';
export { default as ArrowLeftBlue } from './ArrowLeftBlue.png';
export { default as ArrowRightWhite } from './ArrowRightWhite.png';
export { default as ArrowRightBlue } from './ArrowRightBlue.png';
export { default as SomethingWentWrongImage } from './SomethingWentWrongImage.png';
export { default as Costco } from './Costco.png';
export { default as envIcon } from './env.png';
export { default as noteIcon } from './note.png';
export { default as heartIcon } from './heart.png';
export { default as BrowserChrome } from './BrowserChrome.png';
export { default as BrowserSafari } from './BrowserSafari.png';
export { default as BrowserEdge } from './BrowserEdge.png';
export { default as BrowserFirefox } from './BrowserFirefox.png';
export { default as GreenAlert } from './GreenAlert.png';
export { default as GreenCheckMark } from './GreenCheckMark.png';
export { default as RedCheckMark } from './RedCheckMark.png';
export { default as download } from './download.png';
export { default as BackPlay } from './BackPlay.svg';
export { default as ForwardPlay } from './ForwardPlay.svg';
export { default as PinRiverIcon } from './PinRiver.svg';
export const checkboxIcons = { CheckboxOn, CheckboxOff, CheckboxLocked };
export const accountIcons = {
  Camera,
  Message,
  MessageGreen,
  Question,
  AddItem,
  SkipIcon,
  ManageIcon,
  Calendar,
  Invoice,
  Bank,
  Card,
  CreditCard,
  Person,
  Autopay,
  Droplet,
  Cog,
  Not,
  Plus,
  BottlePickup,
  EquipmentService,
  GreenCamera,
  InvoiceGreen,
  AutopayGreen,
  Prepared,
  InProgress,
  InQueue,
  Deliver,
  Finger,
  WrenchIcon,
  ArrowBlack,
  LocationIcon,
  EasyEditPlan,
  PredictiveOrder,
  Replenish,
  LargeBottleCircle,
  LargeBottleCircleNot,
};
export const cartIcons = { OneTimeIcon, Recurring };
export const orderProductIcons = { OneTimeIcon, Recurring };

export const getImage = (object, path, path2) => {
  let imageSrc = get(object, path);
  if (!imageSrc && path2) {
    imageSrc = get(object, path2);
  }
  if (!imageSrc) {
    imageSrc = ComingSoon;
  }
  return imageSrc;
};
