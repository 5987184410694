import React from 'react';
import {
  makeStyles,
  StepConnector as MUIStepConnector,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  alternativeLabel: {
    top: '25px',
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  disabled: {
    '& $line': {
      borderColor: '#eaeaf0',
    },
  },
  line: {
    borderTopWidth: 2,
    borderRadius: 1,
    borderColor: theme.colors.river,
  },
}));

const StepConnector = () => {
  const classes = useStyles();

  return (
    <MUIStepConnector
      alternativeLabel
      classes={{
        alternativeLabel: classes.alternativeLabel,
        active: classes.active,
        completed: classes.completed,
        disabled: classes.disabled,
        line: classes.line,
      }}
    />
  );
};

export default StepConnector;
