import { Button, Icon, Popper, H3, H2, Div, Span } from 'components/Ui';
import { InputAdornment, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import {
  selectBestDeliveryDates,
  selectCalendarDates,
  selectIsFetchingCalendarDates,
  selectNextDelivery,
  selectSelectedServiceLocation,
  selectServiceLocationIsFetchingOrder,
} from 'containers/PrimoAccount/selectors';

import Calendar from 'components/Calendar';
import Loader from 'components/Loader';
import MessageDialog from 'components/Dialogs/MessageDialog';
import { ProductListItem } from 'components/ContentfulFields/ProductCard';
import calendarStyles from 'components/Calendar/styles.scss';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loadCalendarDates as loadCalendarDatesRequest } from 'containers/PrimoAccount/actions';
import moment from 'moment';
import { dataLayerPush } from 'utils/tracking';
import { translateData } from 'utils/translation';
import edStyles from './extra-delivery-styles.scss';

const getIdsFromProducts = (products) => products.map(({ itemId }) => itemId);

export const getRemovedProducts = (clean, dirty) => {
  const cleanIds = getIdsFromProducts(clean);
  const dirtyIds = getIdsFromProducts(dirty);

  const removedIds = cleanIds.filter((x) => !dirtyIds.includes(x));
  let removedProducts = [];

  if (removedIds.length) {
    removedProducts = removedIds.map((itemId) =>
      clean.find(({ itemId: itemIdB }) => itemId === itemIdB),
    );
  }

  return removedProducts.length ? removedProducts : [];
};

const mapItemNumberToId = (products) =>
  [...products].map((product) => {
    const newProduct = {
      ...product,
      id: product.itemNumber,
      variant: product.size,
    };
    delete newProduct.image;
    delete newProduct.images;
    return newProduct;
  });

export const getRemovedProductsForDataLayer = (clean, dirty) =>
  mapItemNumberToId(getRemovedProducts(clean, dirty));
export const getQuantityChangedProductsForDataLayer = (clean, dirty) =>
  mapItemNumberToId(getChangedQuantityProducts(clean, dirty));

export const getProductsWithChangedField = (field, clean, dirty) => {
  const products = dirty.filter(({ itemId, [field]: fieldValue }) => {
    const foundMapping = clean.find(
      ({ itemId: itemIdB }) => itemId === itemIdB,
    );
    return foundMapping ? foundMapping[field] !== fieldValue : false;
  });

  return products.length ? mapItemNumberToId(products) : [];
};

export const getChangedQuantityProducts = (clean, dirty) =>
  getProductsWithChangedField('quantity', clean, dirty).map((product) => {
    const cleanProduct = clean.find(
      (foundCleanProduct) =>
        foundCleanProduct.itemNumber === product.itemNumber,
    );
    product['net-change'] = product.quantity - cleanProduct.quantity;
    return product;
  });

const ExtraDeliveryForm = ({
  order,
  onClose,
  onSubmit,
  editable,
  isFetching,
  isFetchingOrder,
  bestDeliveryDates,
  nextDelivery,
}) => {
  const [editedOrder, changeEditedOrder] = useState(order);
  const [selectedDate, changeSelectedDate] = useState(null);

  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const cannotDeleteTitle = 'Oops!';
  const cannotDeleteMessage =
    'Looks like you’re trying to remove all the items from your delivery order.';

  const ref = React.createRef();

  const dayRenderer = (date, isSelected, dateProps, modifiers) => {
    const goodDeliveryDate =
      bestDeliveryDates.indexOf(date.format('YYYY-MM-DD')) > -1;

    const recurringDeliveryDate = moment(nextDelivery.date).isSame(date, 'day');

    const dayClasses = {
      [edStyles.suggestedDay]: goodDeliveryDate,
      [edStyles.recurringDay]: recurringDeliveryDate,
    };

    if (isSelected) {
      return (
        <span className={calendarStyles.selectedRound}>{date.format('D')}</span>
      );
    }

    if (modifiers.outsideRange) {
      return (
        <span className={calendarStyles.fakeRound}>{date.format('D')}</span>
      );
    }

    return (
      <span
        className={classnames({
          [edStyles.day]: true,
          ...dayClasses,
        })}
      >
        {date.format('D')}
      </span>
    );
  };

  const onDayChange = (selectedDay, modifiers) => {
    if (
      !Object.keys(modifiers).find((classes) => classes.includes('disabled'))
    ) {
      changeSelectedDate(selectedDay);
    }
  };

  const onDelete = (deletedProduct) => {
    if (editedOrder.length === 1) {
      setOpenMessageDialog(true);
    } else {
      changeEditedOrder(
        editedOrder.filter(
          (product) => product.itemNumber !== deletedProduct.itemNumber,
        ),
      );
    }
  };

  const handleQuantityChange = (product, newCount) => {
    const newOrder = editedOrder.map((orderedProduct) => {
      if (orderedProduct.itemNumber === product.itemNumber) {
        return { ...orderedProduct, quantity: newCount };
      }
      return orderedProduct;
    });
    changeEditedOrder(newOrder);
  };

  const handleSubmit = () => {
    onSubmit(editedOrder, moment(selectedDate).format('YYYY-MM-DD'));
    const removedProducts = getRemovedProductsForDataLayer(order, editedOrder);

    const changedQuantityProducts = getQuantityChangedProductsForDataLayer(
      order,
      editedOrder,
    );

    if (removedProducts.length) {
      dataLayerPush(
        'SelfServe',
        {
          section: 'add extra delivery',
          event: 'delete',
          products: removedProducts,
        },
        'dlS28',
      );
    }

    if (changedQuantityProducts.length) {
      dataLayerPush(
        'SelfServe',
        {
          section: 'add extra delivery',
          event: 'quantity-change',
          products: changedQuantityProducts,
        },
        'dlS29',
      );
    }

    dataLayerPush(
      'SelfServe',
      {
        section: 'add extra delivery',
        event: 'add-extra-delivery',
        date: moment(selectedDate).format('YYYY-MM-DD'),
      },
      'dlS30',
    );
  };

  const tooltipButton = (
    <TextField
      ref={ref}
      className={edStyles.inputFieldWithIcon}
      id="outlined-adornment-password"
      type="text"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Icon icon="calendar" className={edStyles.calendarIcon} />
          </InputAdornment>
        ),
      }}
      value={
        selectedDate
          ? moment(selectedDate).format('YYYY-MM-DD')
          : translateData('Select Date')
      }
      disabled
    />
  );

  return (
    <Loader loading={isFetchingOrder}>
      <div className={edStyles.formWrapper}>
        <div className={edStyles.editForm}>
          <div className={edStyles.formHeader}>
            <Icon icon="shopping-cart" />
            <H2>Sometimes you just need more</H2>
            <Span>Add products to your extra delivery.</Span>
          </div>

          <div className={edStyles.formBody}>
            {editedOrder &&
              editedOrder.map((product) => (
                <ProductListItem
                  onQuantityChange={editable ? handleQuantityChange : null}
                  onDelete={product.removable ? onDelete : null}
                  key={product.itemNumber}
                  product={product}
                  inDrawer
                />
              ))}
          </div>
        </div>
        <div className={edStyles.formBottom}>
          <div className={edStyles.additionalActions}>
            <H3>When would you like this delivery?</H3>
            <Popper
              className={edStyles.calendarPopper}
              arrowClassName={edStyles.popperArrow}
              button={tooltipButton}
              placement="top-end"
              modifiers={{
                flip: {
                  enabled: false,
                },
              }}
            >
              <div className={edStyles.calendarContainer}>
                <Calendar
                  calendarDates={selectedDate}
                  selectedDay={selectedDate}
                  value={selectedDate}
                  className={edStyles.calendarWrapper}
                  onDayChange={onDayChange}
                  loading={isFetching}
                  monthsNumber={1}
                  disabledDays={(date) =>
                    !bestDeliveryDates.some((goodDate) =>
                      moment(date).isSame(goodDate, 'day'),
                    )
                  }
                  useMobileNavbar={false}
                  dayRenderer={dayRenderer}
                />
              </div>
              <div className={edStyles.horizontalRule} />
              <div className={edStyles.legend}>
                <Div className={edStyles.legendLabel}>Key:</Div>
                <div className={edStyles.suggestedDate}>
                  <div className={`${edStyles.day} ${edStyles.suggestedDay}`} />
                  <Div className={edStyles.legendText}>Suggested Date</Div>
                </div>
                <div className={edStyles.recurringDeliveryDate}>
                  <div className={`${edStyles.day} ${edStyles.recurringDay}`} />
                  <Div className={edStyles.legendText}>
                    Recurring Delivery Date
                  </Div>
                </div>
              </div>
            </Popper>
          </div>
          <div className={edStyles.formActions}>
            <Button
              className={edStyles.cancelButton}
              text="Cancel"
              onClick={onClose}
            />
            <Button
              className={edStyles.saveButton}
              text="Add delivery"
              onClick={handleSubmit}
              disabled={isFetching || !selectedDate || editedOrder.length === 0}
            />
          </div>
        </div>
        {openMessageDialog && (
          <MessageDialog
            open={openMessageDialog}
            onClose={() => setOpenMessageDialog(false)}
            title={cannotDeleteTitle}
            message={cannotDeleteMessage}
          />
        )}
      </div>
    </Loader>
  );
};

const mapStateToProps = createStructuredSelector({
  calendarDates: selectCalendarDates(),
  bestDeliveryDates: selectBestDeliveryDates(),
  serviceLocation: selectSelectedServiceLocation(),
  isFetching: selectIsFetchingCalendarDates(),
  isFetchingOrder: selectServiceLocationIsFetchingOrder(),
  nextDelivery: selectNextDelivery(),
});

const mapDispatchToProps = {
  loadCalendarDates: loadCalendarDatesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraDeliveryForm);
