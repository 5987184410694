import { getDomainByHost, isAcquisitionDomain } from 'utils/domainHelper';
import { isBrowser } from 'utils/ssrHelper';
import qs from 'qs';
import { updatePostalCode } from 'utils/common';
export const USER_REGION_KEY = 'userRegion';

export const FETCH_USER_REGION = `${USER_REGION_KEY}/FETCH_USER_REGION`;
export const UPDATE_USER_REGION = `${USER_REGION_KEY}/UPDATE_USER_REGION`;
const parseSearch = (search) => qs.parse(search, { ignoreQueryPrefix: true });

export const getDefaultUserRegion = (brandParam) => {
  const brand = brandParam || getDomainByHost(24).brand;
  let postalCodeValue = '';
  if (isBrowser() && isAcquisitionDomain()) {
    const search = parseSearch(window.location.search);
    const { postalCode } = search;
    postalCodeValue = postalCode;
    if (postalCode) {
      updatePostalCode(postalCode);
    }

    const confirmedUserRegion = JSON.parse(
      localStorage.getItem('confirmedUserRegion'),
    );

    if (confirmedUserRegion) {
      const result = {
        brand: confirmedUserRegion.brand,
        branch: confirmedUserRegion.branch,
        postalCode: postalCodeValue || confirmedUserRegion.postalCode,
      };
      localStorage.setItem('confirmedUserRegion', JSON.stringify(result));
      return result;
    }
  }

  let branch = '';
  let postalCode = '';

  switch (brand) {
    case 'sparkletts':
      branch = 'LAP';
      postalCode = '90041';
      break;
    case 'crystal-springs':
      branch = 'ATL';
      postalCode = '30126';
      break;
    case 'crystalrock':
      branch = 'STM';
      postalCode = '06902';
      break;
    case 'alhambrawater':
      branch = 'SAF';
      postalCode = '94124';
      break;
    case 'labradorsource':
      branch = 'ANJ';
      postalCode = 'H1J 3C4';
      break;
    case 'canadiansprings':
      branch = 'MIS';
      postalCode = 'L4T 1S9';
      break;
    case 'deeprockwater':
      branch = 'DNV';
      postalCode = '80205';
      break;
    case 'hinckleysprings':
      branch = 'CHG';
      postalCode = '60638';
      break;
    case 'kentwoodsprings':
      branch = 'KNO';
      postalCode = '70113';
      break;
    case 'mountolympuswater':
      branch = 'SLC';
      postalCode = '84104';
      break;
    case 'sierrasprings':
      branch = 'PTL';
      postalCode = '97230';
      break;
    default:
      break;
  }
  return { brand, branch, postalCode: postalCodeValue || postalCode };
};

const { brand, branch, postalCode } = getDefaultUserRegion();

export const DEFAULT_USER_REGION = {
  postalCode,
  brand,
  branch,
};
