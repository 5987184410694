import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { translateData, getToUrl } from 'utils/translation';
import styles from './styles.scss';

const CategoryFullWidthBanner = ({ location }) => {
  const classname = classNames(styles.collapseFullWidthBanner);
  const shopAllLink = '/bottled-water-and-coffee';

  return (
    <Grid container className={classname}>
      <Button className={classNames(styles.fullWidthButton)}>
        <Link
          className={classNames(styles.link, {
            [styles.active]: location.pathname === shopAllLink,
          })}
          to={getToUrl(shopAllLink, 'U2')}
        >
          {translateData('Shop All')}
        </Link>
      </Button>
    </Grid>
  );
};

CategoryFullWidthBanner.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(CategoryFullWidthBanner);
