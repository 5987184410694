/* Brands */
import camelCase from 'lodash/camelCase';
import { getRegionalBrandName } from 'utils/domainHelper';
import { BRANDS } from './const';

export * from './const';

export const getBrandTag = (brand) => camelCase(brand);

export const getBrandIdByLegacyId = (legacyId) =>
  Object.keys(BRANDS).find((key) => BRANDS[key].legacyId === legacyId);

/** ** ** **
 * START DEPRECATED
 ** ** ** */

export const getBrandTagsDEPREC = (brand) => {
  brand = brand || getRegionalBrandName();
  return BRANDS[brand]?.tags || [];
};

/** ** ** **
 * END DEPRECATED
 * The code above is deprecated and should no longer be used.
 * Its dependencies rely on the hostname to determine the brand.
 *
 * The code below is the new implementation.
 * Brand must be known, and is required to be given, at invocation.
 ** ** ** */

export const getBrandTags = (brand) => {
  // if not brand, loudly protest, lest we return to our previous mess
  if (!brand) throw new Error('getBrandTags: no brand given');
  return BRANDS[brand]?.tags || [];
};
