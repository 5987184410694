import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { navigateCostcoRoute, setHomeOffice } from 'containers/Landing/actions';

const BridgeCostco = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search),
      );

      if ((!params.campaignId || !params.elementId) && document.referrer) {
        const referrer = new URL(document.referrer);
        params.campaignId = referrer.searchParams.get('campaign-id');
        params.elementId = referrer.searchParams.get('element-id');
        params.referralCode = referrer.searchParams.get('referral-code');
      }

      const { locationType, memberNum } = params;

      // input (params) sanity check & escape hatch
      if (!memberNum) throw new Error('Specific URL params are required.');

      const costcoAcct = {
        ...params,
        validExecutive: JSON.parse(params.validExecutive),
        validNonExecutive: JSON.parse(params.validNonExecutive),
        organizationId: JSON.parse(params.organizationId),
      };

      dispatch(setHomeOffice(locationType));
      dispatch(navigateCostcoRoute(costcoAcct));
    } catch (err) {
      console.error(err, 'Redirecting to /.'); // eslint-disable-line
      window.location.replace('/');
    }
  }, []);

  return <div>Redirecting...</div>;
};

export default BridgeCostco;
