import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import get from 'lodash/get';
import { getMiddleOfTheWindow } from 'utils/common';
import { isSsr } from 'utils/ssrHelper';
import styles from './styles.scss';
class SprawlingImage extends Component {
  constructor(props) {
    super(props);
    const isServersideRendering = isSsr();
    this.sprawlingBlock = createRef();
    this.state = {
      middleWindow: getMiddleOfTheWindow(),
      isVisible: false,
      isServersideRendering,
      scroll: !isServersideRendering && window.scrollY,
      offset: 0,
    };
  }

  componentDidMount() {
    if (!this.state.isServersideRendering) {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
    this.offsetLeft = this.sprawlingBlock.current.offsetParent.offsetLeft;
  }

  componentWillUnmount() {
    if (!this.state.isServersideRendering) {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  onVisibleChange = (isVisible) => {
    this.setState({ isVisible });
  };

  handleResize = () => {
    this.setState({
      middleWindow: getMiddleOfTheWindow(),
    });
  };

  getOffset = () => {
    const { middleWindow } = this.state;
    const { offsetLeft } = this.sprawlingBlock.current.offsetParent;

    if (middleWindow < offsetLeft) {
      return offsetLeft / middleWindow;
    }
    return middleWindow / offsetLeft;
  };

  handleScroll = () => {
    const {
      document: {
        fields: { offset: customOffset },
      },
    } = this.props;

    const { isVisible } = this.state;

    this.setState((prevState) => {
      const scrollY = !prevState.isServersideRendering ? window.scrollY : 0;

      const direction = prevState.scroll < scrollY ? 'down' : 'up';

      let { offset } = prevState;
      if (direction === 'down') {
        offset += customOffset;
      } else {
        offset -= customOffset;
      }

      return {
        scroll: scrollY,
        translateSign:
          prevState.middleWindow >
          this.sprawlingBlock.current.offsetParent.offsetLeft
            ? '-'
            : '+',
        offset: offset < 0 || !isVisible ? 0 : offset,
      };
    });
  };

  render() {
    const {
      document: {
        fields: { direction, majorImage },
      },
    } = this.props;

    const { isVisible, offset } = this.state;

    const file = get(majorImage, 'fields.file.url');
    const alt = get(majorImage, 'fields.file.title');

    const translateSign = {
      left: '-',
      right: '+',
    };

    return (
      <VisibilitySensor partialVisibility onChange={this.onVisibleChange}>
        <div
          style={{
            transform:
              isVisible && `translateX(${translateSign[direction]}${offset}px)`,
          }}
          ref={this.sprawlingBlock}
          className={styles.sprawlingWrap}
        >
          <img className={styles.sprawling} src={`${file}?fm=webp`} alt={alt} />
        </div>
      </VisibilitySensor>
    );
  }
}

SprawlingImage.propTypes = {
  document: PropTypes.object.isRequired,
};

export default SprawlingImage;
