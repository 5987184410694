import React from 'react';
import classnames from 'classnames';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { withTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import get from 'lodash/get';
import range from 'lodash/range';

import { ProductGridItem } from 'components/ContentfulFields/ProductCard';
import Button from 'components/Ui/Button';
import { translateData } from 'utils/translation';
import { arrowBackward, arrowForward } from 'images';
import * as PropTypes from 'prop-types';
import styles from './styles.scss';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.swiper = {};
  }

  componentDidUpdate() {
    if (this.swiper && this.swiper.resize) {
      // When changing Delivery Address location,
      // Swiper size needs to recalculate in order to render correctly
      this.swiper.resize.resizeHandler();
    }
  }

  goNext = () => {
    if (this.swiper !== null) {
      this.swiper.slideNext();
    }
  };

  goPrev = () => {
    if (this.swiper !== null) {
      this.swiper.slidePrev();
    }
  };

  goto = (i) => {
    if (this.swiper) {
      this.swiper.slideTo(i);
    }
  };

  render() {
    const { products, theme, section } = this.props;

    const options = {
      slidesPerGroup: 3,
      slidesPerView: 3,
      spaceBetween: 20,
      threshold: 20,
      breakpoints: {
        [theme.breakpoints.values.md]: {
          slidesPerView: 1.5,
          slidesPerGroup: 1,
        },
      },
    };

    const visibleStartIndex = 1;
    const visibleEndIndex = 1;

    const showNavigation =
      get(this.sider, 'currentBreakpoint', 'max') === 'max';
    return (
      <div className={styles.slider}>
        {!!products.length && (
          <Swiper
            {...options}
            ref={(node) => {
              if (node) this.swiper = node.swiper;
            }}
          >
            {products.map((product, index) => (
              <div className={styles.slide} key={product.id}>
                <ProductGridItem
                  className={styles.productCard}
                  product={product}
                  displayIndex={index}
                  section={section}
                />
              </div>
            ))}
          </Swiper>
        )}
        {!products.length && (
          <div className={styles.placeholders}>
            <Skeleton className={styles.placeholder} animation="pulse" />
            <Skeleton className={styles.placeholder} animation="pulse" />
            <Skeleton className={styles.placeholder} animation="pulse" />
          </div>
        )}
        {showNavigation && (
          <div className={styles.navigation}>
            <Button
              text="Backward"
              ariaLabel="go backward one slide"
              disabled={false}
              className={classnames(styles.navButton, {
                [styles.navButtonDisabled]: false,
              })}
              icon={<img src={arrowBackward} alt="" />}
              iconPosition="left"
              link
              onClick={this.goPrev}
            />
            <div className={styles.dots}>
              {range(0, Math.ceil(products.length / 3)).map((i) => (
                <button
                  key={i}
                  aria-label={`${translateData('Go to slide')} ${i + 1}`}
                  type="button"
                  className={classnames(styles.dot, {
                    [styles.dotActive]:
                      i * 3 >= visibleStartIndex && i * 3 <= visibleEndIndex,
                  })}
                  onClick={() => this.goto(i * options.slidesPerGroup)}
                />
              ))}
            </div>

            <Button
              text="Forward"
              ariaLabel="go forward one slide"
              disabled={false}
              className={classnames(styles.navButton, {
                [styles.navButtonDisabled]: false,
              })}
              icon={<img src={arrowForward} alt="" />}
              iconPosition="left"
              link
              onClick={this.goNext}
            />
          </div>
        )}
      </div>
    );
  }
}

Slider.propTypes = { products: PropTypes.any };

export default withTheme(Slider);
