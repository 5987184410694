import { createMuiTheme } from '@material-ui/core';
import { themeConfig } from './themeConfig';
import { themeOverrides } from './themeOverrides';
import { isWaterRegionalTheme, isWaterTheme } from '../utils/common';
import store from '../utils/store';

import { selectStylesTheme } from '../containers/Landing/selectors';

const stylesTheme = selectStylesTheme()(store.getState());

const theme = createMuiTheme({
  ...themeConfig,
  ...themeOverrides,
  themeName: stylesTheme,
  typography: {
    fontFamily:
      isWaterTheme(stylesTheme) || isWaterRegionalTheme(stylesTheme)
        ? themeConfig.fonts.sansPro
        : themeConfig.fonts.benton,
  },
});

export default theme;
