import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Wrapper } from 'components/Ui';
import LoginPage from 'containers/Authentication/Login/Pureflo';
import SignupPage from 'containers/Authentication/Signup/Pureflo';
import styles from 'containers/Authentication/styles.scss';

const SignInForm = ({ redirectUrl }) => (
  <Wrapper color="grey">
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={6}>
        <LoginPage className={styles.signinForm} redirectUrl={redirectUrl} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <SignupPage className={styles.signinForm} redirectUrl={redirectUrl} />
      </Grid>
    </Grid>
  </Wrapper>
);

SignInForm.propTypes = {
  redirectUrl: PropTypes.string,
};

export default SignInForm;
