import PropTypes from 'prop-types';

export const THEMES = {
  PUREFLO: 'pureflo',
  WATER: 'water',
  WATER_REGIONAL: 'water-regional',
  REGIONAL_WATER: 'regional-water',
};

export const PurefloTheme = ({ children }, { stylesTheme }) => {
  if (stylesTheme === THEMES.PUREFLO) {
    return children;
  }

  return null;
};

export const RegionalWaterTheme = ({ children }, { stylesTheme }) => {
  if (stylesTheme === THEMES.REGIONAL_WATER) {
    return children;
  }

  return null;
};

export const WaterRegionalTheme = ({ children }, { stylesTheme }) => {
  if (stylesTheme === THEMES.WATER_REGIONAL) {
    return children;
  }

  return null;
};

export const WaterTheme = ({ children }, { stylesTheme }) => {
  if (stylesTheme === THEMES.WATER) {
    return children;
  }

  return null;
};

PurefloTheme.contextTypes = {
  stylesTheme: PropTypes.string,
};

WaterRegionalTheme.contextTypes = {
  stylesTheme: PropTypes.string,
};

RegionalWaterTheme.contextTypes = {
  stylesTheme: PropTypes.string,
};

WaterTheme.contextTypes = {
  stylesTheme: PropTypes.string,
};
