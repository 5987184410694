import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, set, map, startsWith } from 'lodash';
import { push } from 'connected-react-router';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
// import { selectSiteName } from 'containers/Landing/selectors';
// import { domainMap, COSTCO_WATER_SLUG } from 'containers/Landing/constants';
import {
  Grid,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import { Button, Radio, Dialog, H1, H2, P, Div, Span } from 'components/Ui';
import Calendar from 'components/Calendar';
import { useIsMobile } from 'utils/hooks';
import { translateData, getToUrl } from 'utils/translation';
import { INFORMATION_PAGE } from 'containers/PrimoCheckoutPage/constants';
import commonStyles from 'containers/PrimoCheckoutPage/styles.scss';
import {
  selectDeliverySetupData,
  selectCartData,
} from 'containers/Cart/selectors';
import { saveDeliveySetup } from 'containers/CheckoutPage/actions';
import {
  getCommonDisabledDeliveryDays,
  getNextPossibleDeliveryDay,
} from 'utils/dss/deliveryDates';
import { dataLayerPush } from 'utils/tracking';

import { InfoIcon } from 'images';

import { isSsr } from 'utils/ssrHelper';
import Legend from './Legend';
import calendarStyles from './calendarStyles.scss';
import styles from './styles.scss';

const ScreenReader = styled.p`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const RadioGroupLabel = styled(FormLabel)`
  padding-top: 40px !important;
  border-top: 1px solid #c7c8ca;
  font-weight: 600 !important;
  margin-bottom: 20px;
  color: #000000 !important;
`;

const waterDeliveryScheduleContent = {
  title: 'Water delivery schedule',
  setup: {
    header: 'Setting up bottled water delivery',
    text:
      "To sign up for bottled water delivery service, start by selecting the date of your initial set-up. Your Route Sales Representative will come to install your equipment (if you're receiving any). During this visit, you'll also have the opportunity to choose a designated spot for pick-up of empty bottles and drop-off of full water bottles. Now you're ready to go!",
  },
  schedule: {
    header: 'Getting on your schedule',
    text:
      "After your initial set-up, you'll get delivery every 2 or 4 weeks depending on your area's schedule. You don't need to be home, just let us know how many bottles you'd like each delivery in your online account and leave empty bottles in a designated area. Plus, you can always edit your delivery instructions anytime once you've signed up for your online account.",
  },
};

const Day = ({ date, dateProps, isSelected, modifiers }) => {
  const isDisabled =
    Object.keys(modifiers).findIndex(
      (modifier) =>
        startsWith(modifier, 'styles_disabled') ||
        startsWith(modifier, 'styles_outside'),
    ) !== -1;
  const formattedDate = moment(date).format('dddd, MMMM Do YYYY');
  let label;
  if (isSelected) {
    label = `${translateData('Currently selected date:')} ${formattedDate}`;
  } else if (dateProps && dateProps.isPreferredDay) {
    label = `${translateData(
      'Suggested and Selectable Day:',
    )} ${formattedDate}`;
  } else if (isDisabled) {
    label = `${translateData('Disabled Day:')} ${formattedDate} ${translateData(
      'is unselectable.',
    )}`;
  } else {
    label = `${translateData('Selectable Day:')} ${formattedDate}`;
  }

  return (
    <button
      className={classnames(calendarStyles.day, {
        [calendarStyles.selected]: isSelected,
        [calendarStyles.recurring]: dateProps && dateProps.isRecurringDelivery,
        ...modifiers,
      })}
      type="button"
      aria-label={label}
    >
      {moment(date).format('D')}
      {dateProps && dateProps.isPreferredDay && (
        <div className={calendarStyles.preferredDayBadge} />
      )}
      {dateProps && dateProps.isRecurringDelivery && (
        <div className={calendarStyles.recurringDeliveryBadge} />
      )}
    </button>
  );
};

Day.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateProps: PropTypes.object,
  isSelected: PropTypes.bool,
  modifiers: PropTypes.object,
};

const DeliverySetup = ({ changeCanGoForward, changeSubmitAction }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [
    showWaterDeliveryScheduleModal,
    setShowWaterDeliveryScheduleModal,
  ] = useState(false);

  const deliverySetupData = useSelector(selectDeliverySetupData());

  const [deliveryDate, changeDeliveryDate] = useState(
    deliverySetupData.deliveryDate,
  );
  const [deliveryTime, changeDeliveryTime] = useState(
    deliverySetupData.deliveryTime,
  );

  const cartData = useSelector(selectCartData());

  const deliveryDates = get(cartData, 'serviceDates.deliveryDates') || [];
  const servicesAddresses = get(cartData, 'servicesAddresses') || [];
  const holidayDates = map(
    get(cartData, 'serviceDates.holidayDates') || [],
    (h) => moment(h.date).toDate(),
  );

  const recurringDeliveryDates = (
    get(servicesAddresses, '0.recurringDeliveryDates') || []
  ).map((d) => d.date);
  const futureRecurringDeliveryDates = deliveryDate
    ? recurringDeliveryDates.filter((date) =>
        moment(date).isAfter(moment(deliveryDate)),
      )
    : [];

  const calendarDates = {};

  futureRecurringDeliveryDates.forEach((date) => {
    set(
      calendarDates,
      [
        moment(date).format('YYYY-MM'),
        moment(date).format('DD'),
        'isRecurringDelivery',
      ],
      true,
    );
  });

  deliveryDates.forEach((date) => {
    set(
      calendarDates,
      [
        moment(date).format('YYYY-MM'),
        moment(date).format('DD'),
        'isPreferredDay',
      ],
      true,
    );
  });

  const initialMonth = getNextPossibleDeliveryDay();
  const isServersideRendering = isSsr();

  // let siteName = useSelector(selectSiteName());
  // if (!isServersideRendering && window.isCostcoWater) {
  // const waterBrand = `costcowater-${get(window, 'brandByZip', '').replace(
  //   /-/g,
  //   '',
  // )}`;
  // const domain = domainMap[waterBrand]
  //   ? domainMap[waterBrand]
  //   : domainMap[COSTCO_WATER_SLUG];

  // siteName = domain.name;
  // }

  const isFilled = deliveryDate && deliveryTime;

  const onDayChange = (selectedDay, modifiers) => {
    if (
      !Object.keys(modifiers).find((classes) =>
        classes.includes(calendarStyles.disabled),
      )
    ) {
      changeDeliveryDate(moment(selectedDay).format('YYYY-MM-DD'));
    }
  };

  const handleSubmit = () => {
    dispatch(saveDeliveySetup({ deliveryDate, deliveryTime }));
  };

  const handleBack = () =>
    dispatch(push(getToUrl(`/checkout/${INFORMATION_PAGE}`, 'U49')));

  useEffect(() => {
    dataLayerPush(
      'Acquisition',
      {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 2 },
          },
        },
      },
      'dlA17',
    );
  }, []);

  useEffect(() => {
    changeSubmitAction(() => () => handleSubmit());
  }, []);

  useEffect(() => {
    changeCanGoForward(isFilled);
  }, [isFilled]);

  return (
    <Fragment>
      <Grid
        container
        spacing={isMobile ? 1 : 4}
        classes={{ root: styles.mainContent }}
      >
        <Grid item md={12} lg={6} classes={{ root: styles.calendarContainer }}>
          <Div className={classnames(commonStyles.title, styles.calendarTitle)}>
            Select your preferred delivery date:
          </Div>
          <div>
            <ScreenReader>
              <Span>
                Delivery date calendar container. Suggested dates in the
                calendar mean that we are already in your neighborhood! Choose
                one of these dates to help conserve resources and reduce
                emissions. Hit tab to enter the calendar to choose a delivery
                date.
              </Span>
            </ScreenReader>
            <ScreenReader></ScreenReader>
            <Calendar
              monthsNumber={1}
              initialMonth={initialMonth}
              narrowView
              disableForwardAfter={
                !isServersideRendering && window.isCostcoWater ? 2 : 2
              } // isCostcoWater is 2 months, not sure if others is different
              onDayChange={onDayChange}
              selectedDay={deliveryDate}
              disabledDays={[
                ...getCommonDisabledDeliveryDays(),
                ...holidayDates,
              ]}
              calendarDates={calendarDates}
              dayRenderer={(date, isSelected, dateProps, modifiers) => (
                <Day
                  date={date}
                  dateProps={dateProps}
                  isSelected={isSelected}
                  modifiers={modifiers}
                />
              )}
              classNames={{
                outside: calendarStyles.outside,
                disabled: calendarStyles.disabled,
              }}
            />
          </div>
        </Grid>
        <Grid item md={12} lg={6}>
          <Legend highlight="suggested" showRecurring={!!deliveryDate} />
          <FormControl component="fieldset">
            <RadioGroupLabel id="delivery-time-pref">
              <Span>Select your preferred first delivery time:</Span>
            </RadioGroupLabel>
            <RadioGroup
              className={styles.radioGroup}
              value={deliveryTime}
              onChange={(event) => changeDeliveryTime(event.target.value)}
              name="deliveryTime"
              aria-labelledby="delivery-time-pref"
              aria-label={translateData('Delivery Time')}
            >
              <FormControlLabel
                value="morning"
                control={<Radio />}
                label={translateData(
                  'Morning (typically between 8am-12pm local)',
                )}
              />
              <FormControlLabel
                value="evening"
                control={<Radio />}
                label={translateData(
                  'Afternoon (typically between 12pm-6pm local)',
                )}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <div className={styles.deliveryNote}>
        <Span>
          {
            'Please note: Your preferred date and time window will be used to schedule your delivery.'
          }
        </Span>{' '}
        <Span>
          {
            'Your {brand} Route Sales Representative will use your preferences as a guideline; however, we cannot guarantee delivery within the preferred date and time.'
          }
        </Span>{' '}
        <Button
          link
          onClick={() => setShowWaterDeliveryScheduleModal(true)}
          text="Water Delivery Schedules"
          className={styles.dialogLink}
        />
        <Dialog
          open={showWaterDeliveryScheduleModal}
          onClose={() => setShowWaterDeliveryScheduleModal(false)}
          size="small"
          isCloseButton
        >
          <div className={styles.waterDeliverySchedule}>
            <img src={InfoIcon} alt="" />
            <H1>{waterDeliveryScheduleContent.title}</H1>
            <H2>{waterDeliveryScheduleContent.setup.header}</H2>
            <P>{waterDeliveryScheduleContent.setup.text}</P>
            <H2>{waterDeliveryScheduleContent.schedule.header}</H2>
            <P>{waterDeliveryScheduleContent.schedule.text}</P>
          </div>
        </Dialog>
      </div>
      <hr />
      <div className={classnames(commonStyles.actions, styles.actions)}>
        <ScreenReader>
          <Span>
            Form Navigation, Select the Back button to return to the Delivery
            Information form. Select continue when you have selected a deliver
            date and delivery time option to continue to the Payment form.
          </Span>
        </ScreenReader>
        <Button text="Back" color="gray" type="button" onClick={handleBack} />
        <Button
          text="Continue"
          type="button"
          onClick={handleSubmit}
          disabled={!isFilled}
        />
      </div>
    </Fragment>
  );
};

DeliverySetup.propTypes = {
  changeCanGoForward: PropTypes.func.isRequired,
  changeSubmitAction: PropTypes.func.isRequired,
};

export default DeliverySetup;
