import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  PRIMARY_CONTACT_FORM,
  DELIVERY_AND_INSTALLATION_LOCATION_FORM,
} from 'containers/CheckoutPage/constants';
import {
  selectCheckoutLoading,
  selectCheckoutErrors,
  selectCityCountyList,
} from 'containers/CheckoutPage/selectors';
import {
  selectContactData,
  selectDeliveryData,
} from 'containers/Cart/selectors';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { saveLocation, fetchCityCounty } from 'containers/CheckoutPage/actions';

import { isCanadianBrand } from 'utils/translation';
import DeliveryInformationForm from './DeliveryInformationForm';

const DeliveryInformation = ({
  changeCanGoForward,
  changeSubmitAction,
  drawerToggle,
  isDrawer,
}) => {
  const dispatch = useDispatch();
  const form = useRef();

  const isProcessing = useSelector(selectCheckoutLoading());
  const errors = useSelector(selectCheckoutErrors()) || {};
  const contactData = useSelector(selectContactData());
  const deliveryData = useSelector(selectDeliveryData());
  const postalCode = useSelector(selectPostalCode());
  const cityCountyList = useSelector(selectCityCountyList());

  const cityCountyOptions = cityCountyList.map((option) => ({
    label: `${option.city}, ${option.county}, ${option.state}`,
    value: `${option.city}${option.county}`,
    data: option,
  }));

  useEffect(() => {
    changeSubmitAction(() => () => form.current.submit());
  }, []);

  useEffect(() => {
    if (!isCanadianBrand()) {
      dispatch(fetchCityCounty(postalCode));
    }
  }, [postalCode]);

  const handleSubmit = (values) => {
    dispatch(
      saveLocation({
        drawerToggle,
        [PRIMARY_CONTACT_FORM]: {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          email: values.email.trim(),
          phoneNumber: values.phoneNumber.trim(),
          phoneExtension: values.phoneExtension,
          company: values.company.trim(),
        },
        [DELIVERY_AND_INSTALLATION_LOCATION_FORM]: [
          {
            address1: values.address1.trim(),
            address2: values.address2,
            city: values.city.trim(),
            postalCode: values.postalCode.trim(),
            stateOrProvinceCode: values.stateOrProvinceCode.trim(),
            countryCode: values.countryCode.trim(),
            deliveryInstructions: values.deliveryInstructions.trim(),
            customerType: values.customerType.trim(),
            county: values.county.trim(),
            cityCountyState: values.cityCountyState,
            cityCountyStateDisplay: values.cityCountyStateDisplay,
          },
        ],
      }),
    );
  };

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    phoneExtension: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    stateOrProvinceCode: '',
    postalCode,
    customerType: 'residential',
    countryCode: 'US',
    deliveryInstructions: '',
    county: '',
    cityCountyState: '',
    cityCountyStateDisplay: '',
  };
  const defaultCityCountyState =
    cityCountyList.length === 1
      ? {
          city: cityCountyList[0].city,
          county: cityCountyList[0].county,
          stateOrProvinceCode: cityCountyList[0].state,
          cityCountyState: cityCountyOptions[0].value,
          cityCountyStateDisplay: cityCountyOptions[0].label,
        }
      : {};

  const inDefaultValues = {
    ...defaultValues,
    ...defaultCityCountyState,
    ...contactData,
    ...deliveryData,
    postalCode,
  };

  if (!postalCode) return null;

  return (
    <DeliveryInformationForm
      onSubmit={handleSubmit}
      isProcessing={isProcessing}
      defaultValues={inDefaultValues}
      errors={{
        ...errors[PRIMARY_CONTACT_FORM],
        ...errors[DELIVERY_AND_INSTALLATION_LOCATION_FORM],
      }}
      forwardedRef={form}
      changeCanGoForward={changeCanGoForward}
      drawerToggle={drawerToggle}
      isDrawer={isDrawer}
      cityCountyOptions={cityCountyOptions}
    />
  );
};

DeliveryInformation.propTypes = {
  changeCanGoForward: PropTypes.func,
  changeSubmitAction: PropTypes.func.isRequired,
  drawerToggle: PropTypes.func,
  isDrawer: PropTypes.bool,
};

export default DeliveryInformation;
