import React from 'react';
import styled from 'styled-components';
import { primaryBlue } from 'theme/_variables.scss';
import CaretDown from '../CaretDown';

const PrimaryArrowWrapper = styled.div`
  height: 100%;
  width: 40px;
  background-color: ${primaryBlue};
  top: 0 !important;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrimaryArrow = (props) => (
  <PrimaryArrowWrapper {...props}>
    <CaretDown />
  </PrimaryArrowWrapper>
);

export default PrimaryArrow;
