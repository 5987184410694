/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { translateData } from 'utils/translation';

const Img = ({ alt, defaultText, ...rest }) => {
  return <img alt={translateData(alt, defaultText)} {...rest} />;
};

Img.propTypes = {
  alt: PropTypes.string,
  defaultText: PropTypes.string,
};

export default Img;
