import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { pages } from 'containers/Landing/constants';
import primoAccountReducer from 'containers/PrimoAccount/reducer';
import { PRIMO_ACCCOUNT_PAGE_KEY } from 'containers/PrimoAccount/constants';
import primoAccountSaga from 'containers/PrimoAccount/saga';
import { PROFILE_PAGE_KEY } from 'containers/PrimoProfile/constants';
import primoProfileReducer from 'containers/PrimoProfile/reducer';
import primoProfileSaga from 'containers/PrimoProfile/saga';
import {
  selectIsAuthenticated,
  selectLoginFetching,
  selectIsInitiated,
} from 'containers/Authentication/selectors';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { PRODUCTS_KEY } from 'containers/PrimoProducts/constants';
import primoProductsSaga from 'containers/PrimoProducts/saga';
import {
  selectProductsData,
  selectProductsAreFetching,
  selectProductsTotalCount,
  selectProductBrands,
  selectProductWaterTypes,
  selectProductCategories,
} from 'containers/PrimoProducts/selectors';
import {
  selectNextDelivery,
  selectRecurringOrder,
  selectShowOrderChangeMessage,
} from 'containers/PrimoAccount/selectors';
import {
  loadProductBundles,
  loadMerchandizedProducts,
  loadProductCategories,
  hideOrderChangeMessage,
} from 'containers/PrimoProducts/actions';
import { selectCartItems } from 'containers/Cart/selectors';
import { withContent } from 'components/Content';
import withLanding from 'containers/Landing/withLanding';
import ProductListPage from './ProductListPage';

const mapStateToProps = createStructuredSelector({
  products: selectProductsData(true),
  itemsInOrder: selectCartItems(),
  totalCount: selectProductsTotalCount(),
  brands: selectProductBrands(),
  waterTypes: selectProductWaterTypes(),
  productCategories: selectProductCategories(),
  areProductsFetching: selectProductsAreFetching(),
  isAuthenticated: selectIsAuthenticated(),
  isLoginFetching: selectLoginFetching(),
  isInitiated: selectIsInitiated(),
  postalCode: selectPostalCode(),
  nextDelivery: selectNextDelivery(),
  recurringOrder: selectRecurringOrder(),
  showOrderChangeMessage: selectShowOrderChangeMessage(),
});

const mapDispatchToProps = {
  loadProductBundles,
  loadMerchandizedProducts,
  loadProductCategories,
  hideOrderChangeMessage,
};
const CONTENT_KEYS = {
  largebottle: 'Large Bottles',
  waterbundles: 'Water Bundles',
  casepacks: 'Case Packs',
  sparklingwater: 'Sparkling Water',
  coffee: 'Coffee',
  teaandhotchocolate: 'Tea And Hot Chocolate',
  equipment: 'Equipment',
  coffeeteaandhotchocolate: 'Coffee Tea And Hot Chocolate',
  breakroomsupplies: 'Breakroom Supplies',
};
const CONTENT_TYPE_KEYS = {
  banner: 'banner',
  other: 'branded',
};
const withSaga = injectSaga({
  key: PRODUCTS_KEY,
  saga: primoProductsSaga,
});
const withReducer = injectReducer({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  reducer: primoAccountReducer,
});
const withAccountSaga = injectSaga({
  key: PRIMO_ACCCOUNT_PAGE_KEY,
  saga: primoAccountSaga,
});
const withProfileReducer = injectReducer({
  key: PROFILE_PAGE_KEY,
  reducer: primoProfileReducer,
});
const withProfileSaga = injectSaga({
  key: PROFILE_PAGE_KEY,
  saga: primoProfileSaga,
});

const ProductListBase = [
  withContent(CONTENT_KEYS, CONTENT_TYPE_KEYS),
  withRouter,
  withReducer,
  withProfileReducer,
  withAccountSaga,
  withSaga,
  withProfileSaga,
  connect(mapStateToProps, mapDispatchToProps),
];

export const ProductList = compose(
  withLanding(pages.productList),
  ...ProductListBase,
)(ProductListPage);
