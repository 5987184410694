import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { map, isEmpty, get, flatten } from 'lodash';
import { PRODUCTS_PER_PAGE } from 'containers/PrimoProducts/constants';
import { isLoginRedirect } from 'utils/common';
import { dataLayerPush } from 'utils/tracking';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { H1, Button } from 'components/Ui';
import { ProductGridItem } from 'components/ContentfulFields/ProductCard';
import { getToUrl, getCurrencyCode } from 'utils/translation';
import classnames from 'classnames';
import { isContentType } from 'components/Content';
import styles from './styles.scss';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% + 6px)',
      margin: '-3px',
    },
  },
  item: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0 3px !important',
      marginBottom: 6,
    },
  },
}));

const ProductList = ({
  products,
  totalCount,
  isLoading,
  isAuthenticated,
  isProductsInit,
  loadFilteredProducts,
  history,
  isQuickshop,
  isCostco,
  page,
  equipment,
  isQuickShopPage,
  searchKeyword,
}) => {
  const classes = useStyles();

  const [currentProducts, setCurrentProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isMoreProducts, setIsMoreProducts] = useState(false);
  const [focusedProduct, setFocusedProduct] = useState({});

  useEffect(() => {
    if (products && products.length) {
      setCurrentProducts(products);
      if (
        currentProducts &&
        currentProducts[0].itemNumber === products[0].itemNumber
      ) {
        return;
      }
      const list =
        page === 'select-water'
          ? 'Quick Shop Water'
          : page === 'select-equipment'
          ? 'Quick Shop Equipment'
          : products[0].category;
      const viewItemList = {
        currencyCode: getCurrencyCode(),
        event: 'view_item_list',
        ecommerce: {
          impressions: products.flatMap((item, index) => {
            const isBundle = isContentType(item, 'productBundle');
            if (isBundle) {
              return flatten(
                item.fields.items.map((bi) => ({
                  name: item.fields.title,
                  id: bi.fields.product.fields.itemNumber,
                  price: `${
                    bi.fields.product.fields.price * bi.fields.quantity
                  }`,
                  brand: bi.fields.product.fields.brand || '',
                  category: bi.fields.product.fields.category,
                  variant: bi.fields.product.fields.size,
                  list: 'product-bundles',
                  position: index + 1,
                  bundle_name: item.fields.entryTitle,
                  quantity: bi.fields.quantity,
                })),
              );
            }
            return {
              name: item.name,
              id: item.itemNumber,
              price: `${item.price}`,
              brand: item.brand || '',
              category: item.category,
              variant: item.size,
              list,
              position: index + 1,
            };
          }),
        },
      };
      // console.log('dataLayerPush-*', viewItemList);
      dataLayerPush('Acquisition', viewItemList, 'dlA21');
    }
  }, [products]);

  const handleSeeMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleProductItemClick = (product, index) => {
    const productTypeSlug = get(product, 'productType.slug', '');
    const { itemNumber, name, brand, category, size, price } = product;

    if (!isQuickshop) {
      if (isAuthenticated) {
        dataLayerPush('SelfServe', {
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: { list: `Search Results: ${searchKeyword}` },
              products: [
                {
                  id: itemNumber,
                  name,
                  brand: brand || '',
                  category,
                  variant: size,
                  price,
                  position: index + 1,
                },
              ],
            },
          },
          'gtm.uniqueEventId': 2307,
        });
      } else {
        dataLayerPush(
          'Acquisition',
          {
            event: 'productClick',
            ecommerce: {
              click: {
                actionField: { list: category },
                products: [
                  {
                    name,
                    id: itemNumber,
                    price,
                    brand: brand || '',
                    category,
                    variant: size,
                    position: index + 1,
                  },
                ],
              },
            },
          },
          'dlA22',
        );
      }
      history.push(
        isAuthenticated
          ? `/account/products/${product.itemNumber}`
          : getToUrl(`/product/${productTypeSlug}/${product.slug}`),
      );
    }
  };

  useEffect(() => {
    if (currentPage > 1 || !isProductsInit) {
      loadFilteredProducts(currentPage);
    }
  }, [currentPage, isProductsInit]);

  useEffect(() => {
    const isMoreProductsShown = currentPage * PRODUCTS_PER_PAGE < totalCount;
    setIsMoreProducts(isMoreProductsShown);
  }, [currentPage, totalCount]);

  const isEmptyProducts = isEmpty(products) && !isLoading;

  if (isEmptyProducts && isLoginRedirect()) {
    history.push('/account/products/list');
  }

  return (
    <Grid container spacing={2} classes={{ container: classes.container }}>
      {isEmptyProducts && (
        <H1 className={styles.emptyList}>No products found</H1>
      )}
      {map(products, (product, index) => {
        const isBundle = isContentType(product, 'productBundle');
        return (
          <Grid
            key={product.id}
            item
            xs={equipment ? 6 : 12}
            sm={equipment ? 6 : 6}
            md={equipment ? 6 : 6}
            lg={equipment ? 6 : 4}
            classes={{ item: classes.item }}
          >
            <ProductGridItem
              className={classnames(styles.product, {
                [styles.inactiveProduct]: isLoading,
              })}
              product={isBundle ? product.fields : product}
              onClick={() => handleProductItemClick(product, index)}
              isCostco={isCostco}
              page={page}
              isFocused={focusedProduct.id === product.id}
              changeFocusedProduct={setFocusedProduct}
              displayIndex={index}
              section="product list"
              isQuickShopPage={isQuickShopPage}
              isBundle={isBundle}
            />
          </Grid>
        );
      })}
      {isMoreProducts && (
        <Button
          text="See more"
          link
          onClick={handleSeeMore}
          className={styles.seeMore}
        />
      )}
    </Grid>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  isProductsInit: PropTypes.bool,
  loadFilteredProducts: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isQuickshop: PropTypes.bool,
  isCostco: PropTypes.bool,
  page: PropTypes.string,
  equipment: PropTypes.bool,
  isQuickShopPage: PropTypes.bool,
};
ProductList.defaultProps = {
  isProductsInit: false,
};

export default withRouter(ProductList);
