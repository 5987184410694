import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import classnames from 'classnames';

import { get, isEmpty, find, forEach } from 'lodash';

import uuid from 'uuid/v4';

import {
  selectFeaturedProducts,
  selectProductsData,
} from 'containers/PrimoProducts/selectors';
import { selectCartItems } from 'containers/Cart/selectors';
import { loadFeaturedProducts as loadFeaturedProductsAction } from 'containers/PrimoProducts/actions';

import { setTheme } from 'utils/themeHelper';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { translateData, getToUrl } from 'utils/translation';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';

import injectSaga from 'utils/injectSaga';
import { PRODUCTS_KEY } from 'containers/PrimoProducts/constants';
import primoProductsSaga from 'containers/PrimoProducts/saga';

import { GTM_TRACKING_TAGS } from 'utils/constants';
import { H1, H2, H3, Div, Section } from 'components/Ui';
import styles from './styles.scss';
import Slider from './Slider';

const ProductsFeature = ({
  document,
  featuredProducts,
  loadFeaturedProducts,
  productsData,
  cartItems,
}) => {
  const [products, setProducts] = useState([]);
  const isAcquisition = isAcquisitionDomain();

  useEffect(() => {
    if (isAcquisition) {
      const productsDataFiltered = [];
      forEach(productsData, (product) => {
        const match = find(
          cartItems,
          (item) => product.itemNumber === item.itemNumber,
        );
        if (!match) {
          productsDataFiltered.push(product);
        }
      });

      setProducts(productsDataFiltered.slice(0, 9));
    }
  }, [cartItems]);

  useEffect(() => {
    if (featuredProducts.length) {
      setProducts(featuredProducts);
    }
  }, [featuredProducts]);

  useEffect(() => {
    if (!isAcquisition) {
      loadFeaturedProducts();
    }
  }, []);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        const embeddedType = get(embedded, 'type.name');

        if (embedded) {
          return (
            <Div
              className={classnames({ [styles.body]: embeddedType === 'Link' })}
            >
              {children}
            </Div>
          );
        }

        const childrenWithContent = children.filter(
          (childContent) => childContent !== '',
        );

        if (isEmpty(childrenWithContent)) return null;

        return (
          <div className={setTheme(styles.body, document, styles)}>
            {childrenWithContent.map(
              (child) =>
                child &&
                child
                  .split('\n')
                  .filter((textRow) => textRow.length > 0)
                  .map((textRow) => <Div key={uuid()}>{textRow}</Div>),
            )}
          </div>
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <H1 className={setTheme(styles.title, document, styles)}>{children}</H1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <H2 className={setTheme(styles.title, document, styles)}>{children}</H2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <H3 className={setTheme(styles.title, document, styles)}>{children}</H3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [INLINES.HYPERLINK]: linkParser,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        return (
          <Link
            className={styles.link}
            to={getToUrl(`/${nodeFields.slug}`, 'U18')}
          >
            {translateData(children)}
          </Link>
        );
      },
    },
  };

  const content = documentToReactComponents(
    document && document.fields.content,
    options,
  );

  return (
    <Section
      data-tracking={GTM_TRACKING_TAGS['Featured Products']}
      className={classnames(styles.wrapper, styles.productFeature)}
    >
      <div className={styles.container}>
        <div className={styles.content}>{content}</div>
        <div className={styles.productsWrapper}>
          {products && products.length > 0 && (
            <Slider products={products} section={'Featured Products'} />
          )}
          {products && products.length === 0 && (
            <Div>
              Please return to the list of products to view more products you
              may like.
            </Div>
          )}
        </div>
      </div>
    </Section>
  );
};

ProductsFeature.propTypes = {
  document: PropTypes.object.isRequired,
  featuredProducts: PropTypes.array,
  productsData: PropTypes.array,
  loadFeaturedProducts: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  featuredProducts: selectFeaturedProducts(),
  productsData: selectProductsData(),
  cartItems: selectCartItems(),
});

const mapDispatchToProps = {
  loadFeaturedProducts: loadFeaturedProductsAction,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withProductsSaga = injectSaga({
  key: PRODUCTS_KEY,
  saga: primoProductsSaga,
});

export default compose(withConnect, withProductsSaga)(ProductsFeature);
