import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import {
  selectSteps,
  selectAcquisitionVariables,
} from 'containers/Landing/selectors';
import { setSteps as setStepsAction } from 'containers/Landing/actions';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Button } from 'components/Ui';
import MessageDialog from 'components/Dialogs/MessageDialog';

import { useIsMobile } from 'utils/hooks';
import { replaceAllVariables } from 'utils/translation';
import ServiceForm from './ServiceForm';
import styles from './styles.scss';

const CoolerCleaningServiceStep3 = ({
  document,
  steps,
  setSteps,
  acquisitionVariables,
}) => {
  const { currentStep, step1, step2 } = steps;
  const isMobile = useIsMobile();

  const handleSubmit = () => {
    setSteps({ step1, step2, currentStep: 4 });
  };

  const handleError = () => {
    setSteps({ step1, step2, currentStep: 5 });
  };

  const handleCloseMessageDialog = () => {
    setSteps({
      step1: {
        '100 Series': 0,
        '200 Series': 0,
        '200k Series': 0,
        '300 Series': 0,
        '500 Series': 0,
      },
      step2: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
      },
      currentStep: 1,
    });
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.title}>{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={styles.tbd1}>{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const nodeDocument = get(node, 'content[1].data.target');
        const id = get(nodeDocument, 'sys.contentType.sys.id');

        switch (id) {
          case 'button':
            return (
              <Button className={styles.btn} document={nodeDocument}>
                {children}
              </Button>
            );
          default: {
            return <div className={styles.paragraph}>{children}</div>;
          }
        }
      },
    },
  };

  if (!document || (isMobile && currentStep !== 3)) {
    return null;
  }

  const content = get(
    document,
    'fields.content.content[0].content[1].data.target.fields.content.content',
    '[]',
  );

  const title =
    currentStep === 4
      ? 'coolerCleaningServiceSuccessTitle'
      : 'coolerCleaningServiceErrorTitle';
  const message =
    currentStep === 4
      ? 'coolerCleaningServiceSuccessMessage'
      : 'coolerCleaningServiceErrorMessage';
  const theTitle = replaceAllVariables(title, acquisitionVariables, '');
  const theMessage = replaceAllVariables(message, acquisitionVariables, '');

  const messageDialog = (
    <MessageDialog
      open={currentStep > 3}
      onClose={() => handleCloseMessageDialog()}
      title={theTitle}
      message={theMessage}
      isErrorMessage={currentStep === 5}
    />
  );

  let content1 = null;
  content1 = documentToReactComponents(
    {
      data: {},
      nodeType: 'document',
      content: content.slice(0, 3),
    },
    options,
  );

  return (
    <div className={styles.ccsBanner}>
      {isMobile && <div className={styles.step}>Step 3</div>}
      <div className={styles.content1}>{content1}</div>
      <ServiceForm
        content={content}
        handleSubmit={handleSubmit}
        handleError={handleError}
        url="cooler-cleaning-service-schedule-service"
      />
      {messageDialog}
    </div>
  );
};

CoolerCleaningServiceStep3.propTypes = {
  document: PropTypes.object.isRequired,
};

CoolerCleaningServiceStep3.defaultProps = {
  setSteps: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  steps: selectSteps(),
  acquisitionVariables: selectAcquisitionVariables(),
});

const mapDispatchToProps = {
  setSteps: setStepsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoolerCleaningServiceStep3);
