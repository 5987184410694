import { createGlobalStyle } from 'styled-components';
import { themeConfig as theme } from 'theme/themeConfig';
import { isWaterRegionalTheme } from 'utils/common';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body, p, button {
    font-family: ${(props) =>
      props.theme === 'pureflo' ? 'benton-sans' : 'source-sans-pro'};
  }

  body.fontLoaded {
    font-family: 'benton-sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: ${() => theme.colors.white};
    min-height: 100%;
    min-width: 100%;
  }

  _:-ms-fullscreen, :root #app { //IE flexbox fix
    overflow: hidden;
  }

  p,
  label {
    line-height: 1.5em;
  }

  a {
    color: ${(props) =>
      isWaterRegionalTheme(props.theme)
        ? theme.colors.blue
        : theme.colors.primaryBlue};
  }

  [data-aos="slide-down"] {
    &.aos-animate {
      opacity: 1;

      &:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 98%;
        bottom: 0;
        width: 100%;
        height: 37px;
        left: -10%;
        right: -10%;
        // background: radial-gradient(ellipse at 50% 52%, rgba(0, 0, 0, 0.2), rgba(91, 97, 97, 0) 61%);
      }
    }
  }

  .displayBlock {
    display: block;
  }

  .displayNone {
    display: none;
  }
`;

export default GlobalStyle;
