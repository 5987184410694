import * as types from './constants';

export const fetchHome = () => ({
  type: types.FETCH_HOME,
});

export const fetchPageRequest = (payload) => ({
  type: types.FETCH_PAGE_REQUEST,
  payload,
});

export const fetchPageSuccess = (payload) => ({
  type: types.FETCH_PAGE_SUCCESS,
  payload,
});

export const fetchPageFailure = (error) => ({
  type: types.FETCH_PAGE_FAILURE,
  payload: `${error}`,
});

export const setSection = (payload) => ({
  type: types.SET_SECTION,
  payload,
});

export const setForceFetch = (payload) => ({
  type: types.SET_FORCE_FETCH,
  payload,
});

export const setHomeOffice = (payload) => ({
  type: types.SET_HOME_OFFICE,
  payload,
});

export const navigateZipRedirect = (payload) => ({
  type: types.NAVIGATE_ZIP_REDIRECT,
  payload,
});

export const navigateLandingPageLink = (payload) => ({
  type: types.NAVIGATE_LANDING_PAGE_LINK,
  payload,
});

export const showBrandSwitchDialog = (payload) => ({
  type: types.SHOW_BRAND_SWITCH_DIALOG,
  payload,
});

export const showZipCodeEditPopper = (payload) => ({
  type: types.SHOW_ZIPCODE_POPPER,
  payload,
});

export const hideBrandSwitchDialog = (payload) => ({
  type: types.HIDE_BRAND_SWITCH_DIALOG,
  payload,
});

export const hideInitialZipDialog = (payload) => ({
  type: types.HIDE_INITIAL_ZIP_DIALOG,
  payload,
});

export const navigateCostcoRoute = (payload) => ({
  type: types.NAVIGATE_COSTCO_ROUTE,
  payload,
});

export const showCostcoMembershipDialog = (payload) => ({
  type: types.SHOW_COSTCO_MEMBERSHIP_DIALOG,
  payload,
});

export const hideCostcoMembershipDialog = (payload) => ({
  type: types.HIDE_COSTCO_MEMBERSHIP_DIALOG,
  payload,
});

export const setHeaderFooter = (payload) => ({
  type: types.SET_HEADER_FOOTER,
  payload,
});

export const setLayouts = (payload) => ({
  type: types.SET_LAYOUTS,
  payload,
});

export const getFeatureTour = () => ({
  type: types.FETCH_FEATURE_TOUR,
});

export const getFeatureTourSuccess = (payload) => ({
  type: types.FETCH_FEATURE_TOUR_SUCCESS,
  payload,
});

export const getFeatureTourFailure = (payload) => ({
  type: types.FETCH_FEATURE_TOUR_FAILURE,
  payload,
});

export const getLogoSuccess = (payload) => ({
  type: types.FETCH_LOGO_SUCCESS,
  payload,
});

export const getLogoFailure = (payload) => ({
  type: types.FETCH_LOGO_FAILURE,
  payload,
});

export const fetchVariables = (payload) => ({
  type: types.FETCH_VARIABLES,
  payload,
});

export const fetchEntryByTitle = (payload) => ({
  type: types.FETCH_ENTRY_BY_TITLE,
  payload,
});

export const fetchEntry = (payload) => ({
  type: types.FETCH_ENTRY,
  payload,
});

export const fetchResourceBundles = () => ({
  type: types.FETCH_RESOURCE_BUNDLES,
});

export const fetchJSONDataByTitle = (payload) => ({
  type: types.FETCH_JSON_DATA,
  payload,
});

export const getEntrySuccess = (payload) => ({
  type: types.FETCH_ENTRY_SUCCESS,
  payload,
});

export const getEntryFailure = (payload) => ({
  type: types.FETCH_ENTRY_FAILURE,
  payload,
});

export const updateVariables = (payload) => ({
  type: types.UPDATE_VARIABLES,
  payload,
});

export const setSsrReqRoute = (payload) => ({
  type: types.SSR_REQ_ROUTE,
  payload,
});

export const fetchPromotionLandingData = (payload) => ({
  type: types.FETCH_PROMOTION_LANDING_DATA,
  payload,
});

export const fetchPromotionLandingDataSuccess = (payload) => ({
  type: types.FETCH_PROMOTION_LANDING_DATA_SUCCESS,
  payload,
});

export const fetchPromotionLandingDataFailure = () => ({
  type: types.FETCH_PROMOTION_LANDING_DATA_FAILURE,
});

export const setPageNotFound = () => ({
  type: types.SET_PAGE_NOT_FOUND,
});

export const setSsrReqStatusCode = (payload) => ({
  type: types.SET_SSR_REQ_STATUS_CODE,
  payload,
});

export const setGclId = (payload) => ({
  type: types.SET_GCL_ID,
  payload,
});

export const setLanguage = (payload) => ({
  type: types.SET_LANGUAGE,
  payload,
});

export const setSteps = (payload) => ({
  type: types.SET_STEPS,
  payload,
});
