import isEmpty from 'lodash/isEmpty';

const CREDIT_CARD = 'creditCard';

export const updateStorageCreditCard = (cardData) =>
  sessionStorage.setItem(CREDIT_CARD, JSON.stringify(cardData));

export const getStorageCreditCard = () =>
  typeof sessionStorage !== 'undefined'
    ? JSON.parse(sessionStorage.getItem(CREDIT_CARD))
    : '';

export const clearStorageCreditCard = () =>
  sessionStorage.removeItem(CREDIT_CARD);

export const isRequiredFieldsFilled = (data = {}, fields = []) => {
  if (isEmpty(data)) {
    return false;
  }
  const nonFilledFields = Object.entries(data).filter(([key, value]) =>
    fields.includes(key) ? isEmpty(value) : false,
  );
  return isEmpty(nonFilledFields);
};
