import { fromJS } from 'immutable';

export const INITIAL = 'initial';
export const LOADING = 'loading';
export const SUCCESS = 'success';
export const ERROR = 'error';

export const STATUS = {
  INITIAL,
  LOADING,
  SUCCESS,
  ERROR,
};

const makeFetchActionTypes = (namespace) => ({
  REQUEST: `${namespace}/REQUEST`,
  RECEIVE: `${namespace}/RECEIVE`,
  FAIL: `${namespace}/FAIL`,
  RESET: `${namespace}/RESET`,
});

export const makeFetchActions = (namespace, options = { persist: false }) => {
  const types = makeFetchActionTypes(namespace);
  const { REQUEST, RECEIVE, FAIL, RESET } = types;

  const metaPersist = (persist) => (persist ? { meta: { persist: true } } : {});

  // Passing final arg `true` to action creators `request`, `receive`, and `fail`,
  // will persist (NOT clear) previous action `results`. Additionally, `receive`
  // merges deeply the given `payload` into those previous `results`.
  const actions = {
    request: (payload, persist = options.persist) => ({
      type: REQUEST,
      payload,
      ...metaPersist(persist),
    }),
    receive: (payload, persist = options.persist) => ({
      type: RECEIVE,
      payload,
      ...metaPersist(persist),
    }),
    fail: (error, persist = options.persist) => ({
      type: FAIL,
      error,
      ...metaPersist(persist),
    }),
    reset: () => ({
      type: RESET,
    }),
  };

  return [actions, types];
};

const initialState = fromJS({
  results: null,
  error: null,
  status: INITIAL,
});

export const makeFetchReducer = (namespace) => {
  const { REQUEST, RECEIVE, FAIL, RESET } = makeFetchActionTypes(namespace);

  const fetchReducer = (state = initialState, action = {}) => {
    const { type, payload, error, meta } = action;

    const results = meta?.persist ? {} : { results: null };
    const mergeFunc = meta?.persist ? 'mergeDeep' : 'merge';

    switch (type) {
      case REQUEST: {
        return state.merge({
          ...results,
          error: null,
          status: LOADING,
        });
      }

      case RECEIVE: {
        return state[mergeFunc]({
          results: payload,
          error: null,
          status: SUCCESS,
        });
      }

      case FAIL: {
        return state.merge({
          ...results,
          error,
          status: ERROR,
        });
      }

      case RESET:
        return initialState;

      default:
        return state;
    }
  };

  return fetchReducer;
};
