export default (theme) => ({
  root: {
    width: '80%',
    flexGrow: 1,
    padding: '0 30px',
    position: 'relative',
    background: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  dotsBottom: {
    margin: '20px auto',
  },
  dotsTop: {
    position: 'absolute',
    top: '122px',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('sm')]: {
      top: '-14px',
    },
  },
  dotsHidden: {
    visibility: 'hidden',
  },
  mobileStepper: {
    background: theme.colors.white,
  },
  dot: {
    borderRadius: '0',
    width: '60px',
    height: '8px',
    backgroundColor: theme.colors.lightGreen,
    '&:first-child': {
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  dotActive: {
    backgroundImage: theme.gradients.primaryGradient,
  },
  buttons: {
    position: 'absolute',
    bottom: '50%',
    margin: 'auto 0',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:first-child': {
      left: '-110px',
      [theme.breakpoints.down('lg')]: {
        left: '-55px',
      },
    },
    '&:last-child': {
      right: '-110px',
      [theme.breakpoints.down('lg')]: {
        right: '-55px',
      },
    },
  },
});
