import { fromJS } from 'immutable';
import * as types from './constants';

const initialState = fromJS({
  postalCode: null,
  brand: null,
  branch: null,
});

function userRegionReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_USER_REGION:
      return state.merge(fromJS(action.payload));
    default:
      return state;
  }
}

export default userRegionReducer;
