import {
  SAVE_LOCATION_FORM,
  SAVE_PAYMENT_FORM,
  PLACE_ORDER,
  FETCH_ORDER,
  SAVE_DELIVERY_SETUP_FORM,
  FETCH_CITY_COUNTY,
  RESET_ORDER,
  FETCH_CITY_COUNTY_OPTION,
  RESET_ERROR,
  CREATE_PASSWORD,
  RESET_CREATE_PASSWORD,
} from './constants';

export const saveLocation = (data) => ({
  type: SAVE_LOCATION_FORM,
  payload: data,
});

export const savePayment = (data) => ({
  type: SAVE_PAYMENT_FORM,
  payload: data,
});

export const saveDeliveySetup = (data) => ({
  type: SAVE_DELIVERY_SETUP_FORM,
  payload: data,
});

export const placeOrder = (data) => ({
  type: PLACE_ORDER,
  payload: data,
});

export const fetchOrder = (orderId) => ({
  type: FETCH_ORDER,
  payload: { orderId },
});

export const fetchCityCounty = (postalCode) => ({
  type: FETCH_CITY_COUNTY,
  payload: { postalCode },
});

export const resetOrder = () => ({
  type: RESET_ORDER,
});

export const saveCountyOption = (data) => ({
  type: FETCH_CITY_COUNTY_OPTION,
  payload: data,
});

export const resetCheckoutError = () => ({
  type: RESET_ERROR,
});

export const createPassword = (payload) => ({
  type: CREATE_PASSWORD,
  payload,
});

export const resetCreatePassword = () => ({
  type: RESET_CREATE_PASSWORD,
});
