import history from 'utils/history';
import { isSsr } from 'utils/ssrHelper';
import configureStore from 'configureStore';

const getInitialState = () => {
  if (!isSsr() && window.PRELOADED_STATE) {
    const initialState = window.PRELOADED_STATE;
    delete window.PRELOADED_STATE;
    return initialState;
  }

  return {};
};

export const getStore = () =>
  configureStore(getInitialState(), history, isSsr());

export const store = getStore();
