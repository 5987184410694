import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Button, Dialog, Checkbox } from 'components/Ui';
import {
  selectIsSelectLocationProcessing,
  selectServiceLocations,
} from 'containers/PrimoAccount/selectors';
import { selectServiceLocation } from 'containers/PrimoAccount/actions';
import { hideDrawer } from 'containers/DrawerManager/actions';
import { selectDrawerStatus } from 'containers/DrawerManager/selectors';
import { accountIcons } from 'images';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import HtmlData from 'components/Ui/HtmlData/index';
import styles from './styles.scss';

const renderAddress = (location) => {
  const { address1, city, stateOrProvinceCode, postalCode } = Object.entries(
    location,
  ).reduce((result, [key, value]) => {
    result[key] = String(value).toLowerCase();
    return result;
  }, {});

  return (
    <div>
      <div>{address1}</div>
      <div>
        {city}, <span>{stateOrProvinceCode}</span> {postalCode}
      </div>
    </div>
  );
};

const ConfirmationModal = ({
  drawerId,
  newLocationId,
  oldLocationId,
  reloadUrl,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [useAsDefault, changeUseAsDefault] = useState(false);

  const isShow = useSelector(selectDrawerStatus(drawerId));
  const isProcessing = useSelector(selectIsSelectLocationProcessing());
  const serviceLocations = useSelector(selectServiceLocations());

  const newLocation = useMemo(
    () =>
      serviceLocations.find(
        (serviceLocation) => serviceLocation.id === newLocationId,
      ),
    [newLocationId],
  );
  const oldLocation = useMemo(
    () =>
      serviceLocations.find(
        (serviceLocation) => serviceLocation.id === oldLocationId,
      ),
    [oldLocationId],
  );

  const handleChangeCheckbox = (isChecked) => {
    changeUseAsDefault(isChecked);
  };

  const handleClose = () => {
    dispatch(hideDrawer(drawerId));
    changeUseAsDefault(false);
  };

  const handleConfirm = () => {
    window.initialSSLoaded = false;
    window.reloadUrl = reloadUrl;
    dispatch(
      selectServiceLocation({
        serviceLocationId: newLocationId,
        useAsDefault,
        drawerId,
      }),
    );
    changeUseAsDefault(false);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      size="small"
      onClose={handleClose}
      open={isShow}
    >
      <HtmlData tracking={GTM_TRACKING_TAGS['Change Location']}>
        <div className={styles.container}>
          <div className={styles.content}>
            <img src={accountIcons.LocationIcon} alt="location icon" />
            <span className={styles.title}>Change location</span>
            <span className={styles.text}>
              Product availability may vary based on your delivery area.
            </span>
            <div className={styles.changeContainer}>
              <div className={styles.changeBlock}>
                <div className={styles.subtitle}>Current Location</div>
                {renderAddress(oldLocation)}
              </div>
              <img src={accountIcons.ArrowBlack} alt="black arrow" />
              <div className={styles.changeBlock}>
                <div className={styles.subtitle}>CHANGE TO</div>
                {renderAddress(newLocation)}
              </div>
            </div>
          </div>
          <div className={styles.checkboxContainer}>
            <HtmlData tracking={GTM_TRACKING_TAGS['Make Default']}>
              <Checkbox
                label="Make this the default location in the future"
                defaultChecked={useAsDefault}
                onChange={handleChangeCheckbox}
                isRectangle
              />
            </HtmlData>
          </div>
          <div className={styles.bottom}>
            <div className={styles.note}>
              Note: Please confirm any changes to your order prior to switching
              to a different delivery location.
            </div>
            <div className={styles.buttonsContainer}>
              <Button
                text="Cancel"
                className={styles.cancel}
                onClick={handleClose}
                disabled={isProcessing}
              />
              <Button
                text="Confirm Change"
                onClick={handleConfirm}
                loading={isProcessing}
              />
            </div>
          </div>
        </div>
      </HtmlData>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  newLocationId: PropTypes.string,
  oldLocationId: PropTypes.string,
  drawerId: PropTypes.string,
  reloadUrl: PropTypes.string,
};

export default ConfirmationModal;
