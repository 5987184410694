import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ContentfulFields from 'components/ContentfulFields';
import FAQCategory from './FAQCategory';

const TabItem = ({ items = [], ...rest }) =>
  items.map((item) => {
    const type = get(item, 'sys.contentType.sys.id');

    if (type === 'faqCategory') {
      const { searchResult } = rest;

      if (!searchResult) {
        return <FAQCategory key={item.sys.id} {...item.fields} />;
      }

      const faqItems = item.fields.faqItems.filter((faqItem) =>
        searchResult.includes(faqItem.sys.id),
      );

      if (faqItems.length === 0) {
        return null;
      }

      return (
        <FAQCategory key={item.sys.id} {...item.fields} faqItems={faqItems} />
      );
    }

    return <ContentfulFields type={type} document={item} key={item.sys.id} />;
  });

TabItem.propTypes = {
  items: PropTypes.array,
  searchResult: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default TabItem;
