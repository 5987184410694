import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AppBar } from '@material-ui/core';
import { WaterRegionalTheme, WaterTheme } from 'components/ThemeProvider';
import { Mobile, Default } from 'components/Media';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { selectSection } from 'containers/Landing/selectors';
import CheckoutHeader from 'containers/PrimoCheckoutPage/Header';
import {
  INFORMATION_PAGE,
  CONFIRMATION_PAGE,
  REVIEW_PAGE,
  PAYMENT_PAGE,
  SETUP_PAGE,
  HOMEPAGE_PAGE,
  HOMEPAGE_OFFICE_PAGE,
} from 'containers/PrimoCheckoutPage/constants';
import PromoBanner, { PLACEMENTS } from 'components/Promos/Banner';
import PrimoTransitionBanner from 'components/ContentfulFields/PrimoTransitionBanner';
import { isSsr } from 'utils/ssrHelper';
import Navigation from './Navigation';
import Contacts from './Contacts';
import Collapse from './Collapse';
import styles from './styles/water-regional.scss';

class Header extends PureComponent {
  state = {
    drawerIsOpen: false,
    drawersContent: null,
    drawersActiveTabId: null,
    currentHeight: 40,
  };

  openCollapse = (id, content) => {
    this.setState((prevState) => ({
      drawerIsOpen:
        id !== prevState.drawersActiveTabId ? true : !prevState.drawerIsOpen,
      drawersContent: content,
      drawersActiveTabId: id !== prevState.drawersActiveTabId ? id : null,
    }));
  };

  closeCollapse = () => {
    this.setState({
      drawerIsOpen: false,
      drawersContent: null,
      drawersActiveTabId: null,
    });
  };

  changeHeight = (height) => {
    this.setState({ currentHeight: height });
  };

  render() {
    const {
      state: { drawerIsOpen, drawersContent, drawersActiveTabId },
      props: {
        document,
        match: {
          params: { page },
        },
        section,
        isAuthenticated,
        hasComponentLoaded,
      },
    } = this;

    const testMedia = false;
    const isAcquisition = isAcquisitionDomain();

    const contacts =
      isAcquisition || isAuthenticated ? (
        <Contacts
          fields={document && document.fields.content}
          openCollapse={this.openCollapse}
        />
      ) : null;

    const navigation = (
      <Navigation
        document={document}
        openCollapse={this.openCollapse}
        closeCollapse={this.closeCollapse}
        activeDrawer={drawersActiveTabId}
        drawerIsOpen={drawerIsOpen}
        changeHeight={this.changeHeight}
        section={section}
      />
    );

    const collapse = (
      <Collapse
        open={drawerIsOpen}
        content={drawersContent}
        onClose={this.closeCollapse}
        isHomepage={
          section === HOMEPAGE_PAGE || section === HOMEPAGE_OFFICE_PAGE
        }
      />
    );

    const desktopHeader = () => {
      if (isSsr() || !hasComponentLoaded) {
        return (
          <header className={styles.header}>
            {testMedia && (
              <div className={styles.testMedia}>Desktop Media: </div>
            )}
            {isAuthenticated && <PrimoTransitionBanner />}
            {contacts}
            {navigation}
            <WaterRegionalTheme>{collapse}</WaterRegionalTheme>
          </header>
        );
      }
      return (
        <AppBar elevation={1} className={styles.header} position="sticky">
          {testMedia && <div className={styles.testMedia}>Desktop Media: </div>}
          {isAuthenticated && <PrimoTransitionBanner />}
          {contacts}
          <PromoBanner placement={PLACEMENTS.aboveLogo} />
          {navigation}
          <WaterRegionalTheme>{collapse}</WaterRegionalTheme>
        </AppBar>
      );
    };

    const MobileHeader = (
      <Fragment>
        {testMedia && <div className={styles.testMedia}>Test Media: </div>}
        {isAuthenticated && <PrimoTransitionBanner />}
        {contacts}
        <PromoBanner placement={PLACEMENTS.aboveLogo} />
        <AppBar className={styles.header} position="sticky">
          {navigation}
          <WaterRegionalTheme>{collapse}</WaterRegionalTheme>
          <WaterTheme>{collapse}</WaterTheme>
        </AppBar>
      </Fragment>
    );

    return (
      <Fragment>
        <Default>
          {desktopHeader()}
          <WaterRegionalTheme>
            {[
              INFORMATION_PAGE,
              CONFIRMATION_PAGE,
              PAYMENT_PAGE,
              REVIEW_PAGE,
              SETUP_PAGE,
            ].includes(page) && (
              <CheckoutHeader
                page={page}
                topOffset={this.state.currentHeight}
              />
            )}
          </WaterRegionalTheme>
        </Default>
        <WaterRegionalTheme>
          <Mobile>{MobileHeader}</Mobile>
        </WaterRegionalTheme>
        <WaterTheme>
          <Mobile>{MobileHeader}</Mobile>
        </WaterTheme>
      </Fragment>
    );
  }
}

Header.propTypes = {
  document: PropTypes.object,
  match: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  section: PropTypes.string,
  hasComponentLoaded: PropTypes.bool,
};

Header.contextTypes = {
  stylesTheme: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  section: selectSection(),
});

export default compose(connect(mapStateToProps), withRouter)(Header);
