import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styles from './styles.scss';

const ULItem = ({ fields: { icon, content } }) => {
  const iconSrc = get(icon, 'fields.file.url');
  return (
    <div className={styles.container}>
      {iconSrc && (
        <img className={styles.icon} src={`${iconSrc}?fm=webp`} alt="li-icon" />
      )}
      <span>{content}</span>
    </div>
  );
};

ULItem.propTypes = {
  fields: PropTypes.object,
};

export default ULItem;
