import React from 'react';
import PropTypes from 'prop-types';

const TooltipIcon = ({ className }) => (
  <span aria-hidden='true' className={className}>&#9662;</span>
);

TooltipIcon.propTypes = {
  className: PropTypes.string,
};

export default TooltipIcon;
