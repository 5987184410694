import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { isSelfServeDomain, getDomainByHost } from 'utils/domainHelper';
import { getToUrl } from 'utils/translation';
import { selectSection, selectLogo } from 'containers/Landing/selectors';
import { GTM_TRACKING_TAGS } from 'utils/constants';

const Anchor = ({ to, children, ...props }) => (
  <a href={to} {...props}>
    {children}
  </a>
);

const Logo = ({ fields, className, section, logo, anchor }) => {
  const A = anchor ? Anchor : Link;
  return (
    <A
      to={getToUrl(`/${section || ''}`, 'U15')}
      className={className}
      data-tracking={GTM_TRACKING_TAGS.Logo}
    >
      <img
        src={`${
          logo ? logo.file.url : fields && fields.fields.file.url
        }?fm=webp`}
        alt={`${getDomainByHost().name} home page`}
        style={isSelfServeDomain ? {} : { width: '100%' }}
      />
    </A>
  );
};

Logo.propTypes = {
  fields: PropTypes.object,
  className: PropTypes.string,
  section: PropTypes.string,
  anchor: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  section: selectSection(),
  logo: selectLogo(),
});

export default connect(mapStateToProps)(Logo);
