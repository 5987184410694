import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckIcon = ({ color, ...restProps }) => (
  <SvgIcon viewBox="0 0 24 24" width="24px" height="24px" {...restProps}>
    <path
      fill={color || 'white'}
      d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
    />
  </SvgIcon>
);

export default CheckIcon;
