/*
 * Checkout reducer
 */

import { fromJS, Map } from 'immutable';
import { sortBy } from 'lodash';
import {
  SAVE_LOCATION_FORM,
  SAVE_LOCATION_FORM_ERROR,
  SAVE_LOCATION_FORM_SUCCESS,
  SAVE_PAYMENT_FORM,
  SAVE_PAYMENT_FORM_SUCCESS,
  SAVE_PAYMENT_FORM_ERROR,
  SAVE_CREDIT_CARD,
  PLACE_ORDER,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_ERROR,
  FETCH_ORDER,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_ERROR,
  SAVE_DELIVERY_SETUP_FORM,
  SAVE_DELIVERY_SETUP_FORM_SUCCESS,
  SAVE_DELIVERY_SETUP_FORM_ERROR,
  FETCH_CITY_COUNTY,
  FETCH_CITY_COUNTY_SUCCESS,
  FETCH_CITY_COUNTY_ERROR,
  RESET_ORDER,
  PAYMENT_PROCESS_ERROR,
  FETCH_CITY_COUNTY_OPTION,
  RESET_ERROR,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR,
  RESET_CREATE_PASSWORD,
} from './constants';
import { getStorageCreditCard } from './utils';

const initialState = fromJS({
  creditCardData: getStorageCreditCard() || {},
  loading: false,
  error: null,
  errors: null,
  order: null,
  paymentErrors: null,
  createPasswordError: null,
  createPasswordSuccess: null,
  cityCountyList: [],
  cityCountyOption: null,
});

function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_LOCATION_FORM:
    case SAVE_PAYMENT_FORM:
    case PLACE_ORDER:
    case SAVE_DELIVERY_SETUP_FORM:
      return state.set('loading', true).set('error', null).set('errors', null);
    case SAVE_LOCATION_FORM_SUCCESS:
    case SAVE_PAYMENT_FORM_SUCCESS:
      return state
        .set('loading', false)
        .set('error', null)
        .set('errors', null)
        .set('paymentErrors', null);
    case SAVE_DELIVERY_SETUP_FORM_SUCCESS:
      return state.set('loading', false).set('error', null).set('errors', null);
    case PLACE_ORDER_SUCCESS:
      return state
        .set('creditCardData', Map())
        .set('order', fromJS(action.payload))
        .set('loading', false)
        .set('error', null)
        .set('errors', null);
    case SAVE_LOCATION_FORM_ERROR:
    case SAVE_PAYMENT_FORM_ERROR:
    case PLACE_ORDER_ERROR:
    case SAVE_DELIVERY_SETUP_FORM_ERROR:
      return state
        .set('loading', false)
        .set('error', action.payload)
        .set('errors', fromJS(action.payload));
    case PAYMENT_PROCESS_ERROR:
      return state
        .set('loading', false)
        .set('paymentErrors', fromJS(action.errors));
    case CREATE_PASSWORD_SUCCESS:
      return state
        .set('loading', false)
        .set('createPasswordSuccess', true)
        .set('error', null)
        .set('errors', null)
        .set('paymentErrors', null);
    case CREATE_PASSWORD_ERROR:
      return state
        .set('loading', false)
        .set('createPasswordError', fromJS(action.payload));
    case SAVE_CREDIT_CARD:
      return state.set('creditCardData', fromJS(action.payload));
    case FETCH_ORDER:
      return state.set('loading', true);
    case FETCH_ORDER_SUCCESS:
      return state.set('loading', false).set('order', fromJS(action.payload));
    case FETCH_ORDER_ERROR:
      return state.set('loading', false);
    case FETCH_CITY_COUNTY:
      return state.set('loading', true);
    case FETCH_CITY_COUNTY_SUCCESS:
      return state
        .set('loading', false)
        .set(
          'cityCountyList',
          fromJS(sortBy(action.payload || [], ['city', 'county'])),
        );
    case FETCH_CITY_COUNTY_ERROR:
      return state.set('loading', false);
    case FETCH_CITY_COUNTY_OPTION:
      return state.set('cityCountyOption', fromJS(action.payload));
    case RESET_ORDER:
      return initialState;
    case RESET_ERROR:
      return state.set('error', null);
    case RESET_CREATE_PASSWORD:
      return state
        .set('createPasswordError', null)
        .set('createPasswordSuccess', null);
    default:
      return state;
  }
}

export default checkoutReducer;
