export const NAMESPACE = `products`;
export const PRODUCTS_KEY = NAMESPACE;

export const PRIMO_ACCCOUNT_PAGE_KEY = 'sparkletts-account';

export const RESET_PRODUCTS_ERROR = `${PRODUCTS_KEY}/RESET_PRODUCTS_ERROR`;

export const ADD_TO_ORDER = `${PRODUCTS_KEY}/ADD_TO_ORDER`;
export const ADD_TO_ORDER_SUCCESS = `${PRODUCTS_KEY}/ADD_TO_ORDER_SUCCESS`;
export const ADD_TO_ORDER_FAILURE = `${PRODUCTS_KEY}/ADD_TO_ORDER_FAILURE`;

export const ADD_TO_DELIVERY = `${PRODUCTS_KEY}/ADD_TO_DELIVERY`;
export const ADD_TO_DELIVERY_SUCCESS = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_SUCCESS`;
export const ADD_TO_DELIVERY_FAILURE = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_FAILURE`;

export const LOAD_PRODUCT_CATEGORIES = `${PRODUCTS_KEY}/LOAD_PRODUCT_CATEGORIES`;
export const LOAD_PRODUCT_CATEGORIES_SUCCESS = `${PRODUCTS_KEY}/LOAD_PRODUCT_CATEGORIES_SUCCESS`;
export const LOAD_PRODUCT_CATEGORIES_FAILURE = `${PRODUCTS_KEY}/LOAD_PRODUCT_CATEGORIES_FAILURE`;

export const LOAD_MERCHANDIZED_PRODUCTS = `${PRODUCTS_KEY}/LOAD_MERCHANDIZED_PRODUCTS`;
export const LOAD_MERCHANDIZED_PRODUCTS_SUCCESS = `${PRODUCTS_KEY}/LOAD_MERCHANDIZED_PRODUCTS_SUCCESS`;
export const LOAD_MERCHANDIZED_PRODUCTS_FAILURE = `${PRODUCTS_KEY}/LOAD_MERCHANDIZED_PRODUCTS_FAILURE`;

export const LOAD_AVAILABLE_PRODUCTS = `${PRODUCTS_KEY}/LOAD_AVAILABLE_PRODUCTS`;
export const LOAD_AVAILABLE_PRODUCTS_SUCCESS = `${PRODUCTS_KEY}/LOAD_AVAILABLE_PRODUCTS_SUCCESS`;
export const LOAD_AVAILABLE_PRODUCTS_FAILURE = `${PRODUCTS_KEY}/LOAD_AVAILABLE_PRODUCTS_FAILURE`;

export const LOAD_FEATURED_PRODUCTS = `${PRODUCTS_KEY}/LOAD_FEATURED_PRODUCTS`;
export const LOAD_FEATURED_PRODUCTS_SUCCESS = `${PRODUCTS_KEY}/LOAD_FEATURED_PRODUCTS_SUCCESS`;
export const LOAD_FEATURED_PRODUCTS_FAILURE = `${PRODUCTS_KEY}/LOAD_FEATURED_PRODUCTS_FAILURE`;

export const GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST = `${PRIMO_ACCCOUNT_PAGE_KEY}/GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST`;

export const productsTypes = {
  home: 'residential',
  office: 'commercial',
};

export const WATER_CATEGORIES = [
  {
    isWaterKind: true,
    fields: { urlName: 'LargeBottle' },
    sys: { id: '3K46ptPRZQ31b3PSe4VX57' },
  },
  {
    isWaterKind: true,
    fields: { urlName: 'CasePacks' },
    sys: { id: '7dBFxagEjDc7wTjGAD0SB8' },
  },
  {
    isWaterKind: true,
    fields: { urlName: 'SparklingWater' },
    sys: { id: 'fyHCiFWJeqEu1pFzelufU' },
  },
];

export const PRODUCT_CATEGORIES = [
  ...WATER_CATEGORIES,
  {
    isWaterBundlesKind: true,
    fields: { urlName: 'WaterBundles' },
    sys: { id: '7nUWRY09Hw2V36NZQFg4lC' },
  },
  {
    isEquipmentKind: true,
    fields: { urlName: 'Equipment' },
    sys: { id: '78CFc2wyWFCb9Kwz2t93H1' },
  },
  {
    fields: { urlName: 'TeaAndHotChocolate' },
    sys: { id: '1BASdiuOE13Soa69bI8kMQ' },
  },
  {
    fields: { urlName: 'Coffee' },
    sys: { id: '2fLShbTEL0cKrSR7J9S2hk' },
  },
  {
    fields: { urlName: 'BreakroomSupplies' },
    sys: { id: '5hhul5BMOf5WMFFqQ2J1Sc' },
  },
  {
    fields: { urlName: 'CoffeeTeaAndHotChocolate' },
    sys: { id: '29xmflyz364TqA7Fp3KqLA' },
  },
];

export const PRODUCTS_PER_PAGE = 50;

export const SHOW_ORDER_CHANGE_MESSAGE = `${PRIMO_ACCCOUNT_PAGE_KEY}/SHOW_ORDER_CHANGE_MESSAGE`;
export const HIDE_ORDER_CHANGE_MESSAGE = `${PRIMO_ACCCOUNT_PAGE_KEY}/HIDE_ORDER_CHANGE_MESSAGE`;

export const ADD_TO_DELIVERY_ORDER = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_ORDER`;
export const ADD_TO_DELIVERY_ORDER_SUCCESS = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_ORDER_SUCCESS`;
export const ADD_TO_DELIVERY_ORDER_FAILURE = `${PRODUCTS_KEY}/ADD_TO_DELIVERY_ORDER_FAILURE`;

export const LOAD_PRODUCT_BUNDLES = `${PRODUCTS_KEY}/LOAD_PRODUCT_BUNDLES`;
export const LOAD_PRODUCT_BUNDLES_SUCCESS = `${PRODUCTS_KEY}/LOAD_PRODUCT_BUNDLES_SUCCESS`;
export const LOAD_PRODUCT_BUNDLES_FAILURE = `${PRODUCTS_KEY}/LOAD_PRODUCT_BUNDLES_FAILURE`;

export const LOAD_FAMILY_PRODUCT_BUNDLES = `${PRODUCTS_KEY}/LOAD_FAMILY_PRODUCT_BUNDLES`;
export const LOAD_FAMILY_PRODUCT_BUNDLES_SUCCESS = `${PRODUCTS_KEY}/LOAD_FAMILY_PRODUCT_BUNDLES_SUCCESS`;
export const LOAD_FAMILY_PRODUCT_BUNDLES_FAILURE = `${PRODUCTS_KEY}/LOAD_FAMILY_PRODUCT_BUNDLES_FAILURE`;
