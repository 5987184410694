import { SHOW_DRAWER, HIDE_DRAWER } from 'containers/DrawerManager/constants';

export function showDrawer(pageTitle) {
  return {
    type: SHOW_DRAWER,
    payload: { pageTitle },
  };
}

export function hideDrawer(pageTitle) {
  return {
    type: HIDE_DRAWER,
    payload: { pageTitle },
  };
}
