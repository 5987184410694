import React from 'react';
const RoundedPencilIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <path
      d="M5.783,2.854l.735-.735a2.055,2.055,0,0,0,.395-.455.82.82,0,0,0,0-.727A2.055,2.055,0,0,0,6.518.48,2.055,2.055,0,0,0,6.063.085a.82.82,0,0,0-.727,0A2.055,2.055,0,0,0,4.88.48l-.745.745A4.464,4.464,0,0,0,5.783,2.854M3.54,1.82.726,4.634A1.729,1.729,0,0,0,.408,5a1.7,1.7,0,0,0-.129.469L.026,6.731c-.027.136-.041.2,0,.243s.107.025.243,0l1.26-.252A1.716,1.716,0,0,0,2,6.59a1.712,1.712,0,0,0,.368-.319L5.186,3.451A5.282,5.282,0,0,1,3.54,1.82"
      transform="translate(3 3.002)"
      fill="#0054a6"
      fillRule="evenodd"
    />
    <g fill="none" stroke="#0054a6" strokeWidth="0.7">
      <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
      <circle cx="6.5" cy="6.5" r="6.15" fill="none" />
    </g>
  </svg>
);

export default RoundedPencilIcon;
