import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { F } from 'components/Ui';
import ReferNavButton from 'containers/Landing/Refer/Nav/Button';
import { selectServiceLocationId } from 'containers/PrimoAccount/selectors';
import MyAccountCollapse from 'components/ContentfulFields/Header/HeaderCollapses/MyAccountCollapse';
import SupportCollapse from 'components/ContentfulFields/Header/HeaderCollapses/SupportCollapse';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import HtmlData from 'components/Ui/HtmlData/index';
import styles from './styles.scss';

const AuthenticatedNavigation = (props) => (
  <Fragment>
    {!props.isMobile && (
      <li>
        <HtmlData inline tracking={GTM_TRACKING_TAGS['My Account']}>
          <MyAccountCollapse
            closeCollapse={props.closeCollapse}
            activeDrawer={props.activeDrawer}
            toggleCollapse={props.toggleCollapse}
          />
        </HtmlData>
      </li>
    )}
    {props.isMobile && (
      <li>
        <HtmlData inline tracking={GTM_TRACKING_TAGS['My Account']}>
          <Link
            className={classnames(styles.link, {
              [styles.active]:
                props.location.pathname === '/account/next-delivery',
            })}
            to="/account/next-delivery"
          >
            <F>My Account</F>
          </Link>
        </HtmlData>
      </li>
    )}
    {props.ourProducts ? (
      <HtmlData inline tracking={GTM_TRACKING_TAGS['Our Products']}>
        {props.ourProducts}
      </HtmlData>
    ) : (
      props.serviceLocationId && (
        <li>
          <HtmlData inline tracking={GTM_TRACKING_TAGS['Our Products']}>
            <Link
              className={classnames(styles.link, {
                [styles.active]:
                  props.location.pathname === '/account/products/list',
              })}
              to="/account/products/list"
            >
              <F>Our Products</F>
            </Link>
          </HtmlData>
        </li>
      )
    )}
    <li>
      <HtmlData inline tracking={GTM_TRACKING_TAGS.Support}>
        <SupportCollapse
          closeCollapse={props.closeCollapse}
          activeDrawer={props.activeDrawer}
          toggleCollapse={props.toggleCollapse}
        />
      </HtmlData>
    </li>
    <ReferNavButton isMobile={props.isMobile} />
  </Fragment>
);

AuthenticatedNavigation.propTypes = {
  closeCollapse: PropTypes.func,
  activeDrawer: PropTypes.string,
  toggleCollapse: PropTypes.func,
  location: PropTypes.object,
  ourProducts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isMobile: PropTypes.bool,
  serviceLocationId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  serviceLocationId: selectServiceLocationId(),
});

export default withRouter(
  connect(mapStateToProps, null)(AuthenticatedNavigation),
);
