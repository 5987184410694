/*
 *
 * Authentication reducer
 *
 */

import { fromJS } from 'immutable';
import * as types from 'containers/Authentication/constants';

const initialState = fromJS({
  isInitiated: false,
  isFetching: false,
  isAuthenticated: false,
  isMobileAppView: false,
  isAuthenticating: false,
  isLogoutInitiated: false,
  error: null,
  logIn: {
    isInitiated: false,
    isFetching: false,
    error: null,
  },
  signUp: {
    isInitiated: false,
    isFetching: false,
    error: null,
  },
  restorePassword: {
    isInitiated: false,
    isFetching: false,
    email: null,
    error: null,
    message: null,
  },
  changePassword: {
    isFetching: false,
    error: null,
    message: null,
  },
  user: null,
});

function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN:
      return state.mergeDeep(
        fromJS({
          logIn: {
            isInitiated: true,
            isFetching: true,
            error: null,
          },
        }),
      );
    case types.LOGIN_FAILURE:
      return state.mergeDeep(
        fromJS({
          logIn: {
            isFetching: false,
            error: action.payload.errorMessage,
          },
        }),
      );
    case types.LOGIN_SUCCESS:
      return state.mergeDeep(
        fromJS({
          logIn: {
            isFetching: false,
            error: null,
          },
          isAuthenticated: true,
        }),
      );
    case types.LOAD_AUTHENTICATION:
      return state.merge(
        fromJS({
          isInitiated: true,
          error: null,
        }),
      );
    case types.LOAD_AUTHENTICATION_SUCCESS:
      return state.merge(
        fromJS({
          isInitiated: true,
          isFetching: false,
          isAuthenticated: true,
          error: null,
          user: action.payload,
          isAuthenticating: false,
        }),
      );
    case types.LOAD_AUTHENTICATION_FAILURE:
      return state.merge(
        fromJS({
          isInitiated: true,
          isFetching: false,
          isAuthenticated: false,
          isAuthenticating: false,
          error: action.payload.errorMessage,
        }),
      );
    case types.HANDLE_AUTHENTICATION:
      return state.merge(
        fromJS({
          isAuthenticating: true,
        }),
      );
    case types.SIGNUP:
      return state.merge(
        fromJS({
          signUp: {
            isInitiated: true,
            isFetching: true,
            error: null,
          },
        }),
      );
    case types.SIGNUP_SUCCESS:
      return state.mergeDeep(
        fromJS({
          signUp: {
            isInitiated: true,
            isFetching: false,
          },
        }),
      );
    case types.SIGNUP_FAILURE:
      return state.merge(
        fromJS({
          signUp: {
            isInitiated: true,
            isFetching: false,
            error: action.payload.errorMessage,
          },
        }),
      );
    case types.RESTORE_PASSWORD:
      return state.merge(
        fromJS({
          restorePassword: {
            isInitiated: true,
            isFetching: true,
            email: action.payload.email,
            error: null,
            message: null,
          },
        }),
      );
    case types.RESTORE_PASSWORD_SUCCESS:
      return state.merge(
        fromJS({
          restorePassword: {
            isInitiated: true,
            isFetching: false,
            error: null,
            message: action.payload,
            email: state.getIn(['restorePassword', 'email']),
          },
        }),
      );
    case types.RESTORE_PASSWORD_FAILURE:
      return state.merge(
        fromJS({
          restorePassword: {
            isInitiated: true,
            isFetching: false,
            error: action.payload.errorMessage,
          },
        }),
      );
    case types.CHANGE_PASSWORD:
      return state.merge(
        fromJS({
          changePassword: {
            isFetching: true,
            error: null,
            message: null,
          },
        }),
      );
    case types.CHANGE_PASSWORD_SUCCESS:
      return state.merge(
        fromJS({
          changePassword: {
            isFetching: false,
            error: null,
            message: action.payload,
          },
        }),
      );
    case types.CHANGE_PASSWORD_FAILURE:
      return state.merge(
        fromJS({
          changePassword: {
            isFetching: false,
            error: action.payload.errorMessage,
          },
        }),
      );
    case types.LOGOUT:
      return state.merge(
        fromJS({
          isInitiated: true,
          isFetching: false,
          isAuthenticated: false,
        }),
      );
    case types.LOGOUT_INITIATED:
      return state.merge(
        fromJS({
          isLogoutInitiated: true,
        }),
      );
    case '@@router/LOCATION_CHANGE':
      return state.mergeDeep(
        fromJS({
          logIn: { error: null },
          signUp: { error: null },
          restorePassword: { error: null, message: null },
        }),
      );
    case types.HANDLE_MOBILE_AUTHENTICATION:
      return state.mergeDeep(
        fromJS({ isMobileAppView: true, isFetching: true }),
      );
    case types.LOAD_MOBILE_AUTHENTICATION_SUCCESS:
      return state.merge(
        fromJS({
          isInitiated: true,
          isFetching: false,
          isAuthenticated: true,
          error: null,
          user: null,
        }),
      );
    default:
      return state;
  }
}

export default authenticationReducer;
