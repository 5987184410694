import { apiCall } from 'utils/swaggerClient';
import { getRegionalHost, getProtocol } from 'utils/domainHelper';
import qs from 'qs';
import { redirectToAcquisition } from 'utils/common';
import { isSsr } from 'utils/ssrHelper';
import { DEFAULT_USER_REGION as defaultUserRegion } from 'containers/UserRegion/constants';

const getRedirectPath = (target) => {
  if (target === 'createAccount') return '/account/create';
  if (target === 'quickShop') return '/quick-shop/select-water';
  if (target === 'signIn') return '/account/login';
  return '/bottled-water-and-coffee';
};

export const submitZip = async ({ zip, target, type }) => {
  try {
    const { obj: brand } = await apiCall({
      operationId: 'brands.getBrandByZip',
      parameters: { postalCode: zip, source: 'TBD 3' },
      fullResponse: true,
    });

    if (!brand) {
      throw new Error();
    } else if (!isSsr()) {
      // if (!isBranchInPilot(branch)) {
      if (brand !== defaultUserRegion.brand) {
        redirectToAcquisition(brand);
      }

      const prefix = type === 'office' ? '/office' : '';
      const host = getRegionalHost({ brand });
      const protocol = getProtocol();
      const queryParams = qs.stringify({
        postalCode: zip,
      });

      let redirectUrl = `${host}${prefix}`;

      if (target) {
        redirectUrl += getRedirectPath(target);
      }

      window.location.href = `${protocol}://${redirectUrl}?${queryParams}`;
    }

    return { isNonServiceable: false };
  } catch (e) {
    return { isNonServiceable: true };
  }
};
