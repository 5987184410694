import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { Select as MUISelect, MenuItem, withStyles } from '@material-ui/core';
import {
  SelectArrow,
  ArrowDown,
  PrimaryArrow,
} from 'components/Ui/CustomIcons';
import Input from 'components/Ui/Input';
import { translateData } from 'utils/translation';

const styles = (theme) => {
  const getStyle = require(`./styles/${theme.themeName}`);
  return getStyle(theme);
};

const ICONS = {
  arrowDown: ArrowDown,
  primaryArrow: PrimaryArrow,
  selectArrow: SelectArrow,
};

class Select extends ValidatorComponent {
  state = {
    isOpen: false,
    isValid: true,
  };

  handleOpen = () => this.setState({ isOpen: true });

  handleClose = () => this.setState({ isOpen: false });

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      error,
      classes,
      label,
      boldLabel,
      validatorListener,
      minHeight,
      className,
      icon,
      withoutError,
      value,
      placeholder,
      id,
      ariaLabel,
      autocomplete,
      translateItems,
      ...rest
    } = this.props;

    const translatedAriaLabel = translateData(
      ariaLabel || this.props['aria-label'],
      '',
    );
    const { isValid } = this.state;

    const isRequired = validators.includes('required');

    const menuProps = {
      classes: {
        paper: classes.menuPaper,
        list: classes.menuList,
      },
    };

    const inputComponent = (
      <Input
        select
        id={id}
        label={label}
        boldLabel={boldLabel}
        ariaLabel={translatedAriaLabel}
        required={isRequired}
        value={this.props.value}
        error={error || !isValid}
        minHeight={minHeight}
        withoutError={withoutError}
        autocomplete={autocomplete}
        validators={validators}
        errorMessages={errorMessages}
      />
    );

    return (
      <MUISelect
        {...rest}
        value={value || ''}
        inputProps={{
          name: this.props.name,
        }}
        classes={{
          select: classes.select,
          selectMenu: classnames(
            classes.selectMenu,
            placeholder && value === '' && classes.placeholder,
          ),
          icon: classes.icon,
        }}
        className={className}
        MenuProps={menuProps}
        input={inputComponent}
        IconComponent={ICONS[icon] || SelectArrow}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        displayEmpty={placeholder}
        labelId={`${id}-label`}
      >
        {placeholder && (
          <MenuItem
            value=""
            disabled
            classes={{
              root: classes.placeholder,
            }}
          >
            {translateData(placeholder)}
          </MenuItem>
        )}
        {this.props.items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            {translateItems ? translateData(item.label) : item.label}
          </MenuItem>
        ))}
      </MUISelect>
    );
  }
}

Select.propTypes = {
  translateItems: PropTypes.boolean,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  boldLabel: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default withStyles(styles)(Select);
