import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from './styles.scss';

const PurefloLearnMore = ({ fields }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className={styles.content}>{children}</div>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.title}>{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={styles.title}>{children}</h3>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const { data } = node;

        return (
          <Link className={styles.link} to={data.uri}>
            {children}
          </Link>
        );
      },
    },
  };

  const content = documentToReactComponents(fields, options);

  return <div className={styles.learnMoreContainer}>{content}</div>;
};

PurefloLearnMore.propTypes = {
  fields: PropTypes.object,
};

export default PurefloLearnMore;
