import { get, isEmpty, isNil, isString, map } from 'lodash';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { THEMES } from 'components/ThemeProvider';
import jwtDecode from 'jwt-decode';
import { isSsr } from 'utils/ssrHelper';
import { FR_CA, getLocale, translateData } from 'utils/translation';
import { getFsUid } from 'utils/fullStory';
import { getServerData } from 'utils/getServerData';
import { getAcquisitionOrigin, getDomainByHost } from 'utils/domainHelper';
import { BRANDS } from 'containers/Landing/constants';

export const isDevelopment = getServerData('NODE_ENV') === 'development';

export function applicationUrl() {
  if (!isSsr()) {
    return `${window.location.protocol}//${window.location.host}`;
  }

  return null;
}

export function logoutURL() {
  if (!isSsr()) {
    return window.location.origin;
  }

  return null;
}

export function loginURL() {
  const { hostname } = window.location;
  const regex = /^(\w+-?\w+?)-acq-([^.]+)\.dss-aws\.com$/;
  const matches = hostname.match(regex);
  let BRAND = getDomainByHost().brand;
  const env = matches && matches[2];
  BRAND =
    BRAND.toLowerCase() === BRANDS.PRIMO.toLowerCase() ? BRANDS.WATER : BRAND;

  if (env) {
    // env exists
    return `https://${BRAND}-${env}.dss-aws.com`;
  }
  // env is local, or prod
  return `https://drink.${BRAND}.com`;
}

export function excludedRedirectURL() {
  if (!isSsr()) {
    const { slug } = getDomainByHost(27);
    const domain = slug === 'sparkletts' ? 'water' : slug;
    const outUrl = window.location.host.includes('localhost')
      ? `https://www.${domain}.com`
      : `${window.location.protocol}//www.${domain}.com`;
    return outUrl;
  }

  return null;
}

export const redirectToExternalURL = (strUrl) => {
  const url = new URL(strUrl);
  const params = new URLSearchParams(url.search);

  const fsuid = getFsUid();
  const gclid = !params.has('gclid') && window.gclId;

  if (fsuid) params.set('fsuid', fsuid);
  if (gclid) params.set('gclid', gclid);

  url.search = `${params}` ? `?${params}` : '';

  window.location.href = `${url}`;
};

export const redirectToAcquisition = (brand, path = '/') =>
  redirectToExternalURL(`${getAcquisitionOrigin(brand)}${path}`);

export const isWaterRegionalTheme = (themeName) =>
  themeName === THEMES.WATER_REGIONAL;

export const isWaterTheme = (themeName) => themeName === THEMES.WATER;

export const formatCurrency = (number, defaultTBD = 'TBD') => {
  if (isNil(number)) {
    return translateData(defaultTBD);
  }
  const locale = getLocale();
  const localeNumber = number.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return locale === FR_CA ? `${localeNumber} $` : `$ ${localeNumber}`;
};

export const formatPhone = (phone) => {
  const parsedPhone = parsePhoneNumberFromString(phone, 'US');

  if (!parsedPhone) {
    return {
      formatted: phone,
      value: phone,
    };
  }

  if (parsedPhone.country === 'US') {
    return {
      formatted: parsedPhone.formatNational(),
      value: parsedPhone.number,
    };
  }

  return {
    formatted: parsedPhone.formatInternational(),
    value: parsedPhone.number,
  };
};

export const getMiddleOfTheWindow = () =>
  Math.round(!isSsr() && window.innerWidth / 2);

export const roundPricesToTwoDecimalPlaces = (item) => {
  if (item.floorPrice) {
    item.floorPrice = item.floorPrice.toFixed(2);
  }
  if (item.lineTotal) {
    item.lineTotal = item.lineTotal.toFixed(2);
  }
  if (item.listPrice) {
    item.listPrice = item.listPrice.toFixed(2);
  }
  return item;
};

export const isPastCutOffDateTime = (cutOffDateTime) => {
  const deliveryOrderCutOffMoment = moment
    .utc(cutOffDateTime)
    .format('YYYY-MM-DDTHH:mm:ss');
  return moment().isAfter(deliveryOrderCutOffMoment);
};

export const isPastDeliveryCutOffDateTime = (serviceLocation) =>
  isPastCutOffDateTime(get(serviceLocation, 'deliveryCutOffDateTime'));

export const getUTMQuery = () => {
  const utmSearch = isSsr() ? [] : window.location.search.split('&');
  const utmParams = utmSearch.filter((param) =>
    param.toUpperCase().includes('UTM_'),
  );
  const utmQuery = isEmpty(utmParams)
    ? ''
    : utmParams.join('&').replaceAll('?', '');

  return utmQuery;
};

export const decodeURL = () => {
  let decodedURL = {
    isRegionalAcquistionSite: false,
    isEmailRedirect: false,
    initiateLogin: true,
    redirectToPath: '',
    isCreateAccount: false,
    accountNumber: null,
    firstName: null,
    lastName: null,
    emailId: null,
    userToken: null,
    utmQuery: getUTMQuery(),
  };
  if (isSsr()) {
    return decodedURL;
  }
  const { host } = window.location;

  const isRegionalAcquistionSite =
    !(host.includes('costcowater') || host.includes('pureflo')) &&
    (process.env.NODE_ENV !== 'development' ||
      (host.includes('localhost') &&
        !['costcowater', 'pureflo'].includes(getServerData('LOCAL_DOMAIN'))));

  if (!isRegionalAcquistionSite) {
    return decodedURL;
  }

  decodedURL.isRegionalAcquistionSite = isRegionalAcquistionSite;

  if (!window.location.search) {
    return decodedURL;
  }

  const params = getURLSearchParams();
  const isCreateAccount =
    getQueryParam('createAccount', params)?.toLowerCase() === 'y';

  if (isCreateAccount) {
    const userToken = getQueryParam('userToken', params);
    const decodedToken = isEmpty(userToken) ? {} : jwtDecode(userToken);
    const { accountNumber, firstName, lastName, emailId } = decodedToken;
    decodedURL = {
      ...decodedURL,
      isCreateAccount,
      accountNumber,
      firstName,
      lastName,
      emailId,
      userToken,
    };
  }

  const isEmailRedirect =
    getQueryParam('emailRedirect', params)?.toLowerCase() === 'y';
  if (!isEmailRedirect) {
    return decodedURL;
  }
  decodedURL.isEmailRedirect = isEmailRedirect;

  const product = getQueryParam('product', params);
  if (product) {
    decodedURL.redirectToPath = `/account/products/${product}?loginRedirect=Y`;
    return decodedURL;
  }
  let redirectToPath = `/account/products/list?loginRedirect=Y`;

  const pathValue = (brandPathValue, currentValue) =>
    `${brandPathValue}&brands=${currentValue}`;

  const brands = getQueryParams('brand', params);
  if (brands && brands.length > 0) {
    redirectToPath = `${redirectToPath}&brands=${brands.reduce(pathValue)}`;
  }

  const category = getQueryParam('category', params);
  redirectToPath = category
    ? `${redirectToPath}&categoryId=${category}`
    : redirectToPath;

  const waterType = getQueryParam('waterType', params);
  redirectToPath = waterType
    ? `${redirectToPath}&waterTypes==${waterType}`
    : redirectToPath;

  decodedURL.redirectToPath = redirectToPath;
  return decodedURL;
};

export const isLoginRedirect = () =>
  !isSsr() && window.location.href.includes('loginRedirect=Y');

export const startsWithIgnoreCase = (queryString, testString) => {
  const regex = new RegExp(`^${queryString}`, 'i');
  return regex.test(testString);
};

export const includesIgnoreCase = (queryString, testString) => {
  const regex = new RegExp(`${queryString}`, 'i');
  return regex.test(testString);
};

export const equalsIgnoreCase = (queryString, testString) => {
  const regex = new RegExp(`^${queryString}$`, 'i');
  return regex.test(testString);
};

export const adjustPostalCode = (postalCode) => {
  if (postalCode) {
    return postalCode.length === 5 ? postalCode : postalCode.substring(0, 3);
  }
  return '';
};

export const fixBrand = (brand) =>
  brand === 'deeprock' ? 'deeprockwater' : brand;

export const getProductName = (product) => {
  const name = product ? product.name || product.description || '' : '';
  return isString(name) ? name : '';
};

// HINT: Unfortunately, the url query params are case sensitive and there are proposals
//       found in MDN or specification to make it case insensitive.
export const getURLSearchParams = () =>
  new URLSearchParams(
    map([...new URL(window.location).searchParams], ([key, val]) => [
      key.toLocaleLowerCase(),
      val,
    ]),
  );

export const getQueryParam = (name, searchParams) =>
  (searchParams || getURLSearchParams()).get(name.toLowerCase());

export const getQueryParams = (name, searchParams) =>
  (searchParams || getURLSearchParams()).getAll(name.toLowerCase());

export const updatePostalCode = (value) => {
  localStorage.setItem('confirmedPostalCode', value);
  localStorage.setItem('showPopper', false);
};

export const getRandomString = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
