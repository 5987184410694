import React from 'react';
import { useDispatch } from 'react-redux';
import ReduxDrawer from 'containers/DrawerManager/ReduxDrawer';
import { hideDrawer } from 'containers/DrawerManager/actions';
import SignInForm from './form';
import { SIGN_IN_DRAWER } from '../constants';

const SignInDrawer = () => {
  const dispatch = useDispatch();
  const handleHideForm = () => dispatch(hideDrawer(SIGN_IN_DRAWER));

  return (
    <ReduxDrawer
      type="dialog"
      WrappedComponent={SignInForm}
      drawerKey={SIGN_IN_DRAWER}
      drawerProps={{
        headerTitle: 'Edit billing',
        size: 'small',
      }}
      props={{
        onClose: handleHideForm,
      }}
    />
  );
};

export default SignInDrawer;
