import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Collapse as MUICollapse } from '@material-ui/core';
import get from 'lodash/get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { Button, Icon } from 'components/Ui';
import { getContentOptions, getImagesOptions } from './parserOptions';

class CollapseBanner extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const {
      props: { document, stylesTheme },
      state: { isOpen },
    } = this;

    const styles = require(`./${stylesTheme}.scss`);

    const activeContentImage = documentToReactComponents(
      document && document.fields.activeContent,
      getImagesOptions(styles),
    );

    const activeContent = documentToReactComponents(
      document && document.fields.activeContent,
      getContentOptions(styles, document),
    );

    const hiddenContent = documentToReactComponents(
      document && document.fields.hiddenContent,
      getContentOptions(styles, document),
    );

    const hiddenContentImage = documentToReactComponents(
      document && document.fields.hiddenContent,
      getImagesOptions(styles),
    );

    return (
      document && (
        <div
          className={setTheme(styles.collapseBanner, document, styles)}
          style={{
            backgroundImage:
              document.fields.backgroundImage &&
              `url(${
                document.fields.backgroundImage &&
                document.fields.backgroundImage.fields.file.url
              }?fm=webp)`,
          }}
        >
          {get(
            document,
            'fields.backgroundImage.fields.file.contentType',
            '',
          ).includes('video') && (
            <div className={styles.backgroundVideo}>
              <video autoPlay muted loop>
                <source
                  src={document.fields.backgroundImage.fields.file.url}
                  type={document.fields.backgroundImage.fields.file.contentType}
                />
              </video>
            </div>
          )}
          <div className={styles.content}>
            <div className={styles.activeContent}>
              <div className={styles.controlledContent}>
                <div className={styles.images}>{activeContentImage}</div>
                <div className={styles.descriptionWrapper}>{activeContent}</div>
              </div>
              <div
                className={classnames(styles.toggleBtn, {
                  [styles.isOpen]: isOpen,
                })}
              >
                <span>{isOpen ? 'Less' : 'More Offers'}</span>
                <Button
                  icon={<Icon icon={isOpen ? 'angle-up' : 'angle-down'} />}
                  onClick={this.toggle}
                />
              </div>
            </div>
            <div className={styles.hiddenContent}>
              <MUICollapse in={isOpen}>
                <div className={styles.images}>{hiddenContentImage}</div>
                <div className={styles.descriptionWrapper}>{hiddenContent}</div>
              </MUICollapse>
            </div>
          </div>
        </div>
      )
    );
  }
}

CollapseBanner.propTypes = {
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
};

export default CollapseBanner;
