import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import get from 'lodash/get';
import { useMediaQuery } from '@material-ui/core';
import { isCanadianFrench } from 'utils/translation';
import { useTheme } from '@material-ui/core/styles';
import { useSwipeable } from 'react-swipeable';
import RCalendar from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { defaultClassNames } from './constants';
import Navbar from './Navbar';
import LoadingIndicator from '../LoadingIndicator';
import DayWrapper from './DayWrapper';
import Weekday from './Weekday';
import 'react-day-picker/lib/style.css';
import styles from './styles.scss';
import wrapperStyles from './wrapperStyles.scss';

const currentDate = moment();
const getMonthsList = (startMonth, numberOfMonths) =>
  [...new Array(numberOfMonths)].map((_, i) =>
    startMonth.clone().add(i, 'month'),
  );
const defaultNumberOfMonth = 3;

function Calendar({
  selectedDay,
  calendarDates,
  changeMonths,
  changeRange,
  loading,
  onDayChange,
  disabledDays,
  monthsNumber,
  useMobileNavbar,
  narrowView,
  disableForwardAfter,
  classNames = {},
  dayRenderer,
  initialMonth,
  hideOutsideRange,
  ...calendarProps
}) {
  const [month, changeMonth] = useState((initialMonth || currentDate).toDate());
  const [isCanBack, changeIsCanBack] = useState(false);
  const [timeLang, changeTimeLang] = useState('en');
  const [isCanForward, changeIsCanForward] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const classNamesObject = useMemo(
    () =>
      Object.entries(styles).reduce((result, [className, classValue]) => {
        if (result[className]) {
          result[className] = classnames(classValue, result[className], {
            [styles.useMobileNavbar]: useMobileNavbar,
            [styles.narrowView]: narrowView,
          });
        }

        return result;
      }, Object.assign(defaultClassNames, classNames)),
    [useMobileNavbar, narrowView],
  );

  let numberOfMonths = defaultNumberOfMonth;

  if (monthsNumber) {
    numberOfMonths = monthsNumber;
  } else if (isMobile) {
    numberOfMonths = 1;
  } else if (isTablet) {
    numberOfMonths = 2;
  }

  useEffect(() => {
    if (changeMonths) {
      changeMonths(getMonthsList(moment(month), defaultNumberOfMonth));
    }
    if (isCanadianFrench()) {
      moment.locale('fr');
      changeTimeLang('fr');
    } else {
      moment.locale('en');
      changeTimeLang('en');
    }
  }, []);

  useEffect(() => {
    if (changeRange) {
      changeRange(numberOfMonths);
    }
  }, [numberOfMonths]);

  const handleChangeMonth = (newMonth) => {
    if (newMonth.format('YYYY-MM-DD') === moment(month).format('YYYY-MM-DD')) {
      changeIsCanBack(false);
    } else {
      changeIsCanBack(true);
    }

    if (disableForwardAfter) {
      if (newMonth.isAfter(moment().add(disableForwardAfter, 'months'))) {
        changeIsCanForward(false);
      } else {
        changeIsCanForward(true);
      }
    }

    if (changeMonths) {
      changeMonths(getMonthsList(newMonth, defaultNumberOfMonth));
    }

    changeMonth(newMonth.toDate());
  };

  const handleForward = () =>
    handleChangeMonth(moment(month).add(numberOfMonths, 'month'));

  const handleBackward = () => {
    const newDate = moment(month).subtract(numberOfMonths, 'month');
    const isBeforeMin = newDate.isBefore(currentDate);
    const setDate = isBeforeMin ? currentDate : newDate;

    handleChangeMonth(setDate);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleForward,
    onSwipedRight: handleBackward,
    trackMouse: true,
  });

  function renderDay(day, modifiers) {
    if (modifiers.outsideRange && hideOutsideRange) {
      return null;
    }
    const date = moment(day);
    const dateProps = get(calendarDates, [
      date.format('YYYY-MM'),
      date.format('DD'),
    ]);
    const isSelected = selectedDay && moment(selectedDay).isSame(day, 'day');

    if (dayRenderer) {
      return dayRenderer(date, isSelected, dateProps, modifiers);
    }

    if (dateProps) {
      return (
        <DayWrapper calendarRender {...dateProps}>
          {date.format('D')}
        </DayWrapper>
      );
    }
    if (isSelected) {
      return (
        <span className={wrapperStyles.selectedRound}>{date.format('D')}</span>
      );
    }

    return <span className={wrapperStyles.fakeRound}>{date.format('D')}</span>;
  }

  const additionalProps = useMemo(() => {
    if (useMobileNavbar && isMobile) {
      return {
        captionElement: () => null,
      };
    }

    return {};
  }, [useMobileNavbar, isMobile]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        wrapperStyles.wrapper,
        useMobileNavbar && styles.useMobileNavbar,
      )}
      {...swipeHandlers}
    >
      <RCalendar
        {...calendarProps}
        classNames={classNamesObject}
        numberOfMonths={numberOfMonths}
        onDayClick={onDayChange}
        renderDay={renderDay}
        month={month}
        pagedNavigation={false}
        disabledDays={disabledDays}
        firstDayOfWeek={0}
        localeUtils={MomentLocaleUtils}
        locale={timeLang}
        navbarElement={
          <Navbar
            handleForward={handleForward}
            handleBackward={handleBackward}
            isCanBack={isCanBack}
            isCanForward={isCanForward}
            month={month}
            useMobileNavbar={useMobileNavbar}
            narrowView={narrowView}
          />
        }
        weekdayElement={Weekday}
        {...additionalProps}
      />
      {loading && <LoadingIndicator className={styles.loadingIndicator} />}
    </div>
  );
}

Calendar.propTypes = {
  calendarDates: PropTypes.object,
  disabledDays: PropTypes.any,
  selectedDay: PropTypes.object,
  changeMonths: PropTypes.func,
  changeRange: PropTypes.func,
  onDayChange: PropTypes.func,
  loading: PropTypes.bool,
  monthsNumber: PropTypes.number,
  disableForwardAfter: PropTypes.number,
  useMobileNavbar: PropTypes.bool,
  narrowView: PropTypes.bool,
  initialMonth: PropTypes.object,
  hideOutsideRange: PropTypes.bool,
};

Calendar.defaultProps = {
  useMobileNavbar: true,
  narrowView: true,
};

export default Calendar;
