import React from 'react';
import PropTypes from 'prop-types';
import Product from './item';
import styles from './styles.scss';

const ProductsList = ({ products }) => (
  <div className={styles.productsList}>
    {products.map((product) => (
      <Product key={product.sys.id} document={product} />
    ))}
  </div>
);

ProductsList.propTypes = {
  products: PropTypes.array,
};

export default ProductsList;
