/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { omit, mapValues, get, isObject } from 'lodash';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import { selectSteps } from 'containers/Landing/selectors';
import { Input, Button, Form, PhoneInput } from 'components/Ui';
import { isSsr } from 'utils/ssrHelper';
import {
  Series100,
  Series200,
  Series200k,
  Series300,
  Series500,
} from 'utils/constants';
import { getDomainByHost } from 'utils/domainHelper';
import { dataLayerPush } from 'utils/tracking';

import styles from './styles.scss';

class ServiceForm extends PureComponent {
  state = {
    firstname: '',
    lastname: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    phone: '',
    emailAddress: '',
    account_number: '',
    isErrorShown: false,
    isLoading: false,
  };

  canSubmit = () => {
    const { step1, step2 } = this.props.steps;

    const counts =
      step1[Series100] +
      step1[Series200] +
      step1[Series200k] +
      step1[Series300] +
      step1[Series500];
    const cond1 = counts > 0;

    const cond2 =
      step2.Monday ||
      step2.Tuesday ||
      step2.Wednesday ||
      step2.Thursday ||
      step2.Friday;

    return cond1 && cond2;
  };

  handleChange = (valueObj, fieldName) => {
    const name = isObject(valueObj) ? get(valueObj, 'target.name') : fieldName;
    const value = isObject(valueObj) ? get(valueObj, 'target.value') : valueObj;

    this.setState({ [name]: value });
  };

  handleChangeCheckbox = ({ target: { checked, name } }) => {
    this.setState({ [name]: checked });
  };

  removeWhitespaces = (str) => str.replace(/\s/g, '');

  handleSubmit = () => {
    if (!isSsr()) {
      this.toggleLoading();
      const domain = getDomainByHost(4);

      const data = mapValues(
        omit(this.state, ['isErrorShown', 'isLoading']),
        (formValue) => formValue.value || formValue,
      );
      const { step1, step2 } = this.props.steps;

      // coolerOption: '100Series,200Series,500Series'
      const arr1 = [];
      arr1.push(
        step1[Series100] > 0 ? this.removeWhitespaces(Series100) : null,
      );
      arr1.push(
        step1[Series200] > 0 ? this.removeWhitespaces(Series200) : null,
      );
      arr1.push(
        step1[Series200k] > 0 ? this.removeWhitespaces(Series200k) : null,
      );
      arr1.push(
        step1[Series300] > 0 ? this.removeWhitespaces(Series300) : null,
      );
      arr1.push(
        step1[Series500] > 0 ? this.removeWhitespaces(Series500) : null,
      );
      const coolerOption = arr1.filter(Boolean).join(',');
      data.coolerOption = coolerOption;

      // coolerQuantity: '100 QTY=2\n200 QTY=4\n500 QTY=1'
      const arr2 = [];
      arr2.push(step1[Series100] > 0 ? `100 QTY=${step1[Series100]}` : null);
      arr2.push(step1[Series200] > 0 ? `200 QTY=${step1[Series200]}` : null);
      arr2.push(step1[Series200k] > 0 ? `200k QTY=${step1[Series200k]}` : null);
      arr2.push(step1[Series300] > 0 ? `300 QTY=${step1[Series300]}` : null);
      arr2.push(step1[Series500] > 0 ? `400 QTY=${step1[Series500]}` : null);
      const coolerQuantity = arr2.filter(Boolean).join('\n');
      data.coolerQuantity = coolerQuantity;

      // appointmentDays: 'monday,wednesday'
      const appointmentDaysArray = [];
      appointmentDaysArray.push(step2.Monday ? 'monday' : null);
      appointmentDaysArray.push(step2.Tuesday ? 'tuesday' : null);
      appointmentDaysArray.push(step2.Wednesday ? 'wednesday' : null);
      appointmentDaysArray.push(step2.Thursday ? 'thursday' : null);
      appointmentDaysArray.push(step2.Friday ? 'friday' : null);
      const appointmentDays = appointmentDaysArray.filter(Boolean).join(',');
      data.appointmentDays = appointmentDays;

      data.SubscriberKey = data.emailAddress;
      data.lead_source = domain.url;
      data._clientID = '1057339';
      data._deExternalKey = '7C14D423-EC42-4049-9E06-2B131B1495C2';
      data._action = 'add';
      data._returnXML = '0';
      data._successURL = `https://${domain.url}/cooler-cleaning-service-schedule-service?success=true`;
      data._errorURL = `https://${domain.url}/cooler-cleaning-service-schedule-service?success=false`;

      dataLayerPush(
        'Acquisition',
        {
          event: 'Cooler Cleaning Service',
          data,
        },
        'dlA38',
      );

      axios
        .post('/salesforce/cooler_cleaning_service', data)
        .then(() => {
          this.toggleLoading();
          this.props.handleSubmit();
        })
        .catch(() => {
          this.toggleLoading();
          this.props.handleError();
        });
    }
  };

  showError = () => {
    this.setState({ isErrorShown: true });
  };

  toggleLoading = () => {
    this.setState((prevState) => ({
      isLoading: !prevState.isLoading,
      isErrorShown: false,
    }));
  };

  getText = (content, index) => get(content[index], 'content[0].value', '');

  render() {
    const {
      firstname,
      lastname,
      phone,
      emailAddress,
      account_number,
      address,
      city,
      state,
      zipcode,
      isLoading,
    } = this.state;

    const { content } = this.props;
    const disabled = !this.canSubmit();
    return (
      <Form
        className={styles.container}
        onError={this.showError}
        onSubmit={this.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              label={this.getText(content, 3)}
              className={styles.input}
              validators={['required']}
              errorMessages={['']}
              value={firstname}
              onChange={this.handleChange}
              name="firstname"
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              label={this.getText(content, 4)}
              className={styles.input}
              validators={['required']}
              errorMessages={['']}
              value={lastname}
              onChange={this.handleChange}
              name="lastname"
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Input
              label={this.getText(content, 5)}
              className={styles.input}
              validators={['required']}
              errorMessages={['']}
              value={address}
              onChange={this.handleChange}
              name="address"
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={5}>
            <Input
              label={this.getText(content, 6)}
              className={styles.input}
              validators={['required']}
              errorMessages={['']}
              value={city}
              onChange={this.handleChange}
              name="city"
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2} lg={2}>
            <Input
              label={this.getText(content, 7)}
              className={styles.input}
              validators={['required', 'state']}
              errorMessages={['', 'State is not valid']}
              value={state}
              onChange={this.handleChange}
              name="state"
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} sm={5} md={4} lg={2}>
            <Input
              label={this.getText(content, 8)}
              className={styles.input}
              validators={['required', 'zip']}
              errorMessages={['', 'ZIP is not valid']}
              value={zipcode}
              onChange={this.handleChange}
              name="zipcode"
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={8} sm={7} md={6} lg={3}>
            <PhoneInput
              label={this.getText(content, 9)}
              className={styles.input}
              name="phone"
              requiredMessage=""
              value={phone}
              onChange={this.handleChange}
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              label={this.getText(content, 10)}
              className={styles.input}
              validators={['required', 'isEmail']}
              errorMessages={['', 'Email is not valid']}
              value={emailAddress}
              onChange={this.handleChange}
              name="emailAddress"
              disableAsterisk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Input
              label={this.getText(content, 11)}
              className={styles.input}
              validators={['required']}
              errorMessages={['']}
              value={account_number}
              onChange={this.handleChange}
              name="account_number"
              disableAsterisk
              helperText={this.getText(content, 12)}
              disabled={disabled}
            />
          </Grid>
          <Grid item className={styles.formFooter}>
            <Button
              text={this.getText(content, 14)}
              loading={isLoading}
              disabled={disabled}
              className={styles.submit}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

ServiceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  content: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  steps: selectSteps(),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm);
