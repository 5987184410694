import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Div } from 'components/Ui';
import styles from './styles.scss';

const FormError = ({ text, className }) => (
  <Div className={classNames(styles.formError, className)}>{text}</Div>
);

FormError.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

FormError.defaultProps = {
  text: '*Please complete the required fields',
  className: '',
};

export default FormError;
