import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Ui';
import { formatPhone } from 'utils/common';

const PhoneInput = ({
  label,
  id,
  ariaLabel,
  autocomplete,
  name,
  value: defaultValue,
  error,
  onChange,
  disableAsterisk,
  className,
  requiredMessage,
  invalidMessage,
}) => {
  const [phone, changePhone] = useState(formatPhone(defaultValue).formatted);

  const handleChange = ({ target: { value } }) => {
    const formattedPhone = formatPhone(value);
    changePhone(formattedPhone.formatted);
    onChange(formattedPhone.value, name);
  };

  const handleKeypress = (event) => {
    if (!/[0-9/]+/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Input
      id={id}
      ariaLabel={ariaLabel}
      label={label}
      name={name}
      value={phone}
      className={className}
      onChange={handleChange}
      onKeyPress={handleKeypress}
      validators={['required', 'phone']}
      errorMessages={[requiredMessage, invalidMessage]}
      error={error}
      autocomplete={autocomplete}
      disableAsterisk={disableAsterisk}
      inputProps={{
        maxLength: 10,
      }}
    />
  );
};

PhoneInput.propTypes = {
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  autocomplete: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  disableAsterisk: PropTypes.bool,
  className: PropTypes.string,
  requiredMessage: PropTypes.string,
  invalidMessage: PropTypes.string,
};

PhoneInput.defaultProps = {
  requiredMessage: 'Phone is required',
  invalidMessage: 'Phone is not valid, please enter 10 numeric digits',
};

export default PhoneInput;
