import React, { forwardRef, useImperativeHandle } from 'react';

const ReCaptcha = forwardRef(({ siteKey, onVerify }, ref) => {
  useImperativeHandle(ref, () => ({
    executeReCaptcha() {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(siteKey, { action: 'submit' })
            .then((token) => {
              onVerify(token);
            });
        });
      }
    },
  }));

  return <div style={{ display: 'none' }}>Challenge</div>;
});

export default ReCaptcha;
