import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import CostcoMembershipDialog from 'containers/CostcoMembershipDialog';
import LoginButton from 'components/Auth/LoginButton';
import Logo from 'components/ContentfulFields/Logo';
import CartItemCounter from 'components/ContentfulFields/Header/HeaderPoppers/CartPopper/CartItemsCounter';
import { Button } from 'components/Ui';
import { GTM_TRACKING_TAGS } from 'utils/constants';
import COSTCO_LOGO from 'images/Costco.png';
import styles from './styles.scss';

const COSTCO_LOGO_ALT = 'Costco Logo, External Link to Costco Website';

const CostcoNavigation = React.forwardRef((props, ref) => {
  const {
    fields,
    itemsCount,
    logoFields,
    options,
    handleCounterClick,
    hideCostcoMembership,
    showCostcoMembership,
    isShowCostcoMembershipDialog,
  } = props;

  const onClickStartOrder = () => showCostcoMembership();
  const onCloseCostcoMembership = () => hideCostcoMembership();

  const isHome = window.location.pathname === '/';
  const navigation = documentToReactComponents(fields, options);

  const showCartIcon = isHome ? itemsCount > 0 : true;

  return (
    <>
      <div className={classnames(styles.topHeader, styles.costcoHeader)}>
        <div className={styles.text}>{navigation}</div>
        <div className={styles.bottomLine}>
          <div className={styles.left} />
        </div>
      </div>
      <div className={styles.container}>
        <div
          className={classnames(styles.navigation, styles.costcoWater)}
          ref={ref}
          data-tracking={GTM_TRACKING_TAGS.Header}
        >
          <Logo anchor fields={logoFields} className={styles.logo} />
          <nav className={styles.navbar}>
            <div className={styles.tooltips}>
              {isHome && (
                <Button
                  className={styles.costcoBtn}
                  text="Start Order"
                  onClick={onClickStartOrder}
                  color="dark"
                ></Button>
              )}
              <div className={styles.loginWrapper}>
                <LoginButton />
                {showCartIcon && (
                  <CartItemCounter
                    count={itemsCount}
                    onClick={handleCounterClick}
                  />
                )}
              </div>
            </div>
          </nav>
          {window.isCostcoWater && (
            <a href="https://www.costco.com" target="_blank">
              <img
                border="0"
                alt={COSTCO_LOGO_ALT}
                src={COSTCO_LOGO}
                width="118"
                height="36"
              />
            </a>
          )}
        </div>
        <CostcoMembershipDialog
          open={isShowCostcoMembershipDialog}
          onClose={onCloseCostcoMembership}
        />
      </div>
    </>
  );
});

CostcoNavigation.propTypes = {
  fields: PropTypes.object,
  itemsCount: PropTypes.number,
  logoFields: PropTypes.object,
  options: PropTypes.object,
  handleCounterClick: PropTypes.func,
  hideCostcoMembership: PropTypes.func,
  showCostcoMembership: PropTypes.func,
  isShowCostcoMembershipDialog: PropTypes.bool,
};

export default CostcoNavigation;
