import React, { useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';
import find from 'lodash/find';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import injectSaga from 'utils/injectSaga';
import { Button, IconButton } from 'components/Ui';
import { NAMESPACE, actions, saga } from '../state';
import {
  getError,
  getResults,
  getHasFetched,
  getHasLoaded,
} from '../state/selectors';
import HeartIcon from './HeartIcon';
import styles from './styles.scss';

const FavoriteButton = ({
  item,
  dataLayer,
  favorites,
  className,
  minimal,
  hasError,
  hasFetched,
  hasLoaded,
  request,
  toggle,
}) => {
  const initial = !!find(favorites, item);
  const [isFavorite, setIsFavorite] = useState(initial);

  // useState + remote call to mask latency between click & api success
  const toggleIsFavorite = () => {
    const nextState = !isFavorite;
    setIsFavorite(nextState);
    toggle(item, !nextState, dataLayer); // toggle takes (and flips) current state
  };

  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    if (!hasFetched) request();
  }, [hasFetched]);

  useEffect(() => {
    if (hasLoaded) setIsFavorite(!!find(favorites, item));
  }, [hasLoaded, favorites, item]);

  const handleClick = (event) => {
    event.stopPropagation();
    toggleIsFavorite();
  };

  if (hasError) return null;

  const title = `${isFavorite ? 'Remove from' : 'Add to'} favorites`;

  return minimal ? (
    <div className={className}>
      <IconButton
        className={styles.favIcoBtn}
        title={title}
        aria-label={title}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
      >
        <HeartIcon
          className={styles.icon}
          filled={isFavorite || isHovered}
          fill={isFavorite && isHovered ? 'heavySome' : ''}
          stroke="lightGrey"
        />
      </IconButton>
    </div>
  ) : (
    <div className={className}>
      <Button
        text="Favorite"
        aria-label={title}
        className={styles.favBtn}
        icon={
          <HeartIcon
            className={styles.icon}
            filled={isFavorite || isHovered}
            fill={isFavorite && isHovered ? 'heavySome' : ''}
          />
        }
        iconPosition="right"
        loading={!hasFetched}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
      />
    </div>
  );
};

FavoriteButton.propTypes = {
  item: object,
  dataLayer: object,
  favorites: array,
  minimal: bool,
  hasError: bool,
  hasFetched: bool,
  request: func,
  toggle: func,
};

const mapStateToProps = (state) => ({
  favorites: getResults(state),
  hasFetched: getHasFetched(state),
  hasLoaded: getHasLoaded(state),
  hasError: getError(state) !== null,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSaga({ key: NAMESPACE, saga }),
  withImmutablePropsToJS,
)(FavoriteButton);
