import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, filter } from 'lodash';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { MobileStepper, withStyles } from '@material-ui/core';
import { Icon, Div } from 'components/Ui';
import { Mobile, Default } from 'components/Media';
import Link from 'components/ContentfulFields/Link';
import { getImage } from 'images';
import { isSsr } from 'utils/ssrHelper';
import {
  getRegionalBrandName,
  ALL_BRANDS,
  US_BRANDS,
  CA_BRANDS,
} from 'utils/domainHelper';
import {
  injectVariables,
  translateData,
  getPrimoBrandName,
} from 'utils/translation';

import {
  selectAcquisitionVariables,
  selectSsrReqRoute,
} from 'containers/Landing/selectors';
import Button from '../Button';
import styles from './styles.scss';
import jsStyles from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Carousel extends Component {
  state = {
    activeStep: 0,
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  handleImageClick = (event) => {
    const nextStep = parseInt(
      event.currentTarget.attributes.slideindex.value,
      10,
    );

    this.setState({
      activeStep: nextStep,
    });
  };

  handleArrowKeyPress = (event, value, max) => {
    const keyCode = event.keyCode
      ? event.keyCode
      : event.which
      ? event.which
      : event.charCode;
    // eslint-disable-next-line eqeqeq
    if (keyCode == 13 || keyCode == 32) {
      this.handleArrowClick(value, max);
    }
  };

  handleArrowClick = (value, max) => {
    const newValue = this.state.activeStep + value;
    if (newValue < 0 || newValue > max) {
      return;
    }

    this.setState((prevState) => ({
      activeStep: prevState.activeStep + value,
    }));
  };

  isSupportedFeature = (feature) => {
    let supportedBrands = get(feature, 'fields.supportedBrands', 'All Brands');
    supportedBrands =
      supportedBrands === 'US Brands'
        ? US_BRANDS
        : supportedBrands === 'CA Brands'
        ? CA_BRANDS
        : ALL_BRANDS;
    const brandName = getPrimoBrandName();
    return supportedBrands.includes(brandName);
  };

  composeFeature = (feature, index, clickable = false) => {
    const { activeStep } = this.state;

    const currentStep = index === activeStep;
    const imageSrc = getImage(feature, 'fields.images[0].fields.file.url');

    // TODO: aria-label
    const carouselLabel1 = translateData('Click To Move Carousel To', 'C1.1');
    const carouselLabel2 = translateData('Content', 'C1.2');
    return (
      <Div
        slideindex={index}
        onClick={clickable && this.handleImageClick}
        onKeyPress={clickable && this.handleImageClick}
        className={classNames(styles.item, { [styles.active]: currentStep })}
        key={get(feature, 'sys.id')}
        role="tab"
        aria-label={`${carouselLabel1} ${feature.fields.title} ${carouselLabel2}`}
        tabIndex={0}
        aria-selected={currentStep}
        aria-controls="tab-content"
      >
        <img
          src={`${imageSrc}?fm=webp`}
          alt={
            feature.fields.images[0].fields.file.title ||
            `${feature.fields.title} Image`
          }
        />
        <span>{translateData(feature.fields.title, 'C1')}</span>
      </Div>
    );
  };

  render() {
    const {
      state: { activeStep },
      props: { document, theme, classes, acquisitionVariables, ssrReqRoute },
    } = this;

    const regionalBrandName = getRegionalBrandName(ssrReqRoute, 12);

    const carouselOptions = {
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node, children) => (
          <Button className={styles.btn} document={node.data.target}>
            {injectVariables(
              children,
              regionalBrandName,
              acquisitionVariables,
              'C12',
            )}
          </Button>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => {
          const embedded = children.find((child) => child.props);

          if (embedded) {
            return children;
          }

          return (
            <div className={styles.subtitle}>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C13',
              )}
            </div>
          );
        },
        [BLOCKS.HEADING_1]: (node, children) => (
          <h1 className={styles.title}>
            {injectVariables(
              children,
              regionalBrandName,
              acquisitionVariables,
              'C14',
            )}
          </h1>
        ),
        [BLOCKS.EMBEDDED_ASSET]: () => null,
        [BLOCKS.UL_LIST]: (node, children) => (
          <div className={styles.list}>
            {children.map((child) => (
              <div key={child.key} className={styles.listItem}>
                {injectVariables(
                  child.props.children,
                  regionalBrandName,
                  acquisitionVariables,
                  'C15',
                )}
              </div>
            ))}
          </div>
        ),
        [INLINES.HYPERLINK]: (node, children) => {
          const { uri } = node.data;
          return (
            <Link to={uri}>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C16',
              )}
            </Link>
          );
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          const { fields: nodeFields } = node.data.target;
          return (
            <Link className={styles.link} to={`/${nodeFields.slug}`}>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C17',
              )}
            </Link>
          );
        },
      },
    };

    const carouselItemOptions = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <div role="tabpanel">
            {injectVariables(
              children,
              regionalBrandName,
              acquisitionVariables,
              'C18',
            )}
          </div>
        ),
        [INLINES.HYPERLINK]: (node, children) => {
          const uri = get(node, 'data.uri', '/');
          return (
            <Link to={uri}>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C19',
              )}
            </Link>
          );
        },
        [BLOCKS.HEADING_6]: (node, children) => (
          <h6 className={styles.subtext}>
            {injectVariables(
              children,
              regionalBrandName,
              acquisitionVariables,
              'C20',
            )}
          </h6>
        ),
      },
    };
    const isServerRendering = isSsr();
    const topContent =
      !isServerRendering && window.isCostcoWater
        ? documentToReactComponents(
            document && {
              data: {},
              nodeType: 'document',
              content: document.fields.description.content.slice(0, 2),
            },
            carouselOptions,
          )
        : documentToReactComponents(
            document && document.fields.description,
            carouselOptions,
          );

    const features =
      document && filter(document.fields.features, this.isSupportedFeature);
    const maxSteps = features.length;

    const bottomContent =
      !isServerRendering && window.isCostcoWater ? (
        <div className={styles.bottomContent}>
          {documentToReactComponents(
            document && {
              data: {},
              nodeType: 'document',
              content: document.fields.description.content.slice(2, 3),
            },
            carouselOptions,
          )}
        </div>
      ) : null;

    return (
      document && (
        <div className={styles.carouselContainer}>
          <div className={styles.carousel} role="tablist">
            {document && document.fields.title && (
              <h1 className={styles.title}>
                {injectVariables(
                  document.fields.title,
                  regionalBrandName,
                  acquisitionVariables,
                  'C21',
                )}
              </h1>
            )}
            {topContent}
            <Default>
              <div ref={this.imagesRef} className={styles.images}>
                {features.map((feature, index) =>
                  this.composeFeature(feature, index, true),
                )}
              </div>
            </Default>
            <Mobile>
              <div className={styles.explore}>
                <Div
                  role="button"
                  aria-disabled={this.state.activeStep === 0}
                  aria-label="Go to previous carousel panel"
                  onClick={() => this.handleArrowClick(-1, features.length - 1)}
                  onKeyPress={(event) =>
                    this.handleArrowKeyPress(event, -1, features.length - 1)
                  }
                  tabIndex="0"
                >
                  <Icon icon="chevron-left" />
                  <Icon icon="chevron-left" />
                </Div>
                <span>
                  {injectVariables(
                    'Swipe To Explore',
                    regionalBrandName,
                    acquisitionVariables,
                    'C22',
                  )}
                </span>
                <Div
                  role="button"
                  aria-disabled={this.state.activeStep === features.length - 1}
                  aria-label="Go to next carousel panel"
                  onClick={() => this.handleArrowClick(1, features.length - 1)}
                  onKeyPress={(event) =>
                    this.handleArrowKeyPress(event, 1, features.length - 1)
                  }
                  tabIndex="0"
                >
                  <Icon icon="chevron-right" />
                  <Icon icon="chevron-right" />
                </Div>
              </div>
            </Mobile>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              className={styles.swipeable}
              enableMouseEvents
              interval={2000000}
            >
              {features.map((feature, index) => (
                <Fragment key={get(feature, 'sys.id')}>
                  <Mobile>{this.composeFeature(feature, index)}</Mobile>
                  <div className={styles.swipeableFeature} id="tab-content">
                    {documentToReactComponents(
                      feature.fields.description,
                      carouselItemOptions,
                    )}
                  </div>
                </Fragment>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              classes={{
                root: classes.root,
                dot: classes.dot,
                dotActive: classes.dotActive,
                dots: classes.dots,
              }}
            />
            {bottomContent}
          </div>
        </div>
      )
    );
  }
}

Carousel.propTypes = {
  document: PropTypes.object,
  theme: PropTypes.object,
  classes: PropTypes.object,
  acquisitionVariables: PropTypes.array,
  ssrReqRoute: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  acquisitionVariables: selectAcquisitionVariables(),
  ssrReqRoute: selectSsrReqRoute(),
});
const mapDispatchToProps = {};

export default compose(
  withStyles(jsStyles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(Carousel);
