import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { dataLayerPush } from 'utils/tracking';
import { WaterRegionalTheme } from 'components/ThemeProvider';
import { Input, Button, Radio, FormLabel, H2, Div } from 'components/Ui';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { createStructuredSelector } from 'reselect';
import { RadioGroup, withStyles } from '@material-ui/core';
import {
  setSection,
  showBrandSwitchDialog,
  navigateZipRedirect,
  showZipCodeEditPopper,
} from 'containers/Landing/actions';
import { selectPostalCode } from 'containers/UserRegion/selectors';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { selectZipCode } from 'containers/ClientLocationProvider/selectors';
import { selectSection } from 'containers/Landing/selectors';
import styles from './styles.scss';

const MUIStyles = (theme) => ({
  inputError: {
    position: 'absolute',
    top: '-20px',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: `${theme.colors.lightRed} !important`,
  },
  rootLabel: {
    marginTop: '7px',
    color: `${theme.colors.black} !important`,
  },
});

class ZipForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      zip: props.postalCode || props.locationPostalCode || '',
      loading: false,
      changed: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.postalCode !== state.zip) {
      return {
        zip: state.changed ? state.zip : props.postalCode,
      };
    }

    return null;
  }

  changeLoading = (v) => this.setState({ loading: v });

  handleTypeChange = ({ target: { value } }) => {
    this.props.setSection(value);
  };

  handleZipChange = ({ target: { value } }) => {
    if (/^[0-9]{0,5}(?:-[0-9]{0,4})?$/.test(value)) {
      this.setState({ zip: value, changed: true });
    } else {
      value = value.toUpperCase();
      this.setState({ zip: value, changed: true });
    }
  };

  phoneValueFormatter = (a) =>
    `${a.substr(0, 3)}-${a.substr(3, 3)}-${a.substr(6, 4)}`;

  handleSubmit = () => {
    const { zip } = this.state;

    const {
      postalCode,
      submitAction,
      navigateZipRedirect: navigateZipRedirectAction,
      showZipCodeEditPopper: showZipCodeEditPopperAction,
    } = this.props;

    if (postalCode !== zip) {
      dataLayerPush(
        'Acquisition',
        {
          event: 'Zip Change',
          zip,
        },
        'dlA6',
      );
    }

    if (submitAction) {
      submitAction();
      showZipCodeEditPopperAction(false);
      localStorage.setItem('showPopper', false);
    }
    if (isAcquisitionDomain()) {
      localStorage.setItem('confirmedPostalCode', zip);
    }
    navigateZipRedirectAction({ zip, stay: true });
  };

  render() {
    const { zip, loading } = this.state;
    const { section, classes } = this.props;

    return (
      <ValidatorForm
        onError={() => {}}
        onSubmit={this.handleSubmit}
        className={styles.zipForm}
      >
        <H2 className={styles.title}>Where should we deliver to?</H2>
        <Div className={styles.subtitle}>
          We need your ZIP code so we can show you products in your area.
        </Div>
        <Input
          label="Enter your ZIP code:"
          validators={['required', 'zip']}
          errorMessages={[
            'Zip code is required',
            'Please enter a valid ZIP code',
          ]}
          className={styles.zipInput}
          classes={classes}
          value={zip}
          name="zip"
          onChange={this.handleZipChange}
        />
        <WaterRegionalTheme>
          <FormLabel label="This is for:" />
          <RadioGroup
            name="section"
            className={styles.radioGroup}
            value={section}
            onChange={this.handleTypeChange}
            row
          >
            <Radio value="home" label="Home" />
            <Radio value="office" label="Office" />
          </RadioGroup>
        </WaterRegionalTheme>
        <Button text="Confirm" loading={loading} className={styles.submit} />
      </ValidatorForm>
    );
  }
}

ZipForm.propTypes = {
  postalCode: PropTypes.string,
  locationPostalCode: PropTypes.string,
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
  setSection: PropTypes.func.isRequired,
  navigateZipRedirect: PropTypes.func,
  showZipCodeEditPopper: PropTypes.func,
  section: PropTypes.string,
};

ZipForm.defaultProps = {
  postalCode: null,
  locationPostalCode: null,
};

const mapStateToProps = createStructuredSelector({
  postalCode: selectPostalCode(),
  locationPostalCode: selectZipCode(),
  section: selectSection(),
});

const mapDispatchToProps = {
  setSection,
  showBrandSwitchDialog,
  navigateZipRedirect,
  showZipCodeEditPopper,
};

export default compose(
  withRouter,
  withStyles(MUIStyles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(ZipForm);
