import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { isSelfServeDomain, getRegionalBrandName } from 'utils/domainHelper';
import { isSsr } from 'utils/ssrHelper';
import Leads from './Leads';
import Header from './Header';
import Banner from './Banner';
import Footer from './Footer';
import Quiz from './Quiz/Loadable';
import Slider from './Slider';
import Icon from './Icon/Loadable';
import Button from './Button';
import SliderItem from './Slider/Item';
import ProductsList from './FiltrationProductsList';
import ProductCard from './FiltrationProductsList/Item';
import Carousel from './Carousel';
import Page from './Page';
import FAQ from './FAQ';
import Tabs from './Tabs';
import CollapsedBanner from './CollapseBanner';
import ProductCategory from './ProductCategory';
import PromotionBanner from './PromotionBanner';
import ProductsFeature from './ProductsFeature';
import CostcoMembershipBanner from './CostcoMembershipBanner';
import NonServicableRestrictionsBanner from './NonServicableRestrictionBanner';
import NonServicablePartnerBanner from './NonServicablePartnerBanner';
import AcquisitionNonServicablePartnerBanner from './AcquisitionNonServicablePartnerBanner';
import CostcoPricingBanner from './CostcoPricingBanner';
import PrimoTransitionBanner from './PrimoTransitionBanner';
import PromoBanner from './PromoBanner';
import SelfServeBanner from './SelfServeBanner';
import SelfServePromo from './SelfServePromo';

const ContentfulFields = (props, { stylesTheme }) => {
  const { type, document, showRedeemDialog, ...rest } = props;
  const [isCostcoWater, setIsCostcoWater] = useState(false);
  const [hasComponentLoaded, setHasComponentLoaded] = useState(false);
  useEffect(() => {
    if (!isSsr()) {
      setIsCostcoWater(window.isCostcoWater);
      setHasComponentLoaded(true);
    }
  }, []);

  const entryTitle =
    document && document.fields && document.fields.entryTitle
      ? document.fields.entryTitle
      : '';

  if (entryTitle.includes('Contact us > Content')) {
    const regionalBrandName = getRegionalBrandName(null, 16);
    const brands = ['US Brands', 'Labrador Source', 'Canadian Springs'];
    const index = Math.max(brands.indexOf(regionalBrandName), 0);
    const nodeType = get(document, `fields.content.content[${index}].nodeType`);
    if (nodeType === 'embedded-entry-block') {
      const content = get(
        document,
        `fields.content.content[${index}].data.target.fields.content.content`,
      );
      if (content) {
        document.fields.content.content = content;
      }
    }
    return (
      <Banner
        stylesTheme={stylesTheme}
        document={document}
        entryTitle={entryTitle}
      />
    );
  }
  if (entryTitle.startsWith('Costco > ')) {
    if (entryTitle === 'Costco > Generic > Home > member lookup') {
      return (
        <CostcoMembershipBanner document={document} stylesTheme={stylesTheme} />
      );
    }

    if (entryTitle === 'Costco > Generic > Home > Costco Pricing') {
      return (
        <CostcoPricingBanner stylesTheme={stylesTheme} document={document} />
      );
    }

    if (entryTitle === 'Costco > Generic > Non Serviceable > Restrictions') {
      return (
        <NonServicableRestrictionsBanner
          document={document}
          stylesTheme={stylesTheme}
        />
      );
    }

    if (
      entryTitle ===
      'Costco > Generic > Non Serviceable > Absopure > Top Banner'
    ) {
      return (
        <Banner
          stylesTheme={stylesTheme}
          document={document}
          entryTitle={entryTitle}
        />
      );
    }

    if (entryTitle === 'Costco > Generic > Non Serviceable > Absopure > 2') {
      return (
        <NonServicablePartnerBanner
          document={document}
          stylesTheme={stylesTheme}
          entryTitle={entryTitle}
        />
      );
    }

    if (entryTitle === 'Costco > Generic > Non Serviceable > Premium Waters') {
      return (
        <Banner
          stylesTheme={stylesTheme}
          document={document}
          entryTitle={entryTitle}
        />
      );
    }

    if (entryTitle === 'Costco > Generic > Home > Transition') {
      return <PrimoTransitionBanner document={document} />;
    }
    if (entryTitle.includes('PromoBanner')) {
      return <PromoBanner document={document} />;
    }

    if (
      entryTitle ===
      'Costco > Generic > Non Serviceable > Premium Waters, Inc > 2'
    ) {
      return (
        <NonServicablePartnerBanner
          document={document}
          stylesTheme={stylesTheme}
          entryTitle={entryTitle}
        />
      );
    }
  }

  if (entryTitle === 'Acquisition > Generic > Non Serviceable > Partner > 2') {
    return (
      <AcquisitionNonServicablePartnerBanner
        document={document}
        stylesTheme={stylesTheme}
        entryTitle={entryTitle}
      />
    );
  }

  if (type === 'banner' && isSelfServeDomain()) {
    const index = entryTitle.indexOf('> Landing');
    const str = entryTitle.substring(index + 2);
    switch (str) {
      case 'Landing Page > Welcome > 1':
        document.fields.className = 'welcome';
        break;
      case 'Landing Page > Main Banner > 2':
        document.fields.className = 'main-banner';
        break;
      case 'Landing Page > Greater control with more delivery options > 3':
        document.fields.className = 'delivery-options';
        break;
      case 'Landing Page > Know when your order’s coming > 4':
        document.fields.className = 'know-when-your-orders-coming';
        break;
      case 'Landing Page > Convenience at your fingertips > 5':
        document.fields.className = 'convenience-at-your-fingertips';
        break;
      case 'Landing Page > Let’s get started > 6':
        document.fields.className = 'lets-get-started';
        break;
      default:
        break;
    }
  }

  if (entryTitle.includes('Support > Main Banner')) {
    // TODO: Temporary remove this banner until it is verified and sytyled correctly
    return null;
  }

  switch (type) {
    case 'header':
      return (
        <Header document={document} hasComponentLoaded={hasComponentLoaded} />
      );
    case 'banner':
      return (
        <Banner
          stylesTheme={stylesTheme}
          document={document}
          entryTitle={entryTitle}
        />
      );
    case 'leads':
      return <Leads content={document} />;
    case 'footer':
      return <Footer isCostcoWater={isCostcoWater} document={document} />;
    case 'quiz':
      return <Quiz document={document} />;
    case 'slider':
      return <Slider document={document} />;
    case 'icon':
      return <Icon document={document} />;
    case 'button':
      return <Button document={document} />;
    case 'sliderItem':
      return <SliderItem document={document} />;
    case 'productsList':
      return <ProductsList document={document} />;
    case 'productCard':
      return <ProductCard document={document} />;
    case 'page':
      return <Page document={document} />;
    case 'faq':
      return <FAQ document={document} {...rest} />;
    case 'carousel':
      return <Carousel document={document} />;
    case 'collapseBanner':
      return <CollapsedBanner stylesTheme={stylesTheme} document={document} />;
    case 'tabs':
      return <Tabs document={document} {...rest} />;
    case 'productCategory':
      return <ProductCategory document={document} stylesTheme={stylesTheme} />;
    case 'promotionBanner':
      return (
        <PromotionBanner
          document={document}
          stylesTheme={stylesTheme}
          showRedeemDialog={
            showRedeemDialog ||
            (() => {
              /* TODO mmm */
            })
          }
        />
      );
    case 'featuredProducts':
      return <ProductsFeature document={document} />;
    case 'selfServeBanner':
      return <SelfServeBanner document={document} />;
    case 'selfServePromo':
      return <SelfServePromo document={document} />;
    default:
      return null;
  }
};

ContentfulFields.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default ContentfulFields;
