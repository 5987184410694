import { ButtonBase, Collapse } from '@material-ui/core';
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import {
  compact,
  find,
  includes,
  isEmpty,
  isEqual,
  map,
  omit,
  toUpper,
} from 'lodash';

import { Button, Span } from 'components/Ui';
import PropTypes from 'prop-types';
import arrow from 'images/arrowDown.svg';
import classNames from 'classnames';
import { parseSearchFilter } from 'containers/PrimoProducts/helpers';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { dataLayerPush } from 'utils/tracking';
import { getToUrl } from 'utils/translation';
import styles from './styles.scss';

class FilterCollapse extends PureComponent {
  state = {
    filterClicked: false,
    expanded: false,
    items: [],
    filterItems: [],
  };

  static getDerivedStateFromProps(props, state) {
    const filterClicked =
      props.name === 'Category' ? state.filterClicked : true;

    if (!filterClicked || !isEqual(props.items, state.items)) {
      const filterItems = FilterCollapse.markCheckedFromSearch(props);
      const isChecked = !!find(filterItems, 'checked');

      return {
        filterItems,
        items: props.items,
        expanded: isChecked,
        filterClicked: false,
      };
    }

    return {
      filterClicked: false,
    };
  }

  static markCheckedFromSearch = (props) => {
    const filters = parseSearchFilter(props.location, props.isAuthenticated);
    const filterBy = toUpper(filters[props.filterKey]);

    return map(props.items, (item) => {
      const filterByCat = filterBy && includes(filterBy, toUpper(item.value));
      const filterByNone = !(filterBy || item.value);

      return { ...item, checked: filterByCat || filterByNone };
    });
  };

  handleExpand = () =>
    this.setState((prevState) => ({
      filterClicked: true,
      expanded: !prevState.expanded,
    }));

  handleCheck = ({ value }) => {
    this.setState(
      (prevState) => ({
        filterClicked: true,
        filterItems: prevState.filterItems.map((item) => {
          if (this.props.multi) {
            return item.value === value
              ? { ...item, checked: !item.checked }
              : item;
          }
          return item.value === value
            ? { ...item, checked: !item.checked || !value }
            : { ...item, checked: false };
        }),
      }),
      this.applyFilter,
    );
  };

  handleClear = (e) => {
    e.stopPropagation();
    this.setState(
      (prevState) => ({
        filterClicked: true,
        filterItems: prevState.filterItems.map((item) => ({
          ...item,
          checked: !item.value,
        })),
      }),
      this.applyFilter,
    );
  };

  applyFilter = () => {
    const isCategoryFilter = this.props.name === 'Category';
    const parsed = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const filterValue = compact(
      map(
        this.state.filterItems.filter((item) => item.checked),
        'value',
      ),
    );
    const newSearch = {
      [this.props.filterKey]: filterValue,
    };

    const searchObject = !isEmpty(filterValue)
      ? { ...parsed, ...newSearch }
      : omit(parsed, this.props.filterKey);

    if (this.props.isAuthenticated) {
      const searchString = isCategoryFilter
        ? qs.stringify(
            {
              categoryId: searchObject.categoryId,
              serviceLocationId: searchObject.serviceLocationId,
            },
            { arrayFormat: 'repeat' },
          )
        : qs.stringify(searchObject, {
            arrayFormat: 'repeat',
          });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: searchString,
      });
    } else {
      const searchString = isCategoryFilter
        ? qs.stringify(
            {
              serviceLocationId: searchObject.serviceLocationId,
            },
            { arrayFormat: 'repeat' },
          )
        : qs.stringify(searchObject, {
            arrayFormat: 'repeat',
          });

      const cid = searchObject.categoryId ? `/${searchObject.categoryId}` : '';
      const pathname = getToUrl(`/products${cid}`, 'U64');
      this.props.history.push({
        pathname,
        search: searchString,
      });
    }

    const category = Array.isArray(searchObject.categoryId)
      ? searchObject.categoryId[0]
      : searchObject.categoryId || 'All products';

    const brand = isCategoryFilter
      ? []
      : searchObject.brands
      ? searchObject.brands
      : [];

    const waterTypes = isCategoryFilter
      ? []
      : searchObject.waterTypes
      ? searchObject.waterTypes
      : [];
    if (this.props.isAuthenticated) {
      const event = this.props.shopDeliveryOrder
        ? 'future-delivery-product-list-filter'
        : 'product-list-filter';

      const section = this.props.shopDeliveryOrder
        ? 'future delivery product list'
        : 'delivery product list';

      dataLayerPush(
        'SelfServe',
        {
          event,
          section,
          category,
          brand: Array.isArray(brand) ? brand : [brand],
          'water-type': Array.isArray(waterTypes) ? waterTypes : [waterTypes],
        },
        'dlS34',
      );
    } else {
      dataLayerPush(
        'Acquisition',
        {
          event: 'product-list-filter',
          category,
          brand: Array.isArray(brand) ? brand : [brand],
          'water-type': Array.isArray(waterTypes) ? waterTypes : [waterTypes],
        },
        'dlA27',
      );
    }
  };

  renderCheckSign = (checked) => {
    if (this.props.multi) {
      return (
        <div
          className={classNames(styles.square, { [styles.checked]: checked })}
        />
      );
    }

    return (
      <div
        className={classNames(styles.circle, { [styles.checked]: checked })}
      />
    );
  };

  isSomethingChecked = () => {
    const isChecked = this.state.filterItems.some((item) => item.checked);
    const filterValues = compact(
      map(
        this.state.filterItems.filter((item) => item.checked),
        'value',
      ),
    );

    return isChecked && !isEmpty(filterValues);
  };

  render() {
    const { expanded, filterItems } = this.state;
    const { name } = this.props;

    return (
      <div
        data-tracking={name}
        className={classNames(styles.filterCollapse, {
          [styles.expanded]: expanded,
        })}
      >
        <div className={styles.header}>
          <ButtonBase
            className={styles.actionBlock}
            onClick={this.handleExpand}
            aria-expanded={expanded ? 'true' : 'false'}
          >
            <Span className={styles.name}>{name}</Span>
            <img
              className={classNames({ [styles.expanded]: expanded })}
              src={arrow}
              alt=""
            />
          </ButtonBase>
          {this.isSomethingChecked() && (
            <Button
              link
              text="Clear"
              onClick={this.handleClear}
              className={styles.clear}
            />
          )}
        </div>
        <Collapse in={expanded}>
          {filterItems.map((item) => (
            <ButtonBase
              key={item.value}
              className={styles.filterItem}
              onClick={() => this.handleCheck(item)}
            >
              <Span className={styles.label} defaultText={'DT'}>
                {item.label}
              </Span>
              {this.renderCheckSign(item.checked)}
            </ButtonBase>
          ))}
        </Collapse>
      </div>
    );
  }
}

FilterCollapse.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  multi: PropTypes.bool,
  filterKey: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

FilterCollapse.defaultProps = {
  multi: false,
};

export default withRouter(FilterCollapse);
