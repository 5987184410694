import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { dataLayerPush } from 'utils/tracking';
import { translateData } from 'utils/translation';
import cartImg from 'images/Cart.svg';
import styles from './styles.scss';

const CartItemCounter = ({ count = 0, onClick }) => {
  useEffect(() => {
    dataLayerPush(
      'Acquisition',
      {
        event: 'cart count',
        cart: {
          has_items: (count > 0),
          count,
        },
      },
    );
  }, [count]);

  return (
    <ButtonBase
      onClick={onClick}
      className={styles.counter}
      aria-label={`${count} ${translateData('item(s) in your cart')}`}
    >
      <img src={cartImg} alt="" />
      {count > 0 && (
        <div className={styles.badge} aria-hidden="true">
          {count}
        </div>
      )}
    </ButtonBase>
  );
};

CartItemCounter.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
};

CartItemCounter.defaultProps = {
  onClick: () => {},
};

export default CartItemCounter;
