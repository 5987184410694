import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import { getRegionalBrandName } from 'utils/domainHelper';
import { injectVariables, getToUrl } from 'utils/translation';
import { dataLayerPush } from 'utils/tracking';
import { PROMO_NAME } from 'containers/QuickShop/constants';
import { Button } from 'components/Ui';
import { addPromotion } from 'containers/Cart/actions';
import {
  selectPromotionId,
  selectCartLoading,
  selectCartId,
} from 'containers/Cart/selectors';
import {
  selectPageSlug,
  selectAcquisitionVariables,
  selectSsrReqRoute,
} from 'containers/Landing/selectors';
import { selectIsAuthenticated } from 'containers/Authentication/selectors';
import history from 'utils/history';
import { withPromo, PROMOS } from 'components/Promos';
import { getContentOptions } from '../CollapseBanner/parserOptions';

class PromotionBanner extends Component {
  componentDidMount = () => {
    const { promotionId, cartId } = this.props;
    if (promotionId === null && !cartId) {
      dataLayerPush(
        'Acquisition',
        {
          ecommerce: {
            promoView: {
              promotions: [
                {
                  name: PROMO_NAME,
                  position: 'Special Offers Bar',
                },
              ],
            },
          },
        },
        'dlA32',
      );
    }
  };

  render() {
    const {
      document,
      stylesTheme,
      promotionId,
      isCartLoading,
      pageSlug,
      showRedeemDialog,
      isAuthenticated,
      acquisitionVariables,
      ssrReqRoute,
    } = this.props;
    const regionalBrandName = getRegionalBrandName(ssrReqRoute, 6);
    const isOnProductListPage =
      pageSlug && pageSlug.indexOf('product-list') > -1;
    const styles = require(`./${stylesTheme}.scss`);
    const applyPromotion = () => {
      // mmm ppp promotionClick - Done dlA28 - Home > Promotion Banner > Redeem
      dataLayerPush(
        'Acquisition',
        {
          event: 'promotionClick',
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  name: PROMO_NAME,
                  position: 'Special Offers Bar',
                },
              ],
            },
          },
        },
        'dlA28',
        'PUSH_ADD_PROMOTION_DATA',
        showRedeemDialog,
      );
      this.props.addPromotion();
      history.push(getToUrl('/quick-shop/select-water'));
    };

    const options = {
      renderNode: {
        ...Object.assign(getContentOptions(styles, document).renderNode, {
          [BLOCKS.HEADING_1]: (node, children) => (
            <h1 className={styles.title}>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C24',
              )}
            </h1>
          ),
          [BLOCKS.HEADING_2]: (node, children) => (
            <h2 className={styles.title}>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C25',
              )}
            </h2>
          ),
          [BLOCKS.PARAGRAPH]: (node, children) => (
            <div className={styles.subtitle}>
              {injectVariables(
                children,
                regionalBrandName,
                acquisitionVariables,
                'C26',
              )}
            </div>
          ),
          [INLINES.EMBEDDED_ENTRY]: (node) => {
            const id = get(node, 'data.target.sys.contentType.sys.id');

            switch (id) {
              case 'button':
                return (
                  <Button
                    defaultText={'0'}
                    className={classnames(styles.btn, {
                      [styles.hidden]: promotionId,
                    })}
                    document={node.data.target}
                    text={node.data.target.fields.text}
                    onClick={applyPromotion}
                    loading={isCartLoading}
                  />
                );
              default:
                return null;
            }
          },
        }),
      },
    };

    const content = documentToReactComponents(
      document && document.fields.content,
      options,
    );

    return (
      !isAuthenticated &&
      document && (
        <div
          className={setTheme(
            [
              styles.promotionBanner,
              isOnProductListPage ? styles.isOnProductListPage : '',
            ],
            document,
            styles,
          )}
        >
          <div className={styles.content}>
            <div className={styles.controlledContent}>
              <div className={styles.descriptionWrapper}>{content}</div>
            </div>
          </div>
        </div>
      )
    );
  }
}

PromotionBanner.propTypes = {
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
  promotionId: PropTypes.number,
  addPromotion: PropTypes.func,
  isCartLoading: PropTypes.bool,
  pageSlug: PropTypes.string,
  showRedeemDialog: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  acquisitionVariables: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  promotionId: selectPromotionId(),
  cartId: selectCartId(),
  isCartLoading: selectCartLoading(),
  pageSlug: selectPageSlug(),
  isAuthenticated: selectIsAuthenticated(),
  acquisitionVariables: selectAcquisitionVariables(),
  ssrReqRoute: selectSsrReqRoute(),
});

const mapDispatchToProps = {
  addPromotion,
};

export default withPromo(PROMOS.FullPressGuided)(
  connect(mapStateToProps, mapDispatchToProps)(PromotionBanner),
);
