import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get, isEmpty, some, forEach, cloneDeep, isString } from 'lodash';
import uuid from 'uuid/v4';
import classnames from 'classnames';
import { useAuth0 } from 'auth0Provider';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { setTheme } from 'utils/themeHelper';
import styled from 'styled-components';
import { isSelfServeDomain, getRegionalBrandName } from 'utils/domainHelper';
import { injectVariables, getToUrl } from 'utils/translation';
import {
  selectAcquisitionVariables,
  selectPromotionLandingPageData,
  selectSsrReqRoute,
} from 'containers/Landing/selectors';
import { linkParser } from 'components/ContentfulFields/utils/fieldParsers';
import Link from 'components/ContentfulFields/Link';
import ProductsList from 'components/ContentfulFields/ProductsList';
import FormsMapper from 'components/ContentfulFields/FormsMapper';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import {
  showCostcoMembershipDialog,
  navigateZipRedirect,
  navigateLandingPageLink,
} from 'containers/Landing/actions';
import HiddenContentSpan from 'components/Ui/ScreenReader';
import { isSsr } from 'utils/ssrHelper';
import { makeOneTrustTogglesOn } from 'utils/hacks';
import { GreenCheckMark, RedCheckMark, download } from 'images';
import { Section } from 'components/Ui';
import Button from '../Button';
import UlItem from '../UlItem';
import Features from './Features';
import Animated from '../Animated';
import Counter from '../Counter';
import SprawlingImage from '../SprawlingImage';
import staticStyles from './styles/static.scss';
import costcoStyles from './styles/costcoOverRide.scss';

const Banner = ({
  document,
  stylesTheme,
  entryTitle,
  showCostcoMembership,
  acquisitionVariables,
  promotionLandingPageData,
  navigateZipRedirectAction,
  navigateLandingPageLinkAction,
  ssrReqRoute,
}) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    makeOneTrustTogglesOn('[href="/#privacy-prefs"]');
  });

  const styles = require(`./styles/${stylesTheme}.scss`);
  const animation = get(document, 'fields.animation');
  const disableLazyLoad = get(document, 'fields.disableLazyLoad', false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const regionalBrandName = getRegionalBrandName(ssrReqRoute, 2);

  const bannerEntryTitle = entryTitle || '';
  const str = bannerEntryTitle.toLowerCase();
  const shouldRenderAsItIs =
    str.includes('privacy statement') ||
    str.includes('cookie policy') ||
    str.includes('terms of use') ||
    str.includes('terms and conditions') ||
    str.includes('privacy policy') ||
    str.includes('ccpa');
  const specialCases =
    str.includes('equipment manuals') ||
    str.includes('contact us') ||
    str.includes('privacy statement') ||
    str.includes('cookie policy') ||
    str.includes('terms of use') ||
    str.includes('terms and conditions') ||
    str.includes('california transparency') ||
    str.includes('no promotions') ||
    str.includes('ccpa');
  const isAcquisitionLanding = !!(
    promotionLandingPageData && promotionLandingPageData.path
  );
  const isSelfServe = isSelfServeDomain();
  const costcoWaterBanner = bannerEntryTitle.startsWith('Costco >');
  const costcoWaterEquipment =
    bannerEntryTitle === 'Costco > Generic > Home > Equipment';
  const costcoWaterDontLiftFinger =
    bannerEntryTitle === "Costco > Generic > Home > Don't Lift a finger >1";

  const onClickStartOrder = () => showCostcoMembership();
  const onClickLoginToNewSite = () => loginWithRedirect();
  const isServerRendering = isSsr();
  const onClickLandingPageShop = () => navigateZipRedirectAction({});
  const onClickLandingPageLink = (link) =>
    navigateLandingPageLinkAction({ link });

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <b key={`${text}-key`}>
          {injectVariables(text, regionalBrandName, acquisitionVariables, 'C1')}
        </b>
      ),
      [MARKS.CODE]: (node) => {
        if (node.startsWith('{html:')) {
          return (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: node.substring(6, node.length - 1),
              }}
            />
          );
        }
        return <sub>{node}</sub>;
      },
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'button':
            if (
              !isServerRendering &&
              window.isCostcoWater &&
              node.data.target.fields.text === 'Start Order'
            ) {
              delete node.data.target.fields.link;
              if (node.data.target.fields.color === 'primary') {
                return (
                  <Button
                    className={classnames(styles.btn, costcoStyles.costcoBtn)}
                    document={node.data.target}
                    onClick={onClickStartOrder}
                  >
                    {children}
                  </Button>
                );
              }
              if (node.data.target.fields.color === 'dark') {
                return (
                  <Button
                    className={classnames(
                      styles.btn,
                      costcoStyles.costcoDarkBtn,
                    )}
                    document={node.data.target}
                    onClick={onClickStartOrder}
                  >
                    {children}
                  </Button>
                );
              }
            }
            return (
              <Button
                className={classnames(styles.btn)}
                document={node.data.target}
              >
                {children}
              </Button>
            );
          case 'banner':
            return (
              <Banner
                stylesTheme={stylesTheme}
                document={node.data.target}
                entryTitle={entryTitle}
                key={node.data.target.sys.id}
                showCostcoMembership={showCostcoMembership}
                acquisitionVariables={acquisitionVariables}
              />
            );
          default:
            return null;
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        const embeddedType = get(embedded, 'type.name');

        if (embedded) {
          if (costcoWaterEquipment) {
            children[1].props.children[0] = (
              <span className={styles.link}>
                <DescriptionIcon />
                <span className={styles.label}>
                  {children[1].props.children[0]}
                </span>
              </span>
            );
          }
          const childrenMapped = children.map((child) =>
            isString(child)
              ? injectVariables(
                  child,
                  regionalBrandName,
                  acquisitionVariables,
                  'C2',
                )
              : child,
          );
          return (
            <div
              key={`Link${new Date().getTime() + Math.random()}`}
              className={classnames({
                [styles.subtitle]: embeddedType === 'Link',
                [styles.pdfLink]: costcoWaterEquipment,
              })}
            >
              {costcoWaterEquipment && (
                <HiddenContentSpan>
                  External Document Link Container
                </HiddenContentSpan>
              )}
              {childrenMapped}
            </div>
          );
        }

        const childrenWithContent = children.filter(
          (childContent) => childContent !== '',
        );

        if (isEmpty(childrenWithContent)) return null;

        return (
          <div
            key={uuid()}
            className={setTheme(styles.subtitle, document, styles)}
          >
            {childrenWithContent.map(
              (child) =>
                child &&
                child
                  .split('\n')
                  .filter((textRow) => textRow.length > 0)
                  .map((textRow) => (
                    <div key={uuid()}>
                      {injectVariables(
                        textRow,
                        regionalBrandName,
                        acquisitionVariables,
                        'C3',
                      )}
                    </div>
                  )),
            )}
          </div>
        );
      },
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className={styles.quote}>{children}</blockquote>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 key={uuid()} className={setTheme(styles.title, document, styles)}>
          {injectVariables(
            children,
            regionalBrandName,
            acquisitionVariables,
            'C4',
          )}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className={setTheme(styles.title, document, styles)}>
          {injectVariables(
            children,
            regionalBrandName,
            acquisitionVariables,
            'C5',
          )}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={setTheme(styles.title, document, styles)}>
          {injectVariables(
            children,
            regionalBrandName,
            acquisitionVariables,
            'C6',
          )}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className={setTheme(styles.title, document, styles)}>
          {injectVariables(
            children,
            regionalBrandName,
            acquisitionVariables,
            'C7',
          )}
        </h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol type="a" className={setTheme(styles.list, document, styles)}>
          {children.map((child) => (
            <li
              key={child.key}
              className={setTheme(styles.listItem, document, styles)}
            >
              {child.props.children}
            </li>
          ))}
        </ol>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');
        const iconName = get(node, 'data.target.fields.iconName', '');

        switch (id) {
          case 'icon':
            switch (iconName) {
              case 'GreenCheckMark':
                return (
                  <img
                    src={GreenCheckMark}
                    alt="Green Check Mark"
                    className={styles.checkMark}
                  />
                );
              case 'RedCheckMark':
                return (
                  <img
                    src={RedCheckMark}
                    alt="Red Check Mark"
                    className={styles.checkMark}
                  />
                );
              case 'download':
                return (
                  <img
                    src={download}
                    alt="download"
                    className={styles.download}
                  />
                );
              default:
                return null;
            }

          case 'brandedContent':
            // eslint-disable-next-line no-case-declarations
            const brandedContentEntryTitle = get(
              node,
              'data.target.fields.entryTitle',
              '',
            );
            if (brandedContentEntryTitle.includes(regionalBrandName)) {
              return (
                <>
                  {get(node, 'data.target.fields.content.content').map((item) =>
                    documentToReactComponents(
                      item,
                      shouldRenderAsItIs ? undefined : options,
                    ),
                  )}
                </>
              );
            }
            return null;
          case 'ulItem': {
            const { fields } = node.data.target;
            fields.content = injectVariables(
              fields.content,
              regionalBrandName,
              acquisitionVariables,
              'C8',
            );
            return <UlItem {...node.data.target} />;
          }
          case 'button':
            if (isAcquisitionLanding) {
              if (node.data.target.fields.text === 'Shop') {
                delete node.data.target.fields.link;
                return (
                  <Button
                    className={styles.btn}
                    document={node.data.target}
                    onClick={onClickLandingPageShop}
                  >
                    {children}
                  </Button>
                );
              }
              if (node.data.target.fields.text === 'See Equipment') {
                const target = cloneDeep(node.data.target);
                const { link } = target.fields;
                delete target.fields.link;
                return (
                  <Button
                    className={styles.btn}
                    document={target}
                    onClick={() => onClickLandingPageLink(getToUrl(link))}
                  >
                    {children}
                  </Button>
                );
              }
            }
            if (
              !isServerRendering &&
              window.isCostcoWater &&
              node.data.target.fields.text === 'Start Order'
            ) {
              delete node.data.target.fields.link;
              if (node.data.target.fields.color === 'primary') {
                return (
                  <Button
                    className={classnames(styles.btn, costcoStyles.costcoBtn)}
                    document={node.data.target}
                    onClick={onClickStartOrder}
                  >
                    {children}
                  </Button>
                );
              }
              if (node.data.target.fields.color === 'dark') {
                return (
                  <Button
                    className={classnames(
                      styles.btn,
                      costcoStyles.costcoDarkBtn,
                    )}
                    document={node.data.target}
                    onClick={onClickStartOrder}
                  >
                    {children}
                  </Button>
                );
              }
            }
            if (
              isSelfServe &&
              node.data.target.fields.text === 'Login to New Site'
            ) {
              return (
                <Button
                  className={styles.btn}
                  document={node.data.target}
                  onClick={onClickLoginToNewSite}
                >
                  {children}
                </Button>
              );
            }
            return (
              <Button className={styles.btn} document={node.data.target}>
                {children}
              </Button>
            );
          case 'form':
            return (
              <FormsMapper
                type={node.data.target.fields.type}
                document={document}
              />
            );
          case 'counter':
            return (
              <Counter className={styles.counter} document={node.data.target} />
            );
          case 'sprawlingImage':
            return <Fragment />;
          default:
            return null;
        }
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const uri = get(node, 'data.uri', '/');
        if (uri.startsWith('tel:')) {
          node.data.uri = injectVariables(
            node.data.uri,
            regionalBrandName,
            acquisitionVariables,
            'C32',
          );
        }

        return linkParser(node, children);
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const { fields: nodeFields } = node.data.target;
        return (
          <Link className={styles.link} to={`/${nodeFields.slug}`}>
            {children}
          </Link>
        );
      },
    },
  };

  const imagesOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <div>{children}</div>,
      [BLOCKS.LIST_ITEM]: (node, children) => <div>{children}</div>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (!get(node, 'data.target.fields.title', false)) {
          return null;
        }

        const {
          fields: { file, description },
        } = node.data.target;

        return (
          <div className={styles.embeddedAsset}>
            <Animated animation={animation}>
              <LazyLoadImage
                visibleByDefault={disableLazyLoad}
                src={`${file.url}?fm=webp`}
                alt={description}
              />
            </Animated>
          </div>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const id = get(node, 'data.target.sys.contentType.sys.id');

        switch (id) {
          case 'sprawlingImage':
            return (
              <div className={styles.embeddedAsset}>
                <Animated animation={animation}>
                  <SprawlingImage document={node.data.target} />
                </Animated>
              </div>
            );
          default:
            return null;
        }
      },
      [BLOCKS.HEADING_1]: () => null,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const embedded = children.find((child) =>
          child ? child.props : false,
        );

        const hasLinks = some(node.content, ['nodeType', 'hyperlink']);

        if (embedded && !hasLinks) {
          return children;
        }

        return null;
      },
      [BLOCKS.HEADING_2]: () => null,
      [BLOCKS.HEADING_3]: () => null,
    },
  };

  let brandedImages;
  let foundBrandedImage = false;
  const fieldsContent = get(document, 'fields.content.content');
  forEach(fieldsContent, (item) => {
    const contentType = get(item, 'data.target.sys.contentType.sys.id');
    if (!foundBrandedImage && contentType === 'brandedImages') {
      const fieldsEntryTitle = get(item, 'data.target.fields.entryTitle');
      if (
        fieldsEntryTitle.includes('AllBrands') ||
        fieldsEntryTitle.includes(regionalBrandName)
      ) {
        foundBrandedImage = true;
        brandedImages = documentToReactComponents(
          get(item, 'data.target.fields.content'),
          imagesOptions,
        );
      }
    }
  });

  const images =
    brandedImages ||
    documentToReactComponents(
      document && document.fields.content,
      imagesOptions,
    );
  const bannerTop = documentToReactComponents(
    document && document.fields.content,
    shouldRenderAsItIs
      ? {
          renderNode: {
            [INLINES.EMBEDDED_ENTRY]: (node) => {
              const id = get(node, 'data.target.sys.contentType.sys.id');
              switch (id) {
                case 'brandedContent':
                  // eslint-disable-next-line no-case-declarations
                  const brandedContentEntryTitle = get(
                    node,
                    'data.target.fields.entryTitle',
                    '',
                  );
                  if (brandedContentEntryTitle.includes(regionalBrandName)) {
                    return (
                      <>
                        {get(
                          node,
                          'data.target.fields.content.content',
                        ).map((item) => documentToReactComponents(item))}
                      </>
                    );
                  }
                  return null;
                default:
                  return null;
              }
            },
          },
        }
      : options,
  );
  const bottomLine = documentToReactComponents(
    document && document.fields.bottomContent,
    options,
  );

  const renderStaticContent = () => {
    let content;

    if (
      costcoWaterBanner &&
      (entryTitle.includes('Privacy Policy') ||
        entryTitle.includes('Terms of use') ||
        entryTitle.includes('Cookie Policy') ||
        entryTitle.includes('CCPA') ||
        entryTitle.includes('Service Agreement'))
    ) {
      content = [];
      let start = 0;
      let end = 0;

      document.fields.content.content.forEach((item) => {
        if (item.nodeType === 'heading-2' || item.nodeType === 'heading-3') {
          let ariaTextLabelOne = '';
          let ariaTextLabelTwo = '';
          if (document.fields.content.content[start].content[0]) {
            ariaTextLabelOne =
              document.fields.content.content[start].content[0].value;
          }
          if (document.fields.content.content[start].content[1]) {
            ariaTextLabelTwo =
              document.fields.content.content[start].content[1].value;
          }
          const documentComponents = documentToReactComponents(
            document && {
              data: {},
              nodeType: 'document',
              content: document.fields.content.content.slice(start, end),
            },
          );
          content.push(
            <Section
              className={staticStyles.staticSection}
              aria-label={
                ariaTextLabelOne.length > 1
                  ? ariaTextLabelOne
                  : ariaTextLabelTwo
              }
            >
              {documentComponents}
            </Section>,
          );

          start = end;
          end = start + 1;
        } else {
          end += 1;
        }
      });

      const ariaTextLabel =
        document.fields.content.content[start].content[0].value;
      const documentComponents = documentToReactComponents(
        document && {
          data: {},
          nodeType: 'document',
          content: document.fields.content.content.slice(start, end),
        },
      );
      content.push(
        <Section
          className={staticStyles.staticSection}
          aria-label={ariaTextLabel}
        >
          {documentComponents}
        </Section>,
      );
    }

    return content;
  };

  const renderPartnerContent = () => {
    let content;

    if (
      costcoWaterBanner &&
      bannerTop &&
      isMobile &&
      bannerEntryTitle ===
        'Costco > Generic > Non Serviceable > Absopure > Top Banner'
    ) {
      content = (
        <Fragment>
          <div className={styles.descriptionWrapper}>{bannerTop}</div>
          <div className={styles.images}>{images}</div>
        </Fragment>
      );
    }

    return content;
  };

  const renderEquipmentContent = () => {
    let content;

    if (costcoWaterEquipment) {
      const localBannerTop = documentToReactComponents(
        document && {
          data: {},
          nodeType: 'document',
          content: document.fields.content.content.slice(0, 2),
        },
        options,
      );

      const pdfLinks = (
        <div className={styles.pdfLinksContainer}>
          {documentToReactComponents(
            document && {
              data: {},
              nodeType: 'document',
              content: document.fields.content.content.slice(2, 5),
            },
            options,
          )}
        </div>
      );

      const bannerBottom = documentToReactComponents(
        document && {
          data: {},
          nodeType: 'document',
          content: document.fields.content.content.slice(5),
        },
        options,
      );

      content = (
        <Fragment>
          <div className={styles.images}>{images}</div>
          <div className={styles.descriptionWrapper}>
            {localBannerTop}
            <Features
              animation={animation}
              stylesTheme={stylesTheme}
              document={document.fields.features}
              regionalBrandName={regionalBrandName}
              variables={acquisitionVariables}
            />
            {!isMobile && pdfLinks}
            {bannerBottom}
            {isMobile && pdfLinks}
          </div>
        </Fragment>
      );
    }

    return content;
  };

  const renderDontLiftFingerContent = () => {
    let content;

    if (costcoWaterDontLiftFinger) {
      const banner = documentToReactComponents(
        document && {
          data: {},
          nodeType: 'document',
          content: document.fields.content.content.slice(0),
        },
        options,
      );
      content = (
        <div className={styles.dontLiftFingerContent}>
          {isMobile && images}
          <div className={styles.banner}>{banner}</div>
          {!isMobile && images}
        </div>
      );
    }

    return content;
  };

  const dontLiftFingerContent = renderDontLiftFingerContent();

  const equipmentContent = renderEquipmentContent();

  const staticContent = renderStaticContent();

  const partnerContent = renderPartnerContent();

  const showNormalBanner = !(
    dontLiftFingerContent ||
    partnerContent ||
    staticContent ||
    equipmentContent
  );

  const bannerClasses = classnames(
    setTheme(styles.banner, document, styles),
    { [styles.costcoWaterEquipmentBanner]: costcoWaterEquipment },
    { [styles.costcoWaterBanner]: !costcoWaterEquipment && costcoWaterBanner },
    { [styles.selfServeBanner]: !specialCases && isSelfServe },
    { [styles.acquisitionLandingBanner]: isAcquisitionLanding },
  );

  let backgroundImage;
  const brandedBackgroundImages = get(
    document,
    'fields.brandedBackgroundImages',
  );
  if (brandedBackgroundImages) {
    forEach(brandedBackgroundImages, (item) => {
      const fieldsTitle = item.fields.title;
      if (
        !backgroundImage &&
        (fieldsTitle.includes(regionalBrandName) ||
          fieldsTitle.includes('AllBrands'))
      ) {
        backgroundImage = `url(${item.fields.file.url}?fm=webp)`;
      }
    });
  } else {
    backgroundImage =
      document.fields.backgroundImage &&
      `url(${
        document.fields.backgroundImage &&
        document.fields.backgroundImage.fields.file.url
      }?fm=webp)`;
  }

  const styledComponent = get(document, 'fields.styledComponent', '');

  const StyledBanner = styled.section`
    ${styledComponent}
    white-space: pre-wrap;
  `;

  return (
    document && (
      <StyledBanner
        className={bannerClasses}
        style={{
          backgroundImage,
        }}
      >
        {get(
          document,
          'fields.backgroundImage.fields.file.contentType',
          '',
        ).includes('video') && (
          <div className={styles.backgroundVideo}>
            <video autoPlay muted loop>
              <source
                src={document.fields.backgroundImage.fields.file.url}
                type={document.fields.backgroundImage.fields.file.contentType}
              />
            </video>
          </div>
        )}
        <div
          className={classnames(styles.content, {
            [staticStyles.staticContent]: staticContent,
          })}
        >
          {dontLiftFingerContent && dontLiftFingerContent}
          {partnerContent && partnerContent}
          {staticContent && staticContent}
          {equipmentContent && equipmentContent}
          {showNormalBanner && (
            <Fragment>
              <div className={styles.images}>{images}</div>
              <div className={styles.descriptionWrapper}>
                {bannerTop}
                <Features
                  animation={animation}
                  stylesTheme={stylesTheme}
                  document={document.fields.features}
                  regionalBrandName={regionalBrandName}
                  variables={acquisitionVariables}
                />
                <ProductsList
                  stylesTheme={stylesTheme}
                  products={document.fields.products}
                />
                {bottomLine}
              </div>
            </Fragment>
          )}
        </div>
      </StyledBanner>
    )
  );
};

Banner.propTypes = {
  document: PropTypes.object,
  stylesTheme: PropTypes.string,
  entryTitle: PropTypes.string,
  showCostcoMembership: PropTypes.func,
  acquisitionVariables: PropTypes.array,
  navigateZipRedirectAction: PropTypes.func,
  navigateLandingPageLinkAction: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  acquisitionVariables: selectAcquisitionVariables(),
  promotionLandingPageData: selectPromotionLandingPageData(),
  ssrReqRoute: selectSsrReqRoute(),
});
const mapDispatchToProps = {
  showCostcoMembership: showCostcoMembershipDialog,
  navigateZipRedirectAction: navigateZipRedirect,
  navigateLandingPageLinkAction: navigateLandingPageLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
