import at from 'lodash/at';
import camelCase from 'lodash/camelCase';

export const BRAND_IDS = {
  ALHAMBRA: 'Alhambra',
  CANADIAN: 'Canadian Springs',
  CRYSTALR: 'Crystal Rock',
  CRYSTALS: 'Crystal Springs',
  DEEPROCK: 'Deep Rock',
  HINCKLEY: 'Hinckley Springs',
  KENTWOOD: 'Kentwood Springs',
  LABRADOR: 'Labrador Source',
  MTOLYMPUS: 'Mount Olympus',
  PRIMO: 'Primo',
  SIERRA: 'Sierra Springs',
  SPARKLETTS: 'Sparkletts',
};

const {
  ALHAMBRA,
  CANADIAN,
  CRYSTALR,
  CRYSTALS,
  DEEPROCK,
  HINCKLEY,
  KENTWOOD,
  LABRADOR,
  MTOLYMPUS,
  PRIMO,
  SIERRA,
  SPARKLETTS,
} = BRAND_IDS;

const SOLO_BRAND_TAGS = Object.values(BRAND_IDS).reduce(
  (acc, id) => ({ ...acc, [id]: camelCase(id) }),
  {},
);

const MULTI_BRAND_TAGS = {
  BRANDS_US: 'brandsUS',
  BRANDS_CA: 'brandsCA',
  BRANDS_ALL: 'brandsAll',
};

const BRAND_TAGS = {
  ...SOLO_BRAND_TAGS,
  ...MULTI_BRAND_TAGS,
};

export const BRANDS = {
  [ALHAMBRA]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', ALHAMBRA),
    legacyId: 'alhambrawater', // ui modified; apis returns "alhambra"
  },
  [CANADIAN]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_CA', CANADIAN),
    legacyId: 'canadiansprings',
  },
  [CRYSTALR]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', CRYSTALR),
    legacyId: 'crystalrock',
  },
  [CRYSTALS]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', CRYSTALS),
    legacyId: 'crystal-springs',
  },
  [DEEPROCK]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', DEEPROCK),
    legacyId: 'deeprockwater', // ui modified; apis returns "deeprock"
  },
  [HINCKLEY]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', HINCKLEY),
    legacyId: 'hinckleysprings',
  },
  [KENTWOOD]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', KENTWOOD),
    legacyId: 'kentwoodsprings',
  },
  [LABRADOR]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_CA', LABRADOR),
    legacyId: 'labradorsource',
  },
  [MTOLYMPUS]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', MTOLYMPUS),
    legacyId: 'mountolympuswater',
  },
  [PRIMO]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', PRIMO),
    legacyId: 'primo',
  },
  [SIERRA]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', SIERRA),
    legacyId: 'sierrasprings',
  },
  [SPARKLETTS]: {
    tags: at(BRAND_TAGS, 'BRANDS_ALL', 'BRANDS_US', SPARKLETTS),
    legacyId: 'sparkletts',
  },
};
