import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ContentfulFields from 'components/ContentfulFields';
import { SkipToMainContent } from 'components/Ui/ScreenReader';
import { contentTypes } from 'containers/Landing/constants';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { translateData } from 'utils/translation';

class BaseLayout extends PureComponent {
  componentDidMount() {
    const {
      site: { components },
      fetchSite,
      fetchPage,
    } = this.props;

    const isAcquisition = isAcquisitionDomain();

    if (
      !isAcquisition &&
      !components.header &&
      !components.footer &&
      !components.leads
    ) {
      fetchSite({ contentType: contentTypes.domain });
      fetchPage({ key: 'home' });
    }
  }

  render() {
    const {
      site: {
        components: { header, footer, leads },
      },
      name,
    } = this.props;

    return (
      <>
        <SkipToMainContent>
          <a className="skip" href="#main-content">
            {translateData('Skip To Main Content')}
          </a>
        </SkipToMainContent>
        <ContentfulFields
          type={header && header.sys.contentType.sys.id}
          document={header}
        />
        <main id="main-content" data-tracking={name}>
          {this.props.children}
        </main>
        <ContentfulFields
          type={leads && leads.sys.contentType.sys.id}
          document={leads}
        />
        <ContentfulFields
          type={footer && footer.sys.contentType.sys.id}
          document={footer}
        />
      </>
    );
  }
}

BaseLayout.propTypes = {
  site: PropTypes.object,
  children: PropTypes.object,
  fetchSite: PropTypes.func.isRequired,
  fetchPage: PropTypes.func.isRequired,
};

export default BaseLayout;
