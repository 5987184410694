export const PROFILE_PAGE_KEY = 'primoProfile';
export const NAMESPACE = PROFILE_PAGE_KEY;

export const LOAD_CREDIT_CARDS = `${PROFILE_PAGE_KEY}/LOAD_CREDIT_CARDS`;
export const LOAD_CREDIT_CARDS_SUCCESS = `${PROFILE_PAGE_KEY}/LOAD_CREDIT_CARDS_SUCCESS`;
export const LOAD_CREDIT_CARDS_FAILURE = `${PROFILE_PAGE_KEY}/LOAD_CREDIT_CARDS_FAILURE`;

export const LOAD_BANK_ACCOUNTS = `${PROFILE_PAGE_KEY}/LOAD_BANK_ACCOUNTS`;
export const LOAD_BANK_ACCOUNTS_SUCCESS = `${PROFILE_PAGE_KEY}/LOAD_BANK_ACCOUNTS_SUCCESS`;
export const LOAD_BANK_ACCOUNTS_FAILURE = `${PROFILE_PAGE_KEY}/LOAD_BANK_ACCOUNTS_FAILURE`;

export const SET_CURRENT_PAYMENT_METHOD = `${PROFILE_PAGE_KEY}/SET_CURRENT_PAYMENT_METHOD`;
export const SET_CURRENT_PAYMENT_METHOD_SUCCESS = `${PROFILE_PAGE_KEY}/SET_CURRENT_PAYMENT_METHOD_SUCCESS`;
export const SET_CURRENT_PAYMENT_METHOD_FAILURE = `${PROFILE_PAGE_KEY}/SET_CURRENT_PAYMENT_METHOD_FAILURE`;

export const FETCH_PAYMENT_METHODS_REQUEST = `${PROFILE_PAGE_KEY}/FETCH_PAYMENT_METHODS_REQUEST`;
export const FETCH_PAYMENT_METHODS_SUCCESS = `${PROFILE_PAGE_KEY}/FETCH_PAYMENT_METHODS_SUCCESS`;
export const FETCH_PAYMENT_METHODS_FAILURE = `${PROFILE_PAGE_KEY}/FETCH_PAYMENT_METHODS_FAILURE`;
export const FETCH_PAYMENT_METHODS_RESET = `${PROFILE_PAGE_KEY}/FETCH_PAYMENT_METHODS_RESET`;

export const GET_BILLING_SUMMARY = `${PROFILE_PAGE_KEY}/GET_BILLING_SUMMARY`;
export const GET_BILLING_SUMMARY_SUCCESS = `${PROFILE_PAGE_KEY}/GET_BILLING_SUMMARY_SUCCESS`;
export const GET_BILLING_SUMMARY_FAILURE = `${PROFILE_PAGE_KEY}/GET_BILLING_SUMMARY_FAILURE`;

export const ADD_CREDIT_CARD = `${PROFILE_PAGE_KEY}/ADD_CREDIT_CARD`;
export const ADD_CREDIT_CARD_SUCCESS = `${PROFILE_PAGE_KEY}/ADD_CREDIT_CARD_SUCCESS`;
export const ADD_CREDIT_CARD_FAILURE = `${PROFILE_PAGE_KEY}/ADD_CREDIT_CARD_FAILURE`;

export const UPDATE_CREDIT_CARD = `${PROFILE_PAGE_KEY}/UPDATE_CREDIT_CARD`;
export const UPDATE_CREDIT_CARD_SUCCESS = `${PROFILE_PAGE_KEY}/UPDATE_CREDIT_CARD_SUCCESS`;
export const UPDATE_CREDIT_CARD_FAILURE = `${PROFILE_PAGE_KEY}/UPDATE_CREDIT_CARD_FAILURE`;

export const DELETE_CREDIT_CARD = `${PROFILE_PAGE_KEY}/DELETE_CREDIT_CARD`;
export const DELETE_CREDIT_CARD_SUCCESS = `${PROFILE_PAGE_KEY}/DELETE_CREDIT_CARD_SUCCESS`;
export const DELETE_CREDIT_CARD_FAILURE = `${PROFILE_PAGE_KEY}/DELETE_CREDIT_CARD_FAILURE`;

export const ADD_BANK_ACCOUNT = `${PROFILE_PAGE_KEY}/ADD_BANK_ACCOUNT`;
export const ADD_BANK_ACCOUNT_SUCCESS = `${PROFILE_PAGE_KEY}/ADD_BANK_ACCOUNT_SUCCESS`;
export const ADD_BANK_ACCOUNT_FAILURE = `${PROFILE_PAGE_KEY}/ADD_BANK_ACCOUNT_FAILURE`;

export const UPDATE_BANK_ACCOUNT = `${PROFILE_PAGE_KEY}/UPDATE_BANK_ACCOUNT`;
export const UPDATE_BANK_ACCOUNT_SUCCESS = `${PROFILE_PAGE_KEY}/UPDATE_BANK_ACCOUNT_SUCCESS`;
export const UPDATE_BANK_ACCOUNT_FAILURE = `${PROFILE_PAGE_KEY}/UPDATE_BANK_ACCOUNT_FAILURE`;

export const DELETE_BANK_ACCOUNT = `${PROFILE_PAGE_KEY}/DELETE_BANK_ACCOUNT`;
export const DELETE_BANK_ACCOUNT_SUCCESS = `${PROFILE_PAGE_KEY}/DELETE_BANK_ACCOUNT_SUCCESS`;
export const DELETE_BANK_ACCOUNT_FAILURE = `${PROFILE_PAGE_KEY}/DELETE_BANK_ACCOUNT_FAILURE`;

export const SHOW_STATEMENT_SETTINGS = `${PROFILE_PAGE_KEY}/SHOW_STATEMENT_SETTINGS`;
export const HIDE_STATEMENT_SETTINGS = `${PROFILE_PAGE_KEY}/HIDE_STATEMENT_SETTINGS`;
export const SHOW_STATEMENT_SETTINGS_SUCCESS = `${PROFILE_PAGE_KEY}/SHOW_STATEMENT_SETTINGS_SUCCESS`;
export const HIDE_STATEMENT_SETTINGS_SUCCESS = `${PROFILE_PAGE_KEY}/HIDE_STATEMENT_SETTINGS_SUCCESS`;

export const GET_STATEMENT_SETTINGS = `${PROFILE_PAGE_KEY}/GET_STATEMENT_SETTINGS`;
export const GET_STATEMENT_SETTINGS_SUCCESS = `${PROFILE_PAGE_KEY}/GET_STATEMENT_SETTINGS_SUCCESS`;
export const GET_STATEMENT_SETTINGS_FAILURE = `${PROFILE_PAGE_KEY}/GET_STATEMENT_SETTINGS_FAILURE`;

export const UPDATE_STATEMENT_SETTINGS = `${PROFILE_PAGE_KEY}/UPDATE_STATEMENT_SETTINGS`;
export const UPDATE_STATEMENT_SETTINGS_SUCCESS = `${PROFILE_PAGE_KEY}/UPDATE_STATEMENT_SETTINGS_SUCCESS`;
export const UPDATE_STATEMENT_SETTINGS_FAILURE = `${PROFILE_PAGE_KEY}/UPDATE_STATEMENT_SETTINGS_FAILURE`;

export const GET_BILLING_ACCOUNT = `${PROFILE_PAGE_KEY}/GET_BILLING_ACCOUNT`;
export const GET_BILLING_ACCOUNT_SUCCESS = `${PROFILE_PAGE_KEY}/GET_BILLING_ACCOUNT_SUCCESS`;
export const GET_BILLING_ACCOUNT_FAILURE = `${PROFILE_PAGE_KEY}/GET_BILLING_ACCOUNT_FAILURE`;

export const LOAD_BILLING_ACCOUNTS = `${PROFILE_PAGE_KEY}/LOAD_BILLING_ACCOUNTS`;
export const LOAD_BILLING_ACCOUNTS_SUCCESS = `${PROFILE_PAGE_KEY}/LOAD_BILLING_ACCOUNTS_SUCCESS`;
export const LOAD_BILLING_ACCOUNTS_FAILURE = `${PROFILE_PAGE_KEY}/LOAD_BILLING_ACCOUNTS_FAILURE`;

export const RESET_ADD_CARD = `${PROFILE_PAGE_KEY}/RESET_ADD_CARD`;
export const RESET_PAYMENT_REMOVAL = `${PROFILE_PAGE_KEY}/RESET_PAYMENT_REMOVAL`;
export const UPDATE_AUTO_PAY = `${PROFILE_PAGE_KEY}/UPDATE_AUTO_PAY`;
export const UPDATE_AUTO_PAY_SUCCESS = `${PROFILE_PAGE_KEY}/UPDATE_AUTO_PAY_SUCCESS`;
export const UPDATE_AUTO_PAY_FAILURE = `${PROFILE_PAGE_KEY}/UPDATE_AUTO_PAY_FAILURE`;

export const CLOSE_INVOICE_DRAWER = `${PROFILE_PAGE_KEY}/CLOSE_INVOICE_DRAWER`;
export const CLOSE_INVOICE_DRAWER_SUCCESS = `${PROFILE_PAGE_KEY}/CLOSE_INVOICE_DRAWER_SUCCESS`;

export const CLOSE_USER_DETAILS_DRAWER = `${PROFILE_PAGE_KEY}/CLOSE_USER_DETAILS_DRAWER`;
export const CLOSE_USER_DETAILS_DRAWER_SUCCESS = `${PROFILE_PAGE_KEY}/CLOSE_USER_DETAILS_DRAWER_SUCCESS`;

export const CLOSE_REMOVE_EMAIL_DRAWER = `${PROFILE_PAGE_KEY}/CLOSE_REMOVE_EMAIL_DRAWER`;
export const CLOSE_REMOVE_EMAIL_DRAWER_SUCCESS = `${PROFILE_PAGE_KEY}/CLOSE_REMOVE_EMAIL_DRAWER_SUCCESS`;

export const CLOSE_ADD_AUTHORIZED_USER_DRAWER = `${PROFILE_PAGE_KEY}/CLOSE_ADD_AUTHORIZED_USER_DRAWER`;
export const CLOSE_ADD_AUTHORIZED_USER_DRAWER_SUCCESS = `${PROFILE_PAGE_KEY}/CLOSE_ADD_AUTHORIZED_USER_DRAWER_SUCCESS`;

export const REMOVE_ONE_TIME_CREDIT_CARD = `${PROFILE_PAGE_KEY}/REMOVE_ONE_TIME_CREDIT_CARD`;

export const UPDATE_CUSTOMER_NOTES = `${PROFILE_PAGE_KEY}/UPDATE_CUSTOMER_NOTES`;
export const UPDATE_CUSTOMER_NOTES_SUCCESS = `${PROFILE_PAGE_KEY}/UPDATE_CUSTOMER_NOTES_SUCCESS`;
export const UPDATE_CUSTOMER_NOTES_FAILURE = `${PROFILE_PAGE_KEY}/UPDATE_CUSTOMER_NOTES_FAILURE`;

export const FETCH_CUSTOMER_NOTES = `${PROFILE_PAGE_KEY}/FETCH_CUSTOMER_NOTES`;
export const FETCH_CUSTOMER_NOTES_SUCCESS = `${PROFILE_PAGE_KEY}/FETCH_CUSTOMER_NOTES_SUCCESS`;
export const FETCH_CUSTOMER_NOTES_FAILURE = `${PROFILE_PAGE_KEY}/FETCH_CUSTOMER_NOTES_FAILURE`;

export const FETCH_DELIVERY_NOTES = `${PROFILE_PAGE_KEY}/FETCH_DELIVERY_NOTES`;
export const FETCH_DELIVERY_NOTES_SUCCESS = `${PROFILE_PAGE_KEY}/FETCH_DELIVERY_NOTES_SUCCESS`;
export const FETCH_DELIVERY_NOTES_FAILURE = `${PROFILE_PAGE_KEY}/FETCH_DELIVERY_NOTES_FAILURE`;

export const CLOSE_EDIT_BILLING_DRAWER = `${PROFILE_PAGE_KEY}/CLOSE_EDIT_BILLING_DRAWER`;
export const CLOSE_EDIT_BILLING_DRAWER_SUCCESS = `${PROFILE_PAGE_KEY}/CLOSE_EDIT_BILLING_DRAWER_SUCCESS`;
export const RESET_PAY_MY_BILL_ERROR = `${PROFILE_PAGE_KEY}/RESET_PAY_MY_BILL_ERROR`;
export const RESET_BILLING_INFORMATION_ERROR = `${PROFILE_PAGE_KEY}/RESET_BILLING_INFORMATION_ERROR`;
export const CLOSE_CUSTOMER_NOTES_DRAWER = `${PROFILE_PAGE_KEY}/CLOSE_CUSTOMER_NOTES_DRAWER`;
export const CLOSE_CUSTOMER_NOTES_DRAWER_SUCCESS = `${PROFILE_PAGE_KEY}/CLOSE_CUSTOMER_NOTES_DRAWER_SUCCESS`;

export const UPDATE_BILLING_ADDRESS = 'checkout/UPDATE_BILLING_ADDRESS';
export const UPDATE_BILLING_ADDRESS_SUCCESS =
  'checkout/UPDATE_BILLING_ADDRESS_SUCCESS';
export const UPDATE_BILLING_ADDRESS_FAILURE =
  'checkout/UPDATE_BILLING_ADDRESS_FAILURE';

export const UPDATE_PASSWORD = 'checkout/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'checkout/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'checkout/UPDATE_PASSWORD_FAILURE';

export const DELETE_BILLING_ACCOUNT = 'checkout/DELETE_BILLING_ACCOUNT';
export const DELETE_BILLING_ACCOUNT_SUCCESS =
  'checkout/DELETE_BILLING_ACCOUNTSUCCESS';
export const DELETE_BILLING_ACCOUNT_FAILURE =
  'checkout/DELETE_BILLING_ACCOUNT_FAILURE';

export const UPDATE_USER_DETAILS = 'checkout/UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_SUCCESS =
  'checkout/UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAILURE =
  'checkout/UPDATE_USER_DETAILS_FAILURE';

export const ADD_AUTHORIZED_USER = 'checkout/ADD_AUTHORIZED_USER';
export const ADD_AUTHORIZED_USER_SUCCESS =
  'checkout/ADD_AUTHORIZED_USER_SUCCESS';
export const ADD_AUTHORIZED_USER_FAILURE =
  'checkout/ADD_AUTHORIZED_USER_FAILURE';
