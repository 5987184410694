import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from 'moment';
import {
  selectDefaultServiceLocationId,
  selectUserId,
  selectUserEmail,
} from 'containers/Authentication/selectors';
import { updateWith, get, isEmpty, orderBy } from 'lodash';
import qs from 'qs';
import cookies from 'js-cookie';
import { apiCall } from 'utils/swaggerClient';
import {
  badgeTypes,
  calendarDateToType,
  serviceTypeToBadge,
  typesPriority,
} from 'components/Calendar/constants';
import { fetchImage } from 'utils/contentfulUtils';
import ContentfulClient from 'utils/ContentfulClient';
import { completeProductItemsWithNonContentfulSaga } from 'containers/PrimoProducts/saga';
import { makeSelectLocation } from 'containers/App/selectors';
import { parseSearch } from 'containers/PrimoProducts/helpers';
import { selectCurrentPaymentMethodId } from 'containers/PrimoProfile/selectors';
import { waitServiceLocation } from 'containers/PrimoProfile/saga';
import { HIDE_DRAWER, SHOW_DRAWER } from 'containers/DrawerManager/constants';
import { GET_BILLING_SUMMARY } from 'containers/PrimoProfile/constants';
import {
  setSection,
  setHeaderFooter,
  getLogoSuccess,
  getLogoFailure,
} from 'containers/Landing/actions';
import { getBillingSummary } from 'containers/PrimoProfile/actions';
import {
  selectHeaderFooter,
  selectLanguage,
} from 'containers/Landing/selectors';
import {
  throwErrorTest,
  handleError,
  getErrorMessage,
} from 'utils/errorHandling';

import { isSsr } from 'utils/ssrHelper';
import { isSelfServeDomain } from 'utils/domainHelper';
import { dataLayerPushSuccess, dataLayerPushFailure } from 'utils/tracking';
import { getLocale } from 'utils/translation';
import * as types from './constants';
import {
  loadServiceLocation,
  selectServiceLocation as selectServiceLocationAction,
} from './actions';
import {
  selectServiceLocations,
  selectSelectedServiceLocation,
  selectSelectedServiceLocationId,
  selectBillingAccountId,
  selectNextDelivery,
  selectCustomerSummary,
  selectIsSelectedServiceIndefiniteHold,
  selectSelectedServiceLocationStateOrProvinceCode,
} from './selectors';
const SERVICE_LEVEL_SERVICE = 'SERVICE';
const SERVICE_LEVEL_CUSTOMER = 'CUSTOMER';
const contentfulClient = new ContentfulClient();

const filterDeliveryItems = (delivery) =>
  get(delivery, 'items').filter((item) => get(item, 'itemType', '') !== 'PR');

function* fetchUserServiceLocations() {
  try {
    throwErrorTest('fetchUserServiceLocations');
    const userId = yield select(selectUserId());
    const userServiceLocations = yield call(apiCall, {
      operationId: 'Users.getUserServiceAndBillingLocations',
      parameters: { userId },
    });

    const getServiceLocationsTitle = (brandName) => {
      switch (brandName.toLowerCase()) {
        case 'primo':
          return 'Primo > Logo';
        case 'crystal springs':
          return 'Crystal Springs > Logo (Regionalized)';
        case 'crystal rock':
          return 'Crystal Rock > Logo (Regionalized)';
        case 'alhambra':
          return 'Alhambra > Logo (Regionalized)';
        case 'deep rock water':
          return 'Deep Rock > Logo (Regionalized)';
        case 'hinckley springs':
          return 'Hinckley Springs > Logo (Regionalized)';
        case 'kentwood springs':
          return 'Kentwood > Logo (Regionalized)';
        case 'mount olympus':
          return 'Mount Olympus > Logo (Regionalized)';
        case 'sierra springs':
          return 'Sierra Springs > Logo (Regionalized)';
        case 'sparkletts':
          return 'Sparkletts > Logo (Regionalized)';
        case 'canadian springs':
          return 'Canadian Springs > Logo (Regionalized)';
        case 'labrador source':
          return 'Labrador Source > Logo (Regionalized)';
        default:
          return 'DS Services Logo';
      }
    };
    const { billingLocations, serviceLocations = [] } = userServiceLocations;
    serviceLocations.forEach((loc) => {
      loc.title = getServiceLocationsTitle(loc.brandName);
    });
    const location = yield select(makeSelectLocation());
    const search = parseSearch(location);
    const defaultServiceLocationId = yield select(
      selectDefaultServiceLocationId(),
    );

    yield put({
      type: types.LOAD_SERVICE_LOCATIONS_SUCCESS,
      payload: { billingLocations, serviceLocations },
    });

    let serviceLocationId = null;
    if (!isEmpty(serviceLocations)) {
      if (defaultServiceLocationId) {
        const defaultServiceLocation = serviceLocations.find(
          (userServiceLocation) =>
            userServiceLocation.id === String(defaultServiceLocationId),
        );
        if (defaultServiceLocation) {
          serviceLocationId = defaultServiceLocation.id;
        } else {
          serviceLocationId = serviceLocations[0].id;
        }
      } else {
        serviceLocationId = serviceLocations[0].id;
      }
    }

    if (serviceLocationId) {
      if (search.serviceLocationId) {
        const validServiceLocation = serviceLocations.find(
          (item) => item.id === search.serviceLocationId,
        );
        serviceLocationId = validServiceLocation
          ? validServiceLocation.id
          : serviceLocationId;
      }

      yield put(selectServiceLocationAction({ serviceLocationId }));

      if (search.serviceLocationId !== serviceLocationId) {
        yield put(
          push(
            `${location.pathname}?${qs.stringify({
              ...search,
              serviceLocationId,
            })}`,
          ),
        );
      }
    } else {
      yield put(selectServiceLocationAction({ serviceLocationId: null }));
    }
  } catch (error) {
    yield handleError(
      'fetchUserServiceLocations',
      types.LOAD_SERVICE_LOCATIONS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* loadCustomerAndServiceSummary(payload) {
  const { serviceLocationId, serviceLevel } = payload;

  if (!serviceLocationId) {
    return null;
  }

  let result = yield call(apiCall, {
    operationId: 'ServiceLocations.getServiceLocationCustomerAndServiceSummary',
    parameters: { serviceLocationId, serviceLevel },
  });

  if (serviceLevel === SERVICE_LEVEL_SERVICE) {
    result = result.serviceSummary;
  } else if (serviceLevel === SERVICE_LEVEL_CUSTOMER) {
    result = result.customerSummary;
  }
  return result;
}

function* selectServiceLocation({
  payload: { serviceLocationId, useAsDefault, drawerId },
}) {
  try {
    throwErrorTest('selectServiceLocation');
    yield put({
      type: types.GET_CUSTOMER_SUMMARY_REQUEST,
      payload: { serviceLocationId },
    });
    const serviceLocation = yield call(loadCustomerAndServiceSummary, {
      serviceLocationId,
      serviceLevel: SERVICE_LEVEL_SERVICE,
    });
    const mainData = (yield select(selectServiceLocations())).find(
      (location) => location.id === serviceLocationId,
    );
    yield fetchLogo(mainData.title);

    if (useAsDefault) {
      const userId = yield select(selectUserId());
      const requestBody = { defaultServiceLocationId: serviceLocationId };
      yield call(apiCall, {
        operationId: 'Users.updateDefaultLocation',
        parameters: { userId },
        options: { requestBody },
      });
    }

    yield put({
      type: types.SELECT_SERVICE_LOCATION_SUCCESS,
      payload: { ...serviceLocation, ...mainData },
    });
    yield put({
      type: types.GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_REQUEST,
      payload: { serviceLocationId },
    });
    yield put(getBillingSummary());

    const location = yield select(makeSelectLocation());
    const search = parseSearch(location);
    const updatedSearch = !isEmpty(search)
      ? qs.stringify({ ...search, serviceLocationId })
      : qs.stringify({ serviceLocationId });

    yield put({ type: HIDE_DRAWER, payload: { pageTitle: drawerId } });
    yield put(push(`${location.pathname}?${updatedSearch}`));
  } catch (error) {
    yield handleError(
      'selectServiceLocation',
      types.SELECT_SERVICE_LOCATION_FAILURE,
      getErrorMessage(error),
    );
  }
}

export function* getOrFetchServiceLocation() {
  const loc = yield select(selectSelectedServiceLocation());
  if (loc) return loc;

  yield put(loadServiceLocation());
  yield take(types.SELECT_SERVICE_LOCATION_SUCCESS);
  return yield select(selectSelectedServiceLocation());
}

export function* getOrFetchServiceLocationId() {
  yield call(getOrFetchServiceLocation);
  return yield select(selectSelectedServiceLocationId());
}

export function* fetchLogo(payload) {
  try {
    const logo = yield call(fetchImage, contentfulClient, payload);
    yield put(getLogoSuccess(logo));
  } catch (error) {
    yield put(getLogoFailure(error));
  }
}

function* fetchMobileAppBillPayData() {
  try {
    throwErrorTest('fetchMobileAppBillPayData');
    const userId = cookies.get('userId');
    const serviceLocationId = cookies.get('shipTo');

    if (!userId || !serviceLocationId) {
      yield put({
        type: types.LOAD_MOBILE_APP_BILL_PAY_DATA_FAILURE,
        payload: 'UserId or shipTo is missing',
      });
      return;
    }

    const userServiceLocations = yield call(apiCall, {
      operationId: 'Users.getUserServiceAndBillingLocations',
      parameters: { userId },
    });
    const { serviceLocations } = userServiceLocations;
    const mainData = serviceLocations.find(
      (location) => location.id === serviceLocationId,
    );
    if (isEmpty(mainData)) {
      yield put({
        type: types.LOAD_MOBILE_APP_BILL_PAY_DATA_FAILURE,
        payload:
          'Input ShipTo is not linked to any of the Input access token BillTos',
      });
      return;
    }
    yield put({
      type: types.SELECT_SERVICE_LOCATION_SUCCESS,
      payload: { ...mainData },
    });
    yield put(getBillingSummary());
  } catch (error) {
    yield handleError(
      'fetchMobileAppBillPayData',
      types.LOAD_MOBILE_APP_BILL_PAY_DATA_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* fetchCalendarDates({
  payload: { serviceLocationId, toDate, fromDate },
}) {
  try {
    throwErrorTest('fetchCalendarDates');
    const response = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationDates',
      parameters: { serviceLocationId, toDate, fromDate },
    });

    const serviceLocationDates = response.reduce((result, item) => {
      const yearMonth = item.date.slice(0, 7);
      const day = item.date.slice(-2);
      const dateType = calendarDateToType[item.dateType];
      // TODO: Leaving in below comment out code for now
      // const currentValue = get(result, [yearMonth, day], {});
      // if (currentValue.type === badgeTypes.HOLD) {
      //   return result;
      // }
      // TODO: Replacing the above code with the code below, this removes the holds which we do not use since using skipDelivery
      if (dateType === badgeTypes.HOLD) {
        return result;
      }

      if (dateType) {
        const data = {
          ...item,
          type: dateType,
          badges: [serviceTypeToBadge[item.serviceType]],
          note: dateType === badgeTypes.RESCHEDULED ? item.notes : '',
        };

        return updateWith(
          { ...result },
          `[${yearMonth}][${day}]`,
          (currentData) => {
            if (currentData) {
              const currentTypePriority = typesPriority.indexOf(
                currentData.type,
              );
              const newTypePriority = typesPriority.indexOf(data.type);
              const newType =
                newTypePriority < currentTypePriority
                  ? data.type
                  : currentData.type;

              return {
                ...currentData,
                badges: [...new Set([...currentData.badges, ...data.badges])],
                type: newType,
              };
            }
            return data;
          },
          Object,
        );
      }

      return result;
    }, {});
    const hasDataTypeService = (calDate) => calDate.dateType === 'service';
    const hasConfirmOrder = response && response.some(hasDataTypeService);
    yield put({
      type: types.LOAD_CALENDAR_DATES_SUCCESS,
      payload: { serviceLocationDates, hasConfirmOrder },
    });
  } catch (error) {
    yield handleError(
      'fetchCalendarDates',
      types.LOAD_CALENDAR_DATES_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* fetchDeliveryAfterSkip() {
  try {
    throwErrorTest('fetchDeliveryAfterSkip');
    const { id: serviceLocationId } = yield call(waitServiceLocation);
    const nextDelivery = yield select(selectNextDelivery());
    const toDate = moment(nextDelivery.date)
      .add(6, 'weeks')
      .format('YYYY-MM-DD');
    const allowedDateTypes = [
      'scheduled-service',
      'service-on-request',
      'delivered',
      'holiday-reschedule',
      'service',
    ];
    const response = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationDates',
      parameters: { serviceLocationId, toDate, fromDate: nextDelivery.date },
    });
    const deliveryAfterSkip = response.find(
      (delivery) =>
        allowedDateTypes.includes(delivery.dateType) &&
        delivery.date !== nextDelivery.date,
    );

    yield put({
      type: types.LOAD_DELIVERY_AFTER_SKIP_SUCCESS,
      payload: deliveryAfterSkip,
    });
  } catch (error) {
    yield handleError(
      'fetchDeliveryAfterSkip',
      types.LOAD_DELIVERY_AFTER_SKIP_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* fetchBillingStatements({ payload: { billingAccountId, fromDate } }) {
  try {
    throwErrorTest('fetchBillingStatements');
    const response = yield call(apiCall, {
      operationId: 'BillingAccounts.getBillingAccountStatements',
      parameters: {
        billingAccountId,
        fromDate: fromDate || '2015-01-01',
        toDate: moment().format('YYYY-MM-DD'),
      },
    });
    const billingStatements = orderBy(
      response.billingStatements,
      (item) => moment(item.statementDate).format('YYYYMMDD'),
      ['desc'],
    );
    yield put({
      type: types.FETCH_BILLING_STATEMENTS_SUCCESS,
      payload: { billingStatements },
    });
  } catch (error) {
    yield handleError(
      'fetchBillingStatements',
      types.FETCH_BILLING_STATEMENTS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getServiceLocationEquipment() {
  try {
    throwErrorTest('getServiceLocationEquipment');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    const payload = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationEquipment',
      parameters: { serviceLocationId },
    });
    yield put({
      type: types.GET_SERVICE_LOCATION_EQUIPMENT_SUCCESS,
      payload,
    });
  } catch (error) {
    yield handleError(
      'getServiceLocationEquipment',
      types.GET_SERVICE_LOCATION_EQUIPMENT_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getCustomerSummary({ payload: { serviceLocationId } }) {
  try {
    throwErrorTest('getCustomerSummary');
    const currentCustomerSummary = yield select(selectCustomerSummary());

    const payload = yield call(loadCustomerAndServiceSummary, {
      serviceLocationId,
      serviceLevel: SERVICE_LEVEL_CUSTOMER,
    });

    yield put({
      type: types.GET_CUSTOMER_SUMMARY_SUCCESS,
      payload,
    });

    if (
      isEmpty(currentCustomerSummary) ||
      currentCustomerSummary.custType !== payload.custType
    ) {
      const section = payload.custType === 'PERSON' ? 'home' : 'office';
      yield put(setSection(section));
      const layout = yield select(selectHeaderFooter());
      if (layout) {
        yield put(setHeaderFooter(layout));
      }
    }
  } catch (error) {
    yield handleError(
      'getCustomerSummary',
      types.GET_CUSTOMER_SUMMARY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getServiceSummary(action) {
  try {
    throwErrorTest('getServiceSummary');
    const serviceLocationId =
      action && action.payload
        ? Number(action.payload)
        : yield select(selectSelectedServiceLocationId());
    const payload = yield call(loadCustomerAndServiceSummary, {
      serviceLocationId,
      serviceLevel: SERVICE_LEVEL_SERVICE,
    });

    yield put({
      type: types.GET_SERVICE_SUMMARY_SUCCESS,
      payload,
    });
  } catch (error) {
    yield handleError(
      'getServiceSummary',
      types.GET_SERVICE_SUMMARY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getServiceLocationRecentTransactions({
  payload: { serviceLocationId, fromDate },
}) {
  try {
    throwErrorTest('getServiceLocationRecentTransactions');
    const response = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationRecentTransactions',
      parameters: {
        serviceLocationId,
        fromDate: moment(fromDate || null).format('YYYY-MM-DD'),
        toDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      },
    });
    yield put({
      type: types.GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_FAILURE,
      payload: error,
    });
  }
}

function* recurringOrderService() {
  const serviceLocationId = yield select(selectSelectedServiceLocationId());
  const selectedLocation = yield select(selectSelectedServiceLocation());
  const delivery = yield call(apiCall, {
    operationId: 'ServiceLocations.getServiceLocationRecurringDelivery',
    parameters: {
      serviceLocationId,
      recurringDeliveryId: selectedLocation.recurringDeliveryId,
    },
  });
  // Remove the recurring items with quantity === 0 since they are the past ones
  const deliveryItems = filterDeliveryItems(delivery).filter(
    (item) => item.quantity > 0,
  );
  const products = yield call(
    completeProductItemsWithNonContentfulSaga,
    deliveryItems,
  );
  const productsRecurring = products.map((product) => ({
    ...product,
    isRecurring: true,
  }));
  return productsRecurring;
}

function* loadRecurringOrder() {
  try {
    throwErrorTest('loadRecurringOrder');
    const recurringOrderItems = yield recurringOrderService();
    yield put({
      type: types.LOAD_RECURRING_ORDER_SUCCESS,
      payload: recurringOrderItems,
    });
    dataLayerPushSuccess('PUSH_LOAD_RECURRING_ORDER_SUCCESS');
  } catch (error) {
    yield handleError(
      'loadRecurringOrder',
      types.LOAD_RECURRING_ORDER_FAILURE,
      getErrorMessage(error),
    );
    dataLayerPushFailure('PUSH_LOAD_RECURRING_ORDER_SUCCESS');
  }
}

function* fetchDeliveryOrder(date) {
  const { id: serviceLocationId } = yield select(
    selectSelectedServiceLocation(),
  );
  try {
    throwErrorTest('fetchDeliveryOrder');
    const delivery = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationDelivery',
      parameters: {
        serviceLocationId,
        deliveryDate: date,
      },
    });
    const deliveryItems = yield call(
      completeProductItemsWithNonContentfulSaga,
      filterDeliveryItems(delivery),
    );
    return { ...delivery, items: deliveryItems };
  } catch (error) {
    // ToDo: What should we do here? Is this the right thing to do?
    const deliveryOrder = {
      items: [],
      date,
      editable: false,
      deliveryStatus: '',
      allowReschedule: false,
      serviceType: 'water',
    };
    return deliveryOrder;
  }
}

function* loadDeliveryOrder({
  payload: { date, type, skipDelivery, holdId, cutOffDateTime },
}) {
  try {
    throwErrorTest('loadRecurringOrder');
    const deliveryOrder = yield fetchDeliveryOrder(date);
    yield put({
      type: types.LOAD_DELIVERY_ORDER_SUCCESS,
      payload: {
        ...deliveryOrder,
        type,
        skipDelivery,
        holdId,
        cutOffDateTime,
      },
    });
  } catch (error) {
    yield handleError(
      'loadRecurringOrder',
      types.LOAD_DELIVERY_ORDER_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getServiceLocationRecurringDelivery() {
  try {
    throwErrorTest('getServiceLocationRecurringDelivery');
    const recurringOrderItems = yield recurringOrderService();
    yield put({
      type: types.GET_SERVICE_LOCATION_RECURRING_DELIVERY_SUCCESS,
      payload: recurringOrderItems,
    });
  } catch (error) {
    yield handleError(
      'getServiceLocationRecurringDelivery',
      types.GET_SERVICE_LOCATION_RECURRING_DELIVERY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* startExtraDelivery() {
  try {
    throwErrorTest('startExtraDelivery');
    const recurringOrderItems = yield recurringOrderService();
    yield put({
      type: types.EXTRA_DELIVERY_SUCCESS,
      payload: recurringOrderItems,
    });
  } catch (error) {
    yield handleError(
      'startExtraDelivery',
      types.EXTRA_DELIVERY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getOrderItems({ payload: items }) {
  try {
    throwErrorTest('getOrderItems');
    const orderItems = yield call(
      completeProductItemsWithNonContentfulSaga,
      items,
    );
    yield put({ type: types.GET_ORDER_ITEMS_SUCCESS, payload: orderItems });
  } catch (error) {
    yield handleError(
      'getOrderItems',
      types.GET_ORDER_ITEMS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* updateRecurringOrder({
  payload: { delivery, showChangeSuccessfulModal },
}) {
  try {
    throwErrorTest('updateRecurringOrder');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    const nextDelivery = yield select(selectNextDelivery());
    const nextDeliveryDate = get(
      nextDelivery,
      'date',
      moment().add(7, 'days').format('YYYY-MM-DD'),
    );
    yield call(apiCall, {
      operationId: 'ServiceLocations.updateServiceLocationRecurringDelivery',
      parameters: {
        serviceLocationId,
        nextDeliveryDate,
      },
      options: {
        requestBody: {
          items: delivery,
        },
      },
    });
    if (showChangeSuccessfulModal) {
      yield put({
        type: SHOW_DRAWER,
        payload: { pageTitle: types.UPDATE_ADD_TO_RECURRING_ORDER_SUCCESS },
      });
    }

    const href = !isSsr() ? window.location.href.toLowerCase() : '';
    if (href.includes('account/products')) {
      yield put({ type: types.SHOW_ORDER_CHANGE_MESSAGE });
    }

    yield put({ type: types.UPDATE_RECURRING_ORDER_SUCCESS });
    dataLayerPushSuccess('PUSH_ADD_TO_RECURRING_ORDER');
    yield put({ type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST });
  } catch (error) {
    yield handleError(
      'updateRecurringOrder',
      types.UPDATE_RECURRING_ORDER_FAILURE,
      getErrorMessage(error),
    );
    dataLayerPushFailure('PUSH_ADD_TO_RECURRING_ORDER');
  }
}

function* createExtraDelivery({ payload: { delivery, date } }) {
  try {
    throwErrorTest('createExtraDelivery');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());

    yield call(apiCall, {
      operationId: 'ServiceLocations.createDelivery',
      parameters: { serviceLocationId },
      options: { requestBody: { date, items: delivery } },
    });
    yield call(getServiceSummary);
    yield put({ type: types.CREATE_EXTRA_DELIVERY_SUCCESS });
    yield put({ type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST });
  } catch (error) {
    yield handleError(
      'createExtraDelivery',
      types.CREATE_EXTRA_DELIVERY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getNextDelivery() {
  const { id: serviceLocationId, nextDeliveryDate } = yield select(
    selectSelectedServiceLocation(),
  );
  try {
    throwErrorTest('getNextDelivery');
    const nextDelivery = yield call(apiCall, {
      operationId: 'ServiceLocations.getServiceLocationDelivery',
      parameters: {
        serviceLocationId,
        deliveryDate: nextDeliveryDate,
      },
    });
    const nextDeliveryItems = yield call(
      completeProductItemsWithNonContentfulSaga,
      filterDeliveryItems(nextDelivery),
    );
    const recurringOrder = yield call(recurringOrderService);
    yield put({
      type: types.LOAD_RECURRING_ORDER_SUCCESS,
      payload: recurringOrder,
    });
    const nextDeliveryItemsWithRecurring = [];
    nextDeliveryItems.forEach((nextDeliveryItem) => {
      const isRecurring = Boolean(
        recurringOrder.find(
          (item) => item.itemNumber === nextDeliveryItem.itemNumber,
        ),
      );
      if (
        nextDeliveryItem.deliveryType === 'DELIVERY' &&
        nextDeliveryItem.itemNumber !== '67000104'
      ) {
        nextDeliveryItemsWithRecurring.push({
          ...nextDeliveryItem,
          isRecurring,
        });
      }
    });

    let bottlePickupRequested = false;
    let equipmentServiceRequested = false;
    nextDelivery.items.forEach((item) => {
      if (
        item.deliveryType === 'PICKUP' &&
        item.src === 'ORDER_LINE' &&
        item.itemType === 'RAW'
      ) {
        bottlePickupRequested = true;
      } else {
        equipmentServiceRequested = [
          'INSTALL',
          'EXCHANGE',
          'PICKUP',
          'MAINTAIN',
        ].includes(item.deliveryType)
          ? true
          : equipmentServiceRequested;
      }
    });

    yield put({
      type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_SUCCESS,
      payload: {
        ...nextDelivery,
        serviceType: 'water',
        items: nextDeliveryItemsWithRecurring,
        bottlePickupRequested,
        equipmentServiceRequested,
      },
    });
  } catch (error) {
    // ToDo: What should we do here? Is this the right thing to do?
    const payload = {
      items: [],
      date: nextDeliveryDate,
      editable: false,
      deliveryStatus: '',
      allowReschedule: false,
      serviceType: 'water',
    };
    yield put({
      type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_SUCCESS,
      payload,
    });
  }
}

function* skipNextDelivery({ payload: delivery }) {
  try {
    throwErrorTest('skipNextDelivery');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    const selectedLocation = yield select(selectSelectedServiceLocation());

    yield call(apiCall, {
      operationId: 'ServiceLocations.createServiceHoldRequest',
      parameters: {
        serviceLocationId,
        recurringDeliveryId: selectedLocation.recurringDeliveryId,
      },
      options: {
        requestBody: {
          startDate: delivery.date,
          endDate: delivery.date,
          serviceType: 'water',
        },
      },
    });

    const serviceLocation = yield call(loadCustomerAndServiceSummary, {
      serviceLocationId,
      serviceLevel: SERVICE_LEVEL_SERVICE,
    });

    yield put({ type: types.SKIP_DELIVERY_SUCCESS, payload: serviceLocation });
    yield put({ type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST });
    const isIndefiniteHoldLocation = yield select(
      selectIsSelectedServiceIndefiniteHold(),
    );
    if (isIndefiniteHoldLocation) {
      const fromDate = moment().format('YYYY-MM-DD');
      const toDate = moment().add(90, 'days').format('YYYY-MM-DD');
      yield fetchCalendarDates({
        payload: {
          serviceLocationId,
          fromDate,
          toDate,
        },
      });
    }
  } catch (error) {
    yield handleError(
      'skipNextDelivery',
      types.SKIP_DELIVERY_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* skipDeliveryOrder({ payload: date }) {
  try {
    throwErrorTest('skipDeliveryOrder');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    const selectedLocation = yield select(selectSelectedServiceLocation());

    yield call(apiCall, {
      operationId: 'ServiceLocations.createServiceHoldRequest',
      parameters: {
        serviceLocationId,
        recurringDeliveryId: selectedLocation.recurringDeliveryId,
      },
      options: {
        requestBody: {
          startDate: date,
          endDate: date,
          serviceType: 'water',
        },
      },
    });
    const serviceLocation = yield call(loadCustomerAndServiceSummary, {
      serviceLocationId,
      serviceLevel: SERVICE_LEVEL_SERVICE,
    });

    yield put({
      type: types.SKIP_DELIVERY_ORDER_SUCCESS,
      payload: serviceLocation,
    });
    dataLayerPushSuccess('PUSH_SKIP_DELIVERY_ORDER_SUCCESS');
    yield put({ type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST });
  } catch (error) {
    yield handleError(
      'skipDeliveryOrder',
      types.SKIP_DELIVERY_ORDER_FAILURE,
      getErrorMessage(error),
    );
    dataLayerPushFailure('PUSH_SKIP_DELIVERY_ORDER_SUCCESS');
  }
}

function* removeHold({ payload: { deliveryOrder, successCallback } }) {
  try {
    throwErrorTest('removeHold');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    const selectedLocation = yield select(selectSelectedServiceLocation());

    yield call(apiCall, {
      operationId: 'ServiceLocations.removeHold',
      parameters: {
        serviceLocationId,
        recurringDeliveryId: selectedLocation.recurringDeliveryId,
        holdId: deliveryOrder.holdId,
      },
    });

    const serviceLocation = yield call(loadCustomerAndServiceSummary, {
      serviceLocationId,
      serviceLevel: SERVICE_LEVEL_SERVICE,
    });

    yield put({ type: types.REMOVE_HOLD_SUCCESS, payload: serviceLocation });
    yield put({ type: types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST });
    successCallback(deliveryOrder);
  } catch (error) {
    yield handleError(
      'removeHold',
      types.REMOVE_HOLD_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* getBestDeliveryDates({
  payload: { serviceLocationId, fromDate, toDate, serviceType },
}) {
  try {
    throwErrorTest('getBestDeliveryDates');
    // If we have been passed a valid fromDate and a valid toDate, make the
    // request
    if (toDate) {
      const deliveries = yield call(apiCall, {
        operationId: 'ServiceLocations.getBestDeliveryDates',
        parameters: {
          serviceLocationId:
            serviceLocationId ||
            (yield select(selectSelectedServiceLocationId())),
          fromDate,
          toDate,
          serviceType,
        },
      });

      yield put({
        type: types.GET_BEST_DELIVERY_DATES_SUCCESS,
        payload: deliveries ? deliveries.deliveryDates : [],
      });
    } else {
      // If we don't have a valid toDate, don't make the request and instead
      // reset the delivery dates to empty
      yield put({
        type: types.GET_BEST_DELIVERY_DATES_SUCCESS,
        payload: [],
      });
    }
  } catch (error) {
    yield handleError(
      'getBestDeliveryDates',
      types.GET_BEST_DELIVERY_DATES_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* updateNextDelivery({ payload: { delivery, date, successCallback } }) {
  try {
    throwErrorTest('updateNextDelivery');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    const nextDelivery = yield call(apiCall, {
      operationId: 'ServiceLocations.editDeliveryDetails',
      parameters: { serviceLocationId, deliveryDate: date },
      options: { requestBody: { items: delivery, date } },
    });
    const nextDeliveryItems = yield call(
      completeProductItemsWithNonContentfulSaga,
      filterDeliveryItems(nextDelivery),
    );
    yield put({
      type: types.UPDATE_NEXT_DELIVERY_SUCCESS,
      payload: { ...nextDelivery, items: nextDeliveryItems },
    });
    dataLayerPushSuccess('PUSH_UPDATE_NEXT_DELIVERY_SUCCESS');
    yield put({
      type: SHOW_DRAWER,
      payload: { pageTitle: types.DELIVERY_UPDATED_SUCCESS_DRAWER },
    });
    successCallback.call();
  } catch (error) {
    yield handleError(
      'updateNextDelivery',
      types.UPDATE_NEXT_DELIVERY_FAILURE,
      getErrorMessage(error),
    );
    dataLayerPushFailure('PUSH_UPDATE_NEXT_DELIVERY_SUCCESS');
  }
}

function* updateDeliveryOrder({ payload: { delivery, date } }) {
  try {
    throwErrorTest('updateDeliveryOrder');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    yield call(apiCall, {
      operationId: 'ServiceLocations.editDeliveryDetails',
      parameters: { serviceLocationId, deliveryDate: date },
      options: { requestBody: { items: delivery, date } },
    });
    yield put({ type: types.UPDATE_DELIVERY_ORDER_SUCCESS });
    const isIndefiniteHoldLocation = yield select(
      selectIsSelectedServiceIndefiniteHold(),
    );
    if (isIndefiniteHoldLocation) {
      const fromDate = moment().format('YYYY-MM-DD');
      const toDate = moment().add(90, 'days').format('YYYY-MM-DD');
      yield fetchCalendarDates({
        payload: {
          serviceLocationId,
          fromDate,
          toDate,
        },
      });
      dataLayerPushSuccess('PUSH_UPDATE_DELIVERY_ORDER_SUCCESS');
    }
  } catch (error) {
    yield handleError(
      'updateDeliveryOrder',
      types.UPDATE_DELIVERY_ORDER_FAILURE,
      getErrorMessage(error),
    );
    dataLayerPushFailure('PUSH_UPDATE_DELIVERY_ORDER_SUCCESS');
  }
}

function* makePayment({
  payload: { amount, paymentId, paymentMethodType, email },
}) {
  try {
    throwErrorTest('makePayment');
    const billingAccountId = yield select(selectBillingAccountId());
    const paymentMethodId =
      paymentId || (yield select(selectCurrentPaymentMethodId()));
    const emailAddress = email || (yield select(selectUserEmail()));
    const mobile = cookies.get('isMobileView') === 'Y';

    if (billingAccountId && paymentMethodId) {
      yield call(apiCall, {
        operationId: 'BillingAccounts.createBillingAccountPaymentMethodPayment',
        parameters: {
          billingAccountId,
          paymentMethodId,
        },
        options: {
          requestBody: {
            amount,
            paymentMethodType,
            emailAddress,
            userName: mobile ? 'MOBILE APP USER' : undefined,
          },
        },
      });

      yield put({ type: types.MAKE_PAYMENT_SUCCESS });

      yield put({ type: GET_BILLING_SUMMARY });
    }
  } catch (error) {
    yield handleError(
      'makePayment',
      types.MAKE_PAYMENT_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* createNote({ payload }) {
  try {
    throwErrorTest('createNote');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    yield call(apiCall, {
      operationId: 'ServiceLocations.createServiceLocationNote',
      parameters: { serviceLocationId },
      options: { requestBody: payload },
    });

    yield put({ type: types.CREATE_NOTE_SUCCESS });
  } catch (error) {
    yield handleError(
      'createNote',
      types.CREATE_NOTE_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* fetchContactUsSubjects() {
  try {
    throwErrorTest('fetchContactUsSubjects');
    const response = yield call(apiCall, {
      operationId: 'users.getLookupCodeValues',
      parameters: {
        lookupCode: isSelfServeDomain()
          ? 'swg_cx_self_serve_tasks'
          : 'swg_cx_contact_us_reasons',
      },
    });
    const contactUsSubjects = response.lookUpCode.map((codeValue) => ({
      ...codeValue,
      label: codeValue.description,
    }));

    yield put({
      type: types.FETCH_CONTACT_US_SUBJECTS_SUCCESS,
      payload: contactUsSubjects,
    });
  } catch (error) {
    yield handleError(
      'fetchContactUsSubjects',
      types.FETCH_CONTACT_US_SUBJECTS_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* sendContactUs({ payload }) {
  try {
    throwErrorTest('sendContactUs');
    const serviceLocationId = yield select(selectSelectedServiceLocationId());
    yield call(apiCall, {
      operationId: 'ServiceLocations.createTaskForContactUs',
      parameters: {
        serviceLocationId,
      },
      options: {
        requestBody: {
          ...payload,
        },
      },
    });

    yield put({
      type: types.SEND_CONTACT_US_SUCCESS,
    });

    const { location } = window;
    const successURL = `${location.pathname}/success`;
    yield put(push(successURL));
  } catch (error) {
    yield handleError(
      'sendContactUs',
      types.SEND_CONTACT_US_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* scheduleCallback({ payload }) {
  try {
    throwErrorTest('scheduleCallback');
    const userId = yield select(selectUserId());
    const stateOrProvinceCode = yield select(
      selectSelectedServiceLocationStateOrProvinceCode(),
    );
    const { firstName, lastName, phoneNumber, scheduleDateTime } = payload;
    const locationId = yield select(selectSelectedServiceLocationId());
    const locale = getLocale();
    yield call(apiCall, {
      operationId: 'Users.scheduleF9Call',
      parameters: {
        userId,
      },
      options: {
        requestBody: {
          firstName,
          lastName,
          phoneNumber,
          locationId,
          state: stateOrProvinceCode,
          scheduleDateTime,
          language: locale,
        },
      },
    });

    yield put({
      type: types.SCHEDULE_CALLBACK_SUCCESS,
    });

    const { location } = window;
    const successURL = `${location.pathname}/callback-scheduled`;
    yield put(push(successURL));
  } catch (error) {
    yield handleError(
      'scheduleCallback',
      types.SCHEDULE_CALLBACK_FAILURE,
      getErrorMessage(error),
    );
  }
}

function* fetchRecentActivites() {
  try {
    throwErrorTest('fetchRecentActivities');
    const billingAccountId = yield select(selectBillingAccountId());

    const payload = yield call(apiCall, {
      operationId: 'BillingAccounts.getUnBilledRecentActivities',
      parameters: {
        billingAccountId,
      },
    });

    yield put({
      type: types.FETCH_RECENT_ACTIVITIES_SUCCESS,
      payload,
    });
  } catch (error) {
    yield handleError(
      'fetchRecentActivities',
      types.FETCH_RECENT_ACTIVITIES_FAILURE,
      getErrorMessage(error),
    );
  }
}

export default function* primoAccountSaga() {
  yield all([
    takeLeading(types.LOAD_SERVICE_LOCATIONS, fetchUserServiceLocations),
    takeLatest(types.SELECT_SERVICE_LOCATION, selectServiceLocation),
    takeLatest(types.LOAD_CALENDAR_DATES, fetchCalendarDates),
    takeLatest(types.LOAD_DELIVERY_AFTER_SKIP, fetchDeliveryAfterSkip),
    takeLatest(types.FETCH_BILLING_STATEMENTS_REQUEST, fetchBillingStatements),
    takeLatest(
      types.GET_SERVICE_LOCATION_RECENT_TRANSACTIONS_REQUEST,
      getServiceLocationRecentTransactions,
    ),
    takeLatest(types.GET_ORDER_ITEMS_REQUEST, getOrderItems),
    takeLatest(
      types.GET_SERVICE_LOCATION_RECURRING_DELIVERY_REQUEST,
      getServiceLocationRecurringDelivery,
    ),
    takeLatest(types.LOAD_RECURRING_ORDER_REQUEST, loadRecurringOrder),
    takeLatest(types.LOAD_DELIVERY_ORDER_REQUEST, loadDeliveryOrder),
    takeLatest(types.UPDATE_RECURRING_ORDER, updateRecurringOrder),
    takeLatest(types.UPDATE_DELIVERY_ORDER_REQUEST, updateDeliveryOrder),
    takeLatest(
      types.GET_SERVICE_LOCATION_EQUIPMENT_REQUEST,
      getServiceLocationEquipment,
    ),
    takeLatest(
      types.GET_SERVICE_LOCATION_NEXT_DELIVERY_REQUEST,
      getNextDelivery,
    ),
    takeLatest(types.SKIP_DELIVERY_REQUEST, skipNextDelivery),
    takeLatest(types.SKIP_DELIVERY_ORDER_REQUEST, skipDeliveryOrder),
    takeLatest(types.REMOVE_HOLD_REQUEST, removeHold),
    takeLatest(types.EXTRA_DELIVERY_REQUEST, startExtraDelivery),
    takeLatest(types.CREATE_EXTRA_DELIVERY_REQUEST, createExtraDelivery),
    takeLatest(types.GET_BEST_DELIVERY_DATES_REQUEST, getBestDeliveryDates),
    takeLatest(types.UPDATE_NEXT_DELIVERY_REQUEST, updateNextDelivery),
    takeLatest(types.MAKE_PAYMENT_REQUEST, makePayment),
    takeLatest(types.CREATE_NOTE_REQUEST, createNote),
    takeLatest(types.GET_CUSTOMER_SUMMARY_REQUEST, getCustomerSummary),
    takeLatest(types.GET_SERVICE_SUMMARY_REQUEST, getServiceSummary),
    takeLatest(types.LOAD_MOBILE_APP_BILL_PAY_DATA, fetchMobileAppBillPayData),
    takeLatest(types.SEND_CONTACT_US_REQUEST, sendContactUs),
    takeLatest(types.SCHEDULE_CALLBACK, scheduleCallback),
    takeLatest(types.FETCH_CONTACT_US_SUBJECTS, fetchContactUsSubjects),
    takeLatest(types.FETCH_RECENT_ACTIVITIES, fetchRecentActivites),
  ]);
}
