import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import isNil from 'lodash/isNil';
import {
  Popper as MUIPopper,
  ClickAwayListener,
  Dialog as MUIDialog,
} from '@material-ui/core';
import { CloseIcon } from 'components/Ui/CustomIcons';
import { isSsr } from 'utils/ssrHelper';
import styles from './styles.scss';

const MobileContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
`;

class Popper extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (!isNil(props.open) && props.open !== state.open) {
      return { open: props.open };
    }
    return null;
  }

  state = {
    anchorEl: null,
    open: this.props.open || false,
  };

  arrowRef = React.createRef();

  handleClick = ({ currentTarget }) => {
    if (this.props.handleChange) {
      this.props.handleChange(!this.state.open);
      this.setState({ anchorEl: currentTarget });
      return;
    }

    this.setState((state) => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  handleClickAway = (e) => {
    const {
      button: {
        ref: { current: button },
      },
    } = this.props;

    if (!button.contains(e.target)) {
      if (this.props.handleChange) {
        this.props.handleChange(false);
        return;
      }

      this.setState({ open: false });
    }
  };

  render() {
    const { anchorEl, open } = this.state;
    const {
      role,
      title,
      button,
      children,
      className,
      arrowClassName,
      closeIcon,
      placement,
      disablePortal,
      isMobile,
      anchorElButton,
    } = this.props;

    return isMobile ? (
      <Fragment>
        {button && React.cloneElement(button, { onClick: this.handleClick })}
        <MUIDialog
          classes={classnames(styles.popper, className)}
          open={open}
          onClose={this.handleClick}
          fullScreen
        >
          <MobileContentWrapper>{children}</MobileContentWrapper>
        </MUIDialog>
      </Fragment>
    ) : (
      <Fragment>
        {button && React.cloneElement(button, { onClick: this.handleClick })}
        <MUIPopper
          role={role}
          aria-label={title}
          className={classnames(styles.popper, className)}
          open={open}
          anchorEl={anchorElButton || anchorEl}
          modifiers={{
            arrow: {
              enabled: Boolean(this.arrowRef.current),
              element: this.arrowRef.current,
            },
            ...this.props.modifiers,
          }}
          placement={placement}
          disablePortal={disablePortal}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div>
              <div
                className={classnames(
                  styles.popperContent,
                  !isSsr() && window.location.pathname.includes('cart')
                    ? styles.cartPage
                    : '',
                )}
              >
                {children}
              </div>
              <span className={classnames(styles.arrow, arrowClassName)} />
              {closeIcon && <CloseIcon onClick={this.handleClick} />}
            </div>
          </ClickAwayListener>
        </MUIPopper>
      </Fragment>
    );
  }
}

Popper.propTypes = {
  role: PropTypes.string,
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  children: PropTypes.any.isRequired,
  button: PropTypes.object,
  closeIcon: PropTypes.bool,
  className: PropTypes.string,
  arrowClassName: PropTypes.string,
  placement: PropTypes.string,
  open: PropTypes.bool,
  disablePortal: PropTypes.bool,
  handleChange: PropTypes.func,
  modifiers: PropTypes.object,
  anchorElButton: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
};

Popper.defaultProps = {
  role: 'tooltip',
  title: null,
  closeIcon: false,
  open: null,
  handleChange: null,
  modifiers: {},
  disablePortal: false,
  isMobile: false,
};

export default Popper;
