export const PRIMARY_CONTACT_FORM = 'contact';
export const DELIVERY_AND_INSTALLATION_LOCATION_FORM = 'servicesAddresses';
export const CREDIT_CARD_FORM = 'creditCard';
export const BILLING_ADDRESS_FORM = 'billingAddress';
export const DELIVERY_SETUP_FORM = 'deliverySetup';

export const CHECKOUT_PAGE_KEY = 'checkout';

export const ACCOUNT_PAGE = 'account';
export const LOCATION_PAGE = 'location';
export const BILLING_PAGE = 'billing';
export const CONFIRMATION_PAGE = 'confirmation';
export const SUCCESS_PAGE = 'success';

export const SAVE_CREDIT_CARD = 'checkout/SAVE_CREDIT_CARD';

export const privatePages = [
  LOCATION_PAGE,
  BILLING_PAGE,
  CONFIRMATION_PAGE,
  SUCCESS_PAGE,
];

export const publicPages = [ACCOUNT_PAGE];

export const pages = [...privatePages, ...publicPages];

export const CHECKOUT_BREADCRUMBS_ORDER = [
  ACCOUNT_PAGE,
  LOCATION_PAGE,
  BILLING_PAGE,
  CONFIRMATION_PAGE,
  SUCCESS_PAGE,
];

export const RESET_ERROR = 'checkout/RESET_ERROR';

export const SAVE_LOCATION_FORM = 'checkout/SAVE_LOCATION_FORM';
export const SAVE_LOCATION_FORM_SUCCESS = 'checkout/SAVE_LOCATION_FORM_SUCCESS';
export const SAVE_LOCATION_FORM_ERROR = 'checkout/SAVE_LOCATION_FORM_ERROR';

export const SAVE_DELIVERY_SETUP_FORM = 'checkout/SAVE_DELIVERY_SETUP_FORM';
export const SAVE_DELIVERY_SETUP_FORM_SUCCESS =
  'checkout/SAVE_DELIVERY_SETUP_FORM_SUCCESS';
export const SAVE_DELIVERY_SETUP_FORM_ERROR =
  'checkout/SAVE_DELIVERY_SETUP_FORM_ERROR';

export const SAVE_PAYMENT_FORM = 'checkout/SAVE_PAYMENT_FORM';
export const SAVE_PAYMENT_FORM_SUCCESS = 'checkout/SAVE_PAYMENT_FORM_SUCCESS';
export const SAVE_PAYMENT_FORM_ERROR = 'checkout/SAVE_PAYMENT_FORM_ERROR';
export const PAYMENT_PROCESS_ERROR = 'PAYMENT_PROCESS_ERROR';

export const PLACE_ORDER = 'checkout/PLACE_ORDER';
export const PLACE_ORDER_SUCCESS = 'checkout/PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_ERROR = 'checkout/PLACE_ORDER_ERROR';

export const FETCH_ORDER = 'checkout/FETCH_ORDER';
export const FETCH_ORDER_SUCCESS = 'checkout/FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_ERROR = 'checkout/FETCH_ORDER_ERROR';
export const FETCH_CITY_COUNTY = 'checkout/FETCH_CITY_COUNTY';
export const FETCH_CITY_COUNTY_SUCCESS = 'checkout/FETCH_CITY_COUNTY_SUCCESS';
export const FETCH_CITY_COUNTY_ERROR = 'checkout/FETCH_CITY_COUNTY_ERROR';
export const FETCH_CITY_COUNTY_OPTION = 'checkout/FETCH_CITY_COUNTY_OPTION';
export const RESET_ORDER = 'checkout/RESET_ORDER';

export const CREATE_PASSWORD = 'checkout/CREATE_PASSWORD';
export const CREATE_PASSWORD_SUCCESS = 'checkout/CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_ERROR = 'checkout/CREATE_PASSWORD_ERROR';
export const RESET_CREATE_PASSWORD = 'checkout/RESET_CREATE_PASSWORD';
