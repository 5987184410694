/* eslint-disable prefer-destructuring */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectSsrReqRoute } from 'containers/Landing/selectors';
import {
  WaterRegionalTheme,
  PurefloTheme,
  WaterTheme,
} from 'components/ThemeProvider';
import { getBrandedLogo } from 'utils/domainHelper';
import WaterRegionalNavigation from './WaterRegional';
import WaterNavigation from './Water';
import PurefloNavigation from './Pureflo';

const Navigation = (props) => {
  const {
    document,
    openCollapse,
    closeCollapse,
    activeDrawer,
    drawerIsOpen,
    section,
    ssrReqRoute,
  } = props;
  const entryTitle =
    document && document.fields && document.fields.entryTitle
      ? document.fields.entryTitle
      : '';

  let theLogo;
  if (document) {
    const { logo, brandedLogos } = document.fields;
    theLogo = getBrandedLogo(brandedLogos, logo, ssrReqRoute);
  }

  return (
    <Fragment>
      <WaterTheme>
        <WaterNavigation
          entryTitle={entryTitle}
          fields={document && document.fields.navigation}
          logoFields={theLogo}
          openCollapse={openCollapse}
          closeCollapse={closeCollapse}
          activeDrawer={activeDrawer}
          drawerIsOpen={drawerIsOpen}
          changeHeight={props.changeHeight}
        />
      </WaterTheme>
      <WaterRegionalTheme>
        <WaterRegionalNavigation
          entryTitle={entryTitle}
          fields={document && document.fields.navigation}
          logoFields={theLogo}
          openCollapse={openCollapse}
          closeCollapse={closeCollapse}
          activeDrawer={activeDrawer}
          drawerIsOpen={drawerIsOpen}
          changeHeight={props.changeHeight}
          section={section}
        />
      </WaterRegionalTheme>
      <PurefloTheme>
        <PurefloNavigation
          entryTitle={entryTitle}
          fields={document && document.fields.navigation}
          logoFields={theLogo}
        />
      </PurefloTheme>
    </Fragment>
  );
};

Navigation.propTypes = {
  document: PropTypes.object,
  openCollapse: PropTypes.func.isRequired,
  closeCollapse: PropTypes.func.isRequired,
  changeHeight: PropTypes.func,
  activeDrawer: PropTypes.string,
  drawerIsOpen: PropTypes.bool.isRequired,
  section: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  ssrReqRoute: selectSsrReqRoute(),
});

export default connect(mapStateToProps, null)(Navigation);
