import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useIsVertPhone } from 'utils/hooks';
import { Button, Span } from 'components/Ui';
import styles from './styles.scss';

const CTABanner = ({ onAction, icon, title, text, ctaText, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isVertPhone = useIsVertPhone();
  const ctaTextShort = ctaText.length < 10;
  const button = (
    <Button
      className={classnames(styles.ctaButton, {
        [styles.ctaButtonNoText]: !text && ctaTextShort,
        [styles.ctaButtonNoTextVert]: !text && isVertPhone && !ctaTextShort,
      })}
      text={ctaText}
      onClick={onAction}
    />
  );
  return (
    <div className={classnames(styles.banner, props.className)}>
      <div className={styles.content}>
        {icon && icon}
        {title && <Span className={styles.title}>{title}</Span>}
        {text && <Span className={styles.text}>{text}</Span>}
        {!isMobile && button}
      </div>
      {isMobile && button}
    </div>
  );
};

CTABanner.propTypes = {
  onAction: PropTypes.func.isRequired,
  icon: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CTABanner;
