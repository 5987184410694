import React, { Component, createRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

class Counter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTriggered: false,
    };

    this.myCountUp = createRef();
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible && !this.state.isTriggered) {
      this.myCountUp.current.start();
      this.setState({ isTriggered: true });
    }
  };

  render() {
    const {
      document: {
        fields: { start, end, prefix, suffix },
      },
      className,
    } = this.props;

    return (
      <VisibilitySensor onChange={this.onVisibilityChange}>
        <CountUp
          start={start}
          end={end}
          delay={0.1}
          duration={2}
          prefix={`${prefix || ''} `}
          suffix={` ${suffix || ''} `}
          ref={this.myCountUp}
        >
          {({ countUpRef }) => <span className={className} ref={countUpRef} />}
        </CountUp>
      </VisibilitySensor>
    );
  }
}

Counter.propTypes = {
  document: PropTypes.object,
  className: PropTypes.string,
};

export default Counter;
