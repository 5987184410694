import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { translateData } from 'utils/translation';

const Section = ({ children, ...rest }) => {
  const translatedAriaLabel = translateData(get(rest, 'aria-label'));
  return (
    <section {...rest} aria-label={translatedAriaLabel}>
      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node,
};

export default Section;
