import { getServerData } from 'utils/getServerData';

export const FETCH_SITE_REQUEST = 'landing/FETCH_SITE_REQUEST';
export const FETCH_SITE_SUCCESS = 'landing/FETCH_SITE_SUCCESS';
export const FETCH_SITE_FAILURE = 'landing/FETCH_SITE_FAILURE';

export const FETCH_HOME = 'landing/FETCH_HOME';
export const FETCH_PAGE_REQUEST = 'landing/FETCH_PAGE_REQUEST';
export const FETCH_PAGE_SUCCESS = 'landing/FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAILURE = 'landing/FETCH_PAGE_FAILURE';

export const SET_FORCE_FETCH = 'landing/SET_FORCE_FETCH';
export const SET_LOADER_FALSE = 'landing/SET_LOADER_FALSE';
export const SET_GCL_ID = 'landing/SET_GCL_ID';

export const SET_LANGUAGE = 'landing/SET_LANGUAGE';

export const SET_STEPS = 'landing/SET_STEPS';

export const FETCH_GET_STARTED_REQUEST = 'landing/FETCH_GET_STARTED_REQUEST';
export const FETCH_GET_STARTED_SUCCESS = 'landing/FETCH_GET_STARTED_SUCCESS';
export const FETCH_GET_STARTED_FAILURE = 'landing/FETCH_GET_STARTED_FAILURE';

export const FETCH_FEATURE_TOUR = 'landing/FETCH_FEATURE_TOUR';
export const FETCH_FEATURE_TOUR_SUCCESS = 'landing/FETCH_FEATURE_TOUR_SUCCESS';
export const FETCH_FEATURE_TOUR_FAILURE = 'landing/FETCH_FEATURE_TOUR_FAILURE';

export const FETCH_LOGO = 'landing/FETCH_LOGO';
export const FETCH_LOGO_SUCCESS = 'landing/FETCH_LOGO_SUCCESS';
export const FETCH_LOGO_FAILURE = 'landing/FETCH_LOGO_FAILURE';

export const FETCH_VARIABLES = 'landing/FETCH_VARIABLES';
export const FETCH_ENTRY_BY_TITLE = 'landing/FETCH_ENTRY_BY_TITLE';
export const FETCH_ENTRY = 'landing/FETCH_ENTRY';
export const FETCH_ENTRY_SUCCESS = 'landing/FETCH_ENTRY_SUCCESS';
export const FETCH_ENTRY_FAILURE = 'landing/FETCH_ENTRY_FAILURE';
export const FETCH_JSON_DATA = 'landing/FETCH_JSON_DATA';
export const FETCH_RESOURCE_BUNDLES = 'landing/FETCH_RESOURCE_BUNDLES';

export const NAVIGATE_ZIP_REDIRECT = 'landing/NAVIGATE_ZIP_REDIRECT';
export const NAVIGATE_ZIP_REDIRECT_SUCCESS =
  'landing/NAVIGATE_ZIP_REDIRECT_SUCCESS';
export const NAVIGATE_ZIP_REDIRECT_FAILURE =
  'landing/NAVIGATE_ZIP_REDIRECT_FAILURE';

export const SET_SECTION = 'landing/SET_SECTION';
export const SET_HOME_OFFICE = 'landing/SET_HOME_OFFICE';
export const SET_HEADER_FOOTER = 'landing/SET_HEADER_FOOTER';
export const SET_LAYOUTS = 'landing/SET_LAYOUTS';

export const SHOW_BRAND_SWITCH_DIALOG = 'landing/SHOW_BRAND_SWITCH_DIALOG';
export const HIDE_BRAND_SWITCH_DIALOG = 'landing/HIDE_BRAND_SWITCH_DIALOG';

export const SHOW_COSTCO_MEMBERSHIP_DIALOG =
  'landing/SHOW_COSTCO_MEMBERSHIP_DIALOG';
export const HIDE_COSTCO_MEMBERSHIP_DIALOG =
  'landing/HIDE_COSTCO_MEMBERSHIP_DIALOG';

export const HIDE_INITIAL_ZIP_DIALOG = 'landing/HIDE_INITIAL_ZIP_DIALOG';

export const NAVIGATE_COSTCO_ROUTE = 'landing/NAVIGATE_COSTCO_ROUTE';

export const NAVIGATE_LANDING_PAGE_LINK = 'landing/NAVIGATE_LANDING_PAGE_LINK';

export const SECTION_HOME = 'home';
export const SECTION_OFFICE = 'office';

export const SECTION_RESI_SERVICES = 'residentialServices';
export const SECTION_COMM_SERVICES = 'commercialServices';

export const LANDING_PAGE_KEY = 'landing';

export const UPDATE_VARIABLES = 'landing/UPDATE_VARIABLES';
export const SSR_REQ_ROUTE = 'landing/SSR_REQ_ROUTE';
export const FETCH_PROMOTION_LANDING_DATA =
  'landing/FETCH_PROMOTION_LANDING_DATA';
export const FETCH_PROMOTION_LANDING_DATA_SUCCESS =
  'landing/FETCH_PROMOTION_LANDING_DATA_SUCCESS';
export const FETCH_PROMOTION_LANDING_DATA_FAILURE =
  'landing/FETCH_PROMOTION_LANDING_DATA_FAILURE';
export const SET_PAGE_NOT_FOUND = 'landing/SET_PAGE_NOT_FOUND';
export const SET_SSR_REQ_STATUS_CODE = 'landing/SET_SSR_REQ_STATUS_CODE';
export const SHOW_ZIPCODE_POPPER = 'landing/SHOW_ZIPCODE_POPPER';

export const BRANDS = {
  ALHAMBRA: 'alhambrawater',
  AQUATERRA: 'aquaterracorp',
  CANADIAN: 'canadiansprings',
  CRYSTAL: 'crystal-springs',
  CRYSTALROCK: 'crystalrock',
  DEEPROCK: 'deeprockwater',
  HINCKLEY: 'hinckleysprings',
  KENTWOOD: 'kentwoodsprings',
  LABRADOR: 'labradorsource',
  MTOLYMPUS: 'mountolympuswater',
  PRIMO: 'primo',
  PUREFLO: 'pureflo',
  SIERRA: 'sierrasprings',
  SPARKLETTS: 'sparkletts',
  WATER: 'water',
};

const {
  ALHAMBRA,
  AQUATERRA,
  CANADIAN,
  CRYSTAL,
  CRYSTALROCK,
  DEEPROCK,
  HINCKLEY,
  KENTWOOD,
  LABRADOR,
  MTOLYMPUS,
  PRIMO,
  PUREFLO,
  SIERRA,
  SPARKLETTS,
  WATER,
} = BRANDS;

export const ALHAMBRAWATER_SLUG = ALHAMBRA;
export const AQUATERRACORP_SLUG = AQUATERRA;
export const CANADIANSPRINGS_SLUG = CANADIAN;
export const CRYSTAL_ROCK_SLUG = CRYSTALROCK;
export const CRYSTAL_SPRINGS_SLUG = CRYSTAL;
export const DEEPROCKWATER_SLUG = DEEPROCK;
export const HINCKLEYSPRINGS_SLUG = HINCKLEY;
export const KENTWOODSPRINGS_SLUG = KENTWOOD;
export const LABRADORSOURCE_SLUG = LABRADOR;
export const MOUNTOLYMPUSWATER_SLUG = MTOLYMPUS;
export const PRIMO_SLUG = PRIMO;
export const PUREFLO_SLUG = PUREFLO;
export const SIERRASPRINGS_SLUG = SIERRA;
export const SPARKLETTS_SLUG = SPARKLETTS;
export const WATER_SLUG = WATER;

export const ACQUISITION_UNIFIED_SLUG = 'acquisition-unified';
export const ACQUISITION_WATER_SLUG = 'acquisition-water';
export const ACQUISITION_SPARKLETTS_SLUG = 'acquisition-sparkletts';
export const ACQUISITION_CRYSTAL_SPRINGS_SLUG = 'acquisition-crystal-springs';
export const ACQUISITION_ALHAMBRAWATER_SLUG = 'acquisition-alhambrawater';
export const ACQUISITION_LABRADORSOURCE_SLUG = 'acquisition-labradorsource';
export const ACQUISITION_CANADIANSPRINGS_SLUG = 'acquisition-canadiansprings';
export const ACQUISITION_AQUATERRACORP_SLUG = 'acquisition-aquaterracorp';
export const ACQUISITION_CRYSTAL_ROCK_SLUG = 'acquisition-crystalrock';
export const ACQUISITION_DEEPROCKWATER = 'acquisition-deeprockwater';
export const ACQUISITION_HINCKLEYSPRINGS_SLUG = 'acquisition-hinckleysprings';
export const ACQUISITION_KENTWOODSPRINGS_SLUG = 'acquisition-kentwoodsprings';
export const ACQUISITION_MOUNTOLYMPUSWATER_SLUG =
  'acquisition-mountolympuswater';
export const ACQUISITION_SIERRASPRINGS_SLUG = 'acquisition-sierrasprings';

export const COSTCO_WATER_SLUG = 'costcowater-water';
export const COSTCO_PRIMO_SLUG = 'costcowater-primo';
export const COSTCO_SPARKLETTS_SLUG = 'costcowater-sparkletts';
export const COSTCO_CRYSTAL_SPRINGS_SLUG = 'costcowater-crystalsprings';
export const COSTCO_CRYSTAL_ROCK_SLUG = 'costcowater-crystalrock';
export const COSTCO_DEEPROCKWATER_SLUG = 'costcowater-deeprockwater';
export const COSTCO_HINCKLEYSPRINGS_SLUG = 'costcowater-hinckleysprings';
export const COSTCO_KENTWOODSPRINGS_SLUG = 'costcowater-kentwoodsprings';
export const COSTCO_MOUNTOLYMPUSWATER_SLUG = 'costcowater-mountolympuswater';
export const COSTCO_SIERRASPRINGS_SLUG = 'costcowater-sierrasprings';
export const COSTCO_ALHAMBRAWATER_SLUG = 'costcowater-alhambra';

export const PRODUCTS_URL = {
  home: '/bottled-water-and-coffee',
  office: '/bottled-water-and-coffee',
};

const REGIONAL_SLUGS = [
  ALHAMBRA,
  AQUATERRA,
  CANADIAN,
  CRYSTAL,
  CRYSTALROCK,
  DEEPROCK,
  HINCKLEY,
  KENTWOOD,
  LABRADOR,
  MTOLYMPUS,
  PRIMO,
  SIERRA,
  SPARKLETTS,
  WATER,

  COSTCO_PRIMO_SLUG,
  COSTCO_WATER_SLUG,
  COSTCO_SPARKLETTS_SLUG,
  COSTCO_CRYSTAL_SPRINGS_SLUG,
  COSTCO_CRYSTAL_ROCK_SLUG,
  COSTCO_DEEPROCKWATER_SLUG,
  COSTCO_HINCKLEYSPRINGS_SLUG,
  COSTCO_KENTWOODSPRINGS_SLUG,
  COSTCO_MOUNTOLYMPUSWATER_SLUG,
  COSTCO_SIERRASPRINGS_SLUG,
  COSTCO_ALHAMBRAWATER_SLUG,

  ACQUISITION_UNIFIED_SLUG,
  ACQUISITION_WATER_SLUG,
  ACQUISITION_SPARKLETTS_SLUG,
  ACQUISITION_CRYSTAL_SPRINGS_SLUG,
  ACQUISITION_ALHAMBRAWATER_SLUG,
  ACQUISITION_LABRADORSOURCE_SLUG,
  ACQUISITION_CANADIANSPRINGS_SLUG,
  ACQUISITION_AQUATERRACORP_SLUG,
  ACQUISITION_CRYSTAL_ROCK_SLUG,
  ACQUISITION_DEEPROCKWATER,
  ACQUISITION_HINCKLEYSPRINGS_SLUG,
  ACQUISITION_KENTWOODSPRINGS_SLUG,
  ACQUISITION_MOUNTOLYMPUSWATER_SLUG,
  ACQUISITION_SIERRASPRINGS_SLUG,
];
export const regionalDomains = REGIONAL_SLUGS;
export const REGIONAL_DOMAIN = 'regional';

export const isRegionalDomain = (slug) => REGIONAL_SLUGS.includes(slug);

const COSTCO_SLUGS = [
  COSTCO_ALHAMBRAWATER_SLUG,
  COSTCO_CRYSTAL_ROCK_SLUG,
  COSTCO_CRYSTAL_SPRINGS_SLUG,
  COSTCO_DEEPROCKWATER_SLUG,
  COSTCO_HINCKLEYSPRINGS_SLUG,
  COSTCO_KENTWOODSPRINGS_SLUG,
  COSTCO_MOUNTOLYMPUSWATER_SLUG,
  COSTCO_PRIMO_SLUG,
  COSTCO_SIERRASPRINGS_SLUG,
  COSTCO_SPARKLETTS_SLUG,
  COSTCO_WATER_SLUG,
];

export const isCostcoDomain = (slug) => COSTCO_SLUGS.includes(slug);

const LOGOS = {
  [ALHAMBRA]:
    'https://images.ctfassets.net/x1vbd41hpla5/4XhhZXU23Wjue2Pp4qE6LJ/f89c8e9f0b4854e9b13605bf2449256c/Primo-Alhambra_lockup.svg',
  [CANADIAN]:
    'https://images.ctfassets.net/x1vbd41hpla5/1pdIJRw4FOzJ4plWHqcpvf/1067df9bd86f7c9888a119e223d6853a/Primo-CanSprings_lockup.svg',
  [CRYSTAL]:
    'https://images.ctfassets.net/x1vbd41hpla5/378KAaZzgnugOye95v5d13/761b14b513048fa0aed82e2392e641da/Primo-CrystalSprings_lockup.svg',
  [CRYSTALROCK]:
    'https://images.ctfassets.net/x1vbd41hpla5/7LRuUf69exSxsnElWZbT5v/4216bd5a66abdf7fd0542631333f9681/Primo-CrystalRock_lockup.svg',
  [DEEPROCK]:
    'https://images.ctfassets.net/x1vbd41hpla5/5ruq6qrxDmX7pIDSXQ3Ggu/876a7d3d4982e6c6ca79001c64b1bb8b/Primo-DeepRock_lockup.svg',
  [HINCKLEY]:
    'https://images.ctfassets.net/x1vbd41hpla5/3LGn9tEUNqteExBlniu2Lr/5d012923202e5a86288853a4bbb56666/Primo-HinckleySprings_lockup.svg',
  [KENTWOOD]:
    'https://images.ctfassets.net/x1vbd41hpla5/cUHYoSTHVlCq8yEbAoX5F/e60e5c059880fb309138a2d05c583da4/Primo-KentwoodSprings_lockup.svg',
  [LABRADOR]:
    'https://images.ctfassets.net/x1vbd41hpla5/3lRpwIEp8zqs5CBaCH4BVk/fb6aa5c672e16d1c16d145b0788f4ccb/Primo-LabSource_lockup.svg',
  [MTOLYMPUS]:
    'https://images.ctfassets.net/x1vbd41hpla5/4H5eunGLMO154QGzKgBnMu/7a2f3d25ebd2758b335aa26bb5853823/Primo-MountOlympus_lockup.svg',
  [PRIMO]:
    'https://images.ctfassets.net/x1vbd41hpla5/79H84PNC7lEPbwMio8HnOP/262e90f2f6364e433c37b1965786b06f/Primo_Water_Logo-Padded_Right.svg',
  [SIERRA]:
    'https://images.ctfassets.net/x1vbd41hpla5/2FXg0KlMwA9Z5QKopvP6kL/5f168adec0c6f73401599199f34c0ef3/Primo-SierraSprings_lockup.svg',
  [SPARKLETTS]:
    'https://images.ctfassets.net/x1vbd41hpla5/2SWMdFLZKMZ2IMCdIWJCTb/4b5a5b71a5bcb51857ee7f2c38fbfa26/Primo-Sparkletts_lockup.svg',
};

export const AUTH_CLIENT_IDS = {
  [ALHAMBRA]: getServerData('ALHAMBRAWATER_AUTH_CLIENT_ID'),
  [CANADIAN]: getServerData('CANADIANSPRINGS_AUTH_CLIENT_ID'),
  [CRYSTALROCK]: getServerData('CRYSTALROCK_AUTH_CLIENT_ID'),
  [CRYSTAL]: getServerData('CRYSTAL_SPRINGS_AUTH_CLIENT_ID'),
  [DEEPROCK]: getServerData('DEEPROCKWATER_AUTH_CLIENT_ID'),
  [HINCKLEY]: getServerData('HINCKLEYSPRINGS_AUTH_CLIENT_ID'),
  [KENTWOOD]: getServerData('KENTWOODSPRINGS_AUTH_CLIENT_ID'),
  [LABRADOR]: getServerData('LABRADORSOURCE_AUTH_CLIENT_ID'),
  [MTOLYMPUS]: getServerData('MOUNTOLYMPUSWATER_AUTH_CLIENT_ID'),
  [PUREFLO]: getServerData('PUREFLO_AUTH_CLIENT_ID'),
  [SIERRA]: getServerData('SIERRASPRINGS_AUTH_CLIENT_ID'),
  [SPARKLETTS]: getServerData('SPARKLETTS_AUTH_CLIENT_ID'),
  [WATER]: getServerData('WATER_AUTH_CLIENT_ID'),

  [COSTCO_WATER_SLUG]: getServerData('WATER_AUTH_CLIENT_ID'),
  [COSTCO_SPARKLETTS_SLUG]: getServerData('SPARKLETTS_AUTH_CLIENT_ID'),
  [COSTCO_CRYSTAL_SPRINGS_SLUG]: getServerData(
    'CRYSTAL_SPRINGS_AUTH_CLIENT_ID',
  ),
  [COSTCO_CRYSTAL_ROCK_SLUG]: getServerData('CRYSTALROCK_AUTH_CLIENT_ID'),
  [COSTCO_DEEPROCKWATER_SLUG]: getServerData('DEEPROCKWATER_AUTH_CLIENT_ID'),
  [COSTCO_HINCKLEYSPRINGS_SLUG]: getServerData(
    'HINCKLEYSPRINGS_AUTH_CLIENT_ID',
  ),
  [COSTCO_KENTWOODSPRINGS_SLUG]: getServerData('ENTWOODSPRINGS_AUTH_CLIENT_ID'),
  [COSTCO_MOUNTOLYMPUSWATER_SLUG]: getServerData(
    'MOUNTOLYMPUSWATER_AUTH_CLIENT_ID',
  ),
  [COSTCO_SIERRASPRINGS_SLUG]: getServerData('SIERRASPRINGS_AUTH_CLIENT_ID'),
  [ACQUISITION_UNIFIED_SLUG]: getServerData('SPARKLETTS_AUTH_CLIENT_ID'),
  [ACQUISITION_WATER_SLUG]: getServerData('WATER_AUTH_CLIENT_ID'),
  [ACQUISITION_SPARKLETTS_SLUG]: getServerData('SPARKLETTS_AUTH_CLIENT_ID'),
  [ACQUISITION_CRYSTAL_SPRINGS_SLUG]: getServerData(
    'CRYSTAL_SPRINGS_AUTH_CLIENT_ID',
  ),
  [ACQUISITION_ALHAMBRAWATER_SLUG]: getServerData(
    'ALHAMBRAWATER_AUTH_CLIENT_ID',
  ),
  [ACQUISITION_CRYSTAL_ROCK_SLUG]: getServerData('CRYSTALROCK_AUTH_CLIENT_ID'),
  [ACQUISITION_DEEPROCKWATER]: getServerData('DEEPROCKWATER_AUTH_CLIENT_ID'),
  [ACQUISITION_HINCKLEYSPRINGS_SLUG]: getServerData(
    'HINCKLEYSPRINGS_AUTH_CLIENT_ID',
  ),
  [ACQUISITION_KENTWOODSPRINGS_SLUG]: getServerData(
    'KENTWOODSPRINGS_AUTH_CLIENT_ID',
  ),
  [ACQUISITION_MOUNTOLYMPUSWATER_SLUG]: getServerData(
    'MOUNTOLYMPUSWATER_AUTH_CLIENT_ID',
  ),
  [ACQUISITION_SIERRASPRINGS_SLUG]: getServerData(
    'SIERRASPRINGS_AUTH_CLIENT_ID',
  ),
};

export const PARDOT_SLUGS = {
  [ALHAMBRA]: getServerData('ALHAMBRAWATER_PARDOT_SLUG'),
  [CANADIAN]: getServerData('CANADIANSPRINGS_PARDOT_SLUG'),
  [CRYSTALROCK]: getServerData('BELMONTSPRINGS_PARDOT_SLUG'),
  [CRYSTAL]: getServerData('CRYSTALSPRINGS_PARDOT_SLUG'),
  [DEEPROCK]: getServerData('DEEPROCKWATER_PARDOT_SLUG'),
  [HINCKLEY]: getServerData('HINCKLEYPRINGS_PARDOT_SLUG'),
  [KENTWOOD]: getServerData('KENTWOODSPRINGS_PARDOT_SLUG'),
  [LABRADOR]: getServerData('LABRADORSOURCE_PARDOT_SLUG'),
  [MTOLYMPUS]: getServerData('MOUNTOLYMPUSWATER_PARDOT_SLUG'),
  [PRIMO]: getServerData('PRIMO_PARDOT_SLUG'),
  [SIERRA]: getServerData('SIERRASPRINGS_PARDOT_SLUG'),
  [SPARKLETTS]: getServerData('SPARKLETTS_PARDOT_SLUG'),
  [WATER]: getServerData('WATER_PARDOT_SLUG'),

  [ACQUISITION_SPARKLETTS_SLUG]: getServerData('SPARKLETTS_PARDOT_SLUG'),
  [ACQUISITION_CRYSTAL_SPRINGS_SLUG]: getServerData(
    'CRYSTALSPRINGS_PARDOT_SLUG',
  ),
  [ACQUISITION_ALHAMBRAWATER_SLUG]: getServerData('ALHAMBRAWATER_PARDOT_SLUG'),
  [ACQUISITION_CRYSTAL_ROCK_SLUG]: getServerData('BELMONTSPRINGS_PARDOT_SLUG'),
  [ACQUISITION_DEEPROCKWATER]: getServerData('DEEPROCKWATER_PARDOT_SLUG'),
  [ACQUISITION_KENTWOODSPRINGS_SLUG]: getServerData(
    'KENTWOODSPRINGS_PARDOT_SLUG',
  ),
  [ACQUISITION_HINCKLEYSPRINGS_SLUG]: getServerData(
    'HINCKLEYPRINGS_PARDOT_SLUG',
  ),
  [ACQUISITION_MOUNTOLYMPUSWATER_SLUG]: getServerData(
    'MOUNTOLYMPUSWATER_PARDOT_SLUG',
  ),
  [ACQUISITION_SIERRASPRINGS_SLUG]: getServerData('SIERRASPRINGS_PARDOT_SLUG'),
  [ACQUISITION_LABRADORSOURCE_SLUG]: getServerData(
    'LABRADORSOURCE_PARDOT_SLUG',
  ),
  [ACQUISITION_CANADIANSPRINGS_SLUG]: getServerData(
    'CANADIANSPRINGS_PARDOT_SLUG',
  ),

  [COSTCO_WATER_SLUG]: getServerData('WATER_PARDOT_SLUG'),
  [COSTCO_PRIMO_SLUG]: getServerData('PRIMO_PARDOT_SLUG'),
  [COSTCO_SPARKLETTS_SLUG]: getServerData('SPARKLETTS_PARDOT_SLUG'),
  [COSTCO_CRYSTAL_SPRINGS_SLUG]: getServerData('CRYSTALSPRINGS_PARDOT_SLUG'),
  [COSTCO_ALHAMBRAWATER_SLUG]: getServerData('ALHAMBRAWATER_PARDOT_SLUG'),
  [COSTCO_CRYSTAL_ROCK_SLUG]: getServerData('BELMONTSPRINGS_PARDOT_SLUG'),
  [COSTCO_DEEPROCKWATER_SLUG]: getServerData('DEEPROCKWATER_PARDOT_SLUG'),
  [COSTCO_KENTWOODSPRINGS_SLUG]: getServerData('KENTWOODSPRINGS_PARDOT_SLUG'),
  [COSTCO_HINCKLEYSPRINGS_SLUG]: getServerData('HINCKLEYSPRINGS_PARDOT_SLUG'),
  [COSTCO_MOUNTOLYMPUSWATER_SLUG]: getServerData(
    'MOUNTOLYMPUSWATER_PARDOT_SLUG',
  ),
  [COSTCO_SIERRASPRINGS_SLUG]: getServerData('SIERRASPRINGS_PARDOT_SLUG'),
};

export const contentTypes = {
  domain: 'domain',
};

export const pages = {
  about: 'about',
  accountDetailsSuccess: 'accountDetailsSuccess',
  acquisitionPartner: 'acquisitionPartner',
  cASupplyChain: 'cASupplyChain',
  californiaNoticeAtCollection: 'california-notice-at-collection',
  californiaTransparencyAct: 'californiaTransparencyAct',
  texasNoticeAtCollection: 'texas-notice-at-collection',
  cart: 'cart',
  checkoutSuccess: 'checkoutSuccess',
  coffeeEquipment: 'coffeeEquipment',
  coffeeService: 'coffeeService',
  commercialServices: 'commercialServices',
  commercialServicesDEPREC: 'commercialServicesDEPREC',
  contactUs: 'contactUs',
  contactUsSuccess: 'contactUsSuccess',
  cookiePolicy: 'cookiePolicy',
  coolerCleaningService: 'coolerCleaningService',
  corporateResponsibility: 'corporateResponsibility',
  costcowaterCCPA: 'costcowaterCCPA',
  costcowaterCookies: 'costcowaterCookies',
  costcowaterCASupplyChain: 'costcowaterCASupplyChain',
  costcowaterNonservAbsopure: 'costcowaterNonservAbsopure',
  costcowaterNonservPremiumwater: 'costcowaterNonservPremiumwater',
  costcowaterNonservRestrictions: 'costcowaterNonservRestrictions',
  costcowaterPrivacy: 'costcowaterPrivacy',
  costcowaterService: 'costcowaterService',
  costcowaterTerms: 'costcowaterTerms',
  equipmentManuals: 'equipmentManuals',
  faq: 'faq',
  filtrationEducation: 'filtrationEducation',
  filtrationService: 'filtrationService',
  getStarted: 'getStarted',
  home: 'home',
  homeOfficeProductsDEPREC: 'homeOfficeProductsDEPREC',
  homeOfficeProducts: 'homeOfficeProducts',
  howItWorks: 'howItWorks',
  notFoundPage: 'notFoundPage',
  office: 'office',
  officeDEPREC: 'officeDEPREC',
  ourStory: 'ourStory',
  pageNotFound: 'pageNotFound',
  primoCart: 'primoCart',
  primoCheckoutConfirmation: 'primoCheckoutConfirmation',
  primoNextDelivery: 'primoNextDelivery',
  primoMyPromotions: 'primoMyPromotions',
  primoNoPromotions: 'primoNoPromotions',
  privacyPolicy: 'privacyPolicy',
  productList: 'productList',
  productListLoggedOut: 'productListLoggedOut',
  productPage: 'productPage',
  productPageLoggedOut: 'productPageLoggedOut',
  products: 'products',
  promotionLandingPage: 'promotion-landing-page-v2',
  promotionLandingPageB: 'promotion-landing-page-b',
  promotion30LandingPage: 'promotion-30-landing-page',
  mover50LandingPage: 'mover-50-landing-page',
  pumpDeliveryPage: 'promotion-landing-page-pump-delivery',
  quickShop: 'quickShop',
  refer: 'refer',
  residentialServices: 'residentialServices',
  residentialServicesDEPREC: 'residentialServicesDEPREC',
  responsibility: 'responsibility',
  serviceSuccess: 'serviceSuccess',
  somethingWentWrong: 'somethingWentWrong',
  startOrder: 'startOrder',
  support: 'support',
  termsAndConditions: 'termsAndConditions',
  termsOfUse: 'termsOfUse',
  waterEquipment: 'waterEquipment',
  waterQuality: 'waterQuality',
  whoWeServe: 'whoWeServe',
  whyPage: 'whyPage',
};

const commonBasePages = {
  type: 'page',
  [pages.acquisitionPartner]: 'acquisition-partner-sparkletts',
  [pages.californiaNoticeAtCollection]: 'california-notice-at-collection',
  [pages.californiaTransparencyAct]:
    'california-transparency-supply-chains-act',
  [pages.cart]: 'cart',
  [pages.checkoutSuccess]: 'checkout-success',
  [pages.commercialServices]: 'acq-office-services-v2',
  [pages.commercialServicesDEPREC]: 'office-services',
  [pages.cookiePolicy]: 'cookie-policy',
  [pages.corporateResponsibility]: 'corporate-responsibility',
  [pages.costcowaterCCPA]: 'costcowater-ccpa',
  [pages.costcowaterCookies]: 'costcowater-cookies',
  [pages.costcowaterCASupplyChain]: 'costcowater-casupplychain',
  [pages.costcowaterNonservAbsopure]: 'costcowater-nonserv-absopure',
  [pages.costcowaterNonservPremiumwater]: 'costcowater-nonserv-premiumwater',
  [pages.costcowaterNonservRestrictions]: 'costcowater-nonserv-restrictions',
  [pages.costcowaterPrivacy]: 'costcowater-privacy',
  [pages.costcowaterService]: 'costcowater-service',
  [pages.costcowaterTerms]: 'costcowater-terms',
  [pages.faq]: 'faq',
  [pages.filtrationEducation]: 'filtration-education',
  [pages.getStarted]: 'get-started',
  [pages.home]: 'home',
  [pages.office]: 'office',
  [pages.ourStory]: 'our-story-v2',
  [pages.privacyPolicy]: 'privacy-policy',
  [pages.quickShop]: 'quick-shop-v2',
  [pages.residentialServices]: 'acq-resi-services-v2',
  [pages.residentialServicesDEPREC]: 'services',
  [pages.responsibility]: 'responsibility',
  [pages.support]: 'support',
  [pages.termsAndConditions]: 'terms-and-conditions',
  [pages.termsOfUse]: 'terms-of-use',
  [pages.waterQuality]: 'bottled-water-quality',
  [pages.whyPage]: 'why-us',
};

const commonSelfServePages = {
  [pages.contactUs]: 'contact-us',
  [pages.contactUsSuccess]: 'contact-us-success',
  [pages.equipmentManuals]: 'equipment-manuals',
  [pages.primoNextDelivery]: 'sparkletts-next-delivery',
  [pages.primoMyPromotions]: 'sparkletts-my-promotions',
  [pages.primoNoPromotions]: 'sparkletts-no-promotions',
  [pages.productList]: 'product-list',
  [pages.productPage]: 'product-page',
  [pages.refer]: 'refer',
};

const commonStandAlonePages = {
  [pages.coffeeService]: 'coffee-service',
  [pages.filtrationService]: 'filtration-service',
  [pages.serviceSuccess]: 'service-success',
};

const commonAcquisitionPages = {
  [pages.about]: 'about',
  [pages.accountDetailsSuccess]: 'account-details-success',
  [pages.cASupplyChain]: 'CASupplyChain',
  [pages.coffeeService]: 'coffee-service',
  [pages.contactUs]: 'contact-us',
  [pages.contactUsSuccess]: 'contact-us-success',
  [pages.coolerCleaningService]: 'cooler-cleaning-service',
  [pages.equipmentManuals]: 'equipment-manuals',
  [pages.filtrationService]: 'filtration-service',
  [pages.home]: 'acquisition-home-2',
  [pages.homeOfficeProductsDEPREC]: '-bottled-water-and-coffee',
  [pages.homeOfficeProducts]: '-bottled-water-and-coffee-2',
  [pages.office]: 'acquisition-office-2',
  [pages.officeDEPREC]: 'acquisition-office',
  [pages.pageNotFound]: 'page-not-found',
  [pages.primoCart]: 'sparkletts-cart',
  [pages.primoCheckoutConfirmation]: 'sparkletts-checkout-confirmation',
  [pages.productList]: 'product-list',
  [pages.productListLoggedOut]: 'product-list-logged-out',
  [pages.productPageLoggedOut]: 'product-page-logged-out',
  [pages.serviceSuccess]: 'service-success',
  [pages.whoWeServe]: 'who-we-serve',
};

export const pureflo = {
  name: 'Pureflo',
  slug: PUREFLO,
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    [pages.products]: 'products',
  },
};

export const sparkletts = {
  name: 'Sparkletts',
  slug: SPARKLETTS,
  brandUrl: LOGOS[SPARKLETTS],
  type: contentTypes.domain,
  monetateKey: `${SPARKLETTS}.com`,
  page: {
    ...commonBasePages,
    ...commonSelfServePages,
    [pages.about]: 'about',
    [pages.accountDetailsSuccess]: 'account-details-success',
    [pages.cASupplyChain]: 'CASupplyChain',
    [pages.coffeeService]: 'coffee-service',
    [pages.coolerCleaningService]: 'cooler-cleaning-service',
    [pages.filtrationService]: 'filtration-service',
    [pages.homeOfficeProductsDEPREC]: 'bottled-water-and-coffee',
    [pages.homeOfficeProducts]: 'bottled-water-and-coffee',
    [pages.primoCart]: 'sparkletts-cart',
    [pages.primoCheckoutConfirmation]: 'sparkletts-checkout-confirmation',
    [pages.productPageLoggedOut]: 'product-page-logged-out',
    [pages.serviceSuccess]: 'service-success',
  },
};

export const primo = {
  name: 'Primo',
  brand: PRIMO,
  slug: PRIMO,
  brandUrl: LOGOS[PRIMO],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const water = {
  name: 'Water',
  brand: WATER,
  slug: WATER,
  brandUrl: LOGOS[PRIMO],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const alhambrawater = {
  name: 'Alhambra Water',
  brand: ALHAMBRA,
  slug: ALHAMBRA,
  brandUrl: LOGOS[ALHAMBRA],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const labradorsource = {
  name: 'Labrador Source',
  brand: LABRADOR,
  slug: LABRADOR,
  brandUrl: LOGOS[LABRADOR],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const canadiansprings = {
  name: 'Canadian Springs',
  brand: CANADIAN,
  slug: CANADIAN,
  brandUrl: LOGOS[CANADIAN],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const aquaterracorp = {
  name: 'Aquaterra',
  brand: AQUATERRA,
  slug: AQUATERRA,
  brandUrl: LOGOS[ALHAMBRA], // FIXME why is this the Alhambra logo?
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};
export const crystalsprings = {
  name: 'Crystal Springs',
  brand: CRYSTAL,
  slug: CRYSTAL,
  brandUrl: LOGOS[CRYSTAL],
  type: contentTypes.domain,
  // HINT: as-per request https://jira.water.com/browse/CX-4051
  // monetateKey: `${CRYSTAL}.com`,
  monetateKey: `${SPARKLETTS}.com`,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const crystalrock = {
  name: 'Crystal Rock',
  brand: CRYSTALROCK,
  slug: CRYSTALROCK,
  brandUrl: LOGOS[CRYSTALROCK],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
    [pages.productPageLoggedOut]: 'product-page-logged-out',
  },
};

export const deeprockwater = {
  name: 'Deep Rock Water',
  brand: DEEPROCK,
  slug: DEEPROCK,
  brandUrl: LOGOS[DEEPROCK],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const hinckleysprings = {
  name: 'Hinckley Springs',
  brand: HINCKLEY,
  slug: HINCKLEY,
  brandUrl: LOGOS[HINCKLEY],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const kentwoodsprings = {
  name: 'Kentwood Springs',
  brand: KENTWOOD,
  slug: KENTWOOD,
  brandUrl: LOGOS[KENTWOOD],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const mountolympuswater = {
  name: 'Mount Olympus Water',
  brand: MTOLYMPUS,
  slug: MTOLYMPUS,
  brandUrl: LOGOS[MTOLYMPUS],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const sierrasprings = {
  name: 'Sierra Springs',
  brand: SIERRA,
  slug: SIERRA,
  brandUrl: LOGOS[SIERRA],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
    ...commonSelfServePages,
  },
};

export const costcosparkletts = {
  name: 'Sparkletts',
  brand: SPARKLETTS,
  slug: COSTCO_SPARKLETTS_SLUG,
  brandUrl: LOGOS[SPARKLETTS],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcocrystalsprings = {
  name: 'Crystal Springs',
  brand: CRYSTAL,
  slug: COSTCO_CRYSTAL_SPRINGS_SLUG,
  brandUrl: LOGOS[CRYSTAL],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcocrystalrock = {
  name: 'Crystal Rock',
  brand: CRYSTALROCK,
  slug: COSTCO_CRYSTAL_ROCK_SLUG,
  brandUrl: LOGOS[CRYSTALROCK],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcodeeprockwater = {
  name: 'Deep Rock Water',
  brand: DEEPROCK,
  slug: COSTCO_DEEPROCKWATER_SLUG,
  brandUrl: LOGOS[DEEPROCK],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcohinckleysprings = {
  name: 'Hinckley Springs',
  brand: HINCKLEY,
  slug: COSTCO_HINCKLEYSPRINGS_SLUG,
  brandUrl: LOGOS[HINCKLEY],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcokentwoodsprings = {
  name: 'Kentwood Springs',
  brand: KENTWOOD,
  slug: COSTCO_KENTWOODSPRINGS_SLUG,
  brandUrl: LOGOS[KENTWOOD],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcomountolympuswater = {
  name: 'Mount Olympus Water',
  brand: MTOLYMPUS,
  slug: COSTCO_MOUNTOLYMPUSWATER_SLUG,
  brandUrl: LOGOS[MTOLYMPUS],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcosierrasprings = {
  name: 'Sierra Springs',
  brand: SIERRA,
  slug: COSTCO_SIERRASPRINGS_SLUG,
  brandUrl: LOGOS[SIERRA],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcowater = {
  name: 'Costco Water',
  brand: WATER,
  slug: COSTCO_WATER_SLUG,
  brandUrl: LOGOS[PRIMO],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcoprimo = {
  name: 'Primo',
  brand: PRIMO,
  slug: COSTCO_PRIMO_SLUG,
  brandUrl: LOGOS[PRIMO],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
  },
};

export const costcoalhambrawater = {
  name: 'Alhambra Water',
  brand: ALHAMBRA,
  slug: COSTCO_ALHAMBRAWATER_SLUG,
  brandUrl: LOGOS[ALHAMBRA],
  type: contentTypes.domain,
  page: {
    ...commonBasePages,
    ...commonStandAlonePages,
  },
};

export const acquisitionwater = {
  name: 'Water',
  slug: ACQUISITION_WATER_SLUG,
  brand: WATER,
  url: 'www.water.com',
  brandUrl: LOGOS[PRIMO],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionsparkletts = {
  name: 'Sparkletts',
  slug: ACQUISITION_SPARKLETTS_SLUG,
  brand: SPARKLETTS,
  url: 'www.sparkletts.com',
  brandUrl: LOGOS[SPARKLETTS],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionunified = {
  name: 'Unified',
  slug: ACQUISITION_UNIFIED_SLUG,
  brand: 'Unified',
  // brandUrl: LOGOS[SPARKLETTS],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitioncrystalsprings = {
  name: 'Crystal Springs',
  slug: ACQUISITION_CRYSTAL_SPRINGS_SLUG,
  brand: CRYSTAL,
  url: 'www.crystal-springs.com',
  brandUrl: LOGOS[CRYSTAL],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionalhambrawater = {
  name: 'Alhambra Water',
  slug: ACQUISITION_ALHAMBRAWATER_SLUG,
  brand: ALHAMBRA,
  url: 'www.alhambrawater.com',
  brandUrl: LOGOS[ALHAMBRA],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionlabradorsource = {
  name: 'Labrador Source',
  slug: ACQUISITION_LABRADORSOURCE_SLUG,
  brand: LABRADOR,
  url: 'www.labradorsource.com',
  brandUrl: LOGOS[LABRADOR],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitioncanadiansprings = {
  name: 'Canadian Springs',
  slug: ACQUISITION_CANADIANSPRINGS_SLUG,
  brand: CANADIAN,
  url: 'www.canadiansprings.com',
  brandUrl: LOGOS[CANADIAN],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionaquaterracorp = {
  name: 'Aquaterracorp',
  slug: ACQUISITION_AQUATERRACORP_SLUG,
  brand: AQUATERRA,
  url: 'www.aquaterracorp.com',
  brandUrl: LOGOS[ALHAMBRA], // FIXME why is this the Alhambra logo?
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};
export const acquisitioncrystalrock = {
  name: 'Crystal Rock',
  slug: ACQUISITION_CRYSTAL_ROCK_SLUG,
  brand: CRYSTALROCK,
  url: 'www.crystalrock.com',
  brandUrl: LOGOS[CRYSTALROCK],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitiondeeprockwater = {
  name: 'Deep Rock Water',
  slug: ACQUISITION_DEEPROCKWATER,
  brand: DEEPROCK,
  url: 'www.deeprockwater.com',
  brandUrl: LOGOS[DEEPROCK],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionhinckleysprings = {
  name: 'Hinckley Springs',
  slug: ACQUISITION_HINCKLEYSPRINGS_SLUG,
  brand: HINCKLEY,
  url: 'www.hinckleysprings.com',
  brandUrl: LOGOS[HINCKLEY],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionkentwoodsprings = {
  name: 'Kentwood Springs',
  slug: ACQUISITION_KENTWOODSPRINGS_SLUG,
  brand: KENTWOOD,
  url: 'www.kentwoodsprings.com',
  brandUrl: LOGOS[KENTWOOD],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionmountolympuswater = {
  name: 'Mount Olympus Water',
  slug: ACQUISITION_MOUNTOLYMPUSWATER_SLUG,
  brand: MTOLYMPUS,
  url: 'www.mountolympuswater.com',
  brandUrl: LOGOS[MTOLYMPUS],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const acquisitionsierrasprings = {
  name: 'Sierra Springs',
  slug: ACQUISITION_SIERRASPRINGS_SLUG,
  brand: SIERRA,
  url: 'www.sierrasprings.com',
  brandUrl: LOGOS[SIERRA],
  type: contentTypes.domain,
  isAcquisition: true,
  page: {
    ...commonBasePages,
    ...commonAcquisitionPages,
  },
};

export const contentModules = {
  header: 'header',
  footer: 'footer',
  page: 'page',
  banner: 'banner',
};

export const domainMap = {
  [ALHAMBRA]: alhambrawater,
  [AQUATERRA]: aquaterracorp,
  [CANADIAN]: canadiansprings,
  [CRYSTALROCK]: crystalrock,
  [CRYSTAL]: crystalsprings,
  [DEEPROCK]: deeprockwater,
  [HINCKLEY]: hinckleysprings,
  [KENTWOOD]: kentwoodsprings,
  [LABRADOR]: labradorsource,
  [MTOLYMPUS]: mountolympuswater,
  [PRIMO]: primo,
  [PUREFLO]: pureflo,
  [SIERRA]: sierrasprings,
  [SPARKLETTS]: sparkletts,
  [WATER]: water,

  [ACQUISITION_UNIFIED_SLUG]: acquisitionunified,
  [ACQUISITION_WATER_SLUG]: acquisitionwater,
  [ACQUISITION_SPARKLETTS_SLUG]: acquisitionsparkletts,
  [ACQUISITION_CRYSTAL_SPRINGS_SLUG]: acquisitioncrystalsprings,
  [ACQUISITION_ALHAMBRAWATER_SLUG]: acquisitionalhambrawater,
  [ACQUISITION_LABRADORSOURCE_SLUG]: acquisitionlabradorsource,
  [ACQUISITION_CANADIANSPRINGS_SLUG]: acquisitioncanadiansprings,
  [ACQUISITION_AQUATERRACORP_SLUG]: acquisitionaquaterracorp,
  [ACQUISITION_CRYSTAL_ROCK_SLUG]: acquisitioncrystalrock,
  [ACQUISITION_DEEPROCKWATER]: acquisitiondeeprockwater,
  [ACQUISITION_HINCKLEYSPRINGS_SLUG]: acquisitionhinckleysprings,
  [ACQUISITION_KENTWOODSPRINGS_SLUG]: acquisitionkentwoodsprings,
  [ACQUISITION_MOUNTOLYMPUSWATER_SLUG]: acquisitionmountolympuswater,
  [ACQUISITION_SIERRASPRINGS_SLUG]: acquisitionsierrasprings,

  [COSTCO_PRIMO_SLUG]: costcoprimo,
  [COSTCO_WATER_SLUG]: costcowater,
  [COSTCO_SPARKLETTS_SLUG]: costcosparkletts,
  [COSTCO_CRYSTAL_SPRINGS_SLUG]: costcocrystalsprings,
  [COSTCO_CRYSTAL_ROCK_SLUG]: costcocrystalrock,
  [COSTCO_DEEPROCKWATER_SLUG]: costcodeeprockwater,
  [COSTCO_HINCKLEYSPRINGS_SLUG]: costcohinckleysprings,
  [COSTCO_KENTWOODSPRINGS_SLUG]: costcokentwoodsprings,
  [COSTCO_MOUNTOLYMPUSWATER_SLUG]: costcomountolympuswater,
  [COSTCO_SIERRASPRINGS_SLUG]: costcosierrasprings,
  [COSTCO_ALHAMBRAWATER_SLUG]: costcoalhambrawater,
  default: pureflo,
};
