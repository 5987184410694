import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { Drawer, SuccessBlock } from 'components/Ui';
import RecurringOrderForm from './RecurringOrderForm';
import { cancelRecurringOrderEditing, updateRecurringOrder } from '../actions';
import {
  selectIsEditingOrder,
  selectRecurringOrder,
  selectServiceLocationIsFetchingOrder,
  selectServiceLocationIsSuccessUpdatedOrder,
} from '../selectors';
import ProcessingModal from '../../PrimoProfile/ProcessingModal';
import styles from './styles.scss';

const EditRecurringOrderDrawer = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectIsEditingOrder());
  const isSuccessUpdated = useSelector(
    selectServiceLocationIsSuccessUpdatedOrder(),
  );
  const isLoading = useSelector(selectServiceLocationIsFetchingOrder());
  const order = useSelector(selectRecurringOrder());

  const onUpdateRecurringOrder = (changedDelivery) => {
    const changedDeliveryToUpdate = changedDelivery.map((item) => {
      const itemToUpdate = {
        itemId: item.itemId,
        itemNumber: item.itemNumber,
        deliveryFrequency: item.deliveryFrequency,
        recurringDeliveryId: item.recurringDeliveryId,
        description: item.longDescription || item.description,
      };
      if (item.deliveryFrequency === 'Replenish') {
        const quantity = item.originalQuantity
          ? item.originalQuantity
          : item.quantity;
        itemToUpdate.quantity = item.itemAverageQuantity
          ? item.itemAverageQuantity
          : quantity;
      } else {
        itemToUpdate.quantity = item.quantity;
      }
      return itemToUpdate;
    });
    dispatch(updateRecurringOrder(changedDeliveryToUpdate));
  };
  const handleClosingEditor = () => dispatch(cancelRecurringOrderEditing());

  const renderContent = () => {
    if (isSuccessUpdated) {
      return (
        <Fragment>
          <ProcessingModal />
          <div className={styles.successMessage}>
            <SuccessBlock text="Your changes have been saved." />
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <ProcessingModal />
        <RecurringOrderForm
          order={order}
          onSubmit={onUpdateRecurringOrder}
          onClose={handleClosingEditor}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Drawer
        headerTitle="Edit your recurring order"
        onClose={handleClosingEditor}
        open={open}
        noMargins
      >
        <Loader loading={isLoading} local>
          {renderContent()}
        </Loader>
      </Drawer>
    </Fragment>
  );
};

export default EditRecurringOrderDrawer;
