import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import { get } from 'lodash';
import { selectCartItems } from 'containers/Cart/selectors';

import find from 'lodash/find';
import { PRODUCTS_KEY } from './constants';

const selectProducts = () => (state) => state.get(PRODUCTS_KEY, Map());

export const selectProductCategories = () =>
  createSelector(selectProducts(), (products) =>
    products.get('categories', List()).toJS(),
  );

export const selectIsProductCategoriesFetching = () =>
  createSelector(selectProducts(), (products) =>
    products.get('isCategoriesFetching', false).toJS(),
  );

export const selectFamilyProductBundleData = () =>
  createSelector(selectProducts(), (products) =>
    products.get('familyProductBundles', List()).toJS(),
  );

export const selectProductsData = (interleaveCartItems) => {
  if (interleaveCartItems) {
    return createSelector(
      [selectProducts(), selectCartItems()],
      (products, cartItems) => {
        const productList = products.get('data', List()).toJS();

        return productList.map((product) => {
          const match = find(
            cartItems,
            (item) => product.itemNumber === item.itemNumber,
          );
          return {
            ...product,
            ...(match && { itemId: match.id, quantity: match.quantity }),
          };
        });
      },
    );
  }

  return createSelector(selectProducts(), (products) =>
    products.get('data', List()).toJS(),
  );
};

export const selectProductsAreFetching = () =>
  createSelector(
    selectProducts(),
    (products) =>
      products.get('isFetching') || products.get('isBundlesFetching'),
  );

export const selectProductByItemNumber = (itemNumber) =>
  createSelector(selectProducts(), (products) => {
    const productItem = products
      .get('data', List())
      .find((product) => product.get('itemNumber') === itemNumber);
    return productItem ? productItem.toJS() : null;
  });

export const selectProductBySlugAndType = (type, slug) =>
  createSelector(selectProducts(), (products) => {
    const productItem = products.get('data', List()).find((product) => {
      const p = product.toJS();
      return get(p, 'slug') === slug && get(p, 'productType.slug') === type;
    });

    return productItem ? productItem.toJS() : null;
  });

export const selectProductsTotalCount = () =>
  createSelector(selectProducts(), (products) => products.get('totalCount', 0));

export const selectProductBrands = () =>
  createSelector(selectProducts(), (products) =>
    products.get('brands', List()).sort().toJS(),
  );

export const selectProductWaterTypes = () =>
  createSelector(selectProducts(), (products) =>
    products.get('waterTypes', List()).sort().toJS(),
  );

export const selectFeaturedProducts = () =>
  createSelector(selectProducts(), (products) =>
    products.get('featured').toJS(),
  );

export const selectProductsError = () =>
  createSelector(selectProducts(), (data) => (data ? data.get('error') : null));
