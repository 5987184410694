import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { autoPlay } from 'react-swipeable-views-utils';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classNames from 'classnames';
import get from 'lodash/get';
import { MobileStepper, withStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { Button } from 'components/Ui';
import { Download } from 'components/Ui/CustomIcons';
import { openFile } from 'utils/fileDownload';
import styles from './styles.scss';
import jsStyles from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Item extends PureComponent {
  state = {
    activeStep: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.loading) {
      return {
        loading: props.cartLoading,
      };
    }

    return null;
  }

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  render() {
    const {
      props: {
        classes,
        theme,
        document: {
          fields: { description, images, properties, title },
        },
      },
      state: { activeStep },
    } = this;

    const propertiesOption = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
          get(node, 'content', []).length === 2 ? (
            <p className={styles.property}>{children}</p>
          ) : null,
        [BLOCKS.EMBEDDED_ASSET]: (node) => (
          <Button
            icon={<Download width="20" height="26" />}
            className={styles.brochureBtn}
            text="Download Brochure"
            onClick={() =>
              openFile({ url: get(node, 'data.target.fields.file.url') })
            }
          />
        ),
      },
    };

    const propertiesParsed = documentToReactComponents(
      properties,
      propertiesOption,
    );

    const maxSteps = images && images.length;

    return (
      <div className={styles.product}>
        <div className={styles.name}>{title}</div>
        <div className={styles.content}>
          <div className={styles.gallery}>
            <div className={classes.root}>
              <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={this.handleStepChange}
                enableMouseEvents
                interval={10000}
              >
                {images &&
                  images.map((img) => (
                    <img
                      src={`${img.fields.file.url}?fm=webp`}
                      alt={img.fields.description}
                      key={img.sys.id}
                    />
                  ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                classes={{
                  root: classes.mobileStepper,
                  dot: classes.dot,
                  dotActive: classes.dotActive,
                  dots: classes.dots,
                }}
                nextButton={
                  activeStep !== maxSteps - 1 ? (
                    <Button
                      className={classNames(classes.buttons, styles.btn)}
                      icon={<ChevronRight />}
                      onClick={this.handleNext}
                    >
                      Next
                    </Button>
                  ) : null
                }
                backButton={
                  activeStep !== 0 ? (
                    <Button
                      className={classNames(classes.buttons, styles.btn)}
                      icon={<ChevronLeft />}
                      onClick={this.handleBack}
                    >
                      Back
                    </Button>
                  ) : null
                }
              />
            </div>
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>{description}</div>
            <div className={styles.properties}>{propertiesParsed}</div>
            <Button
              className={styles.contactUsBtn}
              text="Contact Us"
              href="/get-started"
            />
          </div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  document: PropTypes.object,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

export default withStyles(jsStyles, { withTheme: true })(Item);
