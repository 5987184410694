import React from 'react';
import Responsive from 'react-responsive';
import { themeConfig } from 'theme/themeConfig';

const { sm, md, lg } = themeConfig.breakpoints.values;

const Desktop = (props) => <Responsive {...props} minWidth={lg} />;
const Tablet = (props) => (
  <Responsive {...props} minWidth={md} maxWidth={lg - 1} />
);
const Mobile = (props) => <Responsive {...props} maxWidth={md - 1} />;
const Default = (props) => <Responsive {...props} minWidth={md} />;

const AboveSmallPhone = (props) => <Responsive {...props} minWidth={sm} />;
const AboveMobile = (props) => <Responsive {...props} minWidth={md} />;

const BelowDesktop = (props) => <Responsive {...props} maxWidth={lg - 1} />;

export {
  Desktop,
  Tablet,
  Mobile,
  Default,
  AboveSmallPhone,
  BelowDesktop,
  AboveMobile,
};
