import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Grid, withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { Input, Button, ErrorMessage, Divider } from 'components/Ui';
import {
  login,
  logout,
  restorePassword as restorePasswordAction,
} from 'containers/Authentication/actions';
import {
  selectLoginFetching,
  selectLoginError,
  selectRestorePasswordFetching,
  selectRestorePasswordMessage,
  selectRestoreEmail,
} from 'containers/Authentication/selectors';

import { ORACLE_REALM_NAME } from 'containers/Authentication/constants';

import SuccessRestoreDialog from './SuccessRestoreDialog';
import ForgotPasswordDialog from './ForgotPasswordDialog';

import styles from './styles.scss';
import jsStyles from './styles';

export class Login extends React.PureComponent {
  state = {
    username: '',
    password: '',
    isPasswordHidden: true,
    isForgotPasswordDialogOpen: false,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleToggleHidden = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isPasswordHidden: !prevState.isPasswordHidden,
    }));
  };

  handleForgotPasswordDialog = () => {
    this.setState((prevState) => ({
      isForgotPasswordDialogOpen: !prevState.isForgotPasswordDialogOpen,
    }));
  };

  handleSubmit = () => {
    const { username, password } = this.state;
    const {
      location: { state = {} },
      redirectUrl,
    } = this.props;
    const returnTo = redirectUrl || state.prevPath;

    this.props.login({
      username,
      password,
      returnTo,
      realm: ORACLE_REALM_NAME,
    });
  };

  handleRestoreSubmit = (email) => {
    const { restorePassword } = this.props;
    restorePassword(email);
    this.setState({
      isForgotPasswordDialogOpen: false,
    });
  };

  handleResendEmail = () => {
    const { restorePassword } = this.props;
    const { email } = this.state;

    restorePassword(email);
  };

  render() {
    const {
      state: {
        username,
        password,
        isPasswordHidden,
        isForgotPasswordDialogOpen,
      },
      props: { error, isFetching, className, view },
    } = this;

    const isPageView = view === 'page';
    const formClassName = classnames(className, {
      [styles.pageView]: isPageView,
      [styles.popupView]: !isPageView,
    });

    return (
      <Fragment>
        <ValidatorForm onSubmit={this.handleSubmit} className={formClassName}>
          <div className={styles.title}>
            Welcome back!
            {!isPageView && <h1>Sign In</h1>}
          </div>
          <div className={styles.form}>
            {error && (
              <ErrorMessage style={{ marginBottom: '5px' }}>
                {error}
              </ErrorMessage>
            )}
            <Input
              label="Email:"
              validators={['required']}
              errorMessages={['']}
              value={username}
              onChange={this.handleChange}
              name="username"
              withoutError
            />
            <div className={styles.passwordWrapper}>
              {isPageView && (
                <Fragment>
                  <Button
                    link
                    onClick={this.handleToggleHidden}
                    text={isPasswordHidden ? 'Show Password' : 'Hide Password'}
                    className={styles.showPassword}
                  />
                </Fragment>
              )}
              <Input
                label="Password:"
                validators={['required']}
                errorMessages={['']}
                value={password}
                type={!isPasswordHidden && isPageView ? 'text' : 'password'}
                onChange={this.handleChange}
                name="password"
                withoutError
              />
            </div>
            {isPageView && (
              <Fragment>
                <Button
                  link
                  onClick={this.handleForgotPasswordDialog}
                  text="Forgot password?"
                  className={styles.forgotPassword}
                />
                <Divider className={styles.divider} />
              </Fragment>
            )}
            <Grid container justify="flex-end" alignItems="center">
              <Button
                text="SIGN IN"
                loading={isFetching}
                className={styles.submit}
              />
            </Grid>
          </div>
        </ValidatorForm>
        <ForgotPasswordDialog
          open={isForgotPasswordDialogOpen}
          onClose={this.handleForgotPasswordDialog}
          onSubmit={this.handleRestoreSubmit}
        />
        <SuccessRestoreDialog />
      </Fragment>
    );
  }
}

Login.propTypes = {
  isFetching: PropTypes.bool,
  error: PropTypes.string,
  login: PropTypes.func.isRequired,
  className: PropTypes.string,
  redirectUrl: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      prevPath: PropTypes.string,
    }),
  }),
  restorePassword: PropTypes.func,
  view: PropTypes.oneOf(['page', 'popup']),
};

Login.defaultProps = {
  view: 'popup',
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectLoginFetching(),
  error: selectLoginError(),
  isRestorePasswordFetching: selectRestorePasswordFetching(),
  restorePasswordMessage: selectRestorePasswordMessage(),
  email: selectRestoreEmail(),
});

const mapDispatchToProps = {
  login,
  logout,
  restorePassword: restorePasswordAction,
};

export default compose(
  withRouter,
  withStyles(jsStyles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(Login);
