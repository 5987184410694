import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import {
  Tooltip as MUITooltip,
  ClickAwayListener,
  withStyles,
} from '@material-ui/core';
import { CloseIcon } from 'components/Ui/CustomIcons';

import jsStyles from './water-regional';

class Tooltip extends Component {
  static getDerivedStateFromProps(props, state) {
    if (!isNil(props.open) && props.open !== state.open) {
      return { open: props.open };
    }
    return null;
  }

  state = {
    open: !!this.props.open,
  };

  handleClickAway = () => {
    const { handleTooltip } = this.props;

    handleTooltip(false);
  };

  render() {
    const { open } = this.state;
    const { children, classes, anchor, role, title } = this.props;

    const content = (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div>
          <div className={classes.popperContent}>{children}</div>
          <span className={classes.arrow} />
          {<CloseIcon onClick={this.handleClickAway} />}
        </div>
      </ClickAwayListener>
    );

    return (
      <MUITooltip
        open={open}
        PopperProps={{
          disablePortal: true,
          role: role || 'tooltip',
          'aria-label': title,
        }}
        title={content}
        classes={{
          popper: classes.popper,
          tooltip: classes.tooltip,
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        {anchor}
      </MUITooltip>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  anchor: PropTypes.any.isRequired,
  handleTooltip: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

Tooltip.defaultProps = {
  open: false,
};

export default withStyles(jsStyles, { withTheme: true })(Tooltip);
