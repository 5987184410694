import * as types from './constants';

export const updateUserRegion = (payload) => ({
  type: types.UPDATE_USER_REGION,
  payload,
});

export const fetchUserRegion = (payload) => ({
  type: types.FETCH_USER_REGION,
  payload,
});

export const setUserRegionToBrandDefault = () =>
  // TODO: Use brand mapping to get defaults
  // const postalCode = getServerData('DEFAULT_ZIP') || '90041';

  updateUserRegion({ ...types.DEFAULT_USER_REGION });
