import { fromJS } from 'immutable';
import * as types from './constants';

const defaultState = fromJS([]);

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_SNACKBAR:
      return state.mergeDeep(fromJS([action.notification]));

    case types.REMOVE_SNACKBAR:
      return state.set(
        state.filter((notification) => notification.get('key') !== action.key),
      );

    default:
      return state;
  }
};
