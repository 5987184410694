import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Div } from 'components/Ui';
import { translateData } from 'utils/translation';
import styles from './styles.scss';

const Legend = ({ highlight, showRecurring }) => {
  const [visibleTooltip, setVisibleTooltip] = useState(highlight);

  useEffect(() => {
    if (showRecurring) setVisibleTooltip('recurring');
  }, [showRecurring]);

  const tooltipDescriptions = {
    recurring: translateData(
      'Recurring delivery dates are when you will be receiving your regularly scheduled deliveries. Deliveries are every 2 or 4 weeks based on your service area.',
    ),
    suggested: translateData(
      'Suggested dates mean that we are already in your neighborhood! Choose one of these dates to help conserve resources and reduce emissions.',
    ),
  };

  return (
    <div className={styles.legend} aria-hidden="true">
      <div className={styles.items}>
        <div
          role="presentation"
          className={classnames(styles.item, {
            [styles.showTooltip]: visibleTooltip === 'suggested',
          })}
          onClick={() => setVisibleTooltip('suggested')}
          onMouseEnter={() => setVisibleTooltip('suggested')}
        >
          <div className={classnames(styles.symbol, styles.suggested)} />
          <Div className={styles.label}>Suggested Date</Div>
        </div>
        {showRecurring && (
          <div
            role="presentation"
            className={classnames(styles.item, {
              [styles.showTooltip]: visibleTooltip === 'recurring',
            })}
            onClick={() => setVisibleTooltip('recurring')}
            onMouseEnter={() => setVisibleTooltip('recurring')}
          >
            <div className={classnames(styles.symbol, styles.recurring)} />
            <Div className={styles.label}>Recurring Delivery Date</Div>
          </div>
        )}
      </div>
      <div className={styles.tooltip}>
        {tooltipDescriptions[visibleTooltip]}
      </div>
    </div>
  );
};

Legend.propTypes = {
  highlight: PropTypes.string,
  showRecurring: PropTypes.bool,
};

export default Legend;
