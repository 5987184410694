import styled from 'styled-components';
import { themeConfig } from 'theme/themeConfig';

const Title = styled.div`
  color: ${themeConfig.colors.darkBlue};
  font-weight: 200;
  font-size: ${(props) => (props.large ? '3.125rem' : '1.875rem')};
  @media (max-width: ${themeConfig.breakpoints.values.md}px) {
    font-size: ${(props) => (props.large ? '2rem' : '1.875rem')};
  }
`;

Title.displayName = 'Title';

export default Title;
