import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Wrapper from 'components/Ui/Wrapper';
import Title from 'components/Ui/Title';

const FormContainer = (props, { stylesTheme }) => {
  const styles = require(`./styles/${stylesTheme}.scss`);

  return (
    <Fragment>
      {props.title && <Title className={styles.title}>{props.title}</Title>}
      <Wrapper
        color={props.color}
        className={classNames(props.className, styles.wrapper)}
      >
        {props.children}
      </Wrapper>
    </Fragment>
  );
};

FormContainer.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['grey', 'white']),
  title: PropTypes.string,
};

FormContainer.defaultProps = {
  color: 'white',
  title: null,
};

FormContainer.contextTypes = {
  stylesTheme: PropTypes.string.isRequired,
};

export default FormContainer;
