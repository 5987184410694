export default (theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    padding: '0 30px',
    position: 'relative',
    background: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  dots: {
    margin: '20px auto',
  },
  mobileStepper: {
    background: theme.colors.white,
    img: {
      width: '100%',
    },
  },
  dot: {
    borderRadius: '0',
    width: '60px',
    height: '8px',
    backgroundColor: theme.colors.lightGreen,
    '&:first-child': {
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  dotActive: {
    backgroundImage: theme.gradients.primaryGradient,
  },
  buttons: {
    position: 'absolute',
    top: '0',
    bottom: '20%',
    margin: 'auto 0',
    height: 'fit-content',
    padding: '5px 11px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:first-child': {
      left: '55px',
      [theme.breakpoints.down('lg')]: {
        left: '10px',
      },
    },
    '&:last-child': {
      right: '55px',
      [theme.breakpoints.down('lg')]: {
        right: '10px',
      },
    },
  },
});
