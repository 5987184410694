import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PinMapIcon } from 'images';
import shuffle from 'lodash/shuffle';
import styles from './styles.scss';
import { PINS } from './constants';

class MapAnimated extends Component {
  constructor(props) {
    super(props);

    this.pins = [];
    this.addCounter = 0;
    this.deleteCounter = 0;
  }

  componentDidMount() {
    const pinsCount = this.pins.length;

    this.showAnimatePins = setInterval(() => {
      this.pins[this.addCounter].classList.remove(styles.hidden);
      this.pins[this.addCounter].classList.add(styles.visible);

      if (this.addCounter === pinsCount - 1) {
        this.addCounter = 0;
      } else {
        this.addCounter += 1;
      }
    }, 1000);

    setTimeout(() => {
      this.hideAnimatePins = setInterval(() => {
        this.pins[this.deleteCounter].classList.remove(styles.visible);
        this.pins[this.deleteCounter].classList.add(styles.hidden);

        if (this.deleteCounter === pinsCount - 1) {
          this.deleteCounter = 0;
        } else {
          this.deleteCounter += 1;
        }
      }, 1000);
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.showAnimatePins);
    clearInterval(this.hideAnimatePins);
  }

  render() {
    const { children } = this.props;

    return (
      <Fragment>
        {shuffle(PINS).map((pin) => (
          <img
            className={classnames(styles.pin, styles.hidden)}
            ref={(c) => this.pins.push(c)}
            style={{ left: pin.left, top: pin.top }}
            key={`${pin.left}&${pin.top}`}
            src={PinMapIcon}
            alt="Pin"
          />
        ))}
        {children}
      </Fragment>
    );
  }
}

MapAnimated.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MapAnimated;
