import React from 'react';
import PropTypes from 'prop-types';
import Banner from '../Banner';
import ProductsCompare from '../CompareProducts';
import Quiz from '../Quiz/Loadable';
import Slider from '../Slider';
import Carousel from '../Carousel';
import CollapseBanner from '../CollapseBanner';

const getContentModule = (document) => {
  switch (document.sys.contentType.sys.id) {
    case 'banner':
      return <Banner document={document} />;
    case 'quiz':
      return <Quiz document={document} />;
    case 'slider':
      return <Slider document={document} />;
    case 'productsCompare':
      return <ProductsCompare document={document} />;
    case 'carousel':
      return <Carousel document={document} />;
    case 'CollapseBanner':
      return <CollapseBanner document={document} />;
    default:
      return null;
  }
};

const Page = ({
  document: {
    fields: { contentModules },
  },
}) => contentModules.map((content) => getContentModule(content));

Page.propTypes = {
  document: PropTypes.object,
};

export default Page;
