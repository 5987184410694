module.exports = (theme) => ({
  root: {
    padding: '1px',
    fontSize: '0.875rem',
    color: theme.colors.dimGrey,
    backgroundColor: theme.colors.white,
    '&$error $notchedOutline': {
      border: `1px solid ${theme.colors.red} !important`,
    },
  },
  input: {
    padding: '12px 15px',
    backgroundColor: theme.colors.white,
  },
  notchedOutline: {
    borderRadius: 4,
    border: `1px solid ${theme.colors.extraLightGrey} !important`,
  },
  error: {},
  multiline: {
    padding: 0,
  },
  inputError: {
    marginTop: 4,
    fontSize: '0.6875rem',
  },
});
