import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { DRAWERS_STORE_KEY } from 'containers/DrawerManager/constants';

const selectDrawerDomain = (state) => state.get(DRAWERS_STORE_KEY, Map());

const selectDrawerStatus = (pageTitle) =>
  createSelector(selectDrawerDomain, (substate) => substate.get(pageTitle));

const selectDrawers = () =>
  createSelector(selectDrawerDomain, (substate) => substate.toJS());

export { selectDrawerStatus, selectDrawers };
