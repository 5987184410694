/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { isString, isArray } from 'lodash';
import { translateData } from 'utils/translation';

const F = ({ children }) => {
  let translate = false;
  if (isArray(children)) {
    translate = children.length === 1 && children[0].length > 0;
  }
  translate = isString(children) ? children.trim().length > 0 : translate;
  return <>{translate ? translateData(children) : children}</>;
};

F.propTypes = {
  children: PropTypes.node.isRequired,
};

F.defaultProps = {};

export default F;
